import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import Icon from 'components/Icons';
import './index.scss';
import InfoIcon from 'imgs/info.png';
import InfoRedIcon from 'imgs/info-red.png';
import {
  TooltipWrapper as TooltipWrapperInterface,
  Tooltip as TooltipInterface,
  TooltipIcon
} from 'types/tooltip';

const Tooltip = ({ title, body }: TooltipInterface) => (
  <>
    <div className="c-tooltip__title">{title}</div>
    <div>{body}</div>
  </>
);

export const IconTooltip = ({
  title = '',
  body,
  iconType = 'information',
  className = '',
  isError = false
}: TooltipIcon) => (
  <AntTooltip
    title={<Tooltip title={title} body={body} />}
    overlayClassName={`c-tooltip c-tooltip__${className}`}
  >
    <Icon
      type={iconType}
      className="c-tooltip__icon"
      render={() => (
        <img src={isError ? InfoRedIcon : InfoIcon} alt="info icon" />
      )}
    />
  </AntTooltip>
);

export const TooltipWrapper = ({
  title,
  placement = 'bottom',
  mouseEnterDelay = 0.01,
  mouseLeaveDelay = 0.01,
  overlayClassName,
  showTooltip,
  overlay,
  children,
  ...props
}: TooltipWrapperInterface) => {
  if (!showTooltip) {
    return children;
  }
  return (
    <AntTooltip
      placement={placement}
      overlayClassName={overlayClassName}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      overlay={!!title ? <span>{title}</span> : overlay}
      {...props}
    >
      {children}
    </AntTooltip>
  );
};

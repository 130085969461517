import { funnels as defaultState } from './defaultStates';
import { FUNNELS, API, FUNNELGROUPS } from '../types';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import {
  FunnelGroupAction,
  FunnelAction,
  ReportingParamsAction
} from '../../types/redux/action';
import { FunnelStore, FunnelArray } from '../../types/redux/store';
import { Funnel } from '../../model/funnel';
import { AnyObject } from '../../types';
import { getReverseStatus } from 'utils/selectors';
import { FunnelGroupInfo } from 'model/funnelGroupInfo';
import { infoToResource } from 'utils/redux';

const data = (
  state = defaultState.data,
  action: FunnelAction | ReportingParamsAction | FunnelGroupAction
) => {
  switch (action.type) {
    case FUNNELS.SET_LIST:
      if (defined(action.payload) && Array.isArray(action.payload)) {
        return (action.payload as Funnel[]).reduce(
          (acc: FunnelArray, funnel) => {
            acc[funnel.idFunnel] = funnel;
            return acc;
          },
          {}
        );
      }
      return state;
    case FUNNELGROUPS.SET_INFO:
      if (defined(action.payload) && Array.isArray(action.payload)) {
        (action.payload as FunnelGroupInfo[]).forEach(funnelGroupInfo => {
          if (funnelGroupInfo.funnels) {
            funnelGroupInfo.funnels.forEach(funnelInfo => {
              if (!state[funnelInfo.idFunnel]) {
                state[funnelInfo.idFunnel] = infoToResource.funnel(
                  funnelInfo,
                  funnelGroupInfo
                );
              }
            });
          }
        });
      }
      return state;
    case FUNNELS.ADD_SINGLE:
    case FUNNELS.ADD_EXISTED_SINGLE:
      const payload = action.payload as Funnel;
      return {
        ...state,
        [payload.idFunnel]: payload
      };
    case FUNNELS.MOVE_LIST:
      const data = (action.payload as AnyObject).data;
      const moveIds = data.entries;
      const idCampaign = data.idCampaign;

      return Object.entries(state)
        .map(([id, item]: [string, Funnel]) => {
          if (moveIds.includes(item.idFunnel)) {
            return { ...item, idCampaign: idCampaign };
          }

          return item;
        })
        .reduce((acc: FunnelArray, item: any) => {
          return {
            ...acc,
            [item.idFunnel]: item
          };
        }, {} as FunnelArray);

    case FUNNELS.ARCHIVE_SINGLE:
      if (defined(action.payload)) {
        const funnelId = (action.payload as any).data;
        return {
          ...state,
          [funnelId]: {
            ...state[funnelId],
            status: getReverseStatus(state[funnelId]?.status!)
          }
        };
      }
      return state;
    case FUNNELS.DELETE:
      const deletePayload = (action as ReportingParamsAction).payload
        .data as string[];

      return Object.entries(state)
        .filter(
          ([id, item]: [string, Funnel]) =>
            !deletePayload.includes(item.idFunnel)
        )
        .reduce((acc: FunnelArray, item: any) => {
          return {
            ...acc,
            [item[1].idFunnel]: item[1]
          };
        }, {} as FunnelArray);
    case FUNNELS.UPDATE:
      const payloadForUpdate = action.payload as Funnel;
      return {
        ...state,
        [payloadForUpdate.idFunnel]: payloadForUpdate
      };
    default:
      return state;
  }
};

const loading = (state = defaultState.loading, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        isLoading: true
      };
    case API.ENDED:
      return {
        ...state,
        isLoading: false,
        fetchedAt: Date.now()
      };
    default:
      return state;
  }
};

const hasBuilderChangedData = (
  state = defaultState.hasBuilderChangedData,
  action: AnyAction
) => {
  switch (action.type) {
    case FUNNELS.SET_BUILDER_HAS_CHANGED_DATA:
      return action.payload;
    default:
      return state;
  }
};

const isCloseAllFunnelTabsModalOpen = (
  state = defaultState.isCloseAllFunnelTabsModalOpen,
  action: AnyAction
) => {
  switch (action.type) {
    case FUNNELS.SET_IS_CLOSE_ALL_TABS_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): FunnelStore => {
  return {
    data: data(state.data, action),
    loading: loading(state.loading, action),
    hasBuilderChangedData: hasBuilderChangedData(
      state.hasBuilderChangedData,
      action
    ),
    isCloseAllFunnelTabsModalOpen: isCloseAllFunnelTabsModalOpen(
      state.isCloseAllFunnelTabsModalOpen,
      action
    ),
  };
};

import { ConvertedHit } from 'model/models';
import { decimalValueOrZero } from '../number';
import { defined } from 'utils/define';

export const trimStringPropertiesInObject = <T>(
  object: T,
  props: (keyof T)[]
): T => {
  props.forEach((prop: keyof T) => {
    if (defined(object[prop]) && typeof object[prop] === 'string') {
      //@ts-ignore
      object[prop] = object[prop].trim();
    }
  });

  return object;
};

export const updateConversionTextAreaValues = (str: string): ConvertedHit => {
  // : position
  let colonPos = str.search(/:/) === -1 ? 0 : str.search(/:/);
  // , position
  let commaPos = str.search(/,/) === -1 ? 0 : str.search(/,/);
  // values
  let id = '';
  let transaction = '';
  let revenue = '';

  // find id
  if (colonPos !== 0) {
    id = str.slice(0, colonPos);
  } else {
    if (commaPos !== 0) {
      id = str.slice(0, commaPos);
    } else {
      id = str;
    }
  }

  // find transaction
  if (colonPos !== 0) {
    if (commaPos !== 0) {
      transaction = str.slice(colonPos + 1, commaPos).trim();
    } else {
      transaction = str.slice(colonPos + 1).trim();
    }
  }

  // find revenue
  if (commaPos !== 0) {
    for (const l of str.slice(commaPos, str.length).split('')) {
      const p: number = l.codePointAt(0) || 0;
      // find numbers and dot( . )
      if ((p >= 48 && p <= 57) || p === 46 || p === 45) {
        revenue += l;
      }
    }
  }

  const correctRevenue = decimalValueOrZero(revenue);
  return {
    id,
    transaction,
    revenue: correctRevenue !== '0' ? correctRevenue : undefined
  };
};

export const withoutWhiteSpace = (value: string) =>
  value
    .split('')
    .filter(x => x.match(/\S/))
    .join('');

export const filterAlphaNumericWithSpace = (value: string): string =>
  value
    .split('')
    .filter(char => char.match(/[a-zA-Z0-9\s]/))
    .join('');

export const filterAlphaNumeric = (value: string): string =>
  value
    .split('')
    .filter(char => char.match(/[a-zA-Z0-9]/))
    .join('');
    
export const getDayNameByValue = (value: string) => {
  switch (value) {
    case '1':
      return 'Monday';
    case '2':
      return 'Tuesday';
    case '3':
      return 'Wednesday';
    case '4':
      return 'Thursday';
    case '5':
      return 'Friday';
    case '6':
      return 'Saturday';
    case '7':
      return 'Sunday';
  }
};

// @todo we have 3 equal function for UpperCase first letter :) need to decrease
export const firstLetterToUppercase = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const ucFirst = (str: string) =>
  typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : '';

export const getUpperFirstLetter = (str: string) =>
  str.length > 0 ? str.charAt(0).toUpperCase() : str;

export const ucFirstEachWord = (str: string) =>
  str.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());

export const removeNonDigits = (value: string) =>
  value.replace(/[^0-9.]+/g, '');

export const fixNumStringToDecimals = (value: string = '', decimals: number) => {
  const match = value.match(`/\d+\.\d{0,${decimals}}/g`);
  return match ? match[0] : value;
};

export const removeCommaOrMultipleDot = (value: string) => {
  const val = value.replace(',', '');

  if (val.replace(/[^.]/g, '').length > 1) {
    return val.slice(0, val.length - 1);
  }

  return val;
};

export const countDecimals = (val: number) => {
  if(Math.floor(val) === val) return 0;
    return val.toString().split(".")[1].length || 0; 

};

export const removeMoreThenTwoNumbersAfterDot = (value: string) => {
  if (value.match(/\.[0-9]{3,}/gm)) {
    const match = value.match(/[0-9]*\.[0-9]{0,2}/gm);
    return defined(match) ? match[0] : value;
  }
  return value;
};

export const pluralize = (noun: string, count: number, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

export const appendedClass = (baseClass: string, className: string) =>
  !baseClass ? '' : `${baseClass}__${className}`;

export const cloudflareDNSFormatter = (value: string = '') => {
  return value.split('.')?.[0] || ''
};

export const replaceLastCharInPosition = (s: string, c: string, replacement = '') => {
  const lastIndex = s.lastIndexOf(c);
  if (lastIndex) {
    return s.slice(0, lastIndex) + replacement + s.slice(lastIndex + c.length);
  }
  return s;
}
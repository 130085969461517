import React from 'react';
import { CheckMarkButton } from 'components/Parts/Buttons';
import './style.scss';

const StateRenderer = (
  props: { showCheckMark: boolean; step: number; className?: string }
) => {
  return (
    <div className={`state-renderer ${props.className || ''}`}>
      {props.showCheckMark ? (
        <CheckMarkButton />
      ) : (
        <div className="step">{props.step}</div>
      )}
    </div>
  );
};

export default StateRenderer;

import { apiAction } from './api';
import { setTableCols } from './table';
import { TRAFFICSOURCES, VIEW } from '../types';
import { TRAFFICSOURCES as API, CATEGORIES } from 'api/axios';
import { shouldFetchReporting } from '../selectors';
import { TrafficSource } from '../../model/trafficSource';
import { Dispatch } from 'redux';
import { Category } from '../../model/category';
import { DeleteList } from '../../model/deleteList';
import { DrilldownReport } from '../../model/drilldownReport';
import { StatusEnum } from '../../api/types';
import { CompositeStore, TrafficSourceStore } from '../../types/redux/store';
import { makeNewRow, updatedRows } from 'utils/table';
import { DrilldownReportParams } from '../../model/drilldownReportParams';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { defined } from 'utils/define';
import { archiveCategoryRelatedEntities } from 'utils/redux';
import { clearTrafficSource } from '../../utils/model';
import { reportCategoriesToCategories } from 'utils/arrs';
import { TrafficSourceCategoryInfo } from 'model/trafficSourceCategoryInfo';

const setTrafficSourcesCategoryInfo = (payload: TrafficSourceCategoryInfo[] = []) => {
  return {
    type: TRAFFICSOURCES.SET_CATEGORY_INFO,
    payload
  };
};

const setTrafficSourcesList = (payload: TrafficSource[] = []) => {
  return {
    type: TRAFFICSOURCES.SET_LIST,
    payload
  };
};

const setTrafficSource = (payload: TrafficSource) => {
  return {
    type: TRAFFICSOURCES.ADD_SINGLE,
    payload
  };
};

const setExistedTrafficSource = (payload: TrafficSource) => {
  return {
    type: TRAFFICSOURCES.ADD_EXISTED_SINGLE,
    payload
  };
};

const setTrafficSourceRow = (data: DrilldownReportRow) => {
  return {
    type: TRAFFICSOURCES.ADD_SINGLE_ROW,
    payload: { data }
  };
};

const setCategoryList = (payload: Category[] = []) => {
  return {
    type: TRAFFICSOURCES.SET_CATEGORIES,
    payload
  };
};

const trafficSourceArchive = (data: string = '') => {
  return {
    type: TRAFFICSOURCES.ARCHIVE,
    payload: { data }
  };
};

const createCategories = (payload: Category) => {
  return {
    type: TRAFFICSOURCES.CREATE_CATEGORY,
    payload
  };
};

const trafficSourceDelete = (data: string[]) => {
  return {
    type: TRAFFICSOURCES.DELETE,
    payload: { data }
  };
};

const deleteCategory = (payload: string[] = []) => {
  return {
    type: TRAFFICSOURCES.DELETE_CATEGORY,
    payload
  };
};

const archiveCategory = (data: string) => {
  return {
    type: TRAFFICSOURCES.ARCHIVE_CATEGORY,
    payload: { data }
  };
};

const updateCategories = (payload: Category) => {
  return {
    type: TRAFFICSOURCES.UPDATE_CATEGORY,
    payload
  };
};

const setReportingsData = (data = {}, params: DrilldownReportParams) => {
  return {
    type: TRAFFICSOURCES.SET_REPORTING,
    payload: { data, params }
  };
};

const setShow = (payload: StatusEnum) => {
  return {
    type: TRAFFICSOURCES.SET_SHOW,
    payload
  };
};

const categoryEntitiesUpdate = (idCategory: string, categoryName: string) => {
  return {
    type: TRAFFICSOURCES.UPDATE_CATEGORY_ENTITIES,
    payload: {
      idCategory,
      categoryName
    }
  };
};

const trafficSourceUpdate = (data: TrafficSource) => {
  return {
    type: TRAFFICSOURCES.UPDATE_SINGLE,
    payload: data
  };
};

const trafficSourceUpdateReporting = (data: DrilldownReportRow[]) => {
  return {
    type: TRAFFICSOURCES.UPDATE_SINGLE_REPORTING,
    payload: { data }
  };
};

export const fetchTrafficSources = (status: StatusEnum = 'not-deleted') => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(status),
      onSuccess: (data: TrafficSource[]) => setTrafficSourcesList(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.FETCH_LIST
    })
  );
};

export const getTrafficSourcesByCategory = (idCategory: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FIND_BY_CATEGORY(idCategory),
      onSuccess: (pages: TrafficSource[]) => setTrafficSourcesList(pages),
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.FETCH_LIST
    })
  );
};

export const fetchTrafficSourcesCategoryInfo = (status: StatusEnum = 'all') => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_CATEGORY_INFO(status),
      onSuccess: (list: TrafficSourceCategoryInfo[]) => setTrafficSourcesCategoryInfo(list),
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.FETCH_CATEGORY_INFO
    })
  );
};

export const fetchCategories = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: CATEGORIES.FETCH_LIST('trafficsources'),
      onSuccess: (data: Category[]) => setCategoryList(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.FETCH_CATEGORIES
    })
  );
};

export const addNewTrafficSource = (data: TrafficSource) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.CREATE(clearTrafficSource(data)),
      onSuccess: () => {
        dispatch(setTrafficSource(data));
        return setTrafficSourceRow(makeNewRow(data, 'trafficsources'));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.ADD_SINGLE_ROW
    })
  );
};

export const getTrafficSourceById = (idTrafficSource: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.GET(idTrafficSource),
      onSuccess: (trafficSource: TrafficSource) =>
        setExistedTrafficSource(trafficSource),
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.ADD_SINGLE
    })
  );
};

export const fetchReportings = (params: DrilldownReportParams) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  if (shouldFetchReporting(getState().trafficsources)) {
    return dispatch(
      apiAction({
        requestConfig: API.FETCH_REPORTING(params),
        onSuccess: (data: DrilldownReport) => {
          dispatch(setReportingsData(data, params));
          dispatch(
            setCategoryList(
              reportCategoriesToCategories(data.entities?.categories || [])
            )
          );
        },
        onFailure: (e: Error) => {
          throw e;
        },
        label: TRAFFICSOURCES.FETCH_REPORTING
      })
    );
  }
};

export const updateTrafficSource = (data: TrafficSource) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const reportingRows = getState().trafficsources.reporting.rows;
  const category = getState().trafficsources.categories.find(
    item => defined(data.idCategory) && item.idCategory === data.idCategory
  );
  return dispatch(
    apiAction({
      requestConfig: API.UPDATE(clearTrafficSource(data)),
      onSuccess: () => {
        if (defined(category) && defined(data.idCategory)) {
          dispatch(
            categoryEntitiesUpdate(data.idCategory, category.categoryName)
          );
        }
        updatedRows({
          id: data.idTrafficSource,
          newName: data.trafficSourceName,
          newCategoryID: data.idCategory,
          rows: reportingRows
        }).then((res: any) => {
          dispatch(trafficSourceUpdate(data));
          dispatch(trafficSourceUpdateReporting(res));
        });
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.UPDATE_SINGLE
    })
  );
};

export const duplicateTrafficSource = (oldData: TrafficSource) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.DUPLICATE(clearTrafficSource(oldData)),
      onSuccess: (data: TrafficSource) => {
        dispatch(setTrafficSource(data));
        return setTrafficSourceRow(makeNewRow(data, 'trafficsources'));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.ADD_SINGLE_ROW
    })
  );
};

export const setTrafficSourceTableCols = (cols: []) => (dispatch: Dispatch) => {
  return dispatch(setTableCols(cols, VIEW.TRAFFICSOURCES));
};

export const deleteTrafficSource = (data: DeleteList) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => trafficSourceDelete(data.entries),
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.DELETE
    })
  );
};

export const archiveTrafficSource = (data: TrafficSource) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.ARCHIVE(clearTrafficSource(data)),
      onSuccess: () => trafficSourceArchive(data.idTrafficSource),
      onFailure: (e: Error) => {
        throw e;
      },
      label: TRAFFICSOURCES.ARCHIVE
    })
  );
};

export const updateTrafficSourceCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.UPDATE(data),
    onSuccess: () => updateCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: TRAFFICSOURCES.ADD_SINGLE_CATEGORY
  });
};

export const dispatchUpdateTrafficSourceCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(updateTrafficSourceCategories(data));
};

export const createTrafficSourceCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.CREATE(data),
    onSuccess: () => createCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: TRAFFICSOURCES.ADD_SINGLE_CATEGORY
  });
};

export const dispatchCreateTrafficSourceCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(createTrafficSourceCategories(data));
};

export const deleteTrafficSourceCategory = (data: DeleteList) => {
  return apiAction({
    requestConfig: CATEGORIES.DELETE(data),
    onSuccess: () => deleteCategory(data.entries),
    onFailure: (e: Error) => {
      throw e;
    },
    label: TRAFFICSOURCES.DELETE_SINGLE_CATEGORY
  });
};

export const dispatchDeleteTrafficSourceCategory = (data: DeleteList) => (
  dispatch: Dispatch
) => {
  return dispatch(deleteTrafficSourceCategory(data));
};

export const setTrafficSourceShow = (show: StatusEnum) => (
  dispatch: Dispatch
) => {
  return dispatch(setShow(show));
};

export const archiveTrafficSourceCategory = (
  data: Category,
  offerSourceStore?: TrafficSourceStore,
  dispatch?: Dispatch
) => {
  return apiAction({
    requestConfig: CATEGORIES.ARCHIVE(data),
    onSuccess: () => {
      archiveCategoryRelatedEntities(
        dispatch!,
        offerSourceStore!,
        data,
        archiveTrafficSource
      );
      return archiveCategory(data.idCategory);
    },
    onFailure: (e: Error) => {
      throw e;
    },
    label: TRAFFICSOURCES.ARCHIVE_SINGLE_CATEGORY
  });
};

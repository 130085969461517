import React from 'react';
import { Label, Input, Select } from '../Parts/Inputs';
import validateForm, { getErrorId } from 'utils/validation';
import { ReactiveValidateComponent, ReactiveValidateState } from 'utils/reactive/generic';
import { AnyObject, OptionCategory } from 'types';
import { SwitchButton } from 'components/Parts/Buttons';
import { ValidationRule } from 'utils/validation/types';
import { defined, definedObject } from 'utils/define';
import { CustomEventSettingsTemplate } from 'types/customEvents';
import { removeCommaOrMultipleDot, removeNonDigits } from 'utils/string';
import { VisibilityWrapper } from 'uikit';
import './style.scss';

interface Props {
  template: CustomEventSettingsTemplate;
  onChange: (data: { [key: string]: boolean | string }, postbackUrl: string) => void;
  isTouchedForm: boolean;
  initialData: any;
  setCustomScenarioHasError: (value: boolean) => void;
  className?: string;
}

interface State extends ReactiveValidateState {
  model: { [key: string]: boolean | string };
  validationErrors: AnyObject;
}

class CustomScenario extends ReactiveValidateComponent<Props, State> {
  state: State = {
    model: {},
    validationErrors: {},
    isTouchedForm: false
  };

  static defaultProps: Partial<Props> = {
    className: ''
  };

  async componentDidUpdate(prevProps: Props, _: State) {
    if (prevProps.isTouchedForm !== this.props.isTouchedForm && this.props.isTouchedForm) {
      this.setTouchedForm();
      this.validate();
    }
  }

  async componentDidMount() {
    await this.handleStateValuesChange('model', this.props.initialData);
    await this.initializeStateModelData();
    await this.props.onChange(this.state.model, this.getPostbackURL());
    if (this.props.isTouchedForm) {
      this.setTouchedForm();
      this.validate();
    }
  }

  initializeStateModelData = async () => {
    await this.handleStateValuesChange('model', {
      ...Object.values(this.props.template.fields).reduce(
        (acc, crr) => ({
          ...acc,
          [crr.key]: this.state.model?.[crr.key] || (crr.element === 'switch' ? false : '')
        }),
        {}
      )
    });
  };

  handleSetModelData = async (key: string, value: any) => {
    await this.setState(
      state => ({
        ...state,
        model: {
          ...state.model,
          [key]: value
        }
      }),
      this.validate
    );
    await this.props.onChange(this.state.model, this.getPostbackURL());
  };

  handleStateValuesChange = <T extends State>(key: keyof T, value: T[keyof T]) => {
    this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  validationRules = (): ValidationRule[] =>
    this.props.template.fields
      .filter(field => field.validations && (defined(field.show) ? field.show(this.state.model) : true))
      .map(field => ({
        field: field.key,
        validations: field.validations || []
      }));

  setCustomScenarioHasError = () => this.props.setCustomScenarioHasError(definedObject(this.state.validationErrors));

  validate = async () => {
    await this.setCustomScenarioHasError();
    if (this.state.isTouchedForm) {
      await this.handleStateValuesChange('validationErrors', {});
      const [validationErrors] = validateForm(
        {
          ...this.state.model
        },
        this.validationRules()
      );
      await this.handleStateValuesChange('validationErrors', validationErrors);
      await this.setCustomScenarioHasError();
    }
  };

  validateClassName = (field: string) => {
    return this.state.validationErrors[field] ? 'has-error' : '';
  };

  validateTooltip = (field: string) => {
    return this.state.validationErrors[field] ? this.state.validationErrors[field].join(', ') : '';
  };

  getPostbackURL = () => {
    return this.props.template.postbackURL(this.state.model);
  };

  render() {
    if (!this.props.template) return null;
    return (
      <div className={`c-customScenario ${this.props.className}`}>
        {this.props.template.fields.map(field =>
          field.element === 'switch' ? (
            <SwitchButton
              id={field.key}
              key={field.key}
              className="c-customScenario__switch"
              checked={this.state.model?.[field.key]}
              onSwitchClicked={(value: boolean) => this.handleSetModelData(field.key, value)}
              disabled={field.disabled}
              labelName={field.label}
            />
          ) : field.element === 'select' ? (
            <div key={field.key} id={getErrorId(field.key)} className={`c-customScenario__inputGroup ${this.validateClassName(field.key)}`}>
              <Label text={field.label} htmlFor={field.key} />
              <Select
                showSearch={true}
                groupOptions={true}
                value={this.state.model?.[field.key] as string}
                options={field.options || []}
                id={field.key}
                valueGetter={(option: OptionCategory) => option.id}
                labelGetter={(option: OptionCategory) => option.name}
                groupBy="category"
                placeholder={field.placeholder}
                onChange={(value: string) => this.handleSetModelData(field.key, value)}
              />
            </div>
          ) : (
            <>
              <VisibilityWrapper visible={!field.show || field.show(this.state.model)}>
                <div
                  key={field.key}
                  id={getErrorId(field.key)}
                  className={`c-customScenario__inputGroup ${this.validateClassName(field.key)}`}
                >
                  <Label text={field.label} htmlFor={field.key} />
                  <Input
                    id={field.key}
                    error={this.validateTooltip(field.key)}
                    name={field.key}
                    placeholder={field.placeholder}
                    value={this.state.model?.[field.key] as string}
                    onChange={e =>
                      this.handleSetModelData(
                        field.key,
                        field.type === 'number' ? removeCommaOrMultipleDot(removeNonDigits(e.target.value)) : e.target.value
                      )
                    }
                  />
                </div>
              </VisibilityWrapper>
            </>
          )
        )}
      </div>
    );
  }
}

export default CustomScenario;

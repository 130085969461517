import { createContext } from 'react';
import { MessageProps } from 'types';

export interface MessagesState extends MessageProps {
  visibleMessages: string[];
  currentAction: {
    id: string;
    type: any;
    payload: any;
  };
  messages: {
    [key: string]: Function
  }
}

export const defaultMessagesState: MessagesState = {
  currentAction: {
    id: '',
    type: undefined,
    payload: undefined
  },
  visibleMessages: [],
  showMessage: () => {},
  messages: {}
};

const { Provider, Consumer } = createContext(defaultMessagesState);

export const MessagesProvider = Provider;
export const MessagesConsumer = Consumer;

import { CategoricalSelectOptions, TimeRangeKeys } from 'types';
import { decamelize } from 'utils/regexp';
import { defaultDrilldownReportEvents, orderedAttributes } from '../../constants/reportings';
import { Attribute } from '../../model/attribute';
import { DrilldownReportRowAttribute } from 'model/drilldownReportRowAttribute';
import { DrilldownReportRow } from 'model/drilldownReportRow';
import { RestrictData, ShareReportingUrlParams } from 'types/reporting';
import { defined } from '../define';
import { TrafficSourceArray } from '../../types/redux/store';
import { DrilldownReportFilterParam, DrilldownReportParams } from 'model/drilldownReportParams';
import { AttributeData } from 'model/attributeData';

export const makeCategoricalAttributesList = (list: { name: string; value?: string; category?: string }[]): CategoricalSelectOptions[] => {
  return list
    .filter(item => item.value !== Attribute.URLTrackingField)
    .map(item => {
      if (defined(item.value) && defined(item.category)) {
        return {
          id: item.value,
          name: item.name,
          category: item.category,
          value: item.value
        };
      }
      return {
        id: item.name,
        name: item.name.slice(item.name.search(/:/) + 2, item.name.length),
        category: item.name.slice(0, item.name.search(/:/)),
        value: decamelize(item.name.replace(/[: ]+/g, ''))
      };
    });
};

export const makeOrderedCategoricalAttributesList = (): CategoricalSelectOptions[] => {
  const result: CategoricalSelectOptions[] = [];
  Object.keys(orderedAttributes).forEach((groupName: string) => {
    Object.keys(orderedAttributes[groupName]).forEach((name: string) => {
      const payload = orderedAttributes[groupName][name];
      result.push({
        id: name,
        name: name.split(':')?.[1] || name,
        category: groupName,
        payload: payload
      } as CategoricalSelectOptions);
    });
  });
  return result;
};

export const makeReportingRowData = (attributes: DrilldownReportRowAttribute[], date = ''): DrilldownReportRow & { date?: string } => ({
  attributes: attributes,
  conversions: 0,
  cost: 0,
  indirectConversions: 0,
  indirectRevenue: 0,
  landerClicks: 0,
  landerClicksUnique: 0,
  landerViews: 0,
  landerViewsUnique: 0,
  nodeViews: 0,
  nodeViewsUnique: 0,
  offerClicks: 0,
  offerClicksUnique: 0,
  offerViews: 0,
  offerViewsUnique: 0,
  revenue: 0,
  visitors: 0,
  visits: 0,
  customEvents: defaultDrilldownReportEvents,
  date
});

export const reportingGetProfitAndLoss = (row: DrilldownReportRow) => row.revenue + row.indirectRevenue - row.cost;

export const reportingGetReturnOnInvestment = (row: DrilldownReportRow) =>
  ((row.indirectRevenue + row.revenue - row.cost) / row.cost) * 100;

export const reportingGetCVR = (row: DrilldownReportRow, conversionMultiplyer = 1) =>
  (row.conversions * conversionMultiplyer) / row.nodeViews;

export const convertDrilldownReportParamsToQuery = (data: ShareReportingUrlParams) => {
  const queryString =
    '?' +
    new URLSearchParams({
      attributes: btoa(JSON.stringify(data.drilldownReportParams.attributes)),
      timeStart: btoa(data.drilldownReportParams.timeStart),
      timeEnd: btoa(data.drilldownReportParams.timeEnd),
      filters: btoa(JSON.stringify(data.drilldownReportParams.filters || [])),
      showAttributes: btoa(data.showAttributes.toString()),
      customFields: btoa(data.customFields.toString()),
      rawCustomFields: btoa(data.rawCustomFields.toString()),
      timeZone: btoa(data.timeZone),
      initialInlineFilters: btoa(JSON.stringify(data.initialInlineFilters)),
      dateRangeName: btoa(data.dateRangeName),
      useEntranceTime: btoa(String(data.drilldownReportParams.useEntranceTime)),
    }).toString();
  return queryString;
};

export const convertUrlToDrilldownReportParams = (url: string): ShareReportingUrlParams => {
  const params = new URLSearchParams(url);
  const result: ShareReportingUrlParams = {
    drilldownReportParams: {
      attributes: [],
      timeStart: '',
      timeEnd: '',
      filters: [],
      useEntranceTime: false,
    },
    showAttributes: [],
    customFields: [],
    rawCustomFields: [],
    timeZone: '',
    initialInlineFilters: {},
    dateRangeName: ''
  };

  try {
    result.drilldownReportParams.attributes = JSON.parse(atob(params.get('attributes')!)) as AttributeData[];
    result.drilldownReportParams.timeStart = atob(params.get('timeStart')!);
    result.drilldownReportParams.timeEnd = atob(params.get('timeEnd')!);
    result.drilldownReportParams.filters = JSON.parse(atob(params.get('filters')!));
    result.drilldownReportParams.useEntranceTime = atob(params.get('useEntranceTime')!) === 'true';
    result.initialInlineFilters = JSON.parse(atob(params.get('initialInlineFilters')!));
    result.dateRangeName = atob(params.get('dateRangeName')!);
    result.showAttributes = atob(params.get('showAttributes')!)
      .split(',')
      .filter(a => a);
    result.customFields = atob(params.get('customFields')!)
      .split(',')
      .filter(a => a);
    result.rawCustomFields = atob(params.get('rawCustomFields')!)
      .split(',')
      .filter(a => a);
    result.timeZone = atob(params.get('timeZone')!);
    return result;
  } catch {
    return result;
  }
};

export const hasTrafficSourceSlots = (trafficSourceId: string, trafficSources: TrafficSourceArray) =>
  defined(trafficSources) && defined(trafficSources[trafficSourceId]) && defined(trafficSources[trafficSourceId].trackingFieldSlots);

export const getTrafficSourceSlots = (
  trafficSourceId: string,
  trafficSources: TrafficSourceArray
): { key: string; value: string; trafficSourceId: string }[] => {
  return hasTrafficSourceSlots(trafficSourceId, trafficSources)
    ? Object.entries(trafficSources[trafficSourceId].trackingFieldSlots!)
        .filter(([key, _]) => !['campaign', 'external'].includes(key))
        .map(([key, value]) => {
          return { key, value: value.name, trafficSourceId };
        })
    : ([] as any);
};

export const convertFilterValuesByRestrictDataModel = (
  restrictData: RestrictData,
  filterValues: DrilldownReportParams
): DrilldownReportParams => {
  const fieldsData = Object.keys(restrictData.fieldsData);
  const drilldownReportParams: DrilldownReportParams = { ...filterValues, filters: [] };

  for (const key of fieldsData) {
    const addToWhiteList = !!restrictData.switches[key];
    let value = restrictData.fieldsData[key]?.value;
    const attribute = restrictData.fieldsData[key]?.attribute!;
    const hasValue = typeof value === 'string' ? !!value : Array.isArray(value) && value.length > 0 && value[0] !== '' ? true : false;

    if (hasValue) {
      let filter: AttributeData = { attribute };
      if (attribute === Attribute.URLTrackingField) {
        if (Array.isArray(restrictData.fieldsData[key]?.value) && restrictData.fieldsData[key]?.value?.[0]?.length! > 0) {
          value = restrictData.fieldsData[key]?.value.map(val => `{"campaign":"${val}"}`);
        } else {
          value = ['{"campaign":"*"}'];
        }
      }

      if (addToWhiteList) {
        filter.whitelistFilters = value;
      } else {
        filter.blacklistFilters = value;
      }

      drilldownReportParams.filters!.push(filter);
    }
  }

  return drilldownReportParams;
};

export const convertFiltersToRestrictData = (filters: DrilldownReportFilterParam): RestrictData => {
  const restrictData: RestrictData = {
    fieldsData: {},
    switches: {}
  };

  for (const filter of filters) {
    let value = (filter.blacklistFilters || filter.whitelistFilters)!;

    if (filter.attribute === Attribute.URLTrackingField) {
      value = value.filter(val => !!val.match(/\b(?!campaign\b)[a-z0-9]+\b/g)).map(val => val.match(/\b(?!campaign\b)[a-z0-9]+\b/g)) as any;
    }

    if (filter.blacklistFilters) {
      restrictData.switches[filter.attribute!] = false;
      restrictData.fieldsData[filter.attribute!] = { attribute: filter.attribute!, value };
    }
    if (filter.whitelistFilters) {
      restrictData.switches[filter.attribute!] = true;
      restrictData.fieldsData[filter.attribute!] = { attribute: filter.attribute!, value };
    }
  }

  return restrictData;
};

// in some cases f.e if we using Date attributes we should change full-date (2020-08-14T00:00:00+05:00) string to simple format(2020-08-14)
export const convertFilterValues = (value: string) => {
  const regExp = /([0-9]{4}-[0-9]{2}-[0-9]{2})T[0-9]{2}:[0-9]{2}:[0-9]{2}\+[0-9]{2}:[0-9]{2}/gm;

  if (regExp.test(value)) {
    return value.split('T')[0];
  }

  return value;
};

export const getIDsFromFilter = (filters: DrilldownReportFilterParam, attribute: Attribute): AttributeData => {
  const data = (filters || []).find(item => item.attribute === attribute);
  return {
    attribute: attribute,
    whitelistFilters: data?.whitelistFilters || [],
    blacklistFilters: data?.blacklistFilters || []
  };
};

export const extractWhiteListFilterKey = (whiteListFilter = '') => {
  const matched = whiteListFilter.match(/[a-zA-Z0-9]/g);
  if (!matched) {
    return '';
  }
  return matched.join('');
};

export const makeGoogleAdsRowCompatible = (rowData: DrilldownReportRow, rows: DrilldownReportRow[]) => {
  return rows.map(row => {
    row.attributes.map((attribute, index) => {
      attribute.value = rowData.attributes?.[index]?.value || attribute.value;
      attribute.googleAdsStatus = rowData.attributes?.[index]?.googleAdsStatus || attribute.googleAdsStatus;
      return attribute;
    });
    return row;
  });
};

export const makeFacebookAdsRowCompatible = (rowData: DrilldownReportRow, rows: DrilldownReportRow[]) => {
  return rows.map(row => {
    row.attributes.map((attribute, index) => {
      attribute.value = rowData.attributes?.[index]?.value || attribute.value;
      attribute.facebookAdsStatus = rowData.attributes?.[index]?.facebookAdsStatus || attribute.facebookAdsStatus;
      return attribute;
    });
    return row;
  });
};

const aliasKeys: { [key: string]: number } = Array(10)
  .fill(0)
  .reduce((acc, _, index) => {
    acc[`CE${index + 1}`] = index + 1;
    acc[`Custom Event ${index + 1}`] = index + 1;
    acc[`Event ${index + 1}`] = index + 1;
    acc[`E${index + 1}`] = index + 1;
    return acc;
  }, {});

export const replaceShortAliases = (
  input: string = '',
  customEventAliases: { [key: number]: { alias: string; shortAlias: string } } = {}
): string => {
  const regex = /(CE\d+)|(E\d+)/g;
  return input.replace(regex, match => {
    const aliasKey = aliasKeys[match];
    return customEventAliases?.[aliasKey]?.shortAlias ? customEventAliases[aliasKey].shortAlias : match;
  });
};

export const replaceAliases = (
  input: string = '',
  customEventAliases: { [key: number]: { alias: string; shortAlias: string } } = {}
): string => {
  const regex = /(Custom Event \d+)|(Event \d+)/g;
  return input.replace(regex, match => {
    const aliasKey = aliasKeys[match];
    return customEventAliases?.[aliasKey]?.alias ? customEventAliases[aliasKey].alias : match;
  });
};

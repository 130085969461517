import { Tag, TagProps } from 'antd';
import React, { PropsWithChildren } from 'react';

export default ({
  children,
  fill,
  color
}: PropsWithChildren<TagProps> & {
  fill?: boolean;
}) => (
  <Tag color={color} style={{ width: fill ? '100%' : undefined, textAlign: 'center', borderRadius: 4 }}>
    {children}
  </Tag>
);

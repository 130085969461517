import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import ConfirmForm from 'components/Forms/Confirm';
import { archiveLander } from 'redux/actions/landers';
import { ModalsConsumer } from '../context';
import { LANDER_ARCHIVE_MODAL } from 'constants/modal';
import { ModalProps } from '../types';
import { landerArchiveModalProps } from 'types/ModalForms/landerArchive';
import { FormContextModal } from '../../../types/modal';
import { getNameModal } from '../../../utils/modals';
import { LanderModalSelector } from 'redux/selectors';
import { getLanderById } from '../../../redux/actions/landers';
import { AnyObject } from 'types';

const ArchiveText = () => (
  <div className="margin-bottom-15 color-465565 flex flex-col flex-gap-5">
    <strong>
      Archiving a lander will remove it from the active view on this page.
    </strong>
    <p>
      This will not break any existing tracking or funnels, but will make the
      lander unavailable to add to funnels/groups. The lander will still appear
      in reporting as is.
    </p>
    <p>
      You can view all archived landers/categories by toggling the show archived
      setting on this page.
    </p>
    <p>
      Archiving a lander is reversible and will not break funnels, use this
      function to soft-remove a lander that you no longer plan to use in new
      funnels.
    </p>
    <strong>
      Are you sure you want to archive this lander?
    </strong>
  </div>
);

const LanderArchiveModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  contextModal,
  landers,
  getLanderById
}: landerArchiveModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    destroyOnClose
    width={422}
    title={`Archive Lander ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['lander-archive']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ConfirmForm
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        prepareData={(context: FormContextModal) => ({
          ...landers[context.entityId!],
          status: 'archived'
        })}
        contextModal={contextModal}
        text={<ArchiveText />}
        successMessage={(context: FormContextModal) =>
          `${context.data.value} has been archived`
        }
        fetchEntity={() => getLanderById(contextModal!.entityId!)}
      />
    )}
  />
);

const ConnectedLanderArchiveModal = ({ archiveLander, landers, getLanderById }: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <LanderArchiveModal
            isOpen={isOpenModal(LANDER_ARCHIVE_MODAL)}
            onSubmit={archiveLander}
            closeSelf={() => closeModal(LANDER_ARCHIVE_MODAL)}
            contextModal={
              getContextModal(LANDER_ARCHIVE_MODAL) as FormContextModal
            }
            landers={landers}
            getLanderById={getLanderById}
          />
        </>
      )}
    </ModalsConsumer>
  )
}

export default connect(LanderModalSelector, { archiveLander, getLanderById })(
  ConnectedLanderArchiveModal
);

import React, { PropsWithChildren } from 'react';
import Icon from 'components/Icons';
import ReactPortal from 'components/Portal';
import './style.scss';
import {
  OpenDynamicSideBar,
  CloseDynamicSideBar,
  SetButtonGroupProps,
  SetForCopyIdsProps,
  SetSidebarLoading,
  SetTabsError,
  SetTabDisabledState,
  ForCopyIdsBySidebarKey,
  ResetDisabledTabs,
  SidebarRefreshProps,
  SidebarSetRefreshProps,
  SidebarDateTimePickerProps,
  SidebarSetDateTimePickerProps,
  ShowTabs
} from 'types/dynamicSideBar';
import Messages from 'components/Messages';
import { ModalButtonGroup } from 'components/Parts/Groups';
import { Loading } from 'types/loading';
import { defined, definedObject } from 'utils/define';
import { ModalButtonGroupProps } from 'types/buttonGroup';
import { conditionalClass } from 'conditional-class';
import { MessageProps } from '../../types';
import { LoadingTooltip } from 'components/Loading';
import batchedUpdates from 'utils/state/batchedUpdates';
import { SidebarContext } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import { FFIcon, VisibilityWrapper } from 'uikit';
import { Refresh } from 'uikit/components/IconedButton';
import { FFDatepicker } from 'uikit';

interface Tab {
  title: string;
  icon?: React.ReactElement;
  highlightable?: boolean;
  isHidden?: boolean;
  header?: JSX.Element;
}

interface TabWithKey extends Tab {
  key: string;
}

interface State {
  openSideBars: string[];
  activeTab: string;
  activeSidebar: string;
  buttonGroupProps: {
    [sidebarKey: string]: ModalButtonGroupProps;
  };
  isLoading: {
    [sidebarKey: string]: boolean;
  };
  zIndex: number;
  highlightTabs: boolean;
  forCopyIds: ForCopyIdsBySidebarKey;
  tabsWithError: string[];
  disabledTabs: { [key: string]: boolean };
  refreshProps: SidebarRefreshProps;
  dateTimePickerProps: SidebarDateTimePickerProps;
  showTabs: boolean;
}
interface SideBar {
  key: string;
  wrapperClassName?: string;
  title?: string | JSX.Element;
  tabs?: {
    [key: string]: Tab;
  };
  render: (props: {
    open: OpenDynamicSideBar;
    close: CloseDynamicSideBar;
    activeTab: string;
    setButtonGroupProps: SetButtonGroupProps;
    setForCopyIdsProps: SetForCopyIdsProps;
    setLoading: SetSidebarLoading;
    setTabsError: SetTabsError;
    setTabDisabledState: SetTabDisabledState;
    resetDisabledTabs: ResetDisabledTabs;
    showTabs: ShowTabs;
    headerComponent: JSX.Element;
    sidebarLoading: boolean;
    tabTitle: string;
    setRefreshProps: SidebarSetRefreshProps;
    setDateTimePickerProps: SidebarSetDateTimePickerProps;
  }) => any;
  bodyClassName?: string;
  width?: number;
  hasHeader?: boolean;
  showReload?: boolean;
  showDateTimePicker?: boolean;
}
interface Props extends MessageProps {
  sideBars: SideBar[];
  defaultOpenSideBarKey: string;
  defaultActiveTab?: string;
  isOpen: boolean;
  onClose(): void;
  hasTab?: boolean;
  zIndex?: number;
  dataPortalKey: string;
  loading?: Loading;
  className?: string;
  offsetRight?: number;
  showCloseIcon?: boolean;
}

const defaultButtonGroupProps: ModalButtonGroupProps = {
  showCancel: false,
  showSave: false,
  showSaveAndCreate: false,
  saveAndCreateText: 'Save & Create New',
  saveText: 'Save record',
  onSaveAndCreateClicked: () => {},
  onCancelClicked: () => {},
  onSaveClicked: () => {},
  cancelText: 'Cancel'
};

class DynamicSideBar extends React.Component<PropsWithChildren<Props>, State> {
  state: State = {
    openSideBars: [],
    activeTab: '',
    activeSidebar: '',
    buttonGroupProps: {},
    isLoading: {},
    zIndex: this.props.zIndex!,
    highlightTabs: false,
    forCopyIds: {},
    tabsWithError: [],
    disabledTabs: {},
    refreshProps: {
      onClick: () => {}
    },
    dateTimePickerProps: {},
    showTabs: false
  };
  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  static defaultProps: Partial<Props> = {
    zIndex: 1,
    offsetRight: 0,
    showCloseIcon: false
  };

  getSidebarsContext = (): SidebarProps => this.context;

  componentDidMount() {
    if (this.props.isOpen) {
      this.open(this.props.defaultOpenSideBarKey);
    }
  }

  async componentDidUpdate(prevProps: Props, _: State) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.open(this.props.defaultOpenSideBarKey);
        if (this.props.defaultActiveTab) {
          this.handleSetState('activeTab', this.props.defaultActiveTab);
        }
        this.handleSetState('zIndex', this.props.zIndex!);
      } else {
        this.onClose(this.state.activeSidebar, false);
        setTimeout(() => {
          this.handleSetState('zIndex', this.props.zIndex!);
        }, 700);
      }
    }
  }

  handleSetState = async <T extends State, P extends keyof T>(
    name: P,
    value: T[P]
  ) => {
    await this.setState((state: State) => ({
      ...state,
      [name]: value
    }));
  };

  onClose = async (key: string, callOnClose = true) => {
    this.handleSetState('forCopyIds', {
      ...this.state.forCopyIds,
      [key]: {}
    });
    const sidebar = this.props.sideBars.find(s => s.key === key);
    Object.keys(sidebar?.tabs || []).forEach(tabKey => {
      this.handleSetState('disabledTabs', {
        ...this.state.disabledTabs,
        [tabKey]: false
      });
    });

    if (callOnClose) {
      // if there is just one sidebar, then close all
      if (this.state.openSideBars.length === 1) {
        await this.props.onClose();
      }
    }
    batchedUpdates(() => {
      const openSideBars = this.state.openSideBars.filter(item => item !== key);
      this.handleSetState('openSideBars', openSideBars);
      this.handleSetState(
        'activeSidebar',
        openSideBars[openSideBars.length - 1]
      );
    });
  };

  isOpen = (key: string) => this.state.openSideBars.indexOf(key) >= 0;

  open: OpenDynamicSideBar = async (key: string) => {
    if (!this.isOpen(key)) {
      batchedUpdates(() => {
        this.handleSetState('openSideBars', [...this.state.openSideBars, key]);
        this.handleSetState('activeSidebar', key);
        this.handleSetState('showTabs', !!this.props.hasTab);
      });
    }
  };

  setLoading: SetSidebarLoading = (loading: boolean) =>
    this.handleSetState('isLoading', {
      ...this.state.isLoading,
      [this.state.activeSidebar]: loading
    });

  getTranslateX = (key: string) => {
    const numberOfOpenSidebars =
      this.getSidebarsContext().numberOfOpenSidebars() +
      this.state.openSideBars.length -
      2;

    if (!this.isOpen(key)) {
      return 'translateX(150%)';
    }

    let val = 0;
    if (this.state.openSideBars.length > 1) {
      const openSideBars = [...this.state.openSideBars];
      openSideBars.pop();
      val = -(
        (openSideBars.indexOf(key) + 1) * 75 +
        (numberOfOpenSidebars - 1) * 75
      );
    } else {
      val = -this.props.offsetRight!;
    }

    return `translateX(${val}px)`;
  };

  getTabsOfActiveSidebar = (sidebarKey: string): TabWithKey[] => {
    const tabs = Object.entries(
      this.props.sideBars.find(s => s.key === sidebarKey)?.tabs || []
    )
      .map(([key, value]) => ({
        ...value,
        key
      }))
      .filter(tab => !tab.isHidden);

    if (tabs.length <= 1) {
      return [];
    }
    return tabs;
  };

  getZIndex = (sideBarKey: string, forOverlay: boolean) =>
    this.state.openSideBars.indexOf(sideBarKey) +
    (this.props.zIndex || 0) +
    (forOverlay ? 0 : 1);

  copyPassword = (name: string, id: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = id;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    this.props.showMessage(
      Messages.success(`${name} has been copied to clipboard`)
    );
  };

  onCancelClicked = async (sideBar: SideBar) => {
    const btnProps = this.state.buttonGroupProps[sideBar.key];
    try {
      if (btnProps.onCancelClicked) {
        await btnProps.onCancelClicked();
      }
      this.onClose(sideBar.key);
    } catch (e) {}
  };

  setTabsError: SetTabsError = (tabs: string[]) => {
    this.handleSetState('tabsWithError', tabs);
    setTimeout(() => {
      this.handleSetState('tabsWithError', []);
    }, 1000);
  };

  setRefreshProps = (props: SidebarRefreshProps) => {
    this.handleSetState('refreshProps', {
      ...this.state.refreshProps,
      ...props
    });
  };

  setDateTimePickerProps = (props: SidebarDateTimePickerProps) => {
    this.handleSetState('dateTimePickerProps', {
      ...this.state.dateTimePickerProps,
      ...props
    });
  };

  setButtonGroupProps = (data: ModalButtonGroupProps, sidebarKey: string) => {
    this.handleSetState('buttonGroupProps', {
      ...this.state.buttonGroupProps,
      [sidebarKey]: {
        ...(this.state.buttonGroupProps?.[sidebarKey] || {}),
        ...data
      }
    });
  };

  setTabDisabledState = (tabKey: string, disabled = true) => {
    this.handleSetState('disabledTabs', {
      ...this.state.disabledTabs,
      [tabKey]: disabled
    });
  };

  resetDisabledTabs = (tabKey?: string) => {
    this.handleSetState(
      'disabledTabs',
      tabKey ? { ...this.state.disabledTabs, [tabKey]: false } : {}
    );
  };

  showTabs = () => this.handleSetState('showTabs', true);

  render() {
    return (
      <ReactPortal
        className="c-dynamicSidebar__portal"
        dataPortalKey={this.props.dataPortalKey}
        zIndex={this.state.zIndex}
      >
        <LoadingTooltip
          show={!!this.state.isLoading[this.state.activeSidebar]}
        />
        {this.props.sideBars.map((sideBar, idx) => (
          <div
            key={sideBar.key}
            className={this.props.className || `dynamicSidebar__${sideBar.key}`}
            style={{ zIndex: this.state.zIndex + idx }}
          >
            <div
              className={conditionalClass('c-dynamicSidebar__overlay', {
                'c-dynamicSidebar__overlay--hidden': !this.isOpen(sideBar.key)
              })}
              style={{ zIndex: this.state.zIndex + idx }}
            />
            <div
              className={conditionalClass('c-dynamicSidebar', {
                'c-dynamicSidebar--withTitle': defined(sideBar.title),
                'c-dynamicSidebar--withTab': defined(sideBar.tabs),
                'c-dynamicSidebar--withoutTab': !defined(sideBar.tabs),
                'c-dynamicSidebar--isOpen': this.isOpen(sideBar.key),
                [sideBar.wrapperClassName!]: defined(sideBar.wrapperClassName)
              })}
              id={`dynamicSidebar-${sideBar.key}`}
              style={{
                transform: this.getTranslateX(sideBar.key),
                zIndex: this.getZIndex(sideBar.key, false),
                width: sideBar.width
              }}
            >
              <VisibilityWrapper
                visible={this.props.showCloseIcon! && this.isOpen(sideBar.key)}
              >
                <Icon
                  type="flux-close"
                  onClick={() => this.onClose(sideBar.key)}
                  className="c-dynamicSidebar__closeIcon"
                />
              </VisibilityWrapper>

              {(sideBar.title || sideBar.hasHeader) && (
                <div
                  className="c-dynamicSidebar__header"
                  data-testid="dynamic-sidebar-header"
                >
                  {typeof sideBar.title === 'string' ? (
                    <h2 className="c-dynamicSidebar__headerText">
                      {sideBar.title}
                    </h2>
                  ) : (
                    <div>{sideBar.title}</div>
                  )}
                  <div className="flex flex-gap-15 flex-align-center">
                    <VisibilityWrapper visible={sideBar.showReload!}>
                      <Refresh onClick={this.state.refreshProps.onClick} />
                    </VisibilityWrapper>
                    <VisibilityWrapper visible={sideBar.showDateTimePicker!}>
                      <FFDatepicker
                        value={this.state.dateTimePickerProps.value}
                        onCalendarChange={
                          this.state.dateTimePickerProps.onCalendarChange
                        }
                        onChange={value =>
                          this.state.dateTimePickerProps.onChange!(value)
                        }
                        setRangeName={
                          this.state.dateTimePickerProps.setRangeName
                        }
                      />
                    </VisibilityWrapper>
                    {definedObject(this.state.forCopyIds) && (
                      <div className="c-dynamicSidebar__copyIds">
                        {Object.entries(
                          this.state.forCopyIds?.[sideBar.key] || {}
                        ).map(([name, id]) =>
                          id ? (
                            <div
                              className="c-dynamicSidebar__copyIdBox"
                              key={id}
                              onClick={() => this.copyPassword(name, id)}
                            >
                              <div className="c-dynamicSidebar__copyIdTextValue">
                                <span className="c-dynamicSidebar__copyIdText">
                                  {name}
                                </span>
                                <h4 className="c-dynamicSidebar__copyIdValue">
                                  {id}
                                </h4>
                              </div>
                              <FFIcon
                                name="copy"
                                className="c-dynamicSidebar__copyIdIcon"
                              />
                            </div>
                          ) : (
                            <></>
                          )
                        )}
                      </div>
                    )}
                    <ModalButtonGroup
                      {...(this.state.buttonGroupProps[sideBar.key] ||
                        defaultButtonGroupProps)}
                      cancelDisabled={
                        this.props.loading?.save ||
                        this.props.loading?.saveAndCreate
                      }
                      onCancelClicked={() => this.onCancelClicked(sideBar)}
                      loading={this.props.loading}
                      additionalClass="dynamicSidebar__header--buttons"
                    />
                  </div>
                </div>
              )}
              <div className="c-dynamicSidebar__bodyTabsWrapper">
                {this.state.showTabs && (
                  <div
                    className={conditionalClass('c-dynamicSidebar__tabs', {
                      'c-dynamicSidebar__tabs--disabled': this.state.isLoading[
                        this.state.activeSidebar
                      ]
                    })}
                  >
                    {this.getTabsOfActiveSidebar(sideBar.key).map(tab => (
                      <div
                        className={conditionalClass('c-dynamicSidebar__tab', {
                          'c-dynamicSidebar__tab--highlightable': tab.highlightable!,
                          'c-dynamicSidebar__tab--disabled': this.state
                            .disabledTabs[tab.key],
                          'c-dynamicSidebar__tab--withError': this.state.tabsWithError.includes(
                            tab.key
                          ),
                          'c-dynamicSidebar__tab--active':
                            this.state.activeTab === tab.key
                        })}
                        data-testid={tab.key}
                        onClick={() =>
                          this.handleSetState('activeTab', tab.key)
                        }
                        key={tab.key}
                      >
                        <span className="c-dynamicSidebar__tabText">
                          {tab.title}
                        </span>
                        {tab.icon}
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className={conditionalClass('c-dynamicSidebar__body', {
                    [sideBar.bodyClassName!]: defined(sideBar.bodyClassName)
                  })}
                >
                  {this.isOpen(sideBar.key)
                    ? sideBar.render({
                        setLoading: this.setLoading,
                        sidebarLoading: !!this.state.isLoading[sideBar.key],
                        open: this.open,
                        close: this.onClose,
                        activeTab: this.state.activeTab,
                        setButtonGroupProps: data =>
                          this.setButtonGroupProps(data, sideBar.key),
                        setForCopyIdsProps: data =>
                          this.handleSetState('forCopyIds', {
                            ...this.state.forCopyIds,
                            [sideBar.key]: data
                          }),
                        headerComponent: sideBar.tabs?.[this.state.activeTab]
                          ?.header || <></>,
                        tabTitle:
                          sideBar.tabs?.[this.state.activeTab]?.title || '',
                        setTabsError: this.setTabsError,
                        setTabDisabledState: this.setTabDisabledState,
                        resetDisabledTabs: this.resetDisabledTabs,
                        setRefreshProps: this.setRefreshProps,
                        setDateTimePickerProps: this.setDateTimePickerProps,
                        showTabs: this.showTabs
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </ReactPortal>
    );
  }
}

export default Messages.injectIn(DynamicSideBar);

import React from 'react';

import FluxModal from 'components/Modal';
import { AnyObject } from '../../../../types';
import { ModalsConsumer } from '../../context';
import {
  ADD_PAGE_MODAL,
  ADD_PAGE_MODAL_LANDER,
  GET_ACTION_LINK_MODAL,
  MODIFY_ACTION_MODAL,
  MODIFY_ROUTE_MODAL
} from '../../../../constants/modal';
import { connect } from 'react-redux';
import GetActionLink from '../../../Forms/Builder/GetActionLink';
import AddPage from '../../../Forms/Builder/AddPage';
import { FunnelBuilderAddPageSelector } from '../../../../redux/selectors';
import ModifyAction from '../../../Forms/Builder/ModifyAction';
import ModifyRoute from '../../../Forms/Builder/ModifyRoute';
import { ModalProps } from '../../types';
import { ContextMenuModal, FormContextModal } from '../../../../types/modal';
import {
  fetchOffersCategoryInfo,
  fetchOffers,
  fetchCategories as fetchOfferCategories
} from 'redux/actions/offers';
import {
  fetchLandersCategoryInfo,
  fetchLanders,
  fetchCategories as fetchLanderCategories
} from 'redux/actions/landers';
import { BuilderAddPageModalProps } from 'types/ModalForms/builder/addPage';

const GetActionLinkModal = ({
  isOpen,
  closeSelf,
  contextModal,
  domains,
  customDomains
}: AnyObject) => (
  <FluxModal
    width={700}
    destroyOnClose
    title={`Get Action Link`}
    isOpen={isOpen}
    classNames={['get-action-link-modal']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <GetActionLink
        node={contextModal.node}
        funnel={contextModal.funnel}
        connection={contextModal.connection}
        connections={contextModal.connections}
        onClose={closeSelf}
        customDomains={customDomains}
        defaultCustomDomain={domains}
      />
    )}
  />
);

const AddPageModal = ({
  isOpen,
  closeSelf,
  contextModal,
  data,
  fetchOffersCategoryInfo,
  fetchLandersCategoryInfo,
  fetchOffers,
  fetchLanders,
  fetchOfferCategories,
  fetchLanderCategories
}: BuilderAddPageModalProps) => (
  <FluxModal
    width={395}
    destroyOnClose
    title={
      contextModal.type === ADD_PAGE_MODAL_LANDER ? 'Add Lander' : 'Add Offer'
    }
    isOpen={isOpen}
    classNames={['add-page-modal']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    zIndex={contextModal.zIndex}
    render={() => (
      <AddPage
        pages={
          Object.values(
            contextModal.type === ADD_PAGE_MODAL_LANDER
              ? data.landers
              : data.offers
          ) || []
        }
        type={contextModal.type === ADD_PAGE_MODAL_LANDER ? 'lander' : 'offer'}
        onAdd={contextModal.onAdd}
        onAddGroup={contextModal.onAddGroup}
        getPageGroupNamePostfix={contextModal.getPageGroupNamePostfix}
        onClose={closeSelf}
        modalName={contextModal.modalName}
        funnel={contextModal.funnel}
        posX={contextModal.posX}
        posY={contextModal.posY}
        categories={data?.categories?.[contextModal.type] || []}
        fetchLandersCategoryInfo={fetchLandersCategoryInfo}
        fetchOffersCategoryInfo={fetchOffersCategoryInfo}
        fetchOffers={fetchOffers}
        fetchLanders={fetchLanders}
        fetchOfferCategories={fetchOfferCategories}
        fetchLanderCategories={fetchLanderCategories}
      />
    )}
  />
);

const ModifyActionModal = ({ isOpen, closeSelf, contextModal }: AnyObject) => (
  <FluxModal
    width={430}
    destroyOnClose
    title="Modify Action"
    isOpen={isOpen}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ModifyAction
        connections={contextModal.connections}
        connection={contextModal.connection}
        onChange={contextModal.onChange}
        funnelNode={contextModal.funnelNode}
        paramsNode={contextModal.paramsNode}
        onClose={closeSelf}
      />
    )}
  />
);

const ModifyRouteModal = ({ isOpen, closeSelf, contextModal }: AnyObject) => (
  <FluxModal
    width={275}
    destroyOnClose
    title={contextModal.title || 'Modify Action'}
    isOpen={isOpen}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    classNames={[`modify-route-or-action`]}
    render={() => (
      <ModifyRoute
        connections={contextModal.connections}
        connection={contextModal.connection}
        condition={contextModal.condition}
        onChange={contextModal.onChange}
        availableRoutes={contextModal.availableRoutes}
        onClose={closeSelf}
      />
    )}
  />
);

export default connect(FunnelBuilderAddPageSelector, {
  fetchOffersCategoryInfo,
  fetchLandersCategoryInfo,
  fetchOffers,
  fetchLanders,
  fetchOfferCategories,
  fetchLanderCategories
})(
  ({
    landers,
    offers,
    categories,
    customDomains,
    defaultCustomDomain,
    fetchOffersCategoryInfo,
    fetchLandersCategoryInfo,
    fetchOffers,
    fetchLanders,
    fetchOfferCategories,
    fetchLanderCategories
  }) => (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <GetActionLinkModal
            isOpen={isOpenModal(GET_ACTION_LINK_MODAL)}
            closeSelf={() => closeModal(GET_ACTION_LINK_MODAL)}
            contextModal={
              getContextModal(GET_ACTION_LINK_MODAL) as FormContextModal
            }
            customDomains={customDomains}
            domains={defaultCustomDomain}
          />

          <AddPageModal
            isOpen={isOpenModal(ADD_PAGE_MODAL)}
            closeSelf={() => closeModal(ADD_PAGE_MODAL)}
            contextModal={getContextModal(ADD_PAGE_MODAL) as ContextMenuModal}
            data={{
              landers: landers,
              offers: offers,
              categories: categories
            }}
            fetchLandersCategoryInfo={fetchLandersCategoryInfo}
            fetchOffersCategoryInfo={fetchOffersCategoryInfo}
            fetchOffers={fetchOffers}
            fetchLanders={fetchLanders}
            fetchOfferCategories={fetchOfferCategories}
            fetchLanderCategories={fetchLanderCategories}
          />

          <ModifyActionModal
            isOpen={isOpenModal(MODIFY_ACTION_MODAL)}
            closeSelf={() => closeModal(MODIFY_ACTION_MODAL)}
            contextModal={
              getContextModal(MODIFY_ACTION_MODAL) as FormContextModal
            }
          />

          <ModifyRouteModal
            isOpen={isOpenModal(MODIFY_ROUTE_MODAL)}
            closeSelf={() => closeModal(MODIFY_ROUTE_MODAL)}
            contextModal={getContextModal(MODIFY_ROUTE_MODAL)}
          />
        </>
      )}
    </ModalsConsumer>
  )
);

import { ReportingAttributes, ReportingView, RestrictData } from '../types/reporting';
import { Attribute } from '../model/attribute';
import { FFSelectOption } from 'uikit/types/select';
import { DrilldownReportRowEvent } from 'model/drilldownReportRow';
import { AttributeData } from 'model/attributeData';

export const TRAFFIC_SOURCE_OPTION_SEPARATOR = '###';

export const defaultSummaryData = {
  attributes: [],
  conversions: 0,
  cost: 0,
  indirectConversions: 0,
  indirectRevenue: 0,
  landerClicks: 0,
  landerClicksUnique: 0,
  landerViews: 0,
  landerViewsUnique: 0,
  nodeViews: 0,
  nodeViewsUnique: 0,
  offerClicks: 0,
  offerClicksUnique: 0,
  offerViews: 0,
  offerViewsUnique: 0,
  revenue: 0,
  visitors: 0,
  visits: 0
};

export const VISITOR_JOURNEY_PAGE_ONLY = 'Journey (pages only)';
export const VISITOR_JOURNEY_FULL = 'Journey (full)';
export const VISITOR_JOURNEY_GROUPS_AND_PAGES = 'Journey (groups and pages)';

export const orderedAttributes: ReportingAttributes = {
  'General Data': {
    [Attribute.ElementFunnel]: { attribute: Attribute.ElementFunnel },
    [Attribute.ElementFunnelGroup]: { attribute: Attribute.ElementFunnelGroup },
    [Attribute.URLTrackingField]: {
      attribute: Attribute.URLTrackingField,
      whitelistFilters: ['{"campaign":"*"}']
    },
    [Attribute.ThirdPartiesTrafficSource]: { attribute: Attribute.ThirdPartiesTrafficSource },
    'Traffic Source > Tracking Fields': {
      attribute: Attribute.ThirdPartiesTrafficSource
    },
    //??'Tracking Domain': ,
    [Attribute.ConnectionReferrer]: { attribute: Attribute.ConnectionReferrer },
    [Attribute.ConnectionReferrerDomain]: { attribute: Attribute.ConnectionReferrerDomain },
    [Attribute.TrafficTrackingDomain]: { attribute: Attribute.TrafficTrackingDomain }
  },
  'Location Data': {
    [Attribute.LocationContinent]: { attribute: Attribute.LocationContinent },
    [Attribute.LocationCountry]: { attribute: Attribute.LocationCountry },
    [Attribute.LocationRegion]: { attribute: Attribute.LocationRegion },
    [Attribute.LocationCity]: { attribute: Attribute.LocationCity },
    [Attribute.LocationTimezone]: { attribute: Attribute.LocationTimezone }
  },
  'Device Properties': {
    [Attribute.DeviceDeviceType]: { attribute: Attribute.DeviceDeviceType },
    [Attribute.DeviceBrand]: { attribute: Attribute.DeviceBrand },
    [Attribute.DeviceModel]: { attribute: Attribute.DeviceModel },
    //??'Device Model Name':
    [Attribute.DeviceOS]: { attribute: Attribute.DeviceOS },
    [Attribute.DeviceOSVersion]: { attribute: Attribute.DeviceOSVersion },
    [Attribute.DeviceBrowser]: { attribute: Attribute.DeviceBrowser },
    [Attribute.DeviceBrowserVersion]: { attribute: Attribute.DeviceBrowserVersion },
    [Attribute.DeviceMainLanguage]: { attribute: Attribute.DeviceMainLanguage }
    //??'User-agent': // not possible to implement
  },
  'Connection Properties': {
    [Attribute.ConnectionConnectionType]: { attribute: Attribute.ConnectionConnectionType },
    [Attribute.ConnectionISP]: { attribute: Attribute.ConnectionISP },
    [Attribute.ConnectionMobileCarrier]: { attribute: Attribute.ConnectionMobileCarrier }
    //??'Type - Carrier - ISP':
  },
  'Time-parting': {
    [Attribute.TimeDate]: { attribute: Attribute.TimeDate },
    [Attribute.TimeDayParting]: { attribute: Attribute.TimeDayParting },
    [Attribute.TimeWeekParting]: { attribute: Attribute.TimeWeekParting }
  },
  'Page/Node Data': {
    [Attribute.ElementLander]: { attribute: Attribute.ElementLander },
    [Attribute.ElementLanderCategory]: { attribute: Attribute.ElementLanderCategory },
    //??'Lander Group': ,
    [Attribute.ElementOffer]: { attribute: Attribute.ElementOffer },
    [Attribute.ElementOfferCategory]: { attribute: Attribute.ElementOfferCategory },
    //??'Offer Group': ,
    [Attribute.ThirdPartiesOfferSource]: { attribute: Attribute.ThirdPartiesOfferSource },
    [Attribute.InsightVisitorJourneyAllNodes]: {
      attribute: Attribute.InsightVisitorJourneyAllNodes
    },
    [Attribute.InsightVisitorJourney]: {
      attribute: Attribute.InsightVisitorJourneyAllNodes
    },
    [Attribute.InsightVisitorJourneyLanderOfferGroups]: {
      attribute: Attribute.InsightVisitorJourneyAllNodes
    },
    [Attribute.ClickActionNum]: {
      attribute: Attribute.ClickActionNum
    },
    [Attribute.ConversionTransactionID]: {
      attribute: Attribute.ConversionTransactionID
    }
    //??'Conversion Path (Simple)': ,
    //??'Conversion Path (Groups)': ,
    //??'Conversion Path (Full)':
  }
  /*'Conversion Data': {
    //??'Conversion ID': , //not possible to implement
    //??'Conversion Time (Event)': , //not possible to implement
    //??'Conversion Time (Entrance)': , //not possible to implement
    //??'Transaction ID': , //not possible to implement
    //??'Time to conversion bracket': ,
  }*/
};

export const attributesNameMappings = {
  Campaign: Attribute.URLTrackingField,
  [Attribute.URLTrackingField]: 'Campaign',
  Funnel: Attribute.ElementFunnel,
  [Attribute.ElementFunnel]: 'Funnel',
  'Funnel Group': Attribute.ElementFunnelGroup,
  [Attribute.ElementFunnelGroup]: 'Funnel Group',
  Referrer: Attribute.ConnectionReferrer,
  [Attribute.ConnectionReferrer]: 'Referrer',
  'Traffic Source': Attribute.ThirdPartiesTrafficSource,
  [Attribute.ThirdPartiesTrafficSource]: 'Traffic Source',
  [Attribute.ConnectionReferrerDomain]: 'Referrer Domain',
  'Referrer Domain': Attribute.ConnectionReferrerDomain,
  'Tracking Domain': Attribute.TrafficTrackingDomain,
  [Attribute.TrafficTrackingDomain]: 'Tracking Domain',
  Continent: Attribute.LocationContinent,
  [Attribute.LocationContinent]: 'Continent',
  Country: Attribute.LocationCountry,
  [Attribute.LocationCountry]: 'Country',
  Region: Attribute.LocationRegion,
  [Attribute.LocationRegion]: 'Region',
  City: Attribute.LocationCity,
  [Attribute.LocationCity]: 'City',
  Timezone: Attribute.LocationTimezone,
  [Attribute.LocationTimezone]: 'Timezone',
  'Device Type': Attribute.DeviceDeviceType,
  [Attribute.DeviceDeviceType]: 'Device Type',
  'Device Brand': Attribute.DeviceBrand,
  [Attribute.DeviceBrand]: 'Device Brand',
  'Device Model': Attribute.DeviceModel,
  [Attribute.DeviceModel]: 'Device Model',
  'Device OS': Attribute.DeviceOS,
  [Attribute.DeviceOS]: 'Device OS',
  'Device OS Version': Attribute.DeviceOSVersion,
  [Attribute.DeviceOSVersion]: 'Device OS Version',
  'Browser': Attribute.DeviceBrowser,
  [Attribute.DeviceBrowser]: 'Browser',
  'Browser Version': Attribute.DeviceBrowserVersion,
  [Attribute.DeviceBrowserVersion]: 'Browser Version',
  'Browser Language': Attribute.DeviceMainLanguage,
  [Attribute.DeviceMainLanguage]: 'Browser Language',
  'Connection Type': Attribute.ConnectionConnectionType,
  [Attribute.ConnectionConnectionType]: 'Connection Type',
  'ISP': Attribute.ConnectionISP,
  [Attribute.ConnectionISP]: 'ISP',
  'Mobile Carrier': Attribute.ConnectionMobileCarrier,
  [Attribute.ConnectionMobileCarrier]: 'Mobile Carrier',
  'Date': Attribute.TimeDate,
  [Attribute.TimeDate]: 'Date',
  'Hour of the day': Attribute.TimeDayParting,
  [Attribute.TimeDayParting]: 'Hour of the day',
  'Day of the week': Attribute.TimeWeekParting,
  [Attribute.TimeWeekParting]: 'Day of the week',
  'Lander': Attribute.ElementLander,
  [Attribute.ElementLander]: 'Lander',
  'Lander Category': Attribute.ElementLanderCategory,
  [Attribute.ElementLanderCategory]: 'Lander Category',
  'Offer': Attribute.ElementOffer,
  [Attribute.ElementOffer]: 'Offer',
  'Offer Category': Attribute.ElementOfferCategory,
  [Attribute.ElementOfferCategory]: 'Offer Category',
  'Offer Source': Attribute.ThirdPartiesOfferSource,
  [Attribute.ThirdPartiesOfferSource]: 'Offer Source',
  'Journey (full)': Attribute.InsightVisitorJourneyAllNodes,
  [Attribute.InsightVisitorJourneyAllNodes]: 'Journey (full)',
  'Journey (groups and pages)': Attribute.InsightVisitorJourneyAllNodes,
  [Attribute.InsightVisitorJourneyLanderOfferGroups]: 'Journey (groups and pages)',
  'Journey (pages only)': Attribute.InsightVisitorJourneyAllNodes,
  [Attribute.InsightVisitorJourney]: 'Journey (pages only)',
  'Action Number': Attribute.ClickActionNum,
  [Attribute.ClickActionNum]: 'Action Number',
  'Transaction ID': Attribute.ConversionTransactionID,
  [Attribute.ConversionTransactionID]: 'Transaction ID',
}

export const defaultRestrictData = (): RestrictData => ({
  fieldsData: {},
  switches: {}
});

export const CREATE_NEW_VIEW: FFSelectOption = {
  label: 'Create New View',
  value: 'createNewView'
};

export const reportingQuickReports: FFSelectOption<string, Array<AttributeData>>[] = [
  {
    label: 'Funnel Summary',
    objectValue: [
      { attribute: Attribute.ElementFunnelGroup },
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ThirdPartiesTrafficSource }
    ],
    value: 'Funnel Summary'
  },
  {
    label: 'Campaign Summary',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.URLTrackingField, whitelistFilters: ['{"campaign":"*"}'] }
    ],
    value: 'Campaign Summary'
  },
  {
    label: 'Lander Performance',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ElementLander },
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.URLTrackingField, whitelistFilters: ['{"campaign":"*"}'] }
    ],
    value: 'Lander Performance'
  },
  {
    label: 'Offer Performance',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ElementOffer },
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.URLTrackingField, whitelistFilters: ['{"campaign":"*"}'] }
    ],
    value: 'Offer Performance',
    className: 'border-bottom-1'
  },
  {
    label: 'Tracking Fields',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: 'Traffic Source > Tracking Fields' as Attribute }
    ],
    value: 'Tracking Fields'
  },
  {
    label: 'Visitor Journey',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.InsightVisitorJourneyAllNodes }
    ],
    value: 'Visitor Journey',
    className: 'border-bottom-1'
  },
  {
    label: 'Geographic Breakdown',
    objectValue: [
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.LocationCountry },
      { attribute: Attribute.LocationCity }
    ],
    value: 'Geographic Breakdown'
  },
  {
    label: 'Device Breakdown',
    objectValue: [
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.DeviceDeviceType },
      { attribute: Attribute.DeviceOS },
      { attribute: Attribute.DeviceBrowser }
    ],
    value: 'Device Breakdown'
  },
  {
    label: 'Connectivity Breakdown',
    objectValue: [
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.ConnectionConnectionType },
      { attribute: Attribute.ConnectionISP }
    ],
    value: 'Connectivity Breakdown',
    className: 'border-bottom-1'
  },
  {
    label: 'Performance by Hour',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.TimeDayParting }
    ],
    value: 'Performance by Hour'
  },
  {
    label: 'Performance by Day',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.TimeWeekParting }
    ],
    value: 'Performance by Day'
  },
  {
    label: 'Performance by Date',
    objectValue: [
      { attribute: Attribute.ElementFunnel },
      { attribute: Attribute.ThirdPartiesTrafficSource },
      { attribute: Attribute.TimeDate }
    ],
    value: 'Performance by Date'
  }
];

export const defaultDrilldownReportEvents: DrilldownReportRowEvent[] = [
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  },
  {
    count: 0,
    revenue: 0
  }
];

export const rawCustomFields = [
  'c1',
  'c2',
  'c3',
  'c4',
  'c5',
  'c6',
  'c7',
  'c8',
  'c9',
  'c10',
  'c11',
  'c12',
  'c13',
  'c14',
  'c15',
  'c16',
  'c17',
  'c18',
  'c19',
  'c20',
];

export interface FluxFormFieldRule {
  path: string;
  required: string;
  validator?: (e: any) => string | boolean;
}

export interface FluxFormErrors {
  [path: string]: string;
}

export const getDeepValue = <T>(obj: T, path: string) => {
  const keys = path.split('.');
  let currentObj: any = obj;
  for (const key of keys) {
    if (currentObj?.[key]) {
      currentObj = currentObj[key];
    } else {
      return undefined;
    }
  }
  return currentObj;
};

export const setDeepValue = <T>(obj: T, path: string, valueToSet: string | number | string[] | { [key: string]: any }): T => {
  const keys = path.split('.');
  let currentObj: any = { ...obj };

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!currentObj[key]) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }

  const finalKey = keys[keys.length - 1];
  currentObj[finalKey] = valueToSet;

  return obj;
};

export const validator = <T>(data: T, rules: FluxFormFieldRule[]) => {
  let errors: FluxFormErrors = {};
  for (const rule of rules) {
    const value = getDeepValue(data, rule.path);
    rule.validator = rule.validator || (() => '');
    if (value) {
      const validateMessage = rule.validator(value);
      if (validateMessage) {
        errors[rule.path] = validateMessage ? (validateMessage as string) : rule.required || '';
      }
    } else {
      errors[rule.path] = rule.required;
    }
  }
  return errors;
};

const fluxForm = {
  validator,
  getDeepValue,
  setDeepValue,
  hasErrors: (errors: FluxFormErrors) => Object.keys(errors).length > 0
};

export default fluxForm;

import { TrafficCostsTableColumn } from 'types/table';

export const defaultTrafficCostsColumns: TrafficCostsTableColumn[] = [
  {
    headerName: 'Cost',
    field: 'cost',
    suppressMenu: true,
    width: 285,
    suppressSizeToFit: false,
    lockPosition: false,
    resizable: true
  }
];

export const defaultSelectedColumnsData = ['cost'];

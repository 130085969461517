import {
  createLanderCategories,
  archiveLanderCategory,
  updateLanderCategories,
  deleteLanderCategory
} from 'redux/actions/landers';
import {
  createOfferCategories,
  archiveOfferCategory,
  updateOfferCategories,
  deleteOfferCategory
} from 'redux/actions/offers';
import {
  createOfferSourceCategories,
  archiveOfferSourceCategory,
  updateOfferSourceCategories,
  deleteOfferSourceCategory
} from 'redux/actions/offersources';
import {
  LANDER_CATEGORY_TYPE,
  OFFER_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_TYPE,
  TRAFFIC_SOURCE_CATEGORY_TYPE,
  CONDITION_CATEGORY_TYPE
} from 'constants/modal';
import { Dispatch } from 'redux';
import { Category } from '../../model/category';
import { DeleteList } from '../../model/deleteList';
import {
  archiveTrafficSourceCategory,
  deleteTrafficSourceCategory,
  updateTrafficSourceCategories,
  createTrafficSourceCategories
} from './trafficsources';
import { CompositeStore } from 'types/redux/store';
import {
  deleteConditionCategory,
  updateConditionCategories,
  createConditionCategories
} from './conditions';

export const updateCategories = (type: string) => (dispatch: Dispatch) => {
  switch (type) {
    case LANDER_CATEGORY_TYPE:
      return (data: Category) => dispatch(updateLanderCategories(data));
    case OFFER_CATEGORY_TYPE:
      return (data: Category) => dispatch(updateOfferCategories(data));
    case OFFER_SOURCE_CATEGORY_TYPE:
      return (data: Category) => dispatch(updateOfferSourceCategories(data));
    case TRAFFIC_SOURCE_CATEGORY_TYPE:
      return (data: Category) => dispatch(updateTrafficSourceCategories(data));
    case CONDITION_CATEGORY_TYPE:
      return (data: Category) => dispatch(updateConditionCategories(data));
    default:
      return () => {};
  }
};

export const insertCategories = (type: string) => (dispatch: Dispatch) => {
  switch (type) {
    case LANDER_CATEGORY_TYPE:
      return (data: Category) => dispatch(createLanderCategories(data));
    case OFFER_CATEGORY_TYPE:
      return (data: Category) => dispatch(createOfferCategories(data));
    case OFFER_SOURCE_CATEGORY_TYPE:
      return (data: Category) => dispatch(createOfferSourceCategories(data));
    case TRAFFIC_SOURCE_CATEGORY_TYPE:
      return (data: Category) => dispatch(createTrafficSourceCategories(data));
    case CONDITION_CATEGORY_TYPE:
      return (data: Category) => dispatch(createConditionCategories(data));
    default:
      return () => {};
  }
};

export const deleteCategory = (type: string) => (dispatch: Dispatch) => {
  switch (type) {
    case LANDER_CATEGORY_TYPE:
      return (data: DeleteList) => dispatch(deleteLanderCategory(data));
    case OFFER_CATEGORY_TYPE:
      return (data: DeleteList) => dispatch(deleteOfferCategory(data));
    case OFFER_SOURCE_CATEGORY_TYPE:
      return (data: DeleteList) => dispatch(deleteOfferSourceCategory(data));
    case TRAFFIC_SOURCE_CATEGORY_TYPE:
      return (data: DeleteList) => dispatch(deleteTrafficSourceCategory(data));
    case CONDITION_CATEGORY_TYPE:
      return (data: DeleteList) => dispatch(deleteConditionCategory(data));
    default:
      return () => {};
  }
};

export const archiveCategory = (type: string) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  switch (type) {
    case LANDER_CATEGORY_TYPE:
      return (data: Category) =>
        dispatch(archiveLanderCategory(data, getState().landers, dispatch));
    case OFFER_CATEGORY_TYPE:
      return (data: Category) =>
        dispatch(archiveOfferCategory(data, getState().offers, dispatch));
    case OFFER_SOURCE_CATEGORY_TYPE:
      return (data: Category) =>
        dispatch(
          archiveOfferSourceCategory(data, getState().offersources, dispatch)
        );
    case TRAFFIC_SOURCE_CATEGORY_TYPE:
      return (data: Category) =>
        dispatch(
          archiveTrafficSourceCategory(
            data,
            getState().trafficsources,
            dispatch
          )
        );
    default:
      return () => {};
  }
};

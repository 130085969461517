import React from 'react';
import {
  DNSRecordTemplate,
  DNSRecordField,
  DNSRecordFieldParams,
  HelpText
} from 'types/domains';
import {
  Label,
  Select,
  Input,
  TextArea,
  InputNumber
} from 'components/Parts/Inputs';
import './style.scss';
import { defined } from 'utils/define';
import { ProxyToggle } from 'components/Parts/Buttons';
import { AnyObject, MessageProps } from 'types';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from '../../../utils/reactive/generic';
import validateForm, { getErrorId } from 'utils/validation';
import { getFieldValueInformation } from 'utils/domains';
import { dnsTemplates, dnsAddRecordFields } from 'constants/domains';
import { DNSRecordParams } from 'types/domains';
import { ModalButtonGroup } from 'components/Parts/Groups';
import Messages from 'components/Messages';
import { AddDnsRecord, UpdateDnsRecord } from 'types/actions';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';

interface State extends ReactiveValidateState {
  model: DNSRecordParams;
  anotherModel: AnyObject;
  validationErrors: AnyObject;
  activeTemplate: DNSRecordTemplate;
}

interface Props extends MessageProps, LoadingProps {
  isEditMode?: boolean;
  onSubmit?: AddDnsRecord | UpdateDnsRecord;
  params?: DNSRecordParams;
  closeSelf?: () => void;
}

const defaultModel: DNSRecordParams = {
  content: '',
  data: {},
  name: '',
  proxied: true,
  proxiable: true,
  ttl: 1,
  type: 'A',
  zone_id: '',
  zone_name: 'funnelflux.com'
};

class DnsRecordCreator extends ReactiveValidateComponent<Props, State> {
  getTemplateByType = (type: string): DNSRecordTemplate => {
    return dnsTemplates.find((item: DNSRecordTemplate) => item.type === type)!;
  };

  state: State = {
    model: defaultModel,
    anotherModel: {},
    validationErrors: {},
    isTouchedForm: false,
    activeTemplate: this.getTemplateByType(defaultModel.type)
  };

  async componentDidMount() {
    this.setValidateSubscribe();
    if (!!this.props.params && this.props.isEditMode) {
      await this.handleChangeStateValues(
        'model',
        this.getValidatedModel(this.props.params, true)
      );
      await this.handleChangeStateValues(
        'anotherModel',
        this.getValidatedModel(
          { ...this.props.params, ...this.props.params.data },
          true
        )
      );
      await this.handleChangeStateValues(
        'activeTemplate',
        this.getTemplateByType(this.props.params.type)
      );
    }
  }

  handleChangeStateValues = (name: string, value: any) => {
    this.setState((state: State) => ({
      ...state,
      [name]: value
    }));
  };

  handleChangeStateModelValues = (name: string, value: any) => {
    const validate = () => this.validate$.next();
    this.setState(
      (state: State) => ({
        ...state,
        model: {
          ...state.model,
          [name]: value
        }
      }),
      validate
    );
  };

  handleChangeStateModelDataValues = (name: string, value: any) => {
    this.setState(
      (state: State) => ({
        ...state,
        model: {
          ...state.model,
          data: {
            ...state.model.data,
            [name]: value
          }
        }
      }),
      this.validate
    );
  };

  hasError = (field: string, idx: number) =>
    (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
    (idx === undefined && !!this.state.validationErrors[field]);

  validateClassName = (field: string, idx?: number) => {
    return this.hasError(field, idx!) ? 'has-error' : '';
  };

  validateTooltip = (field: string, idx?: number) => {
    return this.hasError(field, idx!)
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {} });
      const [validationErrors] = validateForm(
        this.state.anotherModel,
        this.state.activeTemplate.validationRules
      );
      this.handleChangeStateValues('validationErrors', validationErrors);
    }
  };

  getValidatedModel = (
    model: DNSRecordParams,
    removeZoneName: boolean
  ): DNSRecordParams => {
    if (removeZoneName) {
      return {
        ...model,
        name: model.name.slice(0, model.name.search(/[.]/))
      };
    } else {
      return {
        ...model,
        name: `${model.name}.${model.zone_name}`
      };
    }
  };

  calculateContentByDataParams = (
    contentCalculationPattern: DNSRecordFieldParams[],
    activeTemplate: DNSRecordTemplate,
    isForHelpText?: boolean
  ) => {
    let result = '';
    const valueGetter = (item: DNSRecordFieldParams) =>
      this.getFieldValue(item, this.state.model);

    if (!!isForHelpText) {
      const flagsField = contentCalculationPattern.find(
        item => item.title === 'Flags'
      )!;
      if (valueGetter(flagsField) === 'U') {
        result = 'a record that specifies how to resolve a given SRV record';
        return result;
      }
      if (valueGetter(flagsField) === 'S') {
        result =
          'a URI record against which a successive NAPTR lookup should be conducted';
        return result;
      }
    }
    contentCalculationPattern.forEach((item: DNSRecordFieldParams) => {
      result += valueGetter(item) + ' ';
    });
    if (!isForHelpText) {
      if (activeTemplate.type === 'LOC') {
        result = 'IN LOC ' + result;
      }
    }
    return result.replace(/\s$/, '');
  };

  onSubmit = async () => {
    if (
      Object.keys(this.state.model.data!).length > 0 &&
      defined(this.state.activeTemplate.contentCalculationPattern)
    ) {
      await this.handleChangeStateModelValues(
        'content',
        this.calculateContentByDataParams(
          this.state.activeTemplate.contentCalculationPattern!,
          this.state.activeTemplate
        )
      );
    }
    await this.setTouchedForm();
    await this.validate();

    if (Object.keys(this.state.validationErrors).length === 0) {
      this.props.startLoading('ok');
      try {
        if (this.props.isEditMode) {
          await this.props.onSubmit!(this.state.model);
          this.props.stopLoading('all');
        } else {
          await this.props.onSubmit!(this.state.model);
          this.props.stopLoading('all');
        }
      } catch (e) {
        this.props.showMessage(Messages.failed(`Cannot update ${e}`));
        this.props.stopLoading('all');
      }
    }
  };

  getFieldValue = (
    field: DNSRecordFieldParams,
    stateModelParams: DNSRecordParams
  ) => {
    if (defined(field)) {
      return !!field.isDataParam
        ? defined(stateModelParams.data![field.key])
          ? stateModelParams.data![field.key]
          : ''
        : defined(stateModelParams[field.key as 'content' | 'ttl'])
        ? stateModelParams[field.key as 'content' | 'ttl']
        : '';
    }
  };

  getHelpTextValue = (item: HelpText, activeTemplate: DNSRecordTemplate) => {
    if (activeTemplate.type === 'NAPTR' && item.text === '[content]') {
      if (
        !!this.calculateContentByDataParams(
          activeTemplate.contentCalculationPattern!,
          this.state.activeTemplate,
          true
        )
      ) {
        return this.calculateContentByDataParams(
          activeTemplate.contentCalculationPattern!,
          this.state.activeTemplate,
          true
        );
      } else {
        return item.text;
      }
    } else {
      if (!!this.getFieldValue(item.field!, this.state.model)) {
        return getFieldValueInformation(
          item.text,
          this.getFieldValue(item.field!, this.state.model),
          this.state.model,
          dnsAddRecordFields
        );
      } else {
        return item.text;
      }
    }
  };

  getHelpText = (activeTemplate: DNSRecordTemplate) => {
    const helpText = activeTemplate.helpText;
    if (defined(helpText)) {
      return (
        <p>
          {helpText.map((item: HelpText, idx: number) => (
            <React.Fragment key={idx}>
              <span
                key={item.text}
                className={`
                  help-text
                  ${
                    defined(item.field) &&
                    !this.getFieldValue(item.field!, this.state.model) &&
                    activeTemplate.type !== 'LOC' &&
                    activeTemplate.type !== 'NAPTR' &&
                    item.text !== '[content]'
                      ? 'filled-help-text'
                      : 'empty-help-text'
                  }
                `}
              >
                {this.getHelpTextValue(item, activeTemplate)}
                {item.flag ? item.flag : ''}
              </span>
              {item.postfixText && (
                <span style={{ margin: 5 }}>{item.postfixText}</span>
              )}
            </React.Fragment>
          ))}
          .
        </p>
      );
    }
    return <p />;
  };

  onFieldChange = (field: DNSRecordFieldParams, value: any) => {
    this.handleChangeStateValues('anotherModel', {
      ...this.state.anotherModel,
      [field.key]: value
    });
    if (field.isDataParam) {
      this.handleChangeStateModelDataValues(field.key, value);
    } else {
      this.handleChangeStateModelValues(field.key, value);
    }
  };

  fieldCreator = (
    field: DNSRecordFieldParams,
    wrapperClassName: 'sub-field' | 'main-field'
  ) => {
    const placeholder = field.placeholder ? field.placeholder : '';
    const value = this.getFieldValue(field, this.state.model);
    const options = defined(field.options) ? field.options : [];
    const label = () =>
      field.title ? (
        <div className="label">
          <Label htmlFor={field.key} text={field.title} />
          {field.required && <span>*</span>}
        </div>
      ) : (
        <div className="label">
          <Label htmlFor={field.key} text={'sub field'} />
        </div>
      );
    const description = () =>
      field.description ? (
        <div className="description">{field.description}</div>
      ) : (
        <></>
      );

    switch (field.type) {
      case 'select':
        return (
          <div
            className={`
              field
              ${wrapperClassName}
              ${field.title}
              ${field.key}
              ${field.description}
              ${this.validateClassName(field.key)}
            `}
            id={getErrorId(field.key)}
            key={field.key}
          >
            {label()}
            <Select
              id={field.key}
              name={field.key}
              value={value}
              suspendSort={true}
              options={options}
              disabled={
                (field.key === 'ttl' && this.state.model.proxied) ||
                field.disabled
              }
              onChange={(value: string) => this.onFieldChange(field, value)}
              placeholder={placeholder}
              key={field.key}
              valueGetter={(opt: any) => opt.value}
              labelGetter={(opt: any) => opt.label}
            />
            {description()}
          </div>
        );
      case 'text-input':
        return (
          <div
            className={`
              field
              ${wrapperClassName}
              ${field.title}
              ${field.key}
              ${field.description}
              ${this.validateClassName(field.key)}
            `}
            key={field.key}
            id={getErrorId(field.key)}
          >
            {label()}
            <Input
              id={field.key}
              placeholder={placeholder}
              value={value}
              error={this.validateTooltip(field.key)}
              onChange={e =>
                this.onFieldChange(field, e.target.value)
              }
              name={field.key}
              key={field.key}
            />
            {description()}
          </div>
        );
      case 'textarea':
        return (
          <div
            className={`
              field
              ${wrapperClassName}
              ${field.title}
              ${field.key}
              ${field.description}
              ${field.type}
              ${this.validateClassName(field.key)}
            `}
            id={getErrorId(field.key)}
            key={field.key}
          >
            {label()}
            <TextArea
              id={field.key}
              placeholder={placeholder}
              value={value}
              onChange={e =>
                this.onFieldChange(field, e.target.value)
              }
              name={field.key}
              key={field.key}
              style={{
                height: 120
              }}
            />
          </div>
        );
      case 'number-input':
        return (
          <div
            className={`
              field
              ${wrapperClassName}
              ${field.title}
              ${field.key}
              ${field.description}
              ${this.validateClassName(field.key)}
            `}
            id={getErrorId(field.key)}
            key={field.key}
          >
            {label()}
            <InputNumber
              id={field.key}
              placeholder={placeholder}
              value={value}
              onChange={(value: number) => this.onFieldChange(field, value)}
            />
            {description()}
          </div>
        );
      case 'proxy':
        return (
          <div
            className={`
              field
              ${wrapperClassName}
              ${field.key}
            `}
            key={field.key}
          >
            {label()}
            <ProxyToggle
              onClick={() => this.onFieldChange(field, !value)}
              value={value}
            />
          </div>
        );
    }
  };

  resetModel = async () => {
    await this.handleChangeStateValues('validationErrors', {});
    await this.handleChangeStateValues('anotherModel', {});
    await this.handleChangeStateValues('model', defaultModel);
  };

  onTypeChange = async (type: string) => {
    await this.resetModel();
    await this.handleChangeStateModelValues('type', type);
    const template = this.getTemplateByType(type);
    this.handleChangeStateValues('activeTemplate', template);
    this.handleChangeStateModelValues('proxiable', template.proxiable);
    this.handleChangeStateModelValues('proxied', template.proxiable);
    if (!!template.defaultDataParams) {
      this.handleChangeStateValues('anotherModel', template.defaultDataParams);
      this.handleChangeStateModelValues('data', template.defaultDataParams);
    } else {
      this.handleChangeStateModelValues('data', {});
    }
  };

  render() {
    return (
      <div className="record-creator-wrapper">
        {this.getHelpText(this.state.activeTemplate)}
        <div className={`fields-wrapper ${this.state.model.type}`}>
          <div className="field main-field Type">
            <div className="label">
              <Label htmlFor="type" text="Type" />
            </div>
            <Select
              value={this.state.model.type}
              id="type"
              name="type"
              options={dnsTemplates}
              valueGetter={(option: DNSRecordTemplate) => option.type}
              labelGetter={(option: DNSRecordTemplate) => option.type}
              onChange={(value: string) => this.onTypeChange(value)}
              disabled={this.props.isEditMode}
            />
          </div>
          {this.getTemplateByType(this.state.model.type).fields.map(
            (field: DNSRecordField) =>
              typeof field === 'string' && field === 'nextline' ? (
                <div className="break" key={'break'} />
              ) : (
                <React.Fragment key={field.title}>
                  {this.fieldCreator(field, 'main-field')}
                  {field.subFields &&
                    field.subFields.length &&
                    field.subFields.map((subField: DNSRecordFieldParams) =>
                      this.fieldCreator(subField, 'sub-field')
                    )}
                </React.Fragment>
              )
          )}
        </div>
        <div className="actions">
          <ModalButtonGroup
            loading={this.props.loading}
            showOk
            okText={this.props.isEditMode ? 'Update Record' : 'Add Record'}
            onOkClicked={this.onSubmit}
            showCancel={this.props.isEditMode}
            cancelText={'Cancel'}
            onCancelClicked={this.props.closeSelf}
          />
        </div>
      </div>
    );
  }
}

export default withLoading(Messages.injectIn(DnsRecordCreator));

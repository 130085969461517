export const formatter = ({
  value,
  style = undefined,
  minimumFractionDigits = undefined,
  maximumFractionDigits = undefined
}: {
  value: number | string;
  style?: 'percent' | 'currency' | 'decimal';
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}) => {
  if (value === 'N/A') {
    return value;
  }

  const result = new Intl.NumberFormat('en-US', {
    style,
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits
  }).format(Number(value));

  return !result.includes('NaN') ? result : 0;
};

export const formatCurrencyWithCommas = (currencyString: string) => {
  const [currencySymbol, amount] = currencyString.split('$');
  const formattedAmount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${currencySymbol}$${formattedAmount}`;
}
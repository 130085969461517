import { HotKeys, SelectOption } from 'types';
import { FFSelectOption } from 'uikit/types/select';

export const ENTER_KEY_CODE = 13;
export const UP_KEY_CODE = 38;
export const DOWN_KEY_CODE = 40;

export const NO_OFFER_SOURCE = 'No Offer Source';

export const NO_PAGE_ID_JS_PLACEHOLDER = 'Please save your page first before retrieving JS code snippets';

export const HOT_KEYS: HotKeys = {
  NODE_PALETTE: 'd',
  QUICK_STAT: 'q',
  DELETE_NODE: 'ctrl+del',
  DELETE_NODE_COMMAND_KEY: 'command+del',
  SAVE_FUNNEL: 'ctrl+s',
  SAVE_FUNNEL_COMMAND_KEY: 'command+s',
  NEW_RESOURCE: 'n',
  EDIT_MODE: 'e',
  GROUP_BY_SOURCE: 'g+s',
  TABLE_SETTINGS: 's',
  TABLE_INLINE_FILTER: 'f',
  TABLE_DRILL: 'x',
  TABLE_EXPORT_EXCEL: 'c+e',
  NEW_FUNNEL: 'ctrl+q',
  NEW_FUNNEL_COMMAND_KEY: 'command+q', // its not working - exit from chrome
  NEW_FUNNELGROUP: 'ctrl+w',
  NEW_FUNNELGROUP_COMMAND_KEY: 'command+w', // its not working - close window
  NEW_FUNNEL_TAB: 't+n',
  SHOW_CAMPAIGNS: 't+c',
  REFRESH_TABLE: 'r',
  SHOW_TREE_VIEW: 't+v'
};

export const APP_HOTKEYS = `
  ${HOT_KEYS.NEW_FUNNEL_TAB}
`;

export const COMMON_HOTKEYS = `
  ${HOT_KEYS.REFRESH_TABLE},
  ${HOT_KEYS.EDIT_MODE},
  ${HOT_KEYS.NEW_RESOURCE}
`;

export const FUNNELS_HOT_KEYS = `
  ${COMMON_HOTKEYS},
  ${HOT_KEYS.NEW_FUNNELGROUP},
  ${HOT_KEYS.NEW_FUNNELGROUP_COMMAND_KEY},
  ${HOT_KEYS.NEW_FUNNEL},
  ${HOT_KEYS.NEW_FUNNEL_COMMAND_KEY},
  ${HOT_KEYS.SHOW_CAMPAIGNS}
`;

export const OFFERS_HOT_KEYS = `
  ${COMMON_HOTKEYS},
  ${HOT_KEYS.GROUP_BY_SOURCE}
`;

export const SUMMARY_HOT_KEYS = `
  ${COMMON_HOTKEYS},
  ${HOT_KEYS.SHOW_TREE_VIEW}
`;

export const TABLE_HOT_KEYS = `
  ${HOT_KEYS.TABLE_SETTINGS},
  ${HOT_KEYS.TABLE_DRILL},
  ${HOT_KEYS.TABLE_EXPORT_EXCEL},
  ${HOT_KEYS.TABLE_INLINE_FILTER}
`;

export const BUILDER_HOT_KEYS = `
  ${HOT_KEYS.NODE_PALETTE},
  ${HOT_KEYS.QUICK_STAT},
  ${HOT_KEYS.DELETE_NODE_COMMAND_KEY},
  ${HOT_KEYS.DELETE_NODE},
  ${HOT_KEYS.SAVE_FUNNEL_COMMAND_KEY},
  ${HOT_KEYS.SAVE_FUNNEL}
`;

export const HITS_HOT_KEYS = `
  ${COMMON_HOTKEYS}
`;

export const customEventTriggerOptions = (customEventAliases: {
  [key: number]: { alias: string; shortAlias: string };
}): FFSelectOption<number>[] => {
  const customEvents = [
    {
      label: 'Custom Event 1',
      value: 1
    },
    {
      label: 'Custom Event 2',
      value: 2
    },
    {
      label: 'Custom Event 3',
      value: 3
    },
    {
      label: 'Custom Event 4',
      value: 4
    },
    {
      label: 'Custom Event 5',
      value: 5
    },
    {
      label: 'Custom Event 6',
      value: 6
    },
    {
      label: 'Custom Event 7',
      value: 7
    },
    {
      label: 'Custom Event 8',
      value: 8
    },
    {
      label: 'Custom Event 9',
      value: 9
    },
    {
      label: 'Custom Event 10',
      value: 10
    }
  ];

  return customEvents.map(customEvent => ({
    label: customEventAliases[customEvent.value]?.alias
      ? `${customEvent.label} (${customEventAliases[customEvent.value]?.alias})`
      : customEvent.label,
    value: customEvent.value
  }));
};

export const ORGANIC_TRAFFIC_ID = 'organic';

import React from 'react';
import Messages from 'components/Messages';
import { withLoading } from 'components/Loading';
import { ConfirmFormProps } from 'types/ModalForms/confirm';
import { FormContextModal } from '../../../types/modal';
import { defined } from 'utils/define';
import { FFButton } from 'uikit';
import makeCancellablePromise, { timeoutPromise } from 'utils/promises';
import { CancellablePromise } from 'types';

interface State {
  isHolding: boolean;
}

class ConfirmForm extends React.Component<ConfirmFormProps, State> {
  state: State = {
    isHolding: false
  };
  private submitPromise: Partial<CancellablePromise> = {};

  static defaultProps: Partial<ConfirmFormProps> = {
    okText: 'YES',
    cancelText: 'Cancel',
    prepareData: (context: FormContextModal) => context.entityId,
    successMessage: (context: FormContextModal) =>
      `${context?.data?.value} has been updated`
  };

  async componentDidMount() {
    if (defined(this.props.fetchEntity)) {
      await this.props.startLoading('fetching');

      try {
        await this.props.fetchEntity();
        this.props.stopLoading('fetching');
      } catch (e) {
        this.props.stopLoading('fetching');
      }
    }
  }

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (!this.props.withHoldSubmit) {
      this.props.startLoading('ok');
    } else {
      this.handleState('isHolding', true);
    }
    this.submitPromise = makeCancellablePromise(timeoutPromise(1000));

    try {
      if (this.props.withHoldSubmit) {
        await this.submitPromise.promise;
        this.handleState('isHolding', false);
        this.props.startLoading('ok');
      }
      if (defined(this.props.prepareData) && defined(this.props.contextModal)) {
        await this.props.handleSubmit(
          this.props.prepareData!(this.props.contextModal!)
        );
      } else {
        await this.props.handleSubmit();
      }

      this.props.stopLoading('all');
      if (defined(this.props.contextModal)) {
        this.props.showMessage(
          Messages.success(this.props.successMessage!(this.props.contextModal))
        );
      }
      this.props.actions.onClose();
      this.props.actions.onOk();
    } catch (error) {
      this.props.stopLoading('all');
      this.props.actions.onClose();
      this.props.showMessage(Messages.failed('Failed request'));
    }
  };

  onMouseLeaveOrUp = () => {
    if (!this.submitPromise?.cancel || !this.props.withHoldSubmit) return;
    this.handleState('isHolding', false);
    this.submitPromise.cancel();
    this.props.stopLoading('all');
  };

  render() {
    return (
      <div style={{ padding: '20px 30px 30px 30px' }}>
        <div className="flex flex-col">{this.props.text}</div>
        <div className="flex flex-gap-5">
          <FFButton
            loading={
              this.props.withHoldSubmit
                ? this.state.isHolding
                : this.props.loading.ok
            }
            onMouseDown={this.handleSubmit}
            onMouseLeave={this.onMouseLeaveOrUp}
            onMouseUp={this.onMouseLeaveOrUp}
            disabled={this.props.withHoldSubmit ? this.props.loading.ok : false}
            loadingType={this.props.withHoldSubmit ? 'skeleton' : 'default'}
            color={this.props.withHoldSubmit ? 'dangerColor' : 'defaultColor'}
            className="flex-1"
          >
            {this.props.okText}
          </FFButton>
          <FFButton
            onClick={this.props.actions.onClose}
            disabled={this.props.loading.ok}
            className="flex-1"
          >
            {this.props.cancelText}
          </FFButton>
        </div>
      </div>
    );
  }
}

export default withLoading(Messages.injectIn(ConfirmForm));

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="dice-question">
      <path
        id="Rectangle 570"
        fill="#d7e0ff"
        d="M4.976785714285715 0.9953571428571428h17.916428571428572s3.9814285714285713 0 3.9814285714285713 3.9814285714285713v17.916428571428572s0 3.9814285714285713 -3.9814285714285713 3.9814285714285713h-17.916428571428572s-3.9814285714285713 0 -3.9814285714285713 -3.9814285714285713v-17.916428571428572s0 -3.9814285714285713 3.9814285714285713 -3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Ellipse 63"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.161103392857143 10.727242628571428c0 -2.0842380428571428 1.689598842857143 -3.7738368857142857 3.7738368857142857 -3.7738368857142857s3.7738368857142857 1.689598842857143 3.7738368857142857 3.7738368857142857 -1.689598842857143 3.7738368857142857 -3.7738368857142857 3.7738368857142857v2.0214708214285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Rectangle 569"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.976785714285715 0.9953571428571428h17.916428571428572s3.9814285714285713 0 3.9814285714285713 3.9814285714285713v17.916428571428572s0 3.9814285714285713 -3.9814285714285713 3.9814285714285713h-17.916428571428572s-3.9814285714285713 0 -3.9814285714285713 -3.9814285714285713v-17.916428571428572s0 -3.9814285714285713 3.9814285714285713 -3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <g id="Group 623">
        <path
          id="Vector"
          stroke="#48508b"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.934999999999999 21.0376695c-0.2748579214285714 0 -0.4976785714285714 -0.22296 -0.4976785714285714 -0.4976785714285714 0 -0.2749176428571429 0.22282065 -0.4976785714285714 0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
        <path
          id="Vector_2"
          stroke="#48508b"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.934999999999999 21.0376695c0.2748579214285714 0 0.4976785714285714 -0.22296 0.4976785714285714 -0.4976785714285714 0 -0.2749176428571429 -0.22282065 -0.4976785714285714 -0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
      </g>
    </g>
  </svg>
);

import { AnyAction } from 'redux';
import table from './table';
import { CampaignAnalysisStore } from 'types/redux/store';
import { campaignAnalysis as defaultState } from './defaultStates';
import { API, CAMPAIGN_ANALYSIS, TABLE, VIEW } from '../types';
import { ReportingParamsAction } from 'types/redux/action';
import { defined } from 'utils/define';
import { DrilldownReport } from 'model/drilldownReport';
import { getFieldName } from '../../utils/selectors';

const loading = (state = defaultState.loadings, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        [getFieldName(action, CAMPAIGN_ANALYSIS.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: true
        }
      };
    case API.ENDED:
      return {
        ...state,
        [getFieldName(action, CAMPAIGN_ANALYSIS.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: false,
          fetchedAt: Date.now()
        }
      };
    default:
      return state;
  }
};

const filters = (
  state = defaultState.settings.filters,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case CAMPAIGN_ANALYSIS.SET_REPORTING:
      return action.payload.params;
    default:
      return state;
  }
};

const tableCols = (
  state = defaultState.settings.tableCols,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.payload.view === VIEW.CAMPAIGNANALYSIS &&
    action.type === TABLE.SET_COLS
  ) {
    return table(state, action);
  } else {
    return state;
  }
};

const tableParams = (
  state = defaultState.settings.tableParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_TABLE_PARAMS &&
    action.payload.view === VIEW.CAMPAIGNANALYSIS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const additionalParams = (
  state = defaultState.settings.additionalParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_ADDITIONAL_PARAMS &&
    action.payload.view === VIEW.CAMPAIGNANALYSIS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const reporting = (
  state = defaultState.reporting,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case CAMPAIGN_ANALYSIS.SET_REPORTING:
      if (defined(action.payload)) {
        return {
          ...(action.payload.data as DrilldownReport)
        };
      }
      return state;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): CampaignAnalysisStore => {
  return {
    settings: {
      show: 'all',
      tableCols: tableCols(state.settings.tableCols, action),
      tableParams: tableParams(state.settings.tableParams, action),
      filters: filters(state.settings.filters, action),
      editMode: true,
      additionalParams: additionalParams(
        state.settings.additionalParams,
        action
      )
    },
    reporting: reporting(state.reporting, action),
    loadings: loading(state.loadings, action)
  };
};

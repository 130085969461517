import { AnyObject } from 'types';

export const UNCATEGORIZED = 'Uncategorized';
export const UNKNOWN = 'Unknown';
export const DEBOUNCE_TIME = 300;
export const DEFAULT_MODAL_Z_INDEX = 10;
export const SAVE_OR_DISCARD_MESSAGE =
  'You have unsaved changes for the currently selected node. Please save or discard these first.';

export const OVERVIEW_CATEGORY_TYPE = 'overview';

export const OFFER_CATEGORY_TYPE = 'offer';

export const QUICKSTATS_CATEGORY_TYPE = 'quickstats';

export const CAMPAIGN_ANALYSIS_CATEGORY_TYPE = 'campaignanalysis';

export const LANDER_CATEGORY_TYPE = 'lander';
export const FUNNEL_CATEGORY_TYPE = 'funnels';
export const TRAFFIC_SOURCE_CATEGORY_TYPE = 'trafficsources';
export const TRAFFIC_SOURCE_CATEGORY_TYPE_CATEGORIES =
  'trafficsourcesCategories';
export const OFFER_SOURCE_CATEGORY_TYPE = 'offersources';
export const OFFER_CATEGORY_TYPE_SOURCES = 'offersourcesSources';
export const VISITOR_JOURNEY_CATEGORY_TYPE = 'visitorJourney';
export const LOCATION_CATEGORY_TYPE = 'location';
export const DEVICE_DATA_CATEGORY_TYPE = 'deviceData';
export const CONNECTIVITY_CATEGORY_TYPE = 'connectivity';
export const TIME_SEGMENTATION_CATEGORY_TYPE = 'timeSegmentation';
export const TRACKING_FIELDS_CATEGORY_TYPE = 'trackingFields';
export const CONDITION_CATEGORY_TYPE = 'condition';
export const REPORTING_ADD_VIEW = 'ReportingAddView';
export const REPORTING_EDIT_VIEW = 'ReportingEditView';
export const REPORTING_RESTRICT_DATA = 'ReportingRestrictData';

export const BUILDER_SWAP_GROUP_MODAL = 'Swap';

export const LANDER_MODAL = 'Lander';
export const LANDER_CATEGORY_MODAL = 'LanderCategory';
export const LANDER_CATEGORY_DUPLICATE_MODAL = 'LanderCategoryDuplicate';
export const LANDER_CATEGORY_ARCHIVE_MODAL = 'LanderCategoryArchive';
export const LANDER_CATEGORY_DELETE_MODAL = 'LanderCategoryDelete';
export const LANDER_ARCHIVE_MODAL = 'LanderArchive';
export const LANDER_DELETE_MODAL = 'LanderDelete';
export const LANDER_DUPLICATE_MODAL = 'LanderDuplicate';

export const ROTATOR_MODAL = 'Rotator';

export const EXTERNAL_URL_MODAL = 'ExternalURL';

export const DOMAIN_SETTINGS_MODAL = 'DomainSettings';

export const OFFER_MODAL = 'Offer';
export const OFFER_CATEGORY_MODAL = 'OfferCategory';
export const OFFER_CATEGORY_DUPLICATE_MODAL = 'OfferCategoryDuplicate';
export const OFFER_CATEGORY_ARCHIVE_MODAL = 'OfferCategoryArchive';
export const OFFER_CATEGORY_DELETE_MODAL = 'OfferCategoryDelete';
export const OFFER_ARCHIVE_MODAL = 'OfferArchive';
export const OFFER_DELETE_MODAL = 'OfferDelete';
export const OFFER_DUPLICATE_MODAL = 'OfferDuplicate';

export const CONFIRM_MODAL = 'Confirm';

export const CREATE_NEW_ASSET_MODAL = 'CreateNewAsset';

export const FUNNELGROUP_FUNNEL_FLOW_MODAL = 'FunnelGroupFunnelFlow';

export const FUNNELGROUP_MODAL = 'FunnelsGroups';
export const FUNNELGROUP_ARCHIVE_MODAL = 'FunnelsGroupsArchive';
export const FUNNELGROUP_DELETE_MODAL = 'FunnelsGroupsDelete';
export const FUNNELGROUP_DUPLICATE_MODAL = 'FunnelsGroupsDuplicate';

export const FUNNEL_MODAL = 'FunnelsFunnels';
export const FUNNEL_DELETE_MODAL = 'FunnelsFunnelsDelete';
export const FUNNEL_ARCHIVE_MODAL = 'FunnelsFunnelsArchive';
export const FUNNEL_DUPLICATE_MODAL = 'FunnelsFunnelsDuplicate';

export const PAGE_GROUPS_MODAL = 'PageGroups';
export const PAGE_GROUPS_DUPLICATE_MODAL = 'PageGroupsDuplicate';
export const PAGE_GROUPS_CATEGORY_TYPE = 'pagegroups';
export const PAGE_GROUPS_DELETE = 'PageGroupsDelete';
export const PAGE_GROUPS_ARCHIVE_MODAL = 'PageGroupsArchive';

export const LANDER_GROUP_MODAL = 'landerGroupModal';
export const OFFER_GROUP_MODAL = 'offerGroupModal';
export const LANDER_OFFER_GROUP_MODAL = 'landerOfferGroup';

export const TRAFFIC_SOURCE_MODAL = 'TrafficSources';
export const TRAFFIC_SOURCE_ARCHIVE_MODAL = 'TrafficSourcesArchive';
export const TRAFFIC_SOURCE_DELETE_MODAL = 'TrafficSourcesDelete';
export const TRAFFIC_SOURCE_CATEGORY_MODAL = 'TrafficSourcesCategory';
export const TRAFFIC_SOURCE_CATEGORY_DUPLICATE_MODAL =
  'TrafficSourcesCategoryDuplicate';
export const TRAFFIC_SOURCE_CATEGORY_ARCHIVE_MODAL =
  'TrafficSourcesCategoryArchive';
export const TRAFFIC_SOURCE_CATEGORY_DELETE_MODAL =
  'TrafficSourcesCategoryDelete';
export const TRAFFIC_SOURCE_DUPLICATE_MODAL = 'TrafficSourcesDuplicate';

export const OFFER_SOURCE_MODAL = 'OfferSources';
export const OFFER_SOURCE_DELETE_MODAL = 'OfferSourcesDelete';
export const OFFER_SOURCE_CATEGORY_MODAL = 'OfferSourcesCategory';
export const OFFER_SOURCE_CATEGORY_DUPLICATE_MODAL =
  'OfferSourcesCategoryDuplicate';
export const OFFER_SOURCE_CATEGORY_ARCHIVE_MODAL =
  'OfferSourcesCategoryArchive';
export const OFFER_SOURCE_CATEGORY_DELETE_MODAL = 'OfferSourcesCategoryDelete';
export const OFFER_SOURCE_ARCHIVE_MODAL = 'OfferSourcesArchive';
export const OFFER_SOURCE_DUPLICATE_MODAL = 'OfferSourcesDuplicate';

export const GET_ACTION_LINK_MODAL = 'GetActionLinkModal';
export const ADD_PAGE_MODAL = 'AddPageModal';
export const ADD_PAGE_MODAL_LANDER = 'landers';
export const ADD_PAGE_MODAL_OFFER = 'offers';
export const MODIFY_ACTION_MODAL = 'ModifyActionModal';
export const MODIFY_ROUTE_MODAL = 'ModifyRouteModal';

export const SYSTEM_SETTINGS_MODAL_ADD_DOMAIN =
  'SystemSettingsModelAddDomain';

export const NAVIGATION_ADD_MODAL = 'NavigationAddModal';
export const NAVIGATION_CREATE_OR_OPEN_MODAL = 'NavigationCreateOrOpenModal';

export const RESET_DATA_DELETE_SIDEBAR = 'ResetDataDelete';

export const DOMAINS_MODAL_DELETE_DOMAIN = 'DomainsModalDeleteDomain';
export const DOMAINS_MODAL_EDIT_DNS = 'DomainsModalEditDns';
export const DOMAINS_MODAL_DELETE_DNS = 'DomainsModalDeleteDns';

export const CONDITION_MODAL = 'Condition';
export const CONDITION_DELETE_MODAL = 'ConditionDelete';
export const CONDITION_CATEGORY_MODAL = 'ConditionCategory';
export const CONDITION_CATEGORY_DELETE_MODAL = 'ConditionCategoryDelete';
export const CONDITION_CATEGORY_DUPLICATE_MODAL = 'ConditionCategoryDuplicate';
export const CONDITION_DUPLICATE_MODAL = 'ConditionDuplicate';

export const ADD_EDIT_API_KEY_MODAL = 'AddEditApiKey';
export const DELETE_API_KEY_MODAL = 'DeleteApiKey';

export const WIZARD_MODAL = 'Wizard';

export const PARTNERS_MODAL = 'Partners';

export const SIMPLE_FLOWS_MODAL = 'SimpleFlows';
export const SIMPLE_FLOWS_DUPLICATE_MODAL = 'SimpleFlowsDuplicate';

export const QUICK_STATS_MODAL = 'QuickStats';

export const categoryTypeSourceNameMap: AnyObject = {
  [OFFER_SOURCE_CATEGORY_TYPE]: 'offer sources',
  [OFFER_CATEGORY_TYPE]: 'offers',
  [TRAFFIC_SOURCE_CATEGORY_TYPE]: 'traffic sources',
  [LANDER_CATEGORY_TYPE]: 'landers',
  [CONDITION_CATEGORY_TYPE]: 'conditions'
};

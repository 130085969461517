import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="wrench-circle--crescent-tool-construction-tools-wrench-setting-edit-adjust">
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M13.934999999999999 26.874642857142856c7.146465214285715 0 12.939642857142857 -5.793177642857143 12.939642857142857 -12.939642857142857C26.874642857142856 6.788634321428571 21.081465214285714 0.9953571428571428 13.934999999999999 0.9953571428571428 6.788634321428571 0.9953571428571428 0.9953571428571428 6.788634321428571 0.9953571428571428 13.934999999999999c0 7.146465214285715 5.793277178571428 12.939642857142857 12.939642857142857 12.939642857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Intersect"
        fill="#ffffff"
        d="M16.74455479285714 26.56886914285714C15.839994128571428 26.769135 14.899819585714285 26.874642857142856 13.934999999999999 26.874642857142856c-0.9431407071428571 0 -1.8627312642857143 -0.10092921428571429 -2.7485394 -0.2924359285714286l0 -5.836973357142857c-1.2407524928571427 -0.4614475714285714 -2.3403036214285713 -1.2411307285714286 -3.1870937571428573 -2.2627453928571426 -0.90396345 -1.0905929142857143 -1.4835599142857143 -2.4130841357142856 -1.6727972142857144 -3.816915942857143 -0.18921739285714284 -1.4038119 0.019509 -2.83256745 0.6024498642857143 -4.123605385714285 0.5829209571428572 -1.2910180285714286 1.5166256785714285 -2.392420521428571 2.6948100214285713 -3.178832292857143 0.1953288857142857 -0.10485092142857143 0.4113014785714285 -0.1655079857142857 0.632649 -0.17767124999999998 0.22134752142857142 -0.012163264285714286 0.44267513571428574 0.02446587857142857 0.6483159214285714 0.1072995l0 5.558691407142857c0 1.0994316857142856 0.8912626928571429 1.9907142857142857 1.9907142857142857 1.9907142857142857h2.04328905c1.0994515928571427 0 1.9907142857142857 -0.8912826 1.9907142857142857 -1.9907142857142857l0.00001990714285714286 -5.7276234214285715c0.20629772142857142 -0.049409528571428565 0.4206777428571429 -0.0549039 0.6292249714285715 -0.01610487857142857 0.2085671357142857 0.03879902142857143 0.40662329999999997 0.12101552142857142 0.5813482928571428 0.24133429285714286 1.1617410428571429 0.7865710285714286 2.082565842857143 1.87997085 2.660072057142857 3.158586728571428 0.5775062142857144 1.2786357857142856 0.7889200714285713 2.692361442857143 0.6109502142857143 4.084010078571429 -0.17796985714285712 1.3916486357142857 -0.7383559285714285 2.7066746785714284 -1.6190678357142856 3.7988203499999997 -0.7964250642857142 0.9876928928571428 -1.8265002642857144 1.7581391357142857 -2.9942930785714283 2.2432762071428574l-0.05321179285714286 5.935115571428571Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 26.874642857142856c7.146465214285715 0 12.939642857142857 -5.793177642857143 12.939642857142857 -12.939642857142857C26.874642857142856 6.788634321428571 21.081465214285714 0.9953571428571428 13.934999999999999 0.9953571428571428 6.788634321428571 0.9953571428571428 0.9953571428571428 6.788634321428571 0.9953571428571428 13.934999999999999c0 7.146465214285715 5.793277178571428 12.939642857142857 12.939642857142857 12.939642857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Union"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11.186480507142857 26.571258 0 -5.826024428571428c-1.2407524928571427 -0.4614475714285714 -2.3403036214285713 -1.2411108214285713 -3.1870937571428573 -2.262725485714286 -0.90396345 -1.0905730071428572 -1.4835599142857143 -2.4130841357142856 -1.6727972142857144 -3.816896035714286 -0.18921739285714284 -1.403831807142857 0.019509 -2.8325873571428573 0.6024299571428571 -4.123605385714285 0.5829408642857142 -1.2910180285714286 1.5166455857142855 -2.3924404285714282 2.694829928571428 -3.178832292857143 0.19530897857142857 -0.10487082857142857 0.4113014785714285 -0.16552789285714287 0.632649 -0.17769115714285716 0.22134752142857142 -0.012163264285714286 0.44267513571428574 0.02446587857142857 0.6483159214285714 0.1072995l0 5.558691407142857c0 1.0994515928571427 0.8912626928571429 1.9907142857142857 1.9906943785714286 1.9907142857142857l2.043308957142857 0c1.0994515928571427 0 1.9907142857142857 -0.8912626928571429 1.9907142857142857 -1.9907142857142857l0 -5.727603514285714c0.20629772142857142 -0.049429435714285715 0.42069765 -0.05492380714285714 0.6292448785714285 -0.016124785714285713 0.2085671357142857 0.03879902142857143 0.40662329999999997 0.12103542857142857 0.5813482928571428 0.24133429285714286 1.1617410428571429 0.7865909357142856 2.0825459357142857 1.87997085 2.66005215 3.1586066357142855 0.5775062142857144 1.2786158785714286 0.7889200714285713 2.692361442857143 0.6109502142857143 4.084010078571429 -0.17796985714285712 1.3916486357142857 -0.7383559285714285 2.706654771428571 -1.6190479285714285 3.7988203499999997 -0.7964250642857142 0.9876928928571428 -1.8265002642857144 1.7580993214285714 -2.9942930785714283 2.243236392857143l-0.04001335714285714 5.9377035"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

import { SubscriptionStatus } from 'model/subscription';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { CompositeStore } from 'types/redux/store';
import { loggedIn, SubscriptionSelector } from '../../redux/selectors';
import './index.scss';
import { isStandByOrExpired } from 'utils/app';

const PrivateRoute = ({
  isAuthenticated,
  subscriptionStatus,
  component: Component,
  ...rest
}: {
  isAuthenticated: boolean;
  subscriptionStatus: SubscriptionStatus;
  component: any;
} & RouteProps) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        isStandByOrExpired(subscriptionStatus) ? (
          <Suspense fallback={<div id="suspense-fallback-flag" />}>
            <Component {...props} />
          </Suspense>
        ) : (
          <Suspense fallback={<div id="suspense-fallback-flag" />}>
            <Component {...props} />
          </Suspense>
        )
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default connect((state: CompositeStore) => ({
  isAuthenticated: loggedIn(state),
  ...SubscriptionSelector(state)
}))(PrivateRoute);

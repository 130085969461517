import React from 'react';

export default ({ get: getComponent }) => {
  return class AsyncComponent extends React.Component {
    static Component = null;

    constructor(props) {
      super(props);
      this.state = {
        Component: AsyncComponent.Component
      };
      this._getComponent = this._getComponent.bind(this);
    }

    async componentDidMount() {
      if (!this.state.Component) {
        await this._getComponent();
        this.setState(state => ({
          ...state,
          Component: AsyncComponent.Component
        }));
      }
    }

    async componentDidUpdate(_prevProps, prevState) {
      if (prevState.Component !== this.state.Component) {
        await this._getComponent();
        this.setState(state => ({
          ...state,
          Component: AsyncComponent.Component
        }));
      }
    }

    async _getComponent() {
      try {
        const { default: Component } = await getComponent();
        AsyncComponent.Component = Component;
      } catch (err) {
        AsyncComponent.Component = null;
      }
    }

    render() {
      const { Component } = this.state;
      if (Component) return <Component {...this.props} />;
      return null;
    }
  };
};

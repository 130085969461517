import { AnyObject } from '../types';

export const defaultRowData = (columns: string[] = []) => {
  if (!columns.length) {
    return [
      {
        cost: ''
      }
    ];
  }
  let rowData: AnyObject = {
    cost: ''
  };
  columns.forEach(item => {
    if (item !== 'cost') {
      rowData[item] = '';
    }
  });
  return [rowData];
};

export const CSV_ROWS_LIMIT = 'Cost uploads are limited to 5000 segments at this time';
export const MUST_CONTAIN_COST_COL = 'The CSV must contain a cost column header';
export const CSV_COST_SHOULD_BE_NUMBER = 'The CSV cost column must only have valid number values';
export const CSV_DATE_SHOULD_BE_DATE = 'The CSV date column does not have correct values. Please use correct date format';
export const SELECTED_COLS_DONT_MATCH = 'The uploaded CSV does not contain column headers for each of the selected fields';
export const AT_LEAST_ONE_MATCHING_COL = 'The uploaded CSV must contain at least one column header matching the selected upload template fields';
import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import ConfirmForm from 'components/Forms/Confirm';
import { archiveFunnel, getFunnelById } from 'redux/actions/funnels';
import { ModalsConsumer } from '../context';
import { FUNNEL_ARCHIVE_MODAL } from 'constants/modal';
import { ModalProps } from '../types';
import { funnelDeleteModalProps } from 'types/ModalForms/funnelArchive';
import { getNameModal } from '../../../utils/modals';
import { FormContextModal } from '../../../types/modal';
import { FunnelModalSelector } from 'redux/selectors';
import { AnyObject } from 'types';

const ArchiveText = () => (
  <div className="margin-bottom-15 color-465565">
    <p>
      <b>Archiving a funnel removes it from the active view.</b>
    </p>
    <p>
      It will not delete the funnel or data, all links will continue to work and
      data is available in reporting.
    </p>
    <p>
      Use this feature to archive funnels that are dormant but where you want to
      keep the data, and still want to be able to edit/unarchive later.
    </p>
  </div>
);

const FunnelArchiveModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  contextModal,
  funnels,
  getFunnelById
}: funnelDeleteModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    destroyOnClose
    width={547}
    title={`Archive Funnel ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['funnel-archive']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ConfirmForm
        handleSubmit={onSubmit}
        okText={`Confirm`}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        prepareData={(context: FormContextModal) => ({
          ...funnels[context.data.id],
          status: 'archived'
        })}
        contextModal={contextModal}
        text={<ArchiveText />}
        successMessage={(context: FormContextModal) =>
          `${context.data.value} has been archived`
        }
        fetchEntity={() => getFunnelById(contextModal?.entityId!)}
      />
    )}
  />
);

const ConnectedFunnelArchiveModal = ({
  archiveFunnel,
  funnels,
  getFunnelById
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <FunnelArchiveModal
            isOpen={isOpenModal(FUNNEL_ARCHIVE_MODAL)}
            onSubmit={archiveFunnel}
            closeSelf={() => closeModal(FUNNEL_ARCHIVE_MODAL)}
            contextModal={
              getContextModal(FUNNEL_ARCHIVE_MODAL) as FormContextModal
            }
            funnels={funnels}
            getFunnelById={getFunnelById}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(FunnelModalSelector, {
  archiveFunnel,
  getFunnelById
})(ConnectedFunnelArchiveModal);

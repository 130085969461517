import { AnyObject } from "types";

export interface RequestConfig {
  method: 'get' | 'post' | 'put' | 'delete';
  url: string;
  headers?: {};
  data?: AnyObject;
  params?: {};
  withoutApiUrl?: boolean;
  withoutCurrentVersion?: boolean;
  responseTransformer?: (data: any) => any;
}

export type StatusEnum = 'active' | 'archived' | 'not-deleted' | 'tabs' | 'all';

export const funnelModelKeys = [
  'idFunnel',
  'idCampaign',
  'funnelName',
  'canvasWidth',
  'canvasHeight',
  'accumulatedUrlParams',
  'customTokens',
  'incomingCostOverrides',
  'postbackOverrides',
  'nodes',
  'connections',
  'defaultCost',
  'status',
  'ipAnonymizer',
  'idIndirectFunnels',
  'isArchived',
  'owner'
];
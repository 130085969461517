import React from 'react';

const CopyDocument = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.715 -0.715 20 20" height="20" width="20">
    <g id="copy-document">
      <path
        id="Vector 137"
        stroke="#8d9aa8"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M1.7317851428571431 12.718951135714287V1.86879195C1.7317851428571431 1.202967252857143 2.2715487214285717 0.6632142857142858 2.9373628071428572 0.6632142857142858h7.2334394571428575"
        stroke-width="1.43"
      ></path>
      <path
        id="Vector 993"
        stroke="#8d9aa8"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M5.519123378571429 16.580357142857142V5.7054865928571425c0 -0.7325599714285714 0.5938553357142857 -1.3264285714285715 1.3264285714285715 -1.3264285714285715H12.724827214285714c0.5125452642857142 0 1.0041064285714287 0.20360678571428573 1.3664867142857144 0.5660268642857144l2.1809138571428575 1.9047779571428574c0.3623802857142857 0.36242007857142855 0.5659870714285715 0.8539679785714286 0.5659870714285715 1.3664999785714287V16.580357142857142c0 0.7325865 -0.5938420714285715 1.3264285714285715 -1.3264285714285715 1.3264285714285715H6.845551950000001c-0.7325732357142857 0 -1.3264285714285715 -0.5938420714285715 -1.3264285714285715 -1.3264285714285715Z"
        stroke-width="1.43"
      ></path>
      <path
        id="Vector 159"
        stroke="#8d9aa8"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12.688748357142858 4.3774397785714285v3.7960396071428573h4.1123265"
        stroke-width="1.43"
      ></path>
    </g>
  </svg>
);

export default CopyDocument;
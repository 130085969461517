import Auth from 'api/auth';
import { removeManyFromStorage } from 'utils/local-storage';
import { USER_ACTIONS } from '../types';
import { UNSAVED_FUNNEL_ROUTE_CHANGE_MESSAGE } from 'constants/builder';
import { gistUtils } from 'widgets/gist';
import { resetSegment } from 'widgets/segment'
import { loadGistAndSegmentScript } from 'utils/app';

export const userLoggedIn = user => ({
  type: USER_ACTIONS.LOGGED_IN,
  user
});

const userLoggedOut = () => ({
  type: USER_ACTIONS.LOGGED_OUT
});

export const userLogin = ({ username, password }) => (dispatch, getState) =>
  Auth.login({ username, password }).then(async loginUserData => {
    dispatch(userLoggedIn(loginUserData));
    const user = getState()?.user;
    loadGistAndSegmentScript(user);
    //setTimeout(() => dispatch(changeView('dashboard')), 0);
  });

export const changePassword = ({ email }) => () =>
  Auth.forgotPassword({ email });

export const logout = () => (dispatch, getState) => {
  const hasBuilderChangedData = getState().funnels.hasBuilderChangedData;
  const logoutProcess = () => {
    Auth.logout().then(() => {
      dispatch(userLoggedOut());
      gistUtils.close();
      gistUtils.shutdown();
			resetSegment();
      removeManyFromStorage('store.user');
    });
  };

  if (
    window.location.pathname.includes('funneleditor') &&
    hasBuilderChangedData
  ) {
    const confirmLogout = window.confirm(UNSAVED_FUNNEL_ROUTE_CHANGE_MESSAGE);
    if (confirmLogout) {
      logoutProcess();
    }
  } else {
    logoutProcess();
  }

  return Promise.resolve();
};

import React, { PropsWithChildren } from 'react';
import { Select, SelectProps } from 'antd';
import { FFSelectOption } from 'uikit/types/select';
import { conditionalClass } from 'conditional-class';
import { defined, definedObject } from 'utils/define';
import './style.scss';

interface OptionExtraParams {
  category?: string;
  className?: string;
}

const getOptions = <T extends OptionExtraParams, P extends string>(
  options: T[],
  valueGetter: (opt: T) => P,
  labelGetter: (opt: T) => P,
  categoryGetter: (opt: T) => string,
  groupOptions: boolean,
  sortGroup: boolean,
  checkDisabled: (value: string) => boolean
) => {
  let categorizedOptions: { [key: string]: T[] } = {};
  let result: JSX.Element[] = [];

  categorizedOptions.Uncategorized = [];
  options.forEach(opt => {
    if (categoryGetter(opt)) {
      if (!categorizedOptions[categoryGetter(opt)]) {
        categorizedOptions[categoryGetter(opt)] = [];
      }
      categorizedOptions[categoryGetter(opt)].push(opt);
    } else {
      categorizedOptions.Uncategorized.push(opt);
    }
  });

  const categories = Object.keys(categorizedOptions);
  if (sortGroup) {
    categories.sort();
  }
  if (groupOptions) {
    categories.forEach(category => {
      if (categorizedOptions[category].length > 0) {
        result.push(
          <Select.OptGroup label={category} key={category}>
            {categorizedOptions[category].map(opt => (
              <Select.Option
                key={valueGetter(opt)}
                value={valueGetter(opt)}
                label={labelGetter(opt)}
                className={opt.className}
                disabled={checkDisabled(valueGetter(opt))}
              >
                {labelGetter(opt)}
              </Select.Option>
            ))}
          </Select.OptGroup>
        );
      }
    });
  } else {
    Object.values(categorizedOptions).forEach(options => {
      options.forEach(option => {
        result.push(
          <Select.Option
            key={valueGetter(option)}
            value={valueGetter(option)}
            label={labelGetter(option)}
            disabled={checkDisabled(valueGetter(option))}
            className={option.className}
          >
            {labelGetter(option)}
          </Select.Option>
        );
      });
    });
  }
  return result;
};

const FFSelect = <T, P extends string>({
  className,
  options,
  valueGetter,
  labelGetter,
  categoryGetter = (opt: T) => (opt as any).category,
  createNewView,
  selectAll = false,
  value,
  size = 'middle',
  groupOptions = false,
  maxTagCount = 'responsive',
  sortGroup = false,
  defaultValueFallback,
  optionFilterProp = 'label',
  onChange = () => {},
  checkDisabled = () => false,
  dropdownClassName,
  error,
  ...props
}: Omit<SelectProps, 'options' | 'onChange'> & {
  options: T[];
  valueGetter: (opt: T) => P;
  labelGetter: (opt: T) => P;
  categoryGetter?: (opt: T) => string;
  onChange?: (val: any) => void;
  createNewView?: FFSelectOption;
  selectAll?: boolean;
  groupOptions?: boolean;
  sortGroup?: boolean;
  groupBy?: string;
  defaultValueFallback?: {
    label: string;
    value: string;
  };
  error?: string;
  checkDisabled?: (value: string) => boolean;
}) => {
  return (
    <div
      className={conditionalClass('c-ffSelect', {
        [`${className}`]: defined(className)
      })}
    >
      <Select
        {...props}
        popupClassName={dropdownClassName}
        className={conditionalClass('c-ffSelect__select', {
          [`${className}`]: defined(className),
          [`${className}__select`]: defined(className),
          'c-ffSelect--sizeSmall': size === 'small',
          'c-ffSelect--sizeMiddle': size === 'middle',
          'c-ffSelect--sizeLarge': size === 'large',
          'c-ffSelect--error': !!error
        })}
        size={size}
        value={!value ? undefined : value}
        maxTagCount={maxTagCount}
        optionFilterProp={optionFilterProp}
        onChange={value => {
          if (onChange) {
            if (Array.isArray(value) && value.includes('selectAll')) {
              onChange(options.map(valueGetter));
            } else if (Array.isArray(value) && value.includes('clearAll')) {
              onChange([]);
            } else {
              onChange(value);
            }
          }
        }}
      >
        {definedObject(createNewView) && (
          <Select.Option key={createNewView.value} value={createNewView.value} className="c-ffSelect__createNewView">
            {createNewView.label}
          </Select.Option>
        )}

        {selectAll && Array.isArray(value) && value.length !== options.length && (
          <Select.Option key="selectAll" value="selectAll" className="c-ffSelect__selectAll">
            Select All
          </Select.Option>
        )}

        {selectAll && Array.isArray(value) && value.length === options.length && (
          <Select.Option key="clearAll" value="clearAll" className="c-ffSelect__clearAll">
            Clear All
          </Select.Option>
        )}

        {definedObject(defaultValueFallback) && (
          <Select.Option key={defaultValueFallback.value} value={defaultValueFallback.value} className="uncategorized">
            {defaultValueFallback.label}
          </Select.Option>
        )}

        {getOptions<T, P>(options, valueGetter, labelGetter, categoryGetter, groupOptions, sortGroup, checkDisabled)}
      </Select>
    </div>
  );
};

export const FFSelectTagElement = ({ onClick, children }: PropsWithChildren<{ onClick: () => void; }>) => (
  <div className="ant-select-selection-overflow-item" style={{ opacity: 1 }}>
    <span>
      <span className="ant-select-selection-item">
        {children}
        <span
          className="ant-select-selection-item-remove"
          unselectable="on"
          aria-hidden="true"
          style={{ userSelect: 'none', margin: '0px 3px' }}
          onClick={onClick}
        >
          <span role="img" aria-label="close" className="anticon anticon-close">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
            </svg>
          </span>
        </span>
      </span>
    </span>
  </div>
);

export default FFSelect;

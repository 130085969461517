import React from 'react';
import { connect } from 'react-redux';

import FluxModal from 'components/Modal';

import FunnelGroupDeleteForm from '../../Forms/FunnelGroupDelete';

import { deleteFunnelGroup, fetchFunnelGroupsInfo } from 'redux/actions/funnelGroups';
import { updateFunnel, deleteFunnel, moveFunnels } from 'redux/actions/funnels';

import { ModalsConsumer } from '../context';
import { FUNNELGROUP_DELETE_MODAL } from 'constants/modal';
import { FunnelGroupsArchiveAndDeleteModalSelector } from 'redux/selectors';
import { ModalProps } from '../types';
import { FunnelGroupDeleteModalProps } from '../../../types/ModalForms/funnelGroupDelete';
import { getNameModal } from '../../../utils/modals';
import { FormContextModal } from '../../../types/modal';
import { AnyObject } from 'types';

const FunnelGroupDeleteModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  funnelGroups,
  funnels,
  contextModal,
  deleteFunnel,
  moveFunnels,
  showStatus,
  fetchFunnelGroupsInfo
}: FunnelGroupDeleteModalProps) => (
  <FluxModal
    destroyOnClose
    zIndex={contextModal.zIndex}
    width={547}
    title={`Delete Funnel Group ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['campaign-delete']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <FunnelGroupDeleteForm
        fetchFunnelGroupsInfo={fetchFunnelGroupsInfo}
        showStatus={showStatus}
        handleSubmit={onSubmit}
        funnelGroups={funnelGroups}
        handleDeleteFunnel={deleteFunnel}
        handleMoveFunnels={moveFunnels}
        contextModal={contextModal}
        funnels={funnels}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
      />
    )}
  />
);

const ConnectedFunnelGroupDeleteModal = ({ funnels,
  funnelGroups,
  deleteFunnelGroup,
  deleteFunnel,
  moveFunnels,
  showStatus,
  fetchFunnelGroupsInfo
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <FunnelGroupDeleteModal
            fetchFunnelGroupsInfo={fetchFunnelGroupsInfo}
            showStatus={showStatus}
            isOpen={isOpenModal(FUNNELGROUP_DELETE_MODAL)}
            deleteFunnel={deleteFunnel}
            moveFunnels={moveFunnels}
            funnels={funnels}
            funnelGroups={funnelGroups}
            onSubmit={deleteFunnelGroup}
            closeSelf={() => closeModal(FUNNELGROUP_DELETE_MODAL)}
            contextModal={
              getContextModal(FUNNELGROUP_DELETE_MODAL) as FormContextModal
            }
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(FunnelGroupsArchiveAndDeleteModalSelector, {
  deleteFunnelGroup,
  updateFunnel,
  fetchFunnelGroupsInfo,
  deleteFunnel,
  moveFunnels
})(ConnectedFunnelGroupDeleteModal);

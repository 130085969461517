import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import {
  SIMPLE_FLOWS_DUPLICATE_MODAL,
  SIMPLE_FLOWS_MODAL
} from 'constants/modal';
import { connect } from 'react-redux';
import { SimpleFlowsModalSelector } from 'redux/selectors';
import { getModalTitle, isCopyByContextModal } from 'utils/modals';
import { FormContextSidebar } from 'types/modal';
import SimpleFlowsForm from 'components/Forms/SimpleFlows';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import {
  SIMPLE_FLOWS_FORM_ADVANCED_FLOW_SETTINGS_TAB,
  SIMPLE_FLOWS_FORM_CONFIGURE_PATHS_TAB,
  SIMPLE_FLOWS_FORM_CONFIGURE_ROUTING_TAB,
  SIMPLE_FLOWS_FORM_CONFIGURE_RULES_TAB,
  SIMPLE_FLOWS_FORM_GET_REDIRECT_LINKS_TAB,
  SIMPLE_FLOWS_FORM_HELP_TAB,
  SIMPLE_FLOWS_FORM_PAGE_ACTION_LINKS_TAB,
  SIMPLE_FLOWS_FORM_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import {
  SimpleFlowsFormProps,
  SimpleFlowsSidebarProps,
  SimpleFlowsTabKey
} from 'types/ModalForms/simpleFlows';
import PathEditor from 'components/Forms/SimpleFlows/components/PathEditor';
import RuleEditor from 'components/Forms/SimpleFlows/components/RuleEditor';
import { SimpleFlowPath } from 'model/simpleFlowPath';
import {
  fetchOffersCategoryInfo,
  fetchOffersInfo,
  getOfferById
} from 'redux/actions/offers';
import {
  fetchLandersCategoryInfo,
  fetchLandersInfo,
  getLanderById
} from 'redux/actions/landers';
import { SimpleFlowRule } from 'model/simpleFlowRules';
import {
  fetchTrafficSourcesCategoryInfo,
  fetchTrafficSources
} from 'redux/actions/trafficsources';
import { duplicateFunnel, addFunnel, updateFunnel, getFunnelById } from 'redux/actions/funnels';
import { fetchFunnelGroupsInfo } from 'redux/actions/funnelGroups';
import {
  addCondition,
  updateCondition,
  getConditionById
} from 'redux/actions/conditions';
import {
  addPageGroup,
  fetchPageGroups,
  updatePageGroup
} from 'redux/actions/pageGroups';
import { fetchDomains } from 'redux/actions/domains';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import { FFIcon } from 'uikit';

interface State {
  pathEditorModel: SimpleFlowPath;
  ruleEditorModel: SimpleFlowRule;
  allPaths: SimpleFlowPath[];
  allRules: SimpleFlowRule[];
  updatePathCallback: (path: SimpleFlowPath) => void;
  updateRuleCallback: (rule: SimpleFlowRule) => void;
}

class SimpleFlowsFormWrapper extends React.Component<
  Omit<
    SimpleFlowsFormProps,
    | 'openSidebar'
    | 'setPathEditorModel'
    | 'setRuleEditorModel'
    | 'setButtonGroupProps'
    | 'setPathUpdateCallback'
    | 'setRuleUpdateCallback'
    | 'setSidebarLoading'
    | 'onUpdatePathsList'
    | 'onUpdateRulesList'
    | 'setForCopyIdsProps'
    | 'setTabDisabledState'
    | 'resetDisabledTabs'
  > &
    SimpleFlowsSidebarProps &
    LoadingProps,
  State
> {
  state: State = {
    pathEditorModel: {
      pathId: '',
      pathName: '',
      groups: []
    },
    ruleEditorModel: {
      ruleId: '',
      ruleName: '',
      rule: {
        operator: 'or',
        groups: [],
        routeName: ''
      }
    },
    allPaths: [],
    allRules: [],
    updatePathCallback: () => {},
    updateRuleCallback: () => {}
  };

  handleState = <T extends State, P extends keyof T>(key: P, value: T[P]) => {
    this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  setPathEditorModel = (model: SimpleFlowPath) => {
    this.handleState('pathEditorModel', model);
  };

  setRuleEditorModel = (model: SimpleFlowRule) => {
    this.handleState('ruleEditorModel', model);
  };

  render() {
    return (
      <DynamicSideBar
        isOpen={this.props.isOpen!}
        hasTab={true}
        loading={this.props.loading}
        onClose={this.props.closeSelf}
        defaultOpenSideBarKey="simpleFlowsForm"
        defaultActiveTab={SIMPLE_FLOWS_FORM_TAB}
        dataPortalKey="simpleFlows"
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'simpleFlowsForm',
            tabs: {
              [SIMPLE_FLOWS_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [SIMPLE_FLOWS_FORM_CONFIGURE_PATHS_TAB]: {
                title: 'Configure Paths',
                icon: <FFIcon name="flowPath" />
              },
              [SIMPLE_FLOWS_FORM_CONFIGURE_RULES_TAB]: {
                title: 'Configure Rules',
                icon: <FFIcon name="outlinedCondition" />
              },
              [SIMPLE_FLOWS_FORM_CONFIGURE_ROUTING_TAB]: {
                title: 'Configure Routing',
                icon: <FFIcon name="routing" />
              },
              [SIMPLE_FLOWS_FORM_GET_REDIRECT_LINKS_TAB]: {
                title: 'Get Redirect Links',
                icon: <FFIcon name="redirectLink" />
              },
              [SIMPLE_FLOWS_FORM_PAGE_ACTION_LINKS_TAB]: {
                title: 'Page Action Links',
                icon: <FFIcon name="actionLink" />,
                isHidden: isCopyByContextModal(this.props.contextModal)
              },
              [SIMPLE_FLOWS_FORM_ADVANCED_FLOW_SETTINGS_TAB]: {
                title: 'Advanced Flow Settings',
                icon: <FFIcon name="advancedSettings" />
              },
              [SIMPLE_FLOWS_FORM_HELP_TAB]: {
                title: 'Help',
                icon: <FFIcon name="question" />
              }
            },
            title: `${getModalTitle(this.props.contextModal)} Flow`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setLoading,
              sidebarLoading,
              setTabsError,
              tabTitle,
              open,
              setForCopyIdsProps,
              setTabDisabledState,
              resetDisabledTabs
            }) => (
              <SimpleFlowsForm
                {...this.props}
                activeTab={activeTab as SimpleFlowsTabKey}
                setForCopyIdsProps={setForCopyIdsProps}
                setButtonGroupProps={setButtonGroupProps}
                setSidebarLoading={setLoading}
                sidebarLoading={sidebarLoading}
                tabTitle={tabTitle}
                setTabsError={setTabsError}
                openSidebar={open}
                setPathEditorModel={this.setPathEditorModel}
                setRuleEditorModel={this.setRuleEditorModel}
                setPathUpdateCallback={callback =>
                  this.handleState('updatePathCallback', callback)
                }
                setRuleUpdateCallback={callback =>
                  this.handleState('updateRuleCallback', callback)
                }
                onUpdatePathsList={paths => this.handleState('allPaths', paths)}
                onUpdateRulesList={rules => this.handleState('allRules', rules)}
                setTabDisabledState={setTabDisabledState}
                resetDisabledTabs={resetDisabledTabs}
              />
            )
          },
          {
            key: 'simpleFlowsPathEditor',
            title: 'Edit Path',
            hasHeader: true,
            render: ({
              setLoading,
              sidebarLoading,
              setButtonGroupProps,
              close
            }) => (
              <PathEditor
                model={this.state.pathEditorModel}
                onUpdate={model => {
                  this.setPathEditorModel(model);
                  this.state.updatePathCallback(model);
                }}
                pagesArray={this.props.pagesArray}
                fetchLandersCategoryInfo={this.props.fetchLandersCategoryInfo}
                fetchOffersCategoryInfo={this.props.fetchOffersCategoryInfo}
                setSidebarLoading={setLoading}
                sidebarLoading={sidebarLoading}
                landers={this.props.landers}
                offers={this.props.offers}
                setButtonGroupProps={setButtonGroupProps}
                onClose={() => close('simpleFlowsPathEditor')}
                allPaths={this.state.allPaths}
                categories={this.props.categories}
              />
            )
          },
          {
            key: 'simpleFlowsRuleEditor',
            title: 'Edit Rule',
            hasHeader: true,
            render: ({
              setLoading,
              sidebarLoading,
              setButtonGroupProps,
              close
            }) => (
              <RuleEditor
                model={this.state.ruleEditorModel}
                onModelUpdate={model => {
                  this.setRuleEditorModel(model);
                  this.state.updateRuleCallback(model);
                }}
                setSidebarLoading={setLoading}
                sidebarLoading={sidebarLoading}
                setButtonGroupProps={setButtonGroupProps}
                trafficSources={this.props.trafficSources}
                fetchTrafficSourcesCategoryInfo={
                  this.props.fetchTrafficSourcesCategoryInfo
                }
                onClose={() => close('simpleFlowsRuleEditor')}
                allRules={this.state.allRules}
              />
            )
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(SimpleFlowsFormWrapper);

const ConnectedFormElement = ({
  funnelGroupsArray,
  pagesArray,
  offers,
  landers,
  trafficSources,
  fetchOffersCategoryInfo,
  fetchLandersCategoryInfo,
  fetchTrafficSourcesCategoryInfo,
  updateFunnel,
  addFunnel,
  duplicateFunnel,
  fetchFunnelGroupsInfo,
  getFunnelById,
  funnelsArray,
  userSettings,
  addCondition,
  updateCondition,
  conditionArray,
  getConditionById,
  addPageGroup,
  updatePageGroup,
  pageGroupsArray,
  categories,
  fetchPageGroups,
  domains,
  fetchDomains,
  getOfferById,
  getLanderById,
  fetchLandersInfo,
  fetchOffersInfo,
  fetchTrafficSources,
  domain
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let sidebarName = SIMPLE_FLOWS_MODAL;
        let contextSidebar = getContextSidebar(
          SIMPLE_FLOWS_MODAL
        ) as FormContextSidebar;

        if (isOpenSidebar(SIMPLE_FLOWS_DUPLICATE_MODAL)) {
          sidebarName = SIMPLE_FLOWS_DUPLICATE_MODAL;
          contextSidebar = getContextSidebar(
            SIMPLE_FLOWS_DUPLICATE_MODAL
          ) as FormContextSidebar;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            funnelsArray
          );
        }
        const isOpen =
          isOpenSidebar(SIMPLE_FLOWS_MODAL) ||
          isOpenSidebar(SIMPLE_FLOWS_DUPLICATE_MODAL);

        return (
          <FormElement
            domain={domain}
            fetchTrafficSources={fetchTrafficSources}
            getOfferById={getOfferById}
            getLanderById={getLanderById}
            fetchOffersInfo={fetchOffersInfo}
            fetchLandersInfo={fetchLandersInfo}
            domains={domains}
            userSettings={userSettings}
            fetchPageGroups={fetchPageGroups}
            pageGroupsArray={pageGroupsArray}
            addPageGroup={addPageGroup}
            updatePageGroup={updatePageGroup}
            addCondition={addCondition}
            getConditionById={getConditionById}
            updateCondition={updateCondition}
            conditionArray={conditionArray}
            contextModal={contextSidebar}
            isOpen={isOpen}
            closeSelf={() => closeSidebar(sidebarName)}
            zIndex={contextSidebar.zIndex!}
            offsetRight={getOffsetRight(sidebarName)}
            funnelGroupsArray={funnelGroupsArray}
            pagesArray={pagesArray}
            fetchOffersCategoryInfo={fetchOffersCategoryInfo}
            fetchLandersCategoryInfo={fetchLandersCategoryInfo}
            fetchTrafficSourcesCategoryInfo={fetchTrafficSourcesCategoryInfo}
            fetchFunnelGroupsInfo={fetchFunnelGroupsInfo}
            addFunnel={addFunnel}
            duplicateFunnel={duplicateFunnel}
            getFunnelById={getFunnelById}
            funnelsArray={funnelsArray}
            updateFunnel={updateFunnel}
            offers={offers}
            landers={landers}
            trafficSources={trafficSources}
            categories={categories}
            fetchDomains={fetchDomains}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(SimpleFlowsModalSelector, {
  fetchOffersCategoryInfo,
  fetchLandersCategoryInfo,
  fetchTrafficSourcesCategoryInfo,
  fetchTrafficSources,
  addFunnel,
  duplicateFunnel,
  updateFunnel,
  addCondition,
  updateCondition,
  fetchFunnelGroupsInfo,
  getFunnelById,
  getConditionById,
  addPageGroup,
  updatePageGroup,
  fetchPageGroups,
  fetchDomains,
  getOfferById,
  getLanderById,
  fetchLandersInfo,
  fetchOffersInfo
})(ConnectedFormElement);

import React, { Component } from 'react';
import Messages from 'components/Messages';
import { withLoading } from 'components/Loading';
import { NodeDeleteFormProps } from '../../../types/ModalForms/nodeDelete';
import { CancellablePromise, DropDownOptions } from 'types';
import { FormContextModal } from '../../../types/modal';
import { firstLetterToUppercase } from 'utils/string';
import { Page } from 'model/page';
import { defined } from 'utils/define';
import { FFButton } from 'uikit';
import './style.scss';
import makeCancellablePromise, { timeoutPromise } from 'utils/promises';

interface State {
  isHolding: boolean;
  selectOptions: any[];
}

class NodeDeleteForm extends Component<NodeDeleteFormProps, State> {
  private submitPromise: Partial<CancellablePromise> = {};

  state: State = {
    isHolding: false,
    selectOptions: []
  };

  static defaultProps: Partial<NodeDeleteFormProps> = {
    prepareData: (context: FormContextModal) => ({
      id: context.entityId!,
      entries: []
    })
  };

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  static getDerivedStateFromProps(props: NodeDeleteFormProps, _state: State) {
    return {
      selectOptions: (props.data as Page[]).reduce(
        (acc: DropDownOptions[], item: Page) => {
          if (
            defined(item.idPage) &&
            item.idPage !== props.contextModal.data.id
          ) {
            acc.push({
              value: item.idPage,
              label: item.pageName
            });
          }
          return acc;
        },
        []
      )
    };
  }

  handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.handleState('isHolding', true);
    this.submitPromise = makeCancellablePromise(timeoutPromise(1000));

    try {
      await this.submitPromise.promise;
      this.handleState('isHolding', false);
      this.props.startLoading('ok');
      await this.props.handleSubmit({
        entries: [this.props.contextModal.data.id]
      });
      this.props.showMessage(
        Messages.success(`'${this.props.contextModal.data.value}' has been deleted`)
      );
      this.props.stopLoading('all');
      this.props.actions.onOk();
    } catch (error) {
      this.props.showMessage(Messages.failed(`failed to delete`));
      this.props.stopLoading('all');
      this.props.actions.onClose();
    }
  };

  handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.actions.onClose();
  };

  getType = () => {
    if (this.props.type === 'offer') {
      return `an ${this.props.type}`;
    }

    return `a ${this.props.type}`;
  };

  onMouseLeaveOrUp = () => {
    if (!this.submitPromise?.cancel) return;
    this.handleState('isHolding', false);
    this.submitPromise.cancel();
    this.props.stopLoading('all');
  };

  render() {
    return (
      <div className="c-nodeDelete__contentWrapper">
        <>
          {this.props.customContent ? (
            <>{this.props.customContent}</>
          ) : (
            <>
              <p className="c-nodeDelete__text c-nodeDelete__text--strong">
                Deleting {this.getType()} removes it from this page but not from
                existing stats or funnels.
              </p>
              <p className="c-nodeDelete__text">
                This action is not reversible and it will NOT break funnels that
                use that page – {this.getType()} nodes/groups will remain and
                use the existing page URL and config, so this action does not
                disrupt existing tracking.
              </p>
              <p className="c-nodeDelete__text">
                However, you will not be able to add the page to funnels
                anymore, and it will not show as an item to group/filter by in
                reporting sections.
              </p>
              <p className="c-nodeDelete__text">
                The {this.props.type} will still show in reporting as “
                {firstLetterToUppercase(this.props.type)} Name (deleted)”.
              </p>
              <p className="c-nodeDelete__text">
                Click delete below to confirm removal.
              </p>
            </>
          )}

          <div className="flex flex-gap-5">
            <FFButton
              onMouseDown={this.handleSubmit}
              onMouseLeave={this.onMouseLeaveOrUp}
              onMouseUp={this.onMouseLeaveOrUp}
              loading={this.state.isHolding}
              disabled={this.props.loading.ok}
              loadingType={this.props.loading.ok ? 'default' : 'skeleton'}
              color="dangerColor"
              className="flex-1"
            >
              Hold to Delete
            </FFButton>
            <FFButton
              onClick={this.handleCancel}
              disabled={this.props.loading.ok}
              className="flex-1"
            >
              Cancel
            </FFButton>
          </div>
        </>
      </div>
    );
  }
}

export default withLoading(Messages.injectIn(NodeDeleteForm));

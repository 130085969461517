import { conditionalClass } from 'conditional-class';
import { copy } from 'utils/copy';
import Messages from 'components/Messages';
import React, { RefObject } from 'react';
import { FFIcon, FFTooltip, VisibilityWrapper } from 'uikit';
import './style.scss';
import { MessageProps } from 'types';
import {
  getJsDefaultTrackingParams,
  getGenericViewCodeFull,
  getGenericViewCodeSingle,
  getOfferSourceConversionCodeFull,
  getOfferSourceConversionCodeSingle,
  getOfferConversionCodeFull,
  getOfferConversionCodeSingle,
  getGenericConversionCodeFull,
  getGenericConversionCodeSingle,
  getCustomEventCodeSingle,
  getOfferPostbackUrl,
  getFunnelDirectLink,
  getFunnelRedirectLink
} from 'utils/linkJs';
import { FunnelNode } from 'model/funnelNode';
import { TrafficSource } from 'model/trafficSource';

type CodeType =
  | 'offerSourceConversionFull'
  | 'offerSourceConversionSingle'
  | 'offerConversionFull'
  | 'offerConversionSingle'
  | 'genericViewFull'
  | 'genericViewSingle'
  | 'genericConversionFull'
  | 'genericConversionSingle'
  | 'customEventCodeSingle'
  | 'defaultParams'
  | 'funnelRedirectLink'
  | 'funnelDirectLink'
  | 'offerPostbackUrl';

interface CommonProps {
  domain: string;
  idPage: string;
  idFunnel: string;
  idTrafficSource: string;
  cost: string;
  pid: string;
  vid: string;
  idNode: string;
  pageBaseUrl: string;
  postbackPayout: string;
  postbackTxId: string;
  postbackSubId: string;
  funnelNodes: FunnelNode[];
  trafficSource: TrafficSource;
  appendTsParamsToDirectLink: boolean;
  directLinkWithSeparator: boolean;
}

const getCode = (codeType: CodeType, commonProps: CommonProps) => {
  const {
    domain,
    idPage,
    idFunnel,
    postbackPayout,
    postbackSubId,
    postbackTxId,
    idTrafficSource,
    pageBaseUrl,
    idNode,
    cost,
    trafficSource,
    funnelNodes,
    appendTsParamsToDirectLink,
    directLinkWithSeparator
  } = commonProps;

  switch (codeType) {
    case 'offerSourceConversionFull':
      return getOfferSourceConversionCodeFull(
        domain,
        postbackPayout,
        postbackTxId
      );

    case 'offerSourceConversionSingle':
      return getOfferSourceConversionCodeSingle(
        postbackPayout,
        postbackTxId
      );

    case 'offerConversionFull':
      return getOfferConversionCodeFull(
        domain,
        postbackPayout,
        postbackTxId
      );

    case 'offerConversionSingle':
      return getOfferConversionCodeSingle(
        postbackPayout,
        postbackTxId
      );

    case 'genericViewFull':
      return getGenericViewCodeFull(domain!);

    case 'genericViewSingle':
      return getGenericViewCodeSingle();

    case 'genericConversionFull':
      return getGenericConversionCodeFull(domain);

    case 'genericConversionSingle':
      return getGenericConversionCodeSingle();

    case 'customEventCodeSingle':
      return getCustomEventCodeSingle();
  
    case 'defaultParams':
      return getJsDefaultTrackingParams(idPage, idFunnel);

    case 'offerPostbackUrl':
      return getOfferPostbackUrl(
        domain,
        postbackPayout,
        postbackSubId,
        postbackTxId,
        idPage,
        false
      );

    case 'funnelDirectLink':
      return getFunnelDirectLink(
        domain,
        idFunnel,
        idTrafficSource,
        idPage,
        pageBaseUrl,
        idNode,
        cost,
        trafficSource,
        appendTsParamsToDirectLink,
        directLinkWithSeparator
      );

    case 'funnelRedirectLink':
      return getFunnelRedirectLink(
        domain,
        funnelNodes,
        idFunnel,
        idTrafficSource,
        idNode,
        cost,
        trafficSource
      );
  }
};

const CodeSnippet = ({
  code,
  codeType,
  className = '',
  maxContent = false,
  forwardedRef,
  multiline,
  disabled,
  placeholder,
  showMessage,
  error,
  formatter = (code) => code,
  onCopy,
  ...props
}: {
  code?: string;
  className?: string;
  forwardedRef?: RefObject<HTMLDivElement>;
  maxContent?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  codeType?: CodeType;
  formatter?: (code: string) => string;
  onCopy?: () => void;
  'data-testid'?: string;
} & MessageProps &
  Partial<CommonProps>) => {
    const onCopyClick = () => {
      copy(forwardedRef!, showMessage, Messages)
      if (onCopy) {
        onCopy()
      }
    }

    return (
      <div
        data-testid={props['data-testid']}
        className={conditionalClass([
          'c-codeSnippet',
          className,
          maxContent ? 'c-codeSnippet--maxContent' : '',
          multiline ? 'c-codeSnippet--multiline' : '',
          disabled ? 'c-codeSnippet--disabled' : ''
        ])}
        ref={forwardedRef}
      >
        <pre className="c-codeSnippet__code">
          {codeType
            ? formatter(
                getCode(codeType, {
                  domain: props.domain!,
                  idPage: props.idPage!,
                  idFunnel: props.idFunnel!,
                  pid: props.pid!,
                  vid: props.vid!,
                  idNode: props.idNode!,
                  idTrafficSource: props.idTrafficSource!,
                  cost: props.cost!,
                  pageBaseUrl: props.pageBaseUrl!,
                  funnelNodes: props.funnelNodes || [],
                  trafficSource: props.trafficSource!,
                  appendTsParamsToDirectLink: props.appendTsParamsToDirectLink!,
                  directLinkWithSeparator: props.directLinkWithSeparator!,
                  postbackPayout: props.postbackPayout!,
                  postbackTxId: props.postbackTxId!,
                  postbackSubId: props.postbackSubId!
                })
              )
            : formatter(code || '') || placeholder}
        </pre>
        <div className="c-codeSnippet__suffix">
          <VisibilityWrapper visible={!!error}>
            <FFTooltip title={error} placement="top">
              <div>
                <FFIcon
                  name="information"
                  className="c-codeSnippet__errorIcon"
                  size="small"
                />
              </div>
            </FFTooltip>
          </VisibilityWrapper>
          <VisibilityWrapper visible={!error}>
            <FFTooltip title="Copy" placement="top">
              <div className="c-codeSnippet__copyBtnBox" onClick={onCopyClick}>
                <FFIcon
                  name="copy"
                  className="c-codeSnippet__copyBtn"
                />
              </div>
            </FFTooltip>
          </VisibilityWrapper>
        </div>
      </div>
    )
  };

export default Messages.injectIn(CodeSnippet);

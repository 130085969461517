import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import NodeDeleteForm from 'components/Forms/NodeDelete';
import { OfferDeleteModalSelector } from 'redux/selectors';
import { deleteOffer } from 'redux/actions/offers';
import { ModalsConsumer } from '../context';
import { OFFER_DELETE_MODAL } from 'constants/modal';
import { ModalProps } from '../types';
import { OfferDeleteModalProps } from 'types/ModalForms/offerDelete';
import { getNameModal } from '../../../utils/modals'
import { FormContextModal } from '../../../types/modal';
import { AnyObject } from 'types';

const OfferDeleteModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  offers,
  contextModal
}: OfferDeleteModalProps) => (
  <FluxModal
    width={422}
    destroyOnClose
    title={`Delete Offer ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['offer-delete']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <NodeDeleteForm
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        prepareData={(context: FormContextModal) => ({
          entries: [context.data.id]
        })}
        data={offers}
        contextModal={contextModal}
        type="offer"
      />
    )}
  />
);

const ConnectedOfferDeleteModal = ({ deleteOffer, offers }: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <OfferDeleteModal
            isOpen={isOpenModal(OFFER_DELETE_MODAL)}
            onSubmit={deleteOffer}
            contextModal={getContextModal(OFFER_DELETE_MODAL) as FormContextModal}
            offers={offers}
            closeSelf={() => closeModal(OFFER_DELETE_MODAL)}
          />
        </>
      )}
    </ModalsConsumer>
  )
}

export default connect(
  OfferDeleteModalSelector,
  { deleteOffer }
)(ConnectedOfferDeleteModal);

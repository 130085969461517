import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="file-dollar--common-money-currency-cash-file">
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M16.921071428571427 0.9953571428571428h-13.934999999999999C2.4580941857142857 0.9953571428571428 1.9517560071428572 1.205092827857143 1.578423422142857 1.578423422142857 1.205092827857143 1.9517560071428572 0.9953571428571428 2.4580941857142857 0.9953571428571428 2.9860714285714285v21.89785714285714c0 0.5279374285714286 0.209735685 1.0343751428571428 0.5830662792857143 1.4076340714285713 0.373332585 0.37325892857142856 0.8796707635714285 0.5830802142857142 1.4076480064285715 0.5830802142857142h21.89785714285714c0.5279374285714286 0 1.0343751428571428 -0.20982128571428568 1.4076340714285713 -0.5830802142857142s0.5830802142857142 -0.8796966428571429 0.5830802142857142 -1.4076340714285713v-13.934999999999999l-9.95357142857143 -9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#ffffff"
        d="M16.921071428571427 9.95357142857143V0.9953571428571428l9.95357142857143 9.95357142857143H17.916428571428572c-0.26398862142857143 0 -0.5171676642857144 -0.10487082857142857 -0.7038170357142857 -0.2915401071428571C17.02594225714286 10.470739092857142 16.921071428571427 10.21756005 16.921071428571427 9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.921071428571427 9.95357142857143V0.9953571428571428l9.95357142857143 9.95357142857143H17.916428571428572c-0.26398862142857143 0 -0.5171676642857144 -0.10487082857142857 -0.7038170357142857 -0.2915401071428571C17.02594225714286 10.470739092857142 16.921071428571427 10.21756005 16.921071428571427 9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.921071428571427 0.9953571428571428h-13.934999999999999C2.4580941857142857 0.9953571428571428 1.9517560071428572 1.205092827857143 1.578423422142857 1.578423422142857 1.205092827857143 1.9517560071428572 0.9953571428571428 2.4580941857142857 0.9953571428571428 2.9860714285714285v21.89785714285714c0 0.5279374285714286 0.209735685 1.0343751428571428 0.5830662792857143 1.4076340714285713 0.373332585 0.37325892857142856 0.8796707635714285 0.5830802142857142 1.4076480064285715 0.5830802142857142h21.89785714285714c0.5279374285714286 0 1.0343751428571428 -0.20982128571428568 1.4076340714285713 -0.5830802142857142s0.5830802142857142 -0.8796966428571429 0.5830802142857142 -1.4076340714285713v-13.934999999999999l-9.95357142857143 -9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.921071428571427 18.911785714285713h5.972142857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.401532878571428 10.396027585714286c-0.11653641428571429 -0.32968219285714284 -0.29633772857142854 -0.6294638571428571 -0.5248319142857143 -0.8847530571428571 -0.48603289285714285 -0.5430270428571429 -1.1923184142857144 -0.8847729642857143 -1.9784315785714286 -0.8847729642857143H7.843772614285713c-1.3085363142857143 0 -2.3693083285714285 1.0607720142857142 -2.3693083285714285 2.3693083285714285 0 1.113426407142857 0.7752836785714285 2.076633514285714 1.862990057142857 2.3145636857142855l3.1280292642857144 0.6842682214285714c1.2185361214285715 0.2665566428571428 2.087084764285714 1.3463997 2.087084764285714 2.5937613642857142 0 1.4659221857142857 -1.1883768000000001 2.6552545285714286 -2.6542989857142856 2.6552545285714286h-1.7695260214285713c-1.1556892714285714 0 -2.1388632428571426 -0.7386147214285714 -2.503243585714286 -1.7695260214285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2489"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.013496421428572 8.626421935714285V5.972142857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2490"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.013496421428572 21.89785714285714V19.243498435714283"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

import { keywordRotation as defaultState } from './defaultStates';
import { KeywordRotationLinksAction } from '../../types/redux/action';
import { KeywordRotationLinksStore } from '../../types/redux/store';
import { KEYWORD_ROTATION_LINKS } from 'redux/types';

const data = (state = defaultState.data, action: KeywordRotationLinksAction) => {
  switch (action.type) {
    case KEYWORD_ROTATION_LINKS.SET_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): KeywordRotationLinksStore => ({
  data: data(state.data, action),
});

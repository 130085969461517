import { conditionalClass } from 'conditional-class';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { VisibilityWrapper } from 'uikit';
import { defined } from 'utils/define';
import { IconTooltip } from '../Tooltip';
import './style.scss';
interface ContentWrapperProps {
  children: any;
  show?: boolean;
  style?: React.CSSProperties;
  shouldMount?: boolean;
  width?: string;
  height?: string;
  padding?: string;
  direction?: React.CSSProperties['flexDirection'];
  className?: string;
}

export const ContentWrapper = ({
  children,
  show = true,
  style = {},
  className = '',
  ...props
}: ContentWrapperProps) =>
  !show ? (
    <></>
  ) : (
    <div
      className={`c-contentWrapper ${className}`}
      {...props}
      style={{
        width: props.width || '100%',
        height: props.height || 'auto',
        padding: props.padding || '30px',
        flexDirection: props.direction || 'column',
        display: 'flex',
        visibility: 'visible',
        ...style
      }}
    >
      {children}
    </div>
  );

interface FormContentWrapperInterface extends ContentWrapperProps {
  title?: string;
}

export const FormContentWrapper = ({
  children,
  show = true,
  style = {},
  shouldMount,
  ...props
}: FormContentWrapperInterface) => (
  <ContentWrapper
    style={{
      ...style,
      display: shouldMount && !show ? 'none' : undefined
    }}
    show={shouldMount ? true : show}
    padding="0px"
    {...props}
  >
    {props.title ? <h2>{props.title}</h2> : <></>}
    {children}
  </ContentWrapper>
);

export const FormSectionBox = ({
  title = '',
  children,
  withBoxPadding = true,
  withTitlePadding = false,
  withBorder = true,
  visible = true,
  className = '',
  titleClassname = '',
  titlePostfix,
  tooltipText,
  onBlur,
  margin,
}: {
  title?: string;
  children: any;
  withBoxPadding?: boolean;
  withTitlePadding?: boolean;
  withBorder?: boolean;
  visible?: boolean;
  className?: string;
  titleClassname?: string;
  tooltipText?: string;
  titlePostfix?: JSX.Element;
  margin?: CSSProperties['margin']
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}) => (
  <VisibilityWrapper visible={visible}>
    <div
      className={conditionalClass(['c-formSectionBox', className], {
        'c-formSectionBox--withBoxPadding': withBoxPadding,
        'c-formSectionBox--withBorder': withBorder
      })}
      onBlur={onBlur}
      style={{ margin }}
    >
      <VisibilityWrapper visible={!!title}>
        <div className="flex flex-justify-between flex-align-center">
          <div className="flex flex-gap-5 flex-align-center margin-bottom-5">
            <h1
              className={conditionalClass(
                ['c-formSectionBox__title', titleClassname],
                {
                  'c-formSectionBox__title--withPaddig': withTitlePadding
                }
              )}
            >
              {title}
            </h1>
            <VisibilityWrapper visible={!!tooltipText}>
              <IconTooltip className="switchButton" body={tooltipText!} />
            </VisibilityWrapper>
            <VisibilityWrapper visible={defined(titlePostfix)}>
              {titlePostfix!}
            </VisibilityWrapper>
          </div>
        </div>
      </VisibilityWrapper>

      {children}
    </div>
  </VisibilityWrapper>
);

export const ScrollableContent = ({
  children,
  maxHeight = `200px`
}: PropsWithChildren<{
  maxHeight: string;
}>) => (
  <div className="c-scrollableContainer" style={{ maxHeight: maxHeight }}>
    {children}
  </div>
);

export const FormHelpSection = ({
  content,
  name
}: {
  content: JSX.Element;
  name: string;
}) => {
  return <>{content}</>;
};

import { isDevEnvironment, isTestEnvironment } from '../utils/app';

export const METHOD_GET = `get`;
export const METHOD_POST = `post`;
export const METHOD_PUT = `put`;
export const METHOD_DELETE = `delete`;

export const API_DEBOUNCE = 1000;
export const API_BASE_URL =
  isDevEnvironment && !isTestEnvironment
    ? `https://api-staging.funnelflux.pro`
    : `https://${process.env.REACT_APP_API_DOMAIN}`;

import React, { Component, createRef, RefObject } from 'react';
import { FunnelNode } from '../../../../model/funnelNode';
import { Label, Select } from '../../../Parts/Inputs';
import { SwitchButton } from '../../../Parts/Buttons';
import { ModalButtonGroup } from '../../../Parts/Groups';
import { FunnelConnection } from '../../../../model/funnelConnection';
import Messages from 'components/Messages';
import { defined } from '../../../../utils/define';
import { MessageProps } from '../../../../types';
import { copy } from 'utils/copy';
import { Funnel } from '../../../../model/funnel';
import { DomainEntry } from '../../../../model/domains/domainEntry';
import { FormSectionBox } from 'components/Parts/Content';
import './style.scss';
import CodeSnippet from 'components/CodeSnippet';

interface State {
  switch: {
    defaultRedirectParams: boolean;
  };
  actionUrl: string;
  domain: string;
  actionNumber: number;
}

interface Props extends MessageProps {
  node: FunnelNode;
  funnel: Funnel;
  connection: FunnelConnection;
  connections: FunnelConnection[];
  defaultCustomDomain?: string;
  onClose: Function | false;
  customDomains: DomainEntry[];
  formBoxPadding?: boolean;
}

const getUniqConnection = (connection: FunnelConnection) => {
  if (defined(connection?.connectionPageParams?.onActionNumbers)) {
    return connection.connectionPageParams!.onActionNumbers;
  }

  return [];
};

class GetActionLink extends Component<Props, State> {
  elRefs = {
    actionUrl: createRef<HTMLDivElement>()
  };

  state: State = {
    switch: {
      defaultRedirectParams: false
    },
    actionUrl: '',
    domain: '',
    actionNumber: 1
  };

  static defaultProps: Partial<Props> = {
    formBoxPadding: true
  };

  async componentDidMount() {
    await this.setState({ domain: this.props.defaultCustomDomain! });
    if (
      defined(this.props.connection) &&
      defined(this.props.connection.connectionPageParams) &&
      defined(this.props.connection.connectionPageParams.onActionNumbers)
    ) {
      await this.setState({
        actionNumber: this.props.connection.connectionPageParams
          ?.onActionNumbers[0]
      });
    }
    this.getLink();
  }

  handleChange = async (name: string, value: string) => {
    await this.setState((state: State) => ({
      ...state,
      [name]: value
    }));

    this.getLink();
  };

  handleCopyClick = (context: RefObject<any>) => {
    copy(context, this.props.showMessage, Messages);
  };

  handleSwitchChange = async (value: boolean, name: string) => {
    await this.setState((state: State) => ({
      ...state,
      switch: {
        ...state.switch,
        [name]: value
      }
    }));

    this.getLink();
  };

  getLink = () => {
    const node = this.props.node;
    const funnel = this.props.funnel;

    this.setState({
      actionUrl: `https://${this.state.domain}/action/${
        this.state.switch.defaultRedirectParams
          ? `${defined(funnel?.idFunnel) ? funnel.idFunnel : ''}/${
              defined(node?.idNode) ? node.idNode : ''
            }/`
          : ``
      }${this.state.actionNumber}`
    });
  };

  render() {
    return (
      <form className="cform-getActionLink">
        <FormSectionBox
          withBorder={false}
          withBoxPadding={this.props.formBoxPadding}
          className="cform-getActionLink__box"
        >
				<div>
					<Label htmlFor="domain" text="Custom domain to use:" />
					<Select
						id="domain"
						name="domain"
						options={this.props.customDomains}
						placeholder="Select Domain"
						value={this.state.domain}
						onChange={(value: string) => this.handleChange('domain', value)}
						valueGetter={(option: DomainEntry) => option.domain}
						labelGetter={(option: DomainEntry) => option.domain}
					/>
				</div>
          {defined(this.props.connection) &&
            getUniqConnection(this.props.connection).length > 1 && (
              <div>
                <Label htmlFor="actionNumber" text="Choose action number:" />
                <Select
                  id="actionNumber"
                  name="actionNumber"
                  options={getUniqConnection(this.props.connection)}
                  placeholder="Select Action Number"
                  value={this.state.actionNumber}
                  onChange={(value: string) =>
                    this.handleChange('actionNumber', value)
                  }
                  valueGetter={(option: number) => option}
                  labelGetter={(option: number) => `Action ${option}`}
                />
              </div>
            )}
          <div>
            <Label htmlFor="actionUrl" text="URL of this Action:" />
            <div className="cform-getActionLink__inputCopy">
              <CodeSnippet
                placeholder="URL of this Action"
                forwardedRef={this.elRefs.actionUrl}
                code={this.state.actionUrl}
                data-testid="actionUrl"
                className="width-full"
              />
            </div>
          </div>
          <SwitchButton
            checked={this.state.switch.defaultRedirectParams}
            onSwitchClicked={(value: boolean) =>
              this.handleSwitchChange(value, 'defaultRedirectParams')
            }
            labelName="Add Default Redirect Parameters"
            data-testid="defaultRedirectSwitch"
          />

          {this.props.onClose && (
            <ModalButtonGroup
              showCancel
              cancelText={'Close'}
              onCancelClicked={this.props.onClose}
            />
          )}
        </FormSectionBox>
      </form>
    );
  }
}

export default Messages.injectIn(GetActionLink);

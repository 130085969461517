import React, { Component, PropsWithChildren } from 'react';
import './style.scss';
import { message as AntMessage } from 'antd';
import { MessageProps, Omit } from 'types';
import {
  MessagesProvider as Provider,
  MessagesConsumer as Consumer,
  defaultMessagesState,
  MessagesState
} from './context';
import { CloseCircleOutlined } from '@ant-design/icons';

interface Props extends MessagesState {}

class MessagesProvider extends Component<PropsWithChildren<Props>, MessagesState> {
  state: MessagesState = {
    ...defaultMessagesState,
    showMessage: this.showMessage.bind(this) as any,
    currentAction: {
      id: '',
      type: '',
      payload: ''
    },
    messages: {}
  };

  componentDidMount() {
    AntMessage.config({
      maxCount: 3
    });
  }

  static success(payload = 'Success') {
    return {
      type: 'success',
      payload
    };
  }

  static failed(payload = 'Failed') {
    return {
      type: 'failed',
      payload
    };
  }

  static warning(payload = 'Warning') {
    return {
      type: 'failed',
      payload
    };
  }

  static injectIn<T extends MessageProps>(
    Wrapped: React.ComponentType<T>
  ): React.ComponentClass<Omit<T, keyof MessageProps>> {
    //@ts-ignore
    return (p: T) => (
      <Consumer>
        {(props: MessageProps) => <Wrapped {...p} {...props} />}
      </Consumer>
    );
  }

  async componentDidUpdate(_: Props) {
    if (!!this.state.currentAction.type) {
      await this.handleMessageActions(this.state.currentAction);
    }
  }

  getUniqueId() {
    return new Date().getTime();
  }

  setMessageFunction(id: string, message: Function) {
    this.setState((state: MessagesState) => ({
      ...state,
      messages: {
        ...state.messages,
        [id]: message
      }
    }));
  }

  async handleMessageActions(action = { type: '', payload: '', id: '' }) {
    const { type, payload, id } = action;

    switch (type) {
      case 'success':
        const successMessage = AntMessage.success(
          <span>
            {payload}
            <CloseCircleOutlined
              size={12}
              translate="yes"
              onClick={() => this.state.messages[id]()}
            />
          </span>,
          2
        );
        this.setMessageFunction(id, successMessage);
        break;
      case 'failed':
        const failedMessage = AntMessage.warn(
          <span>
            {payload}
            <CloseCircleOutlined
              size={12}
              translate="yes"
              onClick={() => this.state.messages[id]()}
            />
          </span>,
          3
        );
        this.setMessageFunction(id, failedMessage);
        break;
      case 'warning':
        const warningMessage = AntMessage.warning(
          <span>
            {payload}
            <CloseCircleOutlined
              size={12}
              translate="yes"
              onClick={() => this.state.messages[id]()}
            />
          </span>,
          3
        );
        this.setMessageFunction(id, warningMessage);
        break;
      default:
        return;
    }
    this.clearMessage(id);
    return;
  }

  clearMessage(id: string) {
    this.setState((state: MessagesState) => ({
      ...state,
      currentAction: { ...defaultMessagesState.currentAction },
      visibleMessages: state.visibleMessages.filter(msgId => msgId !== id)
    }));
  }

  showMessage(action = { type: '', payload: null }) {
    if (!!action.type) {
      const id = this.getUniqueId().toString();
      const act = { ...action, id };
      this.setState((state: MessagesState) => ({
        ...state,
        currentAction: act,
        visibleMessages: [...state.visibleMessages, id]
      }));

      return id;
    }
    return null;
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export default MessagesProvider;

import React, { PropsWithChildren } from 'react';
import { conditionalClass } from 'conditional-class';
import './style.scss';

const FFBlock = ({
  children,
  className = ''
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={conditionalClass(['c-ffBlock', className])}>{children}</div>
  );
};

export default FFBlock;

import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30" height="30" width="30">
        <g id="graph-bar-increase-square--up-product-performance-increase-arrow-graph-business-chart">
            <path id="Vector 11" fill="#ffffff" d="M16.423392857142858 6.967499999999999v13.934999999999999H21.89785714285714v-13.934999999999999H16.423392857142858Z" strokeWidth="2.13">
            </path>
            <path id="Vector 10" fill="#ffffff" d="M11.446607142857143 10.94892857142857v9.95357142857143h4.976785714285715v-9.95357142857143h-4.976785714285715Z" strokeWidth="2.13">
            </path>
            <path id="Vector 9" fill="#ffffff" d="M5.972142857142857 14.930357142857142v5.972142857142857h5.474464285714285v-5.972142857142857H5.972142857142857Z" strokeWidth="2.13">
            </path>
            <path id="Subtract" fill="#d7e0ff" fillRule="evenodd" d="M2.9860714285714285 0.9953571428571428h21.89785714285714c1.0994715 0 1.9907142857142857 0.8912726464285714 1.9907142857142857 1.9907142857142857v21.89785714285714c0 1.0994715 -0.8912427857142856 1.9907142857142857 -1.9907142857142857 1.9907142857142857h-21.89785714285714c-1.0994416392857143 0 -1.9907142857142857 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857v-21.89785714285714c0 -1.0994416392857143 0.8912726464285714 -1.9907142857142857 1.9907142857142857 -1.9907142857142857Zm13.437321428571428 5.972142857142857v3.9814285714285713h-4.976785714285715v3.9814285714285713H5.972142857142857v5.972142857142857h15.925714285714285v-13.934999999999999H16.423392857142858Z" clip-rule="evenodd" strokeWidth="2.13">
            </path>
            <path id="Vector 6" stroke="#48508b" strokeLinecap="round" strokeLinejoin="round" d="M5.972142857142857 14.930357142857142v5.972142857142857h5.474464285714285v-5.972142857142857H5.972142857142857Z" strokeWidth="2.13">
            </path>
            <path id="Vector 7" stroke="#48508b" strokeLinecap="round" strokeLinejoin="round" d="M11.446607142857143 10.94892857142857v9.95357142857143h4.976785714285715v-9.95357142857143h-4.976785714285715Z" strokeWidth="2.13">
            </path>
            <path id="Vector 8" stroke="#48508b" strokeLinecap="round" strokeLinejoin="round" d="M16.423392857142858 6.967499999999999v13.934999999999999H21.89785714285714v-13.934999999999999H16.423392857142858Z" strokeWidth="2.13">
            </path>
            <path id="Vector" stroke="#48508b" strokeLinecap="round" strokeLinejoin="round" d="M24.88392857142857 0.9953571428571428h-21.89785714285714c-1.0994416392857143 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857v21.89785714285714c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-21.89785714285714c0 -1.0994416392857143 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z" strokeWidth="2.13">
            </path>
        </g>
    </svg>
);

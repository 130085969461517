import React, { RefObject } from 'react';
import Messages from 'components/Messages';
import { getQueryUrlSeparate } from 'utils/url';
import validateForm, { required, unique } from 'utils/validation';
import { ReactiveValidateComponent, ReactiveValidateState } from 'utils/reactive/generic';
import { AddButton as OpenButton } from 'components/Parts/Buttons';
import { FormContentWrapper, FormHelpSection, FormSectionBox } from 'components/Parts/Content';
import { CONFIRM_MODAL, UNCATEGORIZED } from 'constants/modal';
import { OfferSource } from '../../../model/offerSource';
import { generateEntityId } from '../../../utils/id/generator';
import { Category } from '../../../model/category';
import { AnyObject, MessageProps, StringObject } from '../../../types';
import { OFFER_SOURCE_TEMPLATE as MockedTemplates } from 'constants/templates';
import { DUPLICATE_RECORD_CODE } from '../../../constants/errors';
import { trimStringPropertiesInObject, removeCommaOrMultipleDot, withoutWhiteSpace } from 'utils/string';
import { OfferSourceFormProps } from 'types/ModalForms/offerSource';
import { defined } from '../../../utils/define';
import { handleScrollToErrorElement } from 'utils/validation';
import { copy, getDuplicateModalSidebarContext } from 'utils/copy';
import { sortObject } from '../../../utils/sort';
import { InputSelect } from '../../Parts/InputSelect';
import Icon from '../../Icons';
import { cloneObject, equalObjects } from '../../../utils/object';
import { isCopyByContextModal } from 'utils/modals';
import { DomainEntry } from '../../../model/domains/domainEntry';
import { getActiveEntities, withIncrementedVersion } from '../../../utils/model';
import { ValidationRule } from '../../../utils/validation/types';
import { asyncVersionConfirmSidebar, getVisibilityByActiveTab, hightLightTabs } from 'utils/dynamic-sidebar';
import { LoadingProps } from 'types/loading';
import {
  OFFERSOURCE_FORM_TAB,
  OFFERSOURCE_FORM_CONFIGURE_DATA_PASSING_TAB,
  OFFERSOURCE_FORM_CONFIGURE_TRACKING_TOKENS_TAB,
  OFFERSOURCE_FORM_HELP_TAB
} from 'constants/dynamicSidebar';
import { SidebarContext } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import './style.scss';
import { TemplateSelector } from 'components/TemplateSelector';
import { OfferSourceTemplate } from 'types/offerSources';
import CodeSnippet from 'components/CodeSnippet';
import { FFCol, FFField, FFInput, FFNewButton, FFRow, FFSelect, VisibilityWrapper } from 'uikit';
import NewDataPassing from 'components/DataPassingNew';
import className from 'utils/style/className';

const DEFAULT_POSTBACK_SUBID = 'REQUIRED';
const DEFAULT_POSTBACK_TXID = 'OPTIONAL_TXID';
const DEFAULT_POSTBACK_PAYOUT = 'OPTIONAL_REVENUE';

const { blockClassName, getClass } = className('c-offersourceForm')

interface State extends ReactiveValidateState {
  offerSourceNames: string[];
  model: OfferSource;
  anotherModel: AnyObject;
  validationErrors: AnyObject;
  dataPassingTokens: [];
  isDataPassingOn: boolean;
  isTrackingTokensOn: boolean;
  isTouchedForm: boolean;
  dataPassingErrors: boolean;
  isSubmitted: boolean;
  showErrorTooltip: boolean;
  hasSubmittedTemplate: boolean;
  templateSearch: string;
}

const defaultModelState: OfferSource = {
  idOfferSource: '',
  offerSourceName: '',
  subId: '',
  queryParams: {},
  postbackSubId: '',
  postbackTxId: '',
  postbackPayout: '',
  status: 'active'
};

const defaultState = (): State => ({
  offerSourceNames: [],
  model: defaultModelState,
  validationErrors: {},
  dataPassingTokens: [],
  isDataPassingOn: false,
  isTrackingTokensOn: false,
  anotherModel: {
    hitId: '',
    payOut: '',
    template: '',
    postBackUrl: '',
    redirectType: '',
    transactionId: '',
    iframeTracking: '',
  },
  isTouchedForm: false,
  dataPassingErrors: false,
  showErrorTooltip: false,
  isSubmitted: false,
  hasSubmittedTemplate: false,
  templateSearch: ''
});

class OfferSources extends ReactiveValidateComponent<OfferSourceFormProps & MessageProps & LoadingProps, State> {
  state: State = defaultState();

  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  elRefs = {
    postBackUrl: React.createRef<HTMLDivElement>(),
    yourConversionJsSingle: React.createRef<HTMLDivElement>(),
    yourConversionJsFull: React.createRef<HTMLDivElement>()
  };

  static defaultProps: Partial<OfferSourceFormProps> = {
    templates: MockedTemplates,
    customDomains: [],
    openAddNewCategory: () => {}
  };

  static getDerivedStateFromProps(props: OfferSourceFormProps, state: State) {
    if (state.anotherModel.template !== '') {
      let isSelectedTemplateValid = defined(props.templates)
        ? !!props.templates.find(template => template.id === state.anotherModel.template)
        : false;

      if (!isSelectedTemplateValid) {
        state.anotherModel = {
          ...state.anotherModel,
          template: ''
        };
      }
    }

    if (
      state.model.idCategory !== '' &&
      props.categories.filter((item: Category) => item.idCategory === state.model.idCategory).length === 0
    ) {
      state.model = {
        ...state.model,
        idCategory: ''
      };
    }

    return state;
  }

  async componentDidMount() {
    this.props.setButtonGroupProps({
      showSave: true,
      saveText: isCopyByContextModal(this.props.contextModal) ? `DUPLICATE` : `SAVE`,
      onSaveClicked: this.handleSubmit,
      showCancel: true,
      cancelText: 'DISCARD'
    });

    this.props.setSidebarLoading!(true);
    await this.onDidMount();
    this.props.setSidebarLoading!(false);
  }

  onDidMount = async () => {
    this.props.fetchCategories();
    this.setValidateSubscribe();

    await this.setState({
      offerSourceNames: Object.values(this.props.offerSources).map(offerSource => offerSource.offerSourceName)
    });

    if (defined(this.props.contextModal.data)) {
      const id = this.props.contextModal.entityId!;
      if (this.props.setForCopyIdsProps) {
        this.props.setForCopyIdsProps({
          'Offer Source ID': id
        });
      }
      this.setEditModel(id);
      await this.setPostbackIframe();
      this.setEditModel(id);
      await this.props.getOfferSourceById(id);
      this.setEditModel(id);
    } else {
      await this.setPostbackIframe();
    }

    this.addDefaultFieldSet('domain', this.props.defaultCustomDomain);
  };

  handleStateValuesChange = <T extends State, P extends keyof T>(name: P, value: T[P]) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  setPostbackIframe = async () => {
    if (!defined(this.state.anotherModel.domain) || this.state.anotherModel.domain === '') {
      await this.addDefaultFieldSet('domain', this.props.defaultCustomDomain);
    }

    this.setState({
      anotherModel: {
        ...this.state.anotherModel,
        postBackUrl: this.makePostbackUrl(``, [
          {
            key: 'hit',
            value: this.state.model.postbackSubId || DEFAULT_POSTBACK_SUBID
          },
          {
            key: 'tx',
            value: this.state.model.postbackTxId || DEFAULT_POSTBACK_TXID
          },
          {
            key: 'rev',
            value: this.state.model.postbackPayout || DEFAULT_POSTBACK_PAYOUT
          }
        ]),
        iframeTracking: this.makeIframeTracking([
          {
            key: 'hit',
            value: this.state.model.postbackSubId || DEFAULT_POSTBACK_SUBID
          },
          {
            key: 'tx',
            value: this.state.model.postbackTxId || DEFAULT_POSTBACK_TXID
          },
          {
            key: 'rev',
            value: this.state.model.postbackPayout || DEFAULT_POSTBACK_PAYOUT
          }
        ])
      }
    });
  };

  validationRules = (): ValidationRule[] => [
    {
      field: 'offerSourceName',
      validations: [required, unique(this.state.offerSourceNames, 'offer source')]
    }
  ];

  addDefaultFieldSet = (fieldSetType: string, params: any) => {
    this.setState((state: State) => ({
      ...state,
      anotherModel: {
        ...state.anotherModel,
        [fieldSetType]: params
      }
    }));
  };

  setEditModel = async (id: string) => {
    const editModel =
      defined(this.props.offerSources) && defined(this.props.offerSources[id]) ? cloneObject(this.props.offerSources[id]) : null;

    if (defined(editModel)) {
      if (isCopyByContextModal(this.props.contextModal)) {
        editModel.offerSourceName = this.props.contextModal.copyName!;
        editModel.status = 'active';
      }
      this.onTemplateSelected();
      this.setState((state: State) => ({
        model: {
          ...state.model,
          ...editModel
        },
        anotherModel: {
          ...state.anotherModel,
          queryParams: editModel.queryParams
        },
        offerSourceNames: state.offerSourceNames.filter(name => name !== editModel.offerSourceName)
      }));

      if (!!editModel.postbackSubId || !!editModel.postbackTxId || !!editModel.postbackPayout) {
        this.addDefaultFieldSet(
          'postBackUrl',
          this.makePostbackUrl(this.state.anotherModel.postBackUrl || '', [
            {
              key: 'hit',
              value: editModel.postbackSubId || DEFAULT_POSTBACK_PAYOUT
            },
            {
              key: 'tx',
              value: editModel.postbackTxId || DEFAULT_POSTBACK_TXID
            },
            {
              key: 'rev',
              value: editModel.postbackPayout || DEFAULT_POSTBACK_PAYOUT
            }
          ])
        );

        this.addDefaultFieldSet(
          'iframeTracking',
          this.makeIframeTracking([
            {
              key: 'hit',
              value: editModel.postbackSubId || DEFAULT_POSTBACK_PAYOUT
            },
            {
              key: 'tx',
              value: editModel.postbackTxId || DEFAULT_POSTBACK_TXID
            },
            {
              key: 'rev',
              value: editModel.postbackPayout || DEFAULT_POSTBACK_PAYOUT
            }
          ])
        );
      }
    }
  };

  async componentDidUpdate(prevProps: OfferSourceFormProps, prevState: State) {
    if (
      this.props.templates &&
      !!this.state.anotherModel.template &&
      this.state.anotherModel.template !== prevState.anotherModel.template
    ) {
      const template = this.props.templates.find(temp => temp.id === this.state.anotherModel.template);

      if (template) {
        this.setState((state: State) => ({
          ...state,
          model: {
            ...state.model,
            offerSourceName: template.name,
            postbackSubId: template.tracking.postbackSubId,
            postbackPayout: template.tracking.postbackPayout,
            postbackTxId: template.tracking.postbackTxId,
            queryParams: template.dataPassingFields
          },
          anotherModel: {
            ...state.anotherModel,
            queryParams: template.dataPassingFields,
            postBackUrl: this.makePostbackUrl(state.anotherModel.postBackUrl, [
              {
                key: 'hit',
                value: template.tracking.postbackSubId || DEFAULT_POSTBACK_SUBID
              },
              {
                key: 'tx',
                value: template.tracking.postbackTxId || DEFAULT_POSTBACK_TXID
              },
              {
                key: 'rev',
                value: template.tracking.postbackPayout || DEFAULT_POSTBACK_PAYOUT
              }
            ]),
            iframeTracking: this.makeIframeTracking([
              {
                key: 'hit',
                value: template.tracking.postbackSubId || DEFAULT_POSTBACK_SUBID
              },
              {
                key: 'tx',
                value: template.tracking.postbackTxId || DEFAULT_POSTBACK_TXID
              },
              {
                key: 'rev',
                value: template.tracking.postbackPayout || DEFAULT_POSTBACK_PAYOUT
              }
            ])
          },
          isTrackingTokensOn: true,
          isDataPassingOn: true
        }));
      }
    }

    if (this.props.activeTab !== prevProps.activeTab && this.props.activeTab === 'offersource-form-configure-tracking-tokens') {
      await this.props.fetchDomains();
    }
  }

  makeIframeTracking = (
    tokens = [
      { key: 'hit', value: this.state.anotherModel.hitId },
      { key: 'tx', value: this.state.anotherModel.transactionId },
      { key: 'rev', value: this.state.anotherModel.payOut }
    ]
  ) => {
    const src = `https://${this.state.anotherModel.domain || ''}/pb/`;

    const params = Object.entries(tokens).reduce((acc: string[], [_, { key, value }]: any) => {
      if (!key || !value) return acc;
      return (acc = [...acc, `${key}=${value}`]);
    }, []);

    const joinedParams = params.join('&');

    return `<iframe width='1' height='1' style='display:none;' src='${
      params.length > 0 ? `${src}${getQueryUrlSeparate(joinedParams)}${joinedParams}` : src
    }'></iframe>`;
  };

  makePostbackUrl = (url: string, values: { key: string; value?: string }[]) => {
    if (!defined(url)) {
      url = '';
    }

    if (url.includes('?')) {
      const parts = url.split('?');
      url = parts[0];
    }

    if (url === '') {
      url = `https://${this.state.anotherModel.domain || ''}/pb/`;
    }

    const params = Object.entries(values).reduce((acc: string[], [_, { key, value }]: any) => {
      if (!key || !value) return acc;
      return (acc = [...acc, `${key}=${value}`]);
    }, []);

    const joinedParams = params.join('&');

    return params.length > 0 ? `${url}${getQueryUrlSeparate(joinedParams)}${joinedParams}` : url;
  };

  handleDataPassingChanges = (name: string, value: string | StringObject) => {
    this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        [name]: value
      },
      dataPassingErrors: false
    }));
  };

  handleSelectInputChange = async (name: string, value: string) => {
    const validate = () => this.validate$.next();

    await this.setState((state: State) => {
      return {
        ...state,
        anotherModel: {
          ...state.anotherModel,
          [name]: value
        },
        model: {
          ...state.model,
          offerSourceName: name === 'offerSourceName' ? value : state.model.offerSourceName,
          idCategory: name === 'idCategory' ? value : state.model.idCategory,
          postbackPayout: name === 'postbackPayout' ? value : state.model.postbackPayout,
          postbackSubId: name === 'postbackSubId' ? value : state.model.postbackSubId,
          postbackTxId: name === 'postbackTxId' ? value : state.model.postbackTxId
        }
      };
    });

    validate();

    if (name === 'domain') {
      this.setPostbackIframe();
    }
  };

  handleCopyClick = (context: RefObject<any>) => {
    copy(context, this.props.showMessage, Messages);
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {}, showErrorTooltip: false });
      const [validationErrors] = validateForm(this.state.model, this.validationRules());
      this.setState((state: State) => ({
        ...state,
        validationErrors
      }));

      handleScrollToErrorElement(validationErrors, (entries: IntersectionObserverEntry[]) => {
        if (defined(entries[0]) && entries[0].isIntersecting) {
          this.setState({ showErrorTooltip: true });
        }
      });
    }
  };

  resetForm = async (isCreateAndNew = false) => {
    if (isCreateAndNew) {
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          offerSourceName: getDuplicateModalSidebarContext(this.state.model.idOfferSource, this.props.offerSources),
          idOfferSource: ''
        }
      });
    }
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    this.props.startLoading('save');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm();
      this.props.closeSelf();
    }

    return false;
  };

  handleSaveAndCreate = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.startLoading('saveAndCreate');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm(true);
    }
  };

  performCreateOrUpdate = async () => {
    await this.setTouchedForm();
    await this.validate();
    const model = trimStringPropertiesInObject(this.state.model, ['offerSourceName']);
    model.queryParams = sortObject(model.queryParams || {});
    if (model.idCategory === UNCATEGORIZED) {
      delete model.idCategory;
    }

    if (!Object.keys(this.state.validationErrors).length && !this.state.dataPassingErrors) {
      try {
        if (isCopyByContextModal(this.props.contextModal)) {
          await this.props.handleDuplicate({
            ...model,
            meta: { version: 1 }
          });
        } else if (!!this.state.model.idOfferSource) {
          await this.props.getOfferSourceById(model.idOfferSource);
          if (model?.meta && model.meta?.version !== this.props.offerSources[model.idOfferSource]?.meta?.version) {
            const sidebar = this.context as SidebarProps;
            try {
              await asyncVersionConfirmSidebar(sidebar);
              await this.props.handleUpdate(withIncrementedVersion(model, this.props.offerSources[model.idOfferSource]?.meta?.version));
            } catch (e) {
              this.props.stopLoading('all');
              this.props.closeSelf();
              return;
            }
          } else {
            await this.props.handleUpdate(withIncrementedVersion(model));
          }
        } else {
          const newID = generateEntityId();
          await this.props.handleCreate({
            ...model,
            idOfferSource: newID,
            meta: { version: 1 }
          });
          await this.setState({
            model: {
              ...this.state.model,
              idOfferSource: newID
            }
          });
        }

        this.props.stopLoading('all');
        this.props.showMessage(
          Messages.success(`${model.offerSourceName} ${!!model.idOfferSource ? 'has been updated' : 'has been added'}`)
        );
      } catch (e) {
        if (defined(e.response) && e.response.status === DUPLICATE_RECORD_CODE) {
          this.setState(
            {
              offerSourceNames: [...this.state.offerSourceNames, this.state.model.offerSourceName]
            },
            this.validate
          );
        }
        this.props.showMessage(
          Messages.failed(
            `${this.state.model.offerSourceName} ${!!this.state.model.idOfferSource ? 'cannot be updated' : 'cannot be added'}`
          )
        );
        this.props.stopLoading('all');
        return false;
      }

      return true;
    } else {
      this.props.stopLoading('all');
    }

    return false;
  };

  setDataPassingError = (value: boolean) => {
    this.setState({
      dataPassingErrors: value
    });
  };

  validateClassName = (field: string, idx?: number) => {
    return (idx !== undefined && !!this.state.validationErrors[field] && !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? 'has-error'
      : '';
  };

  validateTooltip = (field: string, idx?: string) => {
    return (idx !== undefined && !!this.state.validationErrors[field] && !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  changeOfferSource = async (inputValue: string, selectValue: string) => {
    const template = defined(this.props.templates) ? this.props.templates.find(temp => temp.id === selectValue) : undefined;

    if (template) {
      hightLightTabs();
    }

    const checkDataPassing =
      Object.entries(this.state.model.queryParams || {}).length > 0 &&
      defined(template) &&
      !equalObjects(template.dataPassingFields, this.state.model.queryParams || {});

    if (checkDataPassing) {
      const sidebar = this.context as SidebarProps;

      sidebar.setContextSidebar(CONFIRM_MODAL, {
        content: (
          <div className={`restore-alert`}>
            <span>
              <Icon type="flux-information" className={`restore-alert-icon`} />
            </span>
            <span>This will overwrite data in the fields below, should we continue?</span>
          </div>
        ),
        okText: 'YES',
        cancelText: 'NO',
        width: 400,
        title: 'Warning',
        onConfirm: async () => {
          this.handleSelectInputChange('offerSourceName', inputValue);
          if (defined(selectValue)) {
            this.handleSelectInputChange('template', selectValue);
          }

          sidebar.closeSidebar(CONFIRM_MODAL);
        }
      });

      sidebar.openSidebar(CONFIRM_MODAL);
    } else {
      this.handleSelectInputChange('offerSourceName', inputValue);
      if (defined(selectValue)) {
        this.handleSelectInputChange('template', selectValue);
      }
    }
  };

  onTemplateSearch = (val: string) => {
    this.handleStateValuesChange('templateSearch', val);
  };

  onTemplateSelected = (id?: string) => {
    this.props.showTabs();
    if (id) {
      this.handleSelectInputChange('template', id);
    }
    this.props.setButtonGroupProps({
      showSave: true
    });
    this.handleStateValuesChange('hasSubmittedTemplate', true);
  };

  hasTemplate = () => this.state.hasSubmittedTemplate && Object.keys(this.state.model.queryParams || {}).length > 0

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;

    return !this.state.hasSubmittedTemplate ? (
      <TemplateSelector
        onSelect={this.onTemplateSelected}
        onSearch={this.onTemplateSearch}
        searchValue={this.state.templateSearch}
        templates={MockedTemplates}
        type="offersource"
      />
    ) : (
      <form onSubmit={this.handleSubmit} className={blockClassName}>
        <FormContentWrapper show={getVisibilityByActiveTab(OFFERSOURCE_FORM_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle!}>
            <FFRow gap={15} marginBottom={15}>
              <FFField label="Offer Source Name" htmlFor="offerSourceName" block>
                <InputSelect
                  dataTestid="offerSourceName"
                  placeholder="Offer Source Name"
                  name="offerSourceName"
                  onChange={(inputValue: string, selectValue: string) => {
                    this.changeOfferSource(inputValue, selectValue);
                  }}
                  value={this.state.model.offerSourceName}
                  error={this.validateTooltip('offerSourceName')}
                  showErrorTooltip={this.state.showErrorTooltip}
                  options={this.props.templates || []}
                  valueGetter={(option: OfferSourceTemplate) => option.id}
                  labelGetter={(option: OfferSourceTemplate) => option.name}
                />
              </FFField>
              <FFField label="Category">
                <FFSelect
                  id="category"
                  data-testid="category"
                  placeholder={UNCATEGORIZED}
                  className={getClass('categorySelect')}
                  dropdownMatchSelectWidth={300}
                  options={getActiveEntities(this.props.categories)}
                  valueGetter={(option: Category) => option.idCategory}
                  labelGetter={(option: Category) => option.categoryName}
                  value={this.state.model.idCategory}
                  defaultValueFallback={{
                    label: UNCATEGORIZED,
                    value: UNCATEGORIZED
                  }}
                  onChange={(value: string) => this.handleSelectInputChange('idCategory', value)}
                  showSearch={true}
                  filterOption={true}
                  style={{ width: 170 }}
                />
                <OpenButton data-testid="open-category-form" onClick={this.props.openAddNewCategory} />
              </FFField>
            </FFRow>
            <VisibilityWrapper visible={!isCopyByContextModal(this.props.contextModal)}>
              <FFNewButton loading={this.props.loading.saveAndCreate} onClick={this.handleSaveAndCreate}>
                Save & Create New
              </FFNewButton>
            </VisibilityWrapper>
          </FormSectionBox>
        </FormContentWrapper>
        <FormContentWrapper
          shouldMount={this.hasTemplate()}
          show={getVisibilityByActiveTab(OFFERSOURCE_FORM_CONFIGURE_DATA_PASSING_TAB, this.props.activeTab)}
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <p>Here you can configure additional data that gets appended to your base page URL.</p>
            <NewDataPassing
              queryParams={this.state.model.queryParams!}
              onChange={(value: StringObject) => this.handleDataPassingChanges('queryParams', value)}
              setError={this.setDataPassingError}
              showErrors={this.state.isSubmitted}
              pageName="Data Passing Offers"
            />
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          show={getVisibilityByActiveTab(OFFERSOURCE_FORM_CONFIGURE_TRACKING_TOKENS_TAB, this.props.activeTab)}
        >
          <FormSectionBox title="Postback URL">
            <p>
              Here, enter the tokens this offer source uses to pass back relevant data. The Hit ID field should have the token that
              corresponds to the field you pass Hit ID under in the configure data passing tab.
            </p>
            <FFRow gap={15} marginBottom={15}>
              <FFField label="Hit ID Field" htmlFor="postbackSubId" block>
                <FFInput
                  id="postbackSubId"
                  name="postbackSubId"
                  data-testid="postbackSubId"
                  placeholder="Hit ID Token"
                  value={this.state.model.postbackSubId}
                  onChange={async e => {
                    await this.handleSelectInputChange(e.target.name, withoutWhiteSpace(e.target.value));
                    this.setPostbackIframe();
                  }}
                />
              </FFField>
              <FFField label="Offer Payout" htmlFor="postbackPayout" block>
                <FFInput
                  id="postbackPayout"
                  name="postbackPayout"
                  data-testid="postbackPayout"
                  placeholder="Payout token"
                  value={this.state.model.postbackPayout}
                  onChange={async e => {
                    await this.handleSelectInputChange(e.target.name, withoutWhiteSpace(removeCommaOrMultipleDot(e.target.value)));
                    this.setPostbackIframe();
                  }}
                />
              </FFField>
              <FFField label="Transaction ID" htmlFor="postbackTxId" block>
                <FFInput
                  id="postbackTxId"
                  name="postbackTxId"
                  data-testid="postbackTxId"
                  placeholder="Transaction token"
                  value={this.state.model.postbackTxId}
                  onChange={async e => {
                    await this.handleSelectInputChange(e.target.name, withoutWhiteSpace(e.target.value));
                    this.setPostbackIframe();
                  }}
                />
              </FFField>
            </FFRow>
            <FFRow marginBottom={15}>
              <FFField
                label="Select a domain:"
                htmlFor="domain"
                tootlipContent={
                  <span>
                    Any domain can be used for postback URLs as these rely on a unique hit ID. However, client-side code like Javascript
                    will rely on cookies if you do not inject hit/vid directly, thus you should use the same domain for tracking links and
                    Javascript where possible.
                  </span>
                }
                block
              >
                <FFSelect
                  id="domain"
                  data-testid="domain"
                  options={this.props.customDomains}
                  placeholder="Select Domain"
                  value={this.state.anotherModel.domain}
                  onChange={(value: string) => this.handleSelectInputChange('domain', value)}
                  valueGetter={(option: DomainEntry) => option.domain}
                  labelGetter={(option: DomainEntry) => option.domain}
                />
              </FFField>
            </FFRow>
            <FFCol marginBottom={15}>
              <p>Here is the postback URL to use at this Offer Source:</p>
              <CodeSnippet
                data-testid="postBackUrl"
                placeholder="Postback URL"
                code={this.state.anotherModel.postBackUrl}
                forwardedRef={this.elRefs.postBackUrl}
              />
            </FFCol>
          </FormSectionBox>
          <FormSectionBox title="Universal Javascript Tag (conversion)">
            <p>
              Here is the full Javascript code to place at this offer source to track a conversion. It includes our universal JS tag and a
              conversion event. See our{' '}
              <a href="https://help.funnelflux.pro/article/106-javascript-tracking-of-conversions" target="_blank">
                help documentation
              </a>{' '}
              for more info.
            </p>
            <CodeSnippet
              codeType="offerSourceConversionFull"
              domain={this.state.anotherModel.domain}
              postbackPayout={this.state.model.postbackPayout}
              postbackTxId={this.state.model.postbackTxId}
              postbackSubId={this.state.model.postbackSubId}
              forwardedRef={this.elRefs.yourConversionJsFull}
              data-testid="conversionJs"
            />
          </FormSectionBox>
          <FormSectionBox title="Optional: Conversion-only Event">
            <p>If you have already loaded our universal JS tag, you can trigger a conversion with the following code.</p>
            <CodeSnippet
              codeType="offerSourceConversionSingle"
              domain={this.state.anotherModel.domain}
              postbackPayout={this.state.model.postbackPayout}
              postbackTxId={this.state.model.postbackTxId}
              postbackSubId={this.state.model.postbackSubId}
              forwardedRef={this.elRefs.yourConversionJsSingle}
              data-testid="conversionJsOnly"
            />
          </FormSectionBox>
        </FormContentWrapper>
        <FormContentWrapper show={getVisibilityByActiveTab(OFFERSOURCE_FORM_HELP_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle!}>
            <FormHelpSection
              content={
                <>
                  <p>Offer Sources are the places where you get offers from.</p>

                  <p>Typically, these will be affiliate networks, but could include direct advertisers and product owners.</p>

                  <p>
                    The key purpose of offer sources is to <strong>template</strong> the way you pass data in your offers from this source,
                    as well as your default conversion tracking approach.
                  </p>

                  <p>
                    By using Offer Sources you can create regular patterns for how you pass data to all your offers from the same source,
                    guaranteeing standardised data and reducing tracking errors.
                  </p>
                  <p>
                    For more help on offer sources, see our documentation{' '}
                    <a href="https://help.funnelflux.pro/article/5-introduction-to-offer-sources" target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </p>
                  <p>
                    For further information on using our Javascript tracking for offers, see our documentation{' '}
                    <a href="https://help.funnelflux.pro/collection/6-javascript-tracking" target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </p>
                </>
              }
              name="Offer Source"
            />
          </FormSectionBox>
        </FormContentWrapper>
      </form>
    );
  }
}

export default Messages.injectIn(OfferSources);

import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { loadFromStorage } from 'utils/local-storage';
import logger from 'redux-logger';
import apiMiddleware from './middlewares/api';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import { changeView } from './actions/navigation';

const singleton = Symbol();
const enforcer = Symbol();

class ReduxSingleton {
  constructor(enf) {
    if (enf !== enforcer) {
      throw new Error('Error: Singleton, use .instance to access properties');
    }

    let middlewares = [thunk, apiMiddleware];
    if (process.env.NODE_ENV !== 'production') {
      middlewares = [...middlewares, logger];
      middlewares = composeWithDevTools(applyMiddleware(...middlewares));
    } else {
      middlewares = applyMiddleware(...middlewares);
    }

    this.persistantState = loadFromStorage();

    this.store = createStore(rootReducer, this.persistantState, middlewares);

    this.trackHistory();
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ReduxSingleton(enforcer);
    }
    return this[singleton];
  }

  trackHistory = () => {
    window.onpopstate = _ => {
      this.store.dispatch(changeView(window.location.pathname));
    };
  };
}

export default ReduxSingleton.instance;

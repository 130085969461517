import React, { Component } from 'react';
import Messages from 'components/Messages';
import { ModalButtonGroup } from 'components/Parts/Groups';
import { withLoading } from 'components/Loading';
import { DeleteApiKeyFormProps } from 'types/ModalForms/deleteApiKey';
import { ContentWrapper } from 'components/Parts/Content';
class DeleteApiKeyForm extends Component<DeleteApiKeyFormProps, {}> {
  handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.startLoading('ok');
    try {
      await this.props.contextModal?.onSubmit!({
        key: this.props.contextModal.data.key
      });

      this.props.stopLoading('all');
      this.props.showMessage(
        Messages.success(
          `API key "${this.props.contextModal.data.name}" has been deleted`
        )
      );
      this.props.actions.onOk();
    } catch (error) {
      this.props.stopLoading('all');
      this.props.showMessage(
        Messages.failed(
          `Delete API key "${this.props.contextModal.data.name}" failed`
        )
      );
    }
  };

  render() {
    return (
      <ContentWrapper padding={`20px 30px 32px 30px`}>
        <p>
          Are you sure you would like to delete this API key? Doing so may break
          existing integrations.
        </p>
        <ModalButtonGroup
          loading={this.props.loading}
          showOk
          okText={'CONFIRM'}
          onOkClicked={this.handleSubmit}
          showCancel
          onCancelClicked={() => {
            this.props.actions.onClose();
          }}
        />
      </ContentWrapper>
    );
  }
}

export default withLoading(Messages.injectIn(DeleteApiKeyForm));

import React from 'react';
import Messages from 'components/Messages';
import { MessageProps } from 'types';
import { FormSectionBox } from 'components/Parts/Content';
import Icon from 'components/Icons';
import { OpenDynamicSideBar } from 'types/dynamicSideBar';
import { SetRuleEditorModel } from 'types/ModalForms/simpleFlows';
import { TextedIcon } from 'components/Parts/Icon';
import { SimpleFlowRule } from 'model/simpleFlowRules';
import { generateEntityId } from 'utils/id/generator';
import { VisibilityWrapper } from 'uikit';

interface State {
  idCampaign: string;
  flowName: string;
  defaultCost: string;
}

interface Props extends MessageProps {
  openSidebar: OpenDynamicSideBar;
  setRuleEditorModel: SetRuleEditorModel;
  rules: SimpleFlowRule[];
  onDeleteRule: (rule: SimpleFlowRule) => void;
}

class SimpleFlowsConfigureRules extends React.Component<Props, State> {
  state: State = {
    idCampaign: '',
    flowName: '',
    defaultCost: ''
  };

  handleState = <T extends State, P extends keyof T>(name: P, value: T[P]) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  openNewRuleEditor = () => {
    this.props.setRuleEditorModel({
      ruleId: generateEntityId(),
      ruleName: '',
      rule: {
        routeName: '',
        groups: [],
        operator: 'or'
      }
    });
    this.props.openSidebar('simpleFlowsRuleEditor');
  };

  openEditRuleEditor = (rule: SimpleFlowRule) => {
    this.props.setRuleEditorModel(rule);
    this.props.openSidebar('simpleFlowsRuleEditor');
  };

  deleteRule = (rule: SimpleFlowRule) => {
    this.props.onDeleteRule(rule);
  };

  render() {
    return (
      <form className="cform-simpleFlows__configureRules">
        <FormSectionBox title="Rules for Routing Visitors">
          <p>
            Rules are conditional logic that process when a visitor first
            arrives in your flow. These process in order, the user gets sent to
            the first one they match, else the default. The paths they then get
            sent to are controlled in the routing section.
          </p>
        </FormSectionBox>
        <FormSectionBox
          title="Rules"
          withBoxPadding={false}
          withTitlePadding={true}
        >
          <div className="cform-simpleFlows__configureRules--rulesWrapper">
            {this.props.rules.map(
              rule => (
                <div
                  className="cform-simpleFlows__configureRules--rule"
                  key={rule.ruleId}
                >
                  <div className="flex flex-gap-10">
                    <Icon type="flux-condition" />
                    <span>{rule.ruleName}</span>
                  </div>
                  <div className="flex flex-gap-10">
                    <VisibilityWrapper visible={!rule.isDefault}>
                      <>
                        <Icon
                          type="flux-modify"
                          className="icon-size-22"
                          onClick={() => this.openEditRuleEditor(rule)}
                        />
                        <Icon
                          type="flux-delete"
                          className="icon-size-22"
                          onClick={() => this.deleteRule(rule)}
                        />
                      </>
                    </VisibilityWrapper>
                  </div>
                </div>
              )
            )}
          </div>
          <TextedIcon
            text="Add New Rule"
            onClick={this.openNewRuleEditor}
            className="margin-x-auto margin-y-25"
          />
        </FormSectionBox>
      </form>
    );
  }
}

export default Messages.injectIn(SimpleFlowsConfigureRules);

import React from 'react';
import Messages from 'components/Messages';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from 'utils/reactive/generic';
import { MessageProps } from 'types';
import { FormSectionBox } from 'components/Parts/Content';
import Icon from 'components/Icons';
import { OpenDynamicSideBar } from 'types/dynamicSideBar';
import { SetPathEditorModel } from 'types/ModalForms/simpleFlows';
import { TextedIcon } from 'components/Parts/Icon';
import { SimpleFlowPath } from 'model/simpleFlowPath';
import { generateEntityId } from 'utils/id/generator';
import { VisibilityWrapper } from 'uikit';

interface State extends ReactiveValidateState {}

interface Props extends MessageProps {
  openSidebar: OpenDynamicSideBar;
  setPathEditorModel: SetPathEditorModel;
  onDeletePath: (path: SimpleFlowPath) => void;
  paths: SimpleFlowPath[];
}

class SimpleFlowsConfigurePaths extends ReactiveValidateComponent<
  Props,
  State
> {
  state: State = {
    isTouchedForm: false,
    validationErrors: {}
  };

  handleState = <T extends State, P extends keyof T>(name: P, value: T[P]) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  openNewPathEditor = () => {
    this.props.setPathEditorModel({
      pathId: generateEntityId(),
      pathName: '',
      groups: []
    });
    this.props.openSidebar('simpleFlowsPathEditor');
  };

  openEditPathEditor = (path: SimpleFlowPath) => {
    this.props.setPathEditorModel(path);
    this.props.openSidebar('simpleFlowsPathEditor');
  };

  deletePath = (path: SimpleFlowPath) => {
    this.props.onDeletePath(path);
  };

  render() {
    return (
      <form className="cform-simpleFlows__configurePaths">
        <FormSectionBox title="Create Your Path Units">
          <p>
            Simple flows allow you to create units called paths, which are a
            sequence of landers/offers chained together in some way. You can
            create as many paths as you want, of any length. You can later
            create rules and control what paths users will route to.
          </p>
        </FormSectionBox>
        <FormSectionBox
          title="Paths"
          withBoxPadding={false}
          withTitlePadding={true}
        >
          <div className="cform-simpleFlows__configurePaths--pathsWrapper">
            {this.props.paths.map(path => (
              <div
                className="cform-simpleFlows__configurePaths--path"
                key={path.pathId}
              >
                <div className="flex flex-align-center flex-gap-10">
                  <Icon type="flux-flowPath" />
                  <span>{path.pathName}</span>
                </div>
                <div className="flex flex-gap-10">
                  <Icon
                    type="flux-modify"
                    className="icon-size-22"
                    onClick={() => this.openEditPathEditor(path)}
                  />
                  <VisibilityWrapper visible={!path.isDefault}>
                    <Icon
                      type="flux-delete"
                      className="icon-size-22"
                      onClick={() => this.deletePath(path)}
                    />
                  </VisibilityWrapper>
                </div>
              </div>
            ))}
          </div>
          <TextedIcon
            text="Add New Path"
            onClick={this.openNewPathEditor}
            className="margin-x-auto margin-y-25"
          />
        </FormSectionBox>
      </form>
    );
  }
}

export default Messages.injectIn(SimpleFlowsConfigurePaths);

import { integrations as defaultState } from './defaultStates';
import { INTEGRATIONS } from '../types';
import { IntegrationAction } from '../../types/redux/action';
import { IntegrationStore } from '../../types/redux/store';
import { defined } from '../../utils/define';
import { IntegrationAPIKey } from 'model/integrationAPIKey';
import { IntegrationAPIDeleteKey } from 'model/integrationAPIDeleteKey';

const data = (state = defaultState.data, action: IntegrationAction) => {
  switch (action.type) {
    case INTEGRATIONS.SET_LIST:
      if (defined(action.payload)) {
        return action.payload as IntegrationAPIKey[];
      }
      return state;
    case INTEGRATIONS.DELETE:
      if (defined(action.payload)) {
        return state.filter(
          item => item.key !== (action.payload as IntegrationAPIDeleteKey).key
        );
      }
      return state;
    case INTEGRATIONS.UPDATE_SINGLE:
      if (defined(action.payload)) {
        return state.map(item => {
          if (
            item.key === (action.payload as IntegrationAPIKey).key
          ) {
            return action.payload as IntegrationAPIKey;
          } else {
            return item;
          }
        });
      }
      return state;
    case INTEGRATIONS.ADD_SINGLE:
      if (defined(action.payload)) {
        return [...state, action.payload as IntegrationAPIKey];
      }
      return state;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): IntegrationStore => ({
  data: data(state.data, action),
});

import React, { PropsWithChildren, CSSProperties } from 'react';
import './style.scss';
import clsx from 'clsx';

export default ({
  children,
  className,
  style,
  padding,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  justifyContent,
  gap,
  alignItems,
  alignSelf,
  margin,
  width,
  height,
  display,
  marginBottom,
  marginTop,
  flex,
  ...rest
}: PropsWithChildren &
  CSSProperties &
  React.DOMAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & { className?: string }) => (
  <div
    className={clsx('c-col', className)}
    style={{
      padding,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      justifyContent,
      gap,
      alignItems,
      alignSelf,
      margin,
      width,
      height,
      display,
      marginBottom,
      marginTop,
      flex,
    }}
  >
    {children}
  </div>
);

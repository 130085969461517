import { isURL, isInt, isFloat, isEmail } from 'validator';
import { handleScrollToElement } from '../builder';
import { defined } from 'utils/define';
import { Page } from '../../model/page';
import { AnyObject } from '../../types';
import { TrackingField } from '../../model/trackingField';
import { ValidationFunction, ValidationRule } from './types';
import { countDecimals } from 'utils/string';

const urlProtocols = ['http', 'https', 'ftp', 'tel', 'mailto', 'callto'];

export const TYPE_ARRAY = 'TYPE_ARRAY';

export default function validateForm(fields: AnyObject = {}, validations: ValidationRule[] = []) {
  let errors: AnyObject = {};
  validations.forEach(value => {
    const field = fields[value.field];
    if (field !== undefined) {
      value.validations.forEach(validation => {
        if (value.type === TYPE_ARRAY) {
          Object.entries(field).forEach(([idx, val]) => {
            if (defined(validation.validate) && !validation.validate(val)) {
              errors = {
                ...errors,
                [value.field]: {
                  ...(errors[value.field] || []),
                  [idx]: [
                    ...(!!errors[value.field] && !!errors[value.field][idx] ? errors[value.field][idx] : []),
                    validation.errorMessage(!!value.label ? value.label : field, val)
                  ]
                }
              };
            }
          });
        } else {
          if (defined(validation.validate) && !validation.validate(field)) {
            errors = {
              ...errors,
              [value.field]: [...(errors[value.field] || []), validation.errorMessage(!!value.label ? value.label : field, field)]
            };
          }
        }
      });
    }
  });

  return [errors];
}

const cloudFlareIP4AddressList = [
  '173.245.48.20',
  '173.245.48.19',
  '173.245.48.18',
  '173.245.48.17',
  '173.245.48.16',
  '173.245.48.15',
  '173.245.48.14',
  '173.245.48.13',
  '173.245.48.12',
  '173.245.48.11',
  '173.245.48.10',
  '173.245.48.9',
  '173.245.48.8',
  '173.245.48.7',
  '173.245.48.6',
  '173.245.48.5',
  '173.245.48.4',
  '173.245.48.3',
  '173.245.48.2',
  '173.245.48.1',
  '173.245.48.0',
  '103.21.244.22',
  '103.21.244.21',
  '103.21.244.20',
  '103.21.244.19',
  '103.21.244.18',
  '103.21.244.17',
  '103.21.244.16',
  '103.21.244.15',
  '103.21.244.14',
  '103.21.244.13',
  '103.21.244.12',
  '103.21.244.11',
  '103.21.244.10',
  '103.21.244.9',
  '103.21.244.8',
  '103.21.244.7',
  '103.21.244.6',
  '103.21.244.5',
  '103.21.244.4',
  '103.21.244.3',
  '103.21.244.2',
  '103.21.244.1',
  '103.21.244.0'
];

export const required: ValidationFunction = {
  errorMessage: (label, value) => `Required`,
  validate: value => defined(value) && value.toString().trim().length
};

export const domainContainFunnelflux: ValidationFunction = {
  errorMessage: (label, value) => `Your domain name should not contain "funnelflux"`,
  validate: value => defined(value) && !value.includes('funnelflux')
};

export const minCharacters = (min: number): ValidationFunction => ({
  errorMessage: (label, value) => `Min char = ${min}`,
  validate: value => typeof value === 'string' && value.length >= min
});

export const maxCharacters = (max: number): ValidationFunction => ({
  errorMessage: (label, value) => `Max char = ${max}`,
  validate: value => typeof value === 'string' && value.length <= max
});

export const isAlphanumeric: ValidationFunction = {
  errorMessage: (label, value) => `Please just use alphanumeric characters`,
  validate: value => (value.match(/\w/gm) || []).length === value.length
};

const getRegex = /(^\?|)(([\d\w]*=[\d\w-]*)|(#[\d\w]*))(&|)/gm;
export const extraUrlParams: ValidationFunction = {
  errorMessage: (label, value) => `Extra URL parameters are invalid`,
  validate: value => {
    value.match(getRegex);
    return getRegex.test(value);
  }
};

export const isCloudFlareIP4Address: ValidationFunction = {
  errorMessage: (label, value) => `You cannot use a cloudflare IPv4 address`,
  validate: value => defined(value) && !cloudFlareIP4AddressList.includes(value.toString())
};

export const IPV4: ValidationFunction = {
  errorMessage: (label, value) => `not a valid IPv4 address`,
  validate: value => (!!value && value.length ? value.match(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/g) : true)
};

export const IPV6: ValidationFunction = {
  errorMessage: (label, value) => `not a valid IPv6 address`,
  validate: value =>
    !!value &&
    value.match(
      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/g
    )
};

export const httpsUrl: ValidationFunction = {
  errorMessage: (label, value) => `Not a valid URL (only https allowed)`,
  validate: value =>
    isURLCustom(value, {
      protocols: ['https']
    })
};

export const email: ValidationFunction = {
  errorMessage: (label, value) => `Not a valid email address`,
  validate: value => !!value && isEmail(value)
};

const urlSpecialChars = ['@', '^', '$', '#', '%', '*', '`', '(', ')', '~'];
const MAX_SPECIAL_CHARS_COUNT = 10;

export const xxxUrl: ValidationFunction = {
  errorMessage: (label, value) => `Not a valid URL`,
  validate: (value: string) => {
    try {
      let url = new URL(value);
      const extraRule =
        urlSpecialChars.reduce((acc, item) => {
          acc += value.split(item).length - 1;
          return acc;
        }, 0) < MAX_SPECIAL_CHARS_COUNT;

      return (
        /(https|http|ftp|callto|geo|mailto|maps|bip|bbmi|itms-services|fb-me|fb-messenger|intent|line|skype|sms|snapchat|tel|tg|threema|twitter|viber|webcal|web+mastodon|wh|whatsapp):/g.test(
          url.protocol
        ) && extraRule
      );
    } catch (e) {
      return false;
    }
  }
};

export const url: ValidationFunction = {
  errorMessage: (label, value) => 'Not a valid URL',
  validate: value =>
    isURL(value, {
      protocols: urlProtocols
    })
};

export const customUrl: ValidationFunction = {
  errorMessage: (label, value) => 'Not a valid URL',
  validate: value =>
    isURLCustom(value, {
      protocols: urlProtocols
    })
};

export const trafficSourceCostModifier: ValidationFunction = {
  errorMessage: (label, value) => 'Not a valid number. Must be between 0 and 500',
  validate: value => Number(value) >= 0 && Number(value) <= 500 && countDecimals(Number(value)) <= 2
};

export const trafficSourcePostbackProbability: ValidationFunction = {
  errorMessage: (label, value) => 'Not a valid number. Must be between 0 and 99.9',
  validate: value => Number(value) >= 0 && Number(value) <= 99.9 && countDecimals(Number(value)) <= 2
};

export const finalUrl = (model: Page): ValidationFunction => {
  return {
    errorMessage: (label, value) => `You have duplicated query parameters, please resolve this error first`,
    validate: value => {
      try {
        if (!model.baseURL) return true;
        const urlParams = new URL(model.baseURL).searchParams;
        return !Array.from(urlParams.keys()).some(key => (model.queryParams || {}).hasOwnProperty(key));
      } catch (e) {
        return false;
      }
    }
  };
};

export const simpleUrl: ValidationFunction = {
  errorMessage: (label, value) => `Not a valid URL`,
  validate: value => !!value && isURLCustom(value)
};

export const notExist = (entities: string): ValidationFunction => {
  return {
    errorMessage: (label, value) => `This entity already exists`,
    validate: value => entities.indexOf(value) < 0
  };
};

export const isNumber: ValidationFunction = {
  errorMessage: (label, value) => `Not a valid number`,
  validate: value => isInt(value) || isFloat(value)
};

export const unique = (data: string | string[], resourceName: string): ValidationFunction => ({
  errorMessage: (label, value) => `A ${resourceName} with name "${value}" already exists`,
  validate: value => !data.includes(value)
});

export const cloudflareHostname: ValidationFunction = {
  errorMessage: (label, value) =>
    'Your domain matches one of our exclude lists - please make sure to use a real, valid domain, as Cloudflare will reject addition of things like example.com',
  validate: (value: string) =>
    new RegExp(
      '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$'
    ).test(value)
};

export const getErrorId = (name: string) => {
  return `error-${name}`;
};

export const handleScrollToErrorElement = (validationErrors: any, callback: any) => {
  handleScrollToElement(`error-${Object.keys(validationErrors)[0]}`, callback);
};

export const requiredTrafficSourceSlotsValues = (): ValidationFunction => {
  const errors: AnyObject = {};
  return {
    errorMessage: (label, value) => errors,
    validate: (value: { [key: string]: TrackingField }) => {
      Object.keys(value).forEach(key => {
        if (value[key].value === '' && value[key].name !== '') {
          errors[key] = {
            ...(errors[key] || {}),
            value: `Required`
          };
        }
        if (value[key].name === '' && value[key].value !== '') {
          errors[key] = {
            ...(errors[key] || {}),
            name: `Required`
          };
        }
      });

      return Object.keys(errors).length === 0;
    }
  };
};

const isURLCustom = (str: string, options?: ValidatorJS.IsURLOptions) =>
  isURL(str.replace('://{data-domain}/', '://example.com/'), options);

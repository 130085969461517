import React, { PropsWithChildren } from 'react';
import Button from 'antd/lib/button';
import VisibilityWrapper from '../VisibilityWrapper';
import { defined } from 'utils/define';
import Icon from '../Icon';
import { IconName, IconSize } from '../../types/icon';
import './style.scss';

const ButtonLoading = () => (
  <div className="c-ffButton__loadingIndicator" data-testid="button-loading">
    <span className="c-ffButton__loadingDot">&#9679;</span>
    <span className="c-ffButton__loadingDot">&#9679;</span>
    <span className="c-ffButton__loadingDot">&#9679;</span>
  </div>
);

const ButtonSkeletonLoading = () => (
  <div>
    <span className="c-ffButton__skeletonLoader"></span>
  </div>
);

export default ({
  className = '',
  onClick = () => {},
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  children,
  iconType,
  size = 'md',
  variant = 'primary',
  color = 'defaultColor',
  iconSize = 'small',
  loading = false,
  disabled = false,
  activeClassname,
  text,
  active,
  loadingType = 'default'
}: PropsWithChildren<{
  className?: string;
  activeClassname?: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseDown?: React.MouseEventHandler<any>;
  onMouseUp?: React.MouseEventHandler<any>;
  onMouseLeave?: React.MouseEventHandler<any>;
  iconType?: IconName;
  iconSize?: IconSize;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'outlined' | 'help' | 'ghost';
  color?: 'defaultColor' | 'dangerColor' | 'warningColor' | 'grayColor' | 'onboardingColor';
  loading?: boolean;
  disabled?: boolean;
  loadingType?: 'default' | 'skeleton';
  text?: string;
}>) => (
  <Button
    className={[
      'c-ffButton',
      `c-ffButton--${size}`,
      `c-ffButton--${variant}`,
      `c-ffButton--${color}`,
      loading ? `c-ffButton--loading` : '',
      loadingType === 'skeleton'
        ? 'c-ffButton--loadingSkeleton'
        : 'c-ffButton--loadingDefault',
      disabled ? `c-ffButton--disabled` : '',
      iconType ? `c-ffButton--withIcon` : '',
      active ? activeClassname : '',
      className
    ].join(' ')}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onMouseLeave={onMouseLeave}
    onMouseUp={onMouseUp}
    disabled={disabled}
  >
    <VisibilityWrapper visible={defined(iconType)}>
      <Icon name={iconType!} size={iconSize} className="c-ffButton__icon" />
    </VisibilityWrapper>
    <span className="c-ffButton__text">{children || text}</span>
    <VisibilityWrapper visible={loading}>
      <>
        <VisibilityWrapper visible={loadingType === 'default'}>
          <ButtonLoading />
        </VisibilityWrapper>
        <VisibilityWrapper visible={loadingType === 'skeleton'}>
          <ButtonSkeletonLoading />
        </VisibilityWrapper>
      </>
    </VisibilityWrapper>
  </Button>
);

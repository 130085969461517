import Auth from 'api/auth';
import { User } from '../../model/user';

export const _getAccessToken = (state: any) =>
  state.tokens ? state.tokens.accessToken : null;

export const isMockedUser = (user: User) => {
  return user.tokens && user.tokens.accessToken === 'mocked';
};

export const _loggedIn = (user: User) => {
  return (
    (user.tokens && user.userId && user.loggedIn && Auth.isAuthenticated) ||
    isMockedUser(user)
  );
};

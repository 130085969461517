import { AnyObject } from '../../types';
import { sortByName } from '../sort';

export const equalObjects = (first: object, second: object) =>
  JSON.stringify(first) === JSON.stringify(second);

export function cloneObject<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export const sameObjects = (
  originalFirst: AnyObject,
  originalSecond: AnyObject,
  exceptProperties: string[],
  sortProperties: Record<string, string>
) => {
  let first = cloneObject(originalFirst);
  let second = cloneObject(originalSecond);

  exceptProperties.forEach(prop => {
    delete first[prop];
    delete second[prop];
  });

  for (let sortKey in sortProperties) {
    sortByName(first[sortKey], sortProperties[sortKey]);
    sortByName(second[sortKey], sortProperties[sortKey]);
  }

  first = Object.keys(first)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: first[key]
      }),
      {}
    );
  second = Object.keys(second)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: second[key]
      }),
      {}
    );

  return equalObjects(first, second);
};

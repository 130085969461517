import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="new-file--empty-common-file-content">
      <path
        id="Vector"
        fill="#ffffff"
        d="M16.921071428571427 0.9953571428571428v7.962857142857143h7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#ffffff"
        d="M16.921071428571427 0.9953571428571428v7.962857142857143h7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        fill="#d7e0ff"
        d="M24.88392857142857 24.88392857142857c0 0.5279374285714286 -0.20982128571428568 1.0343751428571428 -0.5830802142857142 1.4076340714285713s-0.8796966428571429 0.5830802142857142 -1.4076340714285713 0.5830802142857142h-17.916428571428572c-0.5279772428571429 0 -1.0343154214285712 -0.20982128571428568 -1.4076539785714286 -0.5830802142857142C3.1958130857142852 25.918303714285713 2.9860714285714285 25.411866 2.9860714285714285 24.88392857142857v-21.89785714285714c0 -0.5279772428571429 0.20974165714285714 -1.0343154214285712 0.5830603071428571 -1.4076480064285715C3.9424702928571427 1.205092827857143 4.448808471428571 0.9953571428571428 4.976785714285715 0.9953571428571428H17.916428571428572L24.88392857142857 7.962857142857143v16.921071428571427Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2530"
        fill="#ffffff"
        d="M16.921071428571427 8.958214285714286v-7.962857142857143H17.916428571428572L24.88392857142857 7.962857142857143v0.9953571428571428h-7.962857142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.88392857142857 24.88392857142857c0 0.5279374285714286 -0.20982128571428568 1.0343751428571428 -0.5830802142857142 1.4076340714285713s-0.8796966428571429 0.5830802142857142 -1.4076340714285713 0.5830802142857142h-17.916428571428572c-0.5279772428571429 0 -1.0343154214285712 -0.20982128571428568 -1.4076539785714286 -0.5830802142857142C3.1958130857142852 25.918303714285713 2.9860714285714285 25.411866 2.9860714285714285 24.88392857142857v-21.89785714285714c0 -0.5279772428571429 0.20974165714285714 -1.0343154214285712 0.5830603071428571 -1.4076480064285715C3.9424702928571427 1.205092827857143 4.448808471428571 0.9953571428571428 4.976785714285715 0.9953571428571428H17.916428571428572L24.88392857142857 7.962857142857143v16.921071428571427Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2529"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.921071428571427 0.9953571428571428v7.962857142857143h7.962857142857143"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

import { ModalsConsumer } from 'components/Modals/context';
import React from 'react';
import FluxModal from 'components/Modal';
import {
  CONDITION_MODAL,
  CREATE_NEW_ASSET_MODAL,
  FUNNELGROUP_MODAL,
  FUNNEL_MODAL,
  LANDER_MODAL,
  LANDER_OFFER_GROUP_MODAL,
  OFFER_MODAL,
  OFFER_SOURCE_MODAL,
  SIMPLE_FLOWS_MODAL,
  TRAFFIC_SOURCE_MODAL
} from 'constants/modal';
import { ModalProps as ModalType } from '../types';
import { FormContextModal } from 'types/modal';
import './style.scss';
import { CraeteNewAssetModalProps } from 'types/ModalForms/craeteNewAsset';
import { FFIcon, FFNewIcon } from 'uikit';

const Modal = ({ isOpen, closeSelf, context }: CraeteNewAssetModalProps) => (
  <FluxModal
    width="auto"
    title="Create New Asset"
    isOpen={isOpen}
    classNames={['createNewAsset-modal']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    zIndex={context.zIndex}
    destroyOnClose={true}
    render={() => {
      const onClick = (sidebarName: string) => {
        context.onSubmit!(sidebarName);
        closeSelf();
      };
      return (
        <div className="c-createNewAssetModal">
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(FUNNELGROUP_MODAL)}
          >
            <FFNewIcon name="general/duotone/label-folder-tag" size="lg" />
            Funnel Group
          </div>          
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(FUNNEL_MODAL)}
          >
            <FFNewIcon name="navigation/funnels" size="lg" />
            Visual Funnel
          </div>
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(SIMPLE_FLOWS_MODAL)}
          >
            <FFNewIcon name="general/duotone/simple-flow" size="lg" />
            Simple Flow
          </div>
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(TRAFFIC_SOURCE_MODAL)}
          >
            <FFNewIcon name="navigation/traffic-sources" size="lg" />
            Traffic Source
          </div>
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(LANDER_MODAL)}
          >
            <FFNewIcon name="navigation/landers" size="lg" />
            Lander
          </div>
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(OFFER_MODAL)}
          >
            <FFNewIcon name="navigation/offers" size="lg" />
            Offer
          </div>
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(OFFER_SOURCE_MODAL)}
          >
            <FFNewIcon name="navigation/offer-sources" size="lg" />
            Offer Source
          </div>
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(LANDER_OFFER_GROUP_MODAL)}
          >
            <FFNewIcon name="navigation/page-groups" size="lg" />
            Global Page Group
          </div>
          <div
            className="c-createNewAssetModal__item"
            onClick={() => onClick(CONDITION_MODAL)}
          >
            <FFNewIcon name="navigation/conditions" size="lg" />
            Global Condition
          </div>
        </div>
      );
    }}
  />
);

const CreateNewAssetModal = () => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalType) => (
        <>
          <Modal
            isOpen={isOpenModal(CREATE_NEW_ASSET_MODAL)}
            closeSelf={() => closeModal(CREATE_NEW_ASSET_MODAL)}
            context={
              getContextModal(CREATE_NEW_ASSET_MODAL) as FormContextModal
            }
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default CreateNewAssetModal;

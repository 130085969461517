import React from 'react';
import { DatePicker } from 'antd';
import { conditionalClass } from 'conditional-class';
import { getTimeRanges, timeZoneOptions } from 'constants/time';
import VisibilityWrapper from '../VisibilityWrapper';
import './style.scss';
import { RangePickerProps } from 'antd/es/date-picker';
import { defined } from 'utils/define';
import { DatePickerValues, getDatePickerValues } from 'utils/time-helper';
import moment, { Moment } from 'moment';
import { SelectOption, TimeRangeKeys } from 'types';
import FFSelect from '../Select';

let timeRangeItems = getTimeRanges();

type onDatePickerChange = (
  value: DatePickerValues,
  nowTime?: string
) => void | Promise<void>;
type SetRangeName = (
  rangeKey: TimeRangeKeys,
  isChangedByCalendar: boolean
) => void;
type Ranges = {
  short: {
    name: TimeRangeKeys;
    value: Moment[];
  }[];
  long: {
    name: TimeRangeKeys;
    value: Moment[];
  }[];
};
type onOpenChange = (isOpen: boolean, isChanged?: boolean) => void;

const getRanges = (): Ranges => ({
  short: [
    {
      name: 'Today',
      value: timeRangeItems.Today
    },
    {
      name: 'Yesterday',
      value: timeRangeItems.Yesterday
    },
    {
      name: 'Last 24 h',
      value: timeRangeItems['Last 24 h']
    },
    {
      name: 'Last 72 h',
      value: timeRangeItems['Last 72 h']
    },
    {
      name: 'Last 7 days',
      value: timeRangeItems['Last 7 days']
    },
    {
      name: 'Last 14 days',
      value: timeRangeItems['Last 14 days']
    },
  ],
  long: [
    {
      name: 'Last 30 days',
      value: timeRangeItems['Last 30 days']
    },
    {
      name: 'This Month',
      value: timeRangeItems['This Month']
    },
    {
      name: 'Last Month',
      value: timeRangeItems['Last Month']
    },
    {
      name: '90 Days',
      value: timeRangeItems['90 Days']
    },
    {
      name: 'This Year',
      value: timeRangeItems['This Year']
    },
    {
      name: 'Last Year',
      value: timeRangeItems['Last Year']
    }
  ]
});

const onDateTimeSelect = () => {
  const okBtn = document.querySelector(
    '.ant-picker-dropdown .ant-picker-ok button'
  ) as HTMLButtonElement;
  requestAnimationFrame(() => {
    if (defined(okBtn)) {
      okBtn.click();
    }
  });
};

const ExtraFooter = ({
  onChange,
  setOpen,
  onOpenChange,
  setRangeName,
  ranges
}: {
  onChange: onDatePickerChange;
  onOpenChange: onOpenChange;
  setOpen: (isOpen: boolean) => void;
  setRangeName: SetRangeName;
  ranges: Ranges;
}) => {
  const onChangeAndClose = async (
    value: Moment[],
    dateRangeName: TimeRangeKeys
  ) => {
    await setRangeName(dateRangeName, false);
    await onChange(getDatePickerValues(value, true));
    setOpen(false);
    onOpenChange!(false, true);
  };

  return (
    <div className="c-ffDatepicker__footer">
      <div className="c-ffDatepicker__footerRow c-ffDatepicker__footerShort">
        <span className="c-ffDatepicker__footerRowTitle">SHORT:</span>
        {ranges.short.map(tr => (
          <button
            className="c-ffDatepicker__footerRowBtn"
            onClick={() => onChangeAndClose(tr.value, tr.name)}
          >
            {tr.name}
          </button>
        ))}
      </div>
      <div className="c-ffDatepicker__footerRow c-ffDatepicker__footerLong">
        <span className="c-ffDatepicker__footerRowTitle">LONG:</span>
        {ranges.long.map(tr => (
          <button
            className="c-ffDatepicker__footerRowBtn"
            onClick={() => onChangeAndClose(tr.value, tr.name)}
          >
            {tr.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ({
  onChange = () => {},
  label,
  format = 'DD/MM/YYYY HH:mm',
  size = 'middle',
  onCalendarChange,
  onOpenChange = () => {},
  className,
  value,
  showTimeZone = true,
  timeZone = { onTimeZoneChange: () => {} },
  setRangeName = () => {},
  disabled = false,
  calendarDisabled = false,
  showTime = true,
}: Omit<
  RangePickerProps,
  'picker' | 'onChange' | 'onCalendarChange' | 'value' | 'onOpenChange'
> & {
  label?: string;
  showTimeZone?: boolean;
  showTime?: boolean;
  onChange: onDatePickerChange;
  onCalendarChange?: onDatePickerChange;
  setRangeName?: SetRangeName;
  onOpenChange?: onOpenChange;
  timeZone?: {
    placeholder?: string;
    changeDropDownPosition?: boolean;
    disabled?: boolean;
    onTimeZoneChange?: (timeZone: string) => void;
  };
  value: DatePickerValues;
  disabled?: boolean;
  calendarDisabled?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [ranges, setRanges] = React.useState<Ranges>(getRanges());

  return (
    <>
      <VisibilityWrapper visible={defined(label)}>
        <span className="c-ffDatepicker__label">{label}</span>
      </VisibilityWrapper>
      <DatePicker.RangePicker
        disabled={disabled || calendarDisabled}
        popupClassName="c-ffDatepicker__dropdown"
        className={conditionalClass('c-ffDatepicker', {
          [`${className}`]: Boolean(className),
          'c-ffDatepicker__size--middle': size === 'middle'
        })}
        dateRender={currentDate => (
          <div className="ant-picker-cell-inner" onClick={onDateTimeSelect}>
            {currentDate.date()}
          </div>
        )}
        showTime={showTime && {
          format: 'HH:mm'
        }}
        open={open}
        onOpenChange={isOpen => {
          if (isOpen) {
            timeRangeItems = getTimeRanges();
            setRanges(getRanges());
          }
          setOpen(isOpen);
        }}
        value={value?.date as any}
        format={format}
        onChange={value => {
          if (!value) {
            return;
          }
          if (!showTime && value.length > 1) {
            value[1]!.hour(23).minute(59).second(59);
          }
          onChange(
            {
              ...getDatePickerValues(value),
              isChangedByCalendar: true
            },
            getDatePickerValues(moment()).timeEnd
          );
        }}
        onCalendarChange={async (value, f, info) => {
          if (onCalendarChange) {
            await onCalendarChange({
              ...getDatePickerValues(value),
              isChangedByCalendar: true
            });
          } else {
            await onChange(
              {
                ...getDatePickerValues(value),
                isChangedByCalendar: true
              },
              getDatePickerValues(moment()).timeEnd
            );
          }
          await setRangeName('' as any, false);
          if (info.range === 'end') {
            await onOpenChange(false, true);
          }
        }}
        renderExtraFooter={() => (
          <ExtraFooter
            onChange={onChange}
            setOpen={setOpen}
            onOpenChange={onOpenChange}
            setRangeName={setRangeName}
            ranges={ranges}
          />
        )}
      />
      <VisibilityWrapper visible={showTimeZone}>
        <FFSelect
          showArrow
          value={value?.timeZone}
          onChange={async (val: string) => {
            localStorage.setItem('timeZone', val);
            timeRangeItems = getTimeRanges()
            await onChange(getDatePickerValues(value.date, false));
            await setRanges(getRanges());
            await timeZone.onTimeZoneChange!(val);
            if (defined(onOpenChange)) {
              await onOpenChange(false, true);
            }
          }}
          options={timeZoneOptions}
          placeholder={timeZone?.placeholder}
          className='c-ffDatepicker__timeZoneSelector'
          style={{ width: 115 }}
          valueGetter={(option: SelectOption) => option.value}
          labelGetter={(option: SelectOption) => option.label}
          showSearch={true}
          filterOption={true}
          data-testid="timezone"
          dropdownMatchSelectWidth={false}
          disabled={timeZone?.disabled || disabled}
        />
      </VisibilityWrapper>
    </>
  );
};

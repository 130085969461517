import { createSelector } from 'reselect';
import { DrilldownReport } from '../../model/drilldownReport';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { FunnelGroupStore } from '../../types/redux/store';
import { defined } from 'utils/define';
import { StatusEnum } from 'api/types';
import { convertCampaignNoFieldValue, getIndexOfCampaignAttribute } from 'utils/selectors';
import { NO_CHILD_FUNNEL_PRESENT } from 'constants/table';
import { makeReportingRowData } from 'utils/reporting';

export const getReporting = (state: FunnelGroupStore) => state.reporting;
export const getShow = (state: FunnelGroupStore) => state.settings.show;
export const isQuickStats = (_: FunnelGroupStore, isQuickStats?: boolean) => !!isQuickStats;
export const attributes = (state: FunnelGroupStore) => state.settings.filters.attributes;
export const getCategories = (state: FunnelGroupStore) => state.data;

export const getFunnelGroupsReporting: any = createSelector(
  [getReporting, getShow, isQuickStats, attributes, getCategories],
  (reporting: DrilldownReport, show: StatusEnum, isQuickStatsView, attributes, categories): DrilldownReport => {
    const isAll = show === 'not-deleted';
    const status = show;

    if (!defined(reporting.rows)) {
      return reporting;
    }

    const getArchivedRow = (row: DrilldownReportRow) => {
      if (defined(row.attributes[0]) && defined(row.attributes[1])) {
        const noDeletedEntity = row.attributes[0].status !== 'deleted' && row.attributes[1].status !== 'deleted';
        if (!!row.attributes[0].status && !!row.attributes[1].status) {
          return (
            noDeletedEntity &&
            (status === 'archived'
              ? row.attributes[0].status === status || row.attributes[1].status === status
              : row.attributes[0].status === status && row.attributes[1].status === status)
          );
        } else {
          return noDeletedEntity && (row.attributes[0].status === status || row.attributes[1].status === status);
        }
      }
      return true;
    };

    const isDeleted = (row: DrilldownReportRow) => {
      if (defined(row.attributes[0]) && defined(row.attributes[1])) {
        return row.attributes[0].status === 'deleted' || row.attributes[1].status === 'deleted';
      }
      return true;
    };

    const groupedCategoriesReport: { [key: string]: DrilldownReportRow[] } = {};

    reporting.rows.forEach(row => {
      const funnelGroupId = row.attributes[0].id;
      if (!groupedCategoriesReport[funnelGroupId]) {
        groupedCategoriesReport[funnelGroupId] = [];
      }
      groupedCategoriesReport[funnelGroupId].push(row);
    });

    Object.entries(groupedCategoriesReport).forEach(([key, rows]) => {
      if (rows.every(row => row.attributes?.[1]?.status === 'deleted')) {
        const funnelGroupAttributes = rows[0].attributes;
        groupedCategoriesReport[key] = [
          makeReportingRowData([
            funnelGroupAttributes[0],
            {
              attribute: '',
              value: NO_CHILD_FUNNEL_PRESENT,
              id: NO_CHILD_FUNNEL_PRESENT,
              status: funnelGroupAttributes[0].status
            }
          ])
        ];
      }
    });

    const indexOfCampaignAttribute = getIndexOfCampaignAttribute(attributes);
    reporting.rows = Object.values(groupedCategoriesReport)
      .flat()
      .filter(row => {
        row = convertCampaignNoFieldValue(row, indexOfCampaignAttribute);
        return isAll ? (isQuickStatsView ? row : !isDeleted(row)) : getArchivedRow(row);
      });

    return reporting;
  }
);

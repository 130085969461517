import { NAV_ACTIONS } from '../types';
import { sideMenu } from 'utils/menu';
import { defined } from '../../utils/define';
import { Dispatch } from 'redux';
import { CompositeStore } from 'types/redux/store';
import {
  AddFunnelEditorParams,
  SetLastViewRouteParams,
  ViewChangedParams
} from 'types/navigation';
import { History } from 'types';
import { pageSegment } from '../../widgets/segment';
import {
  DeleteFunnelEditor,
  ChangeView,
  SetLastViewRoute
} from 'types/actions';

const viewChanged = (data: ViewChangedParams) => ({
  type: NAV_ACTIONS.CHANGED,
  data
});

const siderMenuIsExpanded = () => ({
  type: NAV_ACTIONS.SET_SIDER_MENU_IS_EXPANDED
});

const funnelEditorAdded = (data: AddFunnelEditorParams) => ({
  type: NAV_ACTIONS.BUILDER_FUNNEL_CHANGED,
  data
});

const funnelEditorDeleted = (idFunnel: string) => ({
  type: NAV_ACTIONS.BUILDER_FUNNEL_DELETED,
  data: {
    idFunnel: idFunnel
  }
});

export const funnelsEditorDeleted = (idFunnels: string[]) => ({
  type: NAV_ACTIONS.BUILDER_FUNNELS_DELETED,
  data: {
    idFunnels: idFunnels
  }
});

const setViewRoute = (data: SetLastViewRouteParams) => ({
  type: NAV_ACTIONS.SET_VIEW_LAST_ROUTE,
  payload: {
    currentView: data.currentActiveItem,
    path: data.path
  }
});

export const changeView: ChangeView = (viewName: string) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const { navigation } = getState();
  const foundSideMenu = sideMenu.find(s => {
    if (s.view === viewName) {
      return s.view === viewName;
    } else {
      return (s.items || []).some(
        it => it.path === viewName || it.view === viewName
      );
    }
  });

  if (defined(foundSideMenu)) {
    const { items, view, name } = foundSideMenu;
    const pathname = window.location.pathname;

    let data: ViewChangedParams = {
      sub: items || [],
      next: view,
      prev: navigation.current,
      prevPath: pathname
    };

    if (navigation.current !== data.next || navigation.prevPath !== pathname) {
      dispatch(viewChanged(data));
    }

    if (pathname !== '/') {
      let pageName = '';
      sideMenu.forEach(s => {
        (s.items || []).forEach(item => {
          if (pathname === item.path) {
            pageName = item.title;
          }
        });
      });

      if (pageName === '' && pathname.includes('/funneleditor')) {
        pageName = 'Funnel Editor';
      }
      pageSegment(name, pageName);
    }
  }
  return Promise.resolve();
};

export const addFunnelEditor = (data: AddFunnelEditorParams) => (
  dispatch: Dispatch
) => {
  dispatch(funnelEditorAdded(data));
  return changeView('FunnelEditor');
};

export const setSiderMenuIsExpanded = () => (dispatch: Dispatch) => {
  dispatch(siderMenuIsExpanded());
};

export const deleteFunnelEditor: DeleteFunnelEditor = (
  idFunnels: string[],
  history: History
) => (dispatch: Dispatch) => {
  idFunnels.forEach(idFunnel => {
    dispatch(funnelEditorDeleted(idFunnel));
  });
  history.push(`/funnels`);
  return changeView('Funnels');
};

export const setLastViewRoute: SetLastViewRoute = (
  data: SetLastViewRouteParams
) => setViewRoute(data);

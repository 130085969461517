import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import AddFunnelForm from 'components/Forms/Funnel';
import {
  FUNNEL_MODAL,
  FUNNEL_DUPLICATE_MODAL,
  FUNNELGROUP_MODAL
} from 'constants/modal';
import { connect } from 'react-redux';
import { FunnelModalSelector } from 'redux/selectors';
import {
  FunnelContextModal,
  FunnelSidebarProps,
  FunnelTabKey
} from 'types/ModalForms/funnel';
import {
  addFunnel,
  duplicateFunnel,
  fetchFunnels,
  getFunnelById,
  updateFunnel
} from 'redux/actions/funnels';
import { Link } from 'react-router-dom';
import { FunnelFormProps } from 'types/ModalForms/funnel';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import {
  FormContextSidebar,
  NavigationTabContextModal
} from '../../../types/modal';
import { addFunnelEditor } from '../../../redux/actions/navigation';
import { defined } from '../../../utils/define';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import { AddFunnelEditor } from 'types/actions';
import { getModalTitle, isCopyByContextModal } from '../../../utils/modals';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import { ModalsConsumer } from 'components/Modals/context';
import { ModalProps } from 'components/Modals/types';
import { fetchOffers } from 'redux/actions/offers';
import { fetchLanders } from 'redux/actions/landers';
import {
  FUNNEL_FORM_TAB,
  FUNNEL_FORM_ADVANCED_FUNNEL_SETTINGS_TAB,
  FUNNEL_FORM_HELP_TAB,
  FUNNEL_FORM_REDIRECT_LINKS_TAB,
  FUNNEL_FORM_USE_JAVASCRIPT_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import { fetchPageGroups } from 'redux/actions/pageGroups';
import { fetchTrafficSources } from 'redux/actions/trafficsources';
import {
  fetchFunnelGroups,
  fetchFunnelGroupsInfo
} from 'redux/actions/funnelGroups';
import { fetchDomains } from 'redux/actions/domains';
import { FFIcon, VisibilityWrapper } from 'uikit';
import './style.scss';

const getFunnelTitle = (
  contextModal: FunnelContextModal,
  closeSelf: () => void,
  addFunnelEditor: AddFunnelEditor
) => {
  const data = (contextModal as FormContextSidebar).data;

  return (
    <div className="sb-funnel__titleIconWrapper">
      <span className="sb-funnel__title">{`${getModalTitle(
        contextModal as FormContextSidebar
      )} Funnel`}</span>
      <VisibilityWrapper
        visible={defined(data) && !isCopyByContextModal(contextModal)}
      >
        <Link
          to={`/funneleditor/${data?.id}`}
          onClick={() => {
            addFunnelEditor({
              idFunnel: data.id,
              funnelName: data.value
            });
            closeSelf();
          }}
        >
          <div className="sb-funnel__link">
            <FFIcon name="tableEdit" size="small" />
            <span className="sb-funnel__linkText">Launch Editor</span>
          </div>
        </Link>
      </VisibilityWrapper>
    </div>
  );
};

class FunnelFormWrapper extends React.Component<
  FunnelFormProps & FunnelSidebarProps & LoadingProps,
  {}
> {
  static defaultProps: Partial<FunnelSidebarProps> = {
    withWrapper: comp => comp
  };

  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen!}
        hasTab={true}
        onClose={this.props.closeSelf}
        defaultOpenSideBarKey="funnelForm"
        defaultActiveTab={
          this.props.shouldOpenFunnelLinkSection
            ? FUNNEL_FORM_REDIRECT_LINKS_TAB
            : FUNNEL_FORM_TAB
        }
        dataPortalKey="funnel"
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'funnelForm',
            tabs: {
              [FUNNEL_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [FUNNEL_FORM_ADVANCED_FUNNEL_SETTINGS_TAB]: {
                title: 'Advanced Funnel Settings',
                icon: <FFIcon name="advancedSettings" />,
                isHidden: !defined(
                  (this.props.contextModal as FormContextSidebar)?.data &&
                    !defined(
                      (this.props.contextModal as FormContextSidebar).copyName
                    )
                )
              },
              [FUNNEL_FORM_REDIRECT_LINKS_TAB]: {
                title: 'Redirect Links',
                icon: <FFIcon name="redirectLink" />,
                isHidden: !defined(
                  (this.props.contextModal as FormContextSidebar)?.data
                )
              },
              [FUNNEL_FORM_USE_JAVASCRIPT_TAB]: {
                title: 'Javascript Tracking',
                icon: <FFIcon name="jsTracking" />,
                isHidden: !defined(
                  (this.props.contextModal as FormContextSidebar)?.data
                )
              },
              [FUNNEL_FORM_HELP_TAB]: {
                title: 'Help',
                icon: <FFIcon name="question" />
              }
            },
            title:
              this.props.customTitle ||
              getFunnelTitle(
                this.props.contextModal!,
                this.props.closeSelf!,
                this.props.addFunnelEditor!
              ),
            render: ({
              activeTab,
              setButtonGroupProps,
              setForCopyIdsProps,
              setLoading,
              sidebarLoading,
              tabTitle
            }) => {
              return this.props.withWrapper!(
                <>
                  <AddFunnelForm
                    {...this.props}
                    activeTab={activeTab as FunnelTabKey}
                    setButtonGroupProps={setButtonGroupProps}
                    setSidebarLoading={setLoading}
                    sidebarLoading={sidebarLoading}
                    tabTitle={tabTitle}
                    setForCopyIdsProps={setForCopyIdsProps}
                  />
                </>
              );
            }
          }
        ]}
      />
    );
  }
}

export const FormElement = withLoading(FunnelFormWrapper);

const ConnectedFormElement = ({
  pagesArray,
  pageGroupsArray,
  funnelGroups,
  funnelGroupsArray,
  funnels,
  trafficSources,
  addFunnel,
  updateFunnel,
  duplicateFunnel,
  getFunnelById,
  addFunnelEditor,
  userSettings,
  scripts,
  domains,
  fetchOffers,
  fetchLanders,
  fetchFunnels,
  fetchPageGroups,
  fetchTrafficSources,
  fetchDomains,
  fetchFunnelGroups,
  fetchFunnelGroupsInfo
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        openSidebar,
        setContextSidebar,
        getOffsetRight
      }: SidebarProps) => (
        <ModalsConsumer>
          {({ closeModal }: ModalProps) => {
            let sidebarName = FUNNEL_MODAL;
            let contextSidebar = getContextSidebar(FUNNEL_MODAL) as
              | FormContextSidebar
              | NavigationTabContextModal;

            if (isOpenSidebar(FUNNEL_DUPLICATE_MODAL)) {
              sidebarName = FUNNEL_DUPLICATE_MODAL;
              contextSidebar = getContextSidebar(
                FUNNEL_DUPLICATE_MODAL
              ) as FormContextSidebar;
              contextSidebar.copyName = getDuplicateModalSidebarContext(
                contextSidebar?.data?.id,
                funnels
              );
              contextSidebar.saveText = 'Duplicate Funnel';
            }
            const isOpen =
              isOpenSidebar(FUNNEL_MODAL) ||
              isOpenSidebar(FUNNEL_DUPLICATE_MODAL);

            return (
              <FormElement
                fetchFunnelGroupsInfo={fetchFunnelGroupsInfo}
                fetchDomains={fetchDomains}
                pagesArray={pagesArray}
                pageGroupsArray={pageGroupsArray}
                funnelGroupsArray={funnelGroupsArray}
                funnelGroups={funnelGroups}
                funnels={funnels}
                trafficSources={trafficSources}
                handleCreate={addFunnel}
                handleUpdate={updateFunnel}
                handleDuplicate={duplicateFunnel}
                contextModal={contextSidebar}
                getFunnelById={getFunnelById}
                addFunnelEditor={addFunnelEditor}
                userSettings={userSettings}
                domains={domains}
                highlightRequiredFields={
                  (contextSidebar as NavigationTabContextModal)
                    ?.highlightRequiredFields || false
                }
                showSaveButton={
                  (contextSidebar as NavigationTabContextModal)
                    ?.showSaveButton || true
                }
                showSaveAndCreateButton={
                  (contextSidebar as NavigationTabContextModal)
                    ?.showSaveAndCreateButton || true
                }
                editorMode={false}
                isOpen={isOpen}
                closeSelf={() => closeSidebar(sidebarName)}
                openGroupModal={data => {
                  setContextSidebar(FUNNELGROUP_MODAL, { data });
                  openSidebar(FUNNELGROUP_MODAL);
                }}
                closeModal={closeModal}
                zIndex={(contextSidebar as FormContextSidebar)?.zIndex || 0}
                offsetRight={getOffsetRight(sidebarName)}
                fetchOffers={fetchOffers}
                fetchLanders={fetchLanders}
                fetchFunnels={fetchFunnels}
                fetchPageGroups={fetchPageGroups}
                fetchFunnelGroups={fetchFunnelGroups}
                fetchTrafficSources={fetchTrafficSources}
              />
            );
          }}
        </ModalsConsumer>
      )}
    </SidebarsConsumer>
  );
};

export default connect(FunnelModalSelector, {
  addFunnel,
  updateFunnel,
  duplicateFunnel,
  getFunnelById,
  addFunnelEditor,
  fetchOffers,
  fetchLanders,
  fetchFunnels,
  fetchPageGroups,
  fetchTrafficSources,
  fetchFunnelGroups,
  fetchDomains,
  fetchFunnelGroupsInfo
})(ConnectedFormElement);

import {
  TrafficSourceArray,
  TrafficSourceStore
} from '../../types/redux/store';
import { createSelector } from 'reselect';
import { DrilldownReport } from '../../model/drilldownReport';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { getCategories, getCategory } from '../../utils/selectors';
import { naturalSort } from '../../utils/sort';
import { AnyObject, ReportingCustomFieldOption } from '../../types';
import { defined } from 'utils/define';
import { StatusEnum } from 'api/types';
import { TrackingField } from '../../model/trackingFields';
import { arrayToObject } from '../../utils/arrs';
import { TrafficSource } from '../../model/trafficSource';

const _getReporting = (state: TrafficSourceStore) => state.reporting;
const _getShow = (state: TrafficSourceStore) => state.settings.show;
export const _getAllCategories = (state: TrafficSourceStore) =>
  state.categories;
export const _isQuickStatsOrSummary = (
  _: TrafficSourceStore,
  isQuickStatsOrSummary?: boolean
) => !!isQuickStatsOrSummary;

export const getTrafficSourcesReporting: any = createSelector(
  [_getReporting, _getShow, _isQuickStatsOrSummary],
  (
    reporting: DrilldownReport,
    show: StatusEnum,
    isQuickStatsOrSummary
  ): DrilldownReportRow[] => {
    const categories = getCategories(reporting);
    const allCategoriesByKeys = arrayToObject(
      reporting.entities?.categories || [],
      'idCategory'
    );
    let existedCategories: AnyObject = {};
    const isAll = show === 'not-deleted';
    const status = show;
    if (!defined(reporting.rows)) {
      return [];
    }
    return reporting.rows
      .filter(row =>
        isQuickStatsOrSummary
          ? true
          : isAll
          ? row.attributes[0].status !== 'deleted'
          : row.attributes[0].status === status
      )
      .map(row => {
        const entity = row.attributes[0];
        const category = getCategory(entity, categories, allCategoriesByKeys);

        existedCategories[category.id] = true;

        return {
          ...row,
          attributes: [category, ...row.attributes]
        };
      });
    /*.concat(
        emptyCategoryRows(
          allCategories,
          existedCategories,
          isAll,
          status,
          reporting
        )
      )*/
  }
);

export const getTrafficSourcesFilterList = (state: TrafficSourceStore) => {
  return Object.values(state.data).map(item => {
    return {
      value: item.idTrafficSource,
      label: item.trafficSourceName
    };
  });
};

const _getAllById = (state: TrafficSourceStore) => state.data;

export const getReportingCustomFieldOptions = createSelector(
  [_getAllById],
  (data: TrafficSourceArray) => {
    const filterTrackingFieldsSlots: TrafficSource[] = Object.values(data)
      .filter(item => defined(item.trackingFieldSlots))
      .sort((a, b) => naturalSort(a.trafficSourceName, b.trafficSourceName));
    const result: { [key: string]: ReportingCustomFieldOption } = {};

    filterTrackingFieldsSlots.forEach((item: TrafficSource) => {
      Object.entries(item.trackingFieldSlots!).forEach(
        ([trackingFieldValue, trackingField]: [string, TrackingField]) => {
          if (
            defined(trackingField.name) &&
            trackingField.name !== '' &&
            !['campaign', 'external'].includes(trackingFieldValue)
          ) {
            result[`${item.trafficSourceName}-${trackingField.name}`] = {
              category: item.trafficSourceName,
              label: trackingField.name,
              value: `${item.idTrafficSource}/${item.trafficSourceName}/${trackingField.name}/${trackingFieldValue}`,
              idTrafficSource: item.idTrafficSource,
              trafficSourceName: item.trafficSourceName,
              trackingFieldName: trackingField.name,
              trackingFieldValue,
            };
          }
        }
      );
    });

    return Object.values(result).sort((a, b) =>
      naturalSort(a.category, b.category)
    );
  }
);

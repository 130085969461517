import React from 'react';
import './style.scss';
import {
  FunnelConditionGroup,
  FunnelConditionRoute,
  FunnelConditionRule,
  TrafficSource
} from 'model/models';
import { DatePicker, TimePicker } from 'antd';
import './style.scss';
import { defined } from 'utils/define';
import { AndOrOperator } from 'components/Parts/Groups';
import { IconTooltip } from 'components/Parts/Tooltip';
import { IconedButton } from 'components/Parts/Buttons';
import { conditionalClass } from 'conditional-class';
import { Input, Select } from 'components/Parts/Inputs';
import Icon from 'components/Icons';
import { AnyObject } from 'types';
import moment from 'moment';
import {
  getConditionCorrectAttributeCategory,
  getConditionCorrectAttributeName,
  getRuleParam,
  getRuleParamKey,
  rulesAttributes
} from 'constants/condition';

interface Props {
  route: FunnelConditionRoute;
  idRoute?: number;
  setRouteParam: (
    param: string,
    value: string,
    idx: number,
    routeNamesEditedByUser?: AnyObject | undefined
  ) => void;
  addGroup: (idRoute: number) => Promise<void>;
  addRule: (idRoute: number, idGroup: number) => Promise<void>;
  setRouteGroupParam: (
    param: string,
    value: string,
    idRoute: number,
    idGroup: number
  ) => void;
  deleteGroup: (idx: number, idGroup: number) => Promise<void>;
  showErrorTooltip: boolean;
  onDeleteRule: (
    idRoute: number,
    idGroup: number,
    idRule: number
  ) => Promise<void>;
  onSetRuleOption: (
    param: string | undefined,
    value: any,
    idRoute: number,
    idxGroup: number,
    idRule: number
  ) => void;
  validateClassName: (
    field: string,
    idx?: number,
    idGroup?: number,
    idRule?: number
  ) => string;
  validateTooltip: (
    field: string,
    idx?: number,
    idGroup?: number,
    idRule?: number
  ) => string;
  trafficSources: TrafficSource[];
  className?: string;
}

interface State {
  validationErrors: AnyObject;
}

class ConditionRule extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    idRoute: 0
  };

  getFieldsByType = (
    rule: FunnelConditionRule,
    idRoute: number,
    idGroup: number,
    idRule: number
  ) => {
    if (defined(rule.attribute) && defined(rulesAttributes[rule.attribute])) {
      const param = getRuleParam(rule);
      const paramKey = getRuleParamKey(rule);

      const name = `input-${param}-${idGroup}-${idRule}`;

      //@ts-ignore
      const ruleParam = rule[param];
      let value = '';
      let field = '';

      switch (rulesAttributes[rule.attribute]?.type) {
        case 'text':
          value =
            defined(ruleParam) && defined(ruleParam[paramKey])
              ? ruleParam[paramKey]
              : defined(ruleParam)
              ? ruleParam
              : '';

          return (
            <Input
              className={`${param} ${
                !value ||
                (defined(ruleParam?.[paramKey!]) &&
                  defined(value[0]) &&
                  !value[0])
                  ? this.props.validateClassName(
                      'rules',
                      idRoute,
                      idGroup,
                      idRule
                    )
                  : ''
              }`}
              error={
                !value ||
                (defined(ruleParam?.[paramKey!]) &&
                  defined(value[0]) &&
                  !value[0])
                  ? this.props.validateTooltip(
                      'rules',
                      idRoute,
                      idGroup,
                      idRule
                    )
                  : ''
              }
              showErrorTooltip={this.props.showErrorTooltip}
              id={name}
              name={name}
              data-testid="value"
              placeholder="Enter value here"
              value={value}
              onChange={e =>
                this.props.onSetRuleOption(
                  param,
                  e.target.value,
                  idRoute,
                  idGroup,
                  idRule
                )
              }
            />
          );
        case 'select':
        case 'resource':
          value = ruleParam?.[paramKey!] || ruleParam || '';
          let options = rulesAttributes[rule.attribute]?.options || [];
          if (rulesAttributes[rule.attribute]?.resourceName) {
            options = this.props[
              rulesAttributes?.[rule.attribute]?.resourceName!
            ];
          }

          return (
            <Select
              className={
                !value
                  ? this.props.validateClassName(
                      'rules',
                      idRoute,
                      idGroup,
                      idRule
                    )
                  : ''
              }
              error={
                !value
                  ? this.props.validateTooltip(
                      'rules',
                      idRoute,
                      idGroup,
                      idRule
                    )
                  : ''
              }
              mode={
                ['not in', 'any in'].includes(rule.test)
                  ? 'multiple'
                  : 'default'
              }
              name={name}
              data-testid="value"
              dropdownMatchSelectWidth={false}
              placeholder={
                rule.attribute === 'Location: Country'
                  ? 'Select country'
                  : 'Select value(s)'
              }
              options={options}
              value={value}
              valueGetter={
                defined(rulesAttributes[rule.attribute]?.valueGetter)
                  ? rulesAttributes[rule.attribute]?.valueGetter
                  : (opt: any) => opt
              }
              labelGetter={
                defined(rulesAttributes[rule.attribute]?.labelGetter)
                  ? rulesAttributes[rule.attribute]?.labelGetter
                  : (opt: any) => opt
              }
              onChange={(value: string) =>
                this.props.onSetRuleOption(
                  param,
                  value,
                  idRoute,
                  idGroup,
                  idRule
                )
              }
              suspendSort={
                defined(rulesAttributes[rule.attribute]?.suspendSort)
                  ? rulesAttributes[rule.attribute]?.suspendSort
                  : false
              }
              showSearch={true}
              filterOption={true}
            />
          );
        case 'date':
          return (
            <DatePicker
              className={
                !value
                  ? this.props.validateClassName(
                      'rules',
                      idRoute,
                      idGroup,
                      idRule
                    )
                  : ''
              }
              onChange={(date: any) => {
                const value = defined(date)
                  ? {
                      day: date.date(),
                      month: date.month() + 1,
                      year: date.year()
                    }
                  : {
                      day: null,
                      month: null,
                      year: null
                    };
                this.props.onSetRuleOption(
                  param,
                  value,
                  idRoute,
                  idGroup,
                  idRule
                );
              }}
              //@ts-ignore
              value={
                defined(ruleParam) &&
                defined(ruleParam.day) &&
                defined(ruleParam.month) &&
                defined(ruleParam.year)
                  ? moment.utc(
                      `${ruleParam.day}.${ruleParam.month}.${ruleParam.year}`,
                      'DD.MM.YYYY'
                    )
                  : undefined
              }
              showTime={false}
              format={'DD.MM.YYYY'}
            />
          );
        case 'time':
          return (
            <TimePicker
              className={
                !value
                  ? this.props.validateClassName(
                      'rules',
                      idRoute,
                      idGroup,
                      idRule
                    )
                  : ''
              }
              onChange={(time, timeString) => {
                const value = defined(time)
                  ? { hour: time.hours(), minutes: time.minutes() }
                  : { hour: null, minutes: null };

                this.props.onSetRuleOption(
                  param,
                  value,
                  idRoute,
                  idGroup,
                  idRule
                );
              }}
              //@ts-ignore
              value={
                defined(ruleParam) &&
                defined(ruleParam.hour) &&
                defined(ruleParam.minutes)
                  ? moment.utc(`${ruleParam.hour}:${ruleParam.minutes}`, 'HH:mm')
                  : undefined
              }
              format="HH:mm"
              //@ts-ignore
              defaultOpenValue={moment('00:00', 'HH:mm')}
            />
          );
        case 'fieldAndValue':
          field = ruleParam && ruleParam.fieldName ? ruleParam.fieldName : '';
          value =
            ruleParam && ruleParam.fieldValues
              ? ruleParam.fieldValues.join('')
              : '';
          return (
            <>
              <Input
                className={
                  !field
                    ? this.props.validateClassName(
                        'rules',
                        idRoute,
                        idGroup,
                        idRule
                      )
                    : ''
                }
                error={
                  !field
                    ? this.props.validateTooltip(
                        'rules',
                        idRoute,
                        idGroup,
                        idRule
                      )
                    : ''
                }
                showErrorTooltip={this.props.showErrorTooltip}
                id={name}
                name={name}
                placeholder="Enter Field name"
                value={field}
                onChange={e =>
                  this.props.onSetRuleOption(
                    param,
                    {
                      fieldName: e.target.value,
                      fieldValues:
                        ruleParam && ruleParam.fieldValues
                          ? ruleParam.fieldValues
                          : ['']
                    },
                    idRoute,
                    idGroup,
                    idRule
                  )
                }
                style={{ paddingRight: '10px' }}
              />
              <Input
                className={
                  !value
                    ? this.props.validateClassName(
                        'rules',
                        idRoute,
                        idGroup,
                        idRule
                      )
                    : ''
                }
                error={
                  !value
                    ? this.props.validateTooltip(
                        'rules',
                        idRoute,
                        idGroup,
                        idRule
                      )
                    : ''
                }
                showErrorTooltip={this.props.showErrorTooltip}
                id={name}
                name={name}
                placeholder="Enter Value name"
                value={value}
                onChange={e =>
                  this.props.onSetRuleOption(
                    param,
                    {
                      fieldName:
                        ruleParam && ruleParam.fieldName
                          ? ruleParam.fieldName
                          : '',
                      fieldValues: [e.target.value]
                    },
                    idRoute,
                    idGroup,
                    idRule
                  )
                }
              />
            </>
          );
      }
    }
  };

  render() {
    return (
      <div className={`c-conditionRule ${this.props.className || ''}`}>
        <div className="c-conditionRule__slider--body">
          <div className="c-conditionRule__slider--bodyHead">
            <div className="flex flex-align-center">
              {defined(this.props.route.groups) &&
                this.props.route.groups.length > 1 && (
                  <>
                    <div className="c-conditionRule__slider--leftLine" />
                    <AndOrOperator
                      className="c-conditionRule__slider--operator"
                      isOr={this.props.route.operator === 'or'}
                      onClick={value => {
                        if (
                          this.props.route.groups &&
                          this.props.route.groups.length > 1
                        )
                          this.props.setRouteParam(
                            'operator',
                            value,
                            this.props.idRoute!
                          );
                      }}
                    />
                    <IconTooltip
                      key="local-nodes"
                      body="OR & AND operators are available between groups/rules when at least two conditional items have been added"
                    />
                  </>
                )}
            </div>

            <IconedButton
              text="Add Group"
              type="flux-smallPlusFunnelBuilder"
              onClick={() => this.props.addGroup(this.props.idRoute!)}
              data-testid={`route-${this.props.idRoute}-addGroup`}
            />
          </div>
          <div className="c-conditionRule__slider--bodyGroups">
            {defined(this.props.route.groups) &&
              this.props.route.groups.map(
                (group: FunnelConditionGroup, idGroup) => {
                  return (
                    <div
                      className={conditionalClass(
                        'c-conditionRule__slider--bodyGroup',
                        {
                          'c-conditionRule__slider--bodyGroupWithLine':
                            this.props.route.groups.length > 1
                        }
                      )}
                      key={`group-${idGroup}`}
                      data-testid={`group-${idGroup}`}
                    >
                      <div className="c-conditionRule__slider--bodyGroupLine" />
                      <div className="flex flex-justify-space-between flex-align-center">
                        <div className="flex flex-align-center">
                          {group.rules.length > 1 && (
                            <>
                              <AndOrOperator
                                className="c-conditionRule__slider--bodyGroupOperator"
                                isOr={group.operator === 'or'}
                                onClick={value => {
                                  if (group && group.rules.length > 1)
                                    this.props.setRouteGroupParam(
                                      'operator',
                                      value,
                                      this.props.idRoute!,
                                      idGroup
                                    );
                                }}
                              />

                              {defined(group.rules) &&
                                group.rules.length > 0 && (
                                  <div className="left-line" />
                                )}

                              <IconTooltip
                                key="local-nodes"
                                body="OR & AND operators are available between groups/rules when at least two conditional items have been added"
                              />
                            </>
                          )}
                        </div>
                        <div className="flex flex-gap-10 flex-align-center">
                          <IconedButton
                            text="Add Rule"
                            data-testid={`route-${this.props.idRoute}-group-${idGroup}-addRule`}
                            type="flux-shape"
                            className="c-conditionRule__slider--shapeIcon"
                            onClick={() =>
                              this.props.addRule(this.props.idRoute!, idGroup)
                            }
                          />
                          <Icon
                            type="flux-delete"
                            data-testid="deleteGroup"
                            className="c-conditionRule__slider--deleteIcon"
                            onClick={() =>
                              this.props.deleteGroup(
                                this.props.idRoute!,
                                idGroup
                              )
                            }
                          />
                        </div>
                      </div>
                      {(group.rules || []).map(
                        (rule: FunnelConditionRule, idRule) => {
                          return (
                            <div
                              className="c-conditionRule__slider--bodyGroupRule"
                              key={idRule}
                              data-testid={`field-${idRule}`}
                            >
                              <div
                                className={conditionalClass(
                                  'c-conditionRule__slider--bodyGroupRuleFields',
                                  {
                                    'c-conditionRule__slider--bodyGroupRuleFieldsWithLine':
                                      group.rules.length > 1
                                  }
                                )}
                              >
                                <div className="c-conditionRule__slider--bodyGroupRuleFieldsLeftLine" />
                                <Select
                                  className={
                                    !rule.attribute
                                      ? this.props.validateClassName(
                                          'rules',
                                          this.props.idRoute,
                                          idGroup,
                                          idRule
                                        )
                                      : ''
                                  }
                                  error={
                                    !rule.attribute
                                      ? this.props.validateTooltip(
                                          'rules',
                                          this.props.idRoute,
                                          idGroup,
                                          idRule
                                        )
                                      : ''
                                  }
                                  data-testid="selectField"
                                  value={rule.attribute}
                                  dropdownMatchSelectWidth={false}
                                  placeholder="Select Attribute"
                                  options={Object.entries(rulesAttributes).map(
                                    (item: AnyObject) => {
                                      return {
                                        value: item[0],
                                        label: getConditionCorrectAttributeName(
                                          item[0]
                                        ),
                                        category: getConditionCorrectAttributeCategory(
                                          item[0]
                                        )
                                      };
                                    }
                                  )}
                                  groupOptions={true}
                                  groupBy={'category'}
                                  valueGetter={(option: AnyObject) =>
                                    option.value
                                  }
                                  labelGetter={(option: AnyObject) =>
                                    option.label
                                  }
                                  onChange={(value: string) =>
                                    this.props.onSetRuleOption(
                                      'attribute',
                                      value,
                                      this.props.idRoute!,
                                      idGroup,
                                      idRule
                                    )
                                  }
                                  showSearch={true}
                                  filterOption={true}
                                />

                                {defined(
                                  rulesAttributes?.[rule?.attribute]
                                ) && (
                                  <Select
                                    className={`test-select ${
                                      !rule.test
                                        ? this.props.validateClassName(
                                            'rules',
                                            this.props.idRoute,
                                            idGroup,
                                            idRule
                                          )
                                        : ''
                                    }`}
                                    error={
                                      !rule.test
                                        ? this.props.validateTooltip(
                                            'rules',
                                            this.props.idRoute,
                                            idGroup,
                                            idRule
                                          )
                                        : ''
                                    }
                                    value={rule.test}
                                    dropdownMatchSelectWidth={false}
                                    data-testid="operator"
                                    placeholder="Operator"
                                    options={(
                                      rulesAttributes[rule.attribute]?.test ||
                                      []
                                    ).map((item: string) => {
                                      return {
                                        value: item,
                                        label:
                                          item === '>'
                                            ? 'GRATHER THAN'
                                            : item === '<'
                                            ? 'LESS THAN'
                                            : item
                                      };
                                    })}
                                    groupOptions={true}
                                    groupBy={'category'}
                                    valueGetter={(option: AnyObject) =>
                                      option.value
                                    }
                                    labelGetter={(option: AnyObject) =>
                                      option.label.toUpperCase()
                                    }
                                    onChange={(value: string) =>
                                      this.props.onSetRuleOption(
                                        'test',
                                        value,
                                        this.props.idRoute!,
                                        idGroup,
                                        idRule
                                      )
                                    }
                                  />
                                )}

                                {this.getFieldsByType(
                                  rule,
                                  this.props.idRoute!,
                                  idGroup,
                                  idRule
                                )}
                              </div>

                              <div>
                                <Icon
                                  type="flux-delete"
                                  data-testid="deleteRule"
                                  className="c-conditionRule__slider--deleteIcon"
                                  onClick={() =>
                                    this.props.onDeleteRule(
                                      this.props.idRoute!,
                                      idGroup,
                                      idRule
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionRule;

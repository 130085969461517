import { summary as defaultState, summaryData } from './defaultStates';
import { SUMMARY, API } from '../types';
import { SummaryDataStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import { ReportingParamsAction, SummaryReportingAction } from '../../types/redux/action';
import { getFieldName } from '../../utils/selectors';
import { DrilldownReport } from '../../model/drilldownReport';
import {
  FUNNEL_CATEGORY_TYPE,
  LANDER_CATEGORY_TYPE,
  OFFER_CATEGORY_TYPE,
  OVERVIEW_CATEGORY_TYPE,
  PAGE_GROUPS_CATEGORY_TYPE,
  TRAFFIC_SOURCE_CATEGORY_TYPE
} from '../../constants/modal';
import { SummaryTypes } from '../../types/summary';

const reporting = (
  state: DrilldownReport = summaryData.reporting,
  action: SummaryReportingAction,
  type: SummaryTypes
) => {
  switch (action.type) {
    case SUMMARY.SET_REPORTING:
      if (defined(action.payload) && type === action.payload.type) {
        return {
          ...(action.payload.data as DrilldownReport)
        };
      }
      return state;
    default:
      return state;
  }
};

const filters = (
  state = defaultState.filters,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case SUMMARY.SET_REPORTING:
      return action.payload.params;
    default:
      return state;
  }
};

const graph = (
  state = defaultState.graph.reporting,
  action: SummaryReportingAction
) => {
  switch (action.type) {
    case SUMMARY.SET_GRAPH:
      if (defined(action.payload)) {
        return {
          ...(action.payload.data as DrilldownReport)
        };
      }
      return state;
    default:
      return state;
  }
};

const loading = (
  state = summaryData.loadings,
  action: AnyAction,
  type: SummaryTypes
) => {
  if (
    action.payload === `${SUMMARY.FETCH_REPORTING}-${type}` ||
    action.payload === SUMMARY.FETCH_GRAPH
  ) {
    const fieldName = getFieldName(
      action,
      `${SUMMARY.FETCH_REPORTING}-${type}`
    );
    switch (action.type) {
      case API.STARTED:
        return {
          ...state,
          [fieldName]: {
            ...state[fieldName],
            isLoading: true
          }
        };
      case API.ENDED:
        return {
          ...state,
          [fieldName]: {
            ...state[fieldName],
            isLoading: false,
            fetchedAt: Date.now()
          }
        };
      default:
        return state;
    }
  }

  return state;
};

const tableCols = (state = defaultState.tableCols, action: AnyAction) => {
  if (defined(action.payload) && action.type === SUMMARY.SET_TABLE_COLS) {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state;
  }
};

const settings = (state = defaultState.settings, action: AnyAction) => {
  if (defined(action.payload) && action.type === SUMMARY.SET_SETTINGS) {
    return {
      ...state,
      [action.payload.key]: action.payload.data
    };
  } else {
    return state;
  }
};

export default (state = defaultState, action: any) => {
  const data = ([
    FUNNEL_CATEGORY_TYPE,
    TRAFFIC_SOURCE_CATEGORY_TYPE,
    LANDER_CATEGORY_TYPE,
    OFFER_CATEGORY_TYPE,
    PAGE_GROUPS_CATEGORY_TYPE,
    OVERVIEW_CATEGORY_TYPE
  ] as SummaryTypes[]).reduce((acc, type) => {
    const stateOfType = (!!state[type]
      ? state[type]
      : summaryData) as SummaryDataStore;

    return {
      ...acc,
      [type]: {
        reporting: reporting(stateOfType.reporting, action, type),
        loadings: loading(stateOfType.loadings, action, type)
      }
    };
  }, {});

  const graphData = !!state.graph ? state.graph : defaultState.graph;

  return {
    ...data,
    tableCols: tableCols(state.tableCols, action),
    graph: {
      reporting: graph(graphData.reporting, action),
      loadings: loading(graphData.loadings, action, 'graph')
    },
    settings: settings(state.settings, action),
    filters: filters(state.filters, action),
  };
};

import { billing as defaultState } from './defaultStates';
import { BillingStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { USER } from '../types';

const quota = (state = defaultState.quota, action: AnyAction) => {
  switch (action.type) {
    case USER.QUOTA:
      return action.payload;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): BillingStore => ({
  quota: quota(state.quota, action)
});

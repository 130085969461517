import React, { PropsWithChildren } from 'react';
import className from 'utils/style/className';
import './style.scss';
import { FFNewIcon, VisibilityWrapper } from 'uikit';
import ReactPortal from 'components/Portal';

const { blockClassName, getClass } = className('c-ffSidePanel');

interface Tab {
  title: string;
  tabId: string;
  icon?: React.ReactElement;
  highlightable?: boolean;
  hidden?: boolean;
}

interface State {
  currentTabId: string;
}

interface Props {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  tabs?: Tab[];
  sidebarName: string;
  zIndex?: number;
  isOpen: boolean;
  onClose: () => void;
  onTabChange?: (tab: Tab) => void;
  defaultTabId?: string;
  minWidth?: number;
  maxWidth?: number;
}

interface Context {
  currentTabId: string;
}

export const SidePanelContext = React.createContext<Context | null>(null);

class SidePanel extends React.Component<PropsWithChildren<Props>, State> {
  static defaultProps: Pick<Props, 'tabs' | 'zIndex'> = {
    tabs: [],
    zIndex: 100,
  };
  state: State = {
    currentTabId: this.props.defaultTabId || ''
  };

  handleState = async <T extends State, P extends keyof T>(key: P, value: T[P]) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  onTabClick = (tab: Tab) => {
    this.handleState('currentTabId', tab.tabId);
    if (this.props.onTabChange) {
      this.props.onTabChange(tab);
    }
  };

  render() {
    const isOpen = this.props.isOpen;
    const hasTabs = this.props.tabs!.length > 0;

    return (
      <ReactPortal zIndex={this.props.zIndex!} dataPortalKey={this.props.sidebarName}>
        <div className={getClass('backdrop', [isOpen && 'open'])} onClick={this.props.onClose} />
        <div
          style={{ zIndex: this.props.zIndex }}
          className={`${blockClassName} ${blockClassName}--${isOpen ? 'open' : 'close'}`}
        >
          <div className={getClass('header')}>
            <h3 className={getClass('title')}>{this.props.title}</h3>
            <div className={getClass('actions')}>{this.props.actions}</div>
          </div>
          <div className={getClass('body')}>
            <div className={getClass('children')}>{this.props.children}</div>
            <VisibilityWrapper visible={hasTabs}>
              <div className={getClass('tabs')}>
                {this.props.tabs!.map((tab, index) => (
                  <div
                    key={index}
                    className={getClass('tab', [
                      !!tab.hidden && 'hidden',
                      !!tab.highlightable && 'highlightable',
                      tab.tabId === this.state.currentTabId ? 'active' : 'inactive'
                    ])}
                    onClick={() => this.onTabClick(tab)}
                  >
                    <span className={getClass('tabTitle')}>{tab.title}</span>
                    {tab.icon}
                  </div>
                ))}
              </div>
            </VisibilityWrapper>
          </div>
        </div>
      </ReactPortal>
    );
  }
}

export default SidePanel;

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="multiple-file-2--double-common-file">
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M17.916428571428572 4.976785714285715H5.972142857142857c-1.0994316857142856 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v17.916428571428572c0 1.0994715 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h11.944285714285714c1.0994316857142856 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-17.916428571428572c0 -1.0994316857142856 -0.8912826 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.916428571428572 4.976785714285715H5.972142857142857c-1.0994316857142856 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v17.916428571428572c0 1.0994715 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h11.944285714285714c1.0994316857142856 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-17.916428571428572c0 -1.0994316857142856 -0.8912826 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 9.95357142857143h7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 14.930357142857142h7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 19.90714285714286h3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.958214285714286 0.9953571428571428H21.89785714285714c0.5279374285714286 0 1.0343751428571428 0.209735685 1.4076340714285713 0.5830662792857143C23.678750142857144 1.9517560071428572 23.888571428571428 2.4580941857142857 23.888571428571428 2.9860714285714285V21.89785714285714"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

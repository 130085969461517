import { defined, valueOrDefault } from '../define';

type InputType = string | number | null | undefined;

export type Decimal = string;

export function decimalValueOrZero(v: InputType): Decimal {
  return decimalValueOrDefault(v, '0');
}

export function decimalValueOrDefault(
  v: InputType,
  defaultValue: Decimal
): Decimal {
  return valueOrDefault(optionalDecimalValueOrDefault(v), defaultValue);
}

export function optionalDecimalValueOrDefault(
  v: InputType,
  defaultValue?: Decimal
): Decimal | undefined {
  if (v === '') {
    return '0';
  }
  if (defined(v)) {
    let s = String(v);
    if (!isNaN(parseFloat(s)) && !isNaN(Number(s))) {
      // parseFloat and Number together gives better number validation
      return s;
    }
  }
  return defaultValue;
}

export const convertToTwoDecimal = (value: string): string =>
  // we did not used toFixed because it has issue with when
  // multiple numbers you type, f.e when I type 15.666
  // it converts it to  15.67 not 15.66
  !value || value.lastIndexOf('.') === 0
    ? ''
    : value.match(/\d+(\.\d{0,2})?/)![0];

export const isBetweenNumbers = (
  lower: number,
  middle: number,
  higher: number
) => lower <= middle && middle <= higher;

import { Dispatch } from 'redux';
import { apiAction } from './api';
import { USER as API_USER } from '../../api/endpoints';
import { USER } from '../types';
import { User } from '../../model/user';
import { UserQuota } from '../../model/userQuota';

const setUser = (payload: User) => {
  return {
    type: USER.FETCH,
    payload
  };
};

export const fetchUser = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API_USER.FETCH(),
      onSuccess: (data: User) => {
        return setUser(data);
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: USER.UPDATE
    })
  );
};

const setQuota = (payload: UserQuota) => {
  return {
    type: USER.QUOTA,
    payload
  };
};

export const fetchQuota = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API_USER.QUOTA(),
      onSuccess: (data: UserQuota) => setQuota(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: USER.UPDATE
    })
  );
};

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import NodeDeleteForm from 'components/Forms/NodeDelete';
import { deleteLander } from 'redux/actions/landers';
import { LanderDeleteModalSelector } from 'redux/selectors';
import { ModalsConsumer } from '../context';
import { LANDER_DELETE_MODAL } from 'constants/modal';
import { getEntityId } from '../../../utils/model';
import { ModalProps } from '../types';
import { landerDeleteModalProps } from '../../../types/ModalForms/landerDelete';
import { FormContextModal } from '../../../types/modal';
import { getNameModal } from '../../../utils/modals'
import { AnyObject } from 'types';

const LanderDeleteModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  landers,
  contextModal
}: landerDeleteModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    destroyOnClose
    width={422}
    title={`Delete Lander ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['lander-delete']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <NodeDeleteForm
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        prepareData={(context: FormContextModal) => ({
          entries: [getEntityId(context.data)]
        })}
        data={landers}
        contextModal={contextModal}
        type="lander"
      />
    )}
  />
);

const ConnectedLanderDeleteModal = ({ deleteLander, landers }: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <LanderDeleteModal
            isOpen={isOpenModal(LANDER_DELETE_MODAL)}
            onSubmit={deleteLander}
            contextModal={getContextModal(LANDER_DELETE_MODAL) as FormContextModal}
            landers={landers}
            closeSelf={() => closeModal(LANDER_DELETE_MODAL)}
          />
        </>
      )}
    </ModalsConsumer>
  )
}

export default connect(
  LanderDeleteModalSelector,
  { deleteLander }
)(ConnectedLanderDeleteModal);

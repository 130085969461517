import { apiAction } from './api';
import { FUNNELS, NAV_ACTIONS } from '../types';
import { FUNNELS as API } from 'api/axios';
import { shouldFetch } from 'redux/selectors';
import { DeleteList } from '../../model/deleteList';
import { Dispatch } from 'redux';
import { Funnel } from '../../model/funnel';
import { StatusEnum } from 'api/types';
import { CompositeStore } from '../../types/redux/store';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { makeNewRow, updatedRows } from 'utils/table';
import { funnelsEditorDeleted } from './navigation';
import { clearFunnel } from '../../utils/model';
import { ArchiveList } from '../../model/archiveList';
import { MoveList } from '../../model/moveList';
import { AnyObject } from '../../types';

const funnelArchive = (data: string = '') => {
  return {
    type: FUNNELS.ARCHIVE_SINGLE,
    payload: { data }
  };
};

const funnelListArchive = (data: string[]) => {
  return {
    type: FUNNELS.ARCHIVE_LIST,
    payload: { data }
  };
};

const funnelListUnarchive = (data: string[]) => {
  return {
    type: FUNNELS.UNARCHIVE_LIST,
    payload: { data }
  };
};

const funnelListMove = (data: AnyObject) => {
  return {
    type: FUNNELS.MOVE_LIST,
    payload: { data }
  };
};

const funnelDelete = (data: string[]) => {
  return {
    type: FUNNELS.DELETE,
    payload: { data }
  };
};

const setFunnelList = (payload: Funnel[] = []) => {
  return {
    type: FUNNELS.SET_LIST,
    payload
  };
};

const setAllFunnelList = (payload: Funnel[] = []) => {
  return {
    type: FUNNELS.SET_ALL_LIST,
    payload
  };
};

const funnelUpdateRow = (data: DrilldownReportRow[]) => {
  return {
    type: FUNNELS.UPDATE_SINGLE_ROW,
    payload: { data }
  };
};

const funnelUpdate = (data: Funnel) => {
  return {
    type: FUNNELS.UPDATE,
    payload: data
  };
};

const funnelEditorNavNameUpdate = (data: Funnel) => {
  return {
    type: NAV_ACTIONS.BUILDER_FUNNELS_UPDATE_NAME,
    data
  };
};

const setFunnel = (payload: Funnel) => {
  return {
    type: FUNNELS.ADD_SINGLE,
    payload
  };
};

const setExistedFunnel = (payload: Funnel) => {
  return {
    type: FUNNELS.ADD_EXISTED_SINGLE,
    payload
  };
};

const setFunnelRow = (data: DrilldownReportRow) => {
  return {
    type: FUNNELS.ADD_SINGLE_ROW,
    payload: { data }
  };
};

const setCloseAllFunnelTabsModalIsOpen = (isOpen: boolean) => {
  return {
    type: FUNNELS.SET_IS_CLOSE_ALL_TABS_MODAL_OPEN,
    payload: isOpen
  };
};

export const getFunnelsByFunnelGroup = (idCampaign: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FIND_BY_FUNNELGROUP(idCampaign),
      onSuccess: (pages: Funnel[]) => setFunnelList(pages),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.FETCH_LIST
    })
  );
};

export const fetchFunnels = (status: StatusEnum | 'tabs' = 'active') => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  if (shouldFetch(getState().funnels)) {
    return dispatch(
      apiAction({
        requestConfig: API.FETCH_LIST(
          status === 'tabs' ? 'not-deleted' : status
        ),
        onSuccess: (data: Funnel[]) => {
          if (status === 'tabs') {
            return setAllFunnelList(data);
          } else {
            return setFunnelList(data);
          }
        },
        onFailure: (e: Error) => {
          throw e;
        },
        label: FUNNELS.FETCH_LIST
      })
    );
  }
};

export const getFunnelById = (idFunnel: string) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.GET(idFunnel),
      onSuccess: (funnel: Funnel) => setExistedFunnel(funnel),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.FETCH_LIST
    })
  );
};

export const addFunnel = (data: Funnel) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const funnelGroupName = getState().funnelGroups.data?.[data.idCampaign]
    ?.campaignName;
  return dispatch(
    apiAction({
      requestConfig: API.CREATE(clearFunnel(data)),
      onSuccess: async () => {
        await dispatch(setFunnel(data));
        dispatch(
          setFunnelRow(
            makeNewRow(
              {
                idCampaign: data.idCampaign,
                campaignName: funnelGroupName,
                idFunnel: data.idFunnel,
                funnelName: data.funnelName,
                status: 'active',
                funnelType: data.funnelType
              },
              'funnel'
            )
          )
        );
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.ADD_SINGLE_ROW
    })
  );
};

export const updateFunnel = (data: Funnel) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const reportingRows = getState().funnelGroups.reporting.rows;
  const funnelGroupData = reportingRows.find(
    item => item.attributes[0].id === data.idCampaign
  );
  return dispatch(
    apiAction({
      requestConfig: API.UPDATE(clearFunnel(data)),
      onSuccess: () => {
        dispatch(funnelEditorNavNameUpdate(data));
        dispatch(funnelUpdate(data));
        updatedRows({
          id: data.idFunnel,
          newName: data.funnelName,
          rows: reportingRows,
          idFunnelGroup: data.idCampaign,
          funnelGroupName: funnelGroupData?.attributes?.[0]?.value || ''
        }).then((res: any) => {
          dispatch(funnelUpdateRow(res));
          dispatch(setExistedFunnel(data));
        });
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.UPDATE_SINGLE_ROW
    })
  );
};

export const duplicateFunnel = (oldData: Funnel) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const funnelGroupName = getState().funnelGroups.data[oldData.idCampaign]
    .campaignName;
  return dispatch(
    apiAction({
      requestConfig: API.DUPLICATE(clearFunnel(oldData)),
      onSuccess: (data: Funnel) => {
        dispatch(setFunnel(data));
        return setFunnelRow(
          makeNewRow(
            {
              idCampaign: data.idCampaign,
              campaignName: funnelGroupName,
              idFunnel: data.idFunnel,
              funnelName: data.funnelName,
              status: 'active'
            },
            'funnel'
          )
        );
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.ADD_SINGLE_ROW
    })
  );
};

export const closeAllFunnelBuilderTabs = () => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const funnelEditorTabs = getState().navigation.funnelEditorSub.map(
    item => item.idFunnel
  );
  return dispatch(funnelsEditorDeleted(funnelEditorTabs));
};

export const deleteFunnel = (data: DeleteList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => {
        dispatch(funnelsEditorDeleted(data.entries));
        return funnelDelete(data.entries);
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.DELETE
    })
  );
};

export const archiveFunnel = (data: Funnel) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.ARCHIVE_SINGLE(clearFunnel(data)),
      onSuccess: () => funnelArchive(data.idFunnel),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.ARCHIVE_SINGLE
    })
  );
};

export const archiveFunnels = (data: ArchiveList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.ARCHIVE_LIST(data),
      onSuccess: () => funnelListArchive(data.entries),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.ARCHIVE_LIST
    })
  );
};

export const unArchiveFunnels = (data: ArchiveList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.UNARCHIVE_LIST(data),
      onSuccess: () => funnelListUnarchive(data.entries),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.UNARCHIVE_LIST
    })
  );
};

export const setIsCloseAllFunnelTabsModalOpen = (isOpen: boolean) => (
  dispatch: Dispatch
) => {
  return dispatch(setCloseAllFunnelTabsModalIsOpen(isOpen));
};

export const moveFunnels = (
  idCampaign: string,
  data: MoveList,
  campaignName: string
) => (dispatch: Dispatch, getState: () => CompositeStore) => {
  return dispatch(
    apiAction({
      requestConfig: API.MOVE(idCampaign, data),
      onSuccess: async () => {
        const reportingRows = getState().funnelGroups.reporting.rows;
        const funnelGroupData = reportingRows.find(
          item => item.attributes[0].id === idCampaign
        );

        for (let idFunnel of data.entries) {
          const funnelData = reportingRows.find(
            item => item.attributes[1].id === idFunnel
          );

          await updatedRows({
            id: idFunnel,
            newName: funnelData?.attributes?.[1]?.value || '',
            rows: reportingRows,
            idFunnelGroup: idCampaign,
            funnelGroupName: funnelGroupData?.attributes?.[0]?.value || ''
          }).then((res: any) => {
            dispatch(funnelUpdateRow(res));
            dispatch(
              setExistedFunnel(
                (getState().funnels.data[idFunnel] || {}) as Funnel
              )
            );
          });
        }

        dispatch(funnelListMove({ idCampaign, campaignName, ...data }));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELS.MOVE_LIST
    })
  );
};

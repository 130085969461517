import React, { Component, PropsWithChildren } from 'react';
import Icon from 'components/Icons';
import LanderArchiveModal from './LanderArchive';
import OfferSourceDelete from './OfferSourceDelete';
import TrafficSourceDeleteModal from './TrafficSourceDelete';
import FunnelGroupDeleteModal from './FunnelGroupDelete';
import LanderDeleteModal from './LanderDelete';
import OfferDeleteModal from './OfferDelete';
import FunnelDeleteModal from './FunnelDelete';
import FunnelArchiveModal from './FunnelArchive';
import CategoryArchiveModal from './CategoryArchive';
import CategoryDeleteModal from './CategoryDelete';
import OfferArchiveModal from './OfferArchive';
import OfferSourcesArchiveModal from './OfferSourceArchive';
import TrafficSourceArchiveModal from './TrafficSourceArchive';
import ConditionDelete from './ConditionDelete';
import CreateNewAsset from './CreateNewAsset';
import AddDomain from './AddDomain';
import DeleteDomain from './DomainDelete';
import EditDns from './EditDns';
import DeleteDns from './DeleteDns';
import PageGroupsDelete from './PageGroupsDelete';
import PageGroupsArchive from './PageGroupsArchive';
import AddEditApiKey from './AddEditApiKey';
import DeleteApiKey from './DeleteApiKey';
import {
  ModalsProvider as Provider,
  ModalsConsumer as Consumer
} from './context';
import { ConfirmModal } from 'components/Modals/Confirm';
import { DEFAULT_MODAL_Z_INDEX } from 'constants/modal';
import ReportingAddEditDeleteView from './ReportingAddEditDeleteView';
import { withRouter } from 'react-router';
import { AnyObject } from 'types';
import { FormContextModal } from 'types/modal';
import { History } from '../../types';
import { ModalProps } from 'components/Modals/types';
import { hotkeysGlobalScope } from 'utils/app';
import { definedObject } from 'utils/define';

interface State extends ModalProps {
  contexts: AnyObject;
  zIndex: number;
}

interface Props {
  history: History;
}

class ModalsProvider extends Component<PropsWithChildren<Props>, State> {
  state: State = {
    openModal: this.openModal.bind(this),
    closeModal: this.closeModal.bind(this),
    isOpenModal: this.isOpenModal.bind(this),
    increaseZIndex: this.increaseZIndex.bind(this),
    setContextModal: this.setContextModal.bind(this),
    getContextModal: this.getContextModal.bind(this),
    contexts: {},
    zIndex: DEFAULT_MODAL_Z_INDEX
  };

  async openModal(modalName: string) {
    await this.setState((state: State) => ({
      ...state,
      contexts: {
        ...state.contexts,
        [modalName]: {
          ...state.contexts[modalName],
          isOpen: true,
          history: this.props.history,
          zIndex: state.zIndex + 1
        }
      },
      zIndex: state.zIndex + 1
    }));
    hotkeysGlobalScope.setScope.setModalScope();
    return () => this.closeModal(modalName);
  }

  isOpenModal(modalName: string) {
    return (
      !!this.state.contexts[modalName] &&
      !!this.state.contexts[modalName].isOpen
    );
  }

  async closeModal(modalName: string) {
    await this.setState(state => ({
      ...state,
      contexts: {
        ...state.contexts,
        [modalName]: {}
      },
      zIndex: state.zIndex - 1
    }));
    if (!Object.values(this.state.contexts).filter(v => definedObject(v)).length) {
      hotkeysGlobalScope.setScope.setPageScope();
    }
  }

  increaseZIndex() {
    this.setState(state => ({
      ...state,
      zIndex: state.zIndex + 1
    }));
  }

  setContextModal<T = FormContextModal>(modalName: string, value: T) {
    this.setState(state => ({
      ...state,
      contexts: {
        ...state.contexts,
        [modalName]: {
          ...state.contexts[modalName],
          ...value
        }
      }
    }));
  }

  getContextModal(modalName: string) {
    return !!this.state.contexts[modalName]
      ? this.state.contexts[modalName]
      : {};
  }

  render() {
    return (
      <>
        <Provider value={this.state}>
          <LanderArchiveModal />
          <LanderDeleteModal />

          <OfferSourcesArchiveModal />
          <OfferSourceDelete />

          <TrafficSourceArchiveModal />
          <TrafficSourceDeleteModal />

          <OfferArchiveModal />
          <OfferDeleteModal />

          <PageGroupsDelete />
          <PageGroupsArchive />

          <FunnelGroupDeleteModal />

          <FunnelDeleteModal />
          <FunnelArchiveModal />

          <ConfirmModal />

          <CategoryArchiveModal />
          <CategoryDeleteModal />
          
          <AddDomain />

          <ReportingAddEditDeleteView />
          <DeleteDomain />
          <EditDns />
          <DeleteDns />

          <ConditionDelete />

          <AddEditApiKey />
          <DeleteApiKey />

          <CreateNewAsset />

          {this.props.children}
        </Provider>
      </>
    );
  }
}

export const ModalTrigger = ({
  modalToOpen,
  label = '',
  disabled = false
}: {
  modalToOpen: string;
  label?: string;
  disabled?: boolean;
}) => {
  return (
    <Consumer>
      {({ openModal }: { openModal(modalName: string): boolean }) => (
        <div
          className="modal-trigger"
          onClick={() => (disabled ? {} : openModal(modalToOpen))}
          aria-disabled={disabled}
        >
          <Icon type="flux-addnew" />
          {!!label && (
            <>
              <span className="hidden">{label}</span>
              <span className="visible">{label}</span>
            </>
          )}
        </div>
      )}
    </Consumer>
  );
};

//@ts-ignore
export default withRouter(ModalsProvider);

import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { defined } from 'utils/define';
import VisibilityWrapper from '../VisibilityWrapper';
import './style.scss';

interface Header {
  title: string;
  key: string;
}

interface Row {
  data: {
    [key: string]: string | JSX.Element;
  };
  key: string;
}

const Table = ({
  headers,
  rows,
  loading,
  className
}: {
  headers: Header[];
  rows: Row[];
  loading: boolean;
  className?: string;
}) => {
  return (
    <table className="c-ffTable">
      <thead className="c-ffTable__thead">
        <tr className="c-ffTable__theadRow">
          {headers.map(header => (
            <th
              className={`c-ffTable__headCell ${className}__headCell--${header.key}`}
              key={header.key}
            >
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="c-ffTable__tbody">
        <VisibilityWrapper visible={!loading && rows.length > 0}>
          <>
            {rows.map(row => (
              <tr className="c-ffTable__tbodyRow" key={row.key}>
                {Object.keys(row.data).map((dataKey, rowIdx) => defined(headers[rowIdx]) && (
                  <td
                    className={`c-ffTable__rowCell ${className}__rowCell--${headers[rowIdx].key}`}
                    key={dataKey}
                  >
                    {row.data[dataKey]}
                  </td>
                ))}
              </tr>
            ))}
          </>
        </VisibilityWrapper>
        <VisibilityWrapper visible={!loading && !rows.length}>
          <tr className="c-ffTable__tbodyRow">
            <td className="c-ffTable__rowCell">No Data Available</td>
          </tr>
        </VisibilityWrapper>
        <VisibilityWrapper visible={loading}>
          <tr className="c-ffTable__tbodyRow--loading">
            <td className="c-ffTable__rowCell">
              <span>
                <LoadingOutlined
                  className="c-ffTable__loadingIcon"
                  style={{ fontSize: 20 }}
                  spin
                />
              </span>
            </td>
          </tr>
        </VisibilityWrapper>
      </tbody>
    </table>
  );
};

export default Table;

import { DNSFields, DNSRecordTemplate, DNSRecordField } from 'types/domains';
import {
  required,
  IPV4,
  IPV6,
  simpleUrl,
  isCloudFlareIP4Address
} from 'utils/validation';

export const dnsAddRecordFields: DNSFields = {
  Name: {
    title: 'Name',
    required: true,
    placeholder: 'Use @ for root',
    type: 'text-input',
    key: 'name',
    isDataParam: false
  },
  IPv4Address: {
    title: 'IPv4 address',
    required: true,
    type: 'text-input',
    key: 'content',
    isDataParam: false
  },
  IPv6Address: {
    title: 'IPv6 address',
    required: true,
    type: 'text-input',
    key: 'content',
    isDataParam: false
  },
  TTL: {
    title: 'TTL',
    required: false,
    key: 'ttl',
    isDataParam: false,
    type: 'select',
    options: [
      {
        label: 'Auto',
        value: 1
      },
      {
        label: '2 min',
        value: 120
      },
      {
        label: '5 min',
        value: 300
      },
      {
        label: '10 min',
        value: 600
      },
      {
        label: '15 min',
        value: 900
      },
      {
        label: '30 min',
        value: 1800
      },
      {
        label: '1 hr',
        value: 3600
      },
      {
        label: '2 hr',
        value: 7200
      },
      {
        label: '5 hr',
        value: 90000
      },
      {
        label: '12 hr',
        value: 43200
      },
      {
        label: '1 day',
        value: 86400
      }
    ]
  },
  FlagsNumber: {
    title: 'Flags',
    required: true,
    type: 'number-input',
    key: 'flags',
    isDataParam: true
  },
  Tag: {
    title: 'Tag',
    required: true,
    key: 'tag',
    isDataParam: true,
    type: 'select',
    options: [
      {
        label: 'Only allow specific hostnames',
        value: 'issue'
      },
      {
        label: 'Only allow wildcards',
        value: 'issuewild'
      },
      {
        label: 'Send violation reports to URL (http:, https:, or mailto:)',
        value: 'iodef'
      }
    ]
  },
  CADomainName: {
    title: 'CA domain name',
    required: true,
    type: 'text-input',
    key: 'value',
    isDataParam: true
  },
  CertType: {
    title: 'Cert. type',
    required: true,
    type: 'number-input',
    description: '0 - 65355',
    key: 'type',
    isDataParam: true
  },
  KeyTag: {
    title: 'Key tag',
    required: true,
    type: 'number-input',
    description: '0 - 65355',
    key: 'key_tag',
    isDataParam: true
  },
  Algorithm: {
    title: 'Algorithm',
    required: true,
    type: 'number-input',
    description: '0 - 255',
    key: 'algorithm',
    isDataParam: true
  },
  Certificate: {
    title: 'Certificate',
    placeholder: `-----BEGIN CERTIFICATE-----
QmFzZTY0IGVuY29kZWQgY2VydGlmaWNhdGUgZGF0YSBleGlzdHMgaGVyZQ==
-----END CERTIFICATE-----
    `,
    type: 'textarea',
    required: true,
    key: 'certificate',
    isDataParam: true
  },
  Target: {
    title: 'Target',
    type: 'text-input',
    required: true,
    key: 'target',
    isDataParam: true
  },
  Protocol: {
    title: 'Protocol',
    required: true,
    type: 'select',
    key: 'proto',
    isDataParam: true,
    options: [
      {
        label: 'TCP',
        value: '_tcp'
      },
      {
        label: 'UDP',
        value: '_udp'
      },
      {
        label: 'TLS',
        value: '_tls'
      }
    ]
  },
  DigestType: {
    title: 'Digest Type',
    required: true,
    type: 'number-input',
    description: '0 - 255',
    key: 'digest_type',
    isDataParam: true
  },
  Digest: {
    title: 'Digest',
    required: true,
    type: 'textarea',
    key: 'digest',
    isDataParam: true
  },
  Latitude: {
    title: 'Latitude',
    type: 'number-input',
    description: 'degrees',
    key: 'lat_degrees',
    isDataParam: true,
    required: true,
    subFields: [
      {
        type: 'number-input',
        description: 'min',
        key: 'lat_minutes',
        isDataParam: true
      },
      {
        type: 'number-input',
        description: 'sec',
        key: 'lat_seconds',
        isDataParam: true
      },
      {
        type: 'select',
        description: 'direction',
        key: 'lat_direction',
        isDataParam: true,
        options: [
          {
            label: 'North',
            value: 'N'
          },
          {
            label: 'South',
            value: 'S'
          }
        ]
      }
    ]
  },
  Precision: {
    title: 'Precision',
    required: true,
    type: 'number-input',
    key: 'precision_horz',
    isDataParam: true,
    description: 'horiz. meters',
    subFields: [
      {
        type: 'number-input',
        description: 'vert. meters',
        key: 'precision_vert',
        isDataParam: true
      }
    ]
  },
  Longitude: {
    title: 'Longitude',
    type: 'number-input',
    description: 'degrees',
    key: 'long_degrees',
    isDataParam: true,
    required: true,
    subFields: [
      {
        type: 'number-input',
        description: 'min',
        key: 'long_minutes',
        isDataParam: true
      },
      {
        type: 'number-input',
        description: 'sec',
        key: 'long_seconds',
        isDataParam: true
      },
      {
        type: 'select',
        description: 'direction',
        key: 'long_direction',
        isDataParam: true,
        options: [
          {
            label: 'East',
            value: 'E'
          },
          {
            label: 'West',
            value: 'W'
          }
        ]
      }
    ]
  },
  Altitude: {
    title: 'Altitude',
    description: 'meters',
    type: 'number-input',
    required: true,
    key: 'altitude',
    isDataParam: true
  },
  Size: {
    title: 'Size',
    description: 'meters',
    type: 'number-input',
    required: true,
    key: 'size',
    isDataParam: true
  },
  MailServer: {
    title: 'Mail server',
    required: true,
    type: 'text-input',
    key: 'content',
    isDataParam: false
  },
  Priority: {
    title: 'Priority',
    required: true,
    description: '0 - 65355',
    type: 'number-input',
    key: 'priority',
    isDataParam: false
  },
  Order: {
    title: 'Order',
    description: '0 - 65355',
    type: 'number-input',
    required: true,
    key: 'order',
    isDataParam: true
  },
  Preference: {
    title: 'Preference',
    description: '0 - 65355',
    type: 'number-input',
    required: true,
    key: 'preference',
    isDataParam: true
  },
  FlagsText: {
    title: 'Flags',
    description: 'S, A, U, P',
    type: 'text-input',
    required: true,
    key: 'flags',
    isDataParam: true
  },
  Service: {
    title: 'Service',
    placeholder: 'protocol=...',
    type: 'text-input',
    required: true,
    key: 'service',
    isDataParam: true
  },
  RegEx: {
    title: 'RegEx',
    placeholder: 'delim-char=...',
    type: 'text-input',
    required: false,
    key: 'regex',
    isDataParam: true
  },
  Replacement: {
    title: 'Replacement',
    type: 'text-input',
    required: false,
    key: 'replacement',
    isDataParam: true
  },
  Nameserver: {
    title: 'Nameserver',
    type: 'text-input',
    required: true,
    key: 'content',
    isDataParam: false
  },
  DomainName: {
    title: 'Domain name',
    type: 'text-input',
    required: true,
    key: 'content',
    isDataParam: false
  },
  Usage: {
    title: 'Usage',
    type: 'number-input',
    description: '0 - 255',
    required: true,
    key: 'usage',
    isDataParam: true
  },
  Selector: {
    title: 'Selector',
    type: 'number-input',
    description: '0 - 255',
    required: true,
    key: 'selector',
    isDataParam: true
  },
  MatchingType: {
    title: 'Matching type',
    type: 'number-input',
    description: '0 - 255',
    required: true,
    key: 'matching_type',
    isDataParam: true
  },
  Content: {
    title: 'Content',
    required: true,
    placeholder: 'v=spf...',
    type: 'text-input',
    key: 'content',
    isDataParam: false
  },
  PublicKey: {
    title: 'Public Key',
    type: 'textarea',
    required: true,
    placeholder: `-----BEGIN PUBLIC KEY-----
QmFzZTY0IGVuY29kZWQgcHJpdmF0ZSBrZXkgZGF0YSBleGlzdHMgaGVyZQ==
-----END PUBLIC KEY-----`,
    key: 'public_key',
    isDataParam: true
  },
  Weight: {
    title: 'Weight',
    required: true,
    type: 'number-input',
    description: '0 - 65355',
    key: 'weight',
    isDataParam: true
  },
  Port: {
    title: 'Port',
    required: true,
    type: 'number-input',
    description: '0 - 65355',
    key: 'port',
    isDataParam: true
  },
  Fingerprint: {
    title: 'Fingerprint',
    required: true,
    type: 'textarea',
    key: 'fingerprint',
    isDataParam: true
  },
  TextAreaContent: {
    title: 'Content',
    required: true,
    type: 'textarea',
    key: 'content',
    isDataParam: false
  },
  Type: {
    title: 'Type',
    required: true,
    type: 'number-input',
    description: '0 - 255',
    key: 'type',
    isDataParam: true
  },
  Proxy: {
    title: 'Proxy status',
    required: false,
    type: 'proxy',
    key: 'proxied',
    isDataParam: false
  }
};

const CNameTarget: DNSRecordField = {
  ...dnsAddRecordFields.Target,
  key: 'content',
  isDataParam: false
};

const DNSKeyProtocol: DNSRecordField = {
  ...dnsAddRecordFields.Protocol,
  key: 'protocol',
  disabled: true,
  options: [
    {
      label: '3 - TCP',
      value: 3
    }
  ]
};

export const dnsTemplates: DNSRecordTemplate[] = [
  {
    type: 'A',
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.IPv4Address,
      dnsAddRecordFields.TTL,
      dnsAddRecordFields.Proxy
    ],
    proxiable: true,
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.IPv4Address.key,
        validations: [required, IPV4, isCloudFlareIP4Address]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'points to'
      },
      {
        field: dnsAddRecordFields.IPv4Address,
        text: '[IPv4 address]',
        postfixText: 'and has its traffic proxied through Cloudflare'
      }
    ]
  },
  {
    type: 'AAAA',
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.IPv6Address,
      dnsAddRecordFields.TTL,
      dnsAddRecordFields.Proxy
    ],
    proxiable: true,
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: 'content',
        validations: [required, IPV6]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'points to'
      },
      {
        field: dnsAddRecordFields.IPv6Address,
        text: '[IPv6 address]',
        postfixText: 'and has its traffic proxied through Cloudflare'
      }
    ]
  },
  {
    type: 'CAA',
    proxiable: false,
    defaultDataParams: {
      [dnsAddRecordFields.FlagsNumber.key]: 0,
      [dnsAddRecordFields.Tag.key]: 'issue'
    },
    contentCalculationPattern: [
      dnsAddRecordFields.FlagsNumber,
      dnsAddRecordFields.Tag,
      dnsAddRecordFields.CADomainName
    ],
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.FlagsNumber,
      dnsAddRecordFields.Tag,
      dnsAddRecordFields.CADomainName,
      dnsAddRecordFields.TTL
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.FlagsNumber.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Tag.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.CADomainName.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.CADomainName,
        text: '[domain name]',
        postfixText: 'can issue certificates for'
      },
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'and'
      },
      {
        field: dnsAddRecordFields.Tag,
        text: '[Tag]'
      }
    ]
  },
  {
    type: 'CERT',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.CertType,
      dnsAddRecordFields.KeyTag,
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.TTL,
      'nextline' as any,
      dnsAddRecordFields.Certificate
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.CertType,
      dnsAddRecordFields.KeyTag,
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.Certificate
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.CertType.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.KeyTag.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Algorithm.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Certificate.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'has certificate type'
      },
      {
        field: dnsAddRecordFields.CertType,
        text: '[cert. type]',
        postfixText: 'and a public key encrypted with'
      },
      {
        field: dnsAddRecordFields.Algorithm,
        text: '[algorithm]'
      }
    ]
  },
  {
    type: 'CNAME',
    proxiable: true,
    fields: [
      dnsAddRecordFields.Name,
      CNameTarget,
      dnsAddRecordFields.TTL,
      dnsAddRecordFields.Proxy
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: CNameTarget.key,
        validations: [required, simpleUrl]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'is an alias of'
      },
      {
        field: CNameTarget,
        text: '[target]',
        postfixText: 'and has its traffic proxied through Cloudflare'
      }
    ]
  },
  {
    type: 'DNSKEY',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.FlagsNumber,
      DNSKeyProtocol,
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.TTL,
      'nextline' as any,
      dnsAddRecordFields.PublicKey
    ],
    defaultDataParams: {
      [DNSKeyProtocol.key]: 3
    },
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.FlagsNumber.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Algorithm.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.PublicKey.key,
        validations: [required]
      }
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.FlagsNumber,
      DNSKeyProtocol,
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.PublicKey
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'verifies DNSSEC with a public key encrypted with'
      },
      {
        field: dnsAddRecordFields.Algorithm,
        text: '[algorithm]'
      }
    ]
  },
  {
    type: 'DS',
    proxiable: false,
    defaultDataParams: {
      algorithm: 13
    },
    contentCalculationPattern: [
      dnsAddRecordFields.KeyTag,
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.DigestType,
      dnsAddRecordFields.Digest
    ],
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.KeyTag,
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.DigestType,
      dnsAddRecordFields.TTL,
      'nextline' as any,
      dnsAddRecordFields.Digest
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.KeyTag.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Algorithm.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.DigestType.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Digest.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'has a DNSSEC key with ID'
      },
      {
        field: dnsAddRecordFields.KeyTag,
        text: '[key tag]',
        postfixText: 'and public key hashed by'
      },
      {
        field: dnsAddRecordFields.DigestType,
        text: '[digest type]',
        postfixText: 'for a DNSKEY record using'
      },
      {
        field: dnsAddRecordFields.Algorithm,
        text: '[algorithm]'
      }
    ]
  },
  {
    type: 'LOC',
    proxiable: false,
    defaultDataParams: {
      long_direction: 'E',
      lat_minutes: 0,
      lat_seconds: 0,
      long_minutes: 0,
      long_seconds: 0,
      lat_direction: 'N'
    },
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Latitude,
      dnsAddRecordFields.Precision,
      'nextline' as any,
      dnsAddRecordFields.TTL,
      dnsAddRecordFields.Longitude,
      dnsAddRecordFields.Altitude,
      dnsAddRecordFields.Size
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.Latitude,
      dnsAddRecordFields.Latitude.subFields![0],
      dnsAddRecordFields.Latitude.subFields![1],
      dnsAddRecordFields.Latitude.subFields![2],
      dnsAddRecordFields.Longitude,
      dnsAddRecordFields.Longitude.subFields![0],
      dnsAddRecordFields.Longitude.subFields![1],
      dnsAddRecordFields.Longitude.subFields![2],
      dnsAddRecordFields.Altitude,
      dnsAddRecordFields.Size,
      dnsAddRecordFields.Precision,
      dnsAddRecordFields.Precision.subFields![0]
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Latitude.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Longitude.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Precision.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Precision.subFields![0].key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Altitude.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Size.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'has location information'
      },
      {
        text: 'IN LOC'
      },
      {
        field: dnsAddRecordFields.Latitude,
        text: '0'
      },
      {
        field: dnsAddRecordFields.Latitude.subFields![0],
        text: '0'
      },
      {
        field: dnsAddRecordFields.Latitude.subFields![1],
        text: '0'
      },
      {
        field: dnsAddRecordFields.Latitude.subFields![2],
        text: 'N'
      },
      {
        field: dnsAddRecordFields.Longitude,
        text: '0'
      },
      {
        field: dnsAddRecordFields.Longitude.subFields![0],
        text: '0'
      },
      {
        field: dnsAddRecordFields.Longitude.subFields![1],
        text: '0'
      },
      {
        field: dnsAddRecordFields.Longitude.subFields![2],
        text: 'E'
      },
      {
        field: dnsAddRecordFields.Altitude,
        text: '0',
        flag: 'm'
      },
      {
        field: dnsAddRecordFields.Size,
        text: '0',
        flag: 'm'
      },
      {
        field: dnsAddRecordFields.Precision,
        text: '0',
        flag: 'm'
      },
      {
        field: dnsAddRecordFields.Precision.subFields![0],
        text: '0',
        flag: 'm'
      }
    ]
  },
  {
    type: 'MX',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.MailServer,
      dnsAddRecordFields.Priority,
      dnsAddRecordFields.TTL
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.MailServer.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Priority.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.MailServer,
        text: '[mail server]',
        postfixText: 'handles mail for'
      },
      {
        field: dnsAddRecordFields.Name,
        text: '[name]'
      }
    ]
  },
  {
    type: 'NAPTR',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Order,
      dnsAddRecordFields.Preference,
      dnsAddRecordFields.FlagsText,
      dnsAddRecordFields.Service,
      'nextline' as any,
      dnsAddRecordFields.TTL,
      dnsAddRecordFields.RegEx,
      dnsAddRecordFields.Replacement
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.Order,
      dnsAddRecordFields.Preference,
      dnsAddRecordFields.FlagsText,
      dnsAddRecordFields.Service,
      dnsAddRecordFields.RegEx,
      dnsAddRecordFields.Replacement
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Order.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Preference.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.FlagsText.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Service.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'returns content'
      },
      {
        field: {} as any,
        text: '[content]'
      }
    ]
  },
  {
    type: 'NS',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Nameserver,
      dnsAddRecordFields.TTL
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Nameserver.key,
        validations: [required, simpleUrl]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'is managed by'
      },
      {
        field: dnsAddRecordFields.Nameserver,
        text: '[nameserver]'
      }
    ]
  },
  {
    type: 'PTR',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.DomainName,
      dnsAddRecordFields.TTL
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.DomainName.key,
        validations: [required, simpleUrl]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.DomainName,
        text: '[domain name]',
        postfixText: 'points to'
      },
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'in a reverse lookup'
      }
    ]
  },
  {
    type: 'SMIMEA',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Usage,
      dnsAddRecordFields.Selector,
      dnsAddRecordFields.MatchingType,
      dnsAddRecordFields.TTL,
      'nextline' as any,
      dnsAddRecordFields.Certificate
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.Usage,
      dnsAddRecordFields.Selector,
      dnsAddRecordFields.MatchingType,
      dnsAddRecordFields.Certificate
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Usage.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Selector.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.MatchingType.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Certificate.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'has an association with'
      },
      {
        field: dnsAddRecordFields.Selector,
        text: '[selector]',
        postfixText: 'using'
      },
      {
        field: dnsAddRecordFields.MatchingType,
        text: '[matching type]',
        postfixText: 'and a'
      },
      {
        field: dnsAddRecordFields.Usage,
        text: '[usage]'
      }
    ]
  },
  {
    type: 'SPF',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Content,
      dnsAddRecordFields.TTL
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Content.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'has a record with content'
      },
      {
        field: dnsAddRecordFields.Content,
        text: '[content]'
      }
    ]
  },
  {
    type: 'SRV',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Service,
      dnsAddRecordFields.Protocol,
      dnsAddRecordFields.Name,
      'nextline' as any,
      dnsAddRecordFields.TTL,
      dnsAddRecordFields.Priority,
      dnsAddRecordFields.Weight,
      dnsAddRecordFields.Port,
      dnsAddRecordFields.Target
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.Priority,
      dnsAddRecordFields.Weight,
      dnsAddRecordFields.Port,
      dnsAddRecordFields.Target
    ],
    defaultDataParams: {
      proto: '_tcp'
    },
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Service.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Protocol.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Priority.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Weight.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Port.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Target.key,
        validations: [required, simpleUrl]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Service,
        text: '[service]',
        flag: '.'
      },
      {
        field: dnsAddRecordFields.Protocol,
        text: '[protocol]',
        flag: '.'
      },
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'points to'
      },
      {
        field: dnsAddRecordFields.Target,
        text: '[target]',
        postfixText: 'and listens on'
      },
      {
        field: dnsAddRecordFields.Protocol,
        text: '[protocol]',
        postfixText: 'port'
      },
      {
        field: dnsAddRecordFields.Port,
        text: '[port]',
        postfixText: 'for'
      },
      {
        field: dnsAddRecordFields.Service,
        text: '[service name]',
        postfixText: 'services'
      }
    ]
  },
  {
    type: 'SSHFP',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.Type,
      dnsAddRecordFields.TTL,
      'nextline' as any,
      dnsAddRecordFields.Fingerprint
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.Algorithm,
      dnsAddRecordFields.Type,
      dnsAddRecordFields.Fingerprint
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Algorithm.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Type.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Fingerprint.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'uses'
      },
      {
        field: dnsAddRecordFields.Algorithm,
        text: '[algorithm]',
        postfixText: 'with'
      },
      {
        field: dnsAddRecordFields.Type,
        text: '[fingerprint type]',
        postfixText: 'fingerprint'
      },
      {
        field: dnsAddRecordFields.Fingerprint,
        text: '[fingerprint]'
      }
    ]
  },
  {
    type: 'TLSA',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Usage,
      dnsAddRecordFields.Selector,
      dnsAddRecordFields.MatchingType,
      dnsAddRecordFields.TTL,
      'nextline' as any,
      dnsAddRecordFields.Certificate
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.Usage,
      dnsAddRecordFields.Selector,
      dnsAddRecordFields.MatchingType,
      dnsAddRecordFields.Certificate
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Usage.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Selector.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.MatchingType.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Certificate.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'has an association with'
      },
      {
        field: dnsAddRecordFields.Selector,
        text: '[selector]',
        postfixText: 'using'
      },
      {
        field: dnsAddRecordFields.MatchingType,
        text: '[matching type]',
        postfixText: 'and a'
      },
      {
        field: dnsAddRecordFields.Usage,
        text: '[usage]'
      }
    ]
  },
  {
    type: 'TXT',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.TTL,
      'nextline' as any,
      dnsAddRecordFields.TextAreaContent
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.TextAreaContent.key,
        validations: [required]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'has a record with content'
      },
      {
        field: dnsAddRecordFields.TextAreaContent,
        text: '[content]'
      }
    ]
  },
  {
    type: 'URI',
    proxiable: false,
    fields: [
      dnsAddRecordFields.Name,
      dnsAddRecordFields.Weight,
      dnsAddRecordFields.Priority,
      dnsAddRecordFields.Target,
      dnsAddRecordFields.TTL
    ],
    contentCalculationPattern: [
      dnsAddRecordFields.Weight,
      dnsAddRecordFields.Target
    ],
    validationRules: [
      {
        field: dnsAddRecordFields.Name.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Weight.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Priority.key,
        validations: [required]
      },
      {
        field: dnsAddRecordFields.Target.key,
        validations: [required, simpleUrl]
      }
    ],
    helpText: [
      {
        field: dnsAddRecordFields.Name,
        text: '[name]',
        postfixText: 'points to'
      },
      {
        field: dnsAddRecordFields.Target,
        text: '[target]'
      }
    ]
  }
];

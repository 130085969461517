import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="logout-3--arrow-enter-right-logout-point-square">
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M22.893214285714286 22.893214285714286c0 0.5279374285714286 -0.20982128571428568 1.0343751428571428 -0.5830802142857142 1.4076340714285713s-0.8796966428571429 0.5830802142857142 -1.4076340714285713 0.5830802142857142h-17.916428571428572c-0.5279772428571429 0 -1.0343154214285712 -0.20982128571428568 -1.4076480064285715 -0.5830802142857142C1.205092827857143 23.92758942857143 0.9953571428571428 23.421151714285713 0.9953571428571428 22.893214285714286v-17.916428571428572c0 -0.5279772428571429 0.209735685 -1.0343154214285712 0.5830662792857143 -1.4076539785714286C1.9517560071428572 3.1958130857142852 2.4580941857142857 2.9860714285714285 2.9860714285714285 2.9860714285714285h17.916428571428572c0.5279374285714286 0 1.0343751428571428 0.20974165714285714 1.4076340714285713 0.5830603071428571 0.37325892857142856 0.37333855714285713 0.5830802142857142 0.8796767357142857 0.5830802142857142 1.4076539785714286"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.893214285714286 22.893214285714286c0 0.5279374285714286 -0.20982128571428568 1.0343751428571428 -0.5830802142857142 1.4076340714285713s-0.8796966428571429 0.5830802142857142 -1.4076340714285713 0.5830802142857142h-17.916428571428572c-0.5279772428571429 0 -1.0343154214285712 -0.20982128571428568 -1.4076480064285715 -0.5830802142857142C1.205092827857143 23.92758942857143 0.9953571428571428 23.421151714285713 0.9953571428571428 22.893214285714286v-17.916428571428572c0 -0.5279772428571429 0.209735685 -1.0343154214285712 0.5830662792857143 -1.4076539785714286C1.9517560071428572 3.1958130857142852 2.4580941857142857 2.9860714285714285 2.9860714285714285 2.9860714285714285h17.916428571428572c0.5279374285714286 0 1.0343751428571428 0.20974165714285714 1.4076340714285713 0.5830603071428571 0.37325892857142856 0.37333855714285713 0.5830802142857142 0.8796767357142857 0.5830802142857142 1.4076539785714286"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.944285714285714 13.934999999999999h14.930357142857142"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m22.893214285714286 9.95357142857143 3.9814285714285713 3.9814285714285713 -3.9814285714285713 3.9814285714285713"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

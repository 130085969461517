import { _loggedIn, _getAccessToken } from './user';
import { getNavDetails } from './navigation';
import { getReportingAttributes, getReportingColumns } from './reportings';
import { getGraphSummary } from './summary';
import {
  getReportingCustomFieldOptions,
  getTrafficSourcesReporting
} from './trafficsources';
import {
  LANDER_CATEGORY_TYPE,
  OFFER_CATEGORY_TYPE,
  FUNNEL_CATEGORY_TYPE,
  TRAFFIC_SOURCE_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_TYPE,
  PAGE_GROUPS_CATEGORY_TYPE,
  OVERVIEW_CATEGORY_TYPE,
  CAMPAIGN_ANALYSIS_CATEGORY_TYPE
} from 'constants/modal';
import {
  FunnelGroupStore,
  CompositeStore,
  ConditionStore,
  FunnelStore,
  LanderStore,
  OfferSourceStore,
  OfferStore,
  PageGroupStore,
  ReportingsStore,
  ResetDataStore,
  SummaryDataStore,
  SummaryGraphStore,
  TrafficSourceStore,
  HitsStore,
  CampaignAnalysisStore,
  GoogleAdsStore,
  FacebookAdsStore
} from '../../types/redux/store';
import { getLandersReporting } from './landers';
import { getOffersReporting } from './offers';
import { getOfferSourcesReporting } from './offersources';
import { getFunnelGroupsReporting } from './funnelGroups';
import { API_DEBOUNCE } from '../../constants/api';
import { getResetValue } from './resetData';
import { getConditionsData } from './conditions';
import { getSelectOptions } from './updateTrafficCosts';
import { QUICKSTATS_CATEGORY_TYPE } from '../../constants/modal';
import { defined } from '../../utils/define';
import { getPageGroupsData } from './pageGroups';
import { getActiveEntities } from 'utils/model';
import { getCampaignAnalysisReporting } from './campaignAnalysis';
import { SubscriptionStatus } from 'model/subscription';
import {
  isSubscriptionExpired,
  isSubscriptionStandby
} from 'utils/app';
import { defaultCustomEventAliases } from 'constants/customEvents';

// --- User
export const getAccessToken = (state: CompositeStore) =>
  _getAccessToken(state.user);
export const getUser = (state: CompositeStore) => state.user;
export const loggedIn = (state: CompositeStore) => _loggedIn(state.user);

type AllStores =
  | FunnelGroupStore
  | LanderStore
  | OfferStore
  | OfferSourceStore
  | TrafficSourceStore;

type CategoriesStore =
  | LanderStore
  | OfferStore
  | OfferSourceStore
  | TrafficSourceStore
  | ConditionStore;

export const getAllData = <T extends AllStores | FunnelStore | ConditionStore | PageGroupStore>(
  state: T
) => Object.values(state.data);

export const getAllDataById = <
  T extends
    | FunnelGroupStore
    | LanderStore
    | OfferStore
    | OfferSourceStore
    | TrafficSourceStore
    | ConditionStore
    | FunnelStore
    | PageGroupStore
>(
  state: T
): T['data'] => state.data;

export const getActiveData = (
  state: AllStores | FunnelStore | ConditionStore
) =>
  Object.values(state.data).filter(
    item => defined(item.status) && item.status === 'active'
  );
export const getReportings = (
  state: AllStores | ReportingsStore | SummaryDataStore | GoogleAdsStore | FacebookAdsStore
) => state.reporting;
export const getReportingRows = (
  state: AllStores | ReportingsStore | SummaryDataStore | ResetDataStore
) => state.reporting.rows;
export const isReportingFetching = (
  state: AllStores | SummaryDataStore | CampaignAnalysisStore
) => state.loadings.reporting.isLoading;
export const isDataFetching = (
  state: AllStores | SummaryDataStore | SummaryGraphStore
) => state.loadings.data.isLoading;
export const isFetching = (
  state: ReportingsStore | ConditionStore | PageGroupStore | HitsStore
) => state.loading.isLoading;
export const getReportingLoading = (state: AllStores | SummaryDataStore) =>
  state.loadings.reporting;
export const tableCols = (
  state:
    | AllStores
    | ReportingsStore
    | GoogleAdsStore
    | FacebookAdsStore
    | PageGroupStore
    | HitsStore
    | CampaignAnalysisStore
) => state.settings.tableCols;
export const filters = (state: AllStores) => state.settings.filters;
export const getShow = (
  state: AllStores | PageGroupStore | CampaignAnalysisStore
) => state.settings.show;
export const getEditMode = (
  state: AllStores | ConditionStore | PageGroupStore | CampaignAnalysisStore
) => state.settings.editMode;
export const getFilters = (state: AllStores | CampaignAnalysisStore) =>
  state.settings.filters;
export const getPageFilters = (state: PageGroupStore) => state.settings.filters;
export const getTableParams = (
  state: AllStores | PageGroupStore | CampaignAnalysisStore
) => state.settings.tableParams;
export const getAdditionalParams = (
  state: AllStores | PageGroupStore | CampaignAnalysisStore
) => state.settings.additionalParams;
export const getCategories = (state: CategoriesStore) => state.categories;
export const shouldFetchData = (state: AllStores) =>
  Date.now() - state.loadings.data.fetchedAt > API_DEBOUNCE ||
  Object.keys(state.data).length === 0;
export const shouldFetch = (
  state: FunnelStore | ConditionStore | PageGroupStore
) =>
  Date.now() - state.loading.fetchedAt > API_DEBOUNCE ||
  Object.keys(state.data).length === 0;
export const shouldFetchReporting = (state: AllStores) =>
  Date.now() - state.loadings.reporting.fetchedAt > API_DEBOUNCE ||
  state.reporting.rows.length === 0;

// --- AppView Selector
export const AppViewSelector = (mainState: CompositeStore) => ({
  user: {
    ...getUser(mainState),
    loggedIn: loggedIn(mainState)
  },
  lastViewRoute: mainState.navigation.lastViewRoute,
  menu: getNavDetails(mainState.navigation),
  app: mainState.app,
  funnels: mainState.funnels.data,
  settings: mainState.systemSettings.data,
  onboardingStatus: mainState.onboarding.status,
  builderData: FunnelBuilderSelector(mainState),
  siderMenuIsExpanded: mainState.navigation.siderMenuIsExpanded,
  ...SubscriptionSelector(mainState)
});

export const SubscriptionSelector = (
  mainState: CompositeStore
): { subscriptionStatus: SubscriptionStatus } => ({
  subscriptionStatus: {
    isExpired: isSubscriptionExpired(mainState.user),
    isStandby: isSubscriptionStandby(mainState.user)
  }
});

// --- QuickStart Wizard Selector
export const QuickStartWizardSelector = (mainState: CompositeStore) => ({
  data: FunnelBuilderSelector(mainState)
});

// --- FunnelGroupsView Selector
export const FunnelGroupsViewSelector = (mainState: CompositeStore) => {
  const state = mainState.funnelGroups;
  return {
    funnelGroups: getAllData(mainState.funnelGroups),
    funnels: getAllData(mainState.funnels),
    reporting: getFunnelGroupsReporting(state, false),
    tableCols: tableCols(state),
    show: getShow(state),
    editMode: getEditMode(state),
    filters: getFilters(state),
    additionalParams: getAdditionalParams(state),
    tableParams: getTableParams(state),
    quickStatsParams: mainState.quickstats[FUNNEL_CATEGORY_TYPE].params,
    showEditModeTooltips: mainState.systemSettings.tooltips.editMode,
    loading: getReportingLoading(state),
    user: mainState.user,
    userSettings: mainState.systemSettings.data,
    ...SubscriptionSelector(mainState)
  };
};

// --- ReportingsView Selector
export const ReportingsViewSelector = (mainState: CompositeStore) => {
  const state = mainState.reportings;
  return {
    reportings: getReportings(state),
    reportingsInitial: state.reportingInitial,
    columns: getReportingColumns(state),
    customFieldOptions: getReportingCustomFieldOptions(
      mainState.trafficsources
    ),
    attributes: getReportingAttributes(state),
    isFetching: isFetching(state),
    tableCols: tableCols(state),
    trafficSources: mainState.trafficsources.data,
    settings: state.settings,
    tableParams: state.tableParams,
    restrictData: state.settings.params.restrictData,
    dateRangeName: state.settings.params.dateRangeName,
    tableColSizes: state.settings.tableColSizes,
    userSettings: mainState.systemSettings.data
  };
};

// --- SummaryView Selector
export const SummaryViewSelector = (mainState: CompositeStore) => {
  const state = mainState.summary;
  const trafficSourceReporting = getReportings(
    state[TRAFFIC_SOURCE_CATEGORY_TYPE]
  );
  const landersReporting = getReportings(state[LANDER_CATEGORY_TYPE]);
  const offerReporting = getReportings(state[OFFER_CATEGORY_TYPE]);

  return {
    [FUNNEL_CATEGORY_TYPE]: {
      reporting: getReportings(state[FUNNEL_CATEGORY_TYPE]),
      isFetching: isReportingFetching(state[FUNNEL_CATEGORY_TYPE])
    },
    [TRAFFIC_SOURCE_CATEGORY_TYPE]: {
      reporting: {
        ...trafficSourceReporting,
        rows: getTrafficSourcesReporting(
          {
            ...mainState.trafficsources,
            reporting: trafficSourceReporting
          },
          true
        )
      },
      isFetching: isReportingFetching(state[TRAFFIC_SOURCE_CATEGORY_TYPE])
    },
    [LANDER_CATEGORY_TYPE]: {
      reporting: {
        ...landersReporting,
        rows: getLandersReporting(
          {
            ...mainState.landers,
            reporting: landersReporting
          },
          true
        )
      },
      isFetching: isReportingFetching(state[LANDER_CATEGORY_TYPE])
    },
    [OFFER_CATEGORY_TYPE]: {
      reporting: {
        ...offerReporting,
        rows: getOffersReporting(
          {
            ...mainState.offers,
            reporting: offerReporting
          },
          true
        )
      },
      isFetching: isReportingFetching(state[OFFER_CATEGORY_TYPE])
    },
    [OVERVIEW_CATEGORY_TYPE]: {
      reporting: getReportings(state[OVERVIEW_CATEGORY_TYPE]),
      isFetching: isReportingFetching(state[OVERVIEW_CATEGORY_TYPE])
    },
    [PAGE_GROUPS_CATEGORY_TYPE]: {
      reporting: {
        ...landersReporting,
        rows: getPageGroupsData(
          {
            ...mainState.pageGroups,
            reporting: {
              landers: landersReporting,
              offers: offerReporting
            }
          },
          mainState.landers,
          mainState.offers,
          true,
          true
        )
      },
      isFetching: isReportingFetching(state[OFFER_CATEGORY_TYPE])
    },
    tableCols: state.tableCols,
    graph: {
      reporting: getGraphSummary(state),
      isFetching: state.graph.loadings.data.isLoading
    },
    isGraphFetching: isDataFetching(state.graph),
    settings: state.settings,
    filters: state.filters,
    userSettings: mainState.systemSettings.data,
  };
};

// --- LandersView Selector
export const LandersViewSelector = (mainState: CompositeStore) => {
  const state = mainState.landers;
  return {
    landers: getAllDataById<LanderStore>(state),
    categories: getCategories(state),
    reporting: getLandersReporting(state, false),
    isFetching: isReportingFetching(state),
    tableCols: tableCols(state),
    show: getShow(mainState.landers),
    editMode: getEditMode(state),
    filters: getFilters(state),
    tableParams: getTableParams(state),
    quickStatsParams: mainState.quickstats[LANDER_CATEGORY_TYPE].params,
    showEditModeTooltips: mainState.systemSettings.tooltips.editMode,
    loading: getReportingLoading(state),
    user: mainState.user,
    userSettings: mainState.systemSettings.data,
    ...SubscriptionSelector(mainState)
  };
};

// --- OffersView Selector
export const OffersViewSelector = (mainState: CompositeStore) => {
  const state = mainState.offers;
  return {
    offers: getAllDataById(state),
    categories: getCategories(state),
    reporting: getOffersReporting(state, false),
    isFetching: isReportingFetching(state),
    tableCols: tableCols(state),
    show: getShow(state),
    editMode: getEditMode(state),
    filters: getFilters(state),
    additionalParams: getAdditionalParams(state),
    tableParams: getTableParams(state),
    quickStatsParams: mainState.quickstats[OFFER_CATEGORY_TYPE].params,
    showEditModeTooltips: mainState.systemSettings.tooltips.editMode,
    loading: getReportingLoading(state),
    user: mainState.user,
    userSettings: mainState.systemSettings.data,
    ...SubscriptionSelector(mainState)
  };
};

// --- OfferSourcesView Selector
export const OfferSourcesViewSelector = (mainState: CompositeStore) => {
  const state = mainState.offersources;
  return {
    offerSources: getAllDataById(state),
    categories: getCategories(state),
    reporting: getOfferSourcesReporting(state, false),
    isFetching: isReportingFetching(state),
    tableCols: tableCols(state),
    show: getShow(state),
    editMode: getEditMode(state),
    filters: getFilters(state),
    tableParams: getTableParams(state),
    quickStatsParams: mainState.quickstats[OFFER_SOURCE_CATEGORY_TYPE].params,
    showEditModeTooltips: mainState.systemSettings.tooltips.editMode,
    loading: getReportingLoading(state),
    user: mainState.user,
    userSettings: mainState.systemSettings.data,
    ...SubscriptionSelector(mainState)
  };
};

// --- TrafficSourcesView Selector
export const TrafficSourcesViewSelector = (mainState: CompositeStore) => {
  const state = mainState.trafficsources;
  return {
    trafficSources: getAllDataById(state),
    categories: getCategories(state),
    trafficSourcesById: getAllDataById(state),
    reporting: getTrafficSourcesReporting(state, false),
    isFetching: isReportingFetching(state),
    tableCols: tableCols(state),
    show: getShow(state),
    editMode: getEditMode(state),
    filters: getFilters(state),
    tableParams: getTableParams(state),
    quickStatsParams: mainState.quickstats[TRAFFIC_SOURCE_CATEGORY_TYPE].params,
    showEditModeTooltips: mainState.systemSettings.tooltips.editMode,
    loading: getReportingLoading(state),
    user: mainState.user,
    userSettings: mainState.systemSettings.data,
    ...SubscriptionSelector(mainState)
  };
};

// --- LanderModal Selector
export const LanderModalSelector = (mainState: CompositeStore) => {
  const state = mainState.landers;
  return {
    landers: getAllDataById(state),
    categories: getCategories(state),
    redirectType: mainState.systemSettings.data.defaultLanderRedirect,
    domain: mainState.systemSettings.data.defaultCustomDomain,
    customDomains: mainState.systemSettings.domains,
    user: mainState.user
  };
};

// --- ConditionModal Selector
export const ConditionModalSelector = (mainState: CompositeStore) => {
  const state = mainState.conditions;
  return {
    conditions: getAllData(state),
    conditionsArray: getAllDataById(state),
    categories: getCategories(state),
    trafficSources: getAllData(mainState.trafficsources),
    domains: mainState.systemSettings.domains
  };
};

// --- LanderDeleteModal Selector
export const LanderDeleteModalSelector = (mainState: CompositeStore) => ({
  landers: getAllData(mainState.landers)
});

// --- PageGroupDeleteModal Selector
export const PageGroupDeleteModalSelector = (mainState: CompositeStore) => ({
  pageGroups: getAllData(mainState.pageGroups)
});

// --- PageGroupArchiveModal Selector
export const PageGroupArchiveModalSelector = (mainState: CompositeStore) => ({
  pageGroups: mainState.pageGroups.data
});

// --- TrafficSourceModal Selector
export const TrafficSourceModalSelector = (mainState: CompositeStore) => {
  const state = mainState.trafficsources;
  return {
    trafficSources: getAllDataById(state),
    categories: getCategories(state),
    attributes: mainState.reportings.attributes,
    userSettings: mainState.systemSettings.data
  };
};

// --- SimpleFlowsModal Selector
export const SimpleFlowsModalSelector = (state: CompositeStore) => {
  return {
    funnelGroupsArray: state.funnelGroups.data,
    funnelsArray: state.funnels.data,
    pagesArray: {
      ...(state.landers.data || {}),
      ...(state.offers.data || {})
    },
    pageGroupsArray: state.pageGroups.data,
    offers: getAllData(state.offers),
    landers: getAllData(state.landers),
    trafficSources: getAllData(state.trafficsources),
    userSettings: state.systemSettings.data,
    conditionArray: state.conditions.data,
    categories: {
      landers: getCategories(state.landers),
      offers: getCategories(state.offers)
    },
    domains: state.systemSettings.domains,
    domain: state.systemSettings.data.defaultCustomDomain
  };
};

// --- OfferModal Selector
export const OfferModalSelector = (mainState: CompositeStore) => {
  const state = mainState.offers;
  return {
    offers: getAllDataById(state),
    offerSources: getActiveData(mainState.offersources),
    offerSourcesCategories: getCategories(mainState.offersources),
    categories: getCategories(state),
    redirectType: mainState.systemSettings.data.defaultOfferRedirect,
    domain: mainState.systemSettings.data.defaultCustomDomain,
    customDomains: mainState.systemSettings.domains
  };
};

// --- OfferDeleteModal Selector
export const OfferDeleteModalSelector = (mainState: CompositeStore) => ({
  offers: getAllData(mainState.offers)
});

// --- OfferSourcesModal Selector
export const OfferSourcesModalSelector = (mainState: CompositeStore) => {
  const state = mainState.offersources;
  return {
    offerSources: getAllDataById(state),
    categories: getCategories(state),
    defaultCustomDomain: mainState.systemSettings.data.defaultCustomDomain,
    customDomains: mainState.systemSettings.domains
  };
};

// -- Common Category Selector
export const CategoryModalSelector = (mainState: CompositeStore) => {
  return {
    landerCategories: getCategories(mainState.landers),
    offerCategories: getCategories(mainState.offers),
    offerSourceCategories: getCategories(mainState.offersources),
    trafficSourceCategories: getCategories(mainState.trafficsources),
    conditionCategories: getCategories(mainState.conditions)
  };
};

// --- FunnelGroupsModal Selector
export const FunnelGroupsModalSelector = (state: CompositeStore) => {
  return {
    funnelGroups: getAllDataById(state.funnelGroups),
    funnels: getAllDataById(state.funnels),
    trafficSources: getAllData(state.trafficsources)
  };
};

// --- FunnelModal Selector
export const FunnelModalSelector = (state: CompositeStore) => {
  return {
    funnelGroupsArray: getAllDataById(state.funnelGroups),
    funnelGroups: getAllData(state.funnelGroups),
    pagesArray: {
      ...getAllDataById(state.landers),
      ...getAllDataById(state.offers)
    },
    pageGroupsArray: getAllDataById(state.pageGroups),
    funnels: getAllDataById(state.funnels),
    trafficSources: getAllData(state.trafficsources),
    userSettings: state.systemSettings.data,
    domains: state.systemSettings.domains
  };
};

// --- FunnelGroupsArchiveAndDeleteModal Selector
export const FunnelGroupsArchiveAndDeleteModalSelector = (
  state: CompositeStore
) => {
  return {
    funnelGroups: getAllDataById(state.funnelGroups),
    funnels: getAllData(state.funnels),
    trafficSources: getAllData(state.trafficsources)
  };
};

// --- FunnelDeleteModal Selector
export const FunnelDeleteModalSelector = (state: CompositeStore) => {
  return {
    funnels: getAllDataById(state.funnels)
  };
};

// --- NavigationTabModal Selector
export const NavigationTabModalSelector = (state: CompositeStore) => {
  return {
    funnels: getAllData(state.funnels),
    funnelGroups: getAllDataById(state.funnelGroups),
    menu: getNavDetails(state.navigation)
  };
};

// --- FunnelBuilder Selector
export const FunnelBuilderSelector = (state: CompositeStore) => {
  return {
    funnels: getAllData(state.funnels),
    funnelsArray: getAllDataById(state.funnels),
    funnelGroups: getAllData(state.funnelGroups),
    funnelGroupsArray: getAllDataById(state.funnelGroups),
    conditionsArray: getAllDataById(state.conditions),
    conditionsCategories: getCategories(state.conditions),
    landers: getAllData(state.landers),
    landerCategories: getCategories(state.landers),
    offers: getAllData(state.offers),
    offerCategories: getCategories(state.offers),
    trafficSources: getAllData(state.trafficsources),
    pageGroupsArray: state.pageGroups.data,
    systemSettings: state.systemSettings,
    userSettings: state.systemSettings.data,
    allOffers: state.offers.data,
    allLanders: state.landers.data,
    domains: state.systemSettings.domains,
    builder: state.builder,
    isCloseAllFunnelTabsModalOpen: state.funnels.isCloseAllFunnelTabsModalOpen,
  };
};

export const HeatmapOvelaySelector = (state: CompositeStore) => ({
  ...state.builder.heatmap
})

// --- PageGroups Selector
export const PageGroupsViewSelector = (mainState: CompositeStore) => {
  const state = mainState.pageGroups;
  return {
    pageGroupsData: getPageGroupsData(
      state,
      mainState.landers,
      mainState.offers
    ),
    pageGroups: state.data,
    isFetching: isFetching(state),
    show: getShow(state),
    editMode: getEditMode(state),
    filters: getPageFilters(state),
    tableParams: getTableParams(state),
    quickStatsParams: mainState.quickstats[PAGE_GROUPS_CATEGORY_TYPE].params,
    additionalParams: getAdditionalParams(state),
    tableCols: tableCols(state),
    showEditModeTooltips: mainState.systemSettings.tooltips.editMode,
    loading: state.loading,
    user: mainState.user,
    userSettings: mainState.systemSettings.data,
    ...SubscriptionSelector(mainState)
  };
};

// --- PageGroups Modal Selector
export const PageGroupsModalSelector = (mainState: CompositeStore) => {
  const state = mainState.pageGroups;
  return {
    pageGroupsArray: state.data,
    landers: getAllData(mainState.landers),
    offers: getAllData(mainState.offers),
    landerCategories: getCategories(mainState.landers),
    offerCategories: getCategories(mainState.offers),
    systemSettings: mainState.systemSettings
  };
};

// --- ResetDataView Selector
export const ResetDataViewSelector = (state: CompositeStore) => ({
  funnels: getAllData(state.funnels),
  funnelsArray: state.funnels.data,
  funnelGroups: getAllData(state.funnelGroups),
  funnelGroupsArray: state.funnelGroups.data,
  trafficSources: getAllData(state.trafficsources),
  hits: getResetValue(state.resetData),
  loading: getReportingLoading(state.resetData),
  reporting: getReportingRows(state.resetData),
  switches: state.resetData.settings.switches,
  data: state.resetData.settings.data,
  URLTrackingFieldData: state.resetData.settings.URLTrackingFieldData,
  collapsibleVisibility: state.resetData.settings.collapsibleVisibility,
});

export const FunnelBuilderAddPageSelector = (state: CompositeStore) => ({
  landers: getAllData(state.landers),
  offers: getAllData(state.offers),
  categories: {
    landers: getCategories(state.landers),
    offers: getCategories(state.offers)
  },
  defaultCustomDomain: state.systemSettings.data.defaultCustomDomain,
  customDomains: state.systemSettings.domains
});

// -- Quick Stats Selector
export const QuickStatsSelector = (state: CompositeStore) => {
  const mainState = state.quickstats;
  return {
    treeSelectData: {
      funnels: getAllData(state.funnels),
      funnelGroups: getAllData(state.funnelGroups)
    },
    tableCols: mainState.tableCols,
    sortModels: mainState.sortModels,
    tableColSizes: mainState.tableColSizes,
    trafficSourcesArray: state.trafficsources.data,
    [OFFER_CATEGORY_TYPE]: {
      storeData: mainState[OFFER_CATEGORY_TYPE],
      filters: filters(mainState[OFFER_CATEGORY_TYPE]),
      params: mainState[OFFER_CATEGORY_TYPE].params,
      tableParams: mainState[OFFER_CATEGORY_TYPE].tableParams,
      settings: mainState[OFFER_CATEGORY_TYPE].settings
    },
    [FUNNEL_CATEGORY_TYPE]: {
      storeData: mainState[FUNNEL_CATEGORY_TYPE],
      reportingColumns: getReportingColumns(mainState[FUNNEL_CATEGORY_TYPE]),
      filters: filters(mainState[FUNNEL_CATEGORY_TYPE]),
      params: mainState[FUNNEL_CATEGORY_TYPE].params,
      tableParams: mainState[FUNNEL_CATEGORY_TYPE].tableParams,
      settings: mainState[FUNNEL_CATEGORY_TYPE].settings
    },
    [LANDER_CATEGORY_TYPE]: {
      storeData: mainState[LANDER_CATEGORY_TYPE],
      filters: filters(mainState[LANDER_CATEGORY_TYPE]),
      reportingColumns: getReportingColumns(mainState[LANDER_CATEGORY_TYPE]),
      params: mainState[LANDER_CATEGORY_TYPE].params,
      tableParams: mainState[LANDER_CATEGORY_TYPE].tableParams,
      settings: mainState[LANDER_CATEGORY_TYPE].settings
    },
    [TRAFFIC_SOURCE_CATEGORY_TYPE]: {
      storeData: mainState[TRAFFIC_SOURCE_CATEGORY_TYPE],
      filters: filters(mainState[TRAFFIC_SOURCE_CATEGORY_TYPE]),
      reportingColumns: getReportingColumns(
        mainState[TRAFFIC_SOURCE_CATEGORY_TYPE]
      ),
      params: mainState[TRAFFIC_SOURCE_CATEGORY_TYPE].params,
      tableParams: mainState[TRAFFIC_SOURCE_CATEGORY_TYPE].tableParams,
      settings: mainState[TRAFFIC_SOURCE_CATEGORY_TYPE].settings
    },
    [QUICKSTATS_CATEGORY_TYPE]: {
      storeData: mainState[QUICKSTATS_CATEGORY_TYPE],
      filters: filters(mainState[QUICKSTATS_CATEGORY_TYPE]),
      reportingColumns: getReportingColumns(
        mainState[QUICKSTATS_CATEGORY_TYPE]
      ),
      params: mainState[QUICKSTATS_CATEGORY_TYPE].params,
      tableParams: mainState[QUICKSTATS_CATEGORY_TYPE].tableParams,
      settings: mainState[QUICKSTATS_CATEGORY_TYPE].settings
    },
    [PAGE_GROUPS_CATEGORY_TYPE]: {
      storeData: mainState[PAGE_GROUPS_CATEGORY_TYPE],
      filters: filters(mainState[PAGE_GROUPS_CATEGORY_TYPE]),
      reportingColumns: getReportingColumns(
        mainState[PAGE_GROUPS_CATEGORY_TYPE]
      ),
      params: mainState[PAGE_GROUPS_CATEGORY_TYPE].params,
      tableParams: mainState[PAGE_GROUPS_CATEGORY_TYPE].tableParams,
      settings: mainState[PAGE_GROUPS_CATEGORY_TYPE].settings
    },
    [OFFER_SOURCE_CATEGORY_TYPE]: {
      storeData: mainState[OFFER_SOURCE_CATEGORY_TYPE],
      filters: filters(mainState[OFFER_SOURCE_CATEGORY_TYPE]),
      reportingColumns: getReportingColumns(
        mainState[OFFER_SOURCE_CATEGORY_TYPE]
      ),
      params: mainState[OFFER_SOURCE_CATEGORY_TYPE].params,
      tableParams: mainState[OFFER_SOURCE_CATEGORY_TYPE].tableParams,
      settings: mainState[OFFER_SOURCE_CATEGORY_TYPE].settings
    },
    [CAMPAIGN_ANALYSIS_CATEGORY_TYPE]: {
      storeData: mainState.campaignanalysis,
      filters: filters(mainState.campaignanalysis),
      reportingColumns: getReportingColumns(mainState.campaignanalysis),
      params: mainState.campaignanalysis.params,
      tableParams: mainState.campaignanalysis.tableParams,
      settings: mainState.campaignanalysis.settings
    },
    user: state.user,
    userSettings: state.systemSettings.data,
    ...SubscriptionSelector(state)
  };
};

export const SystemSettingsSelector = (state: CompositeStore) => ({
  settings: state.systemSettings.data,
  domains: state.systemSettings.domains,
  showEditModeTooltips: state.systemSettings.tooltips.editMode,
  user: state.user
});

export const TrackingCodesSelector = (state: CompositeStore) => ({
  domains: state.systemSettings.domains,
  systemSettings: state.systemSettings
});

export const ApiAccessSelector = (state: CompositeStore) => ({
  accessToken: state.user.tokens.accessToken!,
  refreshToken: state.user.tokens.refreshToken!
});

export const CustomEventMappingSelector = (state: CompositeStore) => ({
  
});

export const RoadmapSelector = (state: CompositeStore) => ({
  user: state.user
});

export const IntegrationsSelector = (
  state: CompositeStore
) => ({
  data: state.integrations.data,
  userSettings: state.systemSettings.data
});

// --- ConditionsView Selector
export const ConditionsViewSelector = (mainState: CompositeStore) => {
  const state = mainState.conditions;
  return {
    conditions: getConditionsData(state),
    isFetching: isFetching(state),
    editMode: getEditMode(state),
    showEditModeTooltips: mainState.systemSettings.tooltips.editMode,
    user: mainState.user,
    ...SubscriptionSelector(mainState)
  };
};

// --- ConditionDeleteModal Selector
export const ConditionDeleteModalSelector = (state: CompositeStore) => {
  return {
    funnels: getAllData(state.funnels)
  };
};

// --- Heatmap Selector
export const HeatmapSelector = (state: CompositeStore) => {
  return {
    heatmap: state.builder.heatmap,
    trafficsources: getAllData(state.trafficsources)
  };
};

// --- UpdateTrafficCosts Selector
export const UpdateTrafficCostsSelector = (mainState: CompositeStore) => {
  return {
    funnelGroupsArray: mainState.funnelGroups.data,
    funnelsArray: mainState.funnels.data,
    trafficsources: getActiveEntities(getAllData(mainState.trafficsources)),
    campaigns: getSelectOptions(mainState.updateTrafficCosts),
    data: mainState.updateTrafficCosts.data,
    showRestrictByAttributes:
      mainState.updateTrafficCosts.settings.showRestrictByAttributes,
    restrictToSingleCampaignId:
      mainState.updateTrafficCosts.settings.restrictToSingleCampaignId,
      updateType: mainState.updateTrafficCosts.settings.updateType,
    campaign: mainState.updateTrafficCosts.campaign.value,
    restrictByAttributes:
      mainState.updateTrafficCosts.settings.restrictByAttributes,
    includeCampaignIds:
      mainState.updateTrafficCosts.settings.includeCampaignIds,
    costType: mainState.updateTrafficCosts.settings.costType,
    restrictToTrackingFields:
      mainState.updateTrafficCosts.settings.restrictToTrackingFields,
    simpleModeCost: mainState.updateTrafficCosts.settings.simpleModeCost,
    selectedColumns: mainState.updateTrafficCosts.settings.selectedColumns,
    rowData: mainState.updateTrafficCosts.rowData
  };
};

// --- UpdateConversions Selector
export const UpdateConversionsSelector = (mainState: CompositeStore) => {
  return {
    data: mainState.updateConversions.data
  };
};

export const RestrictDataSelector = (mainState: CompositeStore) => {
  return {
    trafficSource: getAllData(mainState.trafficsources),
    offerSource: getAllData(mainState.offersources),
    funnels: getAllData(mainState.funnels),
    landers: getAllData(mainState.landers),
    offers: getAllData(mainState.offers),
    landerCategory: getCategories(mainState.landers),
    offerCategory: getCategories(mainState.offers),
    funnelGroups: getAllData(mainState.funnelGroups),
    restrictData: mainState.reportings.settings.params.restrictData
  };
};

export const SubscriptionBillingSelector = (mainState: CompositeStore) => {
  return {
    user: mainState.user,
    quota: mainState.billing.quota,
    ...SubscriptionSelector(mainState)
  };
};

export const HitsViewSelector = (mainState: CompositeStore) => {
  const state = mainState.hits;
  return {
    hits: state.report,
    settings: state.settings,
    isFetching: isFetching(state),
    tableCols: tableCols(state),
    funnels: getActiveEntities(getAllData(mainState.funnels)),
    trafficSources: getActiveEntities(getAllData(mainState.trafficsources)),
    landers: getAllData(mainState.landers),
    offers: getAllData(mainState.offers),
    landerCategories: getCategories(mainState.landers),
    offerCategories: getCategories(mainState.offers),
    offerSources: getAllData(mainState.offersources),
    funnelGroupsArray: mainState.funnelGroups.data,
    funnelsArray: mainState.funnels.data,
    userSettings: mainState.systemSettings.data
  };
};

export const CampaignAnalysisViewSelector = (mainState: CompositeStore) => {
  const state = mainState.campaignAnalysis;
  return {
    reporting: getCampaignAnalysisReporting(state, false),
    tableParams: getTableParams(state),
    tableCols: tableCols(state),
    show: getShow(state),
    editMode: getEditMode(state),
    filters: getFilters(state),
    userSettings: mainState.systemSettings.data,
    additionalParams: getAdditionalParams(state),
    isFetching: isReportingFetching(state),
    quickStatsParams:
      mainState.quickstats[CAMPAIGN_ANALYSIS_CATEGORY_TYPE].params,
    ...SubscriptionSelector(mainState)
  };
};

export const KeywordRotationLinksSelector = (mainState: CompositeStore) => ({
  funnelsArray: mainState.funnels.data,
  funnelGroups: getAllData(mainState.funnelGroups),
  funnelGroupsArray: mainState.funnelGroups.data,
  trafficSourcesArray: mainState.trafficsources.data,
  domains: mainState.systemSettings.domains,
  data: mainState.keywordRotationLinks.data
})

export const GoogleAdsSelector = (mainState: CompositeStore) => ({
  funnelfluxUser: mainState.user,
  allUsers: mainState.googleAds.users.allUsers,
  customerClients: mainState.googleAds.users.customerClients,
  currentUser: mainState.googleAds.users.currentUser,
  currentCustomerClient: mainState.googleAds.users.currentCustomerClient,
  trafficSources: getAllDataById(mainState.trafficsources),
  tableCols: tableCols(mainState.googleAds),
  reportings: getReportings(mainState.googleAds),
  reportingsInitial: mainState.googleAds.reportingInitial,
  dateRangeName: mainState.googleAds.settings.params.dateRangeName,
  tableParams: mainState.googleAds.settings.tableParams,
  primaryDataGrouping: mainState.googleAds.settings.params.primaryDataGrouping,
  optionalSegmentation: mainState.googleAds.settings.params.optionalSegmentation,
  trafficSourceId: mainState.googleAds.settings.params.trafficSourceId,
  filterByStatus: mainState.googleAds.settings.params.filterByStatus,
  userSettings: mainState.systemSettings.data
})

export const FacebookAdsSelector = (mainState: CompositeStore) => ({
  funnelfluxUser: mainState.user,
  adAccounts: mainState.facebookAds.adAccounts,
  users: mainState.facebookAds.users,
  trafficSources: getAllDataById(mainState.trafficsources),
  tableCols: tableCols(mainState.facebookAds),
  reportings: getReportings(mainState.facebookAds),
  reportingsInitial: mainState.facebookAds.reportingInitial,
  dateRangeName: mainState.facebookAds.settings.params.dateRangeName,
  tableParams: mainState.facebookAds.settings.tableParams,
  trafficSourceId: mainState.facebookAds.settings.params.trafficSourceId,
  adAccount: mainState.facebookAds.settings.params.adAccount,
  user: mainState.facebookAds.settings.params.user,
  primaryDataGrouping: mainState.facebookAds.settings.params.primaryDataGrouping,
  optionalSegmentation: mainState.facebookAds.settings.params.optionalSegmentation,
  userSettings: mainState.systemSettings.data
})

export const BuilderModifyAction = (mainState: CompositeStore) => ({
  customEventAliases: mainState.systemSettings.data.customEventAliases || defaultCustomEventAliases
})
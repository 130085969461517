import React from 'react';
import Icon from 'components/Icons';

export const TextedIcon = ({
  text,
  className,
  onClick,
  type = 'flux-addnew'
}: {
  text: string;
  className?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  type?: string;
}) => (
  <div
    className={`flex flex-align-center flex-gap-10 cursor-pointer ${className}`}
    onClick={onClick}
  >
    <Icon type={type} />
    <span>{text}</span>
  </div>
);

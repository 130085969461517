import { SUSPENSE_FALLBACK, SUSPENSE_PRELOADER } from 'constants/progressBar';
import { defined } from '../define';

export class progressBar {
  private id: string;
  private idParent: string;
  private isLoad: boolean;
  private element: HTMLElement | null;
  private interval: any;

  public finishLoading: boolean;

  constructor(id: string, idParent: string = '') {
    this.id = id;
    this.idParent = idParent;
    this.isLoad = false;
    this.element = null;
    this.finishLoading = false;
  }

  setWidth = (width: string | number) => {
    if (this.element !== null) {
      this.element.style.width = `${width}%`;
    }
  };

  clear = () => {
    clearInterval(this.interval);
  };

  start = (idFlag: string = '') => {
    const interval = setInterval(() => {
      const element =
        this.element !== null ? this.element : document.getElementById(this.id);
      const flagElement =
        idFlag !== '' ? document.getElementById(idFlag) : null;

      if (
        (flagElement !== null || flagElement === null || idFlag === '') &&
        element !== null &&
        !this.isLoad
      ) {
        this.isLoad = true;
        clearInterval(interval);

        this.element = element;
        this.addClass(this.idParent, 'visible');

        let width = 0;
        let level = 1;

        this.interval = setInterval(() => {
          width = width + 11 / level;
          this.setWidth(width);
          level++;

          if (
            defined(this.element) &&
            this.element.classList.contains('hidden')
          ) {
            this.finish();
          }
        }, 30);
      }
    }, 200);
  };

  addClass = (id: string = '', className: string) => {
    if (id !== '') {
      const elementWrap = document.getElementById(id);
      if (elementWrap !== null) {
        elementWrap.className = '';
        elementWrap.classList.add(className);
      }
    }
  };

  finish = (width: number = 100) => {
    this.clear();
    this.setWidth(width);

    setTimeout(() => {
      if (this.element !== null) {
        this.element.classList.add('hidden');
        this.element.classList.remove('visible');
      }
    }, 1000);
  };

  finishOnElementRemove = (idFlag: string) => {
    const interval = setInterval(() => {
      const element = document.getElementById(idFlag);

      if (element === null && this.isLoad) {
        this.finishLoading = true;
        this.addClass(this.idParent, 'hidden');
        this.finish(0);
        clearInterval(interval);
        this.isLoad = false;
        this.element = null;
      }
    }, 200);
  };
}

export const progressBarPreloader = new progressBar('preloader');
export const innerProgressBarPreloader = new progressBar(
  SUSPENSE_PRELOADER,
  SUSPENSE_FALLBACK
);

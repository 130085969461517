import { User } from 'model/user';
import { gistUtils, loadGistScript } from 'widgets/gist';
import { identifySegment, loadSegmentScript } from 'widgets/segment';
import { defined } from '../define';
import { SubscriptionStatus } from 'model/subscription';
import { definedObject } from 'utils/define';

export const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    if (a === b) {
      continue;
    }
    return a > b || isNaN(b);
  }
  return false;
};

//export const isProdEnvironment = process.env.NODE_ENV === 'production';
export const isProdEnvironment = window.location.hostname === 'ui.funnelflux.pro';
//export const isDevEnvironment = process.env.NODE_ENV === 'development';
export const isDevEnvironment = window.location.hostname === 'localhost';
export const isTestEnvironment =
  (defined(window.localStorage.store) && window.localStorage.store.includes('"accessToken":"mocked","idToken":"mocked"')) ||
  // @ts-ignore
  defined(window.puppeteer);

export const loadGistAndSegmentScript = (user: User) => {
  if (user?.tokens?.accessToken) {
    loadSegmentScript();
    loadGistScript();
    gistUtils.identify(user);
    identifySegment(user);
    requestAnimationFrame(gistUtils.trackPageView);
  }
};

let globalHotkeysScope = 'page';
export const hotkeysGlobalScope = {
  setScope: {
    setModalScope() {
      globalHotkeysScope = 'modal';
    },
    setSidebarScope() {
      globalHotkeysScope = 'sidebar';
    },
    setPageScope() {
      globalHotkeysScope = 'page';
    },
    setQSOverlayScope() {
      globalHotkeysScope = 'QsOverlay';
    }
  },
  getScope: {
    isModalScope() {
      return globalHotkeysScope === 'modal';
    },
    isSidebarScope() {
      return globalHotkeysScope === 'sidebar';
    },
    isPageScope() {
      return globalHotkeysScope === 'page';
    }
  }
};

export const checkVersion = (): Promise<{ hash: string }> =>
  new Promise((resolve, reject) => {
    fetch(`${window.location.origin}/api/version`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok' + response.statusText);
        }
        resolve(response.json());
      })
      .catch(error => {
        reject(error);
      });
  });

export const isSubscriptionExpired = (user: User) => 
  definedObject(user) ? user.metadata?.subscription_status === 'cancelled' : false;

export const isSubscriptionStandby = (user: User) =>
  definedObject(user) ? user.metadata?.plan_id === 'standby' && user.metadata?.subscription_status !== 'cancelled' : false;

export const isStandByAndExpired = (subscriptionStatus: SubscriptionStatus) =>
  subscriptionStatus.isStandby && subscriptionStatus.isExpired;

export const isStandByAndNotExpired = (subscriptionStatus: SubscriptionStatus) =>
  subscriptionStatus.isStandby && !subscriptionStatus.isExpired;

export const isNotStandByAndExpired = (subscriptionStatus: SubscriptionStatus) =>
  !subscriptionStatus.isStandby && subscriptionStatus.isExpired;

export const isJustStandBy = (subscriptionStatus: SubscriptionStatus) => 
  subscriptionStatus.isStandby;

export const isJustExpired = (subscriptionStatus: SubscriptionStatus) =>
  subscriptionStatus.isExpired;

export const isStandByOrExpired = (subscriptionStatus: SubscriptionStatus) => 
  subscriptionStatus.isStandby || subscriptionStatus.isExpired;

export const isNotStandByAndNotExpired = (subscriptionStatus: SubscriptionStatus) =>
  !subscriptionStatus.isStandby && !subscriptionStatus.isExpired;

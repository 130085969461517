const className = (baseClassName: string) => {
  const blockClassName = baseClassName;

  function getClass(modifier: string | boolean, additionalClass?: string | boolean | (string | boolean)[] | { [key: string]: boolean }) {
    const baseClass = blockClassName;
    const modifiedClass = `${baseClass}__${modifier}`;
    const classes = [modifiedClass];

    if (typeof additionalClass === 'string') {
      classes.push(`${baseClass}__${modifier}--${additionalClass}`);
    }
    if (Array.isArray(additionalClass)) {
      additionalClass
        .filter(c => c)
        .forEach(c => {
          if (c) {
            classes.push(`${baseClass}__${modifier}--${c}`);
          }
        });
    }
    if (typeof additionalClass === 'object') {
      Object.keys(additionalClass)
        //@ts-ignore
        .filter(c => !!additionalClass[c])
        .forEach(c => {
          if (c) {
            classes.push(`${baseClass}__${modifier}--${c}`);
          }
        });
    }

    return classes.join(' ');
  }

  return { blockClassName, getClass };
};

export default className;

import React, { PropsWithChildren } from 'react';
import className from 'utils/style/className';
import VisibilityWrapper from '../VisibilityWrapper';
import { defined } from 'utils/define';
import './style.scss';

const { blockClassName, getClass } = className('c-ffSection');

const Section = ({
  children,
  innerTitle,
  outerTitle,
  outerActions,
  innerActions,
  className
}: PropsWithChildren<{
  innerTitle?: string | React.ReactNode;
  outerTitle?: string | React.ReactNode;
  className?: string;
  outerActions?: React.ReactNode;
  innerActions?: React.ReactNode;
}>) => {
  return (
    <div className={[blockClassName, className].join(' ')}>
      <VisibilityWrapper visible={defined(outerTitle) || defined(outerActions)}>
        <div className={getClass('header')}>
          {outerTitle && (typeof outerTitle === 'string' ? <h2 className={getClass('title')}>{outerTitle}</h2> : outerTitle)}
          {outerActions && <div className={getClass('actions')}>{outerActions}</div>}
        </div>
      </VisibilityWrapper>
      <div className={getClass('box')}>
        <VisibilityWrapper visible={defined(innerTitle) || defined(innerActions)}>
          <div className={getClass('header')}>
            {innerTitle && (typeof innerTitle === 'string' ? <h3 className={getClass('title')}>{innerTitle}</h3> : innerTitle)}
            {innerActions && <div className={getClass('actions')}>{innerActions}</div>}
          </div>
        </VisibilityWrapper>
        <div className={getClass('body')}>{children}</div>
      </div>
    </div>
  );
};

export default Section;

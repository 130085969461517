import { ModalsConsumer } from 'components/Modals/context';
import React from 'react';
import FluxModal from 'components/Modal';
import { CONFIRM_MODAL } from 'constants/modal';
import { ModalProps as ModalType } from '../types';
import { ConfirmModalProps } from 'types/ModalForms/confirm';
import { FormContextModal } from '../../../types/modal';
import ConfirmForm from 'components/Forms/Confirm';
import './style.scss';

const Modal = ({ isOpen, closeSelf, context }: ConfirmModalProps) => (
  <FluxModal
    width={context.width ? context.width : 400}
    title={context.title!}
    isOpen={isOpen}
    classNames={['confirm-modal']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    zIndex={context.zIndex}
    destroyOnClose={true}
    render={() => (
      <ConfirmForm
        text={context.content!}
        okText={context.okText || 'Yes'}
        cancelText={context.cancelText || 'No'}
        handleSubmit={context.onConfirm || (() => {})}
        withHoldSubmit={context.withHoldSubmit}
        actions={{
          onOk: () => {},
          onClose: () => {
            if (!!context.onCancel) {
              context.onCancel();
            }
            closeSelf();
          }
        }}
      />
    )}
  />
);

export const ConfirmModal = () => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalType) => (
        <>
          <Modal
            isOpen={isOpenModal(CONFIRM_MODAL)}
            closeSelf={() => closeModal(CONFIRM_MODAL)}
            context={getContextModal(CONFIRM_MODAL) as FormContextModal}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

import { builder as defaultState } from './defaultStates';
import { BuilderStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { BUILDER } from '../types';

const zooms = (state = defaultState.zooms, action: AnyAction) => {
  switch (action.type) {
    case BUILDER.SET_ZOOM:
      return { ...state, [action.payload.idFunnel]: action.payload.value };
    default:
      return state;
  }
};

const pans = (state = defaultState.pans, action: AnyAction) => {
  switch (action.type) {
    case BUILDER.SET_PAN:
      return { ...state, [action.payload.idFunnel]: action.payload.value };
    default:
      return state;
  }
};

const heatmap = (state = defaultState.heatmap, action: AnyAction) => {
  switch (action.type) {
    case BUILDER.SET_HEATMAP_VALUES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default (state = defaultState, action: any): BuilderStore => ({
  zooms: zooms(state.zooms, action),
  pans: pans(state.pans, action),
  heatmap: heatmap(state.heatmap, action)
});

import React from 'react';
import Messages from 'components/Messages';
import { MessageProps } from 'types';
import { FormSectionBox } from 'components/Parts/Content';
import { Input, Label, Select } from 'components/Parts/Inputs';
import { FunnelGroup } from 'model/funnelGroup';
import { getActiveEntities } from 'utils/model';
import { FunnelGroupArray } from 'types/redux/store';
import { IconTooltip } from 'components/Parts/Tooltip';
import { removeCommaOrMultipleDot } from 'utils/string';
import { SimpleFlow } from 'model/simpleFlow';

interface Props extends MessageProps {
  funnelGroupsArray: FunnelGroupArray;
  model: SimpleFlow;
  onChange: <T extends SimpleFlow, P extends keyof T>(
    key: P,
    value: T[P]
  ) => void;
  validateClassName: (field: string) => string;
  validateTooltip: (field: string) => string;
}

class SimpleFlowsGeneralSettings extends React.Component<Props, {}> {
  render() {
    return (
      <form className="cform-simpleFlows__generalSettings">
        <FormSectionBox title="What are Simple Flows?">
          <p>
            Simple flows allow you to create sequences of pages (a path) and
            then define rules for how traffic should be routed to these.
          </p>
          <p>
            Get started in the paths section by creating a sequence of pages.
            Then go to the routing section and set the default route to go to
            your path newly created path. You can add custom rules and paths
            later.
          </p>
        </FormSectionBox>
        <FormSectionBox title="General Settings">
          <div
            className={`flex flex-col flex-1 margin-bottom-10 ${this.props.validateClassName(
              'idFunnelGroup'
            )}`}
          >
            <Label htmlFor="funnelGroup-name" text="Parent Funnel Group" />
            <Select
              value={this.props.model.idFunnelGroup}
              id="funnelGroup-name"
              data-testid="funnelGroup-name"
              name="funnelGroup-name"
              placeholder={'Select parent funnel group'}
              options={getActiveEntities(
                Object.values(this.props.funnelGroupsArray)
              )}
              valueGetter={(option: FunnelGroup) => option.idCampaign}
              labelGetter={(option: FunnelGroup) => option.campaignName}
              onChange={(value: string) =>
                this.props.onChange('idFunnelGroup', value)
              }
              showSearch={true}
              filterOption={true}
            />
          </div>
          <div
            className={`flex flex-col flex-1 ${this.props.validateClassName(
              'flowName'
            )}`}
          >
            <Label htmlFor="flow-name" text="Simple Flow Name" />
            <Input
              value={this.props.model.flowName}
              id="flow-name"
              data-testid="flow-name"
              error={this.props.validateTooltip('flowName')}
              name="flow-name"
              placeholder="Enter simple flow name"
              onChange={e => this.props.onChange('flowName', e.target.value)}
            />
          </div>
        </FormSectionBox>
        <FormSectionBox title="Optional Settings">
          <Label
            htmlFor="costPerEnt"
            text={[
              `Default Cost per Entrance`,
              <IconTooltip
                key="costPerEnt"
                title="Default Cost per Entrance"
                className="funnel-group-default-cost-tooltip"
                body={[
                  <p>
                    If you add a value here, all funnels in this group will by
                    default inherit this when generating links, unless a cost is
                    added at a more specific level. A cost is assigned to every
                    visitor who enters a funnel (basically a cost per click).
                  </p>,
                  <p>
                    When generating links for your funnel, we check for default
                    costs in the following order:
                  </p>,
                  <ul>
                    <li>Funnel Group (can only add a cost value)</li>
                    <li>Funnel (can only add a cost value)</li>
                    <li>Traffic Source (can use values or tokens/text)</li>
                    <li>Cost field in the link generation form</li>
                  </ul>,
                  <p>These parameters are appended to URLs with …&c=X</p>
                ]}
              />
            ]}
          />
          <Input
            id="defaultCost"
            name="defaultCost"
            data-testid="defaultCost"
            placeholder="E.g. 0.01"
            value={this.props.model.defaultCost}
            style={{ width: 215 }}
            onChange={e =>
              this.props.onChange(
                'defaultCost',
                removeCommaOrMultipleDot(e.target.value)
              )
            }
          />
        </FormSectionBox>
      </form>
    );
  }
}

export default Messages.injectIn(SimpleFlowsGeneralSettings);

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import { FUNNELGROUP_DUPLICATE_MODAL, FUNNELGROUP_MODAL } from 'constants/modal';
import { connect } from 'react-redux';
import { FunnelGroupsModalSelector } from 'redux/selectors';
import { getModalTitle } from 'utils/modals';
import { FormContextSidebar } from 'types/modal';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import {
  addFunnelGroup,
  duplicateFunnelGroup,
  getFunnelGroupById,
  updateFunnelGroup
} from 'redux/actions/funnelGroups';
import {
  FunnelGroupFormProps,
  FunnelGroupSidebarProps
} from 'types/ModalForms/funnelGroup';
import FunnelGroupForm from 'components/Forms/FunnelGroup';
import { getDuplicateModalSidebarContext } from '../../../utils/copy/index';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import {
  FUNNELGROUP_FORM_TAB,
  FUNNELGROUP_FORM_OPTIONAL_SETTINGS_TAB
} from '../../../constants/dynamicSidebar';
import { AnyObject } from 'types';
import { FFIcon } from 'uikit';

class FunnelGroupFormWrapper extends React.Component<
  FunnelGroupFormProps & FunnelGroupSidebarProps & LoadingProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen!}
        dataPortalKey="funnelGroup"
        hasTab={true}
        onClose={this.props.closeSelf}
        defaultOpenSideBarKey="funnelGroupForm"
        defaultActiveTab={FUNNELGROUP_FORM_TAB}
        zIndex={this.props.zIndex}
        sideBars={[
          {
            key: 'funnelGroupForm',
            tabs: {
              [FUNNELGROUP_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [FUNNELGROUP_FORM_OPTIONAL_SETTINGS_TAB]: {
                title: 'Optional Settings',
                icon: <FFIcon name="advancedSettings" />
              }
            },
            title: `${getModalTitle(this.props.contextModal)} Funnel Group`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setLoading,
              setForCopyIdsProps,
              sidebarLoading,
              tabTitle
            }) => {
              return (
                <>
                  <FunnelGroupForm
                    {...this.props}
                    activeTab={activeTab}
                    setButtonGroupProps={setButtonGroupProps}
                    setSidebarLoading={setLoading}
                    sidebarLoading={sidebarLoading}
                    tabTitle={tabTitle}
                    setForCopyIdsProps={setForCopyIdsProps}
                  />
                </>
              );
            }
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(FunnelGroupFormWrapper);

const ConnectedFormElement = ({
  funnelGroups,
  addFunnelGroup,
  updateFunnelGroup,
  duplicateFunnelGroup,
  getFunnelGroupById
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        openSidebar,
        setContextSidebar
      }: SidebarProps) => {
        let modalName = FUNNELGROUP_MODAL;
        let contextSidebar = getContextSidebar(
          FUNNELGROUP_MODAL
        ) as FormContextSidebar;

        if (isOpenSidebar(FUNNELGROUP_DUPLICATE_MODAL)) {
          modalName = FUNNELGROUP_DUPLICATE_MODAL;
          contextSidebar = getContextSidebar(
            FUNNELGROUP_DUPLICATE_MODAL
          ) as FormContextSidebar;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            funnelGroups
          );
        }

        const isOpen =
          isOpenSidebar(FUNNELGROUP_MODAL) ||
          isOpenSidebar(FUNNELGROUP_DUPLICATE_MODAL);

        return (
          <FormElement
            funnelGroups={funnelGroups}
            handleCreate={addFunnelGroup}
            handleUpdate={updateFunnelGroup}
            handleDuplicate={duplicateFunnelGroup}
            contextModal={contextSidebar}
            setContextModal={setContextSidebar}
            openModal={openSidebar}
            getFunnelGroupById={getFunnelGroupById}
            isOpen={isOpen}
            closeSelf={() => closeSidebar(modalName)}
            zIndex={contextSidebar.zIndex!}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(FunnelGroupsModalSelector, {
  addFunnelGroup,
  updateFunnelGroup,
  duplicateFunnelGroup,
  getFunnelGroupById
})(ConnectedFormElement);

import { conditionalClass } from 'conditional-class';
import React from 'react';
import { FFIcon, FFNewIcon } from 'uikit';
import './style.scss';

const Refresh = ({ onClick }: { onClick: () => void }) => (
  <div
  className="c-ffIconedButton__refreshButton"
  onClick={onClick}
>
  <FFNewIcon name="general/duotone/desktop-sync" size="navigation-md" className="c-ffIconedButton__refreshIcon" />
</div>
);

const DashboardRefresh = ({ onClick }: { onClick: () => void }) => (
  <div
  className="c-ffIconedButton__refreshButton--dashboard"
  onClick={onClick}
>
  <FFNewIcon name="general/duotone/desktop-sync" size="navigation-md" className="c-ffIconedButton__refreshIcon" />
</div>
);

const CircleDelete = ({ onClick }: { onClick: () => void }) => (
  <div className="c-ffIconedButton__delete" onClick={onClick}>
    <FFIcon name="delete" />
  </div>
);

const Edit = ({
  onClick,
  disabled = false
}: {
  onClick: () => void;
  disabled?: boolean;
}) => (
  <div
    className={conditionalClass('c-ffIconedButton__edit', {
      'c-ffIconedButton__edit--disabled': disabled
    })}
    onClick={onClick}
  >
    <FFIcon name="edit" />
  </div>
);

const Trash = ({
  onClick,
  disabled = false
}: {
  onClick: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}) => (
  <div
    className={conditionalClass('c-ffIconedButton__trash', {
      'c-ffIconedButton__trash--disabled': disabled
    })}
    onClick={onClick}
  >
    <FFIcon name="trash" />
  </div>
);

const Filter = ({
  onClick,
  disabled = false
}: {
  onClick: () => void;
  disabled?: boolean;
}) => (
  <div
    className={conditionalClass('c-ffIconedButton__filter', {
      'c-ffIconedButton__filter--disabled': disabled
    })}
    onClick={onClick}
  >
    <FFIcon name="tableFilter" />
  </div>
);

const FFIconedButton = () => <></>;

FFIconedButton.Refresh = Refresh;
FFIconedButton.CircleDelete = CircleDelete;
FFIconedButton.Edit = Edit;
FFIconedButton.Trash = Trash;
FFIconedButton.Filter = Filter;

export default FFIconedButton;
export { Refresh, DashboardRefresh, CircleDelete, Edit, Trash, Filter };

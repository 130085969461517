import React from 'react';

import { Modal } from 'antd';
import Icon from 'components/Icons';

import './styled.components.scss';
import { DEFAULT_MODAL_Z_INDEX } from 'constants/modal';
import { ModalActionsType } from 'types';

interface HeaderInterface {
  title: string | JSX.Element;
  onClose: () => void;
}

interface Props {
  key?: string;
  style?: object;
  width: number | 'auto';
  actions: ModalActionsType;
  footer?: any;
  center?: any;
  isOpen: boolean;
  classNames?: string[];
  closable?: boolean;
  render: () => any;
  title: string | JSX.Element;
  maskClosable?: boolean;
  zIndex?: number;
  destroyOnClose?: boolean;
  wrapClassName?: string;
  mask?: boolean;
}

const Header = ({ title, onClose }: HeaderInterface) => (
  <div className="flux-header" data-testid="flux-header">
    {title}
    <Icon
      type="flux-close"
      onClick={() => onClose()}
      style={{
        color: `rgba(126, 142, 173, 0.5)`
      }}
    />
  </div>
);

export default ({
  key,
  style = {},
  width = 500,
  actions = {
    onOk: e => {},
    onClose: e => {}
  },
  footer = null,
  center = true,
  isOpen = false,
  classNames = [],
  closable = false,
  render = () => null,
  title = `FunnelFlux Pro Modal`,
  maskClosable = false,
  zIndex = DEFAULT_MODAL_Z_INDEX,
  destroyOnClose = false,
  wrapClassName = undefined,
  mask = undefined
}: Props) => (
  <Modal
    key={key}
    width={width}
    style={style}
    footer={footer}
    open={isOpen}
    centered={center}
    closable={closable}
    maskClosable={maskClosable}
    destroyOnClose={destroyOnClose}
    zIndex={zIndex}
    className={classNames.join(' ')}
    onCancel={actions.onClose}
    wrapClassName={wrapClassName}
    mask={false}
    title={<Header title={title} onClose={actions.onClose} />}
  >
    {render()}
  </Modal>
);

/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Postback { 
    idTrafficSource: string;
    postbackType: Postback.PostbackTypeEnum;
    /**
     * DEPRECATED
     */
    postbackCode?: string;
    postbackURL?: string;
    postbackHTML?: string;
}
export namespace Postback {
    export type PostbackTypeEnum = 'none' | 'postbackURL' | 'html';
    export const PostbackTypeEnum = {
        None: 'none' as PostbackTypeEnum,
        PostbackURL: 'postbackURL' as PostbackTypeEnum,
        Html: 'html' as PostbackTypeEnum,
    };
}
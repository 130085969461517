import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import { withLoading } from 'components/Loading';
import Swap from '../../../Forms/Builder/Swap';
import { SidebarsConsumer } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import { BUILDER_SWAP_GROUP_MODAL } from 'constants/modal';
import {
  SwapPagesFormProps,
  SwapPagesSidebarProps
} from 'types/ModalForms/swapPages';

class SwapPagesFormWrapper extends React.Component<
  SwapPagesFormProps & SwapPagesSidebarProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        isOpen={this.props.isOpen!}
        loading={{}}
        hasTab={true}
        onClose={this.props.closeSelf}
        dataPortalKey="swapPages"
        defaultOpenSideBarKey="swapPages"
        zIndex={this.props.zIndex}
        sideBars={[
          {
            key: 'swapPages',
            title: 'Swap',
            wrapperClassName: 'swap-page',
            render: ({ setButtonGroupProps }) => {
              return (
                <Swap
                  {...this.props}
                  contextSidebar={this.props.contextSidebar}
                  closeSelf={this.props.closeSelf}
                  isOpen={this.props.isOpen}
                  setButtonGroupProps={setButtonGroupProps}
                />
              );
            }
          }
        ]}
      />
    );
  }
}

export const FormElement = withLoading(SwapPagesFormWrapper);

export default () => (
  <SidebarsConsumer>
    {({
      isOpenSidebar,
      getContextSidebar,
      closeSidebar,
      getOffsetRight
    }: SidebarProps) => {
      return (
        <FormElement
          isOpen={isOpenSidebar(BUILDER_SWAP_GROUP_MODAL)}
          contextSidebar={getContextSidebar<
            SwapPagesFormProps['contextSidebar']
          >(BUILDER_SWAP_GROUP_MODAL)}
          offsetRight={getOffsetRight(BUILDER_SWAP_GROUP_MODAL)}
          closeSelf={() => closeSidebar(BUILDER_SWAP_GROUP_MODAL)}
          zIndex={getContextSidebar(BUILDER_SWAP_GROUP_MODAL).zIndex!}
        />
      );
    }}
  </SidebarsConsumer>
);

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="file-folder--work-office-company-folder-supplies-file">
      <path
        id="Subtract"
        fill="#ffffff"
        d="M6.969510621428571 10.033299535714285h4.201502035714285c0.22303962857142856 0.0013935 0.4394103642857143 0.07622445 0.6156482999999999 0.21296661428571428 0.17625784285714283 0.13674216428571428 0.3025686642857143 0.3277512 0.35942346428571426 0.5435048142857143l0.7959671999999999 3.224957142857143 12.939224807142857 0V1.9907142857142857c0 -0.26398464 -0.10491064285714284 -0.5171577107142856 -0.29144057142857144 -0.7038230078571428C25.403106857142856 1.10022399 25.149887999999997 0.9953571428571428 24.885919285714284 0.9953571428571428H7.964867764285714c-0.26398862142857143 0 -0.5171676642857144 0.10486684714285714 -0.7038369428571428 0.29153413499999997C7.07438145 1.473556575 6.969510621428571 1.7267296457142858 6.969510621428571 1.9907142857142857v8.04258525Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M1.8184159735714285 25.08319907142857 1.0025495935714286 11.148099535714286c-0.01696486714285714 -0.14038517142857143 -0.003836106428571428 -0.28276105714285715 0.03851036785714286 -0.4176717642857143s0.11293919357142856 -0.25923081428571426 0.20707808142857143 -0.3647187642857143c0.09413888785714285 -0.10546804285714285 0.20966202857142857 -0.18967525714285716 0.3388753114285714 -0.24698792142857143 0.12921328285714284 -0.05733257142857143 0.2691525342857143 -0.08643681428571429 0.4104892671428571 -0.08542154999999998h9.173510035714285c0.22303962857142856 0.0013935 0.4394103642857143 0.07622445 0.6156482999999999 0.21296661428571428 0.17625784285714283 0.13674216428571428 0.3025686642857143 0.3277512 0.35942346428571426 0.5435048142857143l0.7959671999999999 3.224957142857143h12.934447092857143c0.13755835714285714 -0.0004379571428571429 0.27392228571428573 0.027690835714285714 0.4001335714285714 0.08261464285714286 0.12621128571428572 0.05492380714285714 0.23968199999999998 0.1354482 0.3330465 0.23649685714285715 0.09356357142857143 0.10102875 0.1650302142857143 0.22037207142857143 0.21021942857142856 0.3504851571428571 0.044990142857142854 0.13009317857142857 0.06250842857142856 0.26814921428571425 0.051559499999999994 0.4053890571428571l-0.7759804285714285 9.953670964285713c-0.040212428571428566 0.4998683571428571 -0.26735292857142856 0.9660936428571428 -0.6364313571428571 1.3057095 -0.36887935714285713 0.33961585714285714 -0.8522247857142856 0.5273402142857143 -1.3534866428571428 0.5257476428571428H3.808335964285714c-0.4950309214285714 0.0023888571428571427 -0.9732004928571428 -0.17976150000000002 -1.3411641214285714 -0.5110163571428571 -0.3679437214285714 -0.3314539285714286 -0.5992587492857142 -0.7879247142857142 -0.6487558692857143 -1.2806265Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.8184159735714285 25.08319907142857 1.0025495935714286 11.148099535714286c-0.01696486714285714 -0.14038517142857143 -0.003836106428571428 -0.28276105714285715 0.03851036785714286 -0.4176717642857143s0.11293919357142856 -0.25923081428571426 0.20707808142857143 -0.3647187642857143c0.09413888785714285 -0.10546804285714285 0.20966202857142857 -0.18967525714285716 0.3388753114285714 -0.24698792142857143 0.12921328285714284 -0.05733257142857143 0.2691525342857143 -0.08643681428571429 0.4104892671428571 -0.08542154999999998h9.173510035714285c0.22303962857142856 0.0013935 0.4394103642857143 0.07622445 0.6156482999999999 0.21296661428571428 0.17625784285714283 0.13674216428571428 0.3025686642857143 0.3277512 0.35942346428571426 0.5435048142857143l0.7959671999999999 3.224957142857143h12.934447092857143c0.13755835714285714 -0.0004379571428571429 0.27392228571428573 0.027690835714285714 0.4001335714285714 0.08261464285714286 0.12621128571428572 0.05492380714285714 0.23968199999999998 0.1354482 0.3330465 0.23649685714285715 0.09356357142857143 0.10102875 0.1650302142857143 0.22037207142857143 0.21021942857142856 0.3504851571428571 0.044990142857142854 0.13009317857142857 0.06250842857142856 0.26814921428571425 0.051559499999999994 0.4053890571428571l-0.7759804285714285 9.953670964285713c-0.040212428571428566 0.4998683571428571 -0.26735292857142856 0.9660936428571428 -0.6364313571428571 1.3057095 -0.36887935714285713 0.33961585714285714 -0.8522247857142856 0.5273402142857143 -1.3534866428571428 0.5257476428571428H3.808335964285714c-0.4950309214285714 0.0023888571428571427 -0.9732004928571428 -0.17976150000000002 -1.3411641214285714 -0.5110163571428571 -0.3679437214285714 -0.3314539285714286 -0.5992587492857142 -0.7879247142857142 -0.6487558692857143 -1.2806265Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.969510621428571 5.972142857142857V1.9907142857142857c0 -0.26398464 0.10487082857142857 -0.5171577107142856 0.29152019999999995 -0.7038230078571428C7.4477001000000005 1.10022399 7.700879142857143 0.9953571428571428 7.964867764285714 0.9953571428571428H24.885919285714284c0.2639687142857143 0 0.5171875714285714 0.10486684714285714 0.7039165714285714 0.29153413499999997 0.18652992857142858 0.18666529714285715 0.29144057142857144 0.43983836785714286 0.29144057142857144 0.7038230078571428v7.962857142857143"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

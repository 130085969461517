import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="remove-filter-slide">
      <path
        id="vector"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.585267442857143 1.4249931v13.870839278571427"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_2"
        fill="#d7e0ff"
        d="M24.2650155 16.074838692857142c1.3727965714285715 0 2.4858049285714285 1.1129088214285714 2.4858049285714285 2.4857651142857144 0 1.3728164785714285 -1.1130083571428573 2.485824835714286 -2.4858049285714285 2.485824835714286 -1.3727965714285715 0 -2.4858049285714285 -1.1130083571428573 -2.4858049285714285 -2.485824835714286 0 -1.3728562928571428 1.1130083571428573 -2.4857651142857144 2.4858049285714285 -2.4857651142857144Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m24.26382107142857 21.046229571428572 0 5.398817142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m24.26760342857143 1.4249931 0 14.650422899999999"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_5"
        fill="#d7e0ff"
        d="M14.921896607142857 15.937937271428572c1.3728363857142858 0 2.485745207142857 -1.1129088214285714 2.485745207142857 -2.485745207142857 0 -1.3728562928571428 -1.1129088214285714 -2.4857651142857144 -2.485745207142857 -2.4857651142857144 -1.3728562928571428 0 -2.4857651142857144 1.1129088214285714 -2.4857651142857144 2.4857651142857144 0 1.3728363857142858 1.1129088214285714 2.485745207142857 2.4857651142857144 2.485745207142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_6"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.26700621428571 16.074838692857142c1.3727965714285715 0 2.4858049285714285 1.1129088214285714 2.4858049285714285 2.4857651142857144 0 1.3728164785714285 -1.1130083571428573 2.485824835714286 -2.4858049285714285 2.485824835714286 -1.3727965714285715 0 -2.4858049285714285 -1.1130083571428573 -2.4858049285714285 -2.485824835714286 0 -1.3728562928571428 1.1130083571428573 -2.4857651142857144 2.4858049285714285 -2.4857651142857144Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_7"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.919945707142857 15.937937271428572c1.3728363857142858 0 2.4857651142857144 -1.1129088214285714 2.4857651142857144 -2.485745207142857 0 -1.3728562928571428 -1.1129287285714284 -2.4857651142857144 -2.4857651142857144 -2.4857651142857144s-2.4857651142857144 1.1129088214285714 -2.4857651142857144 2.4857651142857144c0 1.3728363857142858 1.1129287285714284 2.485745207142857 2.4857651142857144 2.485745207142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_8"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.924524349999999 1.4249931 0 9.54171255"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_9"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.932308042857143 15.937379871428572 0 10.507666842857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector_10"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.085325885714285 19.477506899999998 1.117831857857143 26.445046714285713m0 -6.967539814285715L8.085325885714285 26.445046714285713"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

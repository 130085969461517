import { jsCodes } from 'constants/javscriptCodes';
import { FunnelNode, TrafficSource } from 'model/models';
import { getByTrackingFieldSlotsQuery, getQueryUrlSeparate } from 'utils/url';

export const getPageActionLink = (domain: string, actionNumber: string) =>
  `https://${domain}/action/${!actionNumber ? 1 : actionNumber}`;

export const getCorrectActionNumber = (number: string) => {
  const numberInt = parseInt(number);
  if (numberInt <= 0) {
    return '1';
  } else if (numberInt > 255) {
    return '255';
  }
  return number;
};

// Basic code lookups from scripts file
const getGlobalJsCode = (domain: string) => {
  return jsCodes.globalCode.code.replace('{DOMAIN}', domain);
};

// Formation of complex snippets for various page sections
export const getGenericViewCodeFull = (domain: string) => {
  return (
    `${jsCodes.globalCode.preCodeComment}\n` +
    `${getGlobalJsCode(domain)}\n\n` +
    `${jsCodes.pageView.preCodeComment}\n` +
    jsCodes.pageView.eventCode
  );
};

export const getGenericViewCodeSingle = () => {
  return `${jsCodes.pageView.preCodeComment}\n${jsCodes.pageView.eventCode}`;
};


export const getGenericConversionCodeFull = (domain: string) => {
  return (
    `${jsCodes.globalCode.preCodeComment}\n` +
    `${getGlobalJsCode(domain)}\n\n` +
    `${jsCodes.genericConversion.preCodeComment}\n` +
    jsCodes.genericConversion.eventCode
  );
};

export const getGenericConversionCodeSingle = () => {
  return `${jsCodes.genericConversion.preCodeComment}\n${jsCodes.genericConversion.eventCode}`;
};

export const getCustomEventCodeSingle = () => {
  return `${jsCodes.customEvent.preCodeComment}\n${jsCodes.customEvent.eventCode}`;
};

export const getOfferSourceConversionCodeFull = (
  domain: string,
  payoutToken: string,
  postbackTxId: string
) => {
  let conversionCode = jsCodes.offerConversion.eventCode;
  if (typeof conversionCode === 'string') {
    conversionCode = conversionCode.replace(
      /\{REVENUE\}/gi,
      payoutToken || 'CONVERSION_VALUE'
    );
    conversionCode = conversionCode.replace(
      /\{TRANSACTION\}/gi,
      postbackTxId || 'OPTIONAL_TXID'
    );
  }
  return `${jsCodes.globalCode.preCodeComment}\n${getGlobalJsCode(
    domain
  )}\n\n${
    jsCodes.offerConversion.preCodeComment
  }\n${conversionCode}`;
};

export const getOfferSourceConversionCodeSingle = (
  payoutToken: string,
  postbackTxId: string,
) => {
  let conversionCode = jsCodes.offerConversion.eventCode;
  if (typeof conversionCode === 'string') {
    conversionCode = conversionCode.replace(
      /\{REVENUE\}/gi,
      payoutToken || 'CONVERSION_VALUE'
    );
    conversionCode = conversionCode.replace(
      /\{TRANSACTION\}/gi,
      postbackTxId || 'OPTIONAL_TXID'
    );
  }
  return `${jsCodes.offerConversion.preCodeComment}\n${conversionCode}`;
};

export const getOfferConversionCodeFull = (
  domain: string,
  payoutToken: string,
  postbackTxId: string,
) => {
  let conversionCode = jsCodes.offerConversion.eventCode;
  if (typeof conversionCode === 'string') {
    conversionCode = conversionCode.replace(
      /\{REVENUE\}/gi,
      payoutToken || 'CONVERSION_VALUE'
    );
    conversionCode = conversionCode.replace(
      /\{TRANSACTION\}/gi,
      postbackTxId || 'OPTIONAL_TXID'
    );
  }
  return `${jsCodes.globalCode.preCodeComment}\n${getGlobalJsCode(
    domain
  )}\n\n${
    jsCodes.offerConversion.preCodeComment
  }\n${conversionCode}`;
};

export const getOfferConversionCodeSingle = (
  payoutToken: string,
  postbackTxId: string,
) => {
  let conversionCode = jsCodes.offerConversion.eventCode;
  if (typeof conversionCode === 'string') {
    conversionCode = conversionCode.replace(
      /\{REVENUE\}/gi,
      payoutToken || 'CONVERSION_VALUE'
    );
    conversionCode = conversionCode.replace(
      /\{TRANSACTION\}/gi,
      postbackTxId || 'OPTIONAL_TXID'
    );
  }
  return `${jsCodes.offerConversion.preCodeComment}\n${conversionCode}`;
};

export const getOfferPostbackUrl = (
  domain: string,
  postbackPayout: string,
  postbackSubId: string,
  postbackTxId: string,
  idPage: string,
  withPixel: boolean
) => {
  let url = `https://${domain ? domain : ''}/pb/${withPixel ? `pixel` : ''}`;
  const paramPairs: string[] = [];

  if (withPixel) {
    paramPairs.push(`pid=${idPage}`);
  }
  if (postbackSubId) {
    paramPairs.push(`hit=${postbackSubId}`);
  }
  if (postbackTxId) {
    paramPairs.push(`tx=${postbackTxId}`);
  }
  if (postbackPayout) {
    paramPairs.push(`rev=${postbackPayout}`);
  }
  if (!paramPairs.length) {
    paramPairs.push(...['hit=HIT_ID', 'tx=OPTIONAL_TXID', 'rev=CONVERSION_VALUE']);
  }
  const params = paramPairs.join('&');

  return `${url}${getQueryUrlSeparate(params, url)}${params}`;
};

export const getJsDefaultTrackingParams = (
  idPage: string,
  idFunnel: string
) => {
  let script = `<!-- FF Pro Page Defaults -->\n<script>\nvar fluxDefaults = {\n  p: "THIS_PAGE_ID",\n  f: "DEFAULT_FUNNEL_ID_HERE"\n}\n</script>`;
  if (idPage) {
    script = script.replace("THIS_PAGE_ID", `${idPage}`);
  }
  if (idFunnel) {
    script = script.replace("DEFAULT_FUNNEL_ID_HERE", `${idFunnel}`);
  }
  return script;
};

export const getCostOnTsChange = (ts: TrafficSource, defaultCost: string) =>
  ts?.defaultCost ? ts?.defaultCost : !!defaultCost ? defaultCost : '';

export const getFunnelDirectLink = (
  domain: string,
  idFunnel: string,
  idTrafficSource: string,
  idPage: string,
  pageBaseUrl: string,
  idPageGroupNode: string,
  cost: string,
  trafficSource: TrafficSource,
  appendTsParams: boolean,
  withSeparator: boolean
) => {
  if (!idPage) {
    return 'Please select a page node first';
  }

  let urlParams = `f=${idFunnel}`;
  let defaultCost = !cost ? '0' : cost;
  const trackingFieldSlotsValues = Object.values(
    trafficSource?.trackingFieldSlots || {}
  );
  const trackingFieldSlotsQuery = getByTrackingFieldSlotsQuery(
    trackingFieldSlotsValues,
    defaultCost
  );
  const atobPageBaseUrl = atob(pageBaseUrl).replace('http://', 'https://');
  const domainUrl = atobPageBaseUrl ? atobPageBaseUrl : domain;

  if (idPageGroupNode) {
    urlParams += `&n=${idPageGroupNode}`;
  }
  if (idTrafficSource) {
    urlParams += `&ts=${idTrafficSource}`;
  }
  if (
    defaultCost !== '0' &&
    Number(defaultCost) !== 0 &&
    !trackingFieldSlotsQuery
  ) {
    urlParams += `&c=${defaultCost}`;
  }
  if (appendTsParams && trackingFieldSlotsQuery !== '') {
    urlParams += `&${trackingFieldSlotsQuery}`;
  }

  return `${domainUrl}${
    withSeparator ? getQueryUrlSeparate(urlParams, domainUrl) : ''
  }${urlParams}`;
};

export const getFunnelRedirectLink = (
  domain: string,
  funnelNodes: FunnelNode[],
  idFunnel: string,
  idTrafficSource: string,
  idNode: string,
  cost: string,
  trafficSource: TrafficSource
) => {
  if (!idTrafficSource) {
    return 'Please select a traffic source first';
  }

  let defaultCost = !cost ? '0' : cost;
  const trafficNode = funnelNodes.find(
    node => node.nodeType === 'root'
  ) as FunnelNode;
  const trackingFieldSlotsValues = Object.values(
    trafficSource?.trackingFieldSlots || {}
  );
  const trackingFieldSlotsQuery = getByTrackingFieldSlotsQuery(
    trackingFieldSlotsValues,
    defaultCost
  );

  let url = `https://${domain}/fts/${idFunnel}`;
  if (idTrafficSource) {
    url += `-${idTrafficSource}`;
  }
  if (idNode && idNode !== trafficNode?.idNode) {
    url += `/${idNode}`;
  }
  if (
    defaultCost !== '0' &&
    Number(defaultCost) !== 0 &&
    !trackingFieldSlotsQuery
  ) {
    url += `${getQueryUrlSeparate(cost, url)}c=${cost}`;
  }
  if (trackingFieldSlotsQuery) {
    url += `${getQueryUrlSeparate(
      trackingFieldSlotsQuery,
      url
    )}${trackingFieldSlotsQuery}`;
  }

  return url;
};

import { conditions as defaultState } from './defaultStates';
import { ConditionAction } from '../../types/redux/action';
import { API, CONDITIONS, TABLE, VIEW } from '../types';
import { defined } from '../../utils/define';
import { ConditionArray, ConditionStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { Category } from '../../model/category';
import { FunnelCondition } from '../../model/funnelCondition';
import { FunnelConditionCategoryInfo } from 'model/funnelConditionCategoryInfo';
import { infoToResource } from 'utils/redux';
import { FunnelConditionInfo } from 'model/funnelConditionInfo';

const data = (state = defaultState.data, action: ConditionAction) => {
  switch (action.type) {
    case CONDITIONS.SET_LIST:
      if (defined(action.payload)) {
        return (action.payload as FunnelCondition[]).reduce(
          (acc: ConditionArray, condition) => {
            acc[condition.idCondition] = condition;
            return acc;
          },
          defaultState.data
        );
      }
      return state;
    case CONDITIONS.SET_CATEGORY_INFO:
      if (defined(action.payload)) {
        (action.payload as FunnelConditionCategoryInfo[]).forEach(
          conditionCategoryInfo => {
            if (conditionCategoryInfo.conditions) {
              conditionCategoryInfo.conditions.forEach(conditionInfo => {
                if (!state[conditionInfo.idCondition]) {
                  state[conditionInfo.idCondition] = infoToResource.condition(
                    conditionInfo,
                    conditionCategoryInfo
                  );
                }
              });
            }
          }
        );
      }
      return state;
    case CONDITIONS.SET_INFO:
      if (defined(action.payload)) {
        (action.payload as FunnelConditionInfo[]).forEach(conditionInfo => {
          if (!state[conditionInfo.idCondition]) {
            state[conditionInfo.idCondition] = infoToResource.condition(
              conditionInfo,
              {
                idCategory: state[conditionInfo.idCondition]?.idCategory
              }
            );
          }
        });
      }
      return state;
    case CONDITIONS.ADD_SINGLE:
    case CONDITIONS.ADD_EXISTED_SINGLE:
    case CONDITIONS.UPDATE_SINGLE:
      const payload = action.payload as FunnelCondition;
      return {
        ...state,
        [payload.idCondition]: payload
      };
    case CONDITIONS.DELETE:
      if (defined(action.payload)) {
        return Object.values(state).reduce((acc: ConditionArray, condition) => {
          if (condition.idCondition !== (action.payload as string)) {
            acc[condition.idCondition] = condition;
          }
          return acc;
        }, {});
      }
      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loading, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        isLoading: true
      };
    case API.ENDED:
      return {
        ...state,
        isLoading: false,
        fetchedAt: Date.now()
      };
    default:
      return state;
  }
};

const categories = (state = defaultState.categories, action: AnyAction) => {
  switch (action.type) {
    case CONDITIONS.SET_CATEGORY_INFO:
      if (defined(action.payload)) {
        return (action.payload as FunnelConditionCategoryInfo[]).map(data =>
          infoToResource.conditionCategory(data)
        );
      }
      return state;
    case CONDITIONS.SET_CATEGORIES:
      if (defined(action.payload) && Array.isArray(action.payload)) {
        return [...action.payload];
      }
      return state;
    case CONDITIONS.CREATE_CATEGORY:
      return [...state, ...[action.payload]];
    case CONDITIONS.UPDATE_CATEGORY:
      return state.reduce((acc: Category[], item: Category) => {
        acc.push(
          item.idCategory === action.payload.idCategory
            ? (action.payload as Category)
            : item
        );
        return acc;
      }, []);
    case CONDITIONS.DELETE_CATEGORY:
      return state.filter(item => action.payload.indexOf(item.idCategory) < 0);
    default:
      return state;
  }
};

const editMode = (
  state = defaultState.settings.editMode,
  action: AnyAction
) => {
  return action.type === TABLE.SET_EDIT_MODE &&
    action.payload.view === VIEW.CONDITIONS
    ? action.payload.data
    : state;
};

export default (state = defaultState, action: any): ConditionStore => {
  return {
    data: data(state.data, action),
    settings: {
      editMode: editMode(state.settings.editMode, action)
    },
    categories: categories(state.categories, action),
    loading: loading(state.loading, action)
  };
};

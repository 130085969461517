import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="investing-and-banking">
      <path
        id="Union"
        fill="#ffffff"
        d="M0.9948554828571429 17.522048164285714 10.299895992857142 9.350922492857142v4.587322371428571l7.262623392857143 0v6.414021707142856H10.299895992857142v5.340887357142857L0.9948554828571429 17.522048164285714Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Union_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9948554828571429 17.522048164285714 10.299895992857142 9.350922492857142v4.587322371428571l7.262623392857143 0v6.414021707142856H10.299895992857142v5.340887357142857L0.9948554828571429 17.522048164285714Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Union_3"
        fill="#d7e0ff"
        d="M26.875240071428568 10.347991649999999 17.570104007142856 18.519137228571427V13.931814857142857H10.30752042857143l0 -6.414041614285714 7.262583578571428 0V2.1768659785714286l9.305136064285714 8.171125671428571Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Union_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.875240071428568 10.347991649999999 17.570104007142856 18.519137228571427V13.931814857142857H10.30752042857143l0 -6.414041614285714 7.262583578571428 0V2.1768659785714286l9.305136064285714 8.171125671428571Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

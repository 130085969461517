import { CONFIRM_MODAL } from 'constants/modal';
import React from 'react';
import { SidebarProps } from 'types/sidebars';
import VersionNotificationContent from 'components/NotificationSidebars/Versioning';

export const getVisibilityByActiveTab = (
  key: string | string[],
  activeTab?: string
) =>
  typeof key === 'string' ? activeTab === key : key.indexOf(activeTab!) > -1;

export const asyncVersionConfirmSidebar = (sidebar: SidebarProps) =>
  new Promise((res, rej) => {
    sidebar.setContextSidebar(CONFIRM_MODAL, {
      content: <VersionNotificationContent />,
      okText: 'YES',
      cancelText: 'NO',
      title: '',
      hasHeader: true,
      onConfirm: () => {
        sidebar.closeSidebar(CONFIRM_MODAL);
        res(true);
      },
      onCancel: () => {
        rej();
      }
    });
    sidebar.openSidebar(CONFIRM_MODAL);
  });

export const hightLightTabs = () => {
  const hightLightableTabs = document.evaluate(
    '//div[contains(@class, "dynamic-sidebar-tabs-tab--highlightable")]',
    document,
    null,
    XPathResult.ANY_TYPE,
    null
  );
  let elements: HTMLElement[] = [];

  while (true) {
    const element = hightLightableTabs.iterateNext();
    if (!element) {
      break;
    }
    elements.push(element as HTMLElement);
  }

  elements.forEach(elm => {
    if (elm.classList) {
      elm.classList.add('dynamic-sidebar-tabs-tab--highlight');
      setTimeout(
        () => elm.classList.remove('dynamic-sidebar-tabs-tab--highlight'),
        1500
      );
    }
  });
};

import React, { FormEvent, RefObject } from 'react';
import Messages from 'components/Messages';
import validateForm, { customUrl, trafficSourceCostModifier, required, unique, trafficSourcePostbackProbability } from 'utils/validation';
import { Postback, TrackingField } from 'model/models';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from 'utils/reactive/generic';
import { UNCATEGORIZED } from 'constants/modal';
import { AddButton as OpenButton } from 'components/Parts/Buttons';
import { FormContentWrapper, FormHelpSection } from 'components/Parts/Content';
import { Input, Select, Label } from 'components/Parts/Inputs';
import { ModalButtonGroup } from 'components/Parts/Groups';
import { TrafficSource } from 'model/trafficSource';
import { Category } from 'model/category';
import { generateEntityId } from 'utils/id/generator';
import {
  AnyObject,
  MessageProps,
  SelectOption
} from 'types';
import {
  getAttributes,
  TRAFFIC_SOURCE_TEMPLATE as MockedTemplates
} from 'constants/templates';
import {
  trimStringPropertiesInObject,
  withoutWhiteSpace,
  removeCommaOrMultipleDot
} from 'utils/string';
import { DUPLICATE_RECORD_CODE } from 'constants/errors';
import { TrafficSourceFormProps } from 'types/ModalForms/trafficSource';
import { defined } from 'utils/define';
import './style.scss';
import { getErrorId, handleScrollToErrorElement } from 'utils/validation';
import { copy, getDuplicateModalSidebarContext } from 'utils/copy';
import { repeatObject } from 'utils/arrs';
import { moveToFirstByFunc, sortByName } from 'utils/sort';
import Icon from 'components/Icons';
import { InputSelect } from '../../Parts/InputSelect';
import { LockpadButton, UnLockpadButton } from '../../Parts/Buttons';
import { isCopyByContextModal } from 'utils/modals';
import { cloneObject } from 'utils/object';
import { getActiveEntities, withIncrementedVersion } from 'utils/model';
import { ValidationRule } from 'utils/validation/types';
import {
  asyncVersionConfirmSidebar,
  getVisibilityByActiveTab,
  hightLightTabs
} from 'utils/dynamic-sidebar';
import { LoadingProps } from 'types/loading';
import {
  TRAFFICSOURCE_FORM_TAB,
  TRAFFICSOURCE_FORM_CONVERSION_TRACKING_TAB,
  TRAFFICSOURCE_FORM_HELP_TAB,
  TRAFFICSOURCE_FORM_TRACKING_FIELDS_TAB,
  TRAFFICSOURCE_FORM_ADVANCED_SETTINGS_TAB
} from 'constants/dynamicSidebar';
import { SidebarContext } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import { conditionalClass } from 'conditional-class';
import { TemplateSelector } from 'components/TemplateSelector';
import { TrafficSourceTemplate } from 'types/trafficsource';
import {
  FFAddGroup,
  FFCol,
  FFField,
  FFInput,
  FFRow,
  FFSection,
  FFSelect,
  FFSwitch,
  VisibilityWrapper
} from 'uikit';
import ConversionTracking from './components/ConversionTracking';
import { FFSelectOption } from 'uikit/types/select';
import { Tag } from 'antd';
import { defaultCustomEventAliases } from 'constants/customEvents';

const defaultTrackingFieldSlots: {
  [key: string]: TrackingField;
} = {
  campaign: { name: 'campaign', value: '', status: 'active' },
  external: { name: 'external', value: '', status: 'active' },
  c1: { name: '', value: '', status: 'archived' },
  c2: { name: '', value: '', status: 'archived' },
  c3: { name: '', value: '', status: 'archived' },
  c4: { name: '', value: '', status: 'archived' },
  c5: { name: '', value: '', status: 'archived' },
  c6: { name: '', value: '', status: 'archived' },
  c7: { name: '', value: '', status: 'archived' },
  c8: { name: '', value: '', status: 'archived' },
  c9: { name: '', value: '', status: 'archived' },
  c10: { name: '', value: '', status: 'archived' },
  c11: { name: '', value: '', status: 'archived' },
  c12: { name: '', value: '', status: 'archived' },
  c13: { name: '', value: '', status: 'archived' },
  c14: { name: '', value: '', status: 'archived' },
  c15: { name: '', value: '', status: 'archived' },
  c16: { name: '', value: '', status: 'archived' },
  c17: { name: '', value: '', status: 'archived' },
  c18: { name: '', value: '', status: 'archived' },
  c19: { name: '', value: '', status: 'archived' },
  c20: { name: '', value: '', status: 'archived' }
};

function areAllStringFieldsEmpty(obj: any = {}) {
  if (Object.keys(obj).length === 0) {
    return false;
  }
  for (let key in obj) {
    if (typeof obj[key] === 'string' && obj[key] !== '') {
      return false;
    }
  }
  return true;
}

const defaultValues = (): TrafficSource => ({
  idTrafficSource: '',
  trafficSourceName: '',
  costType: 'cpe',
  idCategory: undefined,
  defaultCost: '',
  status: 'active',
  postback: {
    idTrafficSource: '',
    postbackType: 'none',
    postbackURL: '',
    postbackHTML: ''
  },
  trackingFieldSlots: {},
});

const defaultTracking = {
  external: 'external',
  campaign: 'campaign'
};

const RESERVED_FIELD_NAMES = [
  'cost',
  'external',
  'campaign',
  'f',
  'ts',
  'n',
  'c',
  'vid'
];

const CUSTOM_SCENARIO_POSTBACK_SELECT_OPTION_ID = 'customScenario';

const postbackValues: {
  [key in Postback.PostbackTypeEnum]: Postback.PostbackTypeEnum;
} = {
  html: 'html',
  none: 'none',
  postbackURL: 'postbackURL'
};

interface TrackingFieldWithKey extends TrackingField {
  key: string;
}

type CustomEventTriggerType = 'customScenario' | 'postbackUrl';

interface CustomEventMap {
  triggerType: CustomEventTriggerType;
  triggerUrl: string;
}

const customEventTriggerTypeOptions: SelectOption<
  string,
  CustomEventTriggerType
>[] = [
    {
      value: 'customScenario',
      label: 'Custom Scenario'
    },
    {
      value: 'postbackUrl',
      label: 'Postback Url'
    }
  ];

const defaultCustomEvents = repeatObject<CustomEventMap>(
  {
    triggerType: 'customScenario',
    triggerUrl: ''
  },
  10
);

interface State extends ReactiveValidateState {
  trafficSourceNames: string[];
  model: TrafficSource;
  anotherModel: AnyObject;
  validationErrors: AnyObject;
  dataPassingTokens: [];
  isTouchedForm: boolean;
  showErrorTooltip: boolean;
  attributeOptions: FFSelectOption[];
  inputSelectionStartPostbackURL: number;
  inputSelectionStartPostbackHTML: number;
  lock: AnyObject;
  customEventHasError: boolean;
  hasSubmittedTemplate: boolean;
  templateSearch: string;
  trackingFieldsArray: Array<TrackingFieldWithKey>;
  selectedTemplateId: string;
  customEventsArray: CustomEventMap[];
  triggeringEventOptions: FFSelectOption<string>[],
  triggeringEvent: string;
  enableIncomingCostModification: boolean;
  enableOutgoingRevenueModification: boolean;
  enableOutgoingPostbackProbability: boolean;
}

const defaultState = (): State => ({
  trafficSourceNames: [],
  model: defaultValues(),
  validationErrors: {},
  dataPassingTokens: [],
  isTouchedForm: false,
  showErrorTooltip: false,
  anotherModel: {
    tracking: []
  },
  attributeOptions: [],
  inputSelectionStartPostbackURL: 0,
  inputSelectionStartPostbackHTML: 0,
  lock: {
    external: true,
    campaign: true
  },
  customEventHasError: false,
  hasSubmittedTemplate: false,
  templateSearch: '',
  trackingFieldsArray: [],
  selectedTemplateId: '',
  customEventsArray: defaultCustomEvents,
  triggeringEventOptions: [],
  triggeringEvent: 'Conversions',
  enableIncomingCostModification: false,
  enableOutgoingRevenueModification: false,
  enableOutgoingPostbackProbability: false
});

class TrafficSources extends ReactiveValidateComponent<
  TrafficSourceFormProps & MessageProps & LoadingProps,
  State
> {
  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  elRefs = {
    postback: React.createRef(),
    javascript: React.createRef()
  };

  state: State = defaultState();

  static defaultProps: Partial<TrafficSourceFormProps> = {
    templates: MockedTemplates,
    costTypes: [
      {
        id: 'cpe',
        name: 'CPE'
      },
      {
        id: 'cpa',
        name: 'CPA'
      }
    ],
    postbackTypes: [
      {
        id: postbackValues.none,
        name: 'None'
      },
      {
        id: postbackValues.postbackURL,
        name: 'Postback URL'
      },
      {
        id: postbackValues.html,
        name: 'HTML'
      },
      {
        id: CUSTOM_SCENARIO_POSTBACK_SELECT_OPTION_ID,
        name: 'Custom Scenario'
      }
    ]
  };

  handleStateValuesChange = <T extends State>(
    key: keyof T,
    value: T[keyof T]
  ) => {
    this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  handleModelValuesChange = async <T extends TrafficSource>(
    key: keyof T,
    value: T[keyof T]
  ) => {
    await this.setState(state => ({
      ...state,
      model: {
        ...state.model,
        [key]: value
      }
    }));
  };

  async componentDidMount() {
    if (defined(this.props.setButtonGroupProps)) {
      this.props.setButtonGroupProps({
        saveText: isCopyByContextModal(this.props.contextModal)
          ? `DUPLICATE`
          : `SAVE`,
        onSaveClicked: this.handleSubmit,
        showSave: false,
        showCancel: true,
        cancelText: 'DISCARD'
      });
    }

    await this.onDidMount();
    this.handleStateValuesChange('triggeringEventOptions', [
      { label: 'Conversions', value: 'Conversions' },
      ...Object.entries(this.props.userSettings.customEventAliases || defaultCustomEventAliases).map(
        ([key, value]) => ({
          label: `Custom Event ${key} ${value.alias ? `(${value.alias})` : ''}`,
          value: `${key}`
        })
      )
    ] as FFSelectOption<string>[]);
  }

  onDidMount = async () => {
    this.props.fetchCategories();
    this.setValidateSubscribe();
    await this.setState({
      trafficSourceNames: Object.values(this.props.trafficSources).map(
        trafficSource => trafficSource.trafficSourceName
      )
    });

    if (defined(this.props.contextModal.data)) {
      const id = this.props.contextModal.entityId!;
      if (this.props.setForCopyIdsProps) {
        this.props.setForCopyIdsProps({
          'Traffic Source ID': id
        });
      }
      this.props.setSidebarLoading!(true);
      await this.setEditModel(id);
      await this.props.getTrafficSourceById(id);
      await this.setEditModel(id);
      this.props.setSidebarLoading!(false);
    }

    await this.handleStateValuesChange(
      'trackingFieldsArray',
      this.getTrackingFieldsArrayFromSlots(this.state.model.trackingFieldSlots)
    );

    if (!this.props.attributes.length) {
      await this.props.fetchReportingsAttributes();
    }

    this.setState((state: State) => ({
      ...state,
      attributeOptions: getAttributes('Traffic Source Conversion Tracking')
    }));
  };

  getTrackingFieldsArrayFromSlots = (
    trackingFieldSlots: {
      [key: string]: TrackingField;
    } = {},
    fromTemplate = false
  ) => {
    const _trackingFieldSlots = {
      ...defaultTrackingFieldSlots,
      ...trackingFieldSlots
    };
    if (!Object.keys(trackingFieldSlots).length) {
      _trackingFieldSlots.c1.status = 'active';
      _trackingFieldSlots.c2.status = 'active';
      _trackingFieldSlots.c3.status = 'active';
    }
    let trackingFields = Object.keys(_trackingFieldSlots).reduce(
      (acc: TrackingFieldWithKey[], key) => {
        acc.push({
          name: _trackingFieldSlots?.[key].name || '',
          status: fromTemplate
            ? defined(trackingFieldSlots?.[key])
              ? 'active'
              : 'archived'
            : _trackingFieldSlots?.[key].status || 'active',
          value: _trackingFieldSlots?.[key].value || '',
          key
        });
        return acc;
      },
      []
    );
    trackingFields = sortByName(trackingFields, 'key');
    trackingFields = moveToFirstByFunc(
      trackingFields,
      trackingField => trackingField.key === 'external'
    );
    trackingFields = moveToFirstByFunc(
      trackingFields,
      trackingField => trackingField.key === 'campaign'
    );
    return trackingFields;
  };

  validationRules = (): ValidationRule[] => [
    {
      field: 'trafficSourceName',
      validations: [
        required,
        unique(this.state.trafficSourceNames, 'traffic source')
      ]
    },
    {
      field: 'postbackURL',
      validations: !!this.state.model.postback?.postbackURL ? [customUrl] : []
    },
    {
      field: 'incomingCostModifier',
      validations: this.state.enableIncomingCostModification ? [trafficSourceCostModifier] : []
    },
    {
      field: 'outgoingRevenueModifier',
      validations: this.state.enableOutgoingRevenueModification ? [trafficSourceCostModifier] : []
    },
    {
      field: 'outgoingPostbackProbability',
      validations: this.state.enableOutgoingPostbackProbability ? [trafficSourcePostbackProbability] : []
    }
  ];

  setEditModel = async (id: string) => {
    const editModel = defined(this.props.trafficSources?.[id])
      ? cloneObject(this.props.trafficSources[id])
      : null;

    if (defined(editModel)) {
      // @TODO: patch code, delete it later
      Object.keys(editModel.conversionTrackingSettings || {}).forEach(key => {
        if (editModel.conversionTrackingSettings?.[key].isCustomScenario) {
          if (areAllStringFieldsEmpty(editModel.conversionTrackingSettings[key].customEventData)) {
            editModel.conversionTrackingSettings[key].customEventData = {};
            editModel.conversionTrackingSettings[key].isCustomScenario = false;
            editModel.conversionTrackingSettings[key].postbackType = 'none'
            editModel.conversionTrackingSettings[key].postbackURL = '';
            if (editModel.customEventPostback?.customEvents?.[key]) {
              delete editModel.customEventPostback?.customEvents[key]
            }
          } else {
            //@ts-ignore
            editModel.conversionTrackingSettings[key].postbackType = 'postbackURL';
            if (key !== 'Conversions') {
              //@ts-ignore
              editModel.customEventPostback = {
                ...(editModel.customEventPostback || {}),
                //@ts-ignore
                customEvents: {
                  ...editModel.customEventPostback?.customEvents || {},
                  [key]: {
                    postbackType: 'postbackURL',
                    postbackURL: editModel.conversionTrackingSettings[key].postbackURL!
                  }
                }
              }
            } else {
              // Clean up customEventPostback > conversions if present
              if (editModel.customEventPostback && editModel.customEventPostback.customEvents && editModel.customEventPostback.customEvents['Conversions']) {
                delete editModel.customEventPostback.customEvents['Conversions'];
              }
            }
          }
        }
      })

      if (isCopyByContextModal(this.props.contextModal)) {
        editModel.trafficSourceName = this.props.contextModal.copyName!;
        editModel.status = 'active';
      }
      this.setState((state: State) => ({
        model: {
          ...state.model,
          ...editModel
        },
        enableIncomingCostModification: !!editModel.incomingCostModifier,
        enableOutgoingRevenueModification: !!editModel.outgoingRevenueModifier,
        enableOutgoingPostbackProbability: !!editModel.outgoingPostbackProbability,
        trafficSourceNames: state.trafficSourceNames.filter(
          name => name !== editModel.trafficSourceName
        )
      }));
      this.onTemplateSelected();
    }
  };

  handlePostbackTokens = (value: string) => {
    const postbackURL = this.state.model.postback!.postbackURL!;
    const postbackHTML = this.state.model.postback?.postbackHTML!;
    const isHTML = this.state.model.postback?.postbackType === 'html';
    const inputSelectionStart = isHTML
      ? this.state.inputSelectionStartPostbackHTML
      : this.state.inputSelectionStartPostbackURL;

    const getPostback = (postbackValue: string) => {
      postbackValue =
        defined(postbackValue) && postbackValue.length > 0 ? postbackValue : '';
      return inputSelectionStart >= 0
        ? postbackValue.substr(0, inputSelectionStart) +
        value +
        postbackValue.substr(inputSelectionStart, postbackValue.length)
        : postbackValue;
    };

    this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        postback: {
          ...state.model.postback!,
          postbackURL: isHTML ? postbackURL : getPostback(postbackURL),
          postbackHTML: isHTML ? getPostback(postbackHTML) : postbackHTML
        }
      }
    }));
  };

  handlePostbackChange = <T extends Postback>(
    name: keyof T,
    value: T[keyof T]
  ) => {
    this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        postback: {
          ...state.model.postback!,
          [name]: value,
          idTrafficSource: state.model.idTrafficSource
        }
      }
    }));
  };

  checkFieldNameReserved = (trackingField: TrackingFieldWithKey) =>
    !['campaign', 'external'].includes(trackingField.key)
      ? RESERVED_FIELD_NAMES.includes(trackingField.name)
        ? `Field with name "${trackingField.name}" using one of reserved words`
        : trackingField.name.match(/^0*([1-9]|1[0-9]|20)$/g)
          ? 'Values 1-20 are not allowed'
          : false
      : false;

  getFieldNameError = (data: TrackingFieldWithKey) => {
    const reserved = this.checkFieldNameReserved(data);
    return reserved || '';
  };

  checkFieldHasError = (trackingField: TrackingFieldWithKey) => {
    return (
      defined(this.state.validationErrors) &&
      defined(this.state.validationErrors['trackingFieldSlots']) &&
      defined(this.state.validationErrors['trackingFieldSlots'][0]) &&
      defined(
        this.state.validationErrors['trackingFieldSlots'][0][trackingField.key]
      ) &&
      defined(
        this.state.validationErrors['trackingFieldSlots'][0][trackingField.key]
          .value
      )
    );
  };

  disableTrackingFieldSlot = (idx: number) => {
    this.handleStateValuesChange(
      'trackingFieldsArray',
      this.state.trackingFieldsArray.map((trackingField, _idx) => {
        if (idx === _idx) {
          return {
            ...trackingField,
            status: trackingField.status === 'active' ? 'archived' : 'active'
          };
        } else {
          return trackingField;
        }
      })
    );
  };

  handleCopyClick = (context: RefObject<any>) => {
    copy(context, this.props.showMessage, Messages);
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.handleStateValuesChange('validationErrors', {});
      await this.handleStateValuesChange('showErrorTooltip', false);

      const [validationErrors] = validateForm(
        {
          ...this.state.model,
          ...(this.state.model.postback || {})
        },
        this.validationRules()
      );

      await this.handleStateValuesChange('validationErrors', validationErrors);

      handleScrollToErrorElement(
        validationErrors,
        (entries: IntersectionObserverEntry[]) => {
          if (defined(entries[0]) && entries[0].isIntersecting) {
            this.setState({ showErrorTooltip: true });
          }
        }
      );
    }
  };

  extraValidationTrackingFields = () => {
    return new Promise((res, rej) => {
      const trackingFieldSlots = Object.values(this.state.model.trackingFieldSlots || {});
      const hasDuplicateName = Object.values(this.state.model.trackingFieldSlots || {}).some(
        (trackingFieldSlot, index) => trackingFieldSlots.findIndex(otherItem => otherItem.name === trackingFieldSlot.name) !== index
      );
      if (hasDuplicateName) {
        this.props.showMessage(Messages.failed("You cannot have multiple URL tracking fields with same name"));
        this.props.stopLoading('all');
        rej()
      }
      res(true);
    })
  }

  resetForm = async (isCreateAndNew = false) => {
    if (isCreateAndNew) {
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          trafficSourceName: getDuplicateModalSidebarContext(
            this.state.model.idTrafficSource,
            this.props.trafficSources
          ),
          idTrafficSource: ''
        }
      });
    }
  };

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    this.props.startLoading('save');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm();
      this.props.closeSelf();
    }

    return false;
  };

  validateClassName = (field: string, idx?: string) => {
    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? 'has-error'
      : '';
  };

  validateTooltip = (field: string, idx?: string) => {
    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  getToken = (trackingField: TrackingFieldWithKey) => {
    if (this.isCampaignOrExternal(trackingField)) {
      return `{${trackingField.key}}`;
    }
    return trackingField.name !== '' ? `{data-${trackingField.name}}` : '';
  };

  getCorrectValueToken = (token: string) => {
    if (
      [defaultTracking.campaign, defaultTracking.external].indexOf(token) > -1
    ) {
      return `{${token}}`;
    }
    return token !== '' ? `{data-${token}}` : '';
  };

  componentDidUpdate(prevProps: TrafficSourceFormProps, prevState: State) {
    if (prevState.selectedTemplateId !== this.state.selectedTemplateId) {
      let template = (this.props.templates || []).find(
        template => template.id === this.state.selectedTemplateId
      )!;

      if (template) {
        this.setState((state: State) => ({
          ...state,
          model: {
            ...state.model,
            trafficSourceName: template.name,
            postback: {
              ...(state.model.postback! || {}),
              postbackType: template.trackingType,
              postbackURL:
                template.trackingType === 'postbackURL'
                  ? template.tracking.code
                  : '',
              postbackHTML:
                template.trackingType === 'html' ? template.tracking.code : ''
            },
            conversionTrackingSettings: template.conversionTrackingSettings,
            costType: template.typeCost,
            defaultCost: template.defaultCost,
          },
          trackingFieldsArray: this.getTrackingFieldsArrayFromSlots(
            template.trackingFieldSlots,
            true
          )
        }));
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleSaveAndCreate = async (e: Event) => {
    e.preventDefault();
    this.props.startLoading('saveAndCreate');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm(true);
    }
  };

  addTrackingFields = async () => {
    await this.handleModelValuesChange(
      'trackingFieldSlots',
      this.state.trackingFieldsArray.reduce(
        (acc: { [key: string]: TrackingField }, trackingField) => {
          const previousNameHasChanged =
            this.state.model.trackingFieldSlots?.[trackingField.key]?.name !==
            trackingField.name;
          if (trackingField.name) {
            acc[trackingField.key] = {
              ...trackingField,
              previousName: this.isCampaignOrExternal(trackingField)
                ? trackingField.key
                : previousNameHasChanged
                  ? this.state.model.trackingFieldSlots?.[trackingField.key]?.name
                  : undefined
            };
          }
          return acc;
        },
        {}
      )
    );
  };

  performCreateOrUpdate = async () => {
    await this.setTouchedForm();
    await this.validate();
    await this.addTrackingFields();
    await this.extraValidationTrackingFields();
    let model = trimStringPropertiesInObject(this.state.model, [
      'trafficSourceName'
    ]);
    model.incomingCostModifier = this.state.enableIncomingCostModification ? Number(model.incomingCostModifier) : undefined;
    model.outgoingRevenueModifier = this.state.enableOutgoingRevenueModification ? Number(model.outgoingRevenueModifier) : undefined;
    model.outgoingPostbackProbability = this.state.enableOutgoingPostbackProbability ? Number(model.outgoingPostbackProbability) : undefined;
    const checkTrackingFields = this.checkFieldsName();
    if (model.idCategory === UNCATEGORIZED) {
      delete model.idCategory;
    }

    if (
      !Object.keys(this.state.validationErrors).length &&
      !checkTrackingFields &&
      !this.state.customEventHasError
    ) {
      const newId = generateEntityId();
      try {
        if (isCopyByContextModal(this.props.contextModal)) {
          await this.props.handleDuplicate({
            ...model,
            meta: { version: 1 }
          });
        } else if (!!this.state.model.idTrafficSource) {
          await this.props.getTrafficSourceById(model.idTrafficSource);
          if (
            model?.meta &&
            model.meta?.version !==
            this.props.trafficSources[model.idTrafficSource]?.meta?.version
          ) {
            const sidebar = this.context as SidebarProps;
            try {
              await asyncVersionConfirmSidebar(sidebar);
              await this.props.handleUpdate(
                withIncrementedVersion(
                  model,
                  this.props.trafficSources[model.idTrafficSource]?.meta
                    ?.version
                )
              );
            } catch (e) {
              this.props.stopLoading('all');
              this.props.closeSelf();
              return;
            }
          } else {
            await this.props.handleUpdate(withIncrementedVersion(model));
          }
        } else {
          await this.props.handleCreate({
            ...model,
            idTrafficSource: newId,
            postback: {
              ...model.postback!,
              idTrafficSource: newId
            },
            customEventPostback: {
              ...model.customEventPostback!,
              idTrafficSource: newId
            },
            meta: { version: 1 }
          });
          await this.setState({
            model: {
              ...this.state.model,
              idTrafficSource: newId,
              postback: {
                ...this.state.model?.postback!,
                idTrafficSource: newId
              },
              customEventPostback: {
                ...model.customEventPostback!,
                idTrafficSource: newId
              },
            }
          });
        }

        this.props.stopLoading('all');
        this.props.showMessage(
          Messages.success(
            `${model.trafficSourceName} ${!!model.idTrafficSource ? 'has been updated' : 'has been added'
            }`
          )
        );
      } catch (e) {
        if (
          defined(e.response) &&
          e.response.status === DUPLICATE_RECORD_CODE
        ) {
          this.setState(
            {
              trafficSourceNames: [
                ...this.state.trafficSourceNames,
                this.state.model.trafficSourceName
              ]
            },
            this.validate
          );
        }
        this.props.showMessage(
          Messages.failed(
            `${this.state.model.trafficSourceName} ${!!this.state.model.idTrafficSource
              ? 'cannot be updated'
              : 'cannot be added'
            }`
          )
        );
        this.props.stopLoading('all');
        return false;
      }

      return true;
    } else {
      this.props.stopLoading('all');

      if (defined(this.state.validationErrors?.trackingFieldSlots)) {
        // this.handleCollapsibleChange({
        //   name: 'isTrackingFieldsOn',
        //   value: true
        // });
      }
    }

    return false;
  };

  checkFieldsName = () => {
    return defined(
      this.state.trackingFieldsArray.find(trackingField =>
        this.checkFieldNameReserved(trackingField)
      )
    );
  };

  isCampaignOrExternal = (trackingField: TrackingFieldWithKey) => {
    return [defaultTracking.campaign, defaultTracking.external].includes(
      trackingField.key
    );
  };

  getTooltipData = (trackingField: TrackingFieldWithKey) => {
    switch (trackingField.key) {
      case defaultTracking.external:
        return {
          title: 'External ID',
          body:
            'If your traffic source uses click ID tracking, pass unique click IDs under this field.'
        };
      case defaultTracking.campaign:
        return {
          title: 'Campaign',
          body:
            'Pass unique names or IDs for your traffic source campaigns here. This is important as you will likely be sending clicks from many different traffic source "campaigns" (or "ad sets" for example) to a single funnel.'
        };
    }
  };

  saveInputPosition = (e: any, type: Postback.PostbackTypeEnum) => {
    if (type === 'html') {
      this.setState({
        inputSelectionStartPostbackHTML: e.target.selectionStart
      });
    } else {
      this.setState({
        inputSelectionStartPostbackURL: e.target.selectionStart
      });
    }
  };

  onCustomEventsChange = (
    key: keyof CustomEventMap,
    value: CustomEventTriggerType,
    idx: number
  ) => {
    this.handleStateValuesChange(
      'customEventsArray',
      this.state.customEventsArray.map((customEvent, _idx) => {
        if (_idx === idx) {
          return {
            ...customEvent,
            [key]: value,
            triggerUrl:
              value === 'postbackUrl'
                ? this.state.model?.postback?.postbackURL
                : ''
          };
        } else {
          return customEvent;
        }
      })
    );
  };

  handleLock = (key: string) => {
    this.handleStateValuesChange('lock', {
      ...this.state.lock,
      [key]: !this.state.lock[key]
    });
  };

  isDisableTrackingField = (trackingField: TrackingFieldWithKey) => {
    const isCampaignOrExternal = this.isCampaignOrExternal(trackingField);
    return !isCampaignOrExternal && trackingField.status === 'archived';
  };

  isLockedTrackingField = (trackingField: TrackingFieldWithKey) => {
    return this.state.lock[trackingField.key];
  };

  onTemplateSearch = (val: string) => {
    this.handleStateValuesChange('templateSearch', val);
  };

  onTemplateSelected = (id?: string) => {
    this.props.showTabs();
    if (id) {
      this.handleStateValuesChange('selectedTemplateId', id);
    }
    this.props.setButtonGroupProps({
      showSave: true
    });
    this.handleStateValuesChange('hasSubmittedTemplate', true);
  };

  onTrackingFieldsChange = (
    key: keyof TrackingFieldWithKey,
    value: string,
    idx: number
  ) => {
    const _value =
      key === 'key' ? value.replace(/[^a-zA-Z0-9\-_]/g, '') : value;
    this.handleStateValuesChange(
      'trackingFieldsArray',
      this.state.trackingFieldsArray.map((trackingField, _idx) => {
        if (_idx === idx) {
          return {
            ...trackingField,
            [key]: _value
          };
        } else {
          return trackingField;
        }
      })
    );
  };

  preventWheelChange = (e: any) => {
    e.target.blur();
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    const templateData = this.props.templates!.find(
      item => item.id === this.state.anotherModel.template
    );

    return !this.state.hasSubmittedTemplate ? (
      <TemplateSelector
        onSelect={this.onTemplateSelected}
        onSearch={this.onTemplateSearch}
        searchValue={this.state.templateSearch}
        templates={MockedTemplates}
        type="trafficsource"
      />
    ) : (
      <form className="form-TrafficSource" onSubmit={this.handleSubmit}>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            TRAFFICSOURCE_FORM_TAB,
            this.props.activeTab
          )}
        >
          <FFSection
            innerTitle={this.props.tabTitle!}
          >
            <FFCol gap="15px">
              <FFRow gap="15px" alignItems="center">
                <div
                  id={getErrorId('trafficSourceName')}
                  className={`form-TrafficSource--inputGroup form-TrafficSource--inputGroup--name ${this.validateClassName(
                    'trafficSourceName'
                  )}`}
                >
                  <Label
                    text={`Traffic Source Name`}
                    htmlFor="trafficSourceName"
                  />
                  <InputSelect
                    dataTestid="trafficSourceName"
                    placeholder="Search or enter a custom name"
                    name="trafficSourceName"
                    onChange={(inputValue: string, selectValue: string) => {
                      this.handleModelValuesChange(
                        'trafficSourceName',
                        inputValue
                      );
                      if (selectValue) {
                        this.handleStateValuesChange(
                          'selectedTemplateId',
                          selectValue
                        );
                        hightLightTabs();
                      }
                    }}
                    value={this.state.model.trafficSourceName}
                    error={this.validateTooltip('trafficSourceName')}
                    showErrorTooltip={this.state.showErrorTooltip}
                    options={this.props.templates || []}
                    valueGetter={(option: TrafficSourceTemplate) => option.id}
                    labelGetter={(option: TrafficSourceTemplate) => option.name}
                  />
                </div>
                <div
                  id={getErrorId('categoryId')}
                  className={`form-TrafficSource--inputGroup form-TrafficSource--inputGroup--category ${this.validateClassName(
                    'categoryId'
                  )}`}
                >
                  <Label text="Category" htmlFor="category" />
                  <div className="flex flex-row form-TrafficSource--inputGroup--category--selectBox flex-gap-15">
                    <Select
                      name="category"
                      id="category"
                      data-testid="category"
                      placeholder={UNCATEGORIZED}
                      options={getActiveEntities(this.props.categories)}
                      valueGetter={(option: Category) => option.idCategory}
                      labelGetter={(option: Category) => option.categoryName}
                      value={this.state.model.idCategory}
                      defaultValueFallback={{
                        label: UNCATEGORIZED,
                        value: UNCATEGORIZED
                      }}
                      onChange={(value: string) =>
                        this.handleModelValuesChange('idCategory', value)
                      }
                      showSearch={true}
                      filterOption={true}
                    />
                    <OpenButton
                      data-testid="open-category-form"
                      onClick={this.props.openCategoriesModal}
                    />
                  </div>
                </div>
              </FFRow>
              <div className="flex flex-row flex-gap-15 form-TrafficSource--inputGroup">
                <div className="form-TrafficSource--inputGroup--costType">
                  <Label text="Cost Type" htmlFor="costType" />
                  <Select
                    id="costType"
                    name="costType"
                    data-testid="costType"
                    value={this.state.model.costType}
                    valueGetter={(opt: any) => opt.id}
                    labelGetter={(opt: any) => opt.name}
                    options={this.props.costTypes || []}
                    placeholder="Select Cost Type"
                    onChange={(value: string) => {
                      this.handleModelValuesChange('costType', value);
                    }}
                  />
                </div>
                <div className="form-TrafficSource--inputGroup--costPerEnt">
                  <div className="flex flex-col">
                    <Label
                      text={
                        this.state.model.costType === 'cpa'
                          ? 'Default Cost per Conversion'
                          : 'Default Cost per Entry'
                      }
                      htmlFor="defaultCost"
                    />
                    <div className="flex flex-row">
                      <Input
                        id="defaultCost"
                        name="defaultCost"
                        data-testid="defaultCost"
                        placeholder={`Ex: 0.005`}
                        value={this.state.model.defaultCost}
                        onChange={e =>
                          this.handleModelValuesChange(
                            'defaultCost',
                            removeCommaOrMultipleDot(e.target.value)
                          )
                        }
                      />
                      <div
                        className={conditionalClass(
                          'form-TrafficSource--inputGroup--costPerEnt--note',
                          {
                            _hidden: this.state.model.costType === 'cpa'
                          }
                        )}
                      >
                        <span>Note: you can use tokens here</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ModalButtonGroup
                loading={this.props.loading}
                showSaveAndCreate={!isCopyByContextModal(this.props.contextModal)}
                onSaveAndCreateClicked={this.handleSaveAndCreate}
                additionalClass="form-TrafficSource--buttons"
              />
            </FFCol>
          </FFSection>
        </FormContentWrapper>

        <FormContentWrapper
          show={getVisibilityByActiveTab(
            TRAFFICSOURCE_FORM_TRACKING_FIELDS_TAB,
            this.props.activeTab
          )}
        >
          <FFCol gap="15px">
            <FFSection innerTitle={this.props.tabTitle!}>
              <p className="margin-bottom-0">
                Here you can define the URL tracking fields that are used to pass
                custom data from this traffic source. Below, define the URL
                parameter names and placeholder values. These will be appended to
                links generated for this source. See the help tab for more
                details.
              </p>
            </FFSection>
            <FFSection
              innerTitle="URL Tracking Field Configuration"
              className="form-TrafficSource__fieldContainer"
            >
              <FFAddGroup
                rows={this.state.trackingFieldsArray}
                className="form-TrafficSource__urlFields"
                showAddRow={false}
                rowClassName="flex flex-align-center flex-justify-center"
                headerRow={
                  <FFAddGroup.Row className="form-TrafficSource__urlFieldsHeader">
                    <FFAddGroup.Col
                      className="form-TrafficSource__urlFieldsHeader--name"
                      minWidth={50}
                    >
                      Name
                    </FFAddGroup.Col>
                    <FFAddGroup.Col className="form-TrafficSource__urlFieldsHeader--key">
                      URL Parameter
                    </FFAddGroup.Col>
                    <FFAddGroup.Col className="form-TrafficSource__urlFieldsHeader--value">
                      Source Token
                    </FFAddGroup.Col>
                    <FFAddGroup.Col className="form-TrafficSource__urlFieldsHeader--internal">
                      FunnelFlux Token
                    </FFAddGroup.Col>
                    <FFAddGroup.Col
                      minWidth={50}
                      className="form-TrafficSource__urlFieldsHeader--append"
                    >
                      Append
                    </FFAddGroup.Col>
                  </FFAddGroup.Row>
                }
                renderRow={(row, rowIdx) => {
                  const errorName = this.getFieldNameError(row);
                  const errorValue = this.checkFieldHasError(row);

                  return (
                    <>
                      <FFAddGroup.Col
                        minWidth={50}
                        className="form-TrafficSource__urlFieldsName"
                      >
                        <VisibilityWrapper visible={row.key === 'campaign'}>
                          <span>Campaign ID</span>
                        </VisibilityWrapper>
                        <VisibilityWrapper visible={row.key === 'external'}>
                          <span>External Click ID</span>
                        </VisibilityWrapper>
                        <VisibilityWrapper
                          visible={!this.isCampaignOrExternal(row)}
                        >
                          <span
                            className={conditionalClass(
                              'form-TrafficSource__urlFieldsName',
                              {
                                'form-TrafficSource__urlFieldsName--disabled': this.isDisableTrackingField(
                                  row
                                )
                              }
                            )}
                          >
                            Custom - {row.key}
                          </span>
                        </VisibilityWrapper>
                      </FFAddGroup.Col>
                      <FFAddGroup.Col className="form-TrafficSource__urlFieldsParamKey">
                        <div className="flex flex-gap-5 flex-align-center">
                          <FFInput
                            name="name"
                            value={row.name}
                            data-testid={`fieldName${row.key}`}
                            placeholder="URL parameter name"
                            error={errorName}
                            onChange={e =>
                              this.onTrackingFieldsChange(
                                'name',
                                withoutWhiteSpace(e.target.value),
                                rowIdx
                              )
                            }
                            disabled={
                              this.isDisableTrackingField(row) ||
                              this.isLockedTrackingField(row)
                            }
                          />
                        </div>
                      </FFAddGroup.Col>
                      <FFAddGroup.Col className="form-TrafficSource__urlFieldsParamValue">
                        <FFInput
                          name="token"
                          value={row.value}
                          error={
                            errorValue
                              ? `On field name "${row.key}" value is required`
                              : ''
                          }
                          data-testid={`fieldValue${row.key}`}
                          placeholder={
                            row.key === defaultTracking.campaign
                              ? 'Campaign ID here'
                              : row.key === defaultTracking.external
                                ? 'External tracking ID here'
                                : 'Source Token'
                          }
                          onChange={e =>
                            this.onTrackingFieldsChange(
                              'value',
                              e.target.value,
                              rowIdx
                            )
                          }
                          disabled={this.isDisableTrackingField(row)}
                        />
                      </FFAddGroup.Col>
                      <FFAddGroup.Col className="form-TrafficSource__urlFieldsInternalToken">
                        <FFInput
                          name="funnelfluxToken"
                          value={this.getToken(row)}
                          placeholder="{data-fieldname}"
                          readOnly
                          disabled={this.isDisableTrackingField(row)}
                        />
                      </FFAddGroup.Col>
                      <FFAddGroup.Col
                        className="form-TrafficSource__urlFieldsAppend"
                        minWidth={50}
                      >
                        <VisibilityWrapper
                          visible={!this.isCampaignOrExternal(row)}
                        >
                          <FFSwitch
                            data-testid={`fieldArchive${rowIdx}`}
                            className="form-TrafficSource__urlFieldsAppendSwitch"
                            onClick={() => this.disableTrackingFieldSlot(rowIdx)}
                            checked={row.status === 'active'}
                          />
                        </VisibilityWrapper>
                        <VisibilityWrapper
                          visible={this.isCampaignOrExternal(row)}
                        >
                          <>
                            {this.isLockedTrackingField(row) && (
                              <LockpadButton
                                onClick={() => this.handleLock(row.key)}
                              />
                            )}
                            {!this.isLockedTrackingField(row) && (
                              <UnLockpadButton
                                onClick={() => this.handleLock(row.key)}
                              />
                            )}
                          </>
                        </VisibilityWrapper>
                      </FFAddGroup.Col>
                    </>
                  );
                }}
              />
            </FFSection>
          </FFCol>
        </FormContentWrapper>
        <FormContentWrapper
          className="fireConversions"
          show={getVisibilityByActiveTab(
            TRAFFICSOURCE_FORM_CONVERSION_TRACKING_TAB,
            this.props.activeTab
          )}
        >
          <FFCol gap="15px">
            <FFSection innerTitle="Conversion Tracking">
              <p>
                Here you can set postbacks or custom scenarios to trigger for conversions and custom events. The settings for each event are
                independent.
              </p>
            </FFSection>
            <FFSection>
              <FFField label="Triggering Event" direction="row">
                <FFSelect
                  onSelect={(value) => this.handleStateValuesChange('triggeringEvent', value)}
                  value={this.state.triggeringEvent}
                  placeholder="Select event"
                  dropdownMatchSelectWidth={300}
                  valueGetter={opt => opt.value}
                  labelGetter={opt => opt.label}
                  style={{ width: 200 }}
                  options={this.state.triggeringEventOptions}
                />
              </FFField>
            </FFSection>
            <ConversionTracking
              trafficSource={this.state.model}
              triggeringEvent={this.state.triggeringEvent}
              setCustomEventHasError={value => this.handleStateValuesChange('customEventHasError', value)}
              onUpdate={trafficSource => this.handleStateValuesChange('model', trafficSource)}
            />
          </FFCol>
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            TRAFFICSOURCE_FORM_ADVANCED_SETTINGS_TAB,
            this.props.activeTab
          )}
        >
          <FFCol gap="12px">
            <FFSection innerTitle="Advanced Settings">
              <p>Here you can set optional advanced settings for this traffic source.</p>
            </FFSection>
            <FFSection innerTitle="Incoming cost modifier">
              <FFCol gap="12px">
                <p>
                  When enabled, this will multiply incoming costs by the provided value in percent. Use this to adjust for click loss by setting a value of {'>'}100% (e.g. "110" would add 10% to costs to account for loss).
                </p>
                <FFSwitch
                  checked={this.state.enableIncomingCostModification}
                  onClick={(val) => this.handleStateValuesChange('enableIncomingCostModification', val)}
                >
                  Enable incoming cost modification
                </FFSwitch>
                {this.state.enableIncomingCostModification && (
                  <FFField label="Multiplier in percent" direction="row">
                    <FFInput
                      type="number"
                      onWheel={this.preventWheelChange}
                      style={{ width: 95 }}
                      error={this.state.validationErrors.incomingCostModifier}
                      value={this.state.model.incomingCostModifier}
                      onChange={(e) => e.target.value.length <= 4 && this.handleModelValuesChange('incomingCostModifier', e.target.value)}
                    />
                    %
                  </FFField>
                )}
              </FFCol>
            </FFSection>
            <FFSection innerTitle="Outgoing revenue modifier">
              <FFCol gap="12px">
                <p>
                  When enabled, this will multiply the value of <Tag color="geekblue">{'{payout}'}</Tag> used in postbacks to traffic sources. Setting a value of "75" would multiply outgoing revenues to 75% of their original value.
                </p>
                <FFSwitch
                  checked={this.state.enableOutgoingRevenueModification}
                  onClick={(val) => this.handleStateValuesChange('enableOutgoingRevenueModification', val)}
                >
                  Enable outgoing revenue modification
                </FFSwitch>
                {this.state.enableOutgoingRevenueModification && (
                  <FFField label="Multiplier in percent" direction="row">
                    <FFInput
                      type="number"
                      onWheel={this.preventWheelChange}
                      style={{ width: 95 }}
                      error={this.state.validationErrors.outgoingRevenueModifier}
                      value={this.state.model.outgoingRevenueModifier}
                      onChange={(e) => e.target.value.length <= 4 && this.handleModelValuesChange('outgoingRevenueModifier', e.target.value)}
                    />
                    %
                  </FFField>
                )}
              </FFCol>
            </FFSection>
            <FFSection innerTitle="Disable zero revenue postbacks">
              <FFCol gap="12px">
                <p>
                  When enabled, conversions or custom events with a payout value of zero will no longer be sent to traffic sources. Use this
                  to prevent non-revenue events being sent to your traffic source automatically.
                </p>
                <FFSwitch
                  checked={this.state.model.disableZeroRevenuePostbacks}
                  onClick={(val) => this.handleModelValuesChange('disableZeroRevenuePostbacks', val)}
                >
                  Prevent zero revenue postbacks
                </FFSwitch>
              </FFCol>
            </FFSection>
            <FFSection innerTitle="Outgoing Postback Throttling">
              <FFCol gap="12px">
                <p>
                  When enabled, set the % probability of a postback being sent to the traffic source. Use this when you need to reduce the
                  number of postbacks going to the traffic source arbitrarily by chance. Setting a value of “80” would give an 80% chance of
                  the postback being sent.
                </p>
                <FFSwitch
                  checked={this.state.enableOutgoingPostbackProbability}
                  onClick={(val) => this.handleStateValuesChange('enableOutgoingPostbackProbability', val)}
                >
                  Limit postback events
                </FFSwitch>
                {this.state.enableOutgoingPostbackProbability && (
                  <FFField label="Chance in percent" direction="row">
                    <FFInput
                      type="number"
                      onWheel={this.preventWheelChange}
                      style={{ width: 95 }}
                      error={this.state.validationErrors.outgoingPostbackProbability}
                      value={this.state.model.outgoingPostbackProbability}
                      onChange={(e) => e.target.value.length <= 4 && this.handleModelValuesChange('outgoingPostbackProbability', e.target.value)}
                    />
                    %
                  </FFField>
                )}
              </FFCol>
            </FFSection>
          </FFCol>
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            TRAFFICSOURCE_FORM_HELP_TAB,
            this.props.activeTab
          )}
        >
          <FFSection
            innerTitle={this.props.tabTitle!}
            className="form-TrafficSource__helpSection"
          >
            <FormHelpSection
              content={
                <>
                  <p>
                    Traffic Sources are wherever your users come from prior to
                    entering your funnels.
                  </p>

                  <p>
                    These could be sources like Facebook, Google Adwords,
                    Taboola etc. or could be your own source labels like
                    Internal Email, Affiliate Referrals, etc.
                  </p>

                  <p>
                    These are an important label of incoming traffic and they
                    define what custom URL fields you will use to collect data,
                    which will also be added to links you generate.
                  </p>
                  <h3>Tracking Field Configuration</h3>
                  <p>
                    The tracking field config defines what parameters you will
                    pass in generated URLs and hence capture from the traffic
                    source. Here are the column definitions:
                  </p>
                  <ul>
                    <li>
                      <strong>Name:</strong> The tracking field name. There are
                      two reserved fields for campaign IDs and external click
                      IDs. These cannot be removed, but you can click unlock to
                      alias the parameter name for rare situations.
                    </li>
                    <li>
                      <strong>URL Parameter:</strong> this is the name of the
                      URL parameter or the "key" that will appear in the URL. It
                      should be lowercase and use no symbols other than
                      underscores
                    </li>
                    <li>
                      <strong>Source Token:</strong> here you set the
                      corresponding value that will be passed by a parameter,
                      giving a name=value pair in the URL. Use available traffic
                      source tokens, which depend on the source, or a static
                      placeholder like REPLACE
                    </li>
                    <li>
                      <strong>FunnelFlux Token:</strong> this is the internal
                      FunnelFlux token that will reference the value passed.
                      This token is what you would use in data passing config
                      for landers/offers to pass traffic source information
                      onward.
                    </li>
                    <li>
                      <strong>Append:</strong> here you can choose whether a
                      field will be appended to generated links. Turning this
                      off will stop the field appearing in links, though the
                      defined field will still capture values. Use it to turn
                      off fields you no longer wish to pass.
                    </li>
                  </ul>
                </>
              }
              name="Traffic Source"
            />
          </FFSection>
        </FormContentWrapper>
      </form>
    );
  }
}

export default Messages.injectIn(TrafficSources);

import axios, { AxiosTransformer } from 'axios';

import Auth from './auth';
import { message as AntMessage } from 'antd';
import {
  FUNNELGROUPS,
  LANDERS,
  OFFERS,
  FUNNELS,
  OFFERSOURCES,
  TRAFFICSOURCES,
  REPORTINGS,
  CATEGORIES,
  UPDATE_CONVERSIONS,
  RESET_DATA,
  CONDITIONS,
  UPDATE_TRAFFIC_COSTS,
  APP,
  ONBOARDING,
  HITS,
  CAMPAIGN_ANALYSIS
} from './endpoints';
import { RequestConfig } from './types';
import { API_BASE_URL } from '../constants/api';
import { defined } from '../utils/define';
import { AnyObject } from 'types';

const enforcer = Symbol();

class AxiosInstance {
  private axios: AxiosTransformer;
  private readonly currentVersion: string;
  private static _instance: AxiosInstance;

  constructor(enf: symbol) {
    if (enf !== enforcer) {
      throw new Error('Error: Singleton, use .instance to access properties');
    }
    this.axios = axios;
    this.currentVersion = `/v1`;
  }

  static get instance() {
    if (!AxiosInstance._instance) {
      AxiosInstance._instance = new AxiosInstance(enforcer);
    }
    return AxiosInstance._instance;
  }

  makeAuthHeader = () => ({
    Authorization: `Bearer ${Auth.accessToken}`
  });

  makeHeader = (
    header: object = {},
    withoutBearerHeader = false,
    bodyPayload: AnyObject,
    url: string
  ) => {
    const XLumReportingCountDistinct = bodyPayload?.returnUniqueVisitors;
    const headers: AnyObject = {
      ...header,
      ...(!withoutBearerHeader ? this.makeAuthHeader() : {}),
    };
    if (url.startsWith('api')) {
      headers.user_id = Auth.getUserId();
    }
    if (XLumReportingCountDistinct !== undefined) {
      headers['X-Lum-Reporting-Count-Distinct'] = XLumReportingCountDistinct;
    }
    return headers;
  };

  prepareURL = (urlPath: string, withoutCurrentVersion = false) => {
    if (urlPath.endsWith('meta.json')) {
      return 'https://ui.funnelflux.pro/meta.json'; 
    }
    if (urlPath.startsWith('api')) {
      return `${process.env.REACT_APP_MIDDLEWARE_API_URL}/${urlPath}`; 
    }
    return `${API_BASE_URL}${defined(withoutCurrentVersion) && withoutCurrentVersion ? `` : this.currentVersion}/${urlPath}`;
  }

  request = ({
    url,
    headers,
    params,
    withoutCurrentVersion,
    withoutApiUrl,
    responseTransformer,
    ...config
  }: RequestConfig) => {
    return this.axios({
      ...config,
      data: config.data || {},
      params: params,
      url: this.prepareURL(url, withoutCurrentVersion),
      headers: this.makeHeader(headers, withoutCurrentVersion, config.data!, url)
    })
      .then((res: any) => res.data)
      .then(responseTransformer)
      .catch((err: any) => {
        switch (err.message) {
          case 'Network Error':
            AntMessage.error(err.message);
        }
        // trackSentry(err);
        throw err;
      });
  }
}

export {
  FUNNELGROUPS,
  LANDERS,
  OFFERS,
  OFFERSOURCES,
  FUNNELS,
  TRAFFICSOURCES,
  REPORTINGS,
  CATEGORIES,
  UPDATE_CONVERSIONS,
  RESET_DATA,
  CONDITIONS,
  UPDATE_TRAFFIC_COSTS,
  APP,
  ONBOARDING,
  HITS,
  CAMPAIGN_ANALYSIS
};

export default AxiosInstance.instance;

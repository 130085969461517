import { pregMatchAll } from 'utils/regexp';
import { defined } from '../define';
import { getEntityId, getEntityName } from '../model';
import {
  FunnelGroupArray,
  ConditionArray,
  FunnelArray,
  OfferSourceArray,
  PageArray,
  PageGroupArray,
  TrafficSourceArray
} from '../../types/redux/store';
import { RefObject } from 'react';
import copyToClipboard from 'copy-to-clipboard';

export const getCopyCounter = (name: string, names: string[]) => {
  const clearName = name.replace(/ \(copy( \d)?\)$/, '');
  const listOfCounterNumbers = pregMatchAll(
    names.join('\n'),
    /(.*) \(copy( (\d+)|)\)/g
  )
    .filter(item => item[1] === clearName)
    .reduce((acc: any, item) => {
      return [...acc, item[3] !== undefined ? parseInt(item[3]) : 0];
    }, []);

  const counter = Math.max(...listOfCounterNumbers);

  return counter > 0 ? counter + 1 : listOfCounterNumbers.length > 0 ? 1 : 0;
};

export const getCopyName = (name: string, names: string[]) => {
  const count = getCopyCounter(name, names);
  return (
    name.replace(/ \(copy( \d)?\)$/, '') +
    ` (copy${count > 0 ? ` ${count}` : ``})`
  );
};

const getModalCopyName = (name: string, names: string[]) => {
  return getCopyName(name, names);
};

export const getDuplicateModalSidebarContext = (
  id: string,
  data:
    | PageArray
    | FunnelGroupArray
    | FunnelArray
    | OfferSourceArray
    | TrafficSourceArray
    | ConditionArray
    | PageGroupArray
) => {
  if (!!id && defined(data)) {
    const list = Object.values(data);
    const entity = list.find((item) => {
      return getEntityId(item) === id;
    });
    const entityNames: string[] = list.map(entity => getEntityName(entity));

    return defined(entity)
      ? getModalCopyName(getEntityName(entity), entityNames)
      : '';
  }

  return '';
};

export const copy = (
  context: RefObject<any>,
  showMessage: any,
  Messages: any,
  successText?: string
) => {
  const message = successText || 'Copied';
  const value =
    context?.current?.innerText ||
    context?.current?.input?.value ||
    context?.current?.value;

  if (!value) {
    showMessage(Messages.failed(`Nothing to be copied`));
  } else {
    copyToClipboard(value);
    showMessage(Messages.success(message));
  }
};

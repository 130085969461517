import { SelectOption } from "types";

export const statusOptions: SelectOption[] = [
  { label: 'View All', value: 'not-deleted' },
  { label: 'View Active', value: 'active' },
  { label: 'View Archived', value: 'archived' }
];

export const disabledIPs: SelectOption = { value: 'disabled', label: 'Disable Anonymization' };
export const systemSettingsDefault: SelectOption = { value: 'System Settings Default', label: 'System Settings Default' };

export const IPs: SelectOption[] = [
  { value: 'EU IPs', label: 'EU-based IPs' },
  { value: 'All IPs', label: 'All IPs' },
  disabledIPs
];

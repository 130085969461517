import { defined } from '../define';

export const loadFromStorage = (item = 'store') => {
  try {
    const local_item = getStringFromStorage(item);
    return local_item === null ? undefined : JSON.parse(local_item);
  } catch (e) {
    return;
  }
};

export const getStringFromStorage = key => {
  return localStorage.getItem(key);
};

export const saveToStorage = (obj, item = 'store') => {
  try {
    const serialized_item =
      obj !== null && typeof obj === 'object' ? JSON.stringify(obj) : obj;
    localStorage.setItem(item, serialized_item);
  } catch (e) {
    return;
  }
};

export const removeFromStorage = item => {
  try {
    const itemKey = item.split('.');
    const storage = JSON.parse(getStringFromStorage(item[0]) || {});
    if (defined(storage[itemKey[1]])) {
      delete storage[itemKey[1]];
      saveToStorage(storage);
    } else {
      localStorage.removeItem(item);
    }
  } catch (err) {
    return;
  }
};

export const saveManyToStorage = tokens => {
  if (Object.keys(tokens).length)
    Object.entries(tokens).forEach(([k, v]) => {
      saveToStorage(v, k);
    });
  return;
};

export const loadManuFromStorage = (...keys) => {
  let res = {};
  if (keys.length) {
    keys.forEach(key => {
      res[key] = loadFromStorage(key);
    });
    return keys;
  }
  return;
};

export const removeManyFromStorage = (...keys) => {
  if (keys.length) {
    keys.forEach(key => {
      removeFromStorage(key);
    });
    return true;
  }
  return false;
};

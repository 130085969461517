import { TABLE } from '../types';
import { AnyAction } from 'redux';

export default (state: string[] = [], action: AnyAction) => {
  switch (action.type) {
    case TABLE.SET_COLS:
      return action.payload.data;
    default:
      return state;
  }
};

import { getAttributes } from 'constants/templates';
import React from 'react';
import { OptionCategory, TrackingTokenType } from 'types';
import { FFAddGroup, FFCircleButton, FFInput, FFSelect, VisibilityWrapper } from 'uikit';
import { FFSelectOption } from 'uikit/types/select';
import { definedObject } from 'utils/define';
import { naturalSort } from 'utils/sort';
import { withoutWhiteSpace } from 'utils/string';

const getTrafficSourceField = () => '{data-fieldname}';
const isTrafficSourceField = (value: string) => value === '{data-traffic-source}';
const isUrlTrackingField = (value: string) => value === '{data-fieldname}';
const isBufferField = (value: string) => value === '{buffer-fieldname}';
const isCustomString = (value: string) => value === 'Custom String';

const showTextField = (value: string) =>
  isTrafficSourceField(value) || isUrlTrackingField(value) || isBufferField(value) || isCustomString(value);

const getTextFieldPlaceholder = (value: string) =>
  isUrlTrackingField(value)
    ? 'Enter URL parameter name to pass'
    : isCustomString(value)
    ? 'Custom string entry'
    : isTrafficSourceField(value)
    ? 'Traffic source field name or numbered value'
    : 'Manual text entry';

const getCustomFieldTypesValue = (value: string, manualValue: string) => {
  return `${
    isCustomString(value)
      ? value.replace('Custom String', manualValue)
      : isTrafficSourceField(value)
      ? value.replace('traffic-source', manualValue)
      : value.replace('fieldname', manualValue)
  }`;
};

const CONFLICT_ERROR = 'This parameter conflicts with one that is in your base URL. Please remove one of the conflicting params.';

interface Param {
  key: string;
  value: string;
  manual?: string;
}

interface Error {
  key: string;
  value: string;
  manual: string;
}

interface State {
  queryParams: Param[];
  errors: { [key: number]: Error };
}

const keyCheckRegex = /^[a-z0-9_\-[\]{}()]+$/i;

interface Props {
  queryParams: { [key: string]: string };
  pageName: TrackingTokenType;
  disableFields?: boolean;
  disableRemove?: boolean;
  disableAdd?: boolean;
  baseURL?: string;
  idOfferSource?: string;
  showErrors?: boolean;
  setError?: (hasError: boolean) => void;
  onChange?(queryParams: { [key: string]: string }): void;
}

const defaultParam: Param = {
  key: '',
  value: '',
  manual: ''
};

export default class NewDataPassing extends React.Component<Props, State> {
  timer = 0;
  static defaultProps: Pick<Props, 'onChange' | 'setError' | 'queryParams'> = {
    onChange: () => {},
    setError: () => {},
    queryParams: {}
  };
  state: State = {
    queryParams: [defaultParam],
    errors: {}
  };

  debounce = (func: Function, timeout = 400) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(func, timeout);
  };

  handleSetState = async <T extends State, P extends keyof T>(name: P, value: T[P]) => {
    await this.setState((state: State) => ({
      ...state,
      [name]: value
    }));
  };

  fillByInitialParams = async () => {
    await this.handleSetState(
      'queryParams',
      Object.entries(definedObject(this.props.queryParams) ? this.props.queryParams : { '': '' })
        .sort((a, b) => {
          const nameA = a[0].toLowerCase();
          const nameB = b[0].toLowerCase();
          return naturalSort(nameA, nameB);
        })
        .map(([key, value]: [string, string]) => ({
          key: key,
          value: value
        }))
    );
    this.checkErrors();
  };

  componentDidMount() {
    if (Object.keys(this.props?.queryParams || {})?.length) {
      this.fillByInitialParams();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.baseURL !== this.props.baseURL) {
      this.onChange();
    }
    if (prevProps.idOfferSource !== this.props.idOfferSource) {
      this.fillByInitialParams();
    }
    if (prevProps.showErrors !== this.props.showErrors) {
      this.onChange();
    }
  }

  checkErrors = async () => {
    try {
      const url = new URL(this.props.baseURL || 'https://dummy');
      const errors: {
        [key: number]: Error;
      } = {};
      const allKeys = this.state.queryParams.map(param => param.key);
      this.state.queryParams.forEach((param, idx) => {
        const keyError =
          !param.key && param.value
            ? 'Required'
            : param.key && url.searchParams.has(param.key)
            ? CONFLICT_ERROR
            : allKeys.filter(x => x === param.key).length > 1
            ? 'Duplicated Key'
            : '';
        const valueError = param.key && !param.value ? 'Required' : '';
        const manualError = param.value && param.key && !param.manual && isCustomString(param.value) ? 'Required' : '';

        if (keyError || valueError || manualError) {
          errors[idx] = { ...errors[idx], key: keyError, value: valueError, manual: manualError };
        } else {
          delete errors[idx];
        }
      });
      await this.handleSetState('errors', errors);
    } catch (e) {
      //
    }
  };

  valueGetter = (opt: FFSelectOption) => {
    switch (opt.value) {
      case 'custom_fields_custom_string':
        return `Custom String`;
      case 'custom_fields_url_data':
        return `{data-fieldname}`;
      case 'custom_fields_traffic_source_data':
        return `{data-traffic-source}`;
      default:
        return opt.value;
    }
  };

  onKeyChange = (idx: number) => {
    return async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value && !keyCheckRegex.test(e.target.value)) return;
      await this.handleSetState('queryParams', [
        ...this.state.queryParams.slice(0, idx),
        { ...this.state.queryParams[idx], key: withoutWhiteSpace(e.target.value) },
        ...this.state.queryParams.slice(idx + 1)
      ]);
      this.onChange();
    };
  };

  onValueChange = (idx: number) => {
    const row = this.state.queryParams[idx];
    return async (value: string) => {
      if (!isCustomString(value)) {
        await this.onManualValueChange(idx)('');
      } else {
        await this.onManualValueChange(idx)(row.manual || '');
      }
      await this.handleSetState('queryParams', [
        ...this.state.queryParams.slice(0, idx),
        { ...this.state.queryParams[idx], value },
        ...this.state.queryParams.slice(idx + 1)
      ]);
      this.onChange();
    };
  };

  onManualValueChange = (idx: number) => {
    return async (value: string | React.ChangeEvent<HTMLInputElement>) => {
      await this.handleSetState('queryParams', [
        ...this.state.queryParams.slice(0, idx),
        { ...this.state.queryParams[idx], manual: typeof value === 'string' ? value : value.target.value },
        ...this.state.queryParams.slice(idx + 1)
      ]);
      this.onChange();
    };
  };

  getQueryParams = (queryParams: Param[]) => {
    return queryParams.reduce((acc: { [key: string]: string }, item) => {
      if (item.manual) {
        acc[item.key] = getCustomFieldTypesValue(item.value, item.manual);
      } else if (item.key && item.value) {
        acc[item.key] = item.value;
      }
      return acc;
    }, {});
  };

  onChange = async () => {
    this.debounce(async () => {
      await this.checkErrors();
      const queryParams = await this.getQueryParams(this.state.queryParams);
      this.props.onChange!(queryParams);
      this.props.setError!(Object.keys(this.state.errors).length > 0);
    });
  };

  onAddRow = async () => {
    await this.handleSetState('queryParams', [...this.state.queryParams, defaultParam]);
    this.onChange();
  };

  onRemoveRow = (idx: number) => async () => {
    await this.handleSetState('queryParams', [...this.state.queryParams.slice(0, idx), ...this.state.queryParams.slice(idx + 1)]);
    this.onChange();
  };

  getErrors = (idx: number, key: 'key' | 'value' | 'manual') => {
    if (!this.props.showErrors) return;
    return this.state.errors?.[idx]?.[key];
  };

  render(): React.ReactNode {
    return (
      <>
        <FFAddGroup
          rows={this.state.queryParams}
          showAddRow={!this.props.disableAdd}
          onAddRow={this.onAddRow}
          headerRow={
            <FFAddGroup.Row>
              <FFAddGroup.Col>Fields</FFAddGroup.Col>
              <FFAddGroup.Col>Values to pass</FFAddGroup.Col>
              <VisibilityWrapper visible={!this.props.disableRemove}>
                <FFAddGroup.Col maxWidth={30} />
              </VisibilityWrapper>
            </FFAddGroup.Row>
          }
          renderRow={(row, rowIdx) => {
            return (
              <>
                <FFAddGroup.Col gap="8px">
                  <FFAddGroup.Row>
                    <FFAddGroup.Col>
                      <FFInput
                        value={row.key}
                        placeholder="Key"
                        disabled={this.props.disableFields}
                        onChange={this.onKeyChange(rowIdx)}
                        error={this.getErrors(rowIdx, 'key')}
                      />
                    </FFAddGroup.Col>
                    <FFAddGroup.Col>
                      <FFSelect
                        value={
                          row.manual
                            ? getCustomFieldTypesValue(row.value, row.manual)
                            : isTrafficSourceField(row.value)
                            ? getTrafficSourceField()
                            : row.value
                        }
                        options={getAttributes(this.props.pageName)}
                        valueGetter={this.valueGetter}
                        labelGetter={opt => opt.label}
                        disabled={this.props.disableFields}
                        placeholder="Value"
                        optionLabelProp="value"
                        groupOptions={true}
                        groupBy="category"
                        sortGroup={true}
                        showSearch
                        error={this.getErrors(rowIdx, 'value')}
                        onChange={this.onValueChange(rowIdx)}
                      />
                    </FFAddGroup.Col>
                    <VisibilityWrapper visible={!this.props.disableRemove}>
                      <FFAddGroup.Col maxWidth={30}>
                        <FFCircleButton iconName="general/line/delete-circle" onClick={this.onRemoveRow(rowIdx)} />
                      </FFAddGroup.Col>
                    </VisibilityWrapper>
                  </FFAddGroup.Row>
                  {showTextField(row.value) && (
                    <FFAddGroup.Row>
                      <FFInput
                        value={row.manual}
                        placeholder={getTextFieldPlaceholder(row.value)}
                        disabled={this.props.disableFields}
                        error={this.getErrors(rowIdx, 'manual')}
                        onChange={this.onManualValueChange(rowIdx)}
                      />
                    </FFAddGroup.Row>
                  )}
                </FFAddGroup.Col>
              </>
            );
          }}
        ></FFAddGroup>
      </>
    );
  }
}

import { apiAction } from './api';
import { setTableCols } from './table';
import { REPORTINGS, VIEW } from '../types';
import { REPORTINGS as API } from 'api/axios';
import { AnyAction, Dispatch } from 'redux';
import { DrilldownReportParams } from '../../model/drilldownReportParams';
import { DrilldownReport } from '../../model/drilldownReport';
import { ReportingView } from '../../types/reporting';
import { Attribute } from '../../model/attribute';
import { fetchFunnelGroups } from './funnelGroups';
import {
  fetchCategories as fetchLanderCategories,
  fetchLanders
} from './landers';
import { fetchCategories as fetchOfferCategories, fetchOffers } from './offers';
import { fetchTrafficSources } from './trafficsources';
import { fetchOfferSources } from './offersources';
import { fetchFunnels } from './funnels';
import { ReportingRestrictData } from 'types/ModalForms/reportingDataRestrict';
import { DrilldownReportRow } from 'model/drilldownReportRow';
import { SetReportingSettings, SetReportingTableParams } from 'types/actions';
import { TableColSizesObject, TableParams } from 'types/table';
import { getFilteredJourneyReportRows } from 'utils/redux';

const setReportingsData = (
  initial = true,
  payload = {},
  params = {},
  row: DrilldownReportRow = null as any
) => {
  return {
    type: initial ? REPORTINGS.SET_DATA_INITIAL : REPORTINGS.SET_DATA,
    payload,
    params,
    row
  };
};

const setTableColSizes = (payload: TableColSizesObject) => {
  return {
    type: REPORTINGS.SET_TABLE_COL_SIZES,
    payload
  };
};

const setReportingsAttributes = (payload: string[] = []) => {
  return {
    type: REPORTINGS.SET_ATTRIBUTES,
    payload
  };
};

const addReportingsView = (payload: ReportingView) => {
  return {
    type: REPORTINGS.ADD_VIEW,
    payload
  };
};

const deleteViews = () => {
  return {
    type: REPORTINGS.DELETE_ALL_VIEWS
  };
}

const deleteReportingsView = (payload: string) => {
  return {
    type: REPORTINGS.DELETE_VIEW,
    payload
  };
};

const setSettings = (key: string, data: any) => {
  return {
    type: REPORTINGS.SET_SETTINGS,
    payload: { key, data }
  };
};

const restrictDataReset = () => {
  return {
    type: REPORTINGS.RESET_RESTRICT_DATA
  };
};

const setTableParams = (key: keyof TableParams, value: any) => {
  return {
    type: REPORTINGS.SET_TABLE_PARAMS,
    payload: { key, value }
  };
};

interface DispatchRestrictData {
  (action: Function): AnyAction;
}

export const initFetchRestrictData = (data: ReportingRestrictData) => (
  dispatch: DispatchRestrictData
) => {
  if (!data.funnelGroups.length) {
    dispatch(fetchFunnelGroups());
  }
  if (!data.funnels.length) {
    dispatch(fetchFunnels());
  }
  if (!data.landerCategory.length) {
    dispatch(fetchLanderCategories());
  }
  if (!data.landers.length) {
    dispatch(fetchLanders());
  }
  if (!data.offerCategory.length) {
    dispatch(fetchOfferCategories());
  }
  if (!data.offers.length) {
    dispatch(fetchOffers());
  }
  if (!data.trafficSource.length) {
    dispatch(fetchTrafficSources());
  }
  if (!data.offerSource.length) {
    dispatch(fetchOfferSources());
  }
};

export const fetchReportings = (
  data: DrilldownReportParams,
  row: DrilldownReportRow = null as any,
  isVisitorJourneyPagesOnly = false,
  isVisitorJourneyPagesAndGroupsOnly = false
) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(data),
      onSuccess: (response: DrilldownReport) => {
        if (isVisitorJourneyPagesOnly || isVisitorJourneyPagesAndGroupsOnly) {
          response.rows = getFilteredJourneyReportRows(
            response,
            isVisitorJourneyPagesAndGroupsOnly
          );
        }
        dispatch(setReportingsData(false, response, data, row));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: REPORTINGS.FETCH_LIST
    })
  );
};

export const fetchReportingsInitial = (
  data: DrilldownReportParams,
  hasVisitorJourney = false
) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(data),
      onSuccess: (response: DrilldownReport) => {
        if (hasVisitorJourney) {
          response.rows = getFilteredJourneyReportRows(
            response,
            hasVisitorJourney
          );
        }
        dispatch(setReportingsData(true, response, data));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: REPORTINGS.FETCH_LIST
    })
  );
};

export const fetchReportingsAttributes = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_ATTRIBUTES(),
      onSuccess: (data: Attribute[]) => setReportingsAttributes(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: REPORTINGS.FETCH_ATTRIBUTES
    })
  );
};

export const setReportingTableCols = (cols: []) => (dispatch: Dispatch) => {
  return dispatch(setTableCols(cols, VIEW.REPORTINGS));
};

export const setReportingTableColSizes = (data: TableColSizesObject) => (
  dispatch: Dispatch
) => {
  return dispatch(setTableColSizes(data));
};

export const deleteAllViews = () => (dispatch: Dispatch) => {
  return dispatch(deleteViews());
}

export const addView = (data: ReportingView) => (dispatch: Dispatch) => {
  return dispatch(addReportingsView(data));
};

export const deleteView = (name: string) => (dispatch: Dispatch) => {
  return dispatch(deleteReportingsView(name));
};

export const editView = (data: ReportingView, previousViewName: string) => (
  dispatch: Dispatch
) => {
  dispatch(deleteReportingsView(previousViewName));
  return dispatch(addReportingsView(data));
};

export const setReportingSettings: SetReportingSettings = (key, data) => (
  dispatch: Dispatch
) => {
  dispatch(setSettings(key, data));
};

export const resetRestrictData = () => (
  dispatch: Dispatch
) => {
  dispatch(restrictDataReset());
};

export const setReportingTableParams: SetReportingTableParams = (key, data) => (
  dispatch: Dispatch
) => {
  dispatch(setTableParams(key, data));
};

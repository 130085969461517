import table from './table';
import { offers as defaultState } from './defaultStates';
import { OFFERS, API, VIEW, TABLE } from '../types';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import { OfferStore, PageArray } from '../../types/redux/store';
import { PageAction, ReportingParamsAction } from '../../types/redux/action';
import { getFieldName, getReverseStatus } from '../../utils/selectors';
import { Page } from '../../model/page';
import { Category } from '../../model/category';
import { DrilldownReport } from 'model/drilldownReport';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { ReportCategory } from 'model/reportCategory';
import { ReportOfferSource } from '../../model/reportOfferSource';
import { PageCategoryInfo } from 'model/pageCategoryInfo';
import { infoToResource } from 'utils/redux';
import { PageInfo } from 'model/pageInfo';

const data = (state = defaultState.data, action: PageAction) => {
  switch (action.type) {
    case OFFERS.SET_LIST:
      if (defined(action.payload)) {
        return (action.payload as Page[]).reduce((acc: PageArray, page) => {
          acc[page.idPage] = page;
          return acc;
        }, {});
      }
      return state;
    case OFFERS.SET_CATEGORY_INFO:
      if (defined(action.payload)) {
        (action.payload as PageCategoryInfo[]).forEach(pageCategoryInfo => {
          if (pageCategoryInfo.pages) {
            pageCategoryInfo.pages.forEach(pinfo => {
              if (!state[pinfo.idPage]) {
                state[pinfo.idPage] = infoToResource.page(
                  pinfo,
                  'offer',
                  pageCategoryInfo
                );
              }
            });
          }
        });
      }
      return state;
    case OFFERS.SET_INFO:
      if (defined(action.payload)) {
        (action.payload as PageInfo[]).forEach(pageInfo => {
          if (!state[pageInfo.idPage]) {
            state[pageInfo.idPage] = infoToResource.page(pageInfo, 'offer', {
              idCategory: state[pageInfo.idPage]?.idCategory
            });
          }
        });
      }
      return state;
    case OFFERS.DELETE:
      if (defined(action.payload)) {
        const deletePayload = (action.payload as { data: string[] })
          .data as string[];
        deletePayload.forEach(item => {
          if (defined(state[item])) {
            delete state[item];
          }
        });
        return state;
      }
      return state;
    case OFFERS.ARCHIVE:
      if (defined(action.payload)) {
        const id = (action.payload as any).data as string;
        if (!!id && !!state[id]) {
          state[id] = {
            ...state[id],
            status: getReverseStatus(state[id].status!)
          };
        }
        return state;
      }
      return state;
    case OFFERS.UPDATE_SINGLE:
    case OFFERS.ADD_SINGLE:
    case OFFERS.ADD_EXISTED_SINGLE:
      return {
        ...state,
        [(action.payload as Page).idPage]: action.payload
      };
    default:
      return state;
  }
};

const reporting = (
  state = defaultState.reporting,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case OFFERS.UPDATE_CATEGORY_ENTITIES:
    case OFFERS.CREATE_CATEGORY:
      const idCategory = (action.payload as any).idCategory;
      const categoryName = (action.payload as any).categoryName;
      if (defined(action.payload) && !!idCategory && !!categoryName) {
        return {
          ...state,
          entities: {
            ...state.entities,
            categories: state.entities!.categories!.filter(
              item => item.id === idCategory
            ).length
              ? state.entities!.categories
              : [
                  ...state.entities!.categories!,
                  {
                    id: idCategory,
                    name: categoryName,
                    type: 'offers' as ReportCategory.TypeEnum,
                    status: 'active'
                  } as ReportCategory
                ]
          }
        };
      }
      return state;
    case OFFERS.UPDATE_OFFER_SOURCE_ENTITIES:
      const idOfferSource = (action.payload as any).idOfferSource;
      const offerSourceName = (action.payload as any).offerSourceName;
      const categoryID = (action.payload as any).idCategory;
      if (defined(action.payload) && !!idOfferSource && !!offerSourceName) {
        return {
          ...state,
          entities: {
            ...state.entities,
            offerSources: state.entities!.offerSources!.filter(
              item => item.id === idOfferSource
            ).length
              ? state.entities!.offerSources
              : [
                  ...state.entities!.offerSources!,
                  {
                    id: idOfferSource,
                    name: offerSourceName,
                    categoryID: categoryID,
                    status: 'active'
                  } as ReportOfferSource
                ]
          }
        };
      }
      return state;
    case OFFERS.SET_REPORTING:
      if (defined(action.payload)) {
        return {
          ...(action.payload.data as DrilldownReport)
        };
      }
      return state;
    case OFFERS.ARCHIVE_CATEGORY:
      if (defined(action.payload)) {
        const id = action.payload.data as string;
        return {
          ...state,
          entities: {
            ...state.entities,
            categories: state.entities!.categories!.map(category => {
              if (category.id === id) {
                return {
                  ...category,
                  status: getReverseStatus(category.status)
                };
              }
              return category;
            })
          }
        };
      }
      return state;
    case OFFERS.ADD_SINGLE_ROW:
      if (defined(action.payload)) {
        return {
          ...state,
          rows: [...state.rows, action.payload.data as DrilldownReportRow]
        };
      }
      return state;
    case OFFERS.DELETE:
      if (defined(action.payload)) {
        const deletePayload = action.payload.data as string[];
        return {
          ...state,
          rows: [
            ...state.rows.filter(
              (item: any) => !deletePayload.includes(item.attributes[0].id)
            )
          ]
        };
      }
      return state;
    case OFFERS.ARCHIVE:
      if (defined(action.payload)) {
        const archivePayload = action.payload.data as string;
        return {
          ...state,
          rows: state.rows.map((item: DrilldownReportRow) => {
            if (item.attributes[0].id === archivePayload) {
              item.attributes[0].status = getReverseStatus(
                item.attributes[0].status
              );
            }
            return item;
          })
        };
      }
      return state;
    case OFFERS.UPDATE_SINGLE_REPORTING:
      if (defined(action.payload)) {
        return {
          ...state,
          rows: action.payload.data as DrilldownReportRow[]
        };
      }
      return state;
    case OFFERS.DELETE_CATEGORY:
      if (defined(action.payload)) {
        const categories = state.entities!.categories!.filter(category =>
          //@ts-ignore
          action.payload.indexOf(category.id)
        );

        return {
          ...state,
          entities: {
            ...state.entities,
            categories: categories
          }
        };
      }

      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loadings, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        [getFieldName(action, OFFERS.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: true
        }
      };
    case API.ENDED:
      return {
        ...state,
        [getFieldName(action, OFFERS.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: false,
          fetchedAt: Date.now()
        }
      };
    default:
      return state;
  }
};

const tableCols = (
  state = defaultState.settings.tableCols,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.payload.view === VIEW.OFFERS &&
    action.type === TABLE.SET_COLS
  ) {
    return table(state, action);
  } else {
    return state;
  }
};

const categories = (state = defaultState.categories, action: AnyAction) => {
  switch (action.type) {
    case OFFERS.SET_CATEGORY_INFO:
      if (defined(action.payload)) {
        return (action.payload as PageCategoryInfo[]).map(data =>
          infoToResource.pageCategory(data, 'offer')
        );
      }
      return state;
    case OFFERS.SET_CATEGORIES:
      return [...action.payload];
    case OFFERS.CREATE_CATEGORY:
      return [...state, ...[action.payload]];
    case OFFERS.UPDATE_CATEGORY:
      return state.reduce((acc: Category[], item: Category) => {
        acc.push(
          item.idCategory === action.payload.idCategory
            ? (action.payload as Category)
            : item
        );
        return acc;
      }, []);
    case OFFERS.ARCHIVE_CATEGORY:
      if (defined(action.payload)) {
        const id = action.payload.data as string;
        return state.map(category => {
          if (category.idCategory === id) {
            return {
              ...category,
              status: getReverseStatus(category.status!)
            };
          }
          return category;
        });
      }
      return state;
    case OFFERS.DELETE_CATEGORY:
      return state.filter(item => action.payload.indexOf(item.idCategory) < 0);
    default:
      return state;
  }
};

const show = (state = defaultState.settings.show, action: AnyAction) => {
  switch (action.type) {
    case OFFERS.SET_SHOW:
      return action.payload;
    default:
      return state;
  }
};

const filters = (
  state = defaultState.settings.filters,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case OFFERS.SET_REPORTING:
      return action.payload.params;
    default:
      return state;
  }
};

const editMode = (
  state = defaultState.settings.editMode,
  action: AnyAction
) => {
  return action.type === TABLE.SET_EDIT_MODE &&
    action.payload.view === VIEW.OFFERS
    ? action.payload.data
    : state;
};

const additionalParams = (
  state = defaultState.settings.additionalParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_ADDITIONAL_PARAMS &&
    action.payload.view === VIEW.OFFERS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const tableParams = (
  state = defaultState.settings.tableParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_TABLE_PARAMS &&
    action.payload.view === VIEW.OFFERS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

export default (state = defaultState, action: any): OfferStore => {
  return {
    data: data(state.data, action) as PageArray,
    settings: {
      show: show(state.settings.show, action),
      tableCols: tableCols(state.settings.tableCols, action),
      tableParams: tableParams(state.settings.tableParams, action),
      filters: filters(state.settings.filters, action),
      editMode: editMode(state.settings.editMode, action),
      additionalParams: additionalParams(
        state.settings.additionalParams,
        action
      )
    },
    reporting: reporting(state.reporting, action),
    categories: categories(state.categories, action),
    loadings: loading(state.loadings, action)
  };
};

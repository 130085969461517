import { METHOD_DELETE, METHOD_GET, METHOD_POST, METHOD_PUT } from '../constants/api';
import { RequestConfig, StatusEnum } from './types';
import { Page } from '../model/page';
import { Category } from '../model/category';
import { DeleteList } from '../model/deleteList';
import { TrafficSource } from '../model/trafficSource';
import { OfferSource } from '../model/offerSource';
import { FunnelGroup } from '../model/funnelGroup';
import { Funnel } from '../model/funnel';
import { DrilldownReportParams } from '../model/drilldownReportParams';
import { ConversionsUpdateParams } from '../model/conversionsUpdateParams';
import { UserSettings } from '../model/userSettings';
import { FunnelCondition, ResetUpdateParams, CostUpdateParams, PageGroup, OnboardingTrack, DomainEntry } from 'model/models';
import { DNSRecordParams } from 'types/domains';
import { DrilldownReport } from '../model/drilldownReport';
import { DrilldownReportRow } from '../model/drilldownReportRow';
import { ArchiveList } from '../model/archiveList';
import { MoveList } from '../model/moveList';
import { GenerateIntegrationAPIKeyRequest } from 'model/generateIntegrationAPIKeyRequest';
import { IntegrationAPIDeleteKey } from 'model/integrationAPIDeleteKey';
import { IntegrationAPIKey } from 'model/integrationAPIKey';
import { HitReportParams } from '../model/hits/hitReportParams';
import { FindByNameSchema } from '../model/findByName';
import { DrilldownReportRowAttribute } from 'model/drilldownReportRowAttribute';
import { FacebookAdsEntityStatusUpdate } from 'types/integrationReporting';

const DRILLDOWN_REPORT_REQUEST: RequestConfig = {
  method: METHOD_POST,
  url: `reporting/drilldown/`,
  responseTransformer: transformDrilldownReport
};

export const UPDATE_CONVERSIONS = {
  UPDATE: (data: ConversionsUpdateParams): RequestConfig => ({
    method: METHOD_PUT,
    url: 'reporting/update/conversions/',
    data
  })
};

export const FUNNELGROUPS = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/find/byStatus/`,
    params: { status }
  }),
  FETCH_INFO: (status: StatusEnum = 'active', includeChilds = true): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/list/`,
    params: { status: 'active', includeChilds }
  }),
  GET: (idCampaign: string): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/find/byId/`,
    params: { idCampaign: idCampaign }
  }),
  CREATE: (data: FunnelGroup): RequestConfig => ({
    method: METHOD_POST,
    url: 'campaign/save/',
    data
  }),
  UPDATE: (data: FunnelGroup): RequestConfig => ({
    method: METHOD_PUT,
    url: 'campaign/save/',
    data
  }),
  ARCHIVE: (data: FunnelGroup): RequestConfig => ({
    method: METHOD_PUT,
    url: 'campaign/save/',
    data
  }),
  DUPLICATE: (data: FunnelGroup): RequestConfig => ({
    method: METHOD_POST,
    url: `campaign/duplicate/`,
    params: { idCampaign: data.idCampaign },
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `campaign/delete/`,
    data
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  }),
  FETCH_GRAPH: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  })
};

export const REPORTINGS = {
  FETCH_LIST: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  }),
  FETCH_ATTRIBUTES: (): RequestConfig => ({
    method: METHOD_GET,
    url: `reporting/attributes/`
  })
};

export const LANDERS = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/find/byStatus/`,
    params: { status, pageType: 'lander' }
  }),
  FETCH_INFO: (status: StatusEnum = 'active'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/list/`,
    params: { status: 'active', pageType: 'lander' }
  }),
  FETCH_CATEGORY_INFO: (status: StatusEnum = 'active'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/category/list/`,
    params: { status: 'active', pageType: 'lander', includeChilds: true }
  }),
  CREATE: (data: Page): RequestConfig => ({
    method: METHOD_POST,
    url: 'page/save/',
    data
  }),
  GET: (idPage: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'page/find/byId/',
    params: { idPage: idPage }
  }),
  UPDATE: (data: Page): RequestConfig => ({
    method: METHOD_PUT,
    url: `page/save/`,
    data
  }),
  ARCHIVE: (data: Page): RequestConfig => ({
    method: METHOD_PUT,
    url: `page/save/`,
    data
  }),
  DUPLICATE: (data: Page): RequestConfig => ({
    method: METHOD_POST,
    url: `page/duplicate/`,
    params: { idPage: data.idPage },
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `page/delete/`,
    data
    //data: { selected: data.selected }
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  }),
  FIND_BY_CATEGORY: (idCategory: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'page/find/byCategory/',
    params: { idCategory }
  })
};

export const OFFERS = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/find/byStatus/`,
    params: { status, pageType: 'offer' }
  }),
  FETCH_INFO: (status: StatusEnum = 'active'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/list/`,
    params: { status: 'active', pageType: 'offer' }
  }),
  FETCH_CATEGORY_INFO: (status: StatusEnum = 'active'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/category/list/`,
    params: { status: 'active', pageType: 'offer', includeChilds: true }
  }),
  CREATE: (data: Page): RequestConfig => ({
    method: METHOD_POST,
    url: 'page/save/',
    data
  }),
  GET: (idPage: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'page/find/byId/',
    params: { idPage: idPage }
  }),
  UPDATE: (data: Page): RequestConfig => ({
    method: METHOD_PUT,
    url: `page/save/`,
    data
  }),
  ARCHIVE: (data: Page): RequestConfig => ({
    method: METHOD_PUT,
    url: `page/save/`,
    data
  }),
  DUPLICATE: (data: Page): RequestConfig => ({
    method: METHOD_POST,
    url: `page/duplicate/`,
    params: { idPage: data.idPage },
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `page/delete/`,
    data
    //data: { selected: data.selected }
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  }),
  FIND_BY_CATEGORY: (idCategory: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'page/find/byCategory/',
    params: { idCategory }
  })
};

export const OFFERSOURCES = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `offersource/find/byStatus/`,
    params: { status }
  }),
  CREATE: (data: OfferSource): RequestConfig => ({
    method: METHOD_POST,
    url: 'offersource/save/',
    data
  }),
  GET: (idOfferSource: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'offersource/find/byId/',
    params: { idOfferSource: idOfferSource }
  }),
  UPDATE: (data: OfferSource): RequestConfig => ({
    method: METHOD_PUT,
    url: `offersource/save/`,
    data
  }),
  ARCHIVE: (data: OfferSource): RequestConfig => ({
    method: METHOD_PUT,
    url: `offersource/save/`,
    data
  }),
  DUPLICATE: (data: OfferSource): RequestConfig => ({
    method: METHOD_POST,
    url: `offersource/duplicate/`,
    params: { idOfferSource: data.idOfferSource },
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `offersource/delete/`,
    data
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  }),
  FIND_BY_CATEGORY: (idCategory: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'offersource/find/byCategory/',
    params: { idCategory }
  })
};

export const FUNNELS = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/find/byStatus/`,
    params: { status }
  }),
  FETCH_LIST_BY_TYPE: (funnelType: Funnel.FunnelTypeEnum = 'visual'): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/find/byType/`,
    params: { funnelType }
  }),
  FETCH_INFO: (status: StatusEnum = 'active'): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/list/`,
    params: { status: 'active' }
  }),
  FIND_BY_FUNNELGROUP: (idCampaign: string, status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/find/byStatus/`,
    params: { status, idCampaign }
  }),
  GET: (idFunnel: string): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/find/byId/`,
    params: { idFunnel: idFunnel }
  }),
  CREATE: (data: Funnel): RequestConfig => ({
    method: METHOD_POST,
    url: 'campaign/funnel/save/',
    data
  }),
  DUPLICATE: (data: Funnel): RequestConfig => ({
    method: METHOD_POST,
    url: 'campaign/funnel/duplicate/',
    params: { idFunnel: data.idFunnel },
    data
  }),
  UPDATE: (data: Funnel): RequestConfig => ({
    method: METHOD_PUT,
    url: `campaign/funnel/save/`,
    data
  }),
  ARCHIVE_SINGLE: (data: Funnel): RequestConfig => ({
    method: METHOD_PUT,
    url: 'campaign/funnel/save/',
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: 'campaign/funnel/delete/',
    data
  }),
  ARCHIVE_LIST: (data: ArchiveList): RequestConfig => ({
    method: METHOD_PUT,
    url: 'campaign/funnel/archive/',
    data
  }),
  UNARCHIVE_LIST: (data: ArchiveList): RequestConfig => ({
    method: METHOD_PUT,
    url: 'campaign/funnel/unarchive/',
    data
  }),
  MOVE: (idCampaign: string, data: MoveList): RequestConfig => ({
    method: METHOD_PUT,
    url: `campaign/funnel/move?idCampaign=${idCampaign}`,
    data
  })
};

export const TRAFFICSOURCES = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `trafficsource/find/byStatus/`,
    params: { status: 'active' }
  }),
  FETCH_INFO: (status: StatusEnum = 'active'): RequestConfig => ({
    method: METHOD_GET,
    url: `trafficsource/list/`,
    params: { status: 'active' }
  }),
  FETCH_CATEGORY_INFO: (status: StatusEnum = 'not-deleted', includeChilds = true): RequestConfig => ({
    method: METHOD_GET,
    url: `trafficsource/category/list/`,
    params: { status: 'active', includeChilds }
  }),
  ARCHIVE: (data: TrafficSource): RequestConfig => ({
    method: METHOD_PUT,
    url: `trafficsource/save/`,
    data
    //data: { switched: data.switched }
  }),
  CREATE: (data: TrafficSource): RequestConfig => ({
    method: METHOD_POST,
    url: 'trafficsource/save/',
    data
  }),
  GET: (idTrafficSource: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'trafficsource/find/byId/',
    params: { idTrafficSource: idTrafficSource }
  }),
  UPDATE: (data: TrafficSource): RequestConfig => ({
    method: METHOD_PUT,
    url: `trafficsource/save/`,
    data
  }),
  DUPLICATE: (data: TrafficSource): RequestConfig => ({
    method: METHOD_POST,
    url: `trafficsource/duplicate/`,
    params: { idTrafficSource: data.idTrafficSource },
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `trafficsource/delete/`,
    data
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  }),
  FIND_BY_CATEGORY: (idCategory: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'trafficsource/find/byCategory/',
    params: { idCategory }
  })
};

export const CAMPAIGN_ANALYSIS = {
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  })
};

export const CATEGORIES = {
  FETCH_LIST: (categoryType: Category.CategoryTypeEnum, status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `category/find/byStatus/`,
    params: { status, categoryType }
  }),
  CREATE: (data: Category): RequestConfig => ({
    method: METHOD_POST,
    url: 'category/save/',
    data
  }),
  UPDATE: (data: Category): RequestConfig => ({
    method: METHOD_PUT,
    url: `category/save/`,
    data
  }),
  ARCHIVE: (data: Category): RequestConfig => ({
    method: METHOD_PUT,
    url: `category/save/`,
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `category/delete/`,
    data
  })
};

export const SYSTEM_SETTINGS = {
  FETCH: (): RequestConfig => ({
    method: METHOD_GET,
    url: `user/settings/`
  }),
  PUT: (data: UserSettings): RequestConfig => ({
    method: METHOD_PUT,
    url: `user/settings/`,
    data
  }),
  DELETE: (): RequestConfig => ({
    method: METHOD_PUT,
    url: `user/settings/`
  }),
  DELETE_DNS: (data: DNSRecordParams): RequestConfig => ({
    method: METHOD_DELETE,
    url: `dns/`,
    data
  }),
  EDIT_DNS: (data: DNSRecordParams): RequestConfig => ({
    method: METHOD_PUT,
    url: `dns/`,
    data
  }),
  ADD_DNS: (data: DNSRecordParams): RequestConfig => ({
    method: METHOD_POST,
    url: `dns/`,
    data
  })
};

export const USER = {
  FETCH: (): RequestConfig => ({
    method: METHOD_GET,
    url: `user`
  }),
  QUOTA: (): RequestConfig => ({
    method: METHOD_POST,
    url: `reporting/user/quota/`
  })
};

export const DOMAIN = {
  FETCH: (): RequestConfig => ({
    method: METHOD_GET,
    url: `edge/domains/`
  }),
  PUT: (data: DomainEntry): RequestConfig => ({
    method: METHOD_PUT,
    url: `edge/domains/`,
    data
  }),
  DELETE: (data: DomainEntry): RequestConfig => ({
    method: METHOD_DELETE,
    url: `edge/domains/`,
    data
  })
};

export const INTEGRATIONS = {
  FETCH: (): RequestConfig => ({
    method: METHOD_GET,
    url: `integration/api-keys/`
  }),
  CREATE: (data: GenerateIntegrationAPIKeyRequest): RequestConfig => ({
    method: METHOD_POST,
    url: `integration/api-keys/`,
    data
  }),
  DELETE: (data: IntegrationAPIDeleteKey): RequestConfig => ({
    method: METHOD_DELETE,
    url: `integration/api-keys/`,
    data
  }),
  UPDATE: (data: IntegrationAPIKey): RequestConfig => ({
    method: METHOD_PUT,
    url: `integration/api-keys/`,
    data
  })
};

export const RESET_DATA = {
  FETCH: (data: ResetUpdateParams): RequestConfig => ({
    method: METHOD_POST,
    url: `reporting/update/reset/`,
    data
  }),
  DELETE: (data: ResetUpdateParams): RequestConfig => ({
    method: METHOD_DELETE,
    url: `reporting/update/reset/`,
    data
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  })
};

export const CONDITIONS = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/condition/find/byStatus/`,
    params: { status }
  }),
  FETCH_LIST_BY_IDS: (ids: string[]): RequestConfig => ({
    method: METHOD_GET,
    url: 'campaign/funnel/condition/find/byIds/',
    params: { idConditions: ids.join(',') }
  }),
  FETCH_INFO: (status: StatusEnum = 'not-deleted', idFunnel?: string): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/condition/list/`,
    params: { status: 'active', idFunnel }
  }),
  FETCH_CATEGORY_INFO: (status: StatusEnum = 'not-deleted', idFunnel?: string, includeChilds = true): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/condition/category/list/`,
    params: { status: 'active', idFunnel, includeChilds }
  }),
  CREATE: (data: FunnelCondition): RequestConfig => ({
    method: METHOD_POST,
    url: 'campaign/funnel/condition/save/',
    data
  }),
  GET: (idCondition: string): RequestConfig => ({
    method: METHOD_GET,
    url: 'campaign/funnel/condition/find/byId/',
    params: { idCondition: idCondition }
  }),
  UPDATE: (data: FunnelCondition): RequestConfig => ({
    method: METHOD_PUT,
    url: `campaign/funnel/condition/save/`,
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `campaign/funnel/condition/delete/`,
    data
  }),
  DUPLICATE: (data: FunnelCondition): RequestConfig => ({
    method: METHOD_POST,
    url: `campaign/funnel/condition/duplicate/`,
    params: { idCondition: data.idCondition },
    data
  }),
  FIND_BY_NAME: (data: FindByNameSchema): RequestConfig => ({
    method: METHOD_GET,
    url: `campaign/funnel/condition/find/byName/`,
    params: data
  })
};

export const PAGE_GROUPS = {
  FETCH_LIST: (status: StatusEnum = 'not-deleted'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/group/find/byStatus/`,
    params: { status }
  }),
  FETCH_INFO: (
    status: StatusEnum = 'not-deleted',
    pageType: PageGroup.PageTypeEnum,
    includeChilds: boolean,
    idFunnel?: string
  ): RequestConfig => ({
    method: METHOD_GET,
    url: `page/group/list/`,
    params: { status: 'active', pageType, includeChilds, idFunnel }
  }),
  CREATE: (data: PageGroup): RequestConfig => ({
    method: METHOD_POST,
    url: `page/group/save/`,
    data
  }),
  GET: (idPageGroup: string): RequestConfig => ({
    method: METHOD_GET,
    url: `page/group/find/byId/`,
    params: { idPageGroup: idPageGroup }
  }),
  UPDATE: (data: PageGroup): RequestConfig => ({
    method: METHOD_PUT,
    url: `page/group/save/`,
    data
  }),
  ARCHIVE: (data: PageGroup): RequestConfig => ({
    method: METHOD_PUT,
    url: `page/group/save/`,
    data
  }),
  DELETE: (data: DeleteList): RequestConfig => ({
    method: METHOD_DELETE,
    url: `page/group/delete/`,
    data
  }),
  DUPLICATE: (data: PageGroup): RequestConfig => ({
    method: METHOD_POST,
    url: `page/group/duplicate/`,
    params: { idPageGroup: data.idPageGroup },
    data
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  }),
  FETCH_LANDERS: (status: StatusEnum = 'all'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/find/byStatus/`,
    params: { status, pageType: 'lander' }
  }),
  FETCH_OFFERS: (status: StatusEnum = 'all'): RequestConfig => ({
    method: METHOD_GET,
    url: `page/find/byStatus/`,
    params: { status, pageType: 'offer' }
  }),
  FIND_BY_NAME: (data: FindByNameSchema): RequestConfig => ({
    method: METHOD_GET,
    url: `page/group/find/byName/`,
    params: data
  })
};

export const UPDATE_TRAFFIC_COSTS = {
  UPDATE: (data: CostUpdateParams): RequestConfig => ({
    method: METHOD_PUT,
    url: `reporting/update/cost/`,
    data
  }),
  FETCH_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  })
};

export const APP = {
  FETCH_VERSION: (): RequestConfig => ({
    method: METHOD_GET,
    withoutCurrentVersion: true,
    url: 'meta.json'
  })
};

export const BUILDER = {
  FETCH_HEATMAP_REPORTING: (data: DrilldownReportParams): RequestConfig => ({
    ...DRILLDOWN_REPORT_REQUEST,
    data
  })
};

export const SCRIPT = {
  VIEW: (domain: string, idFunnel: string, idNode: string, idPage: string, idTrafficSource: string, cost: string): RequestConfig => ({
    method: METHOD_GET,
    url: `script/event/view`,
    params: {
      domain,
      idFunnel,
      idNode,
      idPage,
      idTrafficSource,
      cost
    }
  }),
  CONVERSION: (domain: string, revenue: string, transaction: string, idPage: string): RequestConfig => ({
    method: METHOD_GET,
    url: `script/event/conversion`,
    params: {
      domain,
      revenue,
      transaction,
      idPage
    }
  })
};

export const ONBOARDING = {
  TRACK: (data: OnboardingTrack): RequestConfig => ({
    method: METHOD_POST,
    url: `onboarding/track`,
    data
  }),
  STATUS: (): RequestConfig => ({
    method: METHOD_GET,
    url: `onboarding/status`
  })
};

export const HITS = {
  FETCH_HITS: (data: HitReportParams): RequestConfig => ({
    method: METHOD_POST,
    url: `reporting/hits`,
    data
  })
};

// Google Ads
const getGoogleAdsUrl = (path: string, googleAdsCustomerId: string) => {
  const url = new URL(`https://url.com/api/google/${path}`);
  [['customer_id', googleAdsCustomerId]]
    .filter(([_, value]) => value)
    .forEach(([key, value]) => url.searchParams.append(key, value));
  return url.pathname.slice(1) + url.search;
};

export const GOOGLE_ADS = {
  GET_USERS: (): RequestConfig => ({
    method: METHOD_GET,
    url: `api/google/users/all`
  }),
  GET_CUSTOMER_CLIENTS: (googleAdsUserId: string): RequestConfig => ({
    method: METHOD_GET,
    url: getGoogleAdsUrl('listAccessibleCustomers', googleAdsUserId)
  }),
  FETCH_REPORTING: (data: DrilldownReportParams, resourceName: string, googleAdsUserId: string): RequestConfig => ({
    method: METHOD_POST,
    url: getGoogleAdsUrl(`reporting?resourceName=${resourceName}`, googleAdsUserId),
    responseTransformer: transformDrilldownReport,
    data
  }),
  FETCH_GOOGLE_REPORTING: (data: DrilldownReportParams, resourceName: string, googleAdsUserId: string): RequestConfig => ({
    method: METHOD_POST,
    url: getGoogleAdsUrl(`reporting?resourceName=${resourceName}`, googleAdsUserId),
    responseTransformer: transformDrilldownReport,
    data
  }),
  UPDATE_AD_STATUS: (
    resourceName: string,
    adGroupId: string,
    adId: string,
    status: DrilldownReportRowAttribute.GoogleAdsStatusEnum,
    googleAdsUserId: string
  ): RequestConfig => ({
    method: METHOD_PUT,
    url: getGoogleAdsUrl(
      `customers/ads/${adId}/mutate?status=${status}&resourceName=${resourceName}&adGroupId=${adGroupId}`,
      googleAdsUserId
    )
  }),
  UPDATE_OPTIONAL_SEGMENTATION: (
    resourceName: string,
    adGroupId: string,
    id: string,
    status: DrilldownReportRowAttribute.GoogleAdsStatusEnum,
    googleAdsUserId: string
  ): RequestConfig => ({
    method: METHOD_PUT,
    url: getGoogleAdsUrl(
      `customers/adGroupCriteria/${id}/mutate?status=${status}&resourceName=${resourceName}&adGroupId=${adGroupId}`,
      googleAdsUserId
    )
  }),
  UPDATE_ADGROUP_STATUS: (
    resourceName: string,
    adGroupId: string,
    status: DrilldownReportRowAttribute.GoogleAdsStatusEnum,
    googleAdsUserId: string
  ): RequestConfig => ({
    method: METHOD_PUT,
    url: getGoogleAdsUrl(`customers/adGroup/${adGroupId}/mutate?status=${status}&resourceName=${resourceName}`, googleAdsUserId)
  }),
  UPDATE_CAMPAIGN_STATUS: (
    resourceName: string,
    campaignId: string,
    status: DrilldownReportRowAttribute.GoogleAdsStatusEnum,
    googleAdsUserId: string
  ): RequestConfig => ({
    method: METHOD_PUT,
    url: getGoogleAdsUrl(`customers/campaign/${campaignId}/mutate?status=${status}&resourceName=${resourceName}`, googleAdsUserId)
  })
};

// Facebook Ads
const getFacebookAdsUrl = (path: string, facebookAdsAdAccountId: string, facebookAdsCustomerId: string) => {
  const url = new URL(`https://url.com/api/facebook/${path}`);
  [
    ['ad_account_id', facebookAdsAdAccountId],
    ['customer_id', facebookAdsCustomerId]
  ]
    .filter(([_, value]) => value)
    .forEach(([key, value]) => url.searchParams.append(key, value));
  return url.pathname.slice(1) + url.search;
};

export const FACEBOOK_ADS = {
  GET_AD_ACCOUNTS: (facebookAdsAdAccountId: string, facebookAdsCustomerId: string): RequestConfig => ({
    method: METHOD_GET,
    url: getFacebookAdsUrl(`adaccounts/all`, facebookAdsAdAccountId, facebookAdsCustomerId)
  }),
  GET_USERS: (facebookAdsAdAccountId: string, facebookAdsCustomerId: string): RequestConfig => ({
    method: METHOD_GET,
    url: getFacebookAdsUrl(`users`, facebookAdsAdAccountId, facebookAdsCustomerId)
  }),
  FETCH_REPORTING: (data: DrilldownReportParams, facebookAdsAdAccountId: string, facebookAdsCustomerId: string): RequestConfig => ({
    method: METHOD_POST,
    url: getFacebookAdsUrl(`reporting`, facebookAdsAdAccountId, facebookAdsCustomerId),
    responseTransformer: transformDrilldownReport,
    data
  }),
  MUTATE_STATUS: (data: FacebookAdsEntityStatusUpdate, facebookAdsAdAccountId: string, facebookAdsCustomerId: string): RequestConfig => ({
    method: METHOD_POST,
    url: getFacebookAdsUrl('mutate', facebookAdsAdAccountId, facebookAdsCustomerId),
    data
  })
};

// transforms all string-decimal values into Number (float) type, this can cause rounding errors
function transformDrilldownReport(report: DrilldownReport): DrilldownReport {
  if (Array.isArray(report.rows)) {
    report.rows.forEach(castRowDecimalValues);
  }
  if (Array.isArray(report.rootRows)) {
    report.rootRows.forEach(castRowDecimalValues);
  }
  return report;

  function castRowDecimalValues(row: DrilldownReportRow) {
    row.cost = Number(row.cost);
    row.revenue = Number(row.revenue);
    row.indirectRevenue = Number(row.indirectRevenue);
  }
}

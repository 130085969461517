import React, { createRef, RefObject } from 'react';
import Messages from 'components/Messages';
import { Input, Select } from 'components/Parts/Inputs';
import validateForm, { required, unique, xxxUrl } from 'utils/validation';
import { UNCATEGORIZED } from 'constants/modal';
import { ReactiveValidateComponent, ReactiveValidateState } from '../../../utils/reactive/generic';
import batchedUpdates from 'utils/state/batchedUpdates';
import { AddButton as OpenButton, CopyButton } from 'components/Parts/Buttons';
import { FormContentWrapper, FormHelpSection, FormSectionBox } from 'components/Parts/Content';
import { addUrlSlash, getQueryUrlSeparate } from 'utils/url';
import { Page } from '../../../model/page';
import { Category } from '../../../model/category';
import { generateEntityId } from '../../../utils/id/generator';
import { AnyObject, MessageProps, SelectOption, StringObject } from '../../../types';
import { OfferSource } from '../../../model/offerSource';
import { redirects } from '../../../constants/form';
import {
  trimStringPropertiesInObject,
  removeCommaOrMultipleDot,
  removeNonDigits,
  withoutWhiteSpace,
  removeMoreThenTwoNumbersAfterDot
} from 'utils/string';
import { DUPLICATE_RECORD_CODE } from '../../../constants/errors';
import { OfferFormProps } from 'types/ModalForms/offers';
import { defined, definedObject } from '../../../utils/define';
import { finalUrl, handleScrollToErrorElement } from '../../../utils/validation';
import { copy, getDuplicateModalSidebarContext } from 'utils/copy';
import Icon from '../../Icons';
import './style.scss';
import { cloneObject } from '../../../utils/object';
import { DomainEntry } from 'model/models';
import { getPageActionLink, getCorrectActionNumber } from 'utils/linkJs';
import { NO_OFFER_SOURCE } from 'constants/index';
import { isCopyByContextModal } from 'utils/modals';
import { getActiveEntities, withIncrementedVersion } from '../../../utils/model';
import { ValidationRule } from '../../../utils/validation/types';
import { OfferParamsExternalProductIds } from 'model/offerParamsExternalProductIds';
import { asyncVersionConfirmSidebar, getVisibilityByActiveTab } from 'utils/dynamic-sidebar';
import { LoadingProps } from 'types/loading';
import {
  OFFER_FORM_TAB,
  OFFER_FORM_CONFIGURE_DATA_PASSING_TAB,
  OFFER_FORM_CONVERSION_TRACKING_TAB,
  OFFER_FORM_HELP_TAB,
  OFFER_FORM_INTEGRATION_PRODUCT_IDS_TAB,
  OFFER_FORM_JAVASCRIPT_TAB,
  OFFER_FORM_PAGE_ACTION_LINKS_TAB
} from 'constants/dynamicSidebar';
import { SidebarContext } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import { allOnActionNumbers } from 'constants/builder';
import CodeSnippet from 'components/CodeSnippet';
import { FFCol, FFField, FFInput, FFNewButton, FFRow, FFSelect, FFSwitch, FFTextarea, VisibilityWrapper } from 'uikit';
import { InputRef, Tag } from 'antd';
import NewDataPassing from 'components/DataPassingNew';

interface State extends ReactiveValidateState {
  pageNames: string[];
  model: Page;
  inheritedQueryParams: { [key: string]: string };
  anotherModel: AnyObject;
  isTouchedForm: boolean;
  validationErrors: AnyObject;
  offerSource: OfferSource;
  dataPassingErrors: {
    offerSource: boolean;
    offer: boolean;
  };
  isSubmitted: boolean;
  showErrorTooltip: boolean;
  domain: string;
  actionNumber: string;
}

const defaultModelState: Page = {
  idPage: '',
  pageName: '',
  pageType: 'offer',
  idCategory: undefined,
  status: 'active',
  baseURL: '',
  queryParams: {},
  redirectType: '301',
  offerParams: {
    idOfferSource: '',
    payout: '0',
    externalProductIds: {
      clickfunnels: '',
      shopify: ''
    }
  },
  notes: '',
  disableAppendVid: false
};

const defaultOfferSource: OfferSource = {
  idOfferSource: '',
  offerSourceName: '',
  status: 'active'
};

const defaultState = (): State => ({
  pageNames: [],
  model: defaultModelState,
  anotherModel: {
    finalUrl: '',
    source: 'default',
    queryParams: {}
  },
  inheritedQueryParams: {},
  isTouchedForm: false,
  validationErrors: {},
  dataPassingErrors: {
    offerSource: false,
    offer: false
  },
  isSubmitted: false,
  showErrorTooltip: false,
  offerSource: defaultOfferSource,
  domain: '',
  actionNumber: '1'
});

class OfferForm extends ReactiveValidateComponent<OfferFormProps & MessageProps & LoadingProps, State> {
  elRefs = {
    baseURL: createRef<InputRef>(),
    finalUrl: createRef<HTMLDivElement>(),
    postbackUrl: createRef<HTMLDivElement>(),
    actionURL: createRef<HTMLDivElement>(),
    conversionJsFull: createRef<HTMLDivElement>(),
    conversionJsSingle: createRef<HTMLDivElement>(),
    jsTrackingHeader: createRef<HTMLDivElement>(),
    defaultParams: createRef<HTMLDivElement>(),
    genericViewSingle: createRef<HTMLDivElement>()
  };

  state: State = defaultState();

  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  static defaultProps: Partial<OfferFormProps> = {
    categories: [],
    offerSources: []
  };

  static getDerivedStateFromProps(props: OfferFormProps, state: State) {
    if (
      state.model.idCategory !== '' &&
      props.categories.filter((category: Category) => category.idCategory === state.model.idCategory).length === 0
    ) {
      return {
        model: {
          ...state.model,
          idCategory: ''
        }
      };
    }

    return null;
  }

  async componentDidMount() {
    if (defined(this.props.setButtonGroupProps)) {
      this.props.setButtonGroupProps({
        showSave: true,
        saveText: isCopyByContextModal(this.props.contextModal) ? `DUPLICATE` : `SAVE`,
        onSaveClicked: this.handleSubmit,
        showCancel: true,
        cancelText: 'DISCARD'
      });
    }

    if (this.props.setForCopyIdsProps) {
      this.props.setForCopyIdsProps({
        'Offer ID': this.props.contextModal.entityId!
      });
    }

    this.props.setSidebarLoading!(true);
    await this.onDidMount();
    this.props.setSidebarLoading!(false);

    await this.props.fetchDomains();
  }

  onDidMount = async () => {
    this.setValidateSubscribe();
    await this.props.fetchOfferSourceCategories();
    await this.props.fetchOfferSources('not-deleted');
    await this.props.fetchCategories();
    await this.handleStateValuesChange('domain', this.props.domain);
    await this.handleStateValuesChange(
      'pageNames',
      Object.values(this.props.offers).map(offer => offer.pageName)
    );

    if (this.props.contextModal.entityId) {
      const id = this.props.contextModal.entityId!;
      await this.props.getOfferById(id);
      await this.setEditModel(id);
    } else {
      this.setDefaultRedirectType();
    }

    if (!defined(this.state.model.redirectType)) {
      this.setDefaultRedirectType();
    }
  };

  setDefaultRedirectType = async () => {
    this.handleStateModelValuesChange('redirectType', this.props.redirectType!);
  };

  handleStateValuesChange = async <T extends State, P extends keyof T>(name: P, value: T[P], withValidation = true) => {
    const validate = () => withValidation && this.validate$.next();
    await this.setState(
      (state: State) => ({
        ...state,
        [name]: value
      }),
      validate
    );
  };

  handleStateModelValuesChange = <T extends Page, P extends keyof T>(name: P, value: T[P]) => {
    const validate = () => this.validate$.next();
    this.setState(
      (state: State) => ({
        ...state,
        model: {
          ...state.model,
          [name]: value
        }
      }),
      validate
    );
  };

  handleStateAnotherModelValuesChange = (name: string, value: any) => {
    this.setState((state: State) => ({
      ...state,
      anotherModel: {
        ...state.anotherModel,
        [name]: value
      }
    }));
  };

  handleExternalProductIdsChange = (key: keyof OfferParamsExternalProductIds, value: string) => {
    this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        offerParams: {
          ...(state.model.offerParams! || {}),
          externalProductIds: {
            ...(state.model.offerParams?.externalProductIds! || {}),
            [key]: value
          }
        }
      }
    }));
  };

  setEditModel = async (id: string) => {
    const editModel = defined(this.props.offers) && defined(this.props.offers[id]) ? cloneObject(this.props.offers[id]) : null;

    if (defined(editModel)) {
      editModel.disableAppendVid = editModel.disableAppendVid || false;
      if (isCopyByContextModal(this.props.contextModal)) {
        // @ts-ignore
        editModel.pageName = this.props.contextModal.copyName;
        editModel.status = 'active';
      }

      const offerSource: OfferSource = this.props.offerSources.find(
        offerSource => defined(editModel.offerParams) && offerSource.idOfferSource === editModel.offerParams!.idOfferSource
      )!;

      if (editModel.offerParams?.externalProductIds) {
        editModel.offerParams.externalProductIds = {
          ...(defaultModelState.offerParams?.externalProductIds || {}),
          ...(editModel.offerParams.externalProductIds || {})
        } as OfferParamsExternalProductIds;
      }

      editModel.offerParams = !editModel.offerParams!.idOfferSource
        ? {
            ...editModel.offerParams,
            idOfferSource: NO_OFFER_SOURCE
          }
        : editModel.offerParams;

      this.setState((state: State) => ({
        model: {
          ...state.model,
          ...editModel
        },
        offerSource: offerSource! || state.offerSource,
        domain: this.props.domain,
        anotherModel: {
          ...state.anotherModel,
          queryParams: editModel.queryParams
        },
        inheritedQueryParams: offerSource?.queryParams || {},
        pageNames: state.pageNames.filter(name => name !== editModel.pageName)
      }));
    }
  };

  getHit = () => this.state.offerSource?.postbackSubId;

  getTx = () => this.state.offerSource?.postbackTxId;

  getRev = () => this.state.offerSource?.postbackPayout;

  validationRules = (): { [key: string]: ValidationRule } => ({
    pageName: {
      field: 'pageName',
      validations: [required, unique(this.state.pageNames, 'offer')],
      tab: OFFER_FORM_TAB
    },
    baseURL: {
      field: 'baseURL',
      validations: [required, xxxUrl],
      tab: OFFER_FORM_TAB
    },
    idOfferSource: {
      field: 'idOfferSource',
      validations: [required],
      tab: OFFER_FORM_TAB
    },
    finalUrl: {
      field: 'finalUrl',
      validations: [finalUrl(this.state.model)],
      tab: OFFER_FORM_CONFIGURE_DATA_PASSING_TAB
    }
  });

  handleDataPassingChanges = (value: StringObject) => {
    this.handleStateModelValuesChange('queryParams', value);
  };

  makeFinalUrl = (baseUrl: string) => {
    const pairs = Object.entries({
      ...(this.state.inheritedQueryParams || {}),
      ...(this.state.model.queryParams || {})
    })
      .reduce((acc: string[], [key, value]: [string, string]) => (!!key && !!value ? [...acc, `${key}=${value}`] : acc), [])
      .join('&');

    return !!baseUrl ? `${addUrlSlash(baseUrl)}${getQueryUrlSeparate(pairs, baseUrl)}${pairs}` : undefined;
  };

  addQueryParams = (value: string, offerSource: OfferSource) => {
    batchedUpdates(() => {
      this.handleStateModelValuesChange('offerParams', {
        ...this.state.model.offerParams,
        idOfferSource: value
      });
      this.handleStateValuesChange('offerSource', offerSource);
      this.handleStateValuesChange('inheritedQueryParams', offerSource?.queryParams || {});
    });
  };

  handleCopyClick = (context: RefObject<any>) => {
    copy(context, this.props.showMessage, Messages);
  };

  handleSaveAndCreate = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.startLoading('saveAndCreate');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm(true);
    }
  };

  getConvertedNoOfferSource = (model: Page): Page => ({
    ...model,
    offerParams: {
      ...model.offerParams,
      idOfferSource:
        defined(model.offerParams!.idOfferSource) && model.offerParams!.idOfferSource === NO_OFFER_SOURCE
          ? ''
          : model.offerParams!.idOfferSource
    }
  });

  validateOfferPayout = (model: Page) => {
    if (model?.offerParams?.payout === '') {
      model.offerParams.payout = '0';
    }
    return model;
  };

  setTabsError = () => {
    if (this.props.setTabsError) {
      const tabs = Object.keys(this.state.validationErrors || {}).map(k => this.validationRules()?.[k]?.tab || '');
      if (this.state.dataPassingErrors.offer || this.state.dataPassingErrors.offerSource) {
        tabs.push(OFFER_FORM_CONFIGURE_DATA_PASSING_TAB);
      }
      this.props.setTabsError(tabs);
    }
  };

  performCreateOrUpdate = async () => {
    await this.setTouchedForm();
    await this.validate();
    let model = this.validateOfferPayout(trimStringPropertiesInObject(this.state.model, ['pageName']));
    this.setTabsError();
    const hasDataPassingErrors = this.state.dataPassingErrors.offer || this.state.dataPassingErrors.offerSource;
    if (model.idCategory === UNCATEGORIZED) {
      delete model.idCategory;
    }

    if (!Object.keys(this.state.validationErrors).length && !hasDataPassingErrors) {
      let editedModel = this.getConvertedNoOfferSource(model);
      try {
        if (isCopyByContextModal(this.props.contextModal)) {
          await this.props.handleDuplicate({
            ...editedModel,
            meta: { version: 1 }
          });
        } else if (!!this.state.model.idPage) {
          await this.props.getOfferById(model.idPage);
          if (editedModel?.meta && editedModel.meta?.version !== this.props.offers[editedModel.idPage]?.meta?.version) {
            const sidebar = this.context as SidebarProps;
            try {
              await asyncVersionConfirmSidebar(sidebar);
              await this.props.handleUpdate(withIncrementedVersion(editedModel, this.props.offers[editedModel.idPage]?.meta?.version));
            } catch (e) {
              this.props.stopLoading('all');
              this.props.closeSelf();
              return false;
            }
          } else {
            await this.props.handleUpdate(withIncrementedVersion(editedModel));
          }
        } else {
          const newID = generateEntityId();
          await this.props.handleCreate({
            ...editedModel,
            idPage: newID,
            meta: { version: 1 }
          });
          await this.setState({
            model: {
              ...this.state.model,
              idPage: newID
            }
          });
        }

        this.props.stopLoading('all');
        this.props.showMessage(Messages.success(`${model.pageName} ${!!model.idPage ? 'has been updated' : 'has been added'}`));
        if (defined(this.props.contextModal.onSubmit)) {
          this.props.contextModal.onSubmit(editedModel);
        }
      } catch (e) {
        if (defined(e.response) && e.response.status === DUPLICATE_RECORD_CODE) {
          this.handleStateValuesChange('pageNames', [...this.state.pageNames, this.state.model.pageName]);
        }

        await this.props.fetchOfferSourceCategories();

        this.props.showMessage(
          Messages.failed(`${this.state.model.pageName} ${!!this.state.model.idPage ? 'cannot be updated' : 'cannot be added'}`)
        );
        this.props.stopLoading('all');
        return false;
      }

      return true;
    } else {
      this.props.stopLoading('all');
    }

    return false;
  };

  handleDisableAppendVid = () => {
    this.handleStateModelValuesChange('disableAppendVid', !this.state.model.disableAppendVid);
  }

  setDataPassingError = (value: boolean, type: 'offerSource' | 'offer') => {
    this.handleStateValuesChange('dataPassingErrors', {
      ...this.state.dataPassingErrors,
      [type]: value
    });
  };

  handleSubmit = async (e: Event) => {
    this.setState((state: State) => ({
      ...state,
      isSubmitted: true
    }));

    if (defined(e)) {
      e.preventDefault();
    }
    this.props.startLoading('save');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm(false);
      this.props.closeSelf();
    }

    return false;
  };

  resetForm = async (isCreateAndNew = false) => {
    if (isCreateAndNew) {
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          pageName: getDuplicateModalSidebarContext(this.state.model.idPage, this.props.offers),
          idPage: ''
        }
      });
    }
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      const model = {
        ...this.state.model,
        ...this.state.anotherModel,
        ...{
          idOfferSource: !!this.state.model.offerParams!.idOfferSource ? this.state.model.offerParams!.idOfferSource : undefined
        }
      };
      await this.setState({ validationErrors: {}, showErrorTooltip: false });
      const [validationErrors] = validateForm(model, Object.values(this.validationRules()));
      this.handleStateValuesChange('validationErrors', validationErrors, false);

      handleScrollToErrorElement(validationErrors, (entries: IntersectionObserverEntry[]) => {
        if (defined(entries[0]) && entries[0].isIntersecting) {
          this.setState({ showErrorTooltip: true });
        }
      });
    }
  };

  validateClassName = (field: string, idx?: number) => {
    return (idx !== undefined && !!this.state.validationErrors[field] && !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? 'has-error'
      : '';
  };

  validateTooltip = (field: string, idx?: string) => {
    return (idx !== undefined && !!this.state.validationErrors[field] && !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  definedOfferSourceQueryParams = (offerSource: OfferSource) => defined(offerSource) && defined(offerSource.queryParams);

  changeOfferSource = (value: string) => {
    const offerSource = this.props.offerSources.find((item: OfferSource) => item.idOfferSource === value) || {
      idOfferSource: '',
      offerSourceName: ''
    };

    this.handleStateAnotherModelValuesChange('source', value);
    this.addQueryParams(value, offerSource!);
  };

  renderOfferSourceInheritedParams = () => {
    return !(
      !this.state.model.offerParams?.idOfferSource ||
      this.state.model.offerParams?.idOfferSource === NO_OFFER_SOURCE ||
      !definedObject(this.state.offerSource.queryParams)
    );
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <form className="cform-offer">
        <FormContentWrapper show={getVisibilityByActiveTab(OFFER_FORM_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle!}>
            <FFRow gap={10} marginBottom={15}>
              <FFField label="Offer Name" htmlFor="pageName" block>
                <FFInput
                  id="pageName"
                  name="pageName"
                  error={this.validateTooltip('pageName')}
                  placeholder="Offer Name"
                  value={this.state.model.pageName}
                  onChange={e => this.handleStateModelValuesChange('pageName', e.target.value)}
                />
              </FFField>
              <FFField label="Offer Source" htmlFor="idOfferSource">
                <FFSelect
                  id="idOfferSource"
                  data-testid="offerSource"
                  placeholder="Select source"
                  dropdownMatchSelectWidth={300}
                  groupOptions={true}
                  groupBy="category"
                  valueGetter={(option: OfferSource) => option.idOfferSource}
                  labelGetter={(option: OfferSource) => option.offerSourceName}
                  sortGroup={true}
                  defaultValueFallback={{
                    label: NO_OFFER_SOURCE,
                    value: NO_OFFER_SOURCE
                  }}
                  value={this.state.model.offerParams!.idOfferSource}
                  options={getActiveEntities(this.props.offerSources).map((item: OfferSource) => {
                    const category = this.props.offerSourcesCategories.find(c => c.idCategory === item.idCategory);
                    return {
                      ...item,
                      category: category?.categoryName || UNCATEGORIZED
                    };
                  })}
                  onChange={(value: string) => this.changeOfferSource(value)}
                  showSearch={true}
                  filterOption={true}
                  style={{ width: 150 }}
                />
                <OpenButton onClick={this.props.openOfferSourceModal} />
              </FFField>
              <FFField label="Category" htmlFor="Category">
                <FFSelect
                  dropdownMatchSelectWidth={300}
                  id="category"
                  placeholder={UNCATEGORIZED}
                  options={getActiveEntities(this.props.categories)}
                  valueGetter={option => option.idCategory}
                  labelGetter={option => option.categoryName}
                  value={this.state.model.idCategory}
                  defaultValueFallback={{
                    label: UNCATEGORIZED,
                    value: UNCATEGORIZED
                  }}
                  onChange={(value: string) => this.handleStateModelValuesChange('idCategory', value)}
                  showSearch={true}
                  filterOption={true}
                  style={{ width: 150 }}
                />
                <OpenButton data-testid="open-category-form" onClick={this.props.openAddNewCategory} />
              </FFField>
            </FFRow>
            <FFRow marginBottom={15}>
              <FFField
                block
                label="Base Offer URL"
                htmlFor="baseURL"
                tootlipContent={
                  <>
                    Put your offer page URL here. You can configure appending of extra data (query string) in the{' '}
                    <strong>Configure Data Passing</strong> section. You can still use tokens in this base URL.
                  </>
                }
              >
                <FFInput
                  id="baseURL"
                  name="baseURL"
                  data-testid="baseURL"
                  placeholder="Offer URL"
                  forwardedRef={this.elRefs.baseURL}
                  value={this.state.model.baseURL}
                  error={this.validateTooltip('baseURL')}
                  onChange={e => this.handleStateModelValuesChange('baseURL', withoutWhiteSpace(e.target.value))}
                />
                <CopyButton data-testid="baseURL-copyBtn" onClick={() => this.handleCopyClick(this.elRefs.baseURL)} />
              </FFField>
            </FFRow>

            <FFRow gap={10} marginBottom={15}>
              <FFField label="Redirect Type" htmlFor="redirectType">
                <FFSelect
                  id="redirectType"
                  data-testid="redirectType"
                  placeholder="Select redirect Type"
                  valueGetter={(option: SelectOption) => option.value}
                  labelGetter={(option: SelectOption) => option.label}
                  options={redirects}
                  value={this.state.model.redirectType}
                  onChange={(value: Page.RedirectTypeEnum) => this.handleStateModelValuesChange('redirectType', value)}
                />
              </FFField>
              <FFField label="Offer Payout" htmlFor="payout">
                <FFInput
                  id="payout"
                  name="payout"
                  data-testid="payout"
                  placeholder="Offer Payout"
                  value={this.state.model.offerParams!.payout}
                  onChange={e =>
                    this.handleStateModelValuesChange('offerParams', {
                      ...this.state.model.offerParams!,
                      payout: removeMoreThenTwoNumbersAfterDot(removeCommaOrMultipleDot(removeNonDigits(e.target.value)))
                    })
                  }
                />
              </FFField>
            </FFRow>
            <FFRow marginBottom={15}>
              <FFField label="Notes" htmlFor="Notes" block>
                <FFTextarea
                  id="notes"
                  name="notes"
                  rows={4}
                  data-testid="notes"
                  placeholder="Notes"
                  value={this.state.model.notes!}
                  onChange={e => this.handleStateModelValuesChange('notes', e.target.value)}
                />
              </FFField>
            </FFRow>
            <VisibilityWrapper visible={!isCopyByContextModal(this.props.contextModal)}>
              <FFNewButton onClick={this.handleSaveAndCreate} loading={this.props.loading.saveAndCreate}>
                Save & Create New
              </FFNewButton>
            </VisibilityWrapper>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          className="dataPassing"
          shouldMount
          show={getVisibilityByActiveTab(OFFER_FORM_CONFIGURE_DATA_PASSING_TAB, this.props.activeTab)}
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <span>Here you can configure additional data that gets appended to your base page URL.</span>
          </FormSectionBox>
          <FormSectionBox visible={this.renderOfferSourceInheritedParams()} title="Inherited From Offer Source">
            <NewDataPassing
              queryParams={this.state.inheritedQueryParams}
              pageName="Data Passing Offers"
              baseURL={this.state.model.baseURL}
              idOfferSource={this.state.offerSource.idOfferSource}
              showErrors={this.state.isSubmitted}
              setError={value => this.setDataPassingError(value, 'offerSource')}
              disableAdd={true}
              disableFields={true}
              disableRemove={true}
            />
          </FormSectionBox>
          <FormSectionBox title="Offer-level Data Passing">
            <p>
              Here you can configure additional URL parameters that will pass for this offer. If any of these overlap with the offer source,
              the offer-level parameters will take priority.
            </p>
            <NewDataPassing
              queryParams={this.state.model.queryParams!}
              pageName="Data Passing Offers"
              setError={value => this.setDataPassingError(value, 'offer')}
              showErrors={this.state.isSubmitted}
              baseURL={this.state.model.baseURL}
              onChange={this.handleDataPassingChanges}
            />
          </FormSectionBox>
          <FormSectionBox>
            <FFField
              label="Resulting Offer URL"
              htmlFor="finalUrl"
              block
              tootlipContent={
                <>
                  This is the resulting URL from your base URL + query string data configured in the <b>Configure Data Passing</b> section.
                  This is the URL FunnelFlux Pro will use when redirecting users to your page.
                </>
              }
            >
              <CodeSnippet
                data-testid="finalUrl"
                placeholder="Resulting Offer URL"
                code={this.makeFinalUrl(this.state.model.baseURL)!}
                forwardedRef={this.elRefs.finalUrl}
                error={this.validateTooltip('finalUrl')}
                className="width-full"
              />
            </FFField>
          </FormSectionBox>
          <FormSectionBox title="Advanced Settings">
            <p>Here you can configure advanced settings for data passing</p>
            <FFSwitch checked={this.state.model.disableAppendVid} onClick={this.handleDisableAppendVid}>
              Disable automatic appending of visitor ID on redirect
            </FFSwitch>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          className="page-links"
          show={
            getVisibilityByActiveTab(OFFER_FORM_PAGE_ACTION_LINKS_TAB, this.props.activeTab) &&
            !isCopyByContextModal(this.props.contextModal)
          }
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <p>
              Action links are universal click-through URLs that you should use in your pages to send visitors to the next node. You can
              right-click action connections in the funnel builder to get URLs with additional default parameters.
            </p>
            <FFCol gap={15}>
              <FFRow gap={15}>
                <FFField label="Select a domain" htmlFor="domain" block>
                  <Select
                    value={this.state.domain}
                    onChange={(value: string) => this.handleStateValuesChange('domain', value)}
                    id="domain"
                    name="domain"
                    options={this.props.customDomains}
                    valueGetter={(option: DomainEntry) => option.domain}
                    labelGetter={(option: DomainEntry) => option.domain}
                    placeholder="Select Domain"
                    data-testid="yourJs-selectDomain"
                  />
                </FFField>
                <FFField label="Action number" htmlFor="actionNumber" wdith="180px">
                  <Select
                    value={this.state.actionNumber}
                    onChange={(value: string) => {
                      this.handleStateValuesChange('actionNumber', getCorrectActionNumber(value));
                    }}
                    id="actionNumber"
                    name="actionNumber"
                    options={allOnActionNumbers}
                    valueGetter={(option: number) => option}
                    labelGetter={(option: number) => option}
                    placeholder="Select Action Number"
                    data-testid="yourJs-selectActionNumber"
                  />
                </FFField>
              </FFRow>
              <FFField label="Action URL" block>
                <CodeSnippet
                  data-testid="actionURL"
                  placeholder="Action URL"
                  code={getPageActionLink(this.state.domain, this.state.actionNumber)}
                  forwardedRef={this.elRefs.actionURL}
                />
              </FFField>
            </FFCol>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          show={getVisibilityByActiveTab(OFFER_FORM_JAVASCRIPT_TAB, this.props.activeTab) && !isCopyByContextModal(this.props.contextModal)}
        >
          <FormSectionBox title="Required: Universal JS Tag">
            <FFRow marginBottom={15}>
              <FFField label="Select a domain:" block direction="row">
                <Select
                  value={this.state.domain}
                  onChange={(value: string) => this.handleStateValuesChange('domain', value)}
                  options={this.props.customDomains}
                  valueGetter={(option: DomainEntry) => option.domain}
                  labelGetter={(option: DomainEntry) => option.domain}
                  placeholder="Select Domain"
                />
              </FFField>
            </FFRow>
            <p>
              This script is required for direct linking. Place it just before {'</head>'}. We recommend adding this to all of your pages
              regardless as it improves tracking. This code already includes a page view event.
            </p>
            <CodeSnippet forwardedRef={this.elRefs.jsTrackingHeader} codeType="genericViewFull" domain={this.state.domain} />
          </FormSectionBox>
          <FormSectionBox title="Optional: View-only Event">
            <p>
              If you have already loaded our universal JS tag and want to fire a singular page view event, you can use the following code.
              See our{' '}
              <a href="https://help.funnelflux.pro/article/105-javascript-tracking-of-page-views" target="_blank">
                help documentation
              </a>{' '}
              for further methods/arguments.
            </p>
            <CodeSnippet codeType="genericViewSingle" maxContent forwardedRef={this.elRefs.genericViewSingle} />
          </FormSectionBox>
          <FormSectionBox title="Optional: Default Tracking Parameters">
            <p>
              This code is optional and is NOT required for tracking to work. If you would like to track organic visits to your page, it
              needs to declare some defaults. You can embed the below code <em>before</em> our global snippet, to provide the tracking with
              default parameters. See our{' '}
              <a href="https://help.funnelflux.pro/article/108-setting-javascript-page-defaults" target="_blank">
                help documentation
              </a>{' '}
              for more info.
            </p>
            <CodeSnippet codeType="defaultParams" forwardedRef={this.elRefs.defaultParams} idPage={this.state.model.idPage} maxContent />
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          show={
            getVisibilityByActiveTab(OFFER_FORM_CONVERSION_TRACKING_TAB, this.props.activeTab) &&
            !isCopyByContextModal(this.props.contextModal)
          }
        >
          <FormSectionBox title="Postback URL Tracking">
            <>
              <FFRow marginBottom={15}>
                <FFField label="Select a domain:" htmlFor="domain" direction="row" block>
                  <Select
                    value={this.state.domain}
                    onChange={(value: string) => this.handleStateValuesChange('domain', value)}
                    id="domain"
                    name="domain"
                    options={this.props.customDomains}
                    valueGetter={(option: DomainEntry) => option.domain}
                    labelGetter={(option: DomainEntry) => option.domain}
                    placeholder="Select Domain"
                    data-testid="yourConversion-selectDomain"
                  />
                </FFField>
              </FFRow>
              <FFField
                label={`Default Postback URL for ${this.state.offerSource?.offerSourceName || 'Offer'}`}
                tootlipContent="Here we provide the default postback URL/Pixel tracking code for this offer. These values are based on what you have set in your offer source. They are here just to make adding codes to your page or offer platform easier."
              >
                <CodeSnippet
                  forwardedRef={this.elRefs.postbackUrl}
                  codeType="offerPostbackUrl"
                  domain={this.state.domain}
                  postbackPayout={this.getRev()}
                  postbackTxId={this.getTx()}
                  postbackSubId={this.getHit()}
                  idPage={this.state.model.idPage}
                  placeholder="Default Postback URL"
                  data-testid="postbackUrl"
                  className="width-full"
                />
              </FFField>
            </>
          </FormSectionBox>
          <FormSectionBox title="Universal Javascript Tag (conversion)">
            <>
              <p>
                Here is the full Javascript code to place at this offer to track a conversion. It includes our universal JS tag and a
                conversion event. See our{' '}
                <a href="https://help.funnelflux.pro/article/106-javascript-tracking-of-conversions" target="_blank">
                  help documentation
                </a>{' '}
                for more info.
              </p>
              <CodeSnippet
                codeType="offerConversionFull"
                domain={this.state.domain}
                postbackPayout={this.getRev()}
                postbackTxId={this.getTx()}
                idPage={this.state.model.idPage}
                data-testid="conversionJsFull"
                forwardedRef={this.elRefs.conversionJsFull}
                className="margin-bottom-10"
              />
            </>
          </FormSectionBox>
          <FormSectionBox title="Conversion-only Event">
            <>
              <p>
                If you have already loaded our universal JS tag and tracked an offer page view, you can fire a conversion event on its own
                with the following code. See our{' '}
                <a href="https://help.funnelflux.pro/article/106-javascript-tracking-of-conversions" target="_blank">
                  help documentation
                </a>{' '}
                for more info.
              </p>
              <CodeSnippet
                codeType="offerConversionSingle"
                domain={this.state.domain}
                postbackPayout={this.getRev()}
                postbackTxId={this.getTx()}
                idPage={this.state.model.idPage}
                data-testid="conversionJsSingle"
                forwardedRef={this.elRefs.conversionJsSingle}
              />
            </>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          show={
            getVisibilityByActiveTab(OFFER_FORM_INTEGRATION_PRODUCT_IDS_TAB, this.props.activeTab) &&
            !isCopyByContextModal(this.props.contextModal) &&
            definedObject(this.state.model.offerParams?.externalProductIds)
          }
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <FFField label="Product IDs" htmlFor="productID" block>
              {Object.keys(this.state.model.offerParams?.externalProductIds!).map(key => (
                <FFRow gap={10} marginBottom={15} key={key}>
                  <Input value={key} name="productID" disabled data-testid={`productID-${key}`} />
                  <Input
                    value={this.state.model.offerParams?.externalProductIds![key as keyof OfferParamsExternalProductIds]}
                    name="productValue"
                    data-testid={`productValue-${key}`}
                    onChange={e => this.handleExternalProductIdsChange(key as keyof OfferParamsExternalProductIds, e.target.value)}
                  />
                </FFRow>
              ))}
            </FFField>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper show={getVisibilityByActiveTab(OFFER_FORM_HELP_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle!}>
            <FormHelpSection
              content={
                <>
                  <p>Offers are pages you send users to within your funnels. These pages can convert and create revenue, unlike landers.</p>

                  <p>
                    When creating offers you can provide an Offer Source, which helps group the offers by source but also lets them inherit
                    the tracking template from your Offer Source.
                  </p>

                  <p>
                    Typically, offers will be from a third-party using their own tracking system, so here you can add a base URL then
                    template how you pass additional data.
                  </p>

                  <p>The conversion tracking info here is from the Offer's Source and is shown for convenience.</p>
                  <p>
                    For more help on offers, see our documentation{' '}
                    <a href="https://help.funnelflux.pro/article/6-introduction-to-offers" target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </p>
                  <p>
                    For further information on using our Javascript tracking, see our documentation{' '}
                    <a href="https://help.funnelflux.pro/collection/6-javascript-tracking" target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </p>
                </>
              }
              name="Offer"
            />
          </FormSectionBox>
        </FormContentWrapper>
      </form>
    );
  }
}

export default Messages.injectIn(OfferForm);

import React, { Component } from 'react';
import { Page } from '../../../../model/page';
import { Select } from '../../../Parts/Inputs';
import { ContentWrapper } from '../../../Parts/Content';
import { MessageProps } from '../../../../types';
import Messages from 'components/Messages';
import { addCategory } from '../../../../utils/select'
import { SwapPagesFormProps } from 'types/ModalForms/swapPages';
interface State {
  value: string;
}
class Swap extends Component<SwapPagesFormProps & MessageProps, State> {
  state: State = {
    value: ''
  };

  componentDidMount() {
    this.props.setButtonGroupProps!({
      showOk: true,
      showCancel: true,
      okText: 'SAVE',
      cancelText: 'DISCARD',
      okDisabled: !this.state.value,
      onOkClicked: this.onSubmit,
      onCancelClicked: this.props.closeSelf
    })
  }

  onChangeValue = async (value: string) => {
    await this.setState((state: State) => ({
      ...state,
      value: value
    }));
    if (!this.state.value) {
      this.props.setButtonGroupProps!({ okDisabled: true })
    } else {
      this.props.setButtonGroupProps!({ okDisabled: false })
    }
  };

  onSubmit = () => {
    try {
      this.props.contextSidebar.onUpdate(this.state.value);
      this.props.closeSelf();
      this.props.showMessage(Messages.success('Page has been swapped'));
    } catch (e) {
      this.props.showMessage(Messages.failed('Page could not be swapped'));
    }
  };

  render() {
    return (
      <ContentWrapper>
        <Select
          showSearch={true}
          style={{ width: `100%` }}
          onChange={this.onChangeValue}
          value={this.state.value}
          groupOptions={true}
          groupBy="category"
          options={addCategory(
            this.props.contextSidebar?.data?.pages || [],
            this.props.contextSidebar?.data?.categories || []
          )}
          valueGetter={(option: Page) => option.idPage}
          labelGetter={(option: Page) => option.pageName}
          placeholder="Select a page"
        />
      </ContentWrapper>
    );
  }
}

export default Messages.injectIn(Swap);

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import {
  PAGE_GROUPS_MODAL,
  PAGE_GROUPS_DUPLICATE_MODAL,
  LANDER_GROUP_MODAL,
  OFFER_GROUP_MODAL
} from 'constants/modal';
import {
  addPageGroup,
  duplicatePageGroup,
  findGroupByName,
  getPageGroupById,
  updatePageGroup
} from '../../../redux/actions/pageGroups';
import { connect } from 'react-redux';
import { PageGroupsModalSelector } from 'redux/selectors';
import { getModalTitle } from 'utils/modals';
import { FormContextSidebar } from 'types/modal';
import { SidebarProps } from 'types/sidebars';
import { SidebarsConsumer } from '../context';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import { defined } from 'utils/define';
import { PageGroup } from 'model/pageGroup';
import GroupNodeSettings from 'components/Forms/Builder/Group';
import {
  PageGroupFormProps,
  PageGroupSidebarProps,
  PageGroupTabKey
} from '../../../types/ModalForms/pageGroup';
import { LoadingProps } from 'types/loading';
import { withLoading } from 'components/Loading';
import {
  PAGEGROUP_FORM_TAB,
  PAGEGROUP_FORM_ADDITIONAL_SETTINGS_TAB,
  PAGEGROUP_FORM_REDIRECT_LINKS_TAB,
  PAGEGROUP_FORM_DIRECT_LINKS_TAB,
  PAGEGROUP_FORM_ACTION_LINKS_TAB,
  PAGEGROUP_FORM_JAVASCRIPT_TRACKING_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import { fetchOffers, fetchOffersInfo } from 'redux/actions/offers';
import { fetchLanders, fetchLandersInfo } from 'redux/actions/landers';
import { fetchTrafficSources } from 'redux/actions/trafficsources';
import { fetchDomains } from 'redux/actions/domains';
import { fetchSystemSettings } from 'redux/actions/systemSettings';
import { FFIcon } from 'uikit';

class PageGroupFormWrapper extends React.Component<
  PageGroupFormProps & PageGroupSidebarProps & LoadingProps,
  {}
> {
  static defaultProps: Partial<PageGroupSidebarProps> = {
    withWrapper: (comp, isLanderModal) => (
      <div
        className={`node-settings-container ${
          isLanderModal ? 'landerGroup' : 'offerGroup'
        }`}
      >
        {comp}
      </div>
    )
  };

  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen!}
        hasTab={true}
        onClose={this.props.onClose}
        defaultOpenSideBarKey="pagegroupForm"
        defaultActiveTab={
          this.props.shouldOpenLinkSection
            ? PAGEGROUP_FORM_REDIRECT_LINKS_TAB
            : PAGEGROUP_FORM_TAB
        }
        offsetRight={this.props.offsetRight}
        dataPortalKey="pagegroup"
        zIndex={this.props.zIndex}
        className={
          this.props.isInBuilderForm
            ? 'builderPageGroupSidebar'
            : 'pageGroupSidebar'
        }
        sideBars={[
          {
            key: 'pagegroupForm',
            tabs: {
              [PAGEGROUP_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [PAGEGROUP_FORM_REDIRECT_LINKS_TAB]: {
                title: 'Redirect Links',
                icon: <FFIcon name="redirectLink" />,
                isHidden: !this.props.isInBuilderForm
              },
              [PAGEGROUP_FORM_DIRECT_LINKS_TAB]: {
                title: 'Direct Links',
                icon: <FFIcon name="directLink" />,
                isHidden: !this.props.isInBuilderForm
              },
              [PAGEGROUP_FORM_ACTION_LINKS_TAB]: {
                title: 'Action Links',
                icon: <FFIcon name="actionLink" />,
                isHidden: !defined(this.props.funnelConnection)
              },
              [PAGEGROUP_FORM_JAVASCRIPT_TRACKING_TAB]: {
                title: 'Javascript Tracking',
                icon: <FFIcon name="jsTracking" />,
                isHidden: !this.props.isInBuilderForm
              },
              [PAGEGROUP_FORM_ADDITIONAL_SETTINGS_TAB]: {
                title: 'Additional Settings',
                icon: <FFIcon name="advancedSettings" />,
                isHidden: !this.props.isInBuilderForm
              }
            },
            bodyClassName: 'add-edit-page-group page-group',
            title:
              this.props.customTitle ||
              `${getModalTitle(this.props.contextModal!)} Global Page Group`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setForCopyIdsProps,
              sidebarLoading,
              setLoading,
              tabTitle
            }) => {
              return this.props.withWrapper!(
                <GroupNodeSettings
                  {...this.props}
                  activeTab={activeTab as PageGroupTabKey}
                  setForCopyIdsProps={setForCopyIdsProps}
                  setButtonGroupProps={setButtonGroupProps}
                  setSidebarLoading={setLoading}
                  sidebarLoading={sidebarLoading}
                  tabTitle={tabTitle}
                />,
                this.props.isLanderModal
              );
            }
          }
        ]}
      />
    );
  }
}

export const FormElement = withLoading(PageGroupFormWrapper);

const ConnectedFormElement = ({
  addPageGroup,
  updatePageGroup,
  duplicatePageGroup,
  getPageGroupById,
  pageGroupsArray,
  systemSettings,
  landers,
  offers,
  landerCategories,
  offerCategories,
  fetchDomains,
  fetchTrafficSources,
  findGroupByName,
  fetchOffersInfo,
  fetchLandersInfo,
  fetchSystemSettings
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let offsetRight = 0;
        let contextSidebar = isOpenSidebar(LANDER_GROUP_MODAL)
          ? (getContextSidebar(LANDER_GROUP_MODAL) as FormContextSidebar)
          : isOpenSidebar(OFFER_GROUP_MODAL)
          ? (getContextSidebar(OFFER_GROUP_MODAL) as FormContextSidebar)
          : (getContextSidebar(PAGE_GROUPS_MODAL) as FormContextSidebar);

        if (isOpenSidebar(PAGE_GROUPS_DUPLICATE_MODAL)) {
          contextSidebar = getContextSidebar(
            PAGE_GROUPS_DUPLICATE_MODAL
          ) as FormContextSidebar;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            pageGroupsArray
          );
          offsetRight = getOffsetRight(PAGE_GROUPS_DUPLICATE_MODAL);
        }

        let isLanderModal = isOpenSidebar(LANDER_GROUP_MODAL);
        if (isOpenSidebar(PAGE_GROUPS_MODAL)) {
          contextSidebar = getContextSidebar(
            PAGE_GROUPS_MODAL
          ) as FormContextSidebar;
          offsetRight = getOffsetRight(PAGE_GROUPS_MODAL);
        }

        if (isOpenSidebar(OFFER_GROUP_MODAL)) {
          offsetRight = getOffsetRight(OFFER_GROUP_MODAL);
        }

        if (isOpenSidebar(PAGE_GROUPS_MODAL)) {
          offsetRight = getOffsetRight(PAGE_GROUPS_MODAL);
        }

        if (
          defined(contextSidebar?.entityId) &&
          defined(pageGroupsArray[contextSidebar.entityId])
        ) {
          isLanderModal =
            (pageGroupsArray[contextSidebar.entityId] as PageGroup).pageType ===
            'lander';
        }

        const isOpen =
          isOpenSidebar(PAGE_GROUPS_DUPLICATE_MODAL) ||
          isOpenSidebar(LANDER_GROUP_MODAL) ||
          isOpenSidebar(OFFER_GROUP_MODAL) ||
          isOpenSidebar(PAGE_GROUPS_MODAL);

        return (
          <FormElement
            fetchSystemSettings={fetchSystemSettings}
            systemSettings={systemSettings}
            findGroupByName={findGroupByName}
            pageGroupsArray={pageGroupsArray}
            fetchDomains={fetchDomains}
            fetchTrafficSources={fetchTrafficSources}
            fetchOffersInfo={fetchOffersInfo}
            fetchLandersInfo={fetchLandersInfo}
            contextModal={contextSidebar}
            isInBuilderForm={false}
            getPageGroupById={getPageGroupById}
            handleCreate={addPageGroup}
            handleUpdate={updatePageGroup}
            handleDuplicate={duplicatePageGroup}
            type={isLanderModal ? 'landerGroup' : 'offerGroup'}
            data={{
              landers: landers,
              offers: offers,
              pageGroupsArray: pageGroupsArray
            }}
            categories={
              isLanderModal ? landerCategories || [] : offerCategories || []
            }
            pages={isLanderModal ? landers || [] : offers || []}
            pagesName={isLanderModal ? 'Lander' : 'Offer'}
            isOpen={isOpen}
            isLanderModal={isLanderModal}
            onClose={() => {
              [
                PAGE_GROUPS_DUPLICATE_MODAL,
                PAGE_GROUPS_MODAL,
                LANDER_GROUP_MODAL,
                OFFER_GROUP_MODAL
              ].forEach(item => {
                if (isOpenSidebar(item)) {
                  closeSidebar(item);
                }
              });
            }}
            zIndex={contextSidebar.zIndex!}
            offsetRight={offsetRight}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(PageGroupsModalSelector, {
  addPageGroup,
  duplicatePageGroup,
  updatePageGroup,
  getPageGroupById,
  fetchOffers,
  fetchLanders,
  fetchTrafficSources,
  fetchDomains,
  findGroupByName,
  fetchOffersInfo,
  fetchLandersInfo,
  fetchSystemSettings
})(ConnectedFormElement);

import { createContext } from 'react';

export const SidebarContext = createContext(null as any);
const { Provider, Consumer } = SidebarContext;

export const SidebarsProvider = Provider;
export const SidebarsConsumer = Consumer;

export const SidebarOffsetRightContext = createContext(null as any);



import { DOMAINS, SYSTEM_SETTINGS } from '../types';
import { AnyAction } from 'redux';
import { SystemSettingsStore } from 'types/redux/store';
import { systemSettings as defaultState } from './defaultStates';
import { CloudflaredDomain, DomainEntry } from 'model/models';

const data = (state = defaultState.data, action: AnyAction) => {
  switch (action.type) {
    case SYSTEM_SETTINGS.FETCH:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const domains = (state = defaultState.domains, action: AnyAction) => {
  switch (action.type) {
    case DOMAINS.FETCH:
      return action.payload;
    case DOMAINS.DELETE:
      return state.filter(domain => domain.domain !== (action.payload as DomainEntry).domain);
    case DOMAINS.ADD_SINGLE:
      return [...state, action.payload as CloudflaredDomain];
    default:
      return state;
  }
};

const tooltips = (state = defaultState.tooltips, action: AnyAction) => {
  switch (action.type) {
    case SYSTEM_SETTINGS.TOGGLE_SHOW_EDIT_MODE_TOOLTIPS:
      return {
        ...state,
        editMode: action.payload
      };
    default:
      return state;
  }
};

export default (state = defaultState, action: AnyAction): SystemSettingsStore => {
  return {
    data: data(state.data, action),
    tooltips: tooltips(state.tooltips, action),
    domains: domains(state.domains, action)
  };
};

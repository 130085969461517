import React, { PropsWithChildren } from 'react';
import { Modal as AntModal } from 'antd';
import { FFModalProps } from 'uikit/types/modal';
import { FFButton, FFIcon, FFRow } from 'uikit';
import './style.scss';
import className from 'utils/style/className';

const { blockClassName, getClass } = className('c-ffModal');

const Modal = ({ children, open, onCancel, title, centered, footer = null, width }: PropsWithChildren<FFModalProps>) => {
  return (
    <AntModal
      className={blockClassName}
      open={open}
      closable
      width={width}
      wrapClassName={getClass('wrapper')}
      centered={centered}
      closeIcon={<></>}
      title={
        <FFRow justifyContent="space-between" alignItems="center">
          <span className={getClass('title')}>{title}</span>
          <div className={getClass('closeIcon')} onClick={onCancel}>
            <FFIcon name="xCircle" size="bigger" />
          </div>
        </FFRow>
      }
      footer={footer}
    >
      <div style={{ padding: '22px 16px' }}>{children}</div>
    </AntModal>
  );
};

export default Modal;

import { TrafficSource } from 'model/trafficSource';
import { CustomEventSettingsTemplate } from 'types/customEvents';
import { required } from 'utils/validation';

export const customEventSettingsTemplate: {
  [key in TrafficSource.CustomScenarioTypeEnum]: CustomEventSettingsTemplate;
} = {
  TikTok: {
    name: 'TikTok',
    fields: [
      {
        key: 'accessToken',
        label: 'Access Token:',
        element: 'input',
        placeholder: 'Access Token',
        validations: [required],
        type: 'string'
      },
      {
        key: 'pixelID',
        label: 'Pixel ID:',
        element: 'input',
        placeholder: 'Pixel ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'eventName',
        label: 'Event name:',
        element: 'select',
        placeholder: 'Event name',
        validations: [required],
        type: 'string',
        options: [
          {
            id: 'AddPaymentInfo',
            name: 'Add Payment Info',
            category: ''
          },
          {
            id: 'AddToCart',
            name: 'Add to Cart',
            category: ''
          },
          {
            id: 'AddToWishlist',
            name: 'Add to Wishlist',
            category: ''
          },
          {
            id: 'ClickButton',
            name: 'Click Button',
            category: ''
          },
          {
            id: 'CompletePayment',
            name: 'Complete Payment',
            category: ''
          },
          {
            id: 'CompleteRegistration',
            name: 'Complete Registration',
            category: ''
          },
          {
            id: 'Contact',
            name: 'Contact',
            category: ''
          },
          {
            id: 'Download',
            name: 'Download',
            category: ''
          },
          {
            id: 'InitiateCheckout',
            name: 'Initiate Checkout',
            category: ''
          },
          {
            id: 'PlaceAnOrder',
            name: 'Place an Order',
            category: ''
          },
          {
            id: 'Search',
            name: 'Search',
            category: ''
          },
          {
            id: 'SubmitForm',
            name: 'Submit Form',
            category: ''
          },
          {
            id: 'Subscribe',
            name: 'Subscribe',
            category: ''
          },
          {
            id: 'ViewContent',
            name: 'View Content',
            category: ''
          },
          {
            id: '{txid}',
            name: 'Custom – Transaction ID Value',
            category: ''
          }
        ]
      },
      {
        key: 'sendTestEventCode',
        label: 'Send an optional test event code (use for testing only)',
        element: 'switch'
      },
      {
        key: 'testEventCode',
        label: 'Test Event code:',
        element: 'input',
        placeholder: 'Test Event code',
        validations: [required],
        type: 'string',
        show: data => data?.sendTestEventCode
      }
    ],
    postbackURL: ({
      accessToken = 'ACCESS_TOKEN_HERE',
      pixelID = 'PIXEL_ID_HERE',
      eventName = 'EVENT_HERE',
      sendTestEventCode = false,
      testEventCode
    }) =>
      `https://functions.funnelflux.pro/tiktok-s2s-api?access_token=${accessToken}&pixel_id=${pixelID}&event_name=${eventName}&vid={visitor}&ttclid={external}&currency=USD&revenue={payout}&ip={ip}&user_agent={user-agent}&page_url={page-url}&conversion_timestamp={conversion-time}${
        sendTestEventCode && testEventCode ? `&test_event_code=${testEventCode}` : ''
      }`
  },
  Facebook: {
    name: 'Facebook',
    fields: [
      {
        key: 'accessToken',
        label: 'Access Token:',
        element: 'input',
        placeholder: 'Access Token',
        validations: [required],
        type: 'string'
      },
      {
        key: 'pixelID',
        label: 'Pixel ID:',
        element: 'input',
        placeholder: 'Pixel ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'eventName',
        label: 'Event name:',
        element: 'select',
        placeholder: 'Event name',
        validations: [required],
        type: 'string',
        options: [
          {
            id: 'AddPaymentInfo',
            name: 'Add Payment Info',
            category: ''
          },
          {
            id: 'AddToCart',
            name: 'Add to Cart',
            category: ''
          },
          {
            id: 'AddToWishlist',
            name: 'Add to Wishlist',
            category: ''
          },
          {
            id: 'CompleteRegistration',
            name: 'Complete Registration',
            category: ''
          },
          {
            id: 'Contact',
            name: 'Contact',
            category: ''
          },
          {
            id: 'CustomizeProduct',
            name: 'Customize Product',
            category: ''
          },
          {
            id: 'Donate',
            name: 'Donate',
            category: ''
          },
          {
            id: 'FindLocation',
            name: 'Find Location',
            category: ''
          },
          {
            id: 'InitiateCheckout',
            name: 'Initiate Checkout',
            category: ''
          },
          {
            id: 'Lead',
            name: 'Lead',
            category: ''
          },
          {
            id: 'Purchase',
            name: 'Purchase',
            category: ''
          },
          {
            id: 'Schedule',
            name: 'Schedule',
            category: ''
          },
          {
            id: 'Search',
            name: 'Search',
            category: ''
          },
          {
            id: 'StartTrial',
            name: 'Start Trial',
            category: ''
          },
          {
            id: 'SubmitApplication',
            name: 'Submit Application',
            category: ''
          },
          {
            id: 'Subscribe',
            name: 'Subscribe',
            category: ''
          },
          {
            id: 'ViewContent',
            name: 'View Content',
            category: ''
          },
          {
            id: '{txid}',
            name: 'Custom – Use Transaction ID Value',
            category: ''
          }
        ]
      },
      {
        key: 'sendTestEventCode',
        label: 'Send an optional test event code (use for testing only)',
        element: 'switch'
      },
      {
        key: 'testEventCode',
        label: 'Test Event code:',
        element: 'input',
        placeholder: 'Test Event code',
        validations: [required],
        type: 'string',
        show: data => data?.sendTestEventCode
      },
      {
        key: 'sendOverridingEventURL',
        label: 'Send overriding event URL for each conversion',
        element: 'switch'
      },
      {
        key: 'eventURLToUse',
        label: 'Event URL to use:',
        element: 'input',
        placeholder: 'Event URL',
        validations: [required],
        type: 'string',
        show: data => data?.sendOverridingEventURL
      }
    ],
    postbackURL: ({
      accessToken = 'ACCESS_TOKEN_HERE',
      pixelID = 'PIXEL_ID_HERE',
      eventName = 'EVENT_HERE',
      sendTestEventCode = false,
      sendOverridingEventURL = false,
      testEventCode,
      eventURLToUse = '{page-url}'
    }) =>
      `https://functions.funnelflux.pro/facebook-s2s-api?fbclid={external}&funnel_id={funnel-id}&hit_id={hit}&vid={visitor}&revenue={payout}&currency=USD${
        sendOverridingEventURL ? `&page_url=${eventURLToUse}` : '&page_url={page-url}'
      }&ip={ip}&user_agent={user-agent}&entrance_timestamp={entrance-timestamp}&conversion_timestamp={conversion-time}&txid={txid}&offer_id=&offer_cat=&access_token=${accessToken}&pixel_id=${pixelID}&event_name=${eventName}${
        sendTestEventCode && testEventCode ? `&test_event_code=${testEventCode}` : ''
      }`
  },
  GoogleAds: {
    name: 'Google Ads',
    fields: [
      {
        key: 'accountId',
        label: 'Account ID',
        element: 'input',
        placeholder: 'Ad Account ID (no hyphens)',
        validations: [required],
        type: 'number'
      },
      {
        key: 'refreshToken',
        label: 'Refresh token',
        element: 'input',
        placeholder: 'Your generated refresh token',
        validations: [required],
        type: 'string'
      },
      {
        key: 'conversionActionId',
        label: 'Conversion Action ID',
        element: 'input',
        placeholder: 'Your conversion action ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'managerAccountId',
        label: 'Manager Account ID',
        element: 'input',
        placeholder: 'Optional manager account ID',
        type: 'number'
      }
    ],
    postbackURL: ({ refreshToken, accountId, conversionActionId, managerAccountId }) =>
      `https://functions.funnelflux.pro/google-s2s-ingest?refresh_token=${refreshToken}&ad_account_id=${accountId}&conversion_timestamp={conversion-time}&entrance_timestamp={entrance-timestamp}&gclid={external}&wbraid={data-wbraid}&gbraid={data-gbraid}&value={payout}&currency_code=USD&conversion_action_id=${conversionActionId}&order_id={visitor}-{txid}${
        managerAccountId ? `&manager_account_id=${managerAccountId}` : ''
      }`
  },
  MicrosoftAds: {
    name: 'Microsoft Ads',
    fields: [
      {
        key: 'adAccountId',
        label: 'Ad Account ID',
        element: 'input',
        placeholder: 'Ad Account ID (no hyphens)',
        validations: [required],
        type: 'number'
      },
      {
        key: 'customerId',
        label: 'Your Customer ID',
        element: 'input',
        placeholder: 'Your customer ID (no hyphens)',
        validations: [required],
        type: 'number'
      },
      {
        key: 'conversionGoalId',
        label: 'Conversion Goal ID',
        element: 'input',
        placeholder: 'Your conversion goal ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'refreshToken',
        label: 'Refresh token',
        element: 'input',
        placeholder: 'Your generated refresh token',
        validations: [required],
        type: 'string'
      }
    ],
    postbackURL: ({ adAccountId, customerId, conversionGoalId, conversionGoalName, refreshToken }) =>
      `https://functions.funnelflux.pro/msads-s2s-ingest?refresh_token=${refreshToken}&ad_account_id=${adAccountId}&customer_id=${customerId}&conversion_timestamp={conversion-time}&click_timestamp={entrance-timestamp}&msclkid={external}&revenue={payout}&currency=USD&conversion_goal_id=${conversionGoalId}`
  },
  Snapchat: {
    name: 'Snapchat',
    fields: [
      {
        key: 'accessToken',
        label: 'Access Token:',
        element: 'input',
        placeholder: 'Access Token',
        validations: [required],
        type: 'string'
      },
      {
        key: 'pixelID',
        label: 'Pixel ID:',
        element: 'input',
        placeholder: 'Pixel ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'eventType',
        label: 'Event type:',
        element: 'select',
        placeholder: 'Event type',
        validations: [required],
        type: 'string',
        options: [
          {
            id: 'PURCHASE',
            name: 'Purchase',
            category: ''
          },
          {
            id: 'SAVE',
            name: 'Save',
            category: ''
          },
          {
            id: 'START_CHECKOUT',
            name: 'Start Checkout',
            category: ''
          },
          {
            id: 'ADD_CART',
            name: 'Add to Cart',
            category: ''
          },
          {
            id: 'VIEW_CONTENT',
            name: 'View Content',
            category: ''
          },
          {
            id: 'ADD_BILLING',
            name: 'Add Billing Details',
            category: ''
          },
          {
            id: 'SIGN_UP',
            name: 'Sign Up',
            category: ''
          },
          {
            id: 'SEARCH',
            name: 'Search',
            category: ''
          },
          {
            id: 'PAGE_VIEW',
            name: 'Page View',
            category: ''
          },
          {
            id: 'SUBSCRIBE',
            name: 'Subscribe',
            category: ''
          },
          {
            id: 'START_TRIAL',
            name: 'Start Trial',
            category: ''
          },
          {
            id: 'APP_INSTALL',
            name: 'App Install',
            category: ''
          },
          {
            id: '{txid}',
            name: 'Custom – Use Transaction ID Value',
            category: ''
          }
        ]
      },
      {
        key: 'sendOverridingEventURL',
        label: 'Send overriding event URL for each conversion',
        element: 'switch'
      },
      {
        key: 'eventURLToUse',
        label: 'Event URL to use:',
        element: 'input',
        placeholder: 'Event URL',
        validations: [required],
        type: 'string',
        show: data => data?.sendOverridingEventURL
      }
    ],
    postbackURL: ({
      accessToken = 'ACCESS_TOKEN_HERE',
      pixelID = 'PIXEL_ID_HERE',
      eventType = 'EVENT_HERE',
      sendTestEventCode = false,
      sendOverridingEventURL = false,
      testEventCode,
      eventURLToUse = '{page-url}'
    }) =>
      `https://functions.funnelflux.pro/snapchat-s2s-api?click_id={external}${
        sendOverridingEventURL ? `&page_url=${eventURLToUse}` : '&page_url={page-url}'
      }&hit_id={hit}&transaction_id={txid}&revenue={payout}&currency=USD&ip={ip}&user_agent={user-agent}&conversion_timestamp={conversion-time}&access_token=${accessToken}&pixel_id=${pixelID}&event_type=${eventType}`
  },
  Kwai: {
    name: 'Kwai',
    fields: [
      {
        key: 'accessToken',
        label: 'Access Token:',
        element: 'input',
        placeholder: 'Access Token',
        validations: [required],
        type: 'string'
      },
      {
        key: 'pixelID',
        label: 'Pixel ID:',
        element: 'input',
        placeholder: 'Pixel ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'eventName',
        label: 'Event name:',
        element: 'select',
        placeholder: 'Event name',
        validations: [required],
        type: 'string',
        options: [
          {
            id: 'EVENT_ADD_TO_CART',
            name: 'Add to Cart',
            category: ''
          },
          {
            id: 'EVENT_ADD_PAYMENT_INFO',
            name: 'Add Payment Info',
            category: ''
          },
          {
            id: 'EVENT_ADD_TO_WISHLIST',
            name: 'Add to Wishlist',
            category: ''
          },
          {
            id: 'EVENT_BUTTON_CLICK',
            name: 'Button Click',
            category: ''
          },
          {
            id: 'EVENT_COMPLETE_REGISTRATION',
            name: 'Complete Registration',
            category: ''
          },
          {
            id: 'EVENT_CONTACT',
            name: 'Contact',
            category: ''
          },
          {
            id: 'EVENT_CONTENT_VIEW',
            name: 'Content View',
            category: ''
          },
          {
            id: 'EVENT_DOWNLOAD',
            name: 'Download',
            category: ''
          },
          {
            id: 'EVENT_FORM_SUBMIT',
            name: 'Form Submit',
            category: ''
          },
          {
            id: 'EVENT_INITIATED_CHECKOUT',
            name: 'Initiate Checkout',
            category: ''
          },
          {
            id: 'EVENT_PLACE_ORDER',
            name: 'Place an Order',
            category: ''
          },
          {
            id: 'EVENT_PURCHASE',
            name: 'Purchase',
            category: ''
          },
          {
            id: 'EVENT_SEARCH',
            name: 'Search',
            category: ''
          },
          {
            id: 'EVENT_SUBSCRIBE',
            name: 'Subscribe',
            category: ''
          },
          {
            id: '{txid}',
            name: 'Custom – Transaction ID Value',
            category: ''
          }
        ]
      },
      {
        key: 'sendTestEventCode',
        label: 'Turn on test mode (only use for testing)',
        element: 'switch'
      }
    ],
    postbackURL: ({
      accessToken = 'ACCESS_TOKEN_HERE',
      pixelID = 'PIXEL_ID_HERE',
      eventName = 'EVENT_HERE',
      sendTestEventCode = false,
      testEventCode
    }) =>
      `https://functions.funnelflux.pro/kwai-s2s-api?access_token=${accessToken}&pixel_id=${pixelID}&event_name=${eventName}&click_id={external}&revenue={payout}${
        sendTestEventCode ? `&test_event=true` : ''
      }`
  },
  Reddit: {
    name: 'Reddit',
    fields: [
      {
        key: 'refreshToken',
        label: 'Refresh Token:',
        element: 'input',
        placeholder: 'Refresh Token',
        validations: [required],
        type: 'string'
      },
      {
        key: 'eventName',
        label: 'Event type:',
        element: 'select',
        placeholder: 'Event type',
        validations: [required],
        type: 'string',
        options: [
          {
            id: 'AddToCart',
            name: 'Add to Cart',
            category: ''
          },
          {
            id: 'AddToWishlist',
            name: 'Add to Wishlist',
            category: ''
          },
          {
            id: '{txid}',
            name: 'Custom – Transaction ID Value',
            category: ''
          },
          {
            id: 'Lead',
            name: 'Lead',
            category: ''
          },
          {
            id: 'Purchase',
            name: 'Purchase',
            category: ''
          },
          {
            id: 'Search',
            name: 'Search',
            category: ''
          },
          {
            id: 'SignUp',
            name: 'Sign Up',
            category: ''
          },
          {
            id: 'ViewContent',
            name: 'View Content',
            category: ''
          }
        ]
      }
    ],
    postbackURL: ({ refreshToken = 'REFRESH_TOKEN_HERE', eventName = 'EVENT_HERE' }) =>
      `https://functions.funnelflux.pro/reddit-s2s-api?click_id={external}&conversion_timestamp={timestamp}&value={payout}&transaction_id={txid}&ip={ip}&user_agent={user-agent}&account_id={data-account_id}&tracking_type=${eventName}&refresh_token=${refreshToken}`
  },
  Twitter: {
    name: 'Twitter/X',
    fields: [
      {
        key: 'userId',
        label: 'User ID:',
        element: 'input',
        placeholder: 'Your User ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'pixelId',
        label: 'Pixel ID:',
        element: 'input',
        placeholder: 'Your Pixel ID',
        validations: [required],
        type: 'string'
      },
      {
        key: 'pixelEventId',
        label: 'Pixel Event ID:',
        element: 'input',
        placeholder: 'Pixel Event ID',
        validations: [required],
        type: 'string'
      }
    ],
    postbackURL: ({ userId = 'REFRESH_TOKEN_HERE', pixelId = 'EVENT_HERE', pixelEventId = 'EVENT_ID_HERE' }) =>
      `https://functions.funnelflux.pro/twitter-s2s-api?user_id=${userId}&pixel_id=${pixelId}&event_id=tw-${pixelId}-${pixelEventId}&conversion_time={timestamp}&twclid={external}&conversion_id={hit}-{txid}&conversion_value={payout}`
  },
  Bigo: {
    name: 'Bigo Ads',
    fields: [
      {
        key: 'pixelId',
        label: 'Pixel ID:',
        element: 'input',
        placeholder: 'Enter Pixel ID Here',
        validations: [required],
        type: 'string'
      },
      {
        key: 'eventId',
        label: 'Event name:',
        element: 'select',
        placeholder: 'Event name',
        validations: [required],
        type: 'string',
        options: [
          {
            id: 'page_view',
            name: 'Page View',
            category: ''
          },
          {
            id: 'button',
            name: 'Button Click',
            category: ''
          },
          {
            id: 'consult',
            name: 'Online Consultation',
            category: ''
          },
          {
            id: 'ec_register',
            name: 'User Registration',
            category: ''
          },
          {
            id: 'ec_detail_view',
            name: 'Product Detail Page Browse',
            category: ''
          },
          {
            id: 'ec_add_cart',
            name: 'Add to Cart',
            category: ''
          },
          {
            id: 'ec_order',
            name: 'Place an Order',
            category: ''
          },
          {
            id: 'ec_purchase',
            name: 'Complete Payment',
            category: ''
          },
          {
            id: 'form_button',
            name: 'Button Click (Form)',
            category: ''
          },
          {
            id: 'form_detail',
            name: 'Details page browse (Form)',
            category: ''
          },
          {
            id: 'form',
            name: 'Form Submission',
            category: ''
          },
          {
            id: 'phone_button',
            name: 'Button Click (Consultation)',
            category: ''
          },
          {
            id: 'phone_detail',
            name: 'Details Page Browse (Consultation)',
            category: ''
          },
          {
            id: 'phone_consult',
            name: 'Phone Consultation',
            category: ''
          },
          {
            id: 'app_button',
            name: 'Button Click (App)',
            category: ''
          },
          {
            id: 'app_detail',
            name: 'Details Page Browse (App Download)',
            category: ''
          },
          {
            id: 'app_download',
            name: 'Button Click (Download)',
            category: ''
          },
          {
            id: 'ad_watch',
            name: 'Ad Watch',
            category: ''
          },
          {
            id: '{txid}',
            name: 'Custom – Transaction ID Value',
            category: ''
          }
        ]
      }
    ],
    postbackURL: ({ pixelId = 'PIXEL_ID_HERE', eventId = 'EVENT_HERE' }) =>
      `https://functions.funnelflux.pro/bigo-s2s-api?bbg={external}&pixel_id=${pixelId}&event_id=${eventId}&timestamp={conversion-time}&url={page-url}&referrer={referrer}&ip={ip}&user_agent={user-agent}&external_id={visitor}&revenue={payout}`
  }
};

export const defaultCustomEventAliases = {
  '1': {
    alias: '',
    shortAlias: ''
  },
  '2': {
    alias: '',
    shortAlias: ''
  },
  '3': {
    alias: '',
    shortAlias: ''
  },
  '4': {
    alias: '',
    shortAlias: ''
  },
  '5': {
    alias: '',
    shortAlias: ''
  },
  '6': {
    alias: '',
    shortAlias: ''
  },
  '7': {
    alias: '',
    shortAlias: ''
  },
  '8': {
    alias: '',
    shortAlias: ''
  },
  '9': {
    alias: '',
    shortAlias: ''
  },
  '10': {
    alias: '',
    shortAlias: ''
  }
};

import { UserSettings } from '../../model/userSettings';
import { defined } from '../define';
import { DomainEntry } from '../../model/domains/domainEntry';

export const getDefaultDomain = (
  userSettings?: UserSettings,
  domains?: DomainEntry[]
) =>
  defined(userSettings) &&
  defined(userSettings.defaultCustomDomain) &&
  userSettings.defaultCustomDomain !== ''
    ? userSettings.defaultCustomDomain
    : defined(userSettings) && defined(domains) && domains.length > 0
    ? domains[0].domain
    : '';

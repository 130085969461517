import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import { conditionalClass } from 'conditional-class';
import React from 'react';
import './style.scss';

export default ({
  disabled = false,
  value,
  onChange,
  style,
  className = '',
  placeholder,
  resizable,
  ...props
}: {
  'data-testid'?: string;
  resizable?: boolean;
} & TextAreaProps) => (
  <TextArea
    className={conditionalClass(['c-ffTextarea', className], {
      'c-ffTextarea--notResizable': !resizable,
      'c-ffTextarea--disabled': disabled,
    })}
    disabled={disabled}
    value={Array.isArray(value) ? value.join('\n') : value}
    onChange={onChange}
    style={style}
    placeholder={placeholder}
    {...props}
  />
);

import { SummaryDataStore, SummaryStore } from '../../types/redux/store';
import { API_DEBOUNCE } from '../../constants/api';
import { defined } from 'utils/define';
import { SummaryTypes } from '../../types/summary';
import { summaryData } from '../reducers/defaultStates';

export const getGraphSummary = (state: SummaryStore) => state.graph.reporting;

export const shouldFetchReporting = (
  state: SummaryStore,
  type: SummaryTypes
) => {
  const stateOfType = (!!state[type]
    ? state[type]
    : summaryData) as SummaryDataStore;

  return defined(stateOfType.reporting.rows)
    ? Date.now() - stateOfType.loadings.reporting.fetchedAt > API_DEBOUNCE ||
        stateOfType.reporting.rows.length === 0
    : false;
};

export const shouldFetchGraph = (state: SummaryStore, type: SummaryTypes) => {
  const stateOfType = (!!state[type]
    ? state[type]
    : summaryData) as SummaryDataStore;

  return (
    Date.now() - stateOfType.loadings.data.fetchedAt > API_DEBOUNCE ||
    Object.keys(state.graph).length === 0
  );
};

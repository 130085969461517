import table from './table';
import { offerSources as defaultState } from './defaultStates';
import { OFFERSOURCES, API, VIEW, TABLE } from '../types';
import {
  OfferSourceAction,
  ReportingParamsAction
} from '../../types/redux/action';
import { getFieldName, getReverseStatus } from '../../utils/selectors';
import { AnyAction } from 'redux';
import { OfferSourceStore, OfferSourceArray } from '../../types/redux/store';
import { defined } from '../../utils/define';
import { OfferSource } from '../../model/offerSource';
import { Category } from '../../model/category';
import { DrilldownReport } from '../../model/drilldownReport';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { ReportCategory } from 'model/reportCategory';

const data = (state = defaultState.data, action: OfferSourceAction) => {
  switch (action.type) {
    case OFFERSOURCES.SET_LIST:
      if (defined(action.payload)) {
        return (action.payload as OfferSource[]).reduce(
          (acc: OfferSourceArray, offerSource) => {
            acc[offerSource.idOfferSource] = offerSource;
            return acc;
          },
          {}
        );
      }
      return state;
    case OFFERSOURCES.ARCHIVE:
      if (defined(action.payload)) {
        const id = (action.payload as any).data as string;
        if (!!id && !!state[id]) {
          state[id] = {
            ...state[id],
            status: getReverseStatus(state[id].status!)
          };
        }
        return state;
      }
      return state;
    case OFFERSOURCES.UPDATE_SINGLE:
    case OFFERSOURCES.ADD_SINGLE:
    case OFFERSOURCES.ADD_EXISTED_SINGLE:
      return {
        ...state,
        [(action.payload as OfferSource).idOfferSource]: action.payload
      };
    default:
      return state;
  }
};

const reporting = (
  state = defaultState.reporting,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case OFFERSOURCES.UPDATE_CATEGORY_ENTITIES:
    case OFFERSOURCES.CREATE_CATEGORY:
      const idCategory = (action.payload as any).idCategory;
      const categoryName = (action.payload as any).categoryName;
      if (defined(action.payload) && !!idCategory && !!categoryName) {
        return {
          ...state,
          entities: {
            ...state.entities,
            categories: state.entities!.categories!.filter(
              item => item.id === idCategory
            ).length
              ? state.entities!.categories
              : [
                  ...state.entities!.categories!,
                  {
                    id: idCategory,
                    name: categoryName,
                    type: 'offersources' as ReportCategory.TypeEnum,
                    status: 'active'
                  } as ReportCategory
                ]
          }
        };
      }
      return state;
    case OFFERSOURCES.ARCHIVE_CATEGORY:
      if (defined(action.payload)) {
        const id = action.payload.data as string;
        return {
          ...state,
          entities: {
            ...state.entities,
            categories: state.entities!.categories!.map(category => {
              if (category.id === id) {
                return {
                  ...category,
                  status: getReverseStatus(category.status)
                };
              }
              return category;
            })
          }
        };
      }
      return state;
    case OFFERSOURCES.SET_REPORTING:
      if (defined(action.payload)) {
        return {
          ...(action.payload.data as DrilldownReport)
        };
      }
      return state;
    case OFFERSOURCES.DELETE:
      if (defined(action.payload)) {
        const deletePayload = action.payload.data as string[];
        return {
          ...state,
          rows: [
            ...state.rows.filter(
              (item: any) => !deletePayload.includes(item.attributes[0].id)
            )
          ]
        };
      }
      return state;
    case OFFERSOURCES.ARCHIVE:
      if (defined(action.payload)) {
        const archivePayload = action.payload.data as string;
        return {
          ...state,
          rows: state.rows.map((item: DrilldownReportRow) => {
            if (item.attributes[0].id === archivePayload) {
              item.attributes[0].status = getReverseStatus(
                item.attributes[0].status
              );
            }
            return item;
          })
        };
      }
      return state;
    case OFFERSOURCES.ADD_SINGLE_ROW:
      if (defined(action.payload)) {
        return {
          ...state,
          rows: [...state.rows, action.payload.data as DrilldownReportRow]
        };
      }
      return state;
    case OFFERSOURCES.UPDATE_SINGLE_REPORTING:
      if (defined(action.payload)) {
        return {
          ...state,
          rows: action.payload.data as DrilldownReportRow[]
        };
      }
      return state;
    case OFFERSOURCES.DELETE_CATEGORY:
      if (defined(action.payload)) {
        const categories = state.entities!.categories!.filter(category =>
          //@ts-ignore
          action.payload.indexOf(category.id)
        );

        return {
          ...state,
          entities: {
            ...state.entities,
            categories: categories
          }
        };
      }

      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loadings, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        [getFieldName(action, OFFERSOURCES.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: true
        }
      };
    case API.ENDED:
      return {
        ...state,
        [getFieldName(action, OFFERSOURCES.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: false,
          fetchedAt: Date.now()
        }
      };
    default:
      return state;
  }
};

const tableCols = (
  state = defaultState.settings.tableCols,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.payload.view === VIEW.OFFERSOURCES &&
    action.type === TABLE.SET_COLS
  ) {
    return table(state, action);
  } else {
    return state;
  }
};

const categories = (state = defaultState.categories, action: AnyAction) => {
  switch (action.type) {
    case OFFERSOURCES.SET_CATEGORIES:
      return [...action.payload];
    case OFFERSOURCES.CREATE_CATEGORY:
      return [...state, ...[action.payload]];
    case OFFERSOURCES.UPDATE_CATEGORY:
      return state.reduce((acc: Category[], item: Category) => {
        acc.push(
          item.idCategory === action.payload.idCategory
            ? (action.payload as Category)
            : item
        );
        return acc;
      }, []);
    case OFFERSOURCES.ARCHIVE_CATEGORY:
      if (defined(action.payload)) {
        const id = action.payload.data as string;
        return state.map(category => {
          if (category.idCategory === id) {
            return {
              ...category,
              status: getReverseStatus(category.status!)
            };
          }
          return category;
        });
      }
      return state;
    case OFFERSOURCES.DELETE_CATEGORY:
      return state.filter(item => action.payload.indexOf(item.idCategory) < 0);
    default:
      return state;
  }
};

const show = (state = defaultState.settings.show, action: AnyAction) => {
  switch (action.type) {
    case OFFERSOURCES.SET_SHOW:
      return action.payload;
    default:
      return state;
  }
};

const filters = (
  state = defaultState.settings.filters,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case OFFERSOURCES.SET_REPORTING:
      return action.payload.params;
    default:
      return state;
  }
};

const tableParams = (
  state = defaultState.settings.tableParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_TABLE_PARAMS &&
    action.payload.view === VIEW.OFFERSOURCES
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const editMode = (
  state = defaultState.settings.editMode,
  action: AnyAction
) => {
  return action.type === TABLE.SET_EDIT_MODE &&
    action.payload.view === VIEW.OFFERSOURCES
    ? action.payload.data
    : state;
};

export default (state = defaultState, action: any): OfferSourceStore => ({
  data: data(state.data, action) as OfferSourceArray,
  settings: {
    show: show(state.settings.show, action),
    tableCols: tableCols(state.settings.tableCols, action),
    tableParams: tableParams(state.settings.tableParams, action),
    filters: filters(state.settings.filters, action),
    editMode: editMode(state.settings.editMode, action)
  },
  reporting: reporting(state.reporting, action),
  categories: categories(state.categories, action),
  loadings: loading(state.loadings, action)
});

import { IFloatingFilterParams, NumberFilterModel } from 'ag-grid-community';
import React, { Component } from 'react';
import { definedObject } from 'utils/define';

interface State {
  currentValue: string;
}

export default class TextFloatingFilterComponent extends Component<IFloatingFilterParams, State> {
  state: State = {
    currentValue: ''
  };

  onParentModelChanged(parentModel: NumberFilterModel) {
    if (definedObject(parentModel)) {
      this.setState({ currentValue: String(parentModel.filter) });
    }
  }

  onInputBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({ currentValue: value });
    if (!value) {
      this.props.parentFilterInstance(instance => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    this.props.parentFilterInstance(instance => {
      instance.onFloatingFilterChanged('contains', value);
    });
  };

  render() {
    return (
      <div className="c-AggridTable__filterWrapper">
        <input
          className="c-AggridTable__colFilterInput"
          onChange={this.onInputBoxChanged}
          value={this.state.currentValue}
        />
      </div>
    );
  }
}

import React from 'react';
import Messages from 'components/Messages';
import { arrayDifference } from 'utils/arrs';
import validateForm, { notExist, required, TYPE_ARRAY } from 'utils/validation';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from 'utils/reactive/generic';
import { CONFIRM_MODAL } from 'constants/modal';
import { FormContentWrapper, FormSectionBox } from 'components/Parts/Content';
import { Category } from '../../../model/category';
import { generateEntityId } from '../../../utils/id/generator';
import { Page } from '../../../model/page';
import { AnyObject, MessageProps } from '../../../types';
import { CategoryFormProps } from '../../../types/ModalForms/category';
import { ValidationRule } from '../../../utils/validation/types';
import { LoadingProps } from 'types/loading';
import { defined } from 'utils/define';
import './style.scss';
import { FFAddGroup, FFInput } from 'uikit';
import { FFIconedButton } from 'uikit';

interface State extends ReactiveValidateState {
  data: Category[];
  forValidation: number[];
  prevData: Category[];
  isTouchedForm: boolean;
  validationErrors: AnyObject;
}

class CategoryForm extends ReactiveValidateComponent<
  CategoryFormProps & MessageProps & LoadingProps,
  State
> {
  validationRules: ValidationRule[] = [
    {
      field: 'name',
      type: TYPE_ARRAY,
      validations: [required, notExist(this.props.filterOut)]
    }
  ];

  state: State = {
    data: [],
    prevData: [],
    forValidation: [],
    isTouchedForm: false,
    validationErrors: {}
  };

  static defaultProps: Partial<CategoryFormProps> = {
    categories: [],
    setContextSidebar: () => {},
    openSidebar: () => {},
    closeSidebar: () => {}
  };

  componentDidMount() {
    if (defined(this.props.setButtonGroupProps)) {
      this.props.setButtonGroupProps({
        showSave: true,
        saveText: 'SAVE',
        onSaveClicked: this.handleFormSubmit,
        showCancel: true,
        cancelText: 'DISCARD'
      });
    }

    if (this.props.type === 'condition') {
      this.props.fetchConditionsCategories();
    }
    if (this.props.type === 'lander') {
      this.props.fetchLandersCategories();
    }
    if (this.props.type === 'offer') {
      this.props.fetchOffersCategories();
    }
    if (this.props.type === 'offersources') {
      this.props.fetchOfferSourcesCategories();
    }
    if (this.props.type === 'trafficsources') {
      this.props.fetchTrafficSourcesCategories();
    }
  }

  static getDerivedStateFromProps(props: CategoryFormProps, state: State) {
    if (props.categories.length !== state.prevData.length) {
      return {
        prevData: props.categories,
        data: props.categories
      };
    } else if (props.categories.length === 0 && state.data.length === 0) {
      return {
        prevData: props.categories,
        data: [{ name: '', type: props.type }]
      };
    }

    return null;
  }

  handleConfirmLoading = (name: string, value: boolean) => {
    if (name === 'confirmLoading') {
      this.props.setContextSidebar!(CONFIRM_MODAL, {
        loading: value
      });
      return;
    }
  };

  addNewFieldSet = () => {
    this.setState((state: State) => ({
      ...state,
      data: [
        ...state.data,
        {
          categoryName: '',
          idCategory: '',
          categoryType: this.props.type,
          status: 'active'
        }
      ]
    }));
  };

  confirmRemoveFieldSet = (idx: number) => {
    const category = this.state.data[idx];
    if (category.idCategory) {
      this.props.setContextSidebar!(CONFIRM_MODAL, {
        //@ts-ignore
        content: Confirm(this.props.type),
        title: `Delete Category`,
        onConfirm: () => this.removeFieldSet(idx)
      });
      this.props.openSidebar!(CONFIRM_MODAL);
    } else {
      this.removeFieldSet(idx);
    }
  };

  removeFieldSet = async (idx: number) => {
    const category = this.state.data[idx];
    if (category.idCategory) {
      this.handleConfirmLoading('confirmLoading', true);
      try {
        await this.props.onDeleteCategory({ entries: [category.idCategory] });
        this.props.showMessage(
          Messages.success(`${category.categoryName} has been deleted`)
        );
        this.handleConfirmLoading('confirmLoading', false);
        this.props.closeSidebar!(CONFIRM_MODAL);
      } catch (e) {
        this.props.showMessage(
          Messages.failed(`${category.categoryName} cannot be deleted`)
        );
        this.handleConfirmLoading('confirmLoading', false);
        this.props.closeSidebar!(CONFIRM_MODAL);
      }
      return;
    }

    this.setState(
      (state: State) => ({
        ...state,
        data: state.data.filter((_, i) => i !== idx)
      }),
      () => this.validate()
    );
  };

  handleInputChange = (pos: number, value: string) => {
    const setState = (data: Category[]) =>
      [...data].map((field, idx) =>
        idx === pos ? { ...field, categoryName: value } : field
      );
    this.setState(
      (state: State) => ({
        ...state,
        data: setState(state.data)
      }),
      () => this.validate$.next()
    );
  };

  handleFormSubmit = async (e: Event) => {
    e.preventDefault();
    this.props.startLoading('save');
    await this.setTouchedForm();
    await this.setDataForValidation();
    await this.validate();

    const changes = this.getDataChanges();
    const anyChange = changes.length;
    const anyValidationError = !Object.keys(this.state.validationErrors).length;

    const showMessage = (type: 'updated' | 'created', updated: string) => {
      this.props.stopLoading('all');
      this.props.showMessage(Messages.success(`${updated} has been ${type}`));
      this.setState((state: State) => ({
        ...state,
        prevData: state.data
      }));
    };

    if (anyValidationError && anyChange) {
      try {
        await changes.forEach(async (item: Category, idx: number) => {
          try {
            if (item.idCategory === '') {
              await this.props.onCreateCategory({
                ...item,
                idCategory: generateEntityId()
              });
              showMessage('created', item.categoryName);
              this.props.closeSelf();
            } else {
              await this.props.onUpdateCategory(item);
              showMessage('updated', item.categoryName);
              this.props.closeSelf();
            }
          } catch (e) {
            this.props.showMessage(
              Messages.failed(`${item.categoryName} cannot be added/updated`)
            );
            this.props.stopLoading('all');
          }
        });
      } catch (e) {
        this.props.showMessage(
          Messages.failed(`Categories cannot be added/updated`)
        );
        this.props.stopLoading('all');
      }
    } else if (!anyChange) {
      this.props.stopLoading('all');
      this.props.closeSelf();
    } else {
      this.props.stopLoading('all');
    }
  };

  setDataForValidation = () => {
    this.setState((state: State) => ({
      ...state,
      forValidation: state.data.map((_, idx) => {
        return idx;
      })
    }));
  };

  getDataChanges = () => {
    const getData = (array: Category[]) =>
      array.reduce(
        (
          acc: Category[],
          {
            idCategory = '',
            categoryName,
            categoryType = this.props.type,
            status = 'active'
          }
        ) => [
          ...acc,
          {
            idCategory: idCategory,
            categoryName: categoryName,
            categoryType: categoryType,
            status: status
          }
        ],
        []
      );

    const getDataWithEmptyId = (array: Category[]) =>
      array
        .filter(
          (item: Category) =>
            item.idCategory === '' || item.idCategory === undefined
        )
        .reduce(
          (
            acc: Category[],
            {
              idCategory = '',
              categoryName,
              categoryType = this.props.type,
              status = 'active'
            }
          ) => [
            ...acc,
            {
              idCategory: idCategory,
              categoryName: categoryName,
              categoryType: categoryType,
              status: status
            }
          ],
          []
        );

    let changedData = arrayDifference(
      getData(this.state.data),
      getData(this.state.prevData)
    );

    // if we pass prepared data with empty id (new row) we should return these rows
    if (!changedData.length) {
      const dataWithEmptyId = getDataWithEmptyId(this.state.data);
      changedData =
        getDataWithEmptyId.length > 0 ? dataWithEmptyId : changedData;
    }

    return changedData;
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      const fields = this.state.data.reduce(
        (acc: any, { categoryName }, idx) => {
          if (this.state.forValidation.indexOf(idx) > -1) {
            acc[idx] = categoryName;
          }
          return acc;
        },
        {}
      );

      await this.setState({ validationErrors: {} });
      const [validationErrors] = validateForm(
        { name: fields },
        this.validationRules
      );

      this.setState((state: State) => ({
        ...state,
        validationErrors
      }));
    }
  };

  validateClassName = (idx: number) =>
    !this.state.validationErrors?.name?.[idx] ? '' : 'has-error';

  render() {
    return (
      <FormContentWrapper className="c-categoryForm">
        <FormSectionBox>
          <FFAddGroup
            rows={this.state.data}
            onAddRow={this.addNewFieldSet}
            rowClassName="flex flex-align-center flex-justify-center"
            showAddRow={rows =>
              !!(
                this.state.data.length > 1 ||
                rows[this.state.data.length - 1]?.idCategory
              )
            }
            renderRow={(row, rowIdx) => {
              return (
                <>
                  <FFAddGroup.Col>
                    <FFInput
                      value={row.categoryName}
                      data-testid={`ff-${rowIdx}`}
                      key={`ff-group-${rowIdx}`}
                      placeholder="Category name"
                      className={this.validateClassName(rowIdx)}
                      onChange={e =>
                        this.handleInputChange(rowIdx, e.target.value)
                      }
                    />
                  </FFAddGroup.Col>
                  <FFAddGroup.Col autoWidth>
                    <FFIconedButton.CircleDelete
                      onClick={() => this.confirmRemoveFieldSet(rowIdx)}
                    />
                  </FFAddGroup.Col>
                </>
              );
            }}
          ></FFAddGroup>
        </FormSectionBox>
      </FormContentWrapper>
    );
  }
}

const Confirm = (type: Page.PageTypeEnum) => {
  return (
    <>
      <p>
        Deleting this category will move all {type}s in it to{' '}
        <strong>Uncategorized</strong>.
      </p>
      <p>
        <strong>
          Are you sure you want to delete this category? (this action is not
          reversible).
        </strong>
      </p>
    </>
  );
};

export default Messages.injectIn(CategoryForm);


				var addMethods = require("../../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["getDataPassingQuery","calculateSummary","updatedRowsWorker"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "[fullhash].worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "[fullhash].worker.js");
					addMethods(w, methods)
					
					return w
				}
			
import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import {
  addLander,
  updateLander,
  getLanderById,
  duplicateLander,
  fetchCategories as fetchLanderCategories,
  fetchLandersInfo
} from 'redux/actions/landers';
import { fetchSystemSettings } from 'redux/actions/systemSettings';
import {
  LANDER_CATEGORY_MODAL,
  LANDER_DUPLICATE_MODAL,
  LANDER_MODAL
} from 'constants/modal';
import { connect } from 'react-redux';
import { LanderModalSelector } from 'redux/selectors';
import {
  LanderFormProps,
  LanderFormSidebarProps,
  LandersTabKey
} from 'types/ModalForms/lander';
import { getModalTitle, isCopyByContextModal } from 'utils/modals';
import { FormContextSidebar } from 'types/modal';
import LanderForm from 'components/Forms/Lander';
import { SidebarsConsumer } from '../context';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import { SidebarProps } from 'types/sidebars';
import {
  LANDER_FORM_TAB,
  LANDER_FORM_ADVANCED_TAB,
  LANDER_FORM_HELP_TAB,
  LANDER_FORM_JAVASCRIPT_TAB,
  LANDER_FORM_PAGE_LINKS_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import { fetchDomains } from 'redux/actions/domains';
import { FFIcon } from 'uikit';

class LanderFormWrapper extends React.Component<
  LanderFormProps & LanderFormSidebarProps & LoadingProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        isOpen={this.props.isOpen!}
        loading={this.props.loading}
        hasTab={true}
        onClose={this.props.closeSelf}
        dataPortalKey="lander"
        defaultOpenSideBarKey="landerForm"
        defaultActiveTab={LANDER_FORM_TAB}
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'landerForm',
            tabs: {
              [LANDER_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [LANDER_FORM_ADVANCED_TAB]: {
                title: 'Configure Data Passing',
                icon: <FFIcon name="dataPassing" />,
                highlightable: true
              },
              [LANDER_FORM_PAGE_LINKS_TAB]: {
                title: 'Page Action Links',
                icon: <FFIcon name="actionLink" />,
                isHidden: isCopyByContextModal(this.props.contextModal)
              },
              [LANDER_FORM_JAVASCRIPT_TAB]: {
                title: 'Javascript Tracking',
                icon: <FFIcon name="jsTracking" />,
                isHidden: isCopyByContextModal(this.props.contextModal)
              },
              [LANDER_FORM_HELP_TAB]: {
                title: 'Help',
                icon: <FFIcon name="question" />,
              }
            },
            title: `${getModalTitle(this.props.contextModal)} Lander`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setLoading,
              setForCopyIdsProps,
              setTabsError,
              sidebarLoading,
              tabTitle
            }) => {
              return (
                <>
                  <LanderForm
                    {...this.props}
                    activeTab={activeTab as LandersTabKey}
                    setButtonGroupProps={setButtonGroupProps}
                    setForCopyIdsProps={setForCopyIdsProps}
                    setSidebarLoading={setLoading}
                    sidebarLoading={sidebarLoading}
                    tabTitle={tabTitle}
                    setTabsError={setTabsError}
                  />
                </>
              );
            }
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(LanderFormWrapper);

const ConnectedFormElement = ({
  landers,
  categories,
  addLander,
  updateLander,
  duplicateLander,
  getLanderById,
  fetchSystemSettings,
  redirectType,
  domain,
  customDomains,
  fetchLanderCategories,
  fetchDomains,
  fetchLandersInfo
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        setContextSidebar,
        openSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let sidebarName = LANDER_MODAL;
        let contextSidebar = getContextSidebar(
          LANDER_MODAL
        ) as FormContextSidebar;

        if (isOpenSidebar(LANDER_DUPLICATE_MODAL)) {
          sidebarName = LANDER_DUPLICATE_MODAL;
          contextSidebar = getContextSidebar(
            LANDER_DUPLICATE_MODAL
          ) as FormContextSidebar;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            landers
          );
        }
        const isOpen =
          isOpenSidebar(LANDER_MODAL) || isOpenSidebar(LANDER_DUPLICATE_MODAL);

        return (
          <FormElement
            fetchLandersInfo={fetchLandersInfo}
            fetchDomains={fetchDomains}
            fetchCategories={fetchLanderCategories}
            landers={landers}
            categories={categories}
            domain={domain}
            customDomains={customDomains}
            fetchSystemSettings={fetchSystemSettings}
            redirectType={redirectType}
            getLanderById={getLanderById}
            contextModal={contextSidebar}
            setContextModal={setContextSidebar}
            handleCreate={addLander}
            handleUpdate={updateLander}
            handleDuplicate={duplicateLander}
            openCategoriesModal={() => openSidebar(LANDER_CATEGORY_MODAL)}
            closeSelf={() => closeSidebar(sidebarName)}
            isOpen={isOpen}
            zIndex={contextSidebar.zIndex!}
            offsetRight={getOffsetRight(sidebarName)}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(LanderModalSelector, {
  addLander,
  updateLander,
  duplicateLander,
  getLanderById,
  fetchSystemSettings,
  fetchLanderCategories,
  fetchDomains,
  fetchLandersInfo
})(ConnectedFormElement);

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import { CategoryModalSelector } from 'redux/selectors';
import { ModalsConsumer } from '../context';
import { deleteCategory } from 'redux/actions/categories';
import {
  LANDER_CATEGORY_DELETE_MODAL,
  LANDER_CATEGORY_TYPE,
  OFFER_CATEGORY_DELETE_MODAL,
  OFFER_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_DELETE_MODAL,
  OFFER_SOURCE_CATEGORY_TYPE,
  UNCATEGORIZED,
  CONDITION_CATEGORY_TYPE,
  categoryTypeSourceNameMap
} from 'constants/modal';
import ConfirmForm from '../../Forms/Confirm';
import {
  TRAFFIC_SOURCE_CATEGORY_DELETE_MODAL,
  TRAFFIC_SOURCE_CATEGORY_TYPE,
  CONDITION_CATEGORY_DELETE_MODAL
} from '../../../constants/modal';
import { ModalProps } from '../types';
import { CategoryDeleteReduxProps } from 'types/ModalForms/category';
import { CategoryDeleteModalProps } from '../../../types/ModalForms/category';
import { FormContextModal } from '../../../types/modal';
import { getNameModal } from '../../../utils/modals';

const DeleteCategoryText = (props: { categoryType: string }) => {
  return (
    <div className="margin-bottom-15 color-465565">
      <p>
        Deleting this category will move all{' '}
        {categoryTypeSourceNameMap[props.categoryType]} in it to{' '}
        <strong>{UNCATEGORIZED}</strong>.
      </p>
      <p>
        Are you sure you want to delete this category? (this action is not
        reversible).
      </p>
    </div>
  );
};

export const CategoryDelete = ({
  isOpen,
  onSubmit,
  contextModal,
  closeSelf,
  type
}: CategoryDeleteModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    width={418}
    destroyOnClose
    isOpen={isOpen}
    title={`Delete Category ${getNameModal(contextModal)}`}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ConfirmForm
        prepareData={(context: FormContextModal) => ({
          entries: [context.data.id]
        })}
        successMessage={(context: FormContextModal) =>
          `${context.data.value} has been deleted`
        }
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        contextModal={contextModal}
        cancelText="Cancel"
        okText="Hold to Delete"
        withHoldSubmit
        text={<DeleteCategoryText categoryType={type} />}
      />
    )}
  />
);

export default connect(CategoryModalSelector, {
  deleteCategory
})(({ deleteCategory }: CategoryDeleteReduxProps) => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }: ModalProps) => {
      let type: string = LANDER_CATEGORY_TYPE;
      let modalName = LANDER_CATEGORY_DELETE_MODAL;
      let contextModal = getContextModal(
        LANDER_CATEGORY_DELETE_MODAL
      ) as FormContextModal;

      if (isOpenModal(OFFER_CATEGORY_DELETE_MODAL)) {
        type = OFFER_CATEGORY_TYPE;
        modalName = OFFER_CATEGORY_DELETE_MODAL;
        contextModal = getContextModal(
          OFFER_CATEGORY_DELETE_MODAL
        ) as FormContextModal;
      }

      if (isOpenModal(OFFER_SOURCE_CATEGORY_DELETE_MODAL)) {
        type = OFFER_SOURCE_CATEGORY_TYPE;
        modalName = OFFER_SOURCE_CATEGORY_DELETE_MODAL;
        contextModal = getContextModal(
          OFFER_SOURCE_CATEGORY_DELETE_MODAL
        ) as FormContextModal;
      }

      if (isOpenModal(TRAFFIC_SOURCE_CATEGORY_DELETE_MODAL)) {
        type = TRAFFIC_SOURCE_CATEGORY_TYPE;
        modalName = TRAFFIC_SOURCE_CATEGORY_DELETE_MODAL;
        contextModal = getContextModal(
          TRAFFIC_SOURCE_CATEGORY_DELETE_MODAL
        ) as FormContextModal;
      }

      if (isOpenModal(CONDITION_CATEGORY_DELETE_MODAL)) {
        type = CONDITION_CATEGORY_TYPE;
        modalName = CONDITION_CATEGORY_DELETE_MODAL;
        contextModal = getContextModal(
          CONDITION_CATEGORY_DELETE_MODAL
        ) as FormContextModal;
      }

      const isOpen =
        isOpenModal(LANDER_CATEGORY_DELETE_MODAL) ||
        isOpenModal(OFFER_CATEGORY_DELETE_MODAL) ||
        isOpenModal(OFFER_SOURCE_CATEGORY_DELETE_MODAL) ||
        isOpenModal(TRAFFIC_SOURCE_CATEGORY_DELETE_MODAL) ||
        isOpenModal(CONDITION_CATEGORY_DELETE_MODAL);

      return (
        <>
          <CategoryDelete
            isOpen={isOpen}
            type={type}
            onSubmit={deleteCategory(type)}
            contextModal={contextModal}
            closeSelf={() => closeModal(modalName)}
          />
        </>
      );
    }}
  </ModalsConsumer>
));

import React, { ErrorInfo, PropsWithChildren } from 'react';
import logo from '../../imgs/funnelflux-symbol.svg';
import './style.scss';
import { defined } from '../../utils/define';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<PropsWithChildren, State> {
  state: State = { hasError: false };
  isChunkError = false;

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const isChunkError =
      defined(error.message) && error.message.includes('Loading chunk');
    if (isChunkError) {
      this.isChunkError = true;
      await window.location.reload();
    } else {
      this.setState({ hasError: true });
      // trackSentry(error, errorInfo);
    }
  }

  static getDerivedStateFromError(error: Error) {
    // trackSentry(error);
    return null;
  }

  render() {
    if (this.isChunkError) {
      return (
        <div className="error-page">
          <span>
            <img src={logo} alt="ff logo" className="side-item" />
            <div className="error-text">
              Updating application. <br />
            </div>
          </span>
        </div>
      );
    }
    if (this.state.hasError) {
      return (
        <div className="error-page">
          <span>
            <img src={logo} alt="ff logo" className="side-item" />
            <div className="error-text">
              A Javascript error has occurred.
              <br /> We've already logged the error and will try to fix it as
              soon as possible. <br />
            </div>
          </span>
        </div>
      );
    }
    return this.props.children;
  }
}

import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  className?: string;
  dataPortalKey: string;
  zIndex: number;
}

export default class ReactPortal extends React.Component<PropsWithChildren<Props>, {}> {
  el = document.createElement('div');
  appRoot = document.getElementById('root');

  componentDidMount() {
    this.el.setAttribute('data-portal-key', this.props.dataPortalKey);
    if (this.props.className) {
      this.el.classList.add(this.props.className);
    }
    this.setZIndex();
    this.appRoot!.appendChild(this.el);
  }

  componentDidUpdate(prevProps: Props, _: {}) {
   if (prevProps.zIndex !== this.props.zIndex) {
      this.setZIndex();
    }
  }

  setZIndex = () => this.el.setAttribute('style', `z-index: ${this.props.zIndex || 0}`);

  componentWillUnmount() {
    this.appRoot!.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g>
      <path
        id="Vector"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m12.004741221428572 0.7960714285714285 -9.775757142857142 9.791678571428571c-0.07615219285714285 0.07147129285714285 -0.13684467857142857 0.15779727857142858 -0.1783359214285714 0.25364427857142857 -0.041491242857142854 0.09583107857142857 -0.06290556428571428 0.19916115 -0.06290556428571428 0.3036057214285714 0 0.10444457142857143 0.02141432142857143 0.20777464285714287 0.06290556428571428 0.3036057214285714 0.041491242857142854 0.09584699999999999 0.10218372857142857 0.1821729857142857 0.1783359214285714 0.25364427857142857l9.775757142857142 9.791678571428571"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_2"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.299821428571427 0.7960714285714285 10.508126935714285 10.58775c-0.14585620714285713 0.14880167142857142 -0.22756497857142857 0.34887034285714286 -0.22756497857142857 0.5572499999999999 0 0.20837965714285714 0.08170877142857143 0.40844832857142854 0.22756497857142857 0.5572499999999999L20.299821428571427 21.49392857142857"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

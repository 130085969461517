import React, { Component } from 'react';
import Messages from 'components/Messages';
import { ContentWrapper } from 'components/Parts/Content';
import { withLoading } from 'components/Loading';
import { TrafficSourceDeleteFormProps } from 'types/ModalForms/trafficSourceDelete';
import makeCancellablePromise, { timeoutPromise } from 'utils/promises';
import { FFButton } from 'uikit';
import { CancellablePromise } from 'types';

interface State {
  isHolding: boolean;
}

class TrafficSourceDeleteForm extends Component<
  TrafficSourceDeleteFormProps,
  State
> {
  state: State = {
    isHolding: false,
  }
  private submitPromise: Partial<CancellablePromise> = {};

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.handleState('isHolding', true);
    this.submitPromise = makeCancellablePromise(timeoutPromise(1000));

    try {
      await this.submitPromise.promise;
      this.handleState('isHolding', false);
      this.props.startLoading('ok');
      await this.props.handleSubmit({
        entries: [this.props.contextModal.data.id]
      });
      this.props.showMessage(
        Messages.success(
          `Traffic Source ${this.props.contextModal.data.value} has been deleted`
        )
      );
      this.props.stopLoading('all');
      this.props.actions.onOk();
    } catch (error) {
      this.props.stopLoading('all');
      this.props.showMessage(Messages.failed(`Traffic Source delete failed`));
    }
  };

  handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.actions.onClose();
  };

  onMouseLeaveOrUp = () => {
    if (!this.submitPromise?.cancel) return;
    this.handleState('isHolding', false);
    this.submitPromise.cancel();
    this.props.stopLoading('all');
  };

  render() {
    return (
      <ContentWrapper
        padding="22px 25px 29px 29px"
        className="with-common-text-margin"
      >
        <p>
          <strong>
            Deleting a traffic source will remove it from this page and prevent
            generation of new links. This will not break existing tracking
            links/JS, or remove existing data.
          </strong>
        </p>
        <p>
          You will also not be able to pick the traffic source’s custom fields
          as grouping items in reporting, though these will still be present in
          the Traffic Source &gt; Fields reporting attribute.
        </p>
        <p>
          <strong>This is an irreversible action, </strong>
          we highly suggest archiving unless you are sure you do not need this
          traffic source anymore.
        </p>
        <p>
          To delete underlying traffic source data, use the{' '}
          <strong>Reset Stats</strong> feature.
        </p>
        <div className="flex flex-gap-5">
          <FFButton
            onMouseDown={this.handleSubmit}
            onMouseLeave={this.onMouseLeaveOrUp}
            onMouseUp={this.onMouseLeaveOrUp}
            loading={this.state.isHolding}
            disabled={this.props.loading.ok}
            loadingType={this.props.loading.ok ? 'default' : 'skeleton'}
            color="dangerColor"
            className="flex-1"
          >
            Hold to Delete
          </FFButton>
          <FFButton
            onClick={this.handleCancel}
            disabled={this.props.loading.ok}
            className="flex-1"
          >
            Cancel
          </FFButton>
        </div>
      </ContentWrapper>
    );
  }
}

export default withLoading(Messages.injectIn(TrafficSourceDeleteForm));

/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FunnelConditionGroup } from './funnelConditionGroup';


export interface FunnelConditionRoute { 
    routeName: string;
    operator: FunnelConditionRoute.OperatorEnum;
    groups: Array<FunnelConditionGroup>;
}
export namespace FunnelConditionRoute {
    export type OperatorEnum = 'or' | 'and';
    export const OperatorEnum = {
        Or: 'or' as OperatorEnum,
        And: 'and' as OperatorEnum
    };
}

import { updateTrafficCosts as defaultState } from './defaultStates';
import { UPDATE_TRAFFIC_COSTS } from '../types';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import { UpdateTrafficCostsStore } from '../../types/redux/store';
import { CostUpdateParams } from 'model/models';

const campaign = (state = defaultState.campaign, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_TRAFFIC_COSTS.SET_CAMPAIGN_OPTIONS:
      if (defined(action.payload)) {
        return {
          ...state,
          data: action.payload
        };
      }
      return state;
    case UPDATE_TRAFFIC_COSTS.SET_CAMPAIGN_VALUE:
      if (defined(action.payload)) {
        return {
          ...state,
          value: action.payload
        };
      }
      return state;
    default:
      return state;
  }
};

const data = (
  state = defaultState.data,
  action: AnyAction
): CostUpdateParams => {
  switch (action.type) {
    case UPDATE_TRAFFIC_COSTS.UPDATE:
      if (defined(action.payload)) {
        return {
          ...state,
          ...action.payload
        };
      }
      return state;
    default:
      return state;
  }
};

const settings = (state = defaultState.settings, action: AnyAction): any => {
  switch (action.type) {
    case UPDATE_TRAFFIC_COSTS.SET_SETTINGS:
      if (defined(action.payload)) {
        return {
          ...state,
          [action.payload.key]: action.payload.value
        };
      }
      return state;
    default:
      return state;
  }
};

const rowData = (state = defaultState.rowData, action: AnyAction): any => {
  switch (action.type) {
    case UPDATE_TRAFFIC_COSTS.SET_ROWS:
      if (defined(action.payload)) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): UpdateTrafficCostsStore => {
  return {
    data: data(state.data, action),
    campaign: campaign(state.campaign, action),
    settings: settings(state.settings, action),
    rowData: rowData(state.rowData, action)
  };
};

import { ConditionStore } from '../../types/redux/store';
import { UNCATEGORIZED } from '../../constants/modal';
import { defined } from '../../utils/define';
import { FunnelCondition, Category } from 'model/models';
import { makeReportingRowData } from 'utils/reporting';
import { getReportingTypeCategory } from 'utils/selectors';

export const getConditionsData = (state: ConditionStore) => {
  const categories = state.categories;

  const emptyConditionCategoryRows = categories.length
    ? categories
        .filter(
          (category: Category) =>
            !Object.values(state.data).filter(
              d => d.idCategory === category.idCategory
            ).length
        )
        .map((item: Category) => {
          return makeReportingRowData([getReportingTypeCategory(item)]);
        })
    : [];

  return Object.values(state.data)
    .filter((row: FunnelCondition) => !defined(row.restrictToFunnelId))
    .map((row: FunnelCondition) => {
      const category = categories.find(
        item => item.idCategory === row.idCategory
      );

      return {
        attributes: [
          defined(category)
            ? {
                id: category.idCategory,
                value: category.categoryName,
                status: category.status
              }
            : {
                id: UNCATEGORIZED,
                value: UNCATEGORIZED,
                status: 'active'
              },
          {
            id: row.idCondition,
            value: row.conditionName
          }
        ]
      };
    })
};

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import {
  archiveTrafficSource,
  getTrafficSourceById
} from 'redux/actions/trafficsources';
import { ModalsConsumer } from '../context';
import { TRAFFIC_SOURCE_ARCHIVE_MODAL } from 'constants/modal';
import ConfirmForm from '../../Forms/Confirm';
import { ModalProps } from '../types';
import { TrafficSourceArchiveModalProps } from '../../../types/ModalForms/trafficSourceArchive';
import { getNameModal } from '../../../utils/modals';
import { FormContextModal } from '../../../types/modal';
import { TrafficSourceModalSelector } from 'redux/selectors';
import { AnyObject } from 'types';

const TrafficSourcesArchive = () => {
  return (
    <div className="margin-bottom-15 color-465565">
      <p>
        <strong>
          Archiving a traffic source removes it from the active view.
        </strong>
      </p>
      <p>
        It will not delete existing data or break links, but the custom fields
        will not be available as separate grouping options in reporting. You
        will also not see the traffic source as an option when generating links.
      </p>
      <p>
        Archive traffic sources that are dormant but where you may want to
        reactivate them later.
      </p>
    </div>
  );
};

const TrafficSourcesArchiveModal = ({
  isOpen,
  onSubmit,
  closeModal,
  contextModal,
  trafficSources,
  getTrafficSourceById
}: TrafficSourceArchiveModalProps) => {
  const closeSelf = () => closeModal(TRAFFIC_SOURCE_ARCHIVE_MODAL);
  return (
    <FluxModal
      zIndex={contextModal.zIndex}
      width={547}
      destroyOnClose
      title={`Archive Traffic Source ${getNameModal(contextModal)}`}
      isOpen={isOpen}
      classNames={['traffic-source-archive']}
      actions={{
        onOk: closeSelf,
        onClose: closeSelf
      }}
      render={() => (
        <ConfirmForm
          prepareData={(context: FormContextModal) => ({
            ...trafficSources[context!.entityId!],
            status: 'archived'
          })}
          successMessage={(context: FormContextModal) =>
            `${context.data.value} has been archived`
          }
          handleSubmit={onSubmit}
          actions={{
            onOk: closeSelf,
            onClose: closeSelf
          }}
          contextModal={contextModal}
          okText="Confirm"
          cancelText="Cancel"
          text={<TrafficSourcesArchive />}
          fetchEntity={() => getTrafficSourceById(contextModal!.entityId!)}
        />
      )}
    />
  );
};

const ConnectedTrafficSourcesArchiveModal = ({
  archiveTrafficSource,
  trafficSources,
  getTrafficSourceById
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <TrafficSourcesArchiveModal
            isOpen={isOpenModal(TRAFFIC_SOURCE_ARCHIVE_MODAL)}
            onSubmit={archiveTrafficSource}
            closeModal={() => closeModal(TRAFFIC_SOURCE_ARCHIVE_MODAL)}
            contextModal={
              getContextModal(TRAFFIC_SOURCE_ARCHIVE_MODAL) as FormContextModal
            }
            trafficSources={trafficSources}
            getTrafficSourceById={getTrafficSourceById}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(TrafficSourceModalSelector, {
  archiveTrafficSource,
  getTrafficSourceById
})(ConnectedTrafficSourcesArchiveModal);

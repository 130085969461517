import { apiAction } from './api';
import { APP } from '../../api/endpoints';
import { APP as APPLICATION } from '../types';
import { defined } from '../../utils/define';
import { Dispatch } from 'redux';

const appVersion = (payload = {}) => {
  return {
    type: APPLICATION.FETCH_VERSION,
    payload
  };
};

const setUnreadChatCount = (payload: number) => {
  return {
    type: APPLICATION.UPDATE_UNREAD_CHAT_COUNT,
    payload
  };
};

export const fetchAppVersion = () => {
  return apiAction({
    requestConfig: APP.FETCH_VERSION(),
    onSuccess: (data: any) =>
      appVersion(defined(data.version) ? data.version : null),
    onFailure: (e: Error) => {
      throw e;
    }
  });
};

export const updateUnreadChatCount = (unreadCount: number) => (dispatch: Dispatch) => {
  return dispatch(setUnreadChatCount(unreadCount));
};
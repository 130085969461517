import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="calendar-schedule--calendar-date-day-month-time-clock-schedule">
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M2.9860714285714285 3.9814285714285713c-0.5279772428571429 0 -1.0343154214285712 0.20974165714285714 -1.4076480064285715 0.5830603071428571C1.205092827857143 4.9378274357142855 0.9953571428571428 5.444165614285715 0.9953571428571428 5.972142857142857v18.911785714285713c0 0.5279374285714286 0.209735685 1.0343751428571428 0.5830662792857143 1.4076340714285713 0.373332585 0.37325892857142856 0.8796707635714285 0.5830802142857142 1.4076480064285715 0.5830802142857142h21.89785714285714c0.5279374285714286 0 1.0343751428571428 -0.20982128571428568 1.4076340714285713 -0.5830802142857142s0.5830802142857142 -0.8796966428571429 0.5830802142857142 -1.4076340714285713V5.972142857142857c0 -0.5279772428571429 -0.20982128571428568 -1.0343154214285712 -0.5830802142857142 -1.4076539785714286C25.918303714285713 4.191170228571429 25.411866 3.9814285714285713 24.88392857142857 3.9814285714285713h-21.89785714285714Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.9860714285714285 3.9814285714285713c-0.5279772428571429 0 -1.0343154214285712 0.20974165714285714 -1.4076480064285715 0.5830603071428571C1.205092827857143 4.9378274357142855 0.9953571428571428 5.444165614285715 0.9953571428571428 5.972142857142857v18.911785714285713c0 0.5279374285714286 0.209735685 1.0343751428571428 0.5830662792857143 1.4076340714285713 0.373332585 0.37325892857142856 0.8796707635714285 0.5830802142857142 1.4076480064285715 0.5830802142857142h21.89785714285714c0.5279374285714286 0 1.0343751428571428 -0.20982128571428568 1.4076340714285713 -0.5830802142857142s0.5830802142857142 -0.8796966428571429 0.5830802142857142 -1.4076340714285713V5.972142857142857c0 -0.5279772428571429 -0.20982128571428568 -1.0343154214285712 -0.5830802142857142 -1.4076539785714286C25.918303714285713 4.191170228571429 25.411866 3.9814285714285713 24.88392857142857 3.9814285714285713h-3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.967499999999999 0.9953571428571428v5.972142857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.9025 0.9953571428571428v5.972142857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.967499999999999 3.9814285714285713h9.95357142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        fill="#ffffff"
        d="M13.934999999999999 21.89785714285714c3.298314964285714 0 5.972142857142857 -2.673827892857143 5.972142857142857 -5.972142857142857S17.233314964285714 9.95357142857143 13.934999999999999 9.95357142857143 7.962857142857143 12.62739932142857 7.962857142857143 15.925714285714285s2.673827892857143 5.972142857142857 5.972142857142857 5.972142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 21.89785714285714c3.298314964285714 0 5.972142857142857 -2.673827892857143 5.972142857142857 -5.972142857142857S17.233314964285714 9.95357142857143 13.934999999999999 9.95357142857143 7.962857142857143 12.62739932142857 7.962857142857143 15.925714285714285s2.673827892857143 5.972142857142857 5.972142857142857 5.972142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_8"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 13.934999999999999v2.4883928571428573l1.9907142857142857 0.4976785714285714"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

import { USER_ACTIONS } from '../types';
import { AnyAction } from 'redux';

export default function auth(state = {}, action: AnyAction) {
  switch (action.type) {
    case USER_ACTIONS.LOGGED_IN:
      return {
        ...state,
        ...action.user,
        loggedIn: true
      };
    case USER_ACTIONS.LOGGED_OUT:
      return state;
    default:
      return state;
  }
}

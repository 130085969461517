import React from 'react';
import FluxModal from 'components/Modal';
import AddEditApiKeyForm from 'components/Forms/AddEditApiKey';
import { ModalsConsumer } from '../context';
import { ADD_EDIT_API_KEY_MODAL } from 'constants/modal';
import { connect } from 'react-redux';
import { ModalProps } from '../types';
import { FormContextModal } from 'types/modal';
import { AddEditApiKeyModalProps } from 'types/ModalForms/addEditApiKey';
import {
  addIntegrationApiKey,
  updateIntegrationApiKey
} from 'redux/actions/integrations';
import { defined } from 'utils/define';
import { AnyObject } from 'types';

const getTitle = (contextModal: FormContextModal) => {
  if (contextModal?.data?.type === 'clickbank') {
    return 'Add an Account';
  }
  return defined(contextModal?.data?.data) ? 'Edit API Key' : 'Add API Key';
};

const AddApiKeyModal = ({
  isOpen,
  closeSelf,
  contextModal,
  handleCreate,
  handleUpdate
}: AddEditApiKeyModalProps) => (
  <FluxModal
    width={342}
    destroyOnClose
    title={getTitle(contextModal)}
    isOpen={isOpen}
    classNames={['add-edit-api-key']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    zIndex={contextModal.zIndex}
    render={() => (
      <AddEditApiKeyForm
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        contextModal={contextModal}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
      />
    )}
  />
);

const ConnectedAddApiKeyModal = ({
  addIntegrationApiKey,
  updateIntegrationApiKey
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => {
        return (
          <AddApiKeyModal
            isOpen={isOpenModal(ADD_EDIT_API_KEY_MODAL)}
            closeSelf={() => closeModal(ADD_EDIT_API_KEY_MODAL)}
            contextModal={
              getContextModal(ADD_EDIT_API_KEY_MODAL) as FormContextModal
            }
            handleCreate={addIntegrationApiKey}
            handleUpdate={updateIntegrationApiKey}
          />
        );
      }}
    </ModalsConsumer>
  );
};

export default connect(null, {
  addIntegrationApiKey,
  updateIntegrationApiKey
})(ConnectedAddApiKeyModal);

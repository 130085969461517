import Api from 'api/axios';
import { API_CALL } from '../types';
import {
  apiError,
  apiStarted,
  apiEnded,
  accessNotAuthorized
} from '../actions/api';
import { logout, userLoggedIn } from '../actions/auth';
import Auth from '../../api/auth';
import { isMockedUser } from '../selectors/user';
import { defined } from 'utils/define';

const apiMiddleware = ({ dispatch, getState }) => next => async action => {
  if (!action || !action.type) {
    return;
  }

  if (action.type !== API_CALL) {
    return next(action);
  }

  const { onSuccess, onFailure, label, requestConfig } = action.payload;

  if (label) {
    dispatch(apiStarted(label));
  }

  const state = getState();
  if (Auth.isExpired && !isMockedUser(state.user)) {
    const state = getState();
    await Auth.validate({
      idToken: state?.user?.tokens?.idToken || '',
      refreshToken: state?.user?.tokens?.refreshToken || '',
      accessToken: state?.user?.tokens?.accessToken || ''
    })
      .then(async user => {
        await dispatch(userLoggedIn(user));
      })
      .catch(() => {
        dispatch(logout());
      });
  }

  return Api.request(requestConfig)
    .then(data => dispatch(onSuccess(data)))
    .catch(err => {
      if (
        defined(err.response) &&
        (err.response.status === 401 ||
          err.response.status === 403 ||
          (defined(err.response?.data?.error) &&
            [
              'token audience is invalid',
              'token is expired',
              'unauthorized'
            ].includes(err.response.data.error)))
      ) {
        dispatch(accessNotAuthorized());
        return;
      }
      dispatch(apiError(err));
      dispatch(onFailure(err));
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnded(label));
      }
    });
};

export default apiMiddleware;

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import ConfirmForm from 'components/Forms/Confirm';
import { ModalsConsumer } from '../context';
import { PAGE_GROUPS_ARCHIVE_MODAL } from 'constants/modal';
import { ModalProps } from '../types';
import { FormContextModal } from '../../../types/modal';
import { getNameModal } from '../../../utils/modals';
import { PageGroupArchiveModalSelector } from 'redux/selectors';
import {
  archivePageGroup,
  getPageGroupById
} from '../../../redux/actions/pageGroups';
import { pageGroupArchiveModalProps } from '../../../types/ModalForms/pageGroupArchive';
import { AnyObject } from 'types';

const ArchiveText = () => (
  <div className="margin-bottom-15 color-465565">
    <p>
      Archiving a page group is reversible and will not break funnels. The page
      group will also still be present in stats and reports, but you will not be
      able to add it as a new node in funnels.
    </p>
    <p>
      You can view all archived page groups by switching to the show archived
      view on this page.
    </p>
    <p>
      Use this function to soft-remove page groups that you no longer plan to
      use in new funnels.
    </p>
    <strong>Are you sure you want to archive this page group?</strong>
  </div>
);

const PageGroupArchiveModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  contextModal,
  pageGroups,
  getPageGroupById
}: pageGroupArchiveModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    destroyOnClose
    width={422}
    title={`Archive Page Group ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['page-group-archive']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ConfirmForm
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        prepareData={(context: FormContextModal) => {
          return {
            ...pageGroups[context.entityId!],
            status: 'archived'
          };
        }}
        contextModal={contextModal}
        text={<ArchiveText />}
        successMessage={(context: FormContextModal) =>
          `\`${context.data.value}\` has archived`
        }
        fetchEntity={() => getPageGroupById(contextModal.entityId!)}
      />
    )}
  />
);

const ConnectedPageGroupArchiveModal = ({
  archivePageGroup,
  pageGroups,
  getPageGroupById
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <PageGroupArchiveModal
            isOpen={isOpenModal(PAGE_GROUPS_ARCHIVE_MODAL)}
            onSubmit={archivePageGroup}
            closeSelf={() => closeModal(PAGE_GROUPS_ARCHIVE_MODAL)}
            contextModal={
              getContextModal(PAGE_GROUPS_ARCHIVE_MODAL) as FormContextModal
            }
            pageGroups={pageGroups}
            getPageGroupById={getPageGroupById}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(PageGroupArchiveModalSelector, {
  archivePageGroup,
  getPageGroupById
})(ConnectedPageGroupArchiveModal);

import React from 'react';
import FluxModal from 'components/Modal';
import DeleteApiKeyForm from 'components/Forms/DeleteApiKey';
import { ModalsConsumer } from '../context';
import { DELETE_API_KEY_MODAL } from 'constants/modal';
import { connect } from 'react-redux';
import { ModalProps } from '../types';
import { FormContextModal } from 'types/modal';
import { DeleteApiKeyModalProps } from 'types/ModalForms/deleteApiKey';

const AddApiKeyModal = ({
  isOpen,
  closeSelf,
  contextModal
}: DeleteApiKeyModalProps) => (
  <FluxModal
    width={342}
    destroyOnClose
    title={'Delete API Key'}
    isOpen={isOpen}
    classNames={['delete-api-key']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    zIndex={contextModal.zIndex}
    render={() => (
      <DeleteApiKeyForm
        contextModal={contextModal}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
      />
    )}
  />
);

export default connect(null, null)(
  () => (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => {
        return (
          <AddApiKeyModal
            isOpen={isOpenModal(DELETE_API_KEY_MODAL)}
            closeSelf={() => closeModal(DELETE_API_KEY_MODAL)}
            contextModal={
              getContextModal(DELETE_API_KEY_MODAL) as FormContextModal
            }
          />
        );
      }}
    </ModalsConsumer>
  )
);

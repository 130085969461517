import { LANDERS, PAGE_GROUPS, OFFERS, VIEW } from '../types';
import { PageGroup } from '../../model/pageGroup';
import { Dispatch } from 'redux';
import { CompositeStore } from '../../types/redux/store';
import { apiAction } from './api';
import { PAGE_GROUPS as API } from '../../api/endpoints';
import { DeleteList } from '../../model/deleteList';
import { StatusEnum } from '../../api/types';
import { DrilldownReportParams } from '../../model/drilldownReportParams';
import { DrilldownReport } from '../../model/drilldownReport';
import { pageGroupsReportingTypes } from '../../types/reporting';
import { setTableCols } from './table';
import { FindByNameSchema } from '../../model/findByName';
import { CustomError } from 'types';
import { defined } from 'utils/define';
import { PageGroupInfo } from 'model/pageGroupInfo';
import { GetPageGroupById } from 'types/actions';
import { setLandersInfo } from './landers';
import { setOffersInfo } from './offers';

const setPageGroupsList = (payload: PageGroup[] = []) => {
  return {
    type: PAGE_GROUPS.SET_LIST,
    payload
  };
};

const setPageGroupsInfo = (
  payload: PageGroupInfo[] = [],
  pageType: PageGroup.PageTypeEnum
) => {
  return {
    type:
      pageType === 'lander'
        ? PAGE_GROUPS.SET_LANDER_INFO
        : PAGE_GROUPS.SET_OFFER_INFO,
    payload
  };
};

const setPageGroup = (payload: PageGroup) => {
  return {
    type: PAGE_GROUPS.ADD_SINGLE,
    payload
  };
};

const setExistedPageGroup = (payload: PageGroup) => {
  return {
    type: PAGE_GROUPS.ADD_EXISTED_SINGLE,
    payload
  };
};

const pageGroupUpdate = (payload: PageGroup) => {
  return {
    type: PAGE_GROUPS.UPDATE_SINGLE,
    payload
  };
};

const pageGroupDelete = (data: string[]) => {
  return {
    type: PAGE_GROUPS.DELETE,
    payload: data
  };
};

const setShow = (payload: StatusEnum) => {
  return {
    type: PAGE_GROUPS.SET_SHOW,
    payload
  };
};

const pageGroupArchive = (data: PageGroup) => {
  return {
    type: PAGE_GROUPS.ARCHIVE,
    payload: { data }
  };
};

export const setPageGroupsTableCols = (cols: []) => (dispatch: Dispatch) => {
  return dispatch(setTableCols(cols, VIEW.PAGEGROUPS));
};

const setReportingsData = (
  data = {},
  params: DrilldownReportParams,
  pageType: pageGroupsReportingTypes
) => {
  return {
    type: PAGE_GROUPS.SET_REPORTING,
    payload: { data, params, pageType, type: PAGE_GROUPS.SET_REPORTING }
  };
};

export const fetchPageGroups = (status: StatusEnum = 'not-deleted') => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(status),
      onSuccess: (data: PageGroup[]) => dispatch(setPageGroupsList(data)),
      onFailure: (e: Error) => {
        throw e;
      },
      label: PAGE_GROUPS.FETCH_LIST
    })
  );
};

export const fetchPageGroupsInfo = (
  status: StatusEnum = 'all',
  pageType: PageGroup.PageTypeEnum,
  includeChilds = true,
  idFunnel?: string,
) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_INFO(status, pageType, includeChilds, idFunnel),
      onSuccess: (data: PageGroupInfo[]) => {
        dispatch(
          setPageGroupsInfo(
            data.map(d => ({
              ...d,
              pageType
            })),
            pageType
          )
        );
        if (pageType === 'lander') {
          dispatch(setLandersInfo(data.map(pg => pg.pages || []).flat()));
        } else {
          dispatch(setOffersInfo(data.map(pg => pg.pages || []).flat()));
        }
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: PAGE_GROUPS.FETCH_INFO
    })
  );
};

export const fetchReportings = (
  params: DrilldownReportParams,
  pageType: pageGroupsReportingTypes
) => (dispatch: Dispatch, getState: () => CompositeStore) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_REPORTING(params),
      onSuccess: (data: DrilldownReport) =>
        setReportingsData(data, params, pageType),
      onFailure: (e: Error) => {
        throw e;
      },
      label:
        pageType === 'landers'
          ? LANDERS.FETCH_REPORTING
          : OFFERS.FETCH_REPORTING
    })
  );
};

export const setPageGroupsShow = (show: StatusEnum) => (dispatch: Dispatch) => {
  return dispatch(setShow(show));
};

export const updatePageGroup = (data: PageGroup, saveLocally = false) => (
  dispatch: Dispatch
) => {
  if (saveLocally) {
    return dispatch(pageGroupUpdate(data));
  }
  return dispatch(
    apiAction({
      requestConfig: API.UPDATE(data),
      onSuccess: () => pageGroupUpdate(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: PAGE_GROUPS.UPDATE_SINGLE
    })
  );
};

export const getPageGroupById: GetPageGroupById = (idPageGroup: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.GET(idPageGroup),
      onSuccess: (pageGroup: PageGroup) => setExistedPageGroup(pageGroup),
      onFailure: (e: Error) => {
        throw e;
      },
      label: PAGE_GROUPS.ADD_EXISTED_SINGLE
    })
  );
};

export const findGroupByName = (
  data: FindByNameSchema,
  customError?: CustomError
) => {
  return apiAction({
    requestConfig: API.FIND_BY_NAME(data),
    onSuccess: (pageGroup: PageGroup) => {
      if (defined(pageGroup?.idPageGroup) && pageGroup.status === 'active') {
        throw customError;
      }
    },
    onFailure: (e: Error) => {
      throw e;
    },
    label: PAGE_GROUPS.FIND_BY_NAME
  });
};

// don't ever change saveLocally to true
export const addPageGroup = (data: PageGroup, saveLocally = false) => async (
  dispatch: Dispatch
) => {
  if (saveLocally) {
    await dispatch(setPageGroup(data));
  } else {
    return dispatch(
      apiAction({
        requestConfig: API.CREATE(data),
        onSuccess: () => setPageGroup(data),
        onFailure: (e: Error) => {
          throw e;
        },
        label: PAGE_GROUPS.ADD_SINGLE
      })
    );
  }
};

export const duplicatePageGroup = (oldData: PageGroup) => {
  return apiAction({
    requestConfig: API.DUPLICATE(oldData),
    onSuccess: (data: PageGroup) => setPageGroup(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: PAGE_GROUPS.ADD_SINGLE
  });
};

export const archivePageGroup = (data: PageGroup) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.ARCHIVE(data),
      onSuccess: () => pageGroupArchive(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: PAGE_GROUPS.ARCHIVE
    })
  );
};

export const deletePageGroup = (data: DeleteList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => pageGroupDelete(data.entries),
      onFailure: (e: Error) => {
        throw e;
      },
      label: PAGE_GROUPS.DELETE
    })
  );
};

import { createSelector } from 'reselect';
import { DrilldownReport } from '../../model/drilldownReport';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { AnyObject, ReportCategoryObject } from '../../types';
import { getCategories, getCategory } from '../../utils/selectors';
import { LanderStore } from '../../types/redux/store';
import { defined } from 'utils/define';
import { StatusEnum } from 'api/types';
import { arrayToObject } from '../../utils/arrs';

export const getReporting = (state: LanderStore) => state.reporting;
export const getShow = (state: LanderStore) => state.settings.show;
export const getAllCategories = (state: LanderStore) => state.categories;
export const isQuickStatsOrSummary = (
  _: LanderStore,
  isQuickStatsOrSummary?: boolean
) => !!isQuickStatsOrSummary;

export const getLandersReporting: any = createSelector(
  [getReporting, getShow, isQuickStatsOrSummary],
  (
    reporting: DrilldownReport,
    show: StatusEnum,
    isQuickStatsOrSummary
  ): DrilldownReportRow[] => {
    const categories: ReportCategoryObject = getCategories(reporting);
    const allCategoriesByKeys = arrayToObject(
      reporting.entities?.categories || [],
      'idCategory'
    );
    let existedCategories: AnyObject = {};
    const isAll = show === 'not-deleted';
    const status = show;
    if (!defined(reporting.rows)) {
      return [];
    }
    return reporting.rows
      .filter(row =>
        isQuickStatsOrSummary
          ? true
          : isAll
          ? row.attributes[0].status !== 'deleted'
          : row.attributes[0].status === status
      )
      .map(row => {
        const entity = row.attributes[0];
        const category = getCategory(entity, categories, allCategoriesByKeys);

        existedCategories[category.id] = true;

        return {
          ...row,
          attributes: [category, ...row.attributes]
        };
      });
    /*.concat(
        emptyCategoryRows(
          allCategories,
          existedCategories,
          isAll,
          status,
          reporting
        )
      )*/
  }
);

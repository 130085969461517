import React from 'react';
import { Menu } from '../../types/menu';
import { SubscriptionStatus } from 'model/subscription';
import { isStandByOrExpired } from 'utils/app';

export const sideMenu: Menu[] = [
  {
    view: 'dashboard',
    name: 'Dashboard',
    icon: 'flux-navDashboard',
    items: [
      {
        title: 'Dashboard',
        path: `/summary`,
        view: 'Summary'
      }
    ]
  },
  {
    view: 'funnels',
    name: 'Funnels',
    icon: 'flux-navFunnels',
    isFunnels: true,
    items: [
      {
        title: 'Funnels and Flows',
        path: `/funnels`,
        view: 'Funnels'
      },
      {
        title: 'Funnel Editor',
        path: `/funneleditor`,
        view: 'FunnelEditor',
        disableOnSubscriptionEnds: true,
        isFunnelEditor: true,
        items: [
          {
            title: 'Funnel Editor',
            path: '/funneleditor/:id',
            view: 'FunnelEditor',
            disableOnSubscriptionEnds: true
          }
        ]
      }
    ]
  },
  {
    view: 'sourcesandnodes',
    name: 'Assets and Resources',
    icon: 'flux-navAssets',
    items: [
      {
        title: 'Traffic Sources',
        path: '/trafficsources',
        view: 'TrafficSources'
      },
      {
        title: 'Offer Sources',
        path: '/offersources',
        view: 'OfferSources'
      },
      {
        title: 'Offers',
        path: '/offers',
        view: 'Offers'
      },
      {
        title: 'Landers',
        path: '/landers',
        view: 'Landers'
      },
      {
        title: 'Page Groups',
        path: '/pagegroups',
        view: 'PageGroups'
      },
      {
        title: 'Conditions',
        path: '/conditions',
        view: 'Conditions'
      }
    ]
  },
  {
    view: 'analytics',
    name: 'Reporting',
    icon: 'flux-navReporting',
    items: [
      {
        title: 'Reporting',
        path: '/reporting',
        view: 'Reporting',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Campaign Analysis',
        path: '/campaignanalysis',
        view: 'CampaignAnalysis',
				disableOnSubscriptionEnds: true
      },
      {
        title: 'Raw Events',
        path: '/raw/hits',
        view: 'Hits',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Google Ads Reporting',
        path: '/integrated-reporting/google-ads',
        view: 'GoogleAdsReporting',
        disableOnSubscriptionEnds: true,
      },
      {
        title: 'Facebook Ads Reporting',
        path: '/integrated-reporting/facebook-ads',
        view: 'FacebookAdsReporting',
        disableOnSubscriptionEnds: true,
      },
    ]
  },
  {
    view: 'updates',
    name: 'Data Updates',
    icon: 'flux-navUploads',
    disableOnSubscriptionEnds: true,
    items: [
      {
        title: 'Update Traffic Costs',
        path: '/updatetrafficcosts',
        view: 'UpdateTrafficCosts',
				disableOnSubscriptionEnds: true
      },
      {
        title: 'Update Conversions',
        path: '/updateconversions',
        view: 'UpdateConversions',
				disableOnSubscriptionEnds: true
      },
      {
        title: 'Reset Data',
        path: '/resetdata',
        view: 'ResetData',
				disableOnSubscriptionEnds: true
      }
    ]
  },
  {
    view: 'labs',
    name: 'Labs',
    icon: 'flux-navLabs',
    items: [
      {
        title: 'FunnelFlux Labs',
        path: '/labs',
        view: 'Labs',
        disableOnSubscriptionEnds: true,
      },
      {
        title: 'Traffic Filtering',
        path: '/traffic-filtering',
        view: 'TrafficFiltering',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Keyword Rotation Links',
        path: '/keyword-rotation-links',
        view: 'KeywordRotationLinks',
        disableOnSubscriptionEnds: true
      },
    ]
  },
  {
    view: 'settings',
    name: 'Settings',
    icon: 'flux-navSettings',
    items: [
      {
        title: 'Domains',
        path: '/domains',
        view: 'Domains',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Tracking Codes',
        path: '/trackingcodes',
        view: 'TrackingCodes',
        disableOnSubscriptionEnds: true
      },
      // {
      //   title: 'Custom Event Mapping',
      //   path: '/custom-event-mapping',
      //   view: 'CustomEventMapping',
      //   disableOnSubscriptionEnds: true
      // },
      {
        title: 'TrafficSources Integrations',
        path: '/integrations/trafficsources',
        view: 'TrafficSourceIntegrations',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'OfferSources Integrations',
        path: '/integrations/offersources',
        view: 'OfferSourceIntegrations',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Integrated UI Connections',
        path: '/integrations/integrated-uis',
        view: 'IntegratedUIConnections',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Google Ads Integrated Reporting',
        path: '/integrations/integrated-uis/google-ads',
        view: 'IntegratedGoogleAdsUI',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Facebook Ads Integrated Reporting',
        path: '/integrations/integrated-uis/facebook-ads',
        view: 'IntegratedFacebookAdsUI',
        disableOnSubscriptionEnds: true
      },      
      {
        title: 'Other Integrations',
        path: '/integrations/others',
        view: 'OtherIntegrations',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Facebook Integration',
        path: '/integrations/trafficsource/facebook',
        view: 'FacebookIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'TikTok Integration',
        path: '/integrations/trafficsource/tiktok',
        view: 'TikTokIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Reddit Integration',
        path: '/integrations/trafficsource/reddit',
        view: 'RedditIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Twitter Integration',
        path: '/integrations/trafficsource/twitter',
        view: 'TwitterIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Optimizer Integration',
        path: '/integrations/others/optimizer',
        view: 'OptimizerIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Clickbank Integration',
        path: '/integrations/offersources/clickbank',
        view: 'ClickbankIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Explodely Integration',
        path: '/integrations/offersources/explodely',
        view: 'ExplodelyIntegration',
        disableOnSubscriptionEnds: true
      },      
      {
        title: 'Microsoft Ads Integration',
        path: '/integrations/trafficsource/microsoft-ads',
        view: 'MicrosoftAdsIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Google Ads Integration',
        path: '/integrations/trafficsource/google-ads',
        view: 'GoogleAdsIntegration',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'Subscription & Billing',
        path: '/subscriptionbilling',
        view: 'SubscriptionBilling'
      },
      {
        title: 'System Settings',
        path: '/systemsettings',
        view: 'SystemSettings',
        disableOnSubscriptionEnds: true
      },
      {
        title: 'API Access',
        path: '/api-access',
        view: 'ApiAccess',
        disableOnSubscriptionEnds: true
      },
      // !isProdEnvironment
      //   ? {
      //       title: 'Migration Tools',
      //       path: '/migrationtools',
      //       view: 'MigrationTools',
      //       disableOnSubscriptionEnds: true
      //     }
      //   : { title: '', path: '', view: '' }
    ]
  }
];

export const preventNavItemClick = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  disableOnSubscriptionEnds: boolean,
  subscriptionStatus: SubscriptionStatus
) => {
  if (isStandByOrExpired(subscriptionStatus) && disableOnSubscriptionEnds) {
    e.preventDefault();
  }
};

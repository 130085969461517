import { SYSTEM_SETTINGS } from '../types';
import { Dispatch } from 'redux';
import { apiAction } from './api';
import { SYSTEM_SETTINGS as API } from '../../api/endpoints';
import { UserSettings } from '../../model/userSettings';

const setSystemSettings = (payload = {}) => {
  return {
    type: SYSTEM_SETTINGS.FETCH,
    payload
  };
};

const setEditModeTooltips = (payload: boolean) => {
  return {
    type: SYSTEM_SETTINGS.TOGGLE_SHOW_EDIT_MODE_TOOLTIPS,
    payload
  };
};

export const fetchSystemSettings = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH(),
      onSuccess: (data: UserSettings) => setSystemSettings(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: SYSTEM_SETTINGS.FETCH
    })
  );
};

export const putSystemSettings = (data: UserSettings) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.PUT(data),
      onSuccess: () => setSystemSettings(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: SYSTEM_SETTINGS.UPDATE
    })
  );
};

export const toggleShowTooltips = (data: boolean) => (dispatch: Dispatch) => {
  dispatch(setEditModeTooltips(data));
};
import { resetData as defaultState } from './defaultStates';
import { API, RESET_DATA } from '../types';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import { ResetDataStore } from '../../types/redux/store';
import { getFieldName } from '../../utils/selectors';
import { DrilldownReport } from '../../model/drilldownReport';

const value = (state = defaultState.value, action: AnyAction) => {
  switch (action.type) {
    case RESET_DATA.SET_VALUE:
      if (defined(action.payload)) {
        state = action.payload;
      }
      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loadings, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        [getFieldName(action, RESET_DATA.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: true
        }
      };
    case API.ENDED:
      return {
        ...state,
        [getFieldName(action, RESET_DATA.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: false,
          fetchedAt: Date.now()
        }
      };
    default:
      return state;
  }
};

const reporting = (state = defaultState.reporting, action: AnyAction) => {
  switch (action.type) {
    case RESET_DATA.SET_REPORTING:
      if (defined(action.payload)) {
        return action.payload as DrilldownReport;
      }
      return state;
    default:
      return state;
  }
};

const settings = (state = defaultState.settings, action: AnyAction): any => {
  switch (action.type) {
    case RESET_DATA.SET_SETTINGS:
      if (defined(action.payload)) {
        return {
          ...state,
          [action.payload.key]: action.payload.value
        };
      }
      return state;
    default:
      return state;
  }
};

export default (state = defaultState, action: AnyAction): ResetDataStore => ({
  value: value(state.value, action),
  loadings: loading(state.loadings, action),
  reporting: reporting(state.reporting, action),
  settings: settings(state.settings, action)
});

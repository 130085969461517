import { SelectOption } from 'types';
import { CostSegment } from '../model/costSegment';

export const deviceTypes: CostSegment.RestrictToDeviceTypeEnum[] = [
  "Console",
  "Desktop",
  "EReader",
  "MediaHub",
  "SmallScreen",
  "SmartPhone",
  "SmartWatch",
  "TV",
  "Tablet",
  "Unknown"
];

export const deviceTypesById: SelectOption<
  CostSegment.RestrictToDeviceTypeEnum
>[] = [
  {
    value: '0',
    label: 'Unknown'
  },
  {
    value: '1',
    label: 'Console'
  },
  {
    value: '2',
    label: 'Desktop'
  },
  {
    value: '3',
    label: 'EReader'
  },
  {
    value: '4',
    label: 'MediaHub'
  },
  {
    value: '6',
    label: 'SmallScreen'
  },
  {
    value: '7',
    label: 'SmartPhone'
  },
  {
    value: '8',
    label: 'SmartWatch'
  },
  {
    value: '9',
    label: 'Tablet'
  },
  {
    value: '10',
    label: 'TV'
  }
];

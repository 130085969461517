import CloudflareResponse from 'model/domains/cloudflareResponse';
import { CloudflaredDomain, DomainEntry } from 'model/models';
import { DNSRecordParams, DNSFields } from '../../types/domains';

export const getFieldValueInformation = (
  fieldName: string,
  value: any,
  model: DNSRecordParams,
  dnsAddRecordFields: DNSFields
) => {
  if (fieldName === '[algorithm]') {
    switch (true) {
      case value === 0:
        return 'delete DS';
      case value === 1:
        return 'RSA/MD5 (deprecated)';
      case value === 2:
        return 'Diffie-Hellman';
      case value === 3:
        return 'DSA/SHA1';
      case value === 4:
        return 'a reserved algorithm';
      case value === 5:
        return 'RSA/SHA-1';
      case value === 6:
        return 'DSA-NSEC3-SHA1';
      case value === 7:
        return 'RSASHA1-NSEC3-SHA1';
      case value === 8:
        return 'RSA/SHA-256';
      case value === 9:
        return 'a reserved algorithm';
      case value === 10:
        return 'RSA/SHA-512';
      case value === 11:
        return 'a reserved algorithm';
      case value === 12:
        return 'GOST R 34.10-2001';
      case value === 13:
        return 'ECDSA Curve P-256 with SHA-256';
      case value === 14:
        return 'ECDSA Curve P-384 with SHA-384';
      case value === 15:
        return 'Ed25519';
      case value === 16:
        return 'Ed448';
      case value >= 17 && value <= 122:
        return 'algorithm yet to be assigned by IANA';
      case value >= 123 && value <= 251:
        return 'a reserved algorithm';
      case value === 252:
        return 'reserved for indirect keys';
      case value === 253:
        return 'private algorithm private DNS';
      case value === 254:
        return 'private algorithm OID';
      case value === '255':
        return 'a reserved algorithm';
      default:
        return fieldName;
    }
  }

  if (fieldName === '[cert. type]') {
    switch (true) {
      case value < 0:
        return `dns.cert_type.${value}`;
      case value === 0:
        return 'reserved';
      case value === 1:
        return 'PKIX (X.509 as per PKIX)';
      case value === 2:
        return 'SPKI (SPKI certificate)';
      case value === 3:
        return 'PGP (OpenPGP packet)';
      case value === 4:
        return 'IPKIX (X.509 data object URL)';
      case value === 5:
        return 'ISPKI (SPKI certificate URL)';
      case value === 6:
        return 'IPGP (fingerprint and URL of an OpenPGP packet)';
      case value === 7:
        return 'ACPKIX (Attribute Certificate)';
      case value === 8:
        return 'IACPKIX (Attribute Certificate URL)';
      case value >= 9 && value <= 65279:
        return 'to be assigned by IANA';
      default:
        return fieldName;
    }
  }

  if (fieldName === '[digest type]') {
    switch (true) {
      case value === 0:
        return 'a reserved algorithm';
      case value === 1:
        return 'SHA-1';
      case value >= 2 && value < 255:
        return 'fingerprint type yet to be assigned by IANA';
      default:
        return fieldName;
    }
  }

  if (fieldName === '[selector]') {
    switch (true) {
      case value === 0:
        return `a full ${model.type === 'TLSA' ? 'TLS' : 'S/MIME'} certificate`;
      case value === 1:
        return `the SubjectPublicKeyInfo of a ${
          model.type === 'TLSA' ? 'TLS' : 'S/MIME'
        } certificate`;
      case value >= 2 && value <= 255:
        return 'selector type yet to be assigned by IANA';
      default:
        return fieldName;
    }
  }

  if (fieldName === '[matching type]') {
    switch (true) {
      case value === 0:
        return 'CA constraint';
      case value === 1:
        return 'service certificate constraint';
      case value === 2:
        return 'trust anchor assertion';
      case value >= 3 && value <= 255:
        return 'usage yet to be assigned by IANA';
      default:
        return fieldName;
    }
  }

  if (fieldName === '[usage]') {
    switch (true) {
      case value === 0:
        return 'CA constraint';
      case value === 1:
        return 'service certificate constraint';
      case value === 2:
        return 'trust anchor assertion';
      case value === 3:
        return 'domain-issued certificate';
      case value === 4:
        return fieldName;
      case value >= 3 && value <= 255:
        return 'usage yet to be assigned by IANA';
      default:
        return fieldName;
    }
  }

  if (fieldName === '[service]') {
    return `${value}.${model.data!.proto}.`;
  }

  if (fieldName === '[name]') {
    return `${value}.${model.zone_name}`;
  }

  if (fieldName === '[Tag]') {
    return dnsAddRecordFields.Tag.options!.find(item => item.value === value)!
      .label;
  }

  if (fieldName === '[fingerprint type]') {
    switch (true) {
      case value === 0:
        return 'a reserved algorithm';
      case value === 1:
        return 'SHA-1';
      case value >= 2 && value <= 255:
        return 'fingerprint type yet to be assigned by IANA';
      default:
        return fieldName;
    }
  }

  return value;
};

export const getTTLNameByValue = (ttlValue: number) => {
  switch (ttlValue) {
    case 1:
      return 'Auto';
    case 120:
      return '2 min';
    case 300:
      return '5 min';
    case 600:
      return '10 min';
    case 900:
      return '15 min';
    case 1800:
      return '30 min';
    case 3600:
      return '1 hr';
    case 7200:
      return '2 hr';
    case 90000:
      return '5 hr';
    case 43200:
      return '12 hr';
    case 86400:
      return '1 day';
    default:
      return '';
  }
};

export const getCloudflaredDomain = (
  domainEntry: DomainEntry,
  cloudflareResult: CloudflareResponse.Result
): CloudflaredDomain => {
  return {
    ...domainEntry,
    status: cloudflareResult.status,
    cloudflareId: cloudflareResult.id,
  };
};

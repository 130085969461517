import React from 'react';
import { LoadingType, Loading, LoadingProps } from 'types/loading';
import './style.scss';
import { initialLoading } from 'constants/loading';
import { conditionalClass } from 'conditional-class';

interface State {
  loading: Loading;
}

export function withLoading<P>(Component: React.ComponentType<P>) {
  return class extends React.Component<Omit<P, keyof LoadingProps>, State> {
    state: State = {
      loading: initialLoading
    };

    startLoading = (name: LoadingType) => {
      this.setState((state: State) => ({
        ...state,
        loading: {
          ...state.loading,
          [name]: true
        }
      }));
    };

    stopLoading = (name: LoadingType) => {
      this.setState((state: State) => ({
        ...state,
        loading:
          name === 'all'
            ? initialLoading
            : {
                ...state.loading,
                [name]: false
              }
      }));
    };

    render() {
      return (
        //@ts-ignore
        <Component
          loading={this.state.loading}
          startLoading={this.startLoading}
          stopLoading={this.stopLoading}
          {...this.props}
        />
      );
    }
  };
}

export const LoadingTooltip = ({ show }: { show: boolean }) => (
  <div
    id="ff-loading-tooltip"
    data-testid="loading-tooltip"
    className={conditionalClass('c-loading__tooltip', {
      'c-loading__tooltip--hidden': !show
    })}
  >
    <span>Loading...</span>
  </div>
);

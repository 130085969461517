import { hits as defaultState } from './defaultStates';
import { HitsStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { API, HITS, TABLE, VIEW } from '../types';
import { defined } from '../../utils/define';
import table from './table';

const report = (state = defaultState.report, action: AnyAction) => {
  switch (action.type) {
    case HITS.SET_HITS:
      if (defined(action.payload.rows)) {
        return action.payload;
      }
      return defaultState.report;
    default:
      return state;
  }
};

const loading = (state = defaultState.loading, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        isLoading: true
      };
    case API.ENDED:
      return {
        ...state,
        isLoading: false,
        fetchedAt: Date.now()
      };
    default:
      return state;
  }
};

const settings = (state = defaultState.settings, action: AnyAction): any => {
  switch (action.type) {
    case HITS.SET_SETTINGS:
      if (defined(action.payload)) {
        return {
          ...state,
          [action.payload.key]: action.payload.value
        };
      }
      return state;
    default:
      return state;
  }
};

const tableCols = (
  state = defaultState.settings.tableCols,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.payload.view === VIEW.HITS &&
    action.type === TABLE.SET_COLS
  ) {
    return table(state, action);
  } else {
    return state;
  }
};

const additionalParams = (
  state = defaultState.settings.additionalParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_ADDITIONAL_PARAMS &&
    action.payload.view === VIEW.HITS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const tableParams = (
  state = defaultState.settings.tableParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_TABLE_PARAMS &&
    action.payload.view === VIEW.HITS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

export default (state = defaultState, action: any): HitsStore => ({
  report: report(state.report, action),
  loading: loading(state.loading, action),
  settings: {
    ...settings(state.settings, action),
    tableCols: tableCols(state.settings.tableCols, action),
    tableParams: tableParams(state.settings.tableParams, action),
    additionalParams: additionalParams(state.settings.additionalParams, action),
  }
});

// funnel group
export const FUNNELGROUP_FORM_TAB = 'funnelGroup-form';
export const FUNNELGROUP_FORM_OPTIONAL_SETTINGS_TAB =
  'funnelGroup-form-optional-settings';

// condition
export const CONDITION_FORM_TAB = 'condition-form';
export const CONDITION_FORM_GET_ENTRANCE_LINK_TAB =
  'condition-form-get-entrance-link';
export const CONDITION_FORM_HELP_TAB = 'condition-form-help';

// external url
export const EXTERNALURL_FORM_TAB = 'externalUrl-form';
export const EXTERNALURL_FORM_GET_ENTRANCE_LINK_TAB =
  'externalUrl-form-get-entrance-link';

// funnel
export const FUNNEL_FORM_TAB = 'funnel-form';
export const FUNNEL_FORM_ADVANCED_FUNNEL_SETTINGS_TAB =
  'funnel-form-advanced-funnel-settings';
export const FUNNEL_FORM_REDIRECT_LINKS_TAB = 'funnel-form-redirect-links';
export const FUNNEL_FORM_USE_JAVASCRIPT_TAB = 'funnel-form-use-javascript';
export const FUNNEL_FORM_HELP_TAB = 'funnel-form-help';

// lander
export const LANDER_FORM_TAB = 'lander-form';
export const LANDER_FORM_ADVANCED_TAB = 'lander-form-advanced';
export const LANDER_FORM_PAGE_LINKS_TAB = 'lander-form-page-links';
export const LANDER_FORM_JAVASCRIPT_TAB = 'lander-form-javascript';
export const LANDER_FORM_HELP_TAB = 'lander-form-help';

// offer
export const OFFER_FORM_TAB = 'offer-form';
export const OFFER_FORM_CONFIGURE_DATA_PASSING_TAB =
  'offer-form-configure-data-passing';
export const OFFER_FORM_PAGE_ACTION_LINKS_TAB = 'offer-form-page-action-links';
export const OFFER_FORM_JAVASCRIPT_TAB = 'offer-form-javascript';
export const OFFER_FORM_INTEGRATION_PRODUCT_IDS_TAB =
  'offer-form-integration-product-ids';
export const OFFER_FORM_CONVERSION_TRACKING_TAB =
  'offer-form-conversion-tracking';
export const OFFER_FORM_HELP_TAB = 'offer-form-help';

// offersource
export const OFFERSOURCE_FORM_TAB = 'offersource-form';
export const OFFERSOURCE_FORM_CONFIGURE_DATA_PASSING_TAB =
  'offersource-form-configure-data-passing';
export const OFFERSOURCE_FORM_CONFIGURE_TRACKING_TOKENS_TAB =
  'offersource-form-configure-tracking-tokens';
export const OFFERSOURCE_FORM_HELP_TAB = 'offersource-form-help';

// page group
export const PAGEGROUP_FORM_TAB = 'pagegroup-form';
export const PAGEGROUP_FORM_REDIRECT_LINKS_TAB =
  'pagegroup-form-redirect-links';
export const PAGEGROUP_FORM_DIRECT_LINKS_TAB = 'pagegroup-form-use-javascript';
export const PAGEGROUP_FORM_ADDITIONAL_SETTINGS_TAB =
  'pagegroup-form-additional-settings';
export const PAGEGROUP_FORM_ACTION_LINKS_TAB =
  'pagegroup-form-get-action-links';
export const PAGEGROUP_FORM_JAVASCRIPT_TRACKING_TAB =
  'pagegroup-form-javascript-tracking';

// rotator
export const ROTATOR_FORM_TAB = 'rotator-form';
export const ROTATOR_FORM_ADVANCED_TAB = 'rotator-form-advanced';

// traffic source
export const TRAFFICSOURCE_FORM_TAB = 'trafficsource-form';
export const TRAFFICSOURCE_FORM_TRACKING_FIELDS_TAB =
  'trafficsource-form-tracking-fields';
export const TRAFFICSOURCE_FORM_ADVANCED_SETTINGS_TAB = 'trafficsource-form-advancedSettings';
export const TRAFFICSOURCE_FORM_CONVERSION_TRACKING_TAB =
  'trafficsource-form-conversion-tracking';
export const TRAFFICSOURCE_FORM_HELP_TAB = 'trafficsource-form-help';

// simple flows
export const SIMPLE_FLOWS_FORM_TAB = 'simple-flows-form';
export const SIMPLE_FLOWS_FORM_CONFIGURE_PATHS_TAB =
  'simple-flows-configure-paths-tab';
export const SIMPLE_FLOWS_FORM_CONFIGURE_RULES_TAB =
  'simple-flows-configure-rules-tab';
export const SIMPLE_FLOWS_FORM_CONFIGURE_ROUTING_TAB =
  'simple-flows-configure-routing-tab';
export const SIMPLE_FLOWS_FORM_GET_REDIRECT_LINKS_TAB =
  'simple-flows-get-redirect-links-tab';
export const SIMPLE_FLOWS_FORM_PAGE_ACTION_LINKS_TAB =
  'simple-flows-page-actions-link-tab';
export const SIMPLE_FLOWS_FORM_ADVANCED_FLOW_SETTINGS_TAB =
  'simple-flows-advanced-flow-settings-tab';
export const SIMPLE_FLOWS_FORM_HELP_TAB = 'simple-flows-form-help-tab';

// reporting restrict data
export const REPORTING_RESTRICT_GENERAL_TAB = 'reporting-restrict-general-form';
export const REPORTING_RESTRICT_PAGES_TAB = 'reporting-restrict-assets-form';
export const REPORTING_RESTRICT_LOCATION_TAB = 'reporting-restrict-location-form';
export const REPORTING_RESTRICT_DEVICE_PROPERTIES_TAB = 'reporting-restrict-device-properties-form';
export const REPORTING_RESTRICT_CONNECTION_PROPERTIES_TAB = 'reporting-restrict-connection-properties-form';
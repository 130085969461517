import React from 'react';
import FluxModal from 'components/Modal';
import { SYSTEM_SETTINGS_MODAL_ADD_DOMAIN } from '../../../constants/modal';
import { ModalsConsumer } from 'components/Modals/context';
import AddDomainForm from '../../Forms/AddDomain';
import { ModalProps } from '../types';
import { DomainContextModal } from '../../../types/modal';
import { DomainEntry } from 'model/models';

const AddDomainModal = ({
  isOpen,
  closeSelf,
  onSubmit = () => {},
  domains
}: {
  isOpen: boolean;
  closeSelf: () => void;
  onSubmit?: (domainName: DomainEntry) => void;
  domains: DomainEntry[];
}) => (
  <FluxModal
    width={500}
    destroyOnClose
    title={`Add a Domain`}
    isOpen={isOpen}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <AddDomainForm
        onClose={closeSelf}
        onSubmit={onSubmit}
        domains={domains}
      />
    )}
  />
);

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }: ModalProps) => {
      const contextModal = getContextModal(
        SYSTEM_SETTINGS_MODAL_ADD_DOMAIN
      ) as DomainContextModal;

      return (
        <>
          <AddDomainModal
            isOpen={isOpenModal(SYSTEM_SETTINGS_MODAL_ADD_DOMAIN)}
            closeSelf={() => closeModal(SYSTEM_SETTINGS_MODAL_ADD_DOMAIN)}
            onSubmit={contextModal.onSubmit}
            domains={contextModal.domains}
          />
        </>
      );
    }}
  </ModalsConsumer>
);

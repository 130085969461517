export const pregMatchAll = (str: string, regex: RegExp) => {
  let m;
  let result = [];

  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    let current: string[] = [];
    // The result can be accessed through the `m`-variable.
    m.forEach(match => {
      current.push(match);
    });

    result.push(current);
  }
  return result;
};

export const decamelize = (str: string, separator = '_') => {
  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
    .toLowerCase();
};
import React from 'react';
import { FFButton, FFCol, FFField, FFRow, FFSection, FFSelect, FFTextarea, VisibilityWrapper } from 'uikit';
import { FFSelectOption } from 'uikit/types/select';
import { Postback } from 'model/postback';
import { withoutWhiteSpace } from 'utils/string';
import { CopyButton } from 'components/Parts/Buttons';
import { defined } from 'utils/define';
import { getAttributes } from 'constants/templates';
import { TrafficSource } from 'model/trafficSource';
import { customEventSettingsTemplate } from 'constants/customEvents';
import { ConversionTrackingSettings } from 'model/conversionTrackingSettings';
import CustomScenario from 'components/CustomScenario';
import copy from 'copy-to-clipboard';
import './style.scss';
import className from 'utils/style/className';
import { sortArray } from 'utils/sort';

const { getClass } = className('c-conversionTracking');

const CS = 'customScenario';

const fieldTokens = Array(20)
  .fill(0)
  .map((_, index) => `c${index + 1}`);

const defaultConverstionTrackingSettings: ConversionTrackingSettings = {
  customEventType: '' as any,
  customEventData: {},
  isCustomScenario: false,
  postbackURL: '',
  postbackHTML: '',
  postbackType: 'none'
};

const attributeOptions = getAttributes('Traffic Source Conversion Tracking');

const postbackTypes: FFSelectOption<Postback.PostbackTypeEnum>[] = [
  {
    value: Postback.PostbackTypeEnum.None,
    label: 'None'
  },
  {
    value: Postback.PostbackTypeEnum.PostbackURL,
    label: 'Postback URL'
  },
  {
    value: Postback.PostbackTypeEnum.Html,
    label: 'HTML'
  },
  {
    value: CS as any,
    label: 'Custom Scenario'
  }
];

interface Props {
  trafficSource: TrafficSource;
  triggeringEvent: string;
  setCustomEventHasError: (value: boolean) => void;
  onUpdate: (trafficSource: TrafficSource) => void;
}

interface State {
  inputSelectionStartPostbackHTML: number;
  inputSelectionStartPostbackURL: number;
  conversionTrackingSettings: ConversionTrackingSettings;
  legacyCustomScenarioData: TrafficSource['customScenarioData']
}

class ConversionTracking extends React.Component<Props, State> {
  state: State = {
    inputSelectionStartPostbackHTML: 0,
    inputSelectionStartPostbackURL: 0,
    conversionTrackingSettings: defaultConverstionTrackingSettings,
    legacyCustomScenarioData: { isCustomScenario: false, type: '' as any, data: {} }
  };

  componentDidMount() {
    this.handleState('legacyCustomScenarioData', this.props.trafficSource.customScenarioData);
    this.initialize(true);
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.triggeringEvent !== this.props.triggeringEvent) {
      await this.handleState('conversionTrackingSettings', defaultConverstionTrackingSettings);
      this.initialize();
    }
  }

  initialize = async (init = false) => {
    await this.handleState('conversionTrackingSettings', this.props.trafficSource.conversionTrackingSettings?.[this.props.triggeringEvent] || defaultConverstionTrackingSettings);

    if (this.props.triggeringEvent === 'Conversions') {
      this.handleConversionTrackingSettings('postbackType', this.props.trafficSource.postback?.postbackType || 'none');
      this.handleConversionTrackingSettings('postbackURL', this.props.trafficSource.postback?.postbackURL!);
      this.handleConversionTrackingSettings('postbackHTML', this.props.trafficSource.postback?.postbackHTML!);
    }
    if (this.state.legacyCustomScenarioData && init) {
      await this.handleConversionTrackingSettings('customEventType', this.state.legacyCustomScenarioData?.type!);
      await this.handleConversionTrackingSettings('customEventData', this.state.legacyCustomScenarioData?.data);
      this.handleConversionTrackingSettings('isCustomScenario', this.state.legacyCustomScenarioData?.isCustomScenario || false);
    }
  };

  saveInputPosition = (e: React.FocusEvent<HTMLTextAreaElement, Element>, type: Postback.PostbackTypeEnum) => {
    if (type === 'html') {
      this.handleState('inputSelectionStartPostbackHTML', e.target.selectionStart);
    } else {
      this.handleState('inputSelectionStartPostbackURL', e.target.selectionStart);
    }
  };

  handleState = async <T extends State, P extends keyof T>(key: P, value: T[P]) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  handleConversionTrackingSettings = async <T extends ConversionTrackingSettings, P extends keyof T>(key: P, value: T[P]) => {
    await this.setState(state => ({
      ...state,
      conversionTrackingSettings: {
        ...state.conversionTrackingSettings,
        [key]: value
      }
    }));
    this.onChange();
  }

  onChange = () => {
    const trafficSource = this.props.trafficSource;
    if (this.props.triggeringEvent === 'Conversions') {
      trafficSource.postback = {
        idTrafficSource: this.props.trafficSource.idTrafficSource,
        postbackType: this.state.conversionTrackingSettings.postbackType,
        postbackHTML: this.state.conversionTrackingSettings.postbackHTML,
        postbackURL: this.state.conversionTrackingSettings.postbackURL
      }
    } else {
      trafficSource.customEventPostback = {
        idTrafficSource: this.props.trafficSource.idTrafficSource,
        customEvents: {
          ...(this.props.trafficSource.customEventPostback?.customEvents || {}),
          [this.props.triggeringEvent]: {
            postbackType: this.state.conversionTrackingSettings.postbackType,
            postbackURL: this.state.conversionTrackingSettings.postbackURL,
            postbackHTML: this.state.conversionTrackingSettings.postbackHTML
          }
        },
      };
    }
    trafficSource.conversionTrackingSettings = {
      ...(this.props.trafficSource.conversionTrackingSettings || {}),
      [this.props.triggeringEvent]: this.state.conversionTrackingSettings
    };
    if (this.state.conversionTrackingSettings.postbackType === 'none') {
      delete trafficSource.customEventPostback?.customEvents[this.props.triggeringEvent];
    }
    delete trafficSource.customScenarioData;
    this.props.onUpdate(trafficSource);
  };

  onPostbackTypeChange = (value: Postback.PostbackTypeEnum | typeof CS) => {
    if (value === CS) {
      this.handleConversionTrackingSettings('isCustomScenario', true);
      this.handleConversionTrackingSettings('postbackType', 'postbackURL');
    } else {
      this.handleConversionTrackingSettings('postbackURL', '');
      this.handleConversionTrackingSettings('isCustomScenario', false);
      this.handleConversionTrackingSettings('postbackType', value);
    }
  };

  handlePostbackTokens = (value: string) => {
    const postbackURL = this.state.conversionTrackingSettings.postbackURL;
    const postbackHTML = this.state.conversionTrackingSettings.postbackHTML;
    const isHTML = this.state.conversionTrackingSettings.postbackType === 'html';
    const inputSelectionStart = isHTML ? this.state.inputSelectionStartPostbackHTML : this.state.inputSelectionStartPostbackURL;

    const getPostback = (postbackValue: string = '') => {
      postbackValue = defined(postbackValue) && postbackValue.length > 0 ? postbackValue : '';
      return inputSelectionStart >= 0
        ? postbackValue.substr(0, inputSelectionStart) + value + postbackValue.substr(inputSelectionStart, postbackValue.length)
        : postbackValue;
    };

    this.handleConversionTrackingSettings('postbackURL', isHTML ? postbackURL : getPostback(postbackURL));
    this.handleConversionTrackingSettings('postbackHTML', isHTML ? getPostback(postbackHTML) : postbackHTML);
  };

  isCustomScenario = () => {
    return this.state.conversionTrackingSettings.isCustomScenario;
  };

  isNone = () => {
    return this.state.conversionTrackingSettings.postbackType === 'none';
  };

  render() {
    return (
      <FFCol>
        <FFSection>
          <FFCol gap="12px">
            <FFRow gap="12px">
              <FFField label="Tracking Type">
                <FFSelect
                  id="postback"
                  data-testid="postback"
                  value={this.isCustomScenario() ? CS : this.state.conversionTrackingSettings.postbackType}
                  valueGetter={opt => opt.value}
                  labelGetter={opt => opt.label}
                  options={postbackTypes}
                  style={{ width: 290 }}
                  placeholder="Tracking Type"
                  onChange={this.onPostbackTypeChange}
                />
              </FFField>
              {this.isCustomScenario() && (
                <FFField label="Custom Scenario">
                  <FFSelect
                    className={getClass('customEventType')}
                    value={this.state.conversionTrackingSettings.customEventType}
                    valueGetter={key => key}
                    labelGetter={key => customEventSettingsTemplate?.[key as TrafficSource.CustomScenarioTypeEnum].name}
                    options={sortArray(Object.keys(customEventSettingsTemplate))}
                    placeholder="Select Type"
                    onChange={(value: TrafficSource.CustomScenarioTypeEnum) => {
                      this.handleConversionTrackingSettings('customEventType', value);
                    }}
                  />
                </FFField>
              )}
            </FFRow>
            {this.isCustomScenario() && this.state.conversionTrackingSettings.customEventType && (
              <CustomScenario
                template={customEventSettingsTemplate[this.state.conversionTrackingSettings.customEventType]}
                initialData={this.state.conversionTrackingSettings.customEventData}
                onChange={(data, postbackURL) => {
                  this.handleConversionTrackingSettings('postbackURL', postbackURL);
                  this.handleConversionTrackingSettings('customEventData', data);
                }}
                setCustomScenarioHasError={this.props.setCustomEventHasError}
                isTouchedForm={true}
                className={getClass('customScenario')}
              />
            )}
            <VisibilityWrapper visible={!this.isCustomScenario() && !this.isNone()}>
              <FFCol gap="12px">
                {this.state.conversionTrackingSettings.postbackType === 'postbackURL' && (
                  <FFField label="Postback URL">
                    <FFRow gap="8px">
                      <FFTextarea
                        onBlur={e => this.saveInputPosition(e, 'postbackURL')}
                        value={this.state.conversionTrackingSettings.postbackURL}
                        placeholder="Insert your postback URL here"
                        onChange={e => this.handleConversionTrackingSettings('postbackURL', withoutWhiteSpace(e.target.value))}
                      />
                      <CopyButton onClick={() => copy(this.state.conversionTrackingSettings.postbackURL!)} />
                    </FFRow>
                  </FFField>
                )}
                {this.state.conversionTrackingSettings.postbackType === 'html' && (
                  <FFField label="Postback HTML">
                    <FFRow gap="8px">
                      <FFTextarea
                        onBlur={e => this.saveInputPosition(e, 'html')}
                        value={this.state.conversionTrackingSettings.postbackHTML}
                        placeholder="Paste your tracking HTML"
                        onChange={e => this.handleConversionTrackingSettings('postbackHTML', e.target.value)}
                        rows={5}
                      />
                      <CopyButton onClick={() => copy(this.state.conversionTrackingSettings.postbackHTML!)} />
                    </FFRow>
                  </FFField>
                )}
                <FFRow alignItems="center" gap="12px">
                  <FFSelect
                    options={fieldTokens}
                    valueGetter={opt => `{data-${opt}}`}
                    labelGetter={opt => opt}
                    placeholder="Field Tokens"
                    id="fieldTokens"
                    data-testid="fieldTokens"
                    style={{ width: 150 }}
                    onChange={this.handlePostbackTokens}
                  />
                  <FFSelect
                    options={attributeOptions}
                    valueGetter={opt => opt.value}
                    labelGetter={opt => opt.label}
                    filterOption={true}
                    showSearch={true}
                    dropdownMatchSelectWidth={false}
                    placeholder="Attributes"
                    id="attributes"
                    groupOptions={true}
                    groupBy="category"
                    style={{ width: 150 }}
                    onChange={this.handlePostbackTokens}
                    sortGroup={true}
                  />
                  <FFButton text="{external}" onClick={() => this.handlePostbackTokens('{external}')} />
                  <FFButton text="{payout}" onClick={() => this.handlePostbackTokens('{payout}')} />
                  <FFButton text="{txid}" onClick={() => this.handlePostbackTokens('{txid}')} />
                  <FFButton text="{timestamp}" onClick={() => this.handlePostbackTokens('{timestamp}')} />
                </FFRow>
                <p>
                  <span style={{ color: '#008eff' }}>{`{external}`}</span> = the click identifier passed from the traffic source under the
                  &quot;external&quot; field <br />
                  <span style={{ color: '#008eff' }}>{`{payout}`}</span> = offer&apos;s payout <br />
                  <span style={{ color: '#008eff' }}>{`{txid}`}</span> = conversion&apos;s transaction id <br />
                  <span style={{ color: '#008eff' }}>{`{timestamp}`}</span> = current unix timestamp <br />
                </p>
              </FFCol>
            </VisibilityWrapper>
          </FFCol>
        </FFSection>
      </FFCol>
    );
  }
}

export default ConversionTracking;

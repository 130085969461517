import React from 'react';
import Messages from 'components/Messages';
import { Input, Label } from 'components/Parts/Inputs';
import { ModalButtonGroup } from 'components/Parts/Groups';
import { withLoading } from 'components/Loading';
import { AddEditApiKeyFormProps } from 'types/ModalForms/addEditApiKey';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from 'utils/reactive/generic';
import { AnyObject } from 'types';
import validateForm, {
  isAlphanumeric,
  minCharacters,
  required
} from 'utils/validation';
import { defined } from 'utils/define';
import { ValidationRule } from '../../../utils/validation/types';
import { IntegrationType } from 'types/integrations';
import { ContentWrapper } from 'components/Parts/Content';

interface State extends ReactiveValidateState {
  name: string;
  type: IntegrationType;
  validationErrors: AnyObject;
  isTouchedForm: boolean;
  showErrorTooltip: boolean;
}

class AddEditApiKeyForm extends ReactiveValidateComponent<
  AddEditApiKeyFormProps,
  State
> {
  state: State = {
    name: '',
    type: 'clickbank',
    validationErrors: {},
    isTouchedForm: false,
    showErrorTooltip: false
  };

  isUpdate = () => defined(this.props.contextModal?.data?.data);

  async componentDidMount() {
    await this.handleStateChange('type', this.props.contextModal.data.type);
    if (this.isUpdate()) {
      this.handleStateChange('name', this.props.contextModal.data.data.name);
    }
  }

  getName = () => this.state.name;

  isOptimizer = () => this.state.type === 'optimizer';

  validationRules = (): ValidationRule[] => [
    {
      field: 'name',
      validations: [
        required,
        minCharacters(this.isOptimizer() ? 3 : 8),
        ...(this.isOptimizer() ? [] : [isAlphanumeric])
      ]
    }
  ];

  validateClassName = (field: string) =>
    !!this.state.validationErrors[field] ? 'has-error' : '';

  validateTooltip = (field: string) => {
    return this.state.validationErrors[field]
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({
        validationErrors: {},
        showErrorTooltip: false
      });
      const [validationErrors] = validateForm(
        {
          name: this.getName()
        },
        this.validationRules()
      );
      this.setState((state: State) => ({
        ...state,
        validationErrors: validationErrors
      }));
    }
  };

  handleStateChange = <T extends State>(name: keyof T, value: T[keyof T]) => {
    this.setState(
      state => ({
        ...state,
        [name]: value
      }),
      this.validate
    );
  };

  handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await this.setTouchedForm();
    await this.validate();

    if (!Object.keys(this.state.validationErrors).length) {
      this.props.startLoading('ok');
      try {
        if (this.state.type === 'clickbank') {
          await this.props.contextModal?.onSubmit!(this.getName());
        } else {
          if (this.isUpdate()) {
            await this.props.handleUpdate({
              ...this.props.contextModal.data.data,
              name: this.getName()
            });
          } else {
            await this.props.handleCreate({
              name: this.getName(),
              api: 'OPTIMIZER'
            });
          }
        }

        this.props.stopLoading('all');
        this.props.showMessage(
          Messages.success(
            `${this.getName()} has been ${
              this.isUpdate() ? 'updated' : 'created'
            }`
          )
        );
        this.props.actions.onOk();
      } catch (error) {
        this.props.stopLoading('all');
        this.props.showMessage(
          Messages.failed(`Add ${this.getName()} api failed`)
        );
      }
    }
  };

  getForm = (type: IntegrationType) => {
    if (type === 'optimizer') {
      return (
        <div className={this.validateClassName('name')}>
          <Label htmlFor="keyName" text="API Key Name" />
          <Input
            name="keyName"
            value={this.getName()}
            id="keyName"
            data-testid="keyName"
            placeholder="API Key Name"
            error={this.validateTooltip('name')}
            showErrorTooltip={this.state.showErrorTooltip}
            onChange={e => this.handleStateChange('name', e.target.value)}
          />
        </div>
      );
    }

    if (type === 'clickbank') {
      return (
        <div className={this.validateClassName('name')}>
          <Label htmlFor="secretKey" text="Secret Key" />
          <Input
            name="keyName"
            value={this.getName()}
            id="secretKey"
            data-testid="secretKey"
            error={this.validateTooltip('name')}
            showErrorTooltip={this.state.showErrorTooltip}
            placeholder="Your Secret Key"
            onChange={e =>
              e.target.value.length > 16
                ? {}
                : this.handleStateChange('name', e.target.value)
            }
          />
        </div>
      );
    }
  };

  getButtonText = () => {
    if (this.state.type === 'clickbank') {
      return 'Add Account';
    }
    return `${this.isUpdate() ? 'Update' : 'Create'} Key`;
  };

  render() {
    return (
      <ContentWrapper className="padding-x-25 padding-y-15">
        <div className="flex flex-col flex-gap-10">
          {this.getForm(this.state.type)}
          <ModalButtonGroup
            loading={this.props.loading}
            showOk
            okText={this.getButtonText()}
            onOkClicked={this.handleSubmit}
          />
        </div>
      </ContentWrapper>
    );
  }
}

export default withLoading(Messages.injectIn(AddEditApiKeyForm));

import { DrilldownReportRow } from 'model/drilldownReportRow';
import { ValueGetterParams, RowEvent, CellClassParams, ColDef, RowClassParams, SortModelItem } from 'ag-grid-community';
import { FormContextModal } from '../modal';
import { QuickStatsOverlayParams, TimeRangeKeys } from 'types';
import { AttributeData } from 'model/attributeData';
import { StatusEnum } from 'api/types';
import { FluxColProps } from './column';

export type onEditModeButtonsClickType = (
  level: number,
  value: string,
  buttonType: TableEditModeButton,
  data?: DrilldownReportRow
) => void;

export type TableEditModeButton = 'Edit' | 'Delete' | 'FunnelBuilder' | 'Quickstats' | 'Copy' | 'Archive' | 'EditBuilder' | 'Settings';
export const TableEditModeButton: { [key in TableEditModeButton]: TableEditModeButton } = {
  Delete: 'Delete',
  Edit: 'Edit',
  FunnelBuilder: 'FunnelBuilder',
  Quickstats: 'Quickstats',
  Copy: 'Copy',
  Archive: 'Archive',
  EditBuilder: 'EditBuilder',
  Settings: 'Settings'
};

export interface TableSettingsActionsAndEvents {
  title: string;
  items: {
    label: string;
    value: string;
  }[];
}

export type TableCol = {
  metric?: string;
  defaultChecked?: boolean;
  colId: string;
  isUniquenessAttribute?: boolean;
  valueGetter?: (params: ValueGetterParams) => number;
  header?: string;
  symbol?: string;
  type?: string;
};

export type DrillFunctionalityProps = {
  expandAll: boolean;
  forceDrill?: boolean;
};
export type DrillFunctionalityType = (data: DrillFunctionalityProps) => void;
export interface TableColValues {
  [key: string]: TableCol;
}

export interface ColumnsSettings {
  [key: string]: (col: TableCol, initialFilters: InitialInlineFilters, tableColSizes: TableColSizesObject) => any;
}

export interface TableButtonContext {
  onClick: Function;
  context?: FormContextModal;
  rowData?: DrilldownReportRow[];
  moduleName?: string;
  openQuickStats?: (data: QuickStatsOverlayParams) => void;
  showEditModeTooltip?: boolean;
}

export interface AllButtons {
  [key: string]: (context: FormContextModal) => TableButtonContext;
}

export type DefaultButtons = ((context: FormContextModal) => TableButtonContext)[][];

export type RowEditButtonName =
  | 'tableQuickstats'
  | 'tableEdit'
  | 'tableOpenBuilder'
  | 'tableCopy'
  | 'tableArchive'
  | 'tableUnArchive'
  | 'tableDelete';
export interface UpdatedRowsProps {
  id: string;
  newName: string;
  newCategoryID?: string;
  newOfferSourceID?: string;
  newPayout?: string;
  idFunnelGroup?: string;
  funnelGroupName?: string;
  rows: DrilldownReportRow[];
}

export interface CustomValueGetterParams extends ValueGetterParams, ColDef {
  symbol: string;
  metric: string;
  isUniquenessAttribute?: boolean;
}

export interface SortModel {
  colId: string;
  sort: string;
}

export interface InitialInlineFilters {
  [key: string]: string | number;
}

export type TableColSizesObject = { [key: string]: number };

export interface TableColsDefaults {
  [key: string]: FluxColProps[];
}

export interface ReportingColumns {
  [key: string]: TableCol[];
}

export interface TrafficCostsTableColumn {
  headerName: any;
  field: string;
  suppressMenu: boolean;
  width: number;
  suppressSizeToFit: boolean;
  lockPosition: boolean;
  resizable: boolean;
}

export interface ButtonsParams {
  openQuickStats: (data: QuickStatsOverlayParams) => void;
  onUnArchiveClick?: (context: FormContextModal) => Promise<void>;
  rowData?: DrilldownReportRow[];
  showEditModeTooltips: boolean;
  reportAttributes?: (moduleName: string) => AttributeData[];
}

export interface RowStyleSetting {
  colorizeTableRows: boolean;
  show: StatusEnum;
}

export type GetRowStyle = (params: RowClassParams, settingParams?: RowStyleSetting) => React.CSSProperties;

export type GroupColSpan = (params: ValueGetterParams) => number;

export interface TableParams {
  isFilterMode: boolean;
  isDrillMode: boolean;
  expandedRows: string[];
  pageSize: number;
  currentPage: number;
  filter: InitialInlineFilters;
  sort?: SortModelItem[];
  dateRangeName: TimeRangeKeys;
  lastChangedDate: string;
  tableColSizes: TableColSizesObject;
  colorizeTableRows: boolean;
  returnUniqueVisitors: boolean;
}


import React, { createRef, RefObject } from 'react';
import { Input, Label, Select } from 'components/Parts/Inputs';
import './style.scss';
import { FunnelFormProps } from '../../../types/ModalForms/funnel';
import {
  FormContentWrapper,
  FormHelpSection,
  FormSectionBox
} from 'components/Parts/Content';
import { ModalButtonGroup } from 'components/Parts/Groups';
import { IconTooltip } from 'components/Parts/Tooltip';
import Messages from 'components/Messages';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from '../../../utils/reactive/generic';
import validateForm, { required, unique } from 'utils/validation';
import { AddButton as OpenButton } from 'components/Parts/Buttons';
import Icon from 'components/Icons';
import { Funnel } from '../../../model/funnel';
import { generateEntityId } from '../../../utils/id/generator';
import {
  AnyObject,
  MessageProps,
  OptionCategory,
  SelectOption
} from '../../../types';
import { pregMatchAll } from '../../../utils/regexp';
import { DUPLICATE_RECORD_CODE } from '../../../constants/errors';
import { FunnelNode } from '../../../model/funnelNode';
import { defined } from '../../../utils/define';
import { NAVIGATION_CREATE_OR_OPEN_MODAL } from '../../../constants/modal';
import { FunnelGroup } from '../../../model/funnelGroup';
import { disabledIPs, IPs, systemSettingsDefault } from 'constants/view';
import {
  BuilderFunnelEditorForm,
  FormContextModal,
  NavigationTabContextModal
} from '../../../types/modal';
import {
  countDecimals,
  firstLetterToUppercase,
  removeCommaOrMultipleDot,
  removeNonDigits
} from '../../../utils/string';
import { getDefaultDomain } from '../../../utils/settings';
import {
  getErrorId,
  handleScrollToErrorElement
} from '../../../utils/validation';
import { sortByName } from '../../../utils/sort';
import { copy } from 'utils/copy';
import { UnsavedFunnelAlert, NoDomainAlert } from '../../Parts/Alert';
import { cloneObject, equalObjects } from '../../../utils/object';
import { funnelModelKeys } from '../../../api/types';
import { isCopyByContextModal } from '../../../utils/modals';
import {
  getActiveEntities,
  getFunnelWithDefaultNodes,
  serverVersionIsAheadOfLocal,
  withIncrementedVersion
} from '../../../utils/model';
import { RequestIdleCallback } from 'utils/performance';
import { ValidationRule } from '../../../utils/validation/types';
import { getCostOnTsChange } from '../../../utils/linkJs/index';
import { DomainEntry, TrafficSource } from 'model/models';
import {
  asyncVersionConfirmSidebar,
  getVisibilityByActiveTab
} from 'utils/dynamic-sidebar';
import { LoadingProps } from 'types/loading';
import {
  FUNNEL_FORM_TAB,
  FUNNEL_FORM_ADVANCED_FUNNEL_SETTINGS_TAB,
  FUNNEL_FORM_HELP_TAB,
  FUNNEL_FORM_REDIRECT_LINKS_TAB,
  FUNNEL_FORM_USE_JAVASCRIPT_TAB
} from 'constants/dynamicSidebar';
import { SidebarContext } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import { getCategorizedFunnelsList } from 'utils/selectors';
import { PageGroupArray } from 'types/redux/store';
import StateRenderer from 'components/StateRenderer';
import CodeSnippet from 'components/CodeSnippet';
import { Divider } from 'antd';
import { FFAddGroup, FFInput, FFSelect, VisibilityWrapper } from 'uikit';
import { FFIconedButton } from 'uikit';

interface State extends ReactiveValidateState {
  funnelNames: string[];
  model: Funnel;
  oldFunnel: Funnel;
  trafficOverrides: Funnel.IncomingCostOverrides[];
  anotherModel: AnyObject;
  funnelNodes: FunnelNode[];
  ipAnonymizers: SelectOption[];
  isNewFunnel: boolean;
  showErrorTooltip: boolean;
  linksTabState: {
    idPage: string;
    idTrafficSource: string;
    idDirectNode: string;
    idRedirectNode: string;
    pageBaseUrl: string;
    cost: string;
    domain: string;
    directLinkNodeSelect: string;
    appendTsParams: boolean;
  };
}

const defaultModelState: Funnel = {
  idFunnel: '',
  idCampaign: '',
  defaultCost: '',
  funnelName: '',
  status: 'active',
  customTokens: {},
  incomingCostOverrides: {}
};

const defaultState = (): State => ({
  funnelNames: [],
  model: defaultModelState,
  oldFunnel: defaultModelState,
  trafficOverrides: [{ source: '', value: '' }],
  anotherModel: {
    domain: '',
    accumulatedUrlParams: '',
    ipAnonymizer: '',
    node: '',
    nodePage: '',
    yourLink: '',
    postbackOverrides: [
      /*{ postbackType: '', postbackCode: '' }*/
    ]
  },
  ipAnonymizers: [...IPs],
  validationErrors: {},
  isTouchedForm: false,
  funnelNodes: [],
  isNewFunnel: true,
  showErrorTooltip: true,
  linksTabState: {
    cost: '',
    idPage: '',
    idDirectNode: '',
    idRedirectNode: '',
    idTrafficSource: '',
    pageBaseUrl: '',
    domain: '',
    directLinkNodeSelect: '',
    appendTsParams: true
  }
});

const getRedirectLinksNodeOptions = (
  funnelNodes: FunnelNode[],
  pageGroupsArray: PageGroupArray
) => {
  let result: OptionCategory[] = [];
  funnelNodes.forEach(funnelNode => {
    const category = `${
      !funnelNode.nodeType || funnelNode.nodeType === 'rotator'
        ? 'Starting'
        : funnelNode.nodeType === 'condition'
        ? 'Condition'
        : funnelNode.nodeType === 'landerGroup'
        ? 'Lander'
        : funnelNode.nodeType === 'offerGroup'
        ? 'Offer'
        : 'Other'
    } Nodes`;

    if (!funnelNode.status || funnelNode.status === 'active') {
      if (
        funnelNode.nodeType === 'landerGroup' ||
        funnelNode.nodeType === 'offerGroup'
      ) {
        const idPageGroup = funnelNode.nodePageGroupParams?.idPageGroup!;
        const routing = pageGroupsArray[idPageGroup]?.routing;

        if (routing === 'rotator') {
          result.push({
            id: funnelNode.idNode,
            name: `${funnelNode.nodeName} (${funnelNode.idNode})`,
            category: category
          });
        }
      } else {
        result.push({
          id: funnelNode.idNode,
          name: `${funnelNode.nodeName} (${funnelNode.idNode})`,
          category: category
        });
      }
    }
  });
  return result;
};

class AddFunnel extends ReactiveValidateComponent<
  FunnelFormProps & MessageProps & Partial<LoadingProps>,
  State
> {
  state: State = defaultState();

  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  javascript = createRef<HTMLTextAreaElement>();
  yourLinkJs = createRef<HTMLDivElement>();
  yourLink = createRef<HTMLDivElement>();
  headerJs = createRef<HTMLDivElement>();
  viewJs = createRef<HTMLDivElement>();
  idFunnel = createRef();
  idFunnelGroup = createRef();

  static defaultProps: Partial<FunnelFormProps> = {
    funnelGroups: [],
    funnels: {},
    trackingSources: [
      {
        id: 'none',
        name: 'None'
      },
      {
        id: 'postbackURL',
        name: 'Postback URL'
      },
      {
        id: 'pixelURL',
        name: 'Pixel URL'
      },
      {
        id: 'javascript',
        name: 'Javascript'
      },
      {
        id: 'GETRequest',
        name: 'GET Request'
      }
    ],
    showSaveButton: true,
    showSaveAndCreateButton: true,
    openGroupModal: () => {},
    closeModal: () => {}
  };

  getId = () =>
    (this.props.contextModal as FormContextModal)?.data?.id ||
    this.props.funnel?.idFunnel;

  async componentDidMount() {
    if (this.props.setForCopyIdsProps) {
      this.props.setForCopyIdsProps({
        'Funnel ID': this.getId()
      });
    }

    this.props.setSidebarLoading!(true);
    await this.onDidMount();
    await this.props.fetchDomains();
    this.props.setSidebarLoading!(false);
    await this.fetchLinkJsSources();
  }

  onDidMount = async () => {
    this.setValidateSubscribe();

    if (this.props.fetchFunnelGroups) {
      await this.props.fetchFunnelGroups();
    }

    if (!this.props.editorMode) {
      await this.handleSetState(
        'funnelNames',
        Object.values(this.props.funnels!).map(funnel => funnel.funnelName)
      );
    }

    const contextModal = this.props.contextModal as FormContextModal;
    if (this.hasContextModalProperty('data')) {
      const idFunnelGroup = contextModal.data.idCampaign;
      await this.setState((state: State) => ({
        ...state,
        model: {
          ...state.model,
          idCampaign: idFunnelGroup || ''
        }
      }));
      if (defined(contextModal.data.id) || this.props.funnel?.idFunnel) {
        this.setEditModel(this.getId());
        await this.props.getFunnelById(this.getId());
        this.setEditModel(this.getId());
      } else if (defined(this.props.funnel)) {
        await this.setState((state: State) => ({
          ...state,
          model: this.props.funnel!,
          linksTabState: {
            ...state.linksTabState,
            cost: this.props.funnel?.defaultCost!
          },
          isNewFunnel: false
        }));
        await this.setCustomTokens(this.props.funnel);
        await this.setCustomTokens(this.props.funnel);
      }
    }

    if (defined(this.props.setButtonGroupProps)) {
      this.props.setButtonGroupProps({
        showSave: this.props.showSaveButton,
        showCancel: true,
        saveText: 'SAVE',
        onSaveClicked: this.handleSubmit,
        onCancelClicked: this.props.editorMode
          ? this.handleClose
          : this.props.closeSelf,
        cancelText: 'DISCARD'
      });
    }

    if (this.hasContextModalProperty('defaultName')) {
      await this.setState((state: State) => ({
        model: {
          ...state.model,
          funnelName: contextModal.defaultName!
        }
      }));
    }

    if (this.hasContextModalProperty('funnel')) {
      await this.setState({
        model: (this.props.contextModal as BuilderFunnelEditorForm).funnel
      });
    }

    if (!!this.props.onScrollToElement) {
      this.props.onScrollToElement();
    }

    this.setState({
      oldFunnel: this.state.model
    });

    this.setFunnelNodes();
  };

  async componentDidUpdate(prevProps: FunnelFormProps, prevState: State) {
    if (
      this.state.linksTabState.idTrafficSource !==
      prevState.linksTabState.idTrafficSource
    ) {
      const trafficSource = this.getTrafficSourceById(
        this.state.linksTabState.idTrafficSource
      );
      this.setState((state: State) => ({
        ...state,
        linksTabState: {
          ...state.linksTabState,
          cost: getCostOnTsChange(
            trafficSource!,
            this.state.model.defaultCost!
          ),
          trafficSourceData: trafficSource
        }
      }));
    }

    if (this.props.activeTab !== prevProps.activeTab) {
      if (this.props.activeTab === 'funnel-form-advanced-funnel-settings') {
        await this.props.fetchFunnelGroupsInfo('active');
        await this.props.fetchTrafficSources();
      }
      await this.fetchLinkJsSources();
    }
  }

  fetchLinkJsSources = async () => {
    if (this.props.activeTab === 'funnel-form-redirect-links') {
      await this.handleSetState('linksTabState', {
        ...this.state.linksTabState,
        domain: getDefaultDomain(this.props.userSettings, this.props.domains)
      });
      await Promise.all([
        this.props.fetchTrafficSources(),
        this.props.fetchOffers('all', false),
        this.props.fetchLanders('all'),
        this.props.fetchPageGroups()
      ]);
    }
  };

  static getDerivedStateFromProps(props: FunnelFormProps, state: State) {
    if (
      (defined(props.userSettings) && state.anotherModel.domain === '') ||
      state.anotherModel.ipAnonymizer === ''
    ) {
      const ipAnonymizer = defined(state.model.ipAnonymizer)
        ? state.model.ipAnonymizer
        : props.userSettings.ipAnonymizer;
      return {
        anotherModel: {
          ...state.anotherModel,
          domain: getDefaultDomain(props.userSettings, props.domains),
          ipAnonymizer: ipAnonymizer
        },
        ipAnonymizers: state.ipAnonymizers.map(item =>
          item.value === ipAnonymizer
            ? { value: item.value, label: `System Default (${item.label})` }
            : item
        )
      } as Pick<State, 'ipAnonymizers' | 'anotherModel'>;
    }

    return null;
  }

  isDuplicateMode = () => isCopyByContextModal(this.props.contextModal!);

  setEditModel = (id: string) => {
    const editModel =
      defined(this.props.funnels) && defined(this.props.funnels[id])
        ? cloneObject(this.props.funnels[id])
        : null;

    if (defined(editModel)) {
      if (this.isDuplicateMode()) {
        editModel.funnelName = (this.props
          .contextModal as FormContextModal).copyName!;
        editModel.status = 'active';
      }
      this.setState((state: State) => ({
        model: {
          ...state.model,
          ...editModel
        },
        linksTabState: {
          ...state.linksTabState,
          cost: editModel.defaultCost!
        },
        isNewFunnel: false,
        funnelNames: state.funnelNames.filter(
          name => name !== editModel.funnelName
        )
      }));

      this.setCustomTokens(editModel);

      if (defined(editModel.customTokens)) {
        this.setKeyValuesFromObject('customTokens', editModel.customTokens);
      }
      if (defined(editModel.accumulatedUrlParams)) {
        this.setKeyValuesFromObject(
          'accumulatedUrlParams',
          editModel.accumulatedUrlParams
        );
      }

      if (defined(editModel.incomingCostOverrides)) {
        this.setState((state: State) => ({
          ...state,
          trafficOverrides: Object.entries(
            editModel.incomingCostOverrides!
          ).reduce(
            (acc: Funnel.IncomingCostOverrides[], [key, value]) => [
              ...acc,
              { source: key, value: value }
            ],
            []
          )
        }));
      }

      if (defined(editModel.postbackOverrides)) {
        this.setState((state: State) => ({
          anotherModel: {
            ...state.anotherModel,
            postbackOverrides: editModel.postbackOverrides
          }
        }));
      }

      if (defined(editModel.ipAnonymizer)) {
        this.setState((state: State) => ({
          anotherModel: {
            ...state.anotherModel,
            ipAnonymizer: editModel.ipAnonymizer
          }
        }));
      }
    }
  };

  setCustomTokens = async (model: Funnel) => {
    const funnelGroup = this.props.funnelGroups.find(
      item => item.idCampaign === model.idCampaign
    );
    if (defined(funnelGroup?.customTokens)) {
      await this.setKeyValuesFromObject(
        'campaignTokens',
        funnelGroup!.customTokens
      );
    }
  };

  validationRules = (): ValidationRule[] => [
    {
      field: 'funnelName',
      validations: [required, unique(this.state.funnelNames, 'funnel')]
    },
    {
      field: 'idCampaign',
      validations: [required]
    }
  ];

  getTrafficSourceById = (trafficSourceId: string) =>
    this.props.trafficSources.find(
      ts => ts.idTrafficSource === trafficSourceId
    );

  setKeyValuesFromObject = (
    name: string,
    value: {
      [key: string]: string;
    }
  ) => {
    this.setState((state: State) => ({
      anotherModel: {
        ...state.anotherModel,
        [name]: Object.entries(value || {}).reduce(
          (acc: string, [key, value]) => `${acc}${key}=${value}\n`,
          ''
        )
      }
    }));
  };

  setObjectFromKeyValues = async (name: string) => {
    const data = pregMatchAll(
      this.state.anotherModel[name],
      /(.*)=(.*)\n?/gim
    ).reduce(
      (acc: {}, item) =>
        !!item[1] && !!item[2] ? { ...acc, [item[1]]: item[2] } : acc,
      {}
    );

    await this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        [name]: data
      }
    }));
  };

  validateClassName = (field: string, idx?: number) => {
    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? 'has-error'
      : '';
  };

  validateTooltip = (field: string, idx?: string) => {
    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  clearForm = async () => {
    await this.setState({
      ...defaultState()
    });
    this.onDidMount();
  };

  handleClose = async () => {
    this.props.closeSelf();
  };

  handleSubmit = async (e: Event) => {
    if (defined(e)) {
      e.preventDefault();
    }

    if (this.isDuplicateMode()) {
      this.props.startLoading!('saveAndCreate');
    } else {
      this.props.startLoading!('save');
    }

    if (await this.performCreateOrUpdate()) {
      this.props.closeSelf();
      await this.clearForm();
      this.props.closeModal!(NAVIGATION_CREATE_OR_OPEN_MODAL);
    }

    return false;
  };

  handleSaveAndOpenEditor = async (e: Event) => {
    e.preventDefault();
    this.props.startLoading!('saveAndCreate');

    if (await this.performCreateOrUpdate(true)) {
      await this.clearForm();
      this.props.closeModal!(NAVIGATION_CREATE_OR_OPEN_MODAL);
      this.props.closeSelf();
    }

    return false;
  };

  performCreateOrUpdate = async (openEditor = false) => {
    await this.setTouchedForm();
    await this.validate();
    await this.setIncomingTrafficCostOverrides();
    await this.setPostbackOverrides();
    await this.setObjectFromKeyValues('customTokens');
    await this.setObjectFromKeyValues('accumulatedUrlParams');
    const contextModal = this.props.contextModal as FormContextModal;

    if (!Object.keys(this.state.validationErrors).length) {
      try {
        const funnel = getFunnelWithDefaultNodes({
          ...this.state.model
        } as Funnel);
        const idFunnel =
          !funnel.idFunnel || funnel.idFunnel === ''
            ? generateEntityId()
            : funnel.idFunnel;

        if (!defined(funnel.connections)) {
          funnel.connections = [];
        }

        if (
          defined(this.state.funnelNodes) &&
          this.state.funnelNodes.length > 0 &&
          funnel.nodes?.length === 1
        ) {
          funnel.nodes = [
            ...(funnel.nodes || []),
            ...this.state.funnelNodes.filter(item => !!item.nodeType)
          ];
        }

        if (this.isDuplicateMode() && defined(this.props.handleDuplicate)) {
          await this.props.handleDuplicate({
            ...funnel,
            meta: { version: 1 }
          });
        } else if (!!funnel.idFunnel) {
          await this.props.getFunnelById(funnel.idFunnel);
          if (
            funnel?.meta &&
            serverVersionIsAheadOfLocal(
              funnel?.meta?.version,
              this.props.funnels[funnel.idFunnel]?.meta?.version
            )
          ) {
            const sidebar = this.context as SidebarProps;
            try {
              await asyncVersionConfirmSidebar(sidebar);
              await this.props.handleUpdate(
                withIncrementedVersion(
                  funnel,
                  this.props.funnels[funnel.idFunnel]?.meta?.version
                )
              );
            } catch (e) {
              this.props.stopLoading!('all');
              this.handleClose();
              return;
            }
          } else {
            await this.props.handleUpdate(withIncrementedVersion(funnel));
          }
        } else {
          await this.props.handleCreate({
            ...funnel,
            idFunnel: idFunnel,
            meta: { version: 1 }
          });
        }

        if (openEditor) {
          RequestIdleCallback(() => {
            setTimeout(() => {
              if (contextModal.onCreateOrUpdate) {
                contextModal.onCreateOrUpdate();
              }

              if (defined(this.props.addFunnelEditor)) {
                this.props.addFunnelEditor({
                  idFunnel: idFunnel,
                  funnelName: funnel.funnelName
                });
              }

              if (contextModal.history) {
                contextModal.history.push(`/funneleditor/${idFunnel}`);
              }
            }, 100);
          });
        }

        this.props.stopLoading!('all');
        if (!this.props.editorMode) {
          this.props.showMessage(
            Messages.success(
              `${funnel.funnelName} ${
                !!funnel.idFunnel ? 'has been updated' : 'has been added'
              }`
            )
          );
        }
      } catch (e) {
        this.props.stopLoading!('all');
        if (
          defined(e.response) &&
          e.response.status === DUPLICATE_RECORD_CODE
        ) {
          this.setState(
            {
              funnelNames: [
                ...this.state.funnelNames,
                this.state.model.funnelName
              ]
            },
            this.validate
          );
        }
        this.props.showMessage(
          Messages.failed(
            `${this.state.model.funnelName} ${
              !!this.state.model.idFunnel
                ? 'cannot be updated'
                : 'cannot be added'
            }`
          )
        );
        return false;
      }

      return true;
    } else {
      this.props.stopLoading!('all');
    }

    return false;
  };

  setPostbackOverrides = async () => {
    await this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        postbackOverrides: state.anotherModel.postbackOverrides
      }
    }));
  };

  setIncomingTrafficCostOverrides = async () => {
    await this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        incomingCostOverrides: (state.trafficOverrides || []).reduce(
          (acc: {}, item: AnyObject) =>
            !!item.source && !!item.value
              ? {
                  ...acc,
                  [item.source]: item.value
                }
              : acc,
          {}
        )
      }
    }));
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {}, showErrorTooltip: false });
      const [validationErrors] = validateForm(
        this.state.model,
        this.validationRules()
      );
      this.setState((state: State) => ({
        ...state,
        validationErrors
      }));

      handleScrollToErrorElement(
        validationErrors,
        (entries: IntersectionObserverEntry[]) => {
          if (defined(entries[0]) && entries[0].isIntersecting) {
            this.setState({ showErrorTooltip: true });
          }
        }
      );
    }
  };

  removeFieldSet = async (fieldSetType: string, id: number) => {
    await this.setState((state: State) => ({
      ...state,
      anotherModel: {
        ...state.anotherModel,
        [fieldSetType]: state.anotherModel[fieldSetType].filter(
          (_: any, idx: number) => idx !== id
        )
      }
    }));
  };

  addNewFieldSet = async (fieldSetType: string) => {
    let value = this.valueToBeAdded(fieldSetType);
    await this.setState((state: State) => ({
      ...state,
      anotherModel: {
        ...state.anotherModel,
        [fieldSetType]: [...state.anotherModel[fieldSetType], value]
      }
    }));
  };

  addNewTrafficsourceOverride = () => {
    this.handleStateValues('trafficOverrides', [
      ...this.state.trafficOverrides,
      { source: '', value: '' }
    ]);
  };

  removeTrafficsourceOverride = (idx: number) => {
    this.handleStateValues(
      'trafficOverrides',
      this.state.trafficOverrides.filter((_, _idx) => _idx !== idx)
    );
  };

  valueToBeAdded = (type: string) => {
    switch (type) {
      case 'trafficOverrides':
        return { source: '', value: '' };
      case 'postbackOverrides':
        return { postbackType: '', postbackCode: '' };
      default:
        return;
    }
  };

  handleCopyClick = (context: RefObject<any>, successText?: string) => {
    copy(context, this.props.showMessage, Messages, successText);
  };

  handleSetState = <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    this.setState({
      ...this.state,
      [key]: value
    });
  };

  handleStateValues = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  handleTrafficSourceOverrides = (key: string, value: string, idx?: number) => {
    this.handleStateValues(
      'trafficOverrides',
      this.state.trafficOverrides.map((trafficOverride, _idx) => {
        if (idx === _idx) {
          return {
            ...trafficOverride,
            [key]: value
          };
        } else {
          return trafficOverride;
        }
      })
    );
  };

  handleSelectInputChange = async (
    name: string,
    value: any,
    idx?: number,
    fieldSetType?: string,
    appender = false
  ) => {
    const isFunnelType = (x: any): x is Funnel =>
      funnelModelKeys.indexOf(x) >= 0;
    const validate = () => this.validate$.next();
    if (idx !== undefined && fieldSetType !== undefined) {
      if (fieldSetType === 'trafficOverrides' && name === 'source') {
        await this.setState((state: State) => ({
          ...state,
          anotherModel: {
            ...state.anotherModel,
            [fieldSetType]: this.dynamicDataPassing(
              state.anotherModel[fieldSetType],
              idx,
              'value',
              '',
              appender
            )
          }
        }));
      }

      await this.setState(
        (state: State) => ({
          ...state,
          anotherModel: {
            ...state.anotherModel,
            [fieldSetType]: this.dynamicDataPassing(
              state.anotherModel[fieldSetType],
              idx,
              name,
              value,
              appender
            )
          },
          model: isFunnelType(name)
            ? {
                ...state.model,
                [name]: value
              }
            : state.model
        }),
        validate
      );
      return;
    }

    await this.setState(
      (state: State) => ({
        ...state,
        model: isFunnelType(name)
          ? {
              ...state.model,
              [name]: value
            }
          : state.model,
        anotherModel: {
          ...state.anotherModel,
          [name]: value
        }
      }),
      validate
    );
  };

  dynamicDataPassing = (
    arr: [],
    idx: number,
    name: string,
    value: string,
    appender: boolean = false
  ) => {
    return arr.map((q: any, i) => {
      if (idx === i) {
        return { ...q, [name]: value };
      }
      return q;
    });
  };

  isSelectedNode = () => defined(this.state.anotherModel.node);

  setFunnelNodes = () => {
    const nodes = (this.state.model?.nodes || []).filter(
      (item: FunnelNode) => !!item.nodeName
    );
    this.handleSetState(
      'funnelNodes',
      nodes.map((item: FunnelNode) =>
        item.nodeName === 'Traffic' ? { ...item, nodeType: null as any } : item
      )
    );
  };

  hasContextModalProperty = (property: string) =>
    defined((this.props.contextModal as any)?.[property]);

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;

    return (
      <>
        <form className="cform-funnel">
          <FormContentWrapper
            show={getVisibilityByActiveTab(
              FUNNEL_FORM_TAB,
              this.props.activeTab
            )}
          >
            <FormSectionBox title={this.props.tabTitle!}>
              <div className="flex flex-row flex-gap-15 margin-bottom-15">
                <div
                  id={getErrorId('idCampaign')}
                  className={`flex flex-col flex-1 cform-funnel--funnelGroup ${this.validateClassName(
                    'idCampaign'
                  )}`}
                >
                  <div className="flex flex-row">
                    <div className="flex flex-col flex-1">
                      <Label
                        htmlFor="campaign-name"
                        text="Parent Funnel Group"
                      />
                      <Select
                        value={this.state.model.idCampaign}
                        id="campaign-name"
                        data-testid="campaign-name"
                        name="campaign-name"
                        placeholder={'Select parent funnel group'}
                        options={getActiveEntities(this.props.funnelGroups)}
                        valueGetter={(option: FunnelGroup) => option.idCampaign}
                        labelGetter={(option: FunnelGroup) =>
                          option.campaignName
                        }
                        onChange={(value: string) =>
                          this.handleSelectInputChange('idCampaign', value)
                        }
                        showSearch={true}
                        filterOption={true}
                      />
                    </div>
                    <OpenButton
                      onClick={() =>
                        this.props.openGroupModal!({
                          onSubmitGroupForm: (value: string) =>
                            this.handleSelectInputChange('idCampaign', value)
                        })
                      }
                      data-testid="openCampaignForm"
                    />
                  </div>
                </div>

                <div className="cform-funnel--horizontalArrow">
                  <Icon type={`flux-horizontalArrow`} />
                </div>

                <div
                  id={getErrorId('funnelName')}
                  className={`flex flex-col flex-1 cform-funnel--funnelName ${this.validateClassName(
                    'funnelName'
                  )}`}
                >
                  <Label htmlFor="funnel-name" text="Funnel Name" />
                  <Input
                    value={this.state.model.funnelName}
                    id="funnel-name"
                    name="funnel-name"
                    placeholder="Enter funnel name here"
                    error={this.validateTooltip('funnelName')}
                    showErrorTooltip={this.state.showErrorTooltip}
                    maxLength={255}
                    onChange={e =>
                      this.handleSelectInputChange('funnelName', e.target.value)
                    }
                    data-testid={`funnelName`}
                  />
                </div>
              </div>

              <div className="flex flex-row flex-justify-space-between flex-align-center margin-bottom-15">
                <div
                  id={getErrorId('defaultCost')}
                  className={`flex flex-col cform-funnel--defaultCost ${this.validateClassName(
                    'defaultCost'
                  )}`}
                >
                  <Label
                    htmlFor="default-cost"
                    text={[
                      `Default Cost per Entrance`,
                      <IconTooltip
                        key={`default-cost`}
                        title={`Default Cost per Entrance`}
                        className="funnelDefaultCost"
                        body={[
                          <p>
                            If you add a value here, all links generated for
                            this funnel will by default inherit this cost value
                            in the link generation form, but any value set at
                            the traffic source-level will take priority.
                          </p>,
                          <p>
                            The cost parameter is appended to URLs as …&c=X and
                            this can be overridden in the link generation form.
                          </p>,
                          <p>
                            If present, this cost is applied to a visitor
                            loading your link, so can be used to give a default
                            cost per click/visit.
                          </p>,
                          <p>
                            When generating links for your funnel, we check for
                            default costs in the following order:
                          </p>,
                          <ul>
                            <li>Funnel Group (can only add a cost value)</li>
                            <li>Funnel (can only add a cost value)</li>
                            <li>
                              Traffic Source (can use values or tokens/text)
                            </li>
                            <li>Cost field in the link generation form</li>
                          </ul>
                        ]}
                      />
                    ]}
                  />
                  <Input
                    value={this.state.model.defaultCost}
                    id="default-cost"
                    name="default-cost"
                    data-testid="defaultCost"
                    placeholder="E.g. 0.01"
                    onChange={e => {
                      this.handleSelectInputChange(
                        'defaultCost',
                        removeCommaOrMultipleDot(e.target.value)
                      );
                      if (!this.state.linksTabState.cost) {
                        this.handleSetState('linksTabState', {
                          ...this.state.linksTabState,
                          cost: removeCommaOrMultipleDot(e.target.value)
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="margin-bottom-15">
                Note: all settings are also available from within the funnel
                editor
              </div>

              <ModalButtonGroup
                loading={this.props.loading}
                saveAndCreateText={
                  (this.props.contextModal as NavigationTabContextModal)
                    ?.saveText || `Save and Open Editor`
                }
                onSaveAndCreateClicked={
                  this.isDuplicateMode()
                    ? this.handleSubmit
                    : this.handleSaveAndOpenEditor
                }
                showSaveAndCreate={true}
              />
            </FormSectionBox>
          </FormContentWrapper>

          <FormContentWrapper
            className="advanced-settings-section"
            show={
              getVisibilityByActiveTab(
                FUNNEL_FORM_ADVANCED_FUNNEL_SETTINGS_TAB,
                this.props.activeTab
              ) &&
              this.hasContextModalProperty('data') &&
              !!this.state.model.idFunnel
            }
          >
            <FormSectionBox title="IP Anonymization Settings">
              <div className="flex flex-col flex-gap-15">
                <Label
                  htmlFor="ipAnonymizer"
                  text="These settings will determine how FunnelFlux
                        truncates IP data before it gets logged to your
                        analytics database, for user privacy and GDPR compliance.
                        This will not impact geo-location data or tokens used in page URLs."
                />
                <Select
                  value={this.state.anotherModel.ipAnonymizer}
                  id="ipAnonymizer"
                  data-testid="ipAnonymizer"
                  name="ipAnonymizer"
                  placeholder="Select IP Anonymization Settings"
                  options={[
                    ...sortByName(
                      this.state.ipAnonymizers.filter(
                        item =>
                          item.value !==
                          (this.state.anotherModel.ipAnonymizer
                            ? this.state.anotherModel.ipAnonymizer
                            : systemSettingsDefault.value)
                      ),
                      'label'
                    )
                  ]}
                  defaultValueFallback={{
                    label: defined(this.state.anotherModel.ipAnonymizer)
                      ? this.state.ipAnonymizers.find(
                          item =>
                            item.value === this.state.anotherModel.ipAnonymizer
                        )!.label
                      : disabledIPs.label,
                    value: defined(this.state.anotherModel.ipAnonymizer)
                      ? this.state.anotherModel.ipAnonymizer
                      : disabledIPs.value
                  }}
                  valueGetter={(option: SelectOption) => option.value}
                  labelGetter={(option: SelectOption) => option.label}
                  onChange={(value: string) =>
                    this.handleSelectInputChange('ipAnonymizer', value)
                  }
                />
              </div>
            </FormSectionBox>

            <FormSectionBox title="Linked Funnels">
              <div className="flex flex-col flex-gap-15">
                <Label
                  htmlFor="idIndirectFunnels"
                  text="FunnelFlux can link visitors across funnels, allowing
                          you to see the lifetime value (LTV) of visitor
                          activity. This works using cookies and/or passing of
                          user identifier (vid), which you will see
                          automatically added to page URLs after redirection."
                />
                <Select
                  value={this.state.model.idIndirectFunnels}
                  id="idIndirectFunnels"
                  data-testid="idIndirectFunnels"
                  name="idIndirectFunnels"
                  placeholder={
                    defined(this.state.model.idIndirectFunnels) &&
                    this.state.model.idIndirectFunnels.length
                      ? `${this.state.model.idIndirectFunnels.length} funnels selected`
                      : 'Select related funnels here'
                  }
                  options={getCategorizedFunnelsList(
                    getActiveEntities(Object.values(this.props.funnels || {})),
                    this.props.funnelGroupsArray,
                    (funnel: Funnel) =>
                      !funnel.status || funnel.status === 'active'
                  )}
                  groupBy="category"
                  groupOptions={true}
                  mode="multiple"
                  valueGetter={(option: OptionCategory) => option.id}
                  labelGetter={(option: OptionCategory) => option.name}
                  onChange={(value: string) =>
                    this.handleSelectInputChange('idIndirectFunnels', value)
                  }
                  showSearch={true}
                  filterOption={true}
                />
              </div>
            </FormSectionBox>

            <FormSectionBox title="Incoming Traffic Cost Override">
              <div className="flex flex-col flex-gap-15">
                <Label text='Incoming cost overrides will change the cost of all matching visitors who enter a funnel. This will not affect existing/past visitors. These overrides are higher priority than the "c" parameter in URLs/JS and can be used to update average incoming cost in real-time.' />
                <FFAddGroup
                  rows={this.state.trafficOverrides}
                  onAddRow={this.addNewTrafficsourceOverride}
                  showAddRow={true}
                  renderRow={(row, rowIdx) => (
                    <>
                      <FFAddGroup.Col className="flex-1">
                        <FFSelect
                          value={row.source}
                          placeholder="Select a traffic source"
                          showSearch
                          id={`trafficSource${rowIdx}`}
                          data-testid={`trafficSource${rowIdx}`}
                          options={this.props.trafficSources}
                          valueGetter={option => option.idTrafficSource}
                          labelGetter={option => option.trafficSourceName}
                          onChange={(value: string) =>
                            this.handleTrafficSourceOverrides(
                              'source',
                              value,
                              rowIdx
                            )
                          }
                        />
                      </FFAddGroup.Col>
                      <FFAddGroup.Col>
                        <FFInput
                          value={row.value}
                          className="flex-1"
                          name="trafficSourceOverrideCost"
                          data-testid={`trafficSourceOverride${rowIdx}`}
                          placeholder="E.g. 0.01"
                          onChange={e => {
                            let value = removeNonDigits(
                              removeCommaOrMultipleDot(e.target.value)
                            );
                            if (countDecimals(Number(value)) >= 7) {
                              return;
                            }
                            this.handleTrafficSourceOverrides(
                              'value',
                              value,
                              rowIdx
                            );
                          }}
                        />
                      </FFAddGroup.Col>
                      <FFAddGroup.Col autoWidth>
                        <FFIconedButton.CircleDelete
                          onClick={() =>
                            this.removeTrafficsourceOverride(rowIdx)
                          }
                        />
                      </FFAddGroup.Col>
                    </>
                  )}
                />
              </div>
            </FormSectionBox>
          </FormContentWrapper>

          <FormContentWrapper
            show={
              getVisibilityByActiveTab(
                FUNNEL_FORM_REDIRECT_LINKS_TAB,
                this.props.activeTab
              ) &&
              this.hasContextModalProperty('data') &&
              !this.hasContextModalProperty('copyName') &&
              !!this.state.model.idFunnel
            }
          >
            {defined(this.props.domains) && this.props.domains.length > 0 ? (
              <div id="funnel-link-section" data-testid="funnel-link-section">
                <FormSectionBox title="URL Generator">
                  <div className="flex flex-justify-between flex-align-center flex-gap-15 width-full margin-bottom-15">
                    <div className="flex-1">
                      <Label htmlFor="redirectNode" text="Select a node" />
                      <div className="flex flex-gap-10 flex-align-center">
                        <StateRenderer
                          showCheckMark={
                            !!this.state.linksTabState.idRedirectNode
                          }
                          step={1}
                          className="flex-align-self-end"
                        />
                        <Select
                          value={
                            this.state.linksTabState.idRedirectNode || 'Traffic'
                          }
                          id="redirectNode"
                          data-testid="node"
                          name="node"
                          placeholder="Select a page node"
                          options={getRedirectLinksNodeOptions(
                            this.state.funnelNodes,
                            this.props.pageGroupsArray
                          )}
                          groupOptions={true}
                          groupBy="category"
                          valueGetter={(option: OptionCategory) => option.id}
                          labelGetter={(option: OptionCategory) => option.name}
                          groupLabelGetter={(value: string) =>
                            firstLetterToUppercase(value).replace(
                              /([a-z](?=[A-RT-Z]))/g,
                              '$1 '
                            )
                          }
                          suspendSort={true}
                          onChange={(value: string) =>
                            this.handleSetState('linksTabState', {
                              ...this.state.linksTabState,
                              idRedirectNode: value
                            })
                          }
                          showSearch
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <Label
                        htmlFor="source"
                        text={[
                          'Select a Traffic Source',
                          <IconTooltip
                            key="tooltip-traffic-source"
                            body="When sending traffic to a page you can pass traffic source IDs in the URL. If you do not, the visitor will appear as “Organic traffic” in your reporting. We suggest you pass this data in the URL, though it is not required."
                          />
                        ]}
                      />
                      <div className="flex flex-gap-10 flex-align-center">
                        <StateRenderer
                          showCheckMark={
                            !!this.state.linksTabState.idTrafficSource
                          }
                          step={2}
                          className="flex-align-self-end"
                        />
                        <Select
                          value={this.state.linksTabState.idTrafficSource}
                          id="source"
                          data-testid="trafficSource"
                          name="source"
                          placeholder={'Select Traffic Source'}
                          options={getActiveEntities(this.props.trafficSources)}
                          valueGetter={(opt: TrafficSource) =>
                            opt.idTrafficSource
                          }
                          labelGetter={(opt: TrafficSource) =>
                            opt.trafficSourceName
                          }
                          onChange={(value: string) =>
                            this.handleSetState('linksTabState', {
                              ...this.state.linksTabState,
                              idTrafficSource: value
                            })
                          }
                          filterOption={true}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-justify-between flex-align-center flex-gap-15 width-full margin-bottom-15">
                    <div className="flex-1">
                      <Label
                        htmlFor="cost"
                        key="cost"
                        text={[
                          'Entrance cost ',
                          <span
                            className="font-size-12 color-4b606d"
                            key="cost-optional-text"
                          >
                            (optional)
                          </span>
                        ]}
                      />
                      <div className="flex flex-gap-10 flex-align-center">
                        <StateRenderer
                          showCheckMark={!!this.state.linksTabState.cost}
                          step={3}
                        />
                        <Input
                          data-testid="entrance-link-cost"
                          placeholder="Entrance cost (optional)"
                          value={this.state.linksTabState.cost}
                          onChange={e =>
                            this.handleSetState('linksTabState', {
                              ...this.state.linksTabState,
                              cost: e.target.value
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <Label
                        htmlFor="domain"
                        text={[
                          'Tracking Domain',
                          <IconTooltip
                            key="tracking-domain-source"
                            body="We recommend using the same domain for all parts of a funnel where possible, i.e. for redirect links, action links, javascript and conversion tracking. Mixing domains can cause tracking losses if cookies are relied upon"
                          />
                        ]}
                      />
                      <div className="flex flex-gap-10 flex-align-center">
                        <StateRenderer
                          showCheckMark={!!this.state.linksTabState.domain}
                          step={4}
                        />
                        <Select
                          data-testid="trackingDomain"
                          value={this.state.linksTabState.domain}
                          onChange={(value: string) =>
                            this.handleSetState('linksTabState', {
                              ...this.state.linksTabState,
                              domain: value
                            })
                          }
                          options={this.props.domains}
                          valueGetter={(opt: DomainEntry) => opt.domain}
                          labelGetter={(opt: DomainEntry) => opt.domain}
                          showSearch
                        />
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <Label
                    htmlFor="yourLink"
                    text="Your Tracking URL"
                    className="font-size-16 font-weight-600 color-5a6a7b"
                  />
                  <p className="margin-bottom-5-important">
                    Use this URL at your traffic source. It will redirect users
                    to your funnel and appends data as defined in your traffic
                    source settings. You do not need to save these generated
                    links or config.
                  </p>
                  <div className="flex flex-align-center margin-top-5">
                    <CodeSnippet
                      data-testid="yourLink"
                      codeType="funnelRedirectLink"
                      className="width-full"
                      forwardedRef={this.yourLink}
                      domain={this.state.linksTabState.domain}
                      idFunnel={this.state.model.idFunnel}
                      cost={this.state.linksTabState.cost}
                      funnelNodes={this.state.funnelNodes}
                      trafficSource={this.getTrafficSourceById(
                        this.state.linksTabState.idTrafficSource
                      )}
                      idTrafficSource={this.state.linksTabState.idTrafficSource}
                      idNode={this.state.linksTabState.idRedirectNode}
                    />
                  </div>
                </FormSectionBox>
              </div>
            ) : (
              <NoDomainAlert />
            )}
            <VisibilityWrapper visible={this.props.isChangedFunnel!}>
              <UnsavedFunnelAlert />
            </VisibilityWrapper>
          </FormContentWrapper>
          <FormContentWrapper
            show={getVisibilityByActiveTab(
              FUNNEL_FORM_USE_JAVASCRIPT_TAB,
              this.props.activeTab
            )}
          >
            <FormSectionBox>
              <h3 className="cform-funnel__codeSnippetTitle">
                Universal JavaScript Tag
              </h3>
              <p>
                For general tracking, make sure to include our universal JS tag.
                This already includes a view event.
              </p>
              <CodeSnippet
                codeType="genericViewFull"
                className="width-full margin-bottom-15"
                forwardedRef={this.headerJs}
                disabled={!!this.state.anotherModel.nodePage}
                domain={this.state.anotherModel.domain}
              />
            </FormSectionBox>
          </FormContentWrapper>
          <FormContentWrapper
            show={getVisibilityByActiveTab(
              FUNNEL_FORM_HELP_TAB,
              this.props.activeTab
            )}
          >
            <FormSectionBox title={this.props.tabTitle!}>
              <FormHelpSection
                content={
                  <>
                    <p>
                      Funnels are the key components of FunnelFlux that describe
                      the journey you want users to experience.
                    </p>

                    <p>
                      Funnels must belong to a group, which is essentially a
                      category or container.
                    </p>

                    <p>
                      Many of the settings here are basic or allow overriding of
                      default system behaviour inside this funnel.
                    </p>
                    <p>
                      Need more help? Read our documentation{' '}
                      <a
                        href="https://help.funnelflux.pro/article/100"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>
                    </p>
                  </>
                }
                name="Funnel Settings"
              />
            </FormSectionBox>
          </FormContentWrapper>
        </form>
      </>
    );
  }
}

export default Messages.injectIn(AddFunnel);

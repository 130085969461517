import { apiAction } from './api';
import { RESET_DATA } from '../types';
import { RESET_DATA as API } from 'api/axios';
import { Dispatch } from 'redux';
import { ResetUpdateParams } from 'model/models';
import { IntegerValue } from 'model/integerValue';
import { DrilldownReportParams } from '../../model/drilldownReportParams';
import { DrilldownReport } from '../../model/drilldownReport';
import { DispatchResetDataSettings } from 'types/actions';

const setResetData = (payload: Number) => {
  return {
    type: RESET_DATA.SET_VALUE,
    payload
  };
};

const setSettings = (key: any, value: any) => {
  return {
    type: RESET_DATA.SET_SETTINGS,
    payload: {
      key,
      value
    }
  };
};

export const deleteData = (data: ResetUpdateParams) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => {},
      onFailure: (e: Error) => {
        throw e;
      },
      label: RESET_DATA.DELETE
    })
  );
};

const setReportingsData = (payload = {}, params = {}) => {
  return {
    type: RESET_DATA.SET_REPORTING,
    payload,
    params
  };
};

export const fetchData = (data: ResetUpdateParams) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH(data),
      onSuccess: (data: IntegerValue) => setResetData(data.value),
      onFailure: (e: Error) => {
        throw e;
      },
      label: RESET_DATA.FETCH
    })
  );
};

export const fetchReportings = (data: DrilldownReportParams) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_REPORTING(data),
      onSuccess: (response: DrilldownReport) =>
        setReportingsData(response, data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: RESET_DATA.FETCH_REPORTING
    })
  );
};

export const dispatchSetSettings: DispatchResetDataSettings = (key, value) => (
  dispatch: Dispatch
) => {
  return dispatch(setSettings(key, value));
};

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import './style.scss';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import {
  LANDER_OFFER_GROUP_MODAL,
  OFFER_GROUP_MODAL,
  LANDER_GROUP_MODAL
} from 'constants/modal';
import { FFButton, FFIcon } from 'uikit';
import { FormSectionBox } from 'components/Parts/Content';

export default class NewOfferLanderGroup extends React.Component {
  render() {
    return (
      <SidebarsConsumer>
        {({ isOpenSidebar, closeSidebar, openSidebar, getContextSidebar }: SidebarProps) => (
          <DynamicSideBar
            isOpen={isOpenSidebar(LANDER_OFFER_GROUP_MODAL)}
            onClose={() => closeSidebar(LANDER_OFFER_GROUP_MODAL)}
            zIndex={getContextSidebar(LANDER_OFFER_GROUP_MODAL).zIndex}
            defaultOpenSideBarKey="landerOfferGroup"
            dataPortalKey="landerOfferGroup"
            showCloseIcon={true}
            sideBars={[
              {
                key: 'landerOfferGroup',
                title: 'Create Offer or Lander Group',
                render: () => (
                  <div className="sb-landerOfferGroup">
                    <FormSectionBox
                      title="Create Lander Group"
                      className="sb-landerOfferGroup__landerSection"
                      titlePostfix={
                        <FFIcon
                          name="landerGroup"
                          size="bigger"
                          className="sb-landerOfferGroup__landerIcon"
                        />
                      }
                    >
                      <p className="sb-landerOfferGroup__text">
                        Lander groups are a collection of landing pages. You can reuse these in multiple funnels
												and their configuration,	such as rotation weights, will be shared across all funnels.
                      </p>
                      <FFButton
                        variant="outlined"
                        size="lg"
                        onClick={() => {
                          closeSidebar(LANDER_OFFER_GROUP_MODAL);
                          requestAnimationFrame(() =>
                            openSidebar(LANDER_GROUP_MODAL)
                          );
                        }}
                        data-testid="createLanderGroup"
                        className="sb-landerOfferGroup__button"
                      >
                        Create Lander Group
                      </FFButton>
                    </FormSectionBox>

                    <FormSectionBox
                      title="Create Offer Group"
                      className="sb-landerOfferGroup__offerSection"
                      titlePostfix={
                        <FFIcon
                          name="offerGroup"
                          size="bigger"
                          className="sb-landerOfferGroup__offerIcon"
                        />
                      }
                    >
                      <p className="sb-landerOfferGroup__text">
                        Offer groups are a collection of offer pages, much like	lander groups. These are useful for
												split-testing many related offers across multiple funnels and adjusting	rotations universally.
                      </p>
                      <FFButton
                        variant="outlined"
                        size="lg"
                        onClick={() => {
                          closeSidebar(LANDER_OFFER_GROUP_MODAL);
                          requestAnimationFrame(() =>
                            openSidebar(OFFER_GROUP_MODAL)
                          );
                        }}
                        data-testid="createOfferGroup"
                        className="sb-landerOfferGroup__button"
                      >
                        Create Offer Group
                      </FFButton>
                    </FormSectionBox>
                  </div>
                )
              }
            ]}
          />
        )}
      </SidebarsConsumer>
    );
  }
}

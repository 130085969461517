export const RequestIdleCallback = (
  callback: any,
  timeout: IdleRequestOptions = { timeout: 100 }
) => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(() => callback(), timeout!);
  } else {
    callback();
  }
};

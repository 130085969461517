import React from 'react';
import Messages from 'components/Messages';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from 'utils/reactive/generic';
import validateForm, { required, unique } from 'utils/validation';
import { Input, Label } from 'components/Parts/Inputs';
import { FormContentWrapper, FormSectionBox } from 'components/Parts/Content';
import { IconTooltip } from 'components/Parts/Tooltip';
import { FunnelGroup } from 'model/funnelGroup';
import { generateEntityId } from 'utils/id/generator';
import { AnyObject, MessageProps } from 'types';
import { redirects } from 'constants/form';
import { pregMatchAll } from '../../../utils/regexp';
import { DUPLICATE_RECORD_CODE } from '../../../constants/errors';
import { FunnelGroupFormProps } from 'types/ModalForms/funnelGroup';
import { defined } from '../../../utils/define';
import { FUNNEL_MODAL } from 'constants/modal';
import { getErrorId, handleScrollToErrorElement } from 'utils/validation';
import { removeCommaOrMultipleDot } from 'utils/string';
import { isCopyByContextModal } from 'utils/modals';
import { DrilldownReportRowAttribute } from 'model/drilldownReportRowAttribute';
import { ValidationRule } from '../../../utils/validation/types';
import {
  asyncVersionConfirmSidebar,
  getVisibilityByActiveTab
} from 'utils/dynamic-sidebar';
import { LoadingProps } from 'types/loading';
import { ModalButtonGroup } from 'components/Parts/Groups';
import {
  FUNNELGROUP_FORM_TAB,
  FUNNELGROUP_FORM_OPTIONAL_SETTINGS_TAB
} from 'constants/dynamicSidebar';
import { SidebarContext } from 'components/SideBars/context';
import { serverVersionIsAheadOfLocal, withIncrementedVersion } from 'utils/model';
import { SidebarProps } from 'types/sidebars';
import './style.scss';
import Icon from 'components/Icons';

interface State extends ReactiveValidateState {
  funnelGroupNames: string[];
  model: FunnelGroup;
  anotherModel: AnyObject;
  selects: any;
  validationErrors: AnyObject;
  isTouchedForm: boolean;
  isDataPassingOn: boolean;
  showErrorTooltip: boolean;
}

const defaultModelState: FunnelGroup = {
  idCampaign: '',
  campaignName: '',
  status: 'active',
  accumulatedUrlParams: {},
  customTokens: {}
};

const defaultState = (): State => ({
  funnelGroupNames: [],
  model: defaultModelState,
  anotherModel: {
    costPerEnt: '',
    customTokens: '',
    landerRedirect: '',
    offerRedirect: ''
  },
  selects: redirects,
  validationErrors: {},
  isTouchedForm: false,
  isDataPassingOn: false,
  showErrorTooltip: false
});

class AddFunnelGroup extends ReactiveValidateComponent<
  FunnelGroupFormProps & MessageProps & LoadingProps,
  State
> {
  state: State = defaultState();

  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  async componentDidMount() {
    this.props.setSidebarLoading!(true);
    this.setValidateSubscribe();

    if (defined(this.props.setButtonGroupProps)) {
      await this.props.setButtonGroupProps({
        showSave: true,
        saveText: isCopyByContextModal(this.props.contextModal)
          ? `DUPLICATE`
          : `SAVE`,
        onSaveClicked: this.handleSubmit,
        showCancel: true,
        cancelText: 'DISCARD'
      });
    }

    if (defined(this.props.contextModal.data)) {
      const id = this.getEntityId();
      if (id) {
        await this.props.getFunnelGroupById(id);
      }
      this.props.setSidebarLoading!(true);
      if (this.props.setForCopyIdsProps) {
        this.props.setForCopyIdsProps({
          'Funnel Group ID': id
        });
      }
      await this.setEditModel(id);
      this.props.setSidebarLoading!(false);
    }
    this.props.setSidebarLoading!(false);
  }

  getEntityId = () => this.props.contextModal.entityId!;

  validationRules = (): ValidationRule[] => [
    {
      field: 'campaignName',
      validations: [required, unique(this.state.funnelGroupNames, 'funnel group')]
    }
  ];

  setEditModel = async (id: string) => {
    const editModel = this.props.funnelGroups?.[id];

    if (defined(editModel)) {
      if (isCopyByContextModal(this.props.contextModal)) {
        editModel.campaignName = this.props.contextModal.copyName!;
        editModel.status = 'active';
      }
      await this.setState((state: State) => ({
        model: {
          ...state.model,
          ...editModel
        },
        anotherModel: {
          ...state.anotherModel,
          customTokens: this.getCustomTokens(editModel.customTokens)
        }
      }));
    }
  };

  handleSelectInputChange = (name: string, value: string) => {
    this.setState(
      (state: State) => ({
        ...state,
        model: {
          ...state.model,
          campaignName:
            name === 'campaignName' ? value : state.model.campaignName,
          defaultCost: name === 'defaultCost' ? value : state.model.defaultCost
        },
        anotherModel: {
          ...state.anotherModel,
          [name]: value
        }
      }),
      () => this.validate$.next()
    );
  };

  handleSaveAndCreate = async (e: Event) => {
    e.preventDefault();
    this.props.startLoading('saveAndCreate');
    if (await this.performCreateOrUpdate()) {
      this.props.setContextModal(FUNNEL_MODAL, {
        data: {
          idCampaign:
            !!this.props.contextModal.data &&
            !!(this.props.contextModal.data as DrilldownReportRowAttribute).id
              ? (this.props.contextModal.data as DrilldownReportRowAttribute).id
              : !!this.state.model.idCampaign
              ? this.state.model.idCampaign
              : ''
        }
      });
      await this.clearForm();
      await this.props.closeSelf();
      await this.props.openModal(FUNNEL_MODAL);
    }

    return false;
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (defined(e)) {
      e.preventDefault();
    }

    this.props.startLoading('save');
    if (await this.performCreateOrUpdate()) {
      if (defined(this.props.contextModal.data?.onSubmitGroupForm)) {
        this.props.contextModal.data.onSubmitGroupForm(
          !!this.props.contextModal.data &&
            !!(this.props.contextModal.data as DrilldownReportRowAttribute).id
            ? (this.props.contextModal.data as DrilldownReportRowAttribute).id
            : !!this.state.model.idCampaign
            ? this.state.model.idCampaign
            : ''
        );
      }

      await this.clearForm();
      this.props.closeSelf();
    }

    return false;
  };

  setCustomTokens = () => {
    const customTokens = pregMatchAll(
      this.state.anotherModel.customTokens,
      /(.*)=(.*)\n?/gim
    ).reduce(
      (acc: {}, item) =>
        !!item[1] && !!item[2] ? { ...acc, [item[1]]: item[2] } : acc,
      {}
    );

    this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        customTokens: customTokens
      }
    }));
  };

  getCustomTokens = (customTokens?: { [key: string]: string }) => {
    if (defined(customTokens)) {
      return Object.keys(customTokens)
        .map((key: string) => {
          return `${key}=${customTokens[key]}`;
        })
        .join('\n');
    }

    return '';
  };

  performCreateOrUpdate = async () => {
    await this.setTouchedForm();
    await this.validate();
    await this.setCustomTokens();
    let model = this.state.model;

    if (!Object.keys(this.state.validationErrors).length) {
      try {
        const funnelGroupID = generateEntityId();
        if (isCopyByContextModal(this.props.contextModal)) {
          await this.props.handleDuplicate({
            ...model,
            meta: { version: 1 }
          });
        } else if (!!this.state.model.idCampaign) {
          await this.props.getFunnelGroupById(model.idCampaign);
          if (
            model?.meta &&
            serverVersionIsAheadOfLocal(
              model.meta?.version,
              this.props.funnelGroups[model.idCampaign]?.meta?.version
            )
          ) {
            const sidebar = this.context as SidebarProps;
            try {
              await asyncVersionConfirmSidebar(sidebar);
              await this.props.handleUpdate({
                ...withIncrementedVersion(
                  model,
                  this.props.funnelGroups[model.idCampaign]?.meta?.version
                )
              });
            } catch (e) {
              this.props.stopLoading('all');
              this.props.closeSelf();
              return;
            }
          } else {
            await this.props.handleUpdate(model);
          }
        } else {
          await this.props.handleCreate({
            ...model,
            idCampaign: funnelGroupID,
            meta: { version: 1 }
          });
        }

        await this.props.showMessage(
          Messages.success(
            `${this.state.model.campaignName} ${
              !!this.state.model.idCampaign
                ? 'has been updated'
                : 'has been added'
            }`
          )
        );
        await this.setState((state: State) => ({
          ...state,
          model: {
            ...state.model,
            idCampaign: funnelGroupID
          }
        }));
        this.props.stopLoading('all');
      } catch (e) {
        if (
          defined(e.response) &&
          e.response.status === DUPLICATE_RECORD_CODE
        ) {
          this.setState(
            {
              funnelGroupNames: [
                ...this.state.funnelGroupNames,
                this.state.model.campaignName
              ]
            },
            this.validate
          );
        }
        this.props.showMessage(
          Messages.failed(
            `${this.state.model.campaignName} ${
              !!this.state.model.idCampaign
                ? 'cannot be updated'
                : 'cannot be added'
            }`
          )
        );
        this.props.stopLoading('all');
        return false;
      }

      return true;
    } else {
      this.props.stopLoading('all');
    }

    return false;
  };

  clearForm = () => {
    this.setState({
      ...defaultState()
    });
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({
        validationErrors: {},
        showErrorTooltip: false
      });
      const [validationErrors] = validateForm(
        this.state.model,
        this.validationRules()
      );
      this.setState((state: State) => ({
        ...state,
        validationErrors: validationErrors
      }));

      handleScrollToErrorElement(
        validationErrors,
        (entries: IntersectionObserverEntry[]) => {
          if (defined(entries[0]) && entries[0].isIntersecting) {
            this.setState({ showErrorTooltip: true });
          }
        }
      );
    }
  };

  validateClassName = (field: string) =>
    !!this.state.validationErrors[field] ? 'has-error' : '';

  validateTooltip = (field: string, idx?: string) => {
    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <form className="cform-funnelGroup" onSubmit={this.handleSubmit}>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            FUNNELGROUP_FORM_TAB,
            this.props.activeTab
          )}
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <div className="flex flex-row flex-gap-15 cform-funnelGroup--nameIdRow">
              <div
                id={getErrorId('campaignName')}
                className={`flex flex-col cform-funnelGroup--name ${this.validateClassName(
                  'campaignName'
                )}`}
              >
                <Label text={`Funnel Group Name`} htmlFor="name" />
                <Input
                  id="name"
                  name="campaignName"
                  data-testid="campaignName"
                  placeholder="Enter funnel group name here"
                  value={this.state.model.campaignName}
                  error={this.validateTooltip('campaignName')}
                  showErrorTooltip={this.state.showErrorTooltip}
                  onChange={e =>
                    this.handleSelectInputChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
            <ModalButtonGroup
              loading={this.props.loading}
              showSaveAndCreate={!isCopyByContextModal(this.props.contextModal)}
              saveAndCreateText={`Save & Create Funnel`}
              onSaveAndCreateClicked={this.handleSaveAndCreate}
            />
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          className="optional-settings"
          show={getVisibilityByActiveTab(
            FUNNELGROUP_FORM_OPTIONAL_SETTINGS_TAB,
            this.props.activeTab
          )}
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <div className="flex flex-row">
              <div className="flex flex-col cform-funnelGroup--costPerEnt">
                <Label
                  htmlFor="costPerEnt"
                  text={[
                    `Default Cost per Entrance`,
                    <IconTooltip
                      key={`costPerEnt`}
                      title="Default Cost per Entrance"
                      className="funnel-group-default-cost-tooltip"
                      body={[
                        <p>
                          If you add a value here, all funnels in this group
                          will by default inherit this when generating links,
                          unless a cost is added at a more specific level. A
                          cost is assigned to every visitor who enters a funnel
                          (basically a cost per click).
                        </p>,
                        <p>
                          When generating links for your funnel, we check for
                          default costs in the following order:
                        </p>,
                        <ul>
                          <li>Funnel Group (can only add a cost value)</li>
                          <li>Funnel (can only add a cost value)</li>
                          <li>
                            Traffic Source (can use values or tokens/text)
                          </li>
                          <li>Cost field in the link generation form</li>
                        </ul>,
                        <p>These parameters are appended to URLs with …&c=X</p>
                      ]}
                    />
                  ]}
                />
                <Input
                  id="defaultCost"
                  name="defaultCost"
                  data-testid="defaultCost"
                  placeholder="E.g. 0.01"
                  value={this.state.model.defaultCost}
                  style={{ width: 215 }}
                  onChange={e =>
                    this.handleSelectInputChange(
                      e.target.name,
                      removeCommaOrMultipleDot(e.target.value)
                    )
                  }
                />
              </div>
            </div>
          </FormSectionBox>
        </FormContentWrapper>
      </form>
    );
  }
}

export default Messages.injectIn(AddFunnelGroup);

import React from 'react';
import { Alert as AntAlert } from 'antd';
import './index.scss';
import Icon from '../../Icons';

interface AlertProps {
  text: string | React.ReactNode;
  type?: 'info' | 'success' | 'warning';
  className?: string;
}

export const Alert = ({ text, type = 'info', className = '' }: AlertProps) => (
  <>
    <AntAlert message={text} type={type} className={className} />
  </>
);

export const UnsavedFunnelAlert = () => (
  <div className="unsaved-funnel-alert" data-testid="unsaved-funnel-alert">
    <AntAlert
      message=""
      description={
        <span>
          Your funnel has unsaved changes, so this link may not work if you
          choose a node that has not yet been saved. Be sure to{' '}
          <strong>save your funnel</strong> as soon as possible.
        </span>
      }
      type="warning"
      showIcon
      icon={<Icon type="flux-information" />}
    />
  </div>
);

export const GetLinkAndJavascriptNote = () => (
  <AntAlert
    className={'funnel-note-alert'}
    message=""
    description="Use this form to generate tracking links for your funnel. You must
          pick a traffic source for the link, and you can pick any node in the
          funnel that you want to send the visitor to — the default is the
          traffic node, which exists in all funnels. Our Javascript tracking
          will be released soon."
    type="info"
    showIcon
  />
);

export const OfferSourceIframeNote = () => (
  <AntAlert
    className={'offer-source-iframe-alert'}
    message=""
    description="iFrames rely on cookies, so it is best to keep the domain used for tracking consistent end to end, especially if hit ID data cannot be dynamically inserted into the iFrame. You can manually change the domain in the above iFrame code to any of your valid tracking domains."
    type="info"
    showIcon
  />
);

export const AddPageNote = () => (
  <AntAlert
    className="add-page-note"
    message=""
    description="Select multiple pages to create a lander/offer group"
    type="info"
  />
);

interface NoteInterface {
  description: string;
  type?: 'info';
  className?: string;
}

export const Note = ({
  description,
  type = 'info',
  className
}: NoteInterface) => (
  <AntAlert
    message=""
    className={className}
    description={description}
    type={type}
  />
);

export const NoDomainAlert = () => {
  const Text = () => (
    <>
      <div className="no-domains-alert" data-testid="no-domains-alert">
        <span>
          <Icon type="flux-information" className={`no-domains-alert-icon`} />
        </span>
        <span>
          Please go to the settings &gt; domain area and configure a custom
          domain first. You will need a domain before you are able to generate
          links.
        </span>
      </div>
    </>
  );

  return <Alert text={<Text />} />;
};

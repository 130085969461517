import { quickStats as defaultState } from './defaultStates';
import { QUICKSTATS } from '../types';
import {
  LANDER_CATEGORY_TYPE,
  OFFER_CATEGORY_TYPE,
  FUNNEL_CATEGORY_TYPE,
  TRAFFIC_SOURCE_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_TYPE,
  PAGE_GROUPS_CATEGORY_TYPE,
  VISITOR_JOURNEY_CATEGORY_TYPE,
  LOCATION_CATEGORY_TYPE,
  DEVICE_DATA_CATEGORY_TYPE,
  CONNECTIVITY_CATEGORY_TYPE,
  TIME_SEGMENTATION_CATEGORY_TYPE,
  TRACKING_FIELDS_CATEGORY_TYPE,
  CAMPAIGN_ANALYSIS_CATEGORY_TYPE
} from 'constants/modal';
import { AnyAction } from 'redux';
import { defined } from 'utils/define';
import { QUICKSTATS_CATEGORY_TYPE } from 'constants/modal';

const tableCols = (state = defaultState.tableCols, action: AnyAction) => {
  if (defined(action.payload) && action.type === QUICKSTATS.SET_TABLE_COLS) {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state;
  }
};

const tableColSizes = (
  state = defaultState.tableColSizes,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.type === QUICKSTATS.SET_TABLE_COL_SIZES
  ) {
    return action.payload;
  } else {
    return state;
  }
};

const data = (
  state = defaultState.reporting,
  action: AnyAction,
  type: string
) => {
  switch (action.type) {
    case QUICKSTATS.SET_REPORTING:
      return !!action.payload[type] ? action.payload[type] : state;
    default:
      return state;
  }
};

const filters = (
  state = defaultState.settings.filters,
  action: AnyAction,
  type: string
) => {
  switch (action.type) {
    case QUICKSTATS.SET_FILTERS:
      return !!action.payload[type] ? action.payload[type] : state;
    default:
      return state;
  }
};

const sort = (state = defaultState.sortModels, action: AnyAction) => {
  if (defined(action.payload) && action.type === QUICKSTATS.SET_SORT) {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state;
  }
};

const params = (
  state = defaultState.params,
  action: AnyAction,
  type: string
) => {
  switch (action.type) {
    case QUICKSTATS.SET_PARAMS:
      return !!action.payload[type] ? action.payload[type] : state;
    default:
      return state;
  }
};

const tableParams = (
  state = defaultState.params,
  action: AnyAction,
  type: string
) => {
  switch (action.type) {
    case QUICKSTATS.SET_TABLE_PARAMS:
      return {
        ...state,
        ...(action.payload[type] || {})
      };
    default:
      return state;
  }
};

export default (state: any = defaultState, action: AnyAction) => {
  if (action.type === QUICKSTATS.RESET) {
    return {
      ...state,
      tableCols: {},
      [action.payload.type]: defaultState
    };
  }
  return [
    OFFER_CATEGORY_TYPE,
    LANDER_CATEGORY_TYPE,
    TRAFFIC_SOURCE_CATEGORY_TYPE,
    FUNNEL_CATEGORY_TYPE,
    QUICKSTATS_CATEGORY_TYPE,
    OFFER_SOURCE_CATEGORY_TYPE,
    PAGE_GROUPS_CATEGORY_TYPE,
    VISITOR_JOURNEY_CATEGORY_TYPE,
    LOCATION_CATEGORY_TYPE,
    DEVICE_DATA_CATEGORY_TYPE,
    CONNECTIVITY_CATEGORY_TYPE,
    TIME_SEGMENTATION_CATEGORY_TYPE,
    TRACKING_FIELDS_CATEGORY_TYPE,
    CAMPAIGN_ANALYSIS_CATEGORY_TYPE
  ].reduce((acc, type) => {
    return {
      ...acc,
      ...{
        tableCols: tableCols(state.tableCols, action),
        sortModels: sort(state.sortModels, action),
        tableColSizes: tableColSizes(state.tableColSizes, action),
        [type]: {
          settings: {
            show: 'not-deleted',
            filters: filters(
              defined(state[type]?.settings)
                ? state[type].settings.filters
                : defaultState.settings.filters,
              action,
              type
            )
          },
          reporting: data(
            defined(state[type]?.reporting)
              ? state[type].reporting
              : defaultState.reporting,
            action,
            type
          ),
          params: params(
            defined(state[type]?.params)
              ? state[type].params
              : defaultState.params,
            action,
            type
          ),
          tableParams: tableParams(
            defined(state[type]?.tableParams)
              ? state[type].tableParams
              : defaultState.tableParams,
            action,
            type
          )
        }
      }
    };
  }, {});
};

import React from 'react';
import { ModalButtonGroup } from '../../../Parts/Groups';
import { FunnelConnection } from '../../../../model/funnelConnection';
import { Label, Select } from '../../../Parts/Inputs';
import { ReactiveComponent } from '../../../../utils/reactive';
import { FunnelCondition } from '../../../../model/funnelCondition';
import { defined } from '../../../../utils/define';
import { FunnelConditionRoute } from '../../../../model/funnelConditionRoute';
import { AnyObject } from '../../../../types';
import {
  getConnectionsWithRouteNumber,
  getFreeConditionRouteNumber,
  getFreeConditionRouteNumbers
} from '../../../../utils/builder';
import { RequestIdleCallback } from '../../../../utils/performance';

interface State {
  connection?: FunnelConnection;
  connections?: FunnelConnection[];
  routes: FunnelConditionRoute[];
}

interface Props {
  connections: FunnelConnection[];
  connection: FunnelConnection;
  condition: FunnelCondition[];
  onClose: Function;
  availableRoutes?: boolean;
  onChange(connections: FunnelConnection[]): void;
}

export default class ModifyRoute extends ReactiveComponent {
  state: State = {
    routes: []
  };

  static defaultProps = {
    connections: []
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (!!props.connection && !!props.connections && !state.connection) {
      return {
        connection: props.connection,
        connections: props.connections
      };
    }

    return null;
  }

  async componentDidMount() {
    if (
      defined(this.state.connections) &&
      defined(this.state.connection) &&
      !!this.props.condition &&
      !!this.props.condition.routes
    ) {
      const connections = this.state.connections.filter(
        item => this.state.connection!.idSourceNode === item.idSourceNode
      );
      const routeNumbers = getFreeConditionRouteNumbers(
        connections,
        this.props.condition
      );

      const routes = this.props.condition.routes.map(
        (item: FunnelConditionRoute, id: number) => ({
          ...item,
          idRoute: String(id)
        })
      );

      await this.setState({
        routes: defined(this.props.availableRoutes)
          ? routes.filter((route: FunnelConditionRoute, idx: number) =>
              routeNumbers.includes(idx)
            )
          : routes
      });

      if (
        !defined(this.state.connection.connectionConditionParams) ||
        !defined(this.state.connection.connectionConditionParams.onRouteNumber)
      ) {
        const routeNumber = getFreeConditionRouteNumber(
          connections,
          this.props.condition
        );

        await this.setState((state: State) => ({
          ...state,
          connection: {
            ...state.connection,
            connectionConditionParams: {
              ...state.connection!.connectionConditionParams,
              onRouteNumber: routeNumber
            }
          }
        }));

        await this.setConnectionsNumber(routeNumber);
        RequestIdleCallback(() => {
          this.props.onChange(this.state.connections);
        });
      }
    }
  }

  handleSelectChange = async (value: number) => {
    await this.setConnectionsNumber(value);

    await this.setState((state: State) => ({
      ...state,
      connection: {
        ...state.connection,
        connectionConditionParams: {
          ...state.connection!.connectionConditionParams,
          onRouteNumber: value
        }
      }
    }));

    this.props.onChange(this.state.connections);
  };

  setConnectionsNumber = async (value: number) => {
    if (!!this.state.connections) {
      let connections = getConnectionsWithRouteNumber(
        this.state.connections,
        this.state.connection as FunnelConnection,
        value
      );

      connections = connections.map((item: FunnelConnection) => {
        if (
          this.state.connection!.idSourceNode === item.idSourceNode &&
          item.idConnection !== this.state.connection!.idConnection &&
          defined(item.connectionConditionParams) &&
          defined(item.connectionConditionParams.onRouteNumber) &&
          Number(value) === Number(item.connectionConditionParams.onRouteNumber)
        ) {
          item.connectionConditionParams.onRouteNumber = getFreeConditionRouteNumber(
            this.state.connections || [],
            this.props.condition
          );
        }

        return item;
      });

      this.setState({ connections: connections });
    }
  };

  render() {
    return (
      <form className="flex flex-col padding-x-25 padding-y-20 flex-gap-10">
        {this.state.routes.length > 0 &&
          defined(
            this.state.connection?.connectionConditionParams?.onRouteNumber
          ) && (
            <div>
              <Label htmlFor="select-action" text="Select Action:" />
              <Select
                value={String(
                  this.state.connection?.connectionConditionParams
                    ?.onRouteNumber
                )}
                id="select-action"
                name="select-action"
                placeholder={'Select action'}
                style={{ width: 214 }}
                options={this.state.routes}
                valueGetter={(option: AnyObject) => option.idRoute}
                labelGetter={(option: AnyObject) => option.routeName}
                onChange={(value: number) =>
                  this.handleSelectChange(Number(value))
                }
              />
            </div>
          )}
        <ModalButtonGroup
          showCancel
          cancelText={'Close'}
          onCancelClicked={this.props.onClose}
        />
      </form>
    );
  }
}

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="jar-2--art-hobby-amphora-museum-event-entertainment-vase-travel-places">
      <path
        id="Vector"
        fill="#ffffff"
        d="m13.935059721428571 13.934999999999999 -4.976785714285715 3.9814285714285713H4.130274278571428c-0.07061063571428572 0.3419051785714286 -0.10899160714285713 0.6750711214285715 -0.10899160714285713 0.9953571428571428 0 4.976785714285715 4.936971428571429 7.962857142857143 4.936971428571429 7.962857142857143h9.95357142857143S23.916441428571428 23.888571428571428 23.916441428571428 18.911785714285713c0 -0.32028602142857143 -0.038818928571428574 -0.6534519642857143 -0.1100865 -0.9953571428571428H18.911845435714287l-4.976785714285715 -3.9814285714285713Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Subtract"
        fill="#d7e0ff"
        fillRule="evenodd"
        d="M9.95363115 0.9953571428571428v7.962857142857143S4.937329757142857 14.008158749999998 4.130274278571428 17.916428571428572h4.828019635714285l4.976785714285715 -3.9814285714285713 4.976785714285715 3.9814285714285713h4.894489585714286c-0.8163919285714286 -3.908269821428571 -5.889866635714286 -8.958214285714286 -5.889866635714286 -8.958214285714286v-7.962857142857143h-7.962857142857143Z"
        clipRule="evenodd"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 0.9953571428571428h11.944285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.976785714285715 17.916428571428572h3.9814285714285713L13.934999999999999 13.934999999999999l4.976785714285715 3.9814285714285713h3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 21.89785714285714c0.5497158428571428 0 0.9953571428571428 -0.44572092857142853 0.9953571428571428 -0.9953571428571428S14.484715842857142 19.90714285714286 13.934999999999999 19.90714285714286s-0.9953571428571428 0.44572092857142853 -0.9953571428571428 0.9953571428571428 0.4456413 0.9953571428571428 0.9953571428571428 0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.95357142857143 0.9953571428571428v7.962857142857143m7.962857142857143 0v-7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Ellipse 50"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.953611242857143 8.958214285714286s-5.932328571428571 5.972142857142857 -5.932328571428571 9.95357142857143c0 4.976785714285715 4.936971428571429 7.962857142857143 4.936971428571429 7.962857142857143h9.95357142857143S23.916441428571428 23.888571428571428 23.916441428571428 18.911785714285713c0 -3.9814285714285713 -5.9999730428571425 -9.95357142857143 -5.9999730428571425 -9.95357142857143"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

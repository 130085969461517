import { User } from '../../model/user';
import { isDevEnvironment } from '../../utils/app';
import { RequestIdleCallback } from '../../utils/performance';

declare global {
  interface Window {
    segmentIsLoaded: boolean;
  }
}

const waitForSegmentLoad = () =>
  new Promise(res => {
    if (window.segmentIsLoaded) {
      res(true);
    } else {
      const intv = setInterval(() => {
        if (window.segmentIsLoaded) {
          res(true);
          clearInterval(intv);
        }
      }, 700);
    }
  });

export const identifySegment = async (user: User) => {
  if (!isDevEnvironment) {
    await waitForSegmentLoad();
    RequestIdleCallback(() => {
      analytics.identify(user.userId, {
        plan: user?.metadata?.plan_id,
        name: user?.metadata?.name,
        user_type: 'user',
        email: user.email
      });
    });
  }
};

export const pageSegment = async (category: string, page: string) => {
  await waitForSegmentLoad();
  if (!isDevEnvironment) {
    RequestIdleCallback(() => {
      analytics.page(category, page);
    });
  }
};

export const trackSegment = async (event: string, properties?: object) => {
  await waitForSegmentLoad();
  if (!isDevEnvironment) {
    RequestIdleCallback(() => analytics.track(event, properties));
  }
};

export const resetSegment = async () => {
  await waitForSegmentLoad();
  analytics.reset();
};

export const loadSegmentScript = () => {
  const env =
    window.location.hostname === 'ui.funnelflux.pro' ? 'production' : 'staging';
  const isProduction = env === 'production';

  const SEGMENT_APP_ID = isProduction
    ? 'nYY4zNhRmvaBGGqJKZaFt4DqiI2ordnB'
    : 'TB8uzKLxRdVqc0xwDnlFzQsIcbWeRU1R';

  (function () {
    let analytics: any = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on'
        ];
        analytics.factory = function (t: any) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t: any, e: any) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            t +
            '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          n.addEventListener(
            'load',
            function (e) {
              window.segmentIsLoaded = true;
            },
            !1
          );
          //@ts-ignore
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(SEGMENT_APP_ID);
        //analytics.page();
      }
  })();
};

import React, { createRef } from 'react';
import './style.scss';
import { Input, Label, Select } from '../../../Parts/Inputs';
import { FunnelNode } from '../../../../model/funnelNode';
import EntranceLink from '../EntranceLink';
import Messages from 'components/Messages';
import { Checkbox, SwitchButton } from '../../../Parts/Buttons';
import Icon from '../../../Icons';
import { defined, definedObject } from 'utils/define';
import { Page } from '../../../../model/page';
import {
  AnyObject,
  MessageProps,
  OptionCategory,
  SelectOption
} from '../../../../types';
import { addCategory, addCategoryToOptions } from '../../../../utils/select';
import GetActionLink from '../GetActionLink';
import { IconTooltip } from '../../../Parts/Tooltip';
import { decodeURLParams } from '../../../../utils/url';
import { PageGroup } from '../../../../model/pageGroup';
import { PageGroupEntry } from '../../../../model/pageGroupEntry';
import { generateEntityId } from '../../../../utils/id/generator';
import { redirects } from '../../../../constants/form';
import { FunnelNodePageParams } from '../../../../model/funnelNodePageParams';
import {
  extraUrlParams,
  getErrorId,
  handleScrollToErrorElement
} from '../../../../utils/validation';
import { getDefaultPageOverrides } from '../../../../constants/builder';
import { DUPLICATE_RECORD_CODE } from '../../../../constants/errors';
import { ReactiveValidateComponent } from '../../../../utils/reactive/generic';
import validateForm, { required } from 'utils/validation';
import { equalObjects } from '../../../../utils/object';
import { LoadingProps } from '../../../../types/loading';
import { withLoading } from '../../../Loading';
import { RequestIdleCallback } from 'utils/performance';
import { isCopyByContextModal } from 'utils/modals';
import { getPageOverridesFunnelNode } from 'utils/builder';
import {
  isPageModel,
  getActiveEntities,
  withIncrementedVersion,
  serverVersionIsAheadOfLocal
} from 'utils/model';
import { ValidationRule } from '../../../../utils/validation/types';
import { customDuplicateError } from '../../../../utils/error/index';
import { SidebarContext } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import {
  asyncVersionConfirmSidebar,
  getVisibilityByActiveTab
} from 'utils/dynamic-sidebar';
import { FormContentWrapper, FormSectionBox } from 'components/Parts/Content';
import { PageGroupFormProps } from 'types/ModalForms/pageGroup';
import { ModalButtonGroup } from 'components/Parts/Groups';
import {
  PAGEGROUP_FORM_TAB,
  PAGEGROUP_FORM_ADDITIONAL_SETTINGS_TAB,
  PAGEGROUP_FORM_ACTION_LINKS_TAB,
  PAGEGROUP_FORM_REDIRECT_LINKS_TAB,
  PAGEGROUP_FORM_DIRECT_LINKS_TAB,
  PAGEGROUP_FORM_JAVASCRIPT_TRACKING_TAB
} from 'constants/dynamicSidebar';
import PageGroupPageSlider from 'components/PageGroupPageSlider';
import { getDefaultDomain } from 'utils/settings';
import { DomainEntry } from 'model/models';
import CodeSnippet from 'components/CodeSnippet';
import { FFInput } from 'uikit';

interface State {
  pages: PageGroupEntry[];
  oldPages: PageGroupEntry[];
  idPage: string;
  additionalSettingsIdPage: string;
  routing?: PageGroup.RoutingEnum;
  funnelNode?: FunnelNode;
  dragEnter?: string;
  dragLeave?: string;
  groupName: string;
  customUrlString: {
    [key: string]: string;
  };
  pageOverrides: {
    [key: string]: FunnelNodePageParams;
  };
  pageOverridesSwitch: {
    [key: string]: {
      redirectOverride: boolean;
      additionalTokens: boolean;
    };
  };
  group: PageGroup;
  isNewPageGroup: boolean;
  groupNames: string[];
  isTouchedForm: boolean;
  showErrorTooltip: boolean;
  hasEditedName: boolean;
  validationErrors: AnyObject;
  additionalSettingsIsExpanded: boolean;
  settingsIsExpanded: boolean;
  inputMouseDown: { [key: number]: boolean };
  renderEntranceLink: boolean;
  domain: string;
  jsDefaultParamsIdPage: string;
}

const TEXT = [
  'When ',
  <strong key="bold-text">map incoming actions to page number</strong>,
  ' is active you cannot link directly to this group, as actions coming from previous nodes are needed to determine the destination page. You can however directly link to an internal page of the group'
];

class GroupNodeSettings extends ReactiveValidateComponent<
  PageGroupFormProps & MessageProps & LoadingProps,
  State
> {
  state: State = {
    customUrlString: {},
    pageOverrides: {},
    oldPages: [],
    pages: [],
    additionalSettingsIdPage: '',
    idPage: '',
    groupName: '',
    dragEnter: undefined,
    dragLeave: undefined,
    group: {} as PageGroup,
    isNewPageGroup: false,
    groupNames: [],
    validationErrors: {},
    isTouchedForm: false,
    showErrorTooltip: false,
    hasEditedName: false,
    additionalSettingsIsExpanded: false,
    settingsIsExpanded: !this.props.shouldOpenLinkSection,
    pageOverridesSwitch: {},
    inputMouseDown: {},
    renderEntranceLink: false,
    domain: '',
    jsDefaultParamsIdPage: ''
  };

  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;
  elRefs = {
    jsTrackingHeader: createRef<HTMLDivElement>(),
    defaultParams: createRef<HTMLDivElement>(),
    genericViewSingle: createRef<HTMLDivElement>(),
  };

  setSidebarLoading = (loading: boolean) => {
    if (defined(this.props.setSidebarLoading)) {
      this.props.setSidebarLoading!(loading);
    }
  };

  async componentDidMount() {
    this.setSidebarLoading(true);
    const id =
      this.props.contextModal?.entityId ||
      this.props.funnelNode?.nodePageGroupParams?.idPageGroup!;

    if (defined(this.props.setButtonGroupProps)) {
      if (!this.props.isInBuilderForm) {
        this.props.setButtonGroupProps({
          showOk: true,
          showCancel: true,
          okText: 'SAVE',
          cancelText: 'DISCARD',
          onOkClicked: this.handleSubmit,
          additionalClass: 'settings-buttons'
        });
      } else {
        this.props.setButtonGroupProps({
          showOk: true,
          showCancel: true,
          okText: 'SAVE',
          cancelText: 'DISCARD',
          onOkClicked: this.handleSubmit,
          additionalClass: 'settings-buttons'
        });
      }
    }

    this.setForCopyIdsProps(id);
    await this.toggleLinksJavascriptTabVisibility();

    try {
      if (id) {
        await this.props.getPageGroupById(id);
        if (this.props.setOpenedAssets) {
          await this.props.setOpenedAssets(id, 'pageGroup');
        }
        await this.handleSetState('isNewPageGroup', false);
      } else {
        await this.handleSetState('isNewPageGroup', true);
      }
    } catch (e) {
      await this.handleSetState('isNewPageGroup', true);
    }

    let pageGroup = this.getPageGroup();
    const pages = pageGroup?.pages || [];
    const pageOverridesData = getPageOverridesFunnelNode(
      pages,
      this.props.funnelNode!
    );

    if (!this.props.isInBuilderForm) {
      if (
        defined(this.props.contextModal?.entityId) &&
        defined(this.getPageGroup())
      ) {
        if (isCopyByContextModal(this.props.contextModal!)) {
          pageGroup = {
            ...(pageGroup! || {}),
            pageGroupName: this.props.contextModal?.copyName || '',
            status: 'active'
          };
        }
      }

      this.setState(state => ({
        ...state,
        group: defined(pageGroup)
          ? pageGroup
          : ({ status: 'active' } as PageGroup),
        groupName: pageGroup?.pageGroupName!,
        idPageGroup: pageGroup?.idPageGroup,
        oldPages: pages,
        pages: pages,
        routing: pageGroup?.routing || 'rotator',
        pageOverrides: pageOverridesData?.params,
        customUrlString: pageOverridesData?.customUrlStrings,
        pageOverridesSwitch: pageOverridesData?.switchs,
        additionalSettingsIdPage: pages.length > 0 ? pages[0].idPage : ''
      }));
    } else {
      this.setState(state => ({
        ...state,
        group: pageGroup!,
        oldPages: pages,
        pages: pages,
        routing: pageGroup?.routing || 'rotator',
        funnelNode: this.props.funnelNode,
        groupName: this.props.funnelNode?.nodeName || '',
        pageOverrides: pageOverridesData?.params,
        customUrlString: pageOverridesData?.customUrlStrings,
        pageOverridesSwitch: pageOverridesData?.switchs,
        additionalSettingsIdPage: pages.length > 0 ? pages[0].idPage : ''
      }));
    }

    document.addEventListener('mouseup', this.resetInputMouseDown);
    if (this.props.type === 'landerGroup') {
      await this.props.fetchLandersInfo();
    } else {
      await this.props.fetchOffersInfo();
    }
    this.setSidebarLoading(false);

    if (this.props.isInBuilderForm) {
      await Promise.all([
        this.props.fetchTrafficSources(),
        this.props.fetchDomains(),
        this.props.fetchSystemSettings()
      ]);
    }

    const defaultDomain = getDefaultDomain(
      this.props.systemSettings?.data,
      this.props.systemSettings?.domains
    );
    await this.handleSetState('domain', defaultDomain);
    if (this.state.pages.length) {
      this.handleSetState(
        'jsDefaultParamsIdPage',
        this.getPagesOfGroup()?.[0]?.idPage
      );
    }
  }

  resetInputMouseDown = () => this.setState({ inputMouseDown: {} });

  getPageGroup = () => {
    const id =
      this.props.contextModal?.entityId ||
      this.props.funnelNode?.nodePageGroupParams?.idPageGroup!;
    let pageGroup =
      this.props.data.pageGroupsArray?.[id] || this.props.pageGroupsArray?.[id];

    if (
      !pageGroup?.pages?.length &&
      defined(this.props.pageGroupsArray?.[id]) &&
      this.props.pageGroupsArray?.[id]?.pages.length
    ) {
      pageGroup.pages = this.props.pageGroupsArray?.[id].pages || [];
    }
    if (this.props.data.pageGroupsArray?.[id]?.restrictToFunnelId) {
      pageGroup.restrictToFunnelId = this.props.data.pageGroupsArray?.[
        id
      ].restrictToFunnelId;
    }
    return pageGroup;
  };

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.resetInputMouseDown);
  }

  async componentDidUpdate(prevProps: PageGroupFormProps, prevState: State) {
    if (
      (this.props.shouldOpenLinkSection !== prevProps.shouldOpenLinkSection ||
        (defined(this.props.funnelNode?.idNode) &&
          defined(prevProps.funnelNode?.idNode) &&
          this.props.funnelNode?.idNode !== prevProps?.funnelNode?.idNode)) &&
      this.props.shouldOpenLinkSection
    ) {
      this.setState((state: State) => ({
        ...state,
        settingsIsExpanded: false,
        additionalSettingsIsExpanded: false
      }));
    }

    if (this.props.activeTab !== prevProps.activeTab) {
      await this.toggleLinksJavascriptTabVisibility();
    }
  }

  toggleLinksJavascriptTabVisibility = async () => {
    await this.handleSetState('renderEntranceLink', false);
    await this.handleSetState(
      'renderEntranceLink',
      getVisibilityByActiveTab(
        [PAGEGROUP_FORM_REDIRECT_LINKS_TAB, PAGEGROUP_FORM_DIRECT_LINKS_TAB],
        this.props.activeTab
      )
    );
  };

  handleSetState = async <T extends State, P extends keyof T>(
    name: P,
    value: T[P]
  ) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  setForCopyIdsProps = (groupId: string) => {
    if (this.props.setForCopyIdsProps) {
      this.props.setForCopyIdsProps({
        'Node ID': this.props.funnelNode?.idNode!,
        'Page Group ID': groupId
      });
    }
  };

  getPageGroupModel = (): PageGroup => {
    return {
      ...this.state.group,
      pageGroupName: this.state.groupName || '',
      routing: this.state.routing!,
      pages: this.state.pages,
      pageType: this.props.type === 'landerGroup' ? 'lander' : 'offer'
    };
  };

  checkAndSetIdPageGroup = async () => {
    if (!this.state.group?.idPageGroup) {
      await this.handleSetState('group', {
        ...this.state.group,
        idPageGroup:
          this.props.funnelNode?.nodePageGroupParams?.idPageGroup ||
          generateEntityId()
      });
    }
  };

  handleSubmit = async () => {
    await this.setTouchedForm();
    await this.validate()
    await this.checkAndSetIdPageGroup();
    const model = this.getPageGroupModel();
    const shouldSendRequest = this.props.isInBuilderForm
      ? !equalObjects(model, this.getPageGroup()!)
      : true;

    if (Object.keys(this.state.validationErrors).length) return;

    this.props.startLoading('ok');
    if (this.state.pages.length === 0) {
      this.props.showMessage(
        Messages.failed('Page groups must have at least one page added')
      );
      this.props.stopLoading('all');
    } else {
      if (!shouldSendRequest) {
        this.onSavePageOverrides(true);
        this.props.stopLoading('all');
        return true;
      }
      const pageGroupName = this.state.groupName || this.getGroupName();
      this.props.startLoading('ok');

      if (!(await this.onSavePageOverrides())) {
        this.props.stopLoading('all');
        return false;
      }

      try {
        if (defined(this.props.findGroupByName) && this.state.isNewPageGroup) {
          await this.props.findGroupByName(
            {
              name: model.pageGroupName,
              idFunnel: this.state?.group?.restrictToFunnelId
            },
            customDuplicateError(
              DUPLICATE_RECORD_CODE,
              'An Entity with same name exists'
            )
          );
        }

        if (
          isCopyByContextModal(this.props.contextModal!) &&
          defined(this.props.handleDuplicate)
        ) {
          await this.props.handleDuplicate({
            ...model,
            meta: { version: 1 }
          });
        } else {
          if (this.state.isNewPageGroup && defined(this.props.handleCreate)) {
            await this.props.handleCreate({
              ...model,
              meta: { version: 1 }
            });
          } else {
            await this.props.getPageGroupById(model.idPageGroup);
            if (
              model?.meta &&
              serverVersionIsAheadOfLocal(
                model?.meta?.version,
                this.props.pageGroupsArray[model.idPageGroup]?.meta?.version
              )
            ) {
              const sidebar = this.context as SidebarProps;
              try {
                await asyncVersionConfirmSidebar(sidebar);
                await this.props.handleUpdate(
                  withIncrementedVersion(
                    model,
                    this.props.pageGroupsArray[model.idPageGroup]?.meta?.version
                  )
                );
              } catch (e) {
                this.props.stopLoading!('all');
                this.props.onClose();
                return;
              }
            } else {
              await this.props.handleUpdate(withIncrementedVersion(model));
            }
          }
        }

        if (!this.props.isInBuilderForm) {
          this.props.showMessage(
            Messages.success(
              `${model.pageGroupName} ${
                !this.state.isNewPageGroup
                  ? 'has been updated'
                  : 'has been added'
              }`
            )
          );
        }

        this.props.stopLoading('all');
        await this.props.onClose();
      } catch (e) {
        this.props.stopLoading('all');
        if (
          defined(e?.response?.status) &&
          e.response.status === DUPLICATE_RECORD_CODE
        ) {
          this.setState(
            {
              groupNames: [...this.state.groupNames, pageGroupName]
            },
            this.validate
          );
        }
        this.props.showMessage(
          Messages.failed(
            `${pageGroupName} ${
              !this.state.isNewPageGroup
                ? 'cannot be updated'
                : 'cannot be added'
            }, ${e?.response?.data?.message || ''}`
          )
        );
        return false;
      }

      return true;
    }
  };

  validationRules = (): ValidationRule[] => [
    {
      field: 'pageGroupName',
      validations: [required]
    }
  ];

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {}, showErrorTooltip: false });
      const [validationErrors] = validateForm(
        this.getPageGroupModel(),
        this.validationRules()
      );
      this.setState((state: State) => ({
        ...state,
        validationErrors
      }));

      handleScrollToErrorElement(
        validationErrors,
        (entries: IntersectionObserverEntry[]) => {
          if (entries?.[0]?.isIntersecting) {
            this.setState({ showErrorTooltip: true });
          }
        }
      );
    }
  };

  validateClassName = (field: string, idx?: string) => {
    return idx !== undefined &&
      !!this.state.validationErrors[idx] &&
      !!this.state.validationErrors[idx][field]
      ? 'has-error'
      : idx === undefined && !!this.state.validationErrors[field]
      ? 'has-error'
      : '';
  };

  validateTooltip = (field: string, idx?: string) => {
    return idx !== undefined &&
      !!this.state.validationErrors[idx] &&
      !!this.state.validationErrors[idx][field]
      ? this.state.validationErrors[idx][field].join(', ')
      : idx === undefined && !!this.state.validationErrors[field]
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  handleReset = async () => {
    this.setState((state: State) => ({
      ...state,
      pages: this.state.pages.map(item => ({ ...item, weight: 1 }))
    }));
  };

  onSwapPage = async (idPage: string, newIdPage: string) => {
    await this.setState({
      pages: this.state.pages.map(item =>
        item.idPage === idPage ? { ...item, idPage: newIdPage } : item
      )
    });
  };

  getName = (item: PageGroupEntry) => {
    let page = {} as Page;

    if (
      this.props.type === 'landerGroup'
        ? definedObject(this.props.data.allLanders)
        : definedObject(this.props.data.allOffers)
    ) {
      page =
        this.props.type === 'landerGroup'
          ? this.props.data.allLanders?.[item.idPage]!
          : this.props.data!.allOffers?.[item.idPage]!;
    }

    if (!defined(page) || !defined(page.idPage)) {
      page = this.props.pages.find(current => current.idPage === item.idPage)!;
    }

    return defined(page)
      ? `${page.pageName}${page.status === 'deleted' ? ' - deleted' : ''}`
      : '';
  };

  add = async (idPage: string) => {
    if (this.state.idPage !== '') {
      const page: PageGroupEntry = {
        idPage: this.state.idPage,
        weight: 1
        //status: 'active'
      };

      await this.setState({
        pages: [...this.state.pages, page],
        idPage: ''
      });

      if (!this.state.additionalSettingsIdPage) {
        await this.changePageOverrides('idPage', idPage, false);
      }

      RequestIdleCallback(async () => {
        await this.setGroupName(page);
      });
    }
  };

  setGroupName = async (page?: PageGroupEntry | Page) => {
    if (!this.props.funnelNode) return;
    if (this.state.isNewPageGroup && !this.state.hasEditedName) {
      const hasPages = this.state.pages.length > 1;
      let groupName = '';
      if (hasPages) {
        groupName = await this.getGroupName(true);
      } else if (defined(page)) {
        if (isPageModel(page)) {
          groupName = (page as Page).pageName;
        } else {
          groupName = this.getName(page as PageGroupEntry);
        }
      } else {
        groupName = this.getName(this.state.pages[0]);
      }
      this.setState((_: State) => ({
        groupName
      }));
    }
  };

  switch = async (idPage: string, status: boolean) => {
    await this.setState(state => ({
      pages: state.pages.map(item =>
        item.idPage === idPage
          ? { ...item, status: status ? 'active' : 'archived' }
          : item
      )
    }));
  };

  getGroupName = (formNewName: boolean = false) => {
    if (!this.props.funnelNode) return this.state.groupName;
    
    if (!formNewName && this.state.hasEditedName) {
      return this.state.groupName;
    } else if (!formNewName && !!this.state.groupName) {
      return this.state.groupName;
    } else if (!defined(this.props.funnelNode)) {
      return this.state.groupName;
    }

    const groupName = `${
      this.props.type === 'offerGroup' ? 'Offer' : 'Lander'
    }${this.state.pages.length > 1 ? ' Group' : ''}`;

    if (this.state.groupName !== groupName && !this.state.hasEditedName) {
      this.setState({ groupName: groupName });
    }

    return groupName;
  };

  getSelectTitle = (aFlag: boolean) => {
    return this.props.type === 'offerGroup'
      ? `Select${aFlag ? ' a' : ''} offer`
      : `Select${aFlag ? ' a' : ''} lander`;
  };

  getSelectLabel = () => {
    return this.props.type === 'offerGroup' ? (
      <label className="cform-landerOfferGroup--selectPage--offerLabel">
        <Icon type="flux-offerGroup" />
        Add Offer
      </label>
    ) : (
      <label className="cform-landerOfferGroup--selectPage--landerLabel">
        <Icon type="flux-landerGroup" />
        Add Lander
      </label>
    );
  };

  changeMap = (event: AnyObject) => {
    this.setState({
      routing: event.target.checked ? 'actionMapped' : 'rotator'
    });
  };

  isActionMapped = () => this.state.routing === 'actionMapped';

  changePageOverrides = async (
    field: string,
    value: boolean | String | any[],
    onChange = true
  ) => {
    if (field === 'idPage') {
      const id = value as string;
      const data = this.additionalSettingsValueGetter(id);
      await this.setState((state: State) => ({
        ...state,
        additionalSettingsIdPage: value as string,
        customUrlString: {
          ...state.customUrlString,
          [id]: !data.customUrlString ? '' : data.customUrlString
        },
        pageOverridesSwitch: {
          ...state.pageOverridesSwitch,
          [id]: !data.pageOverrideSwitch
            ? {
                additionalTokens: false,
                redirectOverride: false
              }
            : data.pageOverrideSwitch
        },
        pageOverrides: {
          ...state.pageOverrides,
          [id]: !data.pageOverride
            ? getDefaultPageOverrides(id)
            : data.pageOverride
        }
      }));
    } else {
      await this.setState((state: State) => ({
        ...state,
        pageOverrides: {
          ...state.pageOverrides,
          [state.additionalSettingsIdPage]: {
            ...this.additionalSettingsValueGetter().pageOverride,
            [field]: value
          }
        }
      }));
    }

    if (field === 'redirectOverride') {
      this.pageOverridesValidation();
    }
  };

  getEntranceLinkPages = () => {
    const pages: OptionCategory[] = [];
    if (!this.isActionMapped()) {
      pages.push({
        id: '-1',
        name: `${this.props.pagesName} Group (Rotator)`,
        category: ''
      });
    }
    getActiveEntities(this.state.pages).forEach(item => {
      const page = this.props.pages.find(item2 => item2.idPage === item.idPage);
      if (defined(page)) {
        pages.push({
          id: page.idPage,
          name: page.pageName,
          category: page.idCategory || '',
          data: {
            url: page.baseURL
          }
        });
      }
    });

    return addCategoryToOptions(pages, this.props.categories);
  };

  changeSwitch = (field: 'additionalTokens' | 'redirectOverride') => {
    this.setState((state: State) => ({
      ...state,
      pageOverridesSwitch: {
        ...state.pageOverridesSwitch,
        [state.additionalSettingsIdPage]: {
          ...this.additionalSettingsValueGetter().pageOverrideSwitch,
          [field]: !this.additionalSettingsValueGetter().pageOverrideSwitch[
            field
          ]
        }
      },
      validationErrors: {
        ...state.validationErrors,
        [field === 'additionalTokens' ? 'customUrlString' : field]: false
      }
    }));
  };

  changeCustomUrlString = async (value: string) => {
    await this.setState(
      (state: State) => ({
        ...state,
        customUrlString: {
          ...state.customUrlString,
          [state.additionalSettingsIdPage]: value
        }
      }),
      this.pageOverridesValidation
    );
  };

  pageOverridesValidationRules = (): ValidationRule[] => [
    {
      field: 'redirectOverride',
      validations: this.additionalSettingsValueGetter().pageOverrideSwitch
        .redirectOverride
        ? [required]
        : []
    },
    {
      field: 'customUrlString',
      validations: this.additionalSettingsValueGetter().pageOverrideSwitch
        .additionalTokens
        ? [required, extraUrlParams]
        : []
    }
  ];

  pageOverridesValidation = async () => {
    await this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [this.state.additionalSettingsIdPage]: {
          customUrlString: false,
          redirectOverride: false
        }
      }
    });

    const [validationErrors] = validateForm(
      {
        customUrlString: this.additionalSettingsValueGetter().customUrlString,
        redirectOverride: this.additionalSettingsValueGetter().pageOverride
          .redirectOverride
      },
      this.pageOverridesValidationRules()
    );

    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [this.state.additionalSettingsIdPage]: validationErrors
      }
    });
  };

  isPageOverridesValid = () =>
    !this.state.validationErrors[this.state.additionalSettingsIdPage] ||
    (typeof this.state.validationErrors[this.state.additionalSettingsIdPage] ===
      'object' &&
      !Object.keys(
        this.state.validationErrors[this.state.additionalSettingsIdPage]
      ).length);

  onSavePageOverrides = async (closeModal = false) => {
    if (!this.props.isInBuilderForm) {
      return true;
    }

    await this.setTouchedForm();
    const node = { ...this.props.funnelNode } as FunnelNode;
    await this.pageOverridesValidation();

    if (!!node && this.isPageOverridesValid()) {
      const pageOverrides = Object.values(this.state.pageOverrides).map(
        item => {
          const data = this.additionalSettingsValueGetter(item.idPage);
          return {
            ...item,
            additionalTokens:
              data.customUrlString !== '' &&
              data.pageOverrideSwitch.additionalTokens
                ? decodeURLParams(data.customUrlString)
                : undefined,
            accumulateUrlParams: data.pageOverride.accumulateUrlParams,
            redirectOverride: data.pageOverrideSwitch.redirectOverride
              ? data.pageOverride.redirectOverride
              : undefined
          };
        }
      );

      if (defined(this.props.onPageOverridesSubmit)) {
        await this.props.onPageOverridesSubmit(
          this.state.group.idPageGroup,
          pageOverrides
        );
      }

      if (closeModal) {
        this.props.onClose();
      }
      return true;
    } else {
      this.setState({
        additionalSettingsIsExpanded: true
      });
      return false;
    }
  };

  getStatus = (item: PageGroupEntry) => {
    const id = item.idPage;
    const data =
      this.props.type === 'landerGroup'
        ? this.props.data.allLanders
        : this.props.data.allOffers;
    return data?.[id]?.status || '';
  };

  additionalSettingsValueGetter = (idPage?: string) => {
    const id = idPage ? idPage : this.state.additionalSettingsIdPage;
    const pageOverride = this.state.pageOverrides[id]
      ? this.state.pageOverrides[id]
      : getDefaultPageOverrides(id);
    const pageOverrideSwitch = this.state.pageOverridesSwitch[id]
      ? this.state.pageOverridesSwitch[id]
      : {
          additionalTokens: false,
          redirectOverride: false
        };
    const customUrlString = this.state.customUrlString[id]
      ? this.state.customUrlString[id]
      : '';
    return {
      pageOverride,
      pageOverrideSwitch,
      customUrlString
    };
  };

  onGroupNameChange: any = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    await this.setState({
      groupName: value,
      hasEditedName: true
    });
  };

  setPages = async (pages: PageGroupEntry[]) => {
    await this.handleSetState('pages', pages);
  };

  getPagesOfGroup = () => {
    return this.props.pages.filter(
      page => this.state.pages.findIndex(p => p.idPage === page.idPage) >= 0
    );
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <div className="cform-landerOfferGroup">
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            PAGEGROUP_FORM_TAB,
            this.props.activeTab
          )}
        >
          <FormSectionBox
            title={this.props.tabTitle!}
            withBoxPadding={false}
            withTitlePadding={true}
          >
            <div className="padding-x-25">
              <div
                className={`cform-landerOfferGroup--name ${this.validateClassName(
                  'pageGroupName'
                )}`}
                key="node-name"
                style={{ borderBottom: 0, paddingBottom: 0 }}
              >
                <label className="cform-landerOfferGroup--name--landerLabel">
                  Page Group Name
                </label>
                <FFInput
                  name="groupName"
                  error={this.state.validationErrors.pageGroupName?.[0]}
                  placeholder="Enter a group name"
                  data-testid="groupName"
                  value={
                    this.state.hasEditedName
                      ? this.state.groupName
                      : this.getGroupName()
                  }
                  onChange={this.onGroupNameChange}
                  disabled={this.props.wizardLinkToOffers}
                />
              </div>

              <div
                className="cform-landerOfferGroup--selectPage"
                key={`node`}
                style={{ borderBottom: 0, paddingBottom: 0 }}
              >
                {this.getSelectLabel()}
                <Select
                  style={{ width: '100%' }}
                  value={this.state.idPage}
                  data-testid="selectPage"
                  groupOptions={true}
                  groupBy="category"
                  disabled={this.props.wizardLinkToOffers}
                  options={addCategory(
                    getActiveEntities(this.props.pages).filter(
                      item =>
                        !defined(
                          this.state.pages.find(
                            item2 => item.idPage === item2.idPage
                          )
                        )
                    ),
                    this.props.categories
                  )}
                  onChange={async (value: string) => {
                    await this.setState({ idPage: value });
                    await this.add(value);
                  }}
                  placeholder={this.getSelectTitle(true)}
                  showSearch={true}
                  filterOption={true}
                  sortGroup={true}
                  valueGetter={(option: Page) => option.idPage}
                  labelGetter={(option: Page) => option.pageName}
                />
              </div>
              <div>
                <Checkbox
                  key="map-incoming"
                  className="cform-landerOfferGroup--mapIncoming"
                  disabled={this.props.wizardLinkToOffers}
                  checked={this.state.routing === 'actionMapped'}
                  onChange={(value: AnyObject) => this.changeMap(value)}
                  text={[
                    `Map incoming action to ${this.props.pagesName.toLowerCase()} number`,
                    <IconTooltip
                      key="Map incoming action"
                      title="Map incoming action"
                      className="mapIncomingAction"
                      body={
                        <>
                          <p>
                            When this is selected, pages become numbered and
                            clicks coming from previous nodes will route based
                            on the action number used.
                          </p>

                          <p>
                            For example, if a user is on some lander A &gt;
                            clicks action link 2 &gt; goes to this group, they
                            will route to lander 2 in the ordered list.
                          </p>

                          <p>
                            If they do not come from an action, they will always
                            route to lander 1 in the list.
                          </p>

                          <p>
                            Use this in situations like offer walls, where you
                            have 10 products on a lander page and you want
                            action X to go to offer X in the next group
                            (allowing you to use a single offer group node,
                            instead of making separate links to 10 offers).
                          </p>
                        </>
                      }
                    />
                  ]}
                />
                <ModalButtonGroup
                  loading={this.props.loading}
                  showDuplicate
                  duplicateText="Reset"
                  onDuplicateClicked={this.handleReset}
                  additionalClass="cform-landerOfferGroup--buttons"
                />
              </div>
            </div>
            <PageGroupPageSlider
              pages={this.state.pages}
              getName={this.getName}
              getStatus={this.getStatus}
              setPages={this.setPages}
              isActionMapped={this.isActionMapped()}
              type={this.props.type}
              sidebarProps={this.context}
              data={this.props.data}
              onSwapPage={this.onSwapPage}
              changePageOverrides={this.changePageOverrides}
              categories={this.props.categories}
            />
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          show={
            getVisibilityByActiveTab(
              PAGEGROUP_FORM_ADDITIONAL_SETTINGS_TAB,
              this.props.activeTab
            ) && this.props.isInBuilderForm
          }
        >
          <FormSectionBox
            title={this.props.tabTitle!}
            className="cform-landerOfferGroup--additionalSettings"
          >
            <div className="cform-landerOfferGroup--additionalSettings--item margin-bottom-10">
              <Select
                dropdownClassName="additional-settings-dropdown"
                style={{ width: `100%` }}
                data-testid="additional-settings-select"
                value={this.state.additionalSettingsIdPage}
                onChange={(value: string) =>
                  this.changePageOverrides('idPage', value)
                }
                className="cform-landerOfferGroup--additionalSettings--item--dropdown additional-settings-dropdown"
                // hack - save us in FB for correct dragging and show in fine order
                options={[...this.state.pages]}
                valueGetter={(option: PageGroupEntry) => option.idPage}
                labelGetter={(option: PageGroupEntry) => this.getName(option)}
                placeholder={`${this.getSelectTitle(false)} to adjust`}
              />
            </div>
            <div className="cform-landerOfferGroup--additionalSettings--item margin-bottom-15">
              <SwitchButton
                checked={
                  this.additionalSettingsValueGetter().pageOverride
                    .accumulateUrlParams
                }
                onSwitchClicked={() =>
                  this.changePageOverrides(
                    'accumulateUrlParams',
                    !this.additionalSettingsValueGetter().pageOverride
                      .accumulateUrlParams
                  )
                }
                labelName="Receive Accumulated URL Parameters"
                data-testid="append-extra-params-switch"
              />
              <div
                id={getErrorId('url')}
                className={this.validateClassName(
                  'customUrlString',
                  this.state.additionalSettingsIdPage
                )}
              >
                <SwitchButton
                  className="margin-bottom-10"
                  checked={
                    this.additionalSettingsValueGetter().pageOverrideSwitch
                      .additionalTokens
                  }
                  onSwitchClicked={() => this.changeSwitch('additionalTokens')}
                  labelName="Append Extra URL Parameters"
                />
                <Input
                  value={this.additionalSettingsValueGetter().customUrlString}
                  disabled={
                    !this.additionalSettingsValueGetter().pageOverrideSwitch
                      .additionalTokens
                  }
                  error={this.validateTooltip(
                    'customUrlString',
                    this.state.additionalSettingsIdPage
                  )}
                  data-testid={'customUrlString'}
                  placeholder={'Extra URL Parameters'}
                  onChange={e => this.changeCustomUrlString(e.target.value)}
                />
              </div>
            </div>
            <div
              className={`cform-landerOfferGroup--additionalSettings--item ${this.validateClassName(
                'redirectOverride',
                this.state.additionalSettingsIdPage
              )}`}
            >
              <SwitchButton
                checked={
                  this.additionalSettingsValueGetter().pageOverrideSwitch
                    .redirectOverride
                }
                onSwitchClicked={() => this.changeSwitch('redirectOverride')}
                labelName="Override redirect mode"
                data-testid="override-redirect-switch"
              />
              <Select
                style={{ width: `100%` }}
                value={
                  this.additionalSettingsValueGetter().pageOverride
                    .redirectOverride
                }
                error={this.validateTooltip(
                  'redirectOverride',
                  this.state.additionalSettingsIdPage
                )}
                className={'redirectOverride'}
                onChange={(value: string) =>
                  this.changePageOverrides('redirectOverride', value)
                }
                disabled={
                  !this.additionalSettingsValueGetter().pageOverrideSwitch
                    .redirectOverride
                }
                options={redirects}
                valueGetter={(option: SelectOption) => option.value}
                labelGetter={(option: SelectOption) => option.label}
                placeholder={'Select a Redirect'}
                data-testid="redirectOverride"
              />
            </div>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          className="cform-landerOfferGroup--getEntranceLink"
          show={this.state.renderEntranceLink}
        >
          <EntranceLink
            funnel={this.props.data.funnel}
            getLanderById={this.props.getLanderById}
            getOfferById={this.props.getOfferById}
            funnelNode={this.props.funnelNode}
            trafficSources={this.props.data.trafficSources}
            userSettings={
              this.props.data.userSettings || this.props.systemSettings?.data
            }
            pages={this.getEntranceLinkPages()}
            description={
              this.isActionMapped()
                ? TEXT
                : this.props.activeTab === PAGEGROUP_FORM_REDIRECT_LINKS_TAB
                ? 'Generate redirect links that point to FunnelFlux and route visitors according to your funnel design. These links allow you to use rotators/conditions to control page destination and split-test, as well as change the funnel at any time without needing to update your ads.'
                : 'Generate direct links to a page that also pass tracker/traffic source data. Many traffic sources now require these direct URLs to be used. You will need to use our Javascript to track the arriving user.'
            }
            isDirectLink={this.props.activeTab === PAGEGROUP_FORM_DIRECT_LINKS_TAB}
            pagesName={this.props.pagesName}
            openTsOnMount={!!this.props.shouldOpenLinkSection}
            isChangedFunnel={
              defined(this.props.isChangedFunnel)
                ? this.props.isChangedFunnel
                : false
            }
            domains={this.props.data.domains}
            switch={
              this.props.activeTab === PAGEGROUP_FORM_REDIRECT_LINKS_TAB
                ? 'url'
                : 'js'
            }
          />
        </FormContentWrapper>

        <FormContentWrapper
          show={getVisibilityByActiveTab(
            PAGEGROUP_FORM_JAVASCRIPT_TRACKING_TAB,
            this.props.activeTab
          )}
        >
          <FormSectionBox title="Required: Universal JS Tag">
            <div className="flex flex-row flex-align-center flex-gap-10 margin-bottom-15">
              <Label
                text="Select a domain:"
                className="min-width-fitContent"
                htmlFor="domain"
              />
              <Select
                value={this.state.domain}
                onChange={(value: string) =>
                  this.handleSetState('domain', value)
                }
                options={this.props.systemSettings?.domains || []}
                valueGetter={(option: DomainEntry) => option.domain}
                labelGetter={(option: DomainEntry) => option.domain}
                placeholder="Select Domain"
              />
            </div>
            <div className="cform-landerOfferGroup__javascriptTrackingGlobalSection">
              <p>
                This script is required for direct linking. Place it just before{' '}
                {'</head>'}. We recommend adding this to all of your pages
                regardless as it improves tracking. This code already includes a
                page view event.
              </p>
              <CodeSnippet
                forwardedRef={this.elRefs.jsTrackingHeader}
                codeType="genericViewFull"
                domain={this.state.domain}
              />
            </div>
          </FormSectionBox>
          <FormSectionBox title="Optional: View-only Event">
            <div className="cform-landerOfferGroup__javascriptTrackingDefaultParamsSection">
              <p>
                If you have already loaded our universal JS tag and want to fire a
                singular page view event, you can use the following code. See our <a href="https://help.funnelflux.pro/article/105-javascript-tracking-of-page-views" target="_blank">help documentation</a> for further methods/arguments.
              </p>
              <CodeSnippet 
                codeType="genericViewSingle"
                maxContent
                forwardedRef={this.elRefs.genericViewSingle}
              />
            </div>
          </FormSectionBox>
          <FormSectionBox title="Optional: Default Tracking Parameters">
            <div className="cform-landerOfferGroup__javascriptTrackingDefaultParamsSection">
              <p>
                This code is optional and is NOT required for tracking to work.
                If you would like to track organic visits to your page, it needs
                to declare some defaults. You can embed the below code{' '}
                <em>before</em> our global snippet, to provide the tracking with
                default parameters. See our <a href="https://help.funnelflux.pro/article/108-setting-javascript-page-defaults" target="_blank">help documentation</a> for more info.
              </p>
              <Select
                value={this.state.jsDefaultParamsIdPage}
                options={this.getPagesOfGroup()}
                valueGetter={(opt: Page) => opt.idPage}
                labelGetter={(opt: Page) => opt.pageName}
                placeholder="Select the page"
                onChange={(value: string) =>
                  this.handleSetState('jsDefaultParamsIdPage', value)
                }
              />
              <CodeSnippet
                codeType="defaultParams"
                forwardedRef={this.elRefs.defaultParams}
                idPage={this.state.jsDefaultParamsIdPage}
                idFunnel={this.props.data.funnel?.idFunnel}
                maxContent
              />
            </div>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          className="cform-landerOfferGroup--getActionLinks"
          show={
            getVisibilityByActiveTab(
              PAGEGROUP_FORM_ACTION_LINKS_TAB,
              this.props.activeTab
            ) && defined(this.props.funnelConnection)
          }
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <GetActionLink
              connections={this.props.funnelConnections || []}
              node={this.props.funnelNode!}
              funnel={this.props.data.funnel!}
              connection={this.props.funnelConnection!}
              onClose={false}
              customDomains={this.props.data.domains || []}
              defaultCustomDomain={
                this.props.data.userSettings?.defaultCustomDomain
              }
              formBoxPadding={false}
            />
          </FormSectionBox>
        </FormContentWrapper>
      </div>
    );
  }
}

export default withLoading(Messages.injectIn(GroupNodeSettings));

import React from 'react';
import { Page } from '../../../../model/page';
import { Category } from '../../../../model/category';
import { Label, Select } from '../../../Parts/Inputs';
import Messages from 'components/Messages';
import { ModalButtonGroup } from '../../../Parts/Groups';
import { OFFSET_NEW_NODE } from '../../../../constants/builder';
import { FunnelNode } from '../../../../model/funnelNode';
import { generateEntityId } from '../../../../utils/id/generator';
import { defined } from '../../../../utils/define';
import { PageGroupEntry } from '../../../../model/pageGroupEntry';
import { PageGroup } from '../../../../model/pageGroup';
import { ucFirst } from '../../../../utils/string';
import { AddPageNote } from '../../../Parts/Alert';
import { withLoading } from '../../../Loading';
import { LoadingProps } from '../../../../types/loading';
import { AnyObject, MessageProps } from '../../../../types';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from '../../../../utils/reactive/generic';
import validateForm, { required } from '../../../../utils/validation';
import { ValidationRule } from '../../../../utils/validation/types';
import { getActiveEntities } from 'utils/model';
import { SidebarTrigger } from 'components/SideBars';
import { BuilderAddPageFormProps } from 'types/ModalForms/builder/addPage';
import { UNCATEGORIZED } from 'constants/modal';
import { sortByName } from 'utils/sort';

interface State extends ReactiveValidateState {
  categories: Category[] | [];
  categoryId: string;
  pageIds: string[];
  validationErrors: AnyObject;
  categoriesArray: {
    [key: string]: Category;
  };
  isFetching: boolean;
}

class AddPage extends ReactiveValidateComponent<
  BuilderAddPageFormProps & MessageProps & LoadingProps,
  State
> {
  state: State = {
    isTouchedForm: false,
    categoryId: 'all',
    pageIds: [],
    validationErrors: {},
    categories: [],
    categoriesArray: {},
    isFetching: true
  };

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  async componentDidMount() {
    await this.handleState('isFetching', true);
    if (this.props.type === 'lander') {
      await Promise.all([
        this.props.fetchLanderCategories(),
        this.props.fetchLanders()
      ]);
    } else {
      await Promise.all([
        this.props.fetchOfferCategories(),
        this.props.fetchOffers()
      ]);
    }

    const categoriesArray = this.props.categories.reduce(
      (acc: { [key: string]: Category }, crr) => {
        acc[crr.idCategory] = crr;
        return acc;
      },
      {}
    );
    await this.handleState('categories', [
      {
        categoryName: 'All Categories',
        idCategory: 'all',
        categoryType: this.props.type
      },
      ...this.props.categories
    ]);
    await this.handleState('categoriesArray', categoriesArray);
    await this.handleState('isFetching', false);
  }

  handleMultiSelectChange = async (value: []) => {
    await this.setState({ pageIds: value });
    this.validate$.next();
  };

  handleSelectChange = (value: string) => {
    this.setState({ categoryId: value });
  };

  getPages = (): (Page & { category: string })[] => {
    return sortByName(
      sortByName(
        getActiveEntities(this.props.pages)
          .filter(
            (item: Page) =>
              this.state.categoryId === 'all' ||
              this.state.categoryId === item.idCategory
          )
          .map(page => ({
            ...page,
            category:
              this.state.categoriesArray?.[page.idCategory!]?.categoryName ||
              UNCATEGORIZED
          })),
        'pageName'
      ),
      'category'
    );
  };

  validationRules = (): ValidationRule[] => [
    {
      field: 'pageIds',
      validations: [required]
    }
  ];

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {} });
      const [validationErrors] = validateForm(
        this.state,
        this.validationRules()
      );
      this.setState((state: State) => ({
        ...state,
        validationErrors
      }));
    }
  };

  onSave = async () => {
    await this.setTouchedForm();
    await this.validate();
    this.props.startLoading('ok');

    if (!Object.keys(this.state.validationErrors).length) {
      try {
        const pages = this.getPages().filter((item: Page) =>
          this.state.pageIds.includes(item.idPage)
        );

        if (pages.length > 0) {
          const pageGroupName =
            pages.length > 1
              ? `${ucFirst(this.props.type)} Group`
              : defined(pages[0])
              ? pages[0].pageName
              : this.props.type;

          const group: PageGroup = {
            idPageGroup: generateEntityId(),
            pageGroupName:
              pageGroupName + this.props.getPageGroupNamePostfix(pageGroupName),
            pageType: this.props.type,
            routing: 'rotator',
            pages: pages.reduce(
              (acc: PageGroupEntry[], item: Page, id: number) => [
                ...acc,
                {
                  idPage: item.idPage,
                  weight: 1
                }
              ],
              []
            ),
            status: 'active',
            restrictToFunnelId: this.props.funnel.idFunnel
          };

          const node: FunnelNode = {
            idNode: generateEntityId(),
            nodeName: group.pageGroupName,
            nodeType: `${group.pageType}Group` as FunnelNode.NodeTypeEnum,
            posX: this.props.posX - OFFSET_NEW_NODE,
            posY: this.props.posY - OFFSET_NEW_NODE,
            nodePageGroupParams: {
              idPageGroup: group.idPageGroup
            }
          };

          await this.props.onAddGroup(group);
          await this.props.onAdd([node]);
          this.props.stopLoading('all');
          this.props.onClose();
        } else {
          this.props.showMessage(Messages.failed(`Page group cannot be added`));
        }
      } catch (e) {
        this.props.showMessage(Messages.failed(`Page group cannot be added`));
      }
    }

    this.props.stopLoading('ok');
  };

  validateClassName = (field: string, idx?: number) => {
    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? 'has-error'
      : '';
  };

  validateTooltip = (field: string, idx?: string) => {
    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  render() {
    return (
      <form>
        <div className="flex flex-col padding-x-25 padding-y-15 flex-gap-15">
          <div>
            <Label htmlFor="filter-by-category" text="Filter by Category:" />
            <Select
              value={this.state.categoryId}
              id="filter-by-category"
              name="filter-by-category"
              placeholder="All Categories"
              style={{ width: 300 }}
              options={getActiveEntities(this.state.categories)}
              valueGetter={(option: Category) => option.idCategory}
              labelGetter={(option: Category) => option.categoryName}
              onChange={(value: string) => this.handleSelectChange(value)}
              showSearch={true}
              filterOption={true}
              disabled={this.state.isFetching}
            />
          </div>
          <div className={this.validateClassName('pageIds')}>
            <Label
              htmlFor="filter-by-pageIds"
              text={`Add ${ucFirst(this.props.type)}s:`}
            />
            <div className="flex flex-align-center flex-gap-10">
              <Select
                mode="multiple"
                groupOptions={true}
                value={this.state.pageIds}
                id="filter-by-pageIds"
                data-testid="filter-by-pageIds"
                name="filter-by-pageIds"
                error={this.validateTooltip('pageIds')}
                placeholder={`Add ${ucFirst(this.props.type)}s`}
                style={{ width: 300 }}
                options={this.getPages()}
                valueGetter={(option: Page) => option.idPage}
                labelGetter={(option: Page) => option.pageName}
                onChange={(value: []) => this.handleMultiSelectChange(value)}
                showSearch
                groupBy="category"
                disabled={this.state.isFetching}
                suspendSort
              />
              <SidebarTrigger sidebarToOpen={this.props.modalName} />
            </div>
          </div>
          <AddPageNote />
          <ModalButtonGroup
            loading={this.props.loading}
            showOk
            showCancel
            okText="Add"
            onOkClicked={this.onSave}
            onCancelClicked={this.props.onClose}
            cancelText="Close"
          />
        </div>
      </form>
    );
  }
}

export default withLoading(Messages.injectIn(AddPage));

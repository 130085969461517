import React from 'react';
import Messages from 'components/Messages';
import { withLoading } from 'components/Loading';
import { ConditionDeleteFormProps } from '../../../types/ModalForms/conditionDelete';
import { Funnel } from '../../../model/funnel';
import { defined } from '../../../utils/define';
import { FFButton } from 'uikit';
import './style.scss';
import makeCancellablePromise, { timeoutPromise } from 'utils/promises';
import { CancellablePromise } from 'types';

interface State {
  funnels: Funnel[];
  isHolding: boolean;
}

class ConditionDeleteForm extends React.Component<
  ConditionDeleteFormProps,
  State
> {
  state: State = {
    funnels: [],
    isHolding: false
  };
  private submitPromise: Partial<CancellablePromise> = {};

  static getDerivedStateFromProps(props: ConditionDeleteFormProps, _: State) {
    if (defined(props.funnels)) {
      return {
        funnels: props.funnels.filter(funnel =>
          defined(funnel.nodes)
            ? funnel.nodes.some(
                node =>
                  defined(node.nodeConditionParams) &&
                  node.nodeConditionParams.idCondition === props.idCondition
              )
            : false
        )
      };
    }

    return null;
  }

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.handleState('isHolding', true);
    this.submitPromise = makeCancellablePromise(timeoutPromise(1000));

    try {
      await this.submitPromise.promise;
      this.handleState('isHolding', false);
      this.props.startLoading('ok');
      await this.props.handleSubmit({
        entries: [this.props.contextModal!.entityId!]
      });
      this.props.stopLoading('ok');
      this.props.actions.onOk();
      this.props.showMessage(
        Messages.success(
          `${this.props.contextModal.data.value} has been deleted`
        )
      );
    } catch (e) {
      this.props.stopLoading('ok');
      this.props.actions.onOk();
      Messages.success(
        `${this.props.contextModal.data.value} cannot be deleted`
      );
    }
  };

  handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.actions.onClose();
  };

  onMouseLeaveOrUp = () => {
    if (!this.submitPromise?.cancel) return;
    this.handleState('isHolding', false);
    this.submitPromise.cancel();
    this.props.stopLoading('all');
  };

  render() {
    return (
      <div className="c-conditionDelete__contentWrapper">
        <h4 className="c-conditionDelete__text c-conditionDelete__text--bold">
          Deleting a global condition will remove it from the list of conditions
          you can add to funnels.
        </h4>
        <p className="c-conditionDelete__text">
          This will not delete existing condition nodes in funnels or break
          them.
        </p>
        <p className="c-conditionDelete__text">
          If this condition is used in existing funnels, you will still be able
          to configure/modify the condition nodes, routes and so on, which will
          still affect all other funnels.
        </p>
        <p className="c-conditionDelete__text">
          This delete action is NOT reversible. Use it to clean up your
          conditions list for those you will no longer use in future funnels.
        </p>
        <h4 className="c-conditionDelete__text c-conditionDelete__text--bold">
          Click below to confirm deletion.
        </h4>
        <div className="flex flex-gap-5">
          <FFButton
            onMouseDown={this.handleSubmit}
            onMouseLeave={this.onMouseLeaveOrUp}
            onMouseUp={this.onMouseLeaveOrUp}
            loading={this.state.isHolding}
            disabled={this.props.loading.ok}
            loadingType={this.props.loading.ok ? 'default' : 'skeleton'}
            color="dangerColor"
            className="flex-1"
          >
            Hold to Delete
          </FFButton>
          <FFButton
            onClick={this.handleCancel}
            disabled={this.props.loading.ok}
            className="flex-1"
          >
            Cancel
          </FFButton>
        </div>
      </div>
    );
  }
}

export default withLoading(Messages.injectIn(ConditionDeleteForm));

import {
  LanderStore,
  OfferStore,
  PageGroupStore
} from '../../types/redux/store';
import { defined } from '../../utils/define';
import { PageGroup } from 'model/models';
import { ucFirst } from '../../utils/string';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { DrilldownReportRowAttribute } from 'model/drilldownReportRowAttribute';
import { makeReportingRowData } from 'utils/reporting';

export const getPageGroupsData = (
  state: PageGroupStore,
  landerState: LanderStore,
  offerStore: OfferStore,
  strictlyShowAll = false,
  isSummary = false
): DrilldownReportRow[] => {
  const status = state.settings.show;
  const isAll = status === 'not-deleted';
  const filterByGroup = state.settings.additionalParams?.filterByGroup || '';

  let rowsData: { [key: string]: DrilldownReportRow } = {};
  [...state.reporting.landers.rows, ...state.reporting.offers.rows].forEach(
    row => {
      const id = defined(row.attributes[1])
        ? row.attributes[1].id
        : row.attributes[0].id;

      rowsData[id] = row;
    }
  );
  let result: DrilldownReportRow[] = [];

  Object.values(state.data)
    .filter(
      (pageGroup: PageGroup) =>
        !defined(pageGroup.restrictToFunnelId) &&
        (strictlyShowAll
          ? true
          : isAll
          ? pageGroup.status !== 'deleted'
          : pageGroup.status === status) &&
        (filterByGroup! === 'all' ||
          !filterByGroup ||
          filterByGroup === pageGroup.pageType)
    )
    .forEach((pageGroup: PageGroup) => {
      if (isSummary) {
        pageGroup.pages.forEach(page => {
          const attributes: DrilldownReportRowAttribute[] = [
            {
              id: pageGroup.idPageGroup,
              value: pageGroup.pageGroupName,
              status: 'active',
              attribute: `${pageGroup.pageType}Group`,
              info: `${pageGroup.pageType}Group`
            },
            {
              id: page.idPage,
              value:
                (pageGroup.pageType === 'lander'
                  ? landerState.data?.[page.idPage]?.pageName
                  : offerStore.data?.[page.idPage]?.pageName) || '',
              status:
                (pageGroup.pageType === 'lander'
                  ? landerState.data?.[page.idPage]?.status!
                  : offerStore.data?.[page.idPage]?.status!) || 'active',
              attribute: pageGroup.pageType
            }
          ];
          if (rowsData[page.idPage]) {
            result.push({
              ...(rowsData[page.idPage] || makeReportingRowData(attributes)),
              attributes: attributes
            });
          }
        });
      } else {
        const attributes: DrilldownReportRowAttribute[] = [
          {
            id: `${pageGroup.pageType}Group`,
            value: `${ucFirst(pageGroup.pageType)} Groups`,
            status: 'active',
            attribute: `${pageGroup.pageType}Group`
          },
          {
            id: pageGroup.idPageGroup,
            value: pageGroup.pageGroupName,
            status: pageGroup.status || 'active',
            attribute: `${pageGroup.pageType}Group`
          }
        ];
        pageGroup.pages.forEach(page => {
          result.push({
            ...(rowsData[page.idPage] || makeReportingRowData(attributes)),
            attributes: attributes
          });
        });
      }
    });

  return result;
};

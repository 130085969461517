import * as Sentry from '@sentry/react';
import { isDevEnvironment, isProdEnvironment } from '../utils/app';
import { ErrorInfo } from 'react';
import { defined } from '../utils/define';
import { User } from '../model/user';

export const initSentry = () => {
  if (!isDevEnvironment) {
    const packageJson = require('../../package.json');
    Sentry.init({
      dsn: 'https://6089c668fbea405f83d10a869d594f81@o349224.ingest.sentry.io/2261684',
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: isProdEnvironment ? 'production' : 'stage',
      release: 'funnelflux@' + packageJson.version || 'notSetted',
      integrations: [new Sentry.Replay()],
    });
  }
};

export const configureScopeSentry = (user: User) => {
  if (!isDevEnvironment) {
    Sentry.setUser({
      id: user.userId,
      username: user.name,
      email: user.email
    });
  }
};

export const trackSentry = (error: Error, errorInfo?: ErrorInfo) => {
  if (!isDevEnvironment) {
    return new Promise((resolve, reject) => {
      Sentry.withScope(scope => {
        if (defined(errorInfo)) {
          scope.setExtras({ componentStack: errorInfo.componentStack });
        }
        const eventId = Sentry.captureException(error);
        resolve(eventId);
      });
    });
  }
  return new Promise(() => {});
};

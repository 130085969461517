import { GOOGLE_ADS, TABLE, VIEW } from '../types';
import { AnyAction } from 'redux';
import { GoogleAdsStore } from 'types/redux/store';
import { googleAds as defaultState } from './defaultStates';
import { defined } from 'utils/define';
import table from './table';

const data = (state = defaultState.reporting, action: AnyAction) => {
  switch (action.type) {
    case GOOGLE_ADS.SET_DATA:
      if (defined(action.payload)) {
        return {
          ...state,
          ...action.payload
        };
      }
      return state;
    default:
      return state;
  }
};

const dataInitial = (state = defaultState.reporting, action: AnyAction) => {
  switch (action.type) {
    case GOOGLE_ADS.SET_DATA_INITIAL:
      if (defined(action.payload)) {
        return {
          ...state,
          ...action.payload
        };
      }
      return state;
    default:
      return state;
  }
};

const user = (state = defaultState.users, action: AnyAction) => {
  switch (action.type) {
    case GOOGLE_ADS.GET_USERS:
      state.allUsers = action.payload;
      return state;
    case GOOGLE_ADS.UPDATE_CURRENT_USER:
      state.currentUser = action.payload;
    case GOOGLE_ADS.GET_CUSTOMER_CLIENTS:
      state.customerClients = action.payload;
      return state;
    case GOOGLE_ADS.SET_CURRENT_USER:
      state.currentUser = action.payload;
      return state;
    case GOOGLE_ADS.SET_CURRENT_CUSTOMER_CLIENT:
      state.currentCustomerClient = action.payload;
    default:
      return state;
  }
};

const tableCols = (state = defaultState.settings.tableCols, action: AnyAction) => {
  if (defined(action.payload) && action.payload.view === VIEW.GOOGLEADS && action.type === TABLE.SET_COLS) {
    return table(state, action);
  } else {
    return state;
  }
};

const tableColSizes = (state = defaultState.settings.tableColSizes, action: AnyAction) => {
  if (defined(action.payload) && action.type === GOOGLE_ADS.SET_TABLE_COL_SIZES) {
    return action.payload;
  } else {
    return state;
  }
};

const tableParams = (
  state = defaultState.settings.tableParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_TABLE_PARAMS &&
    action.payload.view === VIEW.GOOGLEADS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const settings = (state = defaultState.settings.params, action: AnyAction) => {
  switch (action.type) {
    case GOOGLE_ADS.SET_SETTINGS:
      if (defined(action.payload)) {
        return {
          ...state,
          [action.payload.key]: action.payload.data
        };
      }
      return state;
    default:
      return state;
  }
};

export default (state = defaultState, action: AnyAction): GoogleAdsStore => {
  return {
    reporting: data(state.reporting, action),
    reportingInitial: dataInitial(state.reportingInitial, action),
    users: user(state.users, action),
    settings: {
      tableCols: tableCols(state.settings.tableCols, action),
      tableColSizes: tableColSizes(state.settings.tableColSizes, action),
      params: settings(state.settings.params, action),
      tableParams: tableParams(state.settings.tableParams, action)
    },
  };
};

import React from 'react';
import {
  Input as AntInput,
  InputNumber as AntInputNumber,
  Select as AntSelect,
  DatePicker as AntDatePicker,
  TreeSelect as AntTreeSelect,
  Slider as AntSlider,
  Tooltip,
  Radio,
  TreeSelectProps
} from 'antd';
import './style.scss';
import { InputProps as AntInputProps } from 'antd/lib/input';
import { SelectValue, OptionProps } from 'antd/lib/select';
import { naturalSort } from '../../../utils/sort';
import { AnyObject, DropDownOptions } from '../../../types';
import { timeRangeItems } from 'constants/time';
import { defined } from 'utils/define';
import TextareaAutosize from 'react-textarea-autosize';
import { filterOptionByCategory } from '../../../utils/select';
import { RadioChangeEvent } from 'antd/lib/radio';
import { conditionalClass } from 'conditional-class';
import { SliderSingleProps } from 'antd/lib/slider';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const Option = AntSelect.Option;
const OptionGroup = AntSelect.OptGroup;
const AntTextArea = AntInput.TextArea;
const RangePicker = AntDatePicker.RangePicker;
const ShowChild = AntTreeSelect.SHOW_CHILD;

export const Label = ({
  text,
  className = '',
  ...props
}: {
  text: string | JSX.Element | (JSX.Element | string)[];
  htmlFor?: string;
  className?: string;
}) => (
  <label className={`c-label ${className}`} {...props}>
    {text}
  </label>
);

export const RadioSwitch = ({
  value,
  options,
  labelGetter,
  valueGetter,
  onChange,
  className
}: {
  value: string;
  options: any[];
  labelGetter?: Function;
  valueGetter?: Function;
  onChange?: (e: RadioChangeEvent) => void;
  className?: string;
}) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      className={`c-radioSwitch ${className}`}
      value={value}
      onChange={onChange}
    >
      {options.map((item: AnyObject) => {
        const label = labelGetter ? labelGetter(item) : item.value;
        const value = valueGetter ? valueGetter(item) : item.name;
        return (
          <Radio.Button
            value={value}
            className="c-radioSwitch__btn"
            key={`switcher-${value}`}
            disabled={!!item.disabled ? item.disabled() : false}
          >
            {label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

export const InputNumber = ({
  value,
  onChange,
  placeholder,
  style = {},
  ...props
}: {
  id?: string;
  placeholder?: string;
  onChange?: any;
  style?: object;
  value: number;
}) => (
  <AntInputNumber
    value={value}
    type="number"
    onChange={onChange}
    placeholder={placeholder}
    style={{
      height: 35,
      minHeight: 35,
      ...style
    }}
    {...props}
  />
);

export const TextArea = ({
  id,
  name,
  onChange,
  placeholder,
  style = {},
  forwardedRef = null,
  ...props
}: {
  id: string;
  name: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => any;
  style?: React.CSSProperties;
  forwardedRef?: any;
  value: string;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <AntTextArea
      {...props}
      id={id}
      name={name}
      ref={forwardedRef}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        height: 65,
        minHeight: 65,
        ...style
      }}
    />
  );
};

interface InputProps extends AntInputProps {
  error?: string;
  showErrorTooltip?: boolean;
  forwardedRef?: any;
  elementType?: 'input' | 'textarea';
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => any;
}
export const Input = ({
  id,
  name,
  onChange,
  placeholder,
  style = {},
  forwardedRef = null,
  error,
  disabled,
  showErrorTooltip = false,
  elementType = 'input',
  ...props
}: InputProps) => {
  const suffix = !error ? (
    <></>
  ) : (
    <Tooltip title={error}>
      <InfoCircleOutlined style={{ color: '#f5222d' }} />
    </Tooltip>
  );

  return elementType === 'textarea' ? (
    <div className="input-type-textarea">
      <span className="textarea-suffix">{suffix}</span>
      <TextareaAutosize
        minRows={1}
        maxRows={6}
        onBlur={props.onBlur as any}
        id={id!}
        name={name}
        inputRef={forwardedRef}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        value={(props.value ? props.value : '') as string}
        style={{
          minHeight: 35,
          ...style
        }}
        //@ts-ignore
        data-testid={props['data-testid']}
        className="ant-input"
      />
    </div>
  ) : (
    <AntInput
      data-lpignore={true}
      {...props}
      id={id}
      name={name}
      ref={forwardedRef}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      autoComplete={'off'}
      suffix={suffix}
      style={{
        height: 35,
        minHeight: 35,
        ...style
      }}
    />
  );
};

export const Slider = ({
  value,
  defaultValue,
  onChange,
  ...props
}: SliderSingleProps) => (
  <AntSlider
    {...props}
    onChange={onChange}
    value={value}
    defaultValue={defaultValue}
  />
);

const makeSelectOptions = (
  options: any,
  valueGetter: Function,
  labelGetter: Function,
  checkDisabled: Function,
  withoutPresort: boolean,
  classNameGetter: Function,
  threshold = 40
) => {
  if (!withoutPresort) {
    defined(options) &&
      options.sort((a: any, b: any) =>
        naturalSort(labelGetter(a), labelGetter(b))
      );
  }

  return (
    defined(options) &&
    options.map((opt: any) => {
      const value = valueGetter(opt);
      const label = labelGetter(opt);
      const className = classNameGetter(opt);
      const disabled = checkDisabled ? checkDisabled(value) : false;
      return (
        <Option
          key={value}
          value={value}
          disabled={disabled}
          className={className}
          title={
            typeof label === 'string' && label.length > threshold
              ? label
              : undefined
          }
        >
          {label}
        </Option>
      );
    })
  );
};

const makeGroupedData = (
  value: any[],
  groupBy: string,
  shouldMake: boolean,
  sortGroup: boolean,
  ignoreSortOnCategory: string,
  labelGetter: Function
): object => {
  let data = value;

  if (sortGroup) {
    data = value.sort((a: AnyObject, b: AnyObject) => {
      if (
        a.category === ignoreSortOnCategory ||
        b.category === ignoreSortOnCategory
      ) {
        return 0;
      }
      if (a[groupBy] === b[groupBy]) {
        return naturalSort(labelGetter(a), labelGetter(b));
      }

      return naturalSort(a[groupBy], b[groupBy]);
    });
  }

  return shouldMake
    ? data.reduce((acc: any, item: any) => {
        if (item) {
          const groupName = !!item[groupBy] ? groupBy : '';
          let { [groupName]: field, ...rest } = item;
          acc = {
            ...acc,
            [field]: acc[field] ? [...acc[field], rest] : [rest]
          };
        }

        return acc;
      }, {})
    : data;
};

interface SelectProps {
  options: any[];
  placeholder?: string;
  value?: SelectValue;
  name?: string;
  size?: SizeType;
  mode?: any;
  style?: React.CSSProperties;
  onChange?: any;
  groupBy?: string | string[];
  forwardedRef?: any;
  groupOptions?: boolean;
  valueGetter?: Function;
  labelGetter?: Function;
  classNameGetter?: Function;
  groupLabelGetter?: Function;
  defaultValueFallback?: DropDownOptions;
  listTitle?: string;
  listTitleClass?: string;
  createNewView?: DropDownOptions;
  onSelect?: (value: string) => void;
  onDeselect?: any;
  showArrow?: boolean;
  showSearch?: boolean;
  suspendSort?: boolean;
  optionLabelProp?: 'value' | 'label';
  [key: string]: any;
  onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearch?: (value: string) => void;
  id?: string;
  filterOption?:
    | boolean
    | ((
        inputValue: string,
        option: React.ReactElement<OptionProps>
      ) => boolean);
  maxTagCount?: number | 'responsive';
}

export const Select = ({
  name,
  mode,
  value,
  style,
  options,
  onChange,
  placeholder,
  groupBy = '',
  id,
  forwardedRef = null,
  groupOptions = false,
  valueGetter = (opt: any) => opt,
  labelGetter = (opt: any) => opt,
  classNameGetter = (opt: any) => opt,
  groupLabelGetter = (opt: any) => opt,
  defaultValueFallback,
  listTitle,
  listTitleClass = '',
  createNewView,
  selectAll = false,
  onDeselect = () => {},
  onInputKeyDown = () => {},
  onSearch,
  filterOption = false,
  showArrow = true,
  showSearch = false,
  label,
  suspendSort = false,
  optionLabelProp,
  sortGroup = false,
  ignoreSortOnCategory = '',
  error,
  checkDisabled,
  open,
  maxTagCount = 'responsive',
  size = 'middle',
  ...props
}: SelectProps) => {
  let val = !!value ? value : undefined;
  if (
    val === undefined &&
    !!defaultValueFallback &&
    !!defaultValueFallback.value
  ) {
    val = defaultValueFallback.value;
  }

  const groupByArray = Array.isArray(groupBy)
    ? groupBy
    : groupBy === ''
    ? []
    : [groupBy];

  let shouldMakeGroupedData = groupOptions && groupByArray.length > 0;
  const threshold = id
    ? (document.getElementById(id)?.clientWidth || 0) / 8
    : 40;

  const getGroupedData = (groupCh: any, groupBy: string = '', idx: number) => {
    let opts = makeGroupedData(
      groupCh,
      groupBy,
      shouldMakeGroupedData && groupBy !== '',
      sortGroup,
      ignoreSortOnCategory,
      labelGetter
    );

    if (shouldMakeGroupedData && defined(groupBy) && groupBy !== '') {
      const nextIdx = idx + 1;
      const nextGroup = groupByArray[idx + 1];

      return Object.entries(opts).map(([groupName, groupCh]) => {
        return groupName !== 'undefined' && !!groupName ? (
          <OptionGroup label={groupLabelGetter(groupName)} key={groupName}>
            {getGroupedData(groupCh, nextGroup, nextIdx)}
          </OptionGroup>
        ) : (
          makeSelectOptions(
            groupCh,
            valueGetter,
            labelGetter,
            checkDisabled,
            suspendSort,
            classNameGetter,
            threshold
          )
        );
      });
    } else {
      return makeSelectOptions(
        opts,
        valueGetter,
        labelGetter,
        checkDisabled,
        suspendSort,
        classNameGetter,
        threshold
      );
    }
  };

  let children = getGroupedData(options, groupByArray[0], 0);

  const compareValuesOptions =
    defined(val) &&
    defined(options) &&
    Object.keys(val).length === Object.keys(options).length;

  const getFilterOption = (input: string, option: any) => {
    if (defined(filterOption)) {
      return filterOptionByCategory(input, option);
    }

    return false;
  };

  return (
    <>
      {!!label && <span className="select-label">{label}</span>}
      <AntSelect
        {...props}
        mode={mode}
        maxTagCount={maxTagCount}
        defaultActiveFirstOption={false}
        id={id}
        open={open}
        size={size}
        // @ts-ignore
        value={val}
        optionLabelProp={optionLabelProp}
        onDeselect={(value: any) => onDeselect(value)}
        style={style}
        className={conditionalClass(props.className, {
          'ant-select-middle': size === 'middle'
        })}
        onInputKeyDown={onInputKeyDown}
        showArrow={showArrow}
        showSearch={showSearch}
        ref={forwardedRef}
        placeholder={placeholder}
        onSearch={onSearch}
        filterOption={(input: string, option: any) =>
          getFilterOption(input, option)
        }
        onChange={(value: string) => onChange(value)}
        suffixIcon={
          error ? (
            <Tooltip title={error}>
              <InfoCircleOutlined style={{ color: '#f5222d' }} />
            </Tooltip>
          ) : undefined
        }
      >
        {selectAll && (
          <Option
            key="clearAll"
            className={conditionalClass('ant-select--all', {
              'ant-select--hiddenItem': !compareValuesOptions,
              'ant-select--dropdownMenuItemSelected': !!compareValuesOptions
            })}
            value="clearAll"
          >
            {selectAll}
          </Option>
        )}

        {selectAll && (
          <Option
            key="addAll"
            className={conditionalClass('ant-select--all', {
              'ant-select--hiddenItem': compareValuesOptions
            })}
            value="addAll"
          >
            {selectAll}
          </Option>
        )}

        {defaultValueFallback && (
          <Option
            key={defaultValueFallback.value}
            value={defaultValueFallback.value}
            className="uncategorized"
          >
            {defaultValueFallback.label}{' '}
          </Option>
        )}

        {listTitle && (
          <Option
            key={'ant-select-list-title'}
            className={`listTitle ${listTitleClass}`}
          >
            {listTitle}
          </Option>
        )}

        {createNewView && (
          <Option
            key={createNewView.value}
            value={createNewView.value}
            className="create-new-view"
          >
            {createNewView.label}
          </Option>
        )}
        {children}
      </AntSelect>
    </>
  );
};

export const TreeSelect = ({
  treeData,
  value,
  onChange,
  treeCheckable,
  style,
  showCheckedStrategy = ShowChild,
  onDropdownVisibleChange = () => {},
  notFoundContent,
  disabled,
  className,
  placeholder,
  show = true,
  ...props
}: TreeSelectProps & { 'data-testid'?: string; show?: boolean }) =>
  !show ? (
    <></>
  ) : (
    <AntTreeSelect
      {...props}
      className={`ant-tree-select ${className || ''} data-testid-${
        props['data-testid']
      }`}
      maxTagCount="responsive"
      treeData={treeData}
      value={!value ? undefined : value}
      style={{
        height: 35,
        minHeight: 35,
        ...style
      }}
      treeCheckable={treeCheckable}
      placeholder={placeholder}
      showCheckedStrategy={showCheckedStrategy}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
      notFoundContent={notFoundContent}
      disabled={disabled}
    />
  );

interface DatePickerProps {
  onChange: any;
  format?: string;
  onCalendarChange: () => void;
  [key: string]: any;
}

export const DatePicker = ({
  onChange,
  ranges = timeRangeItems,
  label,
  format = 'DD/MM/YYYY HH:mm',
  showTime = {
    format: 'HH:mm'
  },
  onCalendarChange,
  ...props
}: DatePickerProps) => (
  <>
    {!!label && <span className="datepicker-label">{label}</span>}
    <RangePicker
      ranges={ranges}
      showTime={showTime}
      format={format}
      onChange={onChange}
      onCalendarChange={onCalendarChange}
      {...props}
    />
  </>
);

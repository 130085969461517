import React  from 'react';
import HashTable from 'utils/hash-table';

const ComponentHashTable = new HashTable(20);

const views = {
  Summary: 'Dashboard/Summary',

  Funnels: 'Funnel/Funnels',
  Links: 'Campaigns/Links',
  FunnelEditor: 'Funnel/FunnelEditor',

  TrafficSources: 'PagesAndSources/TrafficSources',
  OfferSources: 'PagesAndSources/OfferSources',
  Offers: 'PagesAndSources/Offers',
  Landers: 'PagesAndSources/Landers',
  Conditions: 'PagesAndSources/Conditions',
  PageGroups: 'PagesAndSources/PageGroups',

  Reporting: 'Analytics/Reporting',
  Hits: 'Analytics/Hits',
  CampaignAnalysis: 'Analytics/CampaignAnalysis',

  UpdateTrafficCosts: 'Updates/UpdateTrafficCosts',
  UpdateConversions: 'Updates/UpdateConversions',

  ResetData: 'Updates/ResetData',

  GeneralSettings: 'Settings/GeneralSettings',
  SystemSettings: 'Settings/SystemSettings',
  Domains: 'Settings/Domains',
  TrackingCodes: 'Settings/TrackingCodes',
  ApiAccess: 'Settings/ApiAccess',
  CustomEventMapping: 'Settings/CustomEventMapping',
  SubscriptionBilling: 'Settings/SubscriptionBilling',
  MigrationTools: 'Settings/MigrationTools',
  ProductPortal: 'Settings/ProductPortal',

  Labs: 'Labs/Labs',
  TrafficFiltering: 'Labs/TrafficFiltering',
  KeywordRotationLinks: 'Labs/KeywordRotationLinks',

  TrafficSourceIntegrations: 'Integrations/TrafficSource',
  OfferSourceIntegrations: 'Integrations/OfferSource',
  IntegratedUIConnections: 'Integrations/IntegratedUIConnections',
  OtherIntegrations: 'Integrations/Others',
  
  GoogleAdsReporting: 'Analytics/IntegratedUIs/GoogleAds',
  FacebookAdsReporting: 'Analytics/IntegratedUIs/FacebookAds',
  GoogleAdsIntegration: 'Integrations/TrafficSource/GoogleAds',
  ClickbankIntegration: 'Integrations/OfferSource/Clickbank',
  ExplodelyIntegration: 'Integrations/OfferSource/Explodely',  
  FacebookIntegration: 'Integrations/TrafficSource/Facebook',
  TwitterIntegration: 'Integrations/TrafficSource/Twitter',
  MicrosoftAdsIntegration:  'Integrations/TrafficSource/MicrosoftAds',
  TikTokIntegration: 'Integrations/TrafficSource/TikTok',
  RedditIntegration: 'Integrations/TrafficSource/Reddit',
  OptimizerIntegration: 'Integrations/Others/Optimizer',
  IntegratedGoogleAdsUI: 'Integrations/IntegratedUIConnections/IntegratedGoogleAdsUI',
  IntegratedFacebookAdsUI: 'Integrations/IntegratedUIConnections/IntegratedFacebookAdsUI',

  LoginView: 'LoginView'
};

Object.entries(views).forEach(([name, link]) =>
  ComponentHashTable.insert(name, React.lazy(() => import(`../view/${link}`)))
);

export default ComponentHashTable;

import React from 'react';
import './style.scss';
import { OkButton } from '../Parts/Buttons';
import FluxModal from '../Modal';

interface Props {
  pathname: string;
}
interface State {
  isDetected: boolean;
  isShow: boolean;
}

class AdBlock extends React.Component<Props, State> {
  state = {
    isDetected: false,
    isShow: true
  };

  componentDidMount() {
    const head = document.getElementsByTagName('head')[0];
    const noAdBlockDetected = () => {
      this.setState({ isDetected: false });
    };
    const adBlockDetected = () => {
      setTimeout(() => {
        this.setState({ isDetected: true });
      }, 5000);
    };
    const oldScript = document.getElementById('adblock-detection');
    if (oldScript) {
      head.removeChild(oldScript);
    }
    const script = document.createElement('script');
    script.id = 'adblock-detection';
    script.type = 'text/javascript';
    script.src = '/analytics/tracking/ads-tracker.js';
    script.onload = noAdBlockDetected;
    script.onerror = adBlockDetected;
    head.appendChild(script);
  }

  perform = () => this.setState({ isShow: false });

  render() {
    return (
      <>
        <FluxModal
          width={768}
          title="Adblock extension"
          isOpen={this.state.isDetected && this.state.isShow}
          actions={{
            onOk: this.perform,
            onClose: this.perform
          }}
          render={() => (
            <div className="c-Adblock">
              <p>
                Uh oh! It looks like you have some ad/script blocker that is
                interfering with our app, such as an Adblock extension.{' '}
              </p>
              <p>
                To ensure FunnelFlux Pro works as smoothly as possible, please
                use the extension's whitelist/disable feature to prevent
                unexpected behaviour on this site.{' '}
              </p>
              <p>If you keep seeing this message, reach out to our support. </p>

              <OkButton className="c-Adblock__btn" text={`OK, I understand`} onClick={this.perform} />
            </div>
          )}
        />
      </>
    );
  }
}

export default AdBlock;

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="filter-1--funnel-filter-round-oil">
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M26.874642857142856 0.9953571428571428H0.9953571428571428c0.0010988742857142857 2.9135298 0.983936415 5.741697771428572 2.789886535714286 8.02803312857143C5.5911798642857145 11.309705721428571 8.114848178571428 12.920790792857142 10.94892857142857 13.596578571428571v13.278064285714285l5.972142857142857 -3.9814285714285713V13.596578571428571c2.834080392857143 -0.6757877785714286 5.357808428571428 -2.28687285 7.163784428571428 -4.5731883C25.890632785714285 6.737054914285714 26.87344842857143 3.908886942857143 26.874642857142856 0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 0.9953571428571428H0.9953571428571428c0.0010988742857142857 2.9135298 0.983936415 5.741697771428572 2.789886535714286 8.02803312857143C5.5911798642857145 11.309705721428571 8.114848178571428 12.920790792857142 10.94892857142857 13.596578571428571v13.278064285714285l5.972142857142857 -3.9814285714285713V13.596578571428571c2.834080392857143 -0.6757877785714286 5.357808428571428 -2.28687285 7.163784428571428 -4.5731883C25.890632785714285 6.737054914285714 26.87344842857143 3.908886942857143 26.874642857142856 0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

import { User } from '../../model/user';
import {
  Loading,
  OfferStore,
  LanderStore,
  TrafficSourceStore,
  OfferSourceStore,
  FunnelGroupStore,
  ResetDataStore,
  FunnelStore,
  ReportingsStore,
  SummaryStore,
  QuickStatsStore,
  ConditionStore,
  AppStore,
  PageGroupStore,
  SystemSettingsStore,
  UpdateTrafficCostsStore,
  UpdateConversionsStore,
  IntegrationStore,
  BuilderStore,
  BillingStore,
  OnboardingStore,
  HitsStore,
  OverviewStore,
  CampaignAnalysisStore,
  KeywordRotationLinksStore,
  GoogleAdsStore,
  FacebookAdsStore
} from '../../types/redux/store';
import { Attribute } from '../../model/attribute';
import { AttributeData } from '../../model/attributeData';
import { defaultRestrictData } from 'constants/reportings';
import { DEFAULT_PAGE_SIZE } from '../../constants/table';
import { DEFAULT_TIME_RANGE, timeRangeItems } from 'constants/time';
import { TableParams } from '../../types/table';
import { DrilldownReportRowAttribute } from 'model/drilldownReportRowAttribute';
import {
  CONNECTIVITY_CATEGORY_TYPE,
  DEVICE_DATA_CATEGORY_TYPE,
  FUNNEL_CATEGORY_TYPE,
  LANDER_CATEGORY_TYPE,
  LOCATION_CATEGORY_TYPE,
  OFFER_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_TYPE,
  OVERVIEW_CATEGORY_TYPE,
  PAGE_GROUPS_CATEGORY_TYPE,
  TIME_SEGMENTATION_CATEGORY_TYPE,
  TRACKING_FIELDS_CATEGORY_TYPE,
  TRAFFIC_SOURCE_CATEGORY_TYPE,
  VISITOR_JOURNEY_CATEGORY_TYPE
} from '../../constants/modal';
import { defaultSelectedColumnsData } from 'utils/table/trafficCost';
import { defaultRowData } from 'constants/updateTrafficCosts';
import { TimeRangeKeys } from 'types';
import { getDatePickerValues } from '../../utils/time-helper';
import { defaultHeatmapExtraParams } from 'utils/builder';
import { DrilldownReportParams } from 'model/drilldownReportParams';
import { DrilldownReport } from 'model/drilldownReport';

const loading: Loading = {
  hash: '',
  isLoading: false,
  fetchedAt: 0
};

export const showDefault = 'active';

const loadingsDefaults = {
  data: loading,
  reporting: loading
};

export const reportingDefault: DrilldownReport = {
  columns: {
    attributes: []
  },
  rows: [],
  rootRows: [],
  entities: {
    categories: [],
    offerSources: []
  }
};

const dateTime = (timeRange: TimeRangeKeys) => getDatePickerValues(timeRangeItems[timeRange]);

const drilldownReportParamsDefault = (customRange = DEFAULT_TIME_RANGE): DrilldownReportParams => ({
  timeStart: dateTime(customRange).timeStart,
  timeEnd: dateTime(customRange).timeEnd,
  attributes: [],
  includeAssetsWithoutTraffic: true,
  returnUniqueVisitors: false,
  useEntranceTime: false
});

const tableParamsDefault: TableParams = {
  isFilterMode: false,
  isDrillMode: false,
  expandedRows: [],
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: 0,
  filter: {},
  sort: [],
  dateRangeName: DEFAULT_TIME_RANGE,
  lastChangedDate: '',
  tableColSizes: {},
  colorizeTableRows: true,
  returnUniqueVisitors: false
};

export const userDefault: User = {
  loggedIn: false,
  userId: '',
  email: '',
  emailVerified: false,
  name: '',
  nickname: '',
  picture: '',
  tokens: {
    accessToken: '',
    idToken: '',
    expiresAt: 0
  }
};

export const funnelGroupsAttributesReporting: AttributeData[] = [
  { attribute: Attribute.ElementFunnelGroup },
  { attribute: Attribute.ElementFunnel }
];

export const subcampaignAttributesReporting = [
  { attribute: Attribute.ElementFunnelGroup },
  { attribute: Attribute.ElementFunnel },
  {
    attribute: Attribute.URLTrackingField,
    whitelistFilters: ['{"campaign":"*"}']
  }
];

export const attributesFunnelCampaignReporting = [
  { attribute: Attribute.ElementFunnel },
  {
    attribute: Attribute.URLTrackingField,
    whitelistFilters: ['{"campaign":"*"}']
  }
];

export const attributesCampaignReporting = [
  { attribute: Attribute.ThirdPartiesTrafficSource },
  {
    attribute: Attribute.URLTrackingField,
    whitelistFilters: ['{"campaign":"*"}']
  }
];

export const emptyFunnelAttribute = {
  id: '',
  status: 'active',
  value: ''
} as DrilldownReportRowAttribute;

export const funnelGroups: FunnelGroupStore = {
  data: {},
  settings: {
    show: showDefault,
    filters: {
      ...drilldownReportParamsDefault(),
      attributes: funnelGroupsAttributesReporting
    },
    additionalParams: {},
    tableCols: [],
    tableParams: tableParamsDefault,
    editMode: true
  },
  reporting: reportingDefault,
  loadings: loadingsDefaults
};

export const quickStats: QuickStatsStore = {
  tableCols: {
    [FUNNEL_CATEGORY_TYPE]: [],
    [LANDER_CATEGORY_TYPE]: [],
    [OFFER_CATEGORY_TYPE]: [],
    [OFFER_SOURCE_CATEGORY_TYPE]: [],
    [PAGE_GROUPS_CATEGORY_TYPE]: [],
    [TRAFFIC_SOURCE_CATEGORY_TYPE]: [],
    [VISITOR_JOURNEY_CATEGORY_TYPE]: [],
    [LOCATION_CATEGORY_TYPE]: [],
    [DEVICE_DATA_CATEGORY_TYPE]: [],
    [CONNECTIVITY_CATEGORY_TYPE]: [],
    [TIME_SEGMENTATION_CATEGORY_TYPE]: [],
    [TRACKING_FIELDS_CATEGORY_TYPE]: []
  },
  sortModels: {},
  tableColSizes: {},
  reporting: reportingDefault,
  params: {} as any,
  tableParams: {
    ...tableParamsDefault,
    colorizeTableRows: true,
    isFilterMode: true
  },
  settings: {
    show: showDefault,
    filters: {
      ...drilldownReportParamsDefault(DEFAULT_TIME_RANGE),
      includeAssetsWithoutTraffic: false
    }
  }
};

export const facebookAds: FacebookAdsStore = {
  adAccounts: {
    data: [],
    paging: {
      cursors: {
        before: '',
        after: ''
      }
    }
  },
  users: [],
  reportingInitial: reportingDefault,
  reporting: reportingDefault,
  settings: {
    tableCols: [],
    tableColSizes: {},
    tableParams: tableParamsDefault,
    params: {
      date: '',
      dateRangeName: DEFAULT_TIME_RANGE,
      sortModel: [
        {
          colId: 'visits',
          sort: 'desc'
        }
      ],
      trafficSourceId: '',
      adAccount: {
        id: '',
        name: '',
        account_id: '',
        currency: 'USD'
      },
      primaryDataGrouping: 'campaign',
      optionalSegmentation: 'none',
      user: {}
    }
  }
};

export const googleAds: GoogleAdsStore = {
  reporting: reportingDefault,
  reportingInitial: reportingDefault,
  users: {
    allUsers: [],
    customerClients: [],
    currentUser: {},
    currentCustomerClient: {}
  },
  settings: {
    tableCols: [],
    tableColSizes: {},
    params: {
      date: '',
      dateRangeName: DEFAULT_TIME_RANGE,
      sortModel: [
        {
          colId: 'visits',
          sort: 'desc'
        }
      ],
      primaryDataGrouping: 'campaign',
      optionalSegmentation: 'none',
      trafficSourceId: '',
      filterByStatus: ['ENABLED']
    },
    tableParams: tableParamsDefault
  }
};

export const reportings: ReportingsStore = {
  reporting: reportingDefault,
  reportingInitial: reportingDefault,
  settings: {
    tableCols: [],
    tableColSizes: {},
    view: {
      views: []
    },
    params: {
      customFields: [],
      date: '',
      showAttributes: [],
      restrictData: defaultRestrictData(),
      dateRangeName: DEFAULT_TIME_RANGE,
      sortModel: [
        {
          colId: 'visits',
          sort: 'desc'
        }
      ]
    }
  },
  restrictFilter: {
    attributes: []
  },
  tableParams: tableParamsDefault,
  attributes: [],
  loading: loading
};

export const funnels: FunnelStore = {
  data: {},
  loading: loading,
  hasBuilderChangedData: false,
  isCloseAllFunnelTabsModalOpen: false
};

export const summaryData = {
  reporting: reportingDefault,
  loadings: loadingsDefaults
};

export const summary: SummaryStore = {
  [FUNNEL_CATEGORY_TYPE]: summaryData,
  [TRAFFIC_SOURCE_CATEGORY_TYPE]: summaryData,
  [LANDER_CATEGORY_TYPE]: summaryData,
  [OFFER_CATEGORY_TYPE]: summaryData,
  [PAGE_GROUPS_CATEGORY_TYPE]: summaryData,
  [OVERVIEW_CATEGORY_TYPE]: summaryData,
  tableCols: {
    [FUNNEL_CATEGORY_TYPE]: [],
    [TRAFFIC_SOURCE_CATEGORY_TYPE]: [],
    [LANDER_CATEGORY_TYPE]: [],
    [OFFER_CATEGORY_TYPE]: [],
    [PAGE_GROUPS_CATEGORY_TYPE]: []
  },
  graph: {
    reporting: reportingDefault,
    loadings: loadingsDefaults
  },
  settings: {
    showGraph: true,
    timeZone: '',
    activeQuickReportButton: 'Overview',
    isDrillMode: false,
    expandedRows: [],
    reloadData: 'none',
    isFilterMode: false,
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 0,
    filter: {},
    colorizeTableRows: false,
    dateRangeName: DEFAULT_TIME_RANGE,
    returnUniqueVisitors: false,
    lastChangedDate: '',
    tableColSizes: {}
  },
  filters: {
    ...drilldownReportParamsDefault(),
    includeAssetsWithoutTraffic: false
  }
};

export const landersAttributesReporting: AttributeData[] = [{ attribute: Attribute.ElementLander }];

export const landers: LanderStore = {
  data: {},
  settings: {
    show: showDefault,
    filters: {
      ...drilldownReportParamsDefault(),
      attributes: landersAttributesReporting
    },
    tableCols: [],
    tableParams: tableParamsDefault,
    editMode: true
  },
  reporting: reportingDefault,
  categories: [],
  loadings: loadingsDefaults
};

export const offersAttributesReporting: AttributeData[] = [
  { attribute: Attribute.ThirdPartiesOfferSource },
  { attribute: Attribute.ElementOffer }
];

export const offers: OfferStore = {
  data: {},
  settings: {
    show: showDefault,
    filters: {
      ...drilldownReportParamsDefault(),
      attributes: [{ attribute: Attribute.ElementOffer }, { attribute: Attribute.ThirdPartiesOfferSource }]
    },
    additionalParams: {},
    tableCols: [],
    tableParams: tableParamsDefault,
    editMode: true
  },
  reporting: reportingDefault,
  categories: [],
  loadings: loadingsDefaults
};

export const overview: OverviewStore = {
  reporting: reportingDefault,
  loadings: loadingsDefaults
};

export const resetData: ResetDataStore = {
  value: 0,
  loadings: loadingsDefaults,
  reporting: reportingDefault,
  settings: {
    switches: {
      campaign: false,
      country: false,
      visitorIDs: false,
      IPs: false,
      URLTrackingField: false
    },
    data: {
      timeStart: '',
      timeEnd: '',
      restrictToCampaignIDs: [],
      restrictToFunnelIDs: [],
      restrictToTrafficSourceIDs: [],
      restrictToCountryCodes: [],
      restrictToVisitorIDs: [],
      restrictToIPs: [],
      restrictToTrackingFields: {}
    },
    URLTrackingFieldData: {
      selectValue: '',
      textAreaValue: ''
    },
    collapsibleVisibility: false
  }
};

export const updateTrafficCosts: UpdateTrafficCostsStore = {
  data: {
    funnelIDs: [],
    trafficSourceIDs: [],
    timeStart: drilldownReportParamsDefault().timeStart,
    timeEnd: drilldownReportParamsDefault().timeEnd,
    costSegments: [
      {
        cost: '0',
        costType: 'wholeSegment',
        restrictToDeviceType: '' as any,
        restrictToConnectionType: '' as any,
        restrictToCountryCode: '',
        restrictToBrowser: '',
        restrictToTrackingFields: {}
      }
    ]
  },
  campaign: {
    data: reportingDefault,
    value: ''
  },
  settings: {
    restrictByAttributes: {
      restrictToDeviceType: '',
      restrictToConnectionType: '',
      restrictToCountryCode: '',
      restrictToBrowser: ''
    },
    showRestrictByAttributes: false,
    updateType: 'simple',
    restrictToSingleCampaignId: false,
    includeCampaignIds: false,
    costType: 'wholeSegment',
    restrictToTrackingFields: {},
    simpleModeCost: '0',
    selectedColumns: defaultSelectedColumnsData
  },
  rowData: defaultRowData()
};

export const updateConversions: UpdateConversionsStore = {
  data: {
    overrideDefaultOffer: false,
    overridePayout: undefined,
    textarea: '',
    disablePostbacks: false
  }
};

export const offerSourcesAttributesReporting: AttributeData[] = [{ attribute: Attribute.ThirdPartiesOfferSource }];

export const offerSources: OfferSourceStore = {
  data: {},
  settings: {
    tableCols: [],
    tableParams: tableParamsDefault,
    editMode: true,
    show: showDefault,
    filters: {
      ...drilldownReportParamsDefault(),
      attributes: offerSourcesAttributesReporting
    }
  },
  reporting: reportingDefault,
  categories: [],
  loadings: loadingsDefaults
};

export const systemSettings: SystemSettingsStore = {
  data: {
    defaultHomepageURL: '',
    defaultOfferRedirect: '307',
    defaultLanderRedirect: '307',
    ipAnonymizer: 'All IPs',
    defaultCustomDomain: '',
    thirdPartyKeys: {
      clickbankIPN: ''
    }
  },
  tooltips: {
    editMode: true
  },
  domains: []
};

export const integrations: IntegrationStore = {
  data: []
};

export const keywordRotation: KeywordRotationLinksStore = {
  data: {
    funnelNodeOptions: {},
    funnelId: '',
    funnelNodeId: '',
    trafficSourceId: '',
    cost: '',
    workerDomain: '',
    trackingDomain: '',
    trackingFields: [],
    keywordRotations: []
  }
};

export const trafficSourcesAttributesReporting: AttributeData[] = [{ attribute: Attribute.ThirdPartiesTrafficSource }];

export const trafficSources: TrafficSourceStore = {
  data: {},
  settings: {
    tableCols: [],
    tableParams: tableParamsDefault,
    editMode: true,
    show: showDefault,
    filters: {
      ...drilldownReportParamsDefault(),
      attributes: trafficSourcesAttributesReporting
    }
  },
  reporting: reportingDefault,
  categories: [],

  loadings: loadingsDefaults
};

export const navDefault = {
  current: '',
  prev: '',
  prevPath: '',
  sub: [],
  funnelEditorSub: [],
  lastViewRoute: {},
  siderMenuIsExpanded: true
};

export const conditions: ConditionStore = {
  data: {},
  loading: loading,
  categories: [],
  settings: {
    editMode: false
  }
};

export const campaignAnalysis: CampaignAnalysisStore = {
  loadings: loadingsDefaults,
  settings: {
    show: showDefault,
    filters: {
      ...drilldownReportParamsDefault(),
      attributes: [
        { attribute: Attribute.ElementFunnel },
        { attribute: Attribute.ThirdPartiesTrafficSource },
        {
          attribute: Attribute.URLTrackingField,
          whitelistFilters: ['{"campaign":"*"}']
        }
      ],
      includeAssetsWithoutTraffic: false
    },
    additionalParams: {},
    tableCols: [],
    tableParams: {
      ...tableParamsDefault,
      isFilterMode: true,
      colorizeTableRows: true
    },
    editMode: true
  },
  reporting: reportingDefault
};

export const pageGroupsAttributesReporting: AttributeData[] = [{ attribute: Attribute.ThirdPartiesTrafficSource }];

export const pageGroups: PageGroupStore = {
  data: {},
  loading: loading,
  reporting: {
    landers: reportingDefault,
    offers: reportingDefault
  },
  settings: {
    tableCols: [],
    tableParams: tableParamsDefault,
    editMode: true,
    filters: {
      landers: {
        ...drilldownReportParamsDefault(),
        attributes: landersAttributesReporting
      },
      offers: {
        ...drilldownReportParamsDefault(),
        attributes: offersAttributesReporting
      }
    },
    additionalParams: {},
    show: showDefault
  }
};

export const app: AppStore = {
  version: '',
  unreadChatCount: 0
};

export const builder: BuilderStore = {
  zooms: {},
  pans: {},
  heatmap: {
    enabled: false,
    isOpen: false,
    date: getDatePickerValues(timeRangeItems[DEFAULT_TIME_RANGE]),
    mode: 'basicStats',
    reporting: {
      columns: {
        attributes: []
      },
      rootRows: [],
      rows: []
    },
    filters: {
      switches: {
        restrictByBrowser: false,
        restrictByCampaignID: false,
        restrictByCountry: false,
        restrictByDeviceType: false,
        restrictByTrafficsource: false,
        whitelistBrowser: true,
        whitelistCampaignID: true,
        whitelistCountry: true,
        whitelistDeviceType: true,
        whitelistTrafficsource: true
      },
      trafficsourceIds: [],
      campaignID: '',
      countryIds: [],
      browserIds: [],
      deviceIds: []
    },
    heatmapReport: {},
    heatmapExtraParams: defaultHeatmapExtraParams()
  }
};

export const billing: BillingStore = {
  quota: { billingCycles: [] }
};

export const onboarding: OnboardingStore = {
  status: {
    tasks: [],
    totalScore: 0
  }
};

export const hits: HitsStore = {
  report: { rows: [] },
  loading: loading,
  settings: {
    model: {
      timeStart: '',
      timeEnd: '',
      pagingLength: 100,
      sort: 'desc',
      includeClicks: false,
      includeConversions: false,
      includeHits: true
    },
    tableParams: tableParamsDefault,
    tableCols: ['eventType', 'eventTime', 'hitID', 'visitorID', 'locationCountry', 'nodeID', 'nodeName', 'external']
  }
};

import { AnyObject } from '../../types';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { defined } from '../define';
import { Attribute } from '../../model/attribute';
import { DrilldownReportRowAttribute } from '../../model/drilldownReportRowAttribute';
import { COMPOSITE_ID_SEPARATOR } from '../../constants/table';
import {
  OfferStore,
  LanderStore,
  TrafficSourceStore,
  OfferSourceStore
} from 'types/redux/store';
import { TrafficSource } from '../../model/trafficSource';
import { Page } from 'model/page';
import { Dispatch } from 'redux';
import {
  Category,
  Funnel,
  FunnelCondition,
  FunnelGroup,
  OfferSource,
  PageGroup
} from 'model/models';
import { generateEntityId } from '../id/generator';
import { DrilldownReportColumns } from '../../model/drilldownReportColumns';
import { RootData } from '../../types/reporting';
import { equalObjects } from '../object';
import { naturalSort } from '../sort';
import { TrafficSourceInfo } from 'model/trafficSourceInfo';
import { PageCategoryInfo } from 'model/pageCategoryInfo';
import { PageGroupInfo } from 'model/pageGroupInfo';
import { PageInfo } from 'model/pageInfo';
import { FunnelGroupInfo } from 'model/funnelGroupInfo';
import { FunnelInfo } from 'model/funnelInfo';
import { FunnelConditionInfo } from 'model/funnelConditionInfo';
import { FunnelConditionCategoryInfo } from 'model/funnelConditionCategoryInfo';
import { TrafficSourceCategoryInfo } from 'model/trafficSourceCategoryInfo';
import { DrilldownReport } from 'model/drilldownReport';

export const makeCompositeId = (attributes: DrilldownReportRowAttribute[]) => {
  const listOfEntranceLevelAttributes = [
    Attribute.URLTrackingField,
    Attribute.ElementFunnelGroup,
    Attribute.ElementFunnel,
    Attribute.ThirdPartiesTrafficSource,
    Attribute.TrafficVisitorTag,
    Attribute.TrafficFilteredStatus,
    Attribute.TrafficTrackingDomain,
    Attribute.DeviceDeviceType,
    Attribute.DeviceBrand,
    Attribute.DeviceModel,
    Attribute.DeviceModelName,
    Attribute.DeviceOS,
    Attribute.DeviceOSVersion,
    Attribute.DeviceBrowser,
    Attribute.DeviceBrowserVersion,
    Attribute.DeviceMainLanguage,
    Attribute.ConnectionConnectionType,
    Attribute.ConnectionISP,
    Attribute.ConnectionMobileCarrier,
    Attribute.ConnectionTypeCarrierISP,
    Attribute.ConnectionReferrer,
    Attribute.ConnectionReferrerDomain,
    Attribute.LocationContinent,
    Attribute.LocationCountry,
    Attribute.LocationRegion,
    Attribute.LocationCity,
    Attribute.LocationTimezone,
    Attribute.InsightTimeToConversionSegments,
    Attribute.TimeDate,
    Attribute.TimeWeekParting,
    Attribute.TimeDayParting,
    Attribute.Time10MinuteBlocks,
    Attribute.Time30MinuteBlocks,
    Attribute.Time1HourBlocks,
    Attribute.Time3HourBlocks
  ];

  const compositeId: string[] = [];
  for (let i = 0; i < attributes.length; i++) {
    if (
      listOfEntranceLevelAttributes.includes(
        attributes[i].attribute as Attribute
      )
    ) {
      compositeId.push(attributes[i].id);
    } else {
      break;
    }
  }
  return compositeId;
};

export const getCompositeId = (attributes: DrilldownReportRowAttribute[]) => {
  return defined(attributes) ? attributes.map(item => item.id) : [];
};

export const convertJourneyToRows = (
  columns: DrilldownReportColumns,
  rows: DrilldownReportRow[],
  hasTrackingFields?: boolean
): [number, DrilldownReportRow[], RootData] => {
  let maxAttributeCount = 0;
  let rootRows: RootData = {};

  if (Array.isArray(rows)) {
    let journeyIdx = columns.attributes.findIndex(
      item =>
        defined(item) &&
        [
          Attribute.InsightVisitorJourney,
          Attribute.InsightVisitorJourneyAllNodes
        ].includes(item)
    );

    const excluded = [
      'cost',
      'nodeViews',
      'nodeViewsUnique',
      'indirectConversions',
      'indirectRevenue',
      'visitors',
      'visits',
      'attributes'
    ];

    const clear = (row: DrilldownReportRow) => {
      const data: AnyObject = { ...row };
      excluded.forEach(field => {
        delete data[field];
      });
      return data;
    };

    if (journeyIdx >= 0) {
      if (hasTrackingFields) {
        journeyIdx += 1;
      }
      const compositeIdForDuplicates: string[] = [];

      rows.forEach((row, rowIdx) => {
        const compositeId: string[] = [];
        const compositeId2: string[] = [];

        if (
          defined(row.attributes[journeyIdx]) &&
          /\[>>\]/gm.test(row.attributes[journeyIdx].value)
        ) {
          const splitValues = row.attributes[journeyIdx].value.split(
            /\[>>\]/gm
          );
          const splitIds = row.attributes[journeyIdx].id.split(/,/gm);
          const splitInfo = defined(row.attributes[journeyIdx].info)
            ? row.attributes[journeyIdx].info!.split(/,/gm)
            : [];

          if (splitValues.length > maxAttributeCount) {
            maxAttributeCount = splitValues.length;
          }

          row.attributes = row.attributes.reduce(
            (
              acc: DrilldownReportRowAttribute[],
              item: DrilldownReportRowAttribute,
              idx: number
            ) => {
              if (idx === journeyIdx) {
                splitValues.forEach((item: string, splitIdx: number) => {
                  const id = splitIds[splitIdx] || generateEntityId();
                  const info = splitInfo[splitIdx] || '';
                  compositeId.push(id);
                  acc.push({
                    id: id,
                    value: item.trim(),
                    status: 'active',
                    info: info,
                    attribute: Attribute.InsightVisitorJourneyAllNodes
                  });
                });
              } else {
                compositeId.push(item.id);
                compositeId2.push(item.id);
                acc.push(item);
              }
              return acc;
            },
            []
          );
        } else {
          row.attributes.forEach(
            (item: DrilldownReportRowAttribute, idx: number) => {
              compositeId.push(item.id);

              if (idx < row.attributes.length - 1) {
                compositeId2.push(item.id);
              }
            }
          );
        }

        /*rootRows[compositeId.join(COMPOSITE_ID_SEPARATOR)] = [clear(row)];
        rootRows[compositeId2.join(COMPOSITE_ID_SEPARATOR)] = [
          ...(rootRows[compositeId2.join(COMPOSITE_ID_SEPARATOR)] || []),
          clear(row)
        ];*/

        compositeIdForDuplicates.push(compositeId.join(COMPOSITE_ID_SEPARATOR));
      });

      compositeIdForDuplicates.sort(naturalSort).reverse();
      compositeIdForDuplicates.forEach((id, idx) => {
        compositeIdForDuplicates.forEach((id2, idx2) => {
          if (id.includes(id2) && id !== id2) {
            delete compositeIdForDuplicates[idx2];
          }
        });
      });

      const rows2: DrilldownReportRow[] = [];
      // remove duplicates
      rows.forEach(row => {
        if (
          compositeIdForDuplicates.includes(
            getCompositeId(row.attributes).join(COMPOSITE_ID_SEPARATOR)
          )
        ) {
          rows2.push(row);
        } else {
          rootRows[
            getCompositeId(row.attributes).join(COMPOSITE_ID_SEPARATOR)
          ] = [row];
        }
      });

      rows = rows2;
    }
  }
  maxAttributeCount--;

  return [maxAttributeCount, rows, rootRows];
};

export const convertRootRows = (
  columns: DrilldownReportColumns,
  rootRows: DrilldownReportRow[],
  rows?: DrilldownReportRow[]
): RootData => {
  let rootData: RootData = {};
  let rowsData = [...(rootRows || []), ...(rows || [])];
  if (defined(rowsData)) {
    const roots: { [key: string]: DrilldownReportRow } = rowsData.reduce(
      (acc: AnyObject, item) => {
        const compositeId = getCompositeId(item.attributes).join(
          COMPOSITE_ID_SEPARATOR
        );

        if (compositeId !== '') {
          acc[compositeId] = {
            visitors: item.visitors,
            visits: item.visits,
            cost: item.cost,
            conversions: item.conversions,
            revenue: item.revenue,
            landerViews: item.landerViews,
            landerClicks: item.landerClicks,
            offerViews: item.offerViews,
            customEvents: item.customEvents,
            offerClicks: item.offerClicks,
            googleClicks: item.googleClicks,
            googleCostMicros: item.googleCostMicros,
            googleImpressions: item.googleImpressions,
            googleCtr: item.googleCtr,
            googleAverageCpm: item.googleAverageCpm,
            googleAverageCpc: item.googleAverageCpc,
          };
        }
        return acc;
      },
      {}
    );

    rowsData.forEach(item => {
      const compositeId = getCompositeId(item.attributes).join(
        COMPOSITE_ID_SEPARATOR
      );

      let ids = [compositeId];

      if (defined(roots[compositeId])) {
        ids.forEach(id => {
          if (!rootData[id]) {
            rootData[id] = [
              {
                visitors: roots[compositeId].visitors,
                visits: roots[compositeId].visits,
                cost: roots[compositeId].cost,
                conversions: roots[compositeId].conversions,
                revenue: roots[compositeId].revenue,
                landerViews: roots[compositeId].landerViews,
                landerClicks: roots[compositeId].landerClicks,
                offerViews: roots[compositeId].offerViews,
                customEvents: roots[compositeId].customEvents,
                offerClicks: roots[compositeId].offerClicks,
                googleClicks: roots[compositeId].googleClicks,
                googleCostMicros: roots[compositeId].googleCostMicros,
                googleImpressions: roots[compositeId].googleImpressions,
                googleCtr: roots[compositeId].googleCtr,
                googleAverageCpm: roots[compositeId].googleAverageCpm,
                googleAverageCpc: roots[compositeId].googleAverageCpc,
                forceChange: [
                  'conversions',
                  'revenue',
                  'landerViews',
                  'landerClicks',
                  'offerViews',
                  'offerClicks'
                ]
              }
            ];
          }
        });
      }
    });
  }

  return rootData;
};

export const mergeRootRows = (root: RootData, root2: RootData) => {
  Object.keys(root2).forEach(key => {
    if (defined(root[key]) && !equalObjects(root[key], root2[key])) {
      root[key] = [...root[key], ...root2[key]];
    } else {
      root[key] = root2[key];
    }
  });

  return root;
};

export const archiveCategoryRelatedEntities = (
  dispatch: Dispatch,
  store: LanderStore | OfferStore | TrafficSourceStore | OfferSourceStore,
  category: Category,
  callback: Function
) => {
  if (category.status === 'archived' && defined(store) && defined(dispatch)) {
    Object.values(store.data)
      .filter(
        (item: Page | TrafficSource | OfferSource) =>
          !!item.idCategory &&
          item.idCategory === category.idCategory &&
          item.status === 'active'
      )
      .forEach((page: Page | TrafficSource | OfferSource) => {
        dispatch(
          callback({
            ...page,
            status: 'archived'
          })
        );
      });
  }
};

export const infoToResource = {
  funnelGroup: (data: FunnelGroupInfo): FunnelGroup => ({
    campaignName: data.campaignName,
    idCampaign: data.idCampaign,
    status: data.status
  }),
  funnel: (
    funnelInfo: FunnelInfo,
    funnelGroupInfo: FunnelGroupInfo
  ): Funnel => ({
    funnelName: funnelInfo.funnelName,
    idFunnel: funnelInfo.idFunnel,
    idCampaign: funnelGroupInfo.idCampaign,
    status: funnelInfo.status
  }),
  trafficSourceCategory: (
    trafficsourceCategoryInfo: TrafficSourceCategoryInfo
  ): Category => ({
    idCategory: trafficsourceCategoryInfo.idCategory,
    categoryName: trafficsourceCategoryInfo.categoryName,
    status: trafficsourceCategoryInfo.status,
    categoryType: 'trafficsources'
  }),
  trafficSource: (
    trafficsourceInfo: TrafficSourceInfo,
    trafficsourceCategoryInfo: TrafficSourceCategoryInfo
  ): TrafficSource => ({
    idTrafficSource: trafficsourceInfo.idTrafficSource,
    trafficSourceName: trafficsourceInfo.trafficSourceName,
    status: trafficsourceInfo.status,
    idCategory: trafficsourceCategoryInfo.idCategory,
    costType: 'cpa'
  }),
  pageCategory: (
    data: PageCategoryInfo,
    categoryType: Category.CategoryTypeEnum
  ): Category => ({
    idCategory: data.idCategory,
    categoryName: data.categoryName,
    status: data.status,
    categoryType
  }),
  page: (
    pageInfo: PageInfo,
    pageType: Page.PageTypeEnum,
    pageCategoryInfo?: Partial<PageCategoryInfo>
  ): Page => ({
    idPage: pageInfo.idPage,
    pageName: pageInfo.pageName,
    baseURL: pageInfo.baseUrl,
    redirectType: '301',
    pageType,
    idCategory: pageCategoryInfo?.idCategory || pageInfo.idCategory,
    status: pageInfo.status,
    disableAppendVid: false
  }),
  conditionCategory: (data: FunnelConditionCategoryInfo): Category => ({
    idCategory: data.idCategory,
    categoryName: data.categoryName,
    status: data.status,
    categoryType: 'condition'
  }),
  condition: (
    conditionInfo: FunnelConditionInfo,
    conditionCategoryInfo: Partial<FunnelConditionCategoryInfo>
  ): FunnelCondition => ({
    idCondition: conditionInfo.idCondition,
    conditionName: conditionInfo.conditionName,
    status: conditionInfo.status,
    routes: [],
    idCategory: conditionCategoryInfo.idCategory
  }),
  pageGroup: (
    data: PageGroupInfo,
    pageType: PageGroup.PageTypeEnum
  ): PageGroup => ({
    idPageGroup: data.idPageGroup,
    pageGroupName: data.pageGroupName,
    status: data.status,
    routing: 'rotator',
    pages: (data.pages || []).map(pinfo => ({
      idPage: pinfo.idPage,
      weight: 1
    })),
    pageType
  })
};

// this is a temporary utility until BE adds support
// for visitor journey pages only
export const getFilteredJourneyReportRows = (
  response: DrilldownReport,
  includesGroups = false
): DrilldownReportRow[] => {
  return response.rows
    .filter(row => {
      const splitedInfo = row.attributes
        .find(
          reportRowAttribute =>
            reportRowAttribute.attribute ===
            Attribute.InsightVisitorJourneyAllNodes
        )
        ?.info?.split(',');
      const lastInfo = Array.isArray(splitedInfo)
        ? splitedInfo!.pop()
        : undefined;

      if (lastInfo) {
        if (
          lastInfo === 'lander' ||
          lastInfo === 'offer' ||
          (includesGroups
            ? lastInfo === 'landerGroup' || lastInfo === 'offerGroup'
            : false)
        ) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    })
    .map(row => ({
      ...row,
      attributes: row.attributes.map(reportRowAttribute => {
        if (
          reportRowAttribute.attribute ===
          Attribute.InsightVisitorJourneyAllNodes
        ) {
          let helperIdx = 0;
          let splitedInfo = reportRowAttribute.info?.split(',');
          let splitedId = reportRowAttribute.id?.split(',');
          let splitedValue = reportRowAttribute.value?.split('[>>]');

          splitedInfo = (splitedInfo || []).filter((info, idx) => {
            if (
              !(
                info === 'lander' ||
                info === 'offer' ||
                (includesGroups
                  ? info === 'landerGroup' || info === 'offerGroup'
                  : false)
              )
            ) {
              splitedId!.splice(idx - helperIdx, 1);
              splitedValue!.splice(idx - helperIdx, 1);
              helperIdx += 1;
              return false;
            }
            return true;
          });

          reportRowAttribute.info = splitedInfo.join(',');
          reportRowAttribute.id = splitedId!.join(',');
          reportRowAttribute.value = splitedValue!.join(' [>>] ');
        }
        return reportRowAttribute;
      })
    }));
};

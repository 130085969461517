import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.64 -0.64 18 18">
    <g id="arrow-down-3--arrow-down-keyboard">
      <path
        id="Vector"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m0.5971428571428572 4.598119428571429 7.344857142857144 7.344857142857144c0.05361148571428572 0.05708685714285715 0.11836565714285716 0.10270857142857143 0.19026165714285717 0.13376000000000002 0.07188405714285714 0.031170857142857146 0.14939320000000003 0.04717428571428572 0.22773834285714287 0.04717428571428572 0.07834514285714288 0 0.15585428571428572 -0.016003428571428575 0.22773834285714287 -0.04717428571428572 0.071896 -0.031051428571428574 0.13665017142857144 -0.07667314285714286 0.19026165714285717 -0.13376000000000002l7.344857142857144 -7.344857142857144"
        strokeWidth="1.28"
      ></path>
    </g>
  </svg>
);

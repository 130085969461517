import React, { Component } from 'react';
import { ModalButtonGroup } from '../../Parts/Groups';
import { withLoading } from '../../Loading';
import { LoadingProps } from '../../../types/loading';

interface Props extends LoadingProps {
  onClose(): void;
  onSubmit(): void;
}

class DeleteDomains extends Component<Props, {}> {
  render() {
    return (
      <div className="padding-x-25 padding-y-20">
        <p className="font-size-14">
          Deleting this domain will deauthorise it, which <strong className="color-red">will cause existing links to stop working.</strong>
        </p>
        <p className="font-size-14 font-weight-700">Please remove with care.</p>
        <p>Note you can add the domain back at any time.</p>
        <p>The deletion process takes some time, please wait patiently after clicking the remove domain button.</p>
        <div className="ff_buttons">
          <ModalButtonGroup
            showOk
            okText={'Remove Domain'}
            showCancel
            cancelText={'Cancel'}
            loading={this.props.loading}
            onOkClicked={async () => {
              await this.props.startLoading('ok');
              await this.props.onSubmit();
              await this.props.stopLoading('ok');
              this.props.onClose();
            }}
            onCancelClicked={this.props.onClose}
          />
        </div>
      </div>
    );
  }
}

export default withLoading(DeleteDomains);

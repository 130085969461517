import React from 'react';
import { ModalButtonGroup } from '../../Parts/Groups';
import Messages from 'components/Messages';
import { MessageProps } from '../../../types';
import { withLoading } from '../../Loading';
import { LoadingProps } from '../../../types/loading';
import { DomainEntry } from '../../../model/domains/domainEntry';
import { FFButton, FFInput, VisibilityWrapper } from 'uikit';
import { Alert, Space } from 'antd';
import {
  ReactiveValidateState,
  ReactiveValidateComponent
} from 'utils/reactive/generic';
import { ValidationRule } from 'utils/validation/types';
import validateForm, {
  required,
  unique,
  cloudflareHostname,
  domainContainFunnelflux
} from 'utils/validation';

interface Props extends MessageProps, LoadingProps {
  onClose(): void;
  onSubmit(domain: Partial<DomainEntry>): void;
  domains: DomainEntry[];
}

interface State extends ReactiveValidateState {
  newDomainName: string;
  showRootDomainAlert: boolean;
  rootDomainSubmitted: boolean;
}

class AddDomain extends ReactiveValidateComponent<Props, State> {
  state: State = {
    newDomainName: '',
    isTouchedForm: false,
    validationErrors: {},
    showRootDomainAlert: false,
    rootDomainSubmitted: false
  };

  handleSetState = async <T extends State, P extends keyof T>(
    name: P,
    value: T[P]
  ) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  validationRules = (): ValidationRule[] => [
    {
      field: 'newDomainName',
      validations: [
        required,
        domainContainFunnelflux,
        unique(
          this.props.domains.map(domainEntry => domainEntry.domain),
          'domain'
        ),
        cloudflareHostname
      ]
    }
  ];

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {} });
      const [validationErrors] = validateForm(
        this.state,
        this.validationRules()
      );
      this.setState((state: State) => ({
        ...state,
        validationErrors: validationErrors
      }));
    }
  };

  validateClassName = (field: string) =>
    !!this.state.validationErrors[field] ? 'has-error' : '';

  validateTooltip = (field: string) => {
    return !!this.state.validationErrors[field]
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  submit = async () => {
    await this.setTouchedForm();
    await this.validate();

    if (new RegExp(/^[^.]+\.[^.]+$/g).test(this.state.newDomainName)) {
      this.handleSetState('showRootDomainAlert', true);
      if (!this.state.rootDomainSubmitted) {
        await this.handleSetState(
          'rootDomainSubmitted',
          true
        );
        return;
      }
    }

    this.handleSetState('showRootDomainAlert', false);
    if (!Object.keys(this.state.validationErrors).length) {
      this.props.startLoading('ok');
      await this.props.onSubmit({
        domain: this.state.newDomainName
      });
      this.props.stopLoading('ok');
      this.props.onClose();
    }
  };

  render() {
    return (
      <div className="flex flex-col padding-x-25 padding-y-20">
        <span className="margin-bottom-5">Enter the custom domain to add:</span>
        <FFInput
          id="domains"
          name="domains"
          data-testid="domains"
          placeholder="domain.com, or sub.domain.com"
          onChange={e =>
            this.setState({ newDomainName: e.target.value.toLowerCase() })
          }
          value={this.state.newDomainName}
          error={this.validateTooltip('newDomainName')}
          className="margin-bottom-15"
        />
        <VisibilityWrapper visible={this.state.showRootDomainAlert}>
          <Alert
            description={`Many DNS providers other than Cloudflare (e.g. GoDaddy, Namecheap etc.) will not allow you to create a CNAME record for a root domain. If you wish to use "domain.com" for tracking, we highly recommend managing your domain DNS in Cloudflare. If you understand and are sure you want to use this root domain, click the add button one more time.`}
            type="warning"
            className="margin-bottom-15"
          />
        </VisibilityWrapper>
        <div className="flex flex-gap-10 margin-top-10">
          <FFButton loading={this.props.loading.ok} onClick={this.submit}>
            Add
          </FFButton>
          <FFButton
            disabled={this.props.loading.ok}
            onClick={this.props.onClose}
            variant="outlined"
          >
            Cancel
          </FFButton>
        </div>
      </div>
    );
  }
}

export default withLoading(Messages.injectIn(AddDomain));

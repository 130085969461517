import React from 'react';
import Messages from 'components/Messages';
import { AnyObject, MessageProps } from 'types';
import { FormSectionBox } from 'components/Parts/Content';
import Icon from 'components/Icons';
import { Input } from 'components/Parts/Inputs';
import { SetRuleEditorModel } from 'types/ModalForms/simpleFlows';
import ConditionRoute from 'components/ConditionRoute';
import { SidebarContext } from 'components/SideBars/context';
import { SetButtonGroupProps, SetSidebarLoading } from 'types/dynamicSideBar';
import { SimpleFlowRule } from 'model/simpleFlowRules';
import { TrafficSource } from 'model/trafficSource';
import { FunnelConditionRoute } from 'model/funnelConditionRoute';
import { FunnelConditionGroup } from 'model/funnelConditionGroup';
import { FunnelConditionRule } from 'model/funnelConditionRule';
import { getRuleParamKey } from 'constants/condition';
import { FetchTrafficSourcesCategoryInfo } from 'types/actions';
import { generateEntityId } from 'utils/id/generator';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from 'utils/reactive/generic';
import { defined } from 'utils/define';

interface State extends ReactiveValidateState {
  isEditingName: boolean;
  model: SimpleFlowRule;
  showErrorTooltip: boolean;
}

interface Props extends MessageProps {
  model: SimpleFlowRule;
  onModelUpdate: SetRuleEditorModel;
  setSidebarLoading: SetSidebarLoading;
  sidebarLoading: boolean;
  trafficSources: TrafficSource[];
  allRules: SimpleFlowRule[];
  setButtonGroupProps: SetButtonGroupProps;
  onClose(): void;
  fetchTrafficSourcesCategoryInfo: FetchTrafficSourcesCategoryInfo;
}

class SimpleFlowsRuleEditor extends ReactiveValidateComponent<Props, State> {
  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  state: State = {
    isEditingName: false,
    model: {
      ruleId: generateEntityId(),
      ruleName: '',
      rule: {
        groups: [],
        operator: 'or',
        routeName: ''
      }
    },
    showErrorTooltip: false,
    isTouchedForm: false,
    validationErrors: {}
  };

  async componentDidMount() {
    this.props.setButtonGroupProps({
      okText: 'Save',
      showOk: true,
      showCancel: true,
      cancelText: 'DISCARD',
      onOkClicked: this.handleUpdate
    });
    this.props.setSidebarLoading(true);
    await this.handleState('model', this.props.model);
    this.props.setSidebarLoading(false);

    if (!this.state.model.rule.groups.length) {
      this.addDefaultGroup();
    }
  }

  addDefaultGroup = () => {
    this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        groups: [
          {
            operator: 'or',
            rules: [{} as FunnelConditionRule]
          }
        ]
      }
    });
  };

  handleState = <T extends State, P extends keyof T>(name: P, value: T[P]) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  toggleEditingName = async () => {
    await this.handleState('isEditingName', !this.state.isEditingName);
    if (this.state.isEditingName) {
      (document.querySelector('#ruleName')! as HTMLInputElement).focus();
    }
  };

  handleUpdate = async () => {
    const allRulesName = this.props.allRules
      .filter(r => r.ruleId !== this.state.model.ruleId)
      .map(r => r.ruleName);
    await this.setTouchedForm();
    await this.validate();

    if (allRulesName.includes(this.state.model.ruleName)) {
      this.props.showMessage(
        Messages.warning('All rules must have unique names.')
      );
      return;
    }
    if (!this.state.model.ruleName) {
      this.props.showMessage(
        Messages.warning('You should set a name for your rule.')
      );
      return;
    }

    if (!Object.keys(this.state.validationErrors).length) {
      this.props.onModelUpdate(this.state.model);
      this.props.onClose();
    }
  };

  setRouteParam = async (param: string, value: string) => {
    this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        operator:
          param === 'operator'
            ? (value as FunnelConditionRoute.OperatorEnum)
            : this.state.model.rule.operator
      }
    });
  };

  addGroup = async () => {
    await this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        groups: [
          ...this.state.model.rule.groups,
          {
            operator: 'or',
            rules: []
          }
        ]
      }
    });
  };

  addRule = async (_: number, idGroup: number) => {
    await this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        groups: (this.state.model.rule.groups || []).map((group, idx) => {
          if (idx === idGroup) {
            return {
              ...group,
              rules: [...group.rules, {} as any]
            };
          }
          return group;
        })
      }
    });
  };

  setRouteGroupParam = (
    param: string,
    value: string,
    _: number,
    idGroup: number
  ) => {
    this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        groups: this.state.model.rule.groups.map((group, idG) =>
          idG === idGroup && param === 'operator'
            ? {
                ...group,
                operator: value as FunnelConditionGroup.OperatorEnum
              }
            : group
        )
      }
    });
  };

  deleteGroup = async (_: number, idGroup: number) => {
    await this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        groups: (this.state.model.rule.groups || []).filter(
          (_, idG) => idG !== idGroup
        )
      }
    });
  };

  deleteRule = async (_: number, idGroup: number, idRule: number) => {
    await this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        groups: this.state.model.rule.groups.map((group, idG) => {
          if (idG === idGroup) {
            return {
              ...group,
              rules: group.rules.filter((_, idR) => idR !== idRule)
            };
          }
          return group;
        })
      }
    });
  };

  setRuleOption = async (
    param: string | undefined,
    value: any,
    _: number,
    idxGroup: number,
    idRule: number
  ) => {
    await this.handleState('model', {
      ...this.state.model,
      rule: {
        ...this.state.model.rule,
        groups: this.state.model.rule.groups.map((group, idG) => {
          if (idG === idxGroup) {
            return {
              ...group,
              rules: group.rules.map((rule, idR) => {
                if (idR === idRule) {
                  switch (param) {
                    case 'attribute':
                      // just keep attribute because attribute has changed
                      return {
                        test: '' as any,
                        attribute: value as FunnelConditionRule.AttributeEnum
                      };
                    case 'test':
                      return {
                        ...rule,
                        test: value as FunnelConditionRule.TestEnum
                      };
                    default:
                      if (
                        param === 'capVisitorConversionParams' ||
                        param === 'capOfferConversionParams' ||
                        param === 'trackingFieldParams'
                      ) {
                        return {
                          ...rule,
                          [param]: value
                        };
                      } else {
                        return {
                          ...rule,
                          [param!]: !getRuleParamKey(rule)
                            ? value
                            : {
                                [getRuleParamKey(rule)!]:
                                  param === 'genericParams' &&
                                  typeof value === 'string'
                                    ? value.split(',')
                                    : value
                              }
                        };
                      }
                  }
                }
                return rule;
              })
            };
          }
          return group;
        })
      }
    });
    if (param === 'attribute') {
      if ((value as FunnelConditionRule.AttributeEnum) === 'Traffic Source') {
        await this.props.fetchTrafficSourcesCategoryInfo('active');
      }
    }
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {}, showErrorTooltip: false });
      let validationErrors = {} as AnyObject;
      let validationRules = {} as AnyObject;
      let isRuleError = false;

      [this.state.model.rule].forEach(
        (route: FunnelConditionRoute, idRoute: number) => {
          if (defined(route.groups) && route.groups.length > 0) {
            route.groups.forEach(
              (group: FunnelConditionGroup, idGroup: number) => {
                if (defined(group.rules) && group.rules.length > 0) {
                  group.rules.forEach(
                    (rule: FunnelConditionRule, idRule: number) => {
                      const genericParams =
                        !defined(rule.genericParams) ||
                        !defined(rule.genericParams.values) ||
                        !defined(rule.genericParams.values[0]) ||
                        rule.genericParams.values[0] === '';
                      const timeDateParams =
                        !defined(rule.timeDateParams) ||
                        !defined(rule.timeDateParams.day) ||
                        !defined(rule.timeDateParams.month) ||
                        !defined(rule.timeDateParams.year);
                      const timeOfDayParams =
                        !defined(rule.timeOfDayParams) ||
                        !defined(rule.timeOfDayParams.minutes) ||
                        !defined(rule.timeOfDayParams.hour);
                      const timeDayOfWeekParams =
                        !defined(rule.timeDayOfWeekParams) ||
                        !defined(rule.timeDayOfWeekParams.day);
                      const timeDayOfMonthParams =
                        !defined(rule.timeDayOfMonthParams) ||
                        !defined(rule.timeDayOfMonthParams.day);
                      const timeMonthOfYearParams =
                        !defined(rule.timeMonthOfYearParams) ||
                        !defined(rule.timeMonthOfYearParams.month);
                      const capVisitorConversionParams =
                        !defined(rule.capVisitorConversionParams) ||
                        !defined(rule.capVisitorConversionParams.idOffer) ||
                        !defined(
                          rule.capVisitorConversionParams.conversionsCount
                        );
                      const capOfferConversionParams =
                        !defined(rule.capOfferConversionParams) ||
                        !defined(rule.capOfferConversionParams.idOffer) ||
                        !defined(
                          rule.capOfferConversionParams.conversionsCount
                        ) ||
                        !defined(rule.capOfferConversionParams.lastXDays);
                      const trackingFieldParams =
                        !defined(rule.trackingFieldParams) ||
                        !defined(rule.trackingFieldParams.fieldName) ||
                        !defined(rule.trackingFieldParams.fieldValues);

                      if (
                        !rule.attribute ||
                        !rule.test ||
                        (genericParams &&
                          timeDateParams &&
                          timeOfDayParams &&
                          timeDayOfWeekParams &&
                          timeDayOfMonthParams &&
                          timeMonthOfYearParams &&
                          capVisitorConversionParams &&
                          capOfferConversionParams &&
                          trackingFieldParams &&
                          (!defined(validationRules[idRoute]) ||
                            !defined(validationRules[idRoute][idGroup]) ||
                            !defined(
                              validationRules[idRoute][idGroup][idRule]
                            )))
                      ) {
                        if (!defined(validationRules[idRoute])) {
                          validationRules[idRoute] = {};
                        }

                        if (!defined(validationRules[idRoute][idGroup])) {
                          validationRules[idRoute][idGroup] = {};
                        }

                        isRuleError = true;
                        validationRules[idRoute][idGroup][idRule] = true;
                      }
                    }
                  );
                } else {
                  validationErrors = {
                    ...validationErrors,
                    route: {
                      ...validationErrors.route,
                      [idRoute]: ['Please add rule for group']
                    }
                  };
                }
              }
            );
          } else {
            if (idRoute !== 0) {
              validationErrors = {
                ...validationErrors,
                route: {
                  ...validationErrors.route,
                  [idRoute]: ['Routes must have at least one rule added']
                }
              };
            }
          }
        }
      );

      this.handleState(
        'validationErrors',
        isRuleError
          ? { ...validationErrors, rules: validationRules }
          : validationErrors
      );
    }
  };

  validateClassName = (
    field: string,
    idx?: number,
    idGroup?: number,
    idRule?: number
  ) => {
    if (field === 'rules' && idGroup !== undefined && idRule !== undefined) {
      return idx !== undefined &&
        !!this.state.validationErrors?.[field]?.[idx]?.[idGroup]?.[idRule]
        ? 'has-error'
        : '';
    }

    return (idx !== undefined &&
      !!this.state.validationErrors?.[field]?.[idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? 'has-error'
      : '';
  };

  validateTooltip = (
    field: string,
    idx?: number,
    idGroup?: number,
    idRule?: number
  ) => {
    if (field === 'rules' && idGroup !== undefined && idRule !== undefined) {
      return idx !== undefined &&
        !!this.state.validationErrors?.[field]?.[idx]?.[idGroup]?.[idRule]
        ? 'is not set'
        : '';
    }

    return (idx !== undefined &&
      !!this.state.validationErrors[field] &&
      !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? idx !== undefined
        ? this.state.validationErrors[field][idx].join(', ')
        : this.state.validationErrors[field].join(', ')
      : '';
  };

  onEditName = (value: string) => {
    this.handleState('model', {
      ...this.state.model,
      ruleName: value,
      rule: {
        ...this.state.model.rule,
        routeName: value
      }
    });
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <div className="cform-simpleFlows__ruleEditor">
        <FormSectionBox withBoxPadding={false}>
          <div className="flex flex-gap-15 flex-align-center height-75 padding-x-20">
            <Icon type="flux-modify" className="icon-size-22" />
            {this.state.isEditingName ? (
              <Input
                id="ruleName"
                name="ruleName"
                data-testid="ruleName"
                value={this.state.model.ruleName}
                onChange={e => this.onEditName(e.target.value)}
                onBlur={this.toggleEditingName}
              />
            ) : (
              <span
                onClick={this.toggleEditingName}
                className="font-weight-600 font-size-18"
              >
                {this.state.model.ruleName || 'Rule Name Here'}
              </span>
            )}
          </div>
        </FormSectionBox>
        <FormSectionBox withBoxPadding={false} withTitlePadding={false}>
          <div className="padding-x-10">
            <ConditionRoute
              route={this.state.model.rule}
              setRouteParam={this.setRouteParam}
              addGroup={this.addGroup}
              addRule={this.addRule}
              setRouteGroupParam={this.setRouteGroupParam}
              deleteGroup={this.deleteGroup}
              showErrorTooltip={this.state.showErrorTooltip}
              onDeleteRule={this.deleteRule}
              onSetRuleOption={this.setRuleOption}
              validateClassName={this.validateClassName}
              validateTooltip={this.validateTooltip}
              trafficSources={this.props.trafficSources}
            />
          </div>
        </FormSectionBox>
      </div>
    );
  }
}

export default Messages.injectIn(SimpleFlowsRuleEditor);

export const deviceOS = [
  'Android',
  'Bada OS',
  'Desktop',
  'Fire OS',
  'Firefox OS',
  'Hiptop OS',
  'Linux Desktop',
  'Linux Smartphone OS',
  'MTK/Nucleus OS',
  'Mac OS X',
  'MeeGo',
  'PSP OS',
  'Palm OS',
  'PlayStation OS',
  'RIM OS',
  'RIM Tablet OS',
  'Rex Qualcomm OS',
  'Symbian OS',
  'Tizen',
  'Ubuntu Touch',
  'Windows',
  'Windows CE',
  'Windows Mobile OS',
  'Windows Phone OS',
  'Windows RT',
  'Xbox OS',
  'iOS',
  'macOS',
  'webOS'
];

import { Dispatch } from 'redux';
import { apiAction } from './api';
import { ONBOARDING as API } from '../../api/endpoints';
import { ONBOARDING } from '../types';
import { OnboardingStatus } from '../../model/auth/onboardingStatus';

const setStatus = (payload: OnboardingStatus) => {
  return {
    type: ONBOARDING.SET_STATUS,
    payload
  };
};

export const fetchOnboardingStatus = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.STATUS(),
      onSuccess: (data: OnboardingStatus) => setStatus(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: ONBOARDING.FETCH_STATUS
    })
  );
};

export const trackOnboarding = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.TRACK({ taskId: 'WATCH_ONBOARDING_VIDEO' }),
      onSuccess: () => {},
      onFailure: (e: Error) => {
        throw e;
      },
      label: ONBOARDING.FETCH_STATUS
    })
  );
};

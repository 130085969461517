/**
 * API ACTION TYPES
 */
export const API_CALL = Symbol('API_CALL');
export const API = {
  ACCESS_DENIED: 'API_ACCESS_DENIED',
  ERROR: 'API_ERROR',
  STARTED: 'API_STARTED',
  ENDED: 'API_ENDED'
};

export const QUICKSTATS = {
  SET_REPORTING: 'QUICKSTATS_SET_REPORTING',
  FETCH_REPORTING: 'QUICKSTATS_FETCH_REPORTING',
  FETCH_LIST: 'QUICKSTATS_FETCH_LIST',
  SET_FILTERS: 'QUICKSTATS_SET_FILTERS',
  SET_SORT: 'QUICKSTATS_SET_SORT',
  SET_PARAMS: 'QUICKSTATS_SET_PARAMS',
  SET_TABLE_PARAMS: 'QUICKSTATS_SET_TABLE_PARAMS',
  SET_TABLE_COLS: 'QUICKSTATS_SET_TABLE_COLS',
  SET_TABLE_COL_SIZES: 'QUICKSTATS_SET_TABLE_COL_SIZES',
  RESET: 'QUICKSTATS_RESET',
  SET_TRAFFIC_SOURCE_LIST: 'SET_TRAFFIC)SOURCE_LIST'
};

export const FUNNELGROUPS = {
  FETCH_LIST: 'FUNNELGROUPS_FETCH_LIST',
  FETCH_INFO: 'FUNNELGROUPS_FETCH_INFO',
  SET_INFO: 'FUNNELGROUPS_SET_INFO',
  ADD_SINGLE: 'FUNNELGROUPS_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'FUNNELGROUPS_ADD_EXISTED_SINGLE',
  UPDATE_SINGLE: 'FUNNELGROUPS_UPDATE_SINGLE',
  SET_LIST: 'FUNNELGROUPS_SET_LIST',
  ARCHIVE: 'FUNNELGROUPS_ARCHIVE',
  DELETE: 'FUNNELGROUPS_DELETE',
  SET_REPORTING: 'FUNNELGROUPS_SET_REPORTING',
  FETCH_REPORTING: 'FUNNELGROUPS_FETCH_REPORTING',
  SET_GRAPH: 'FUNNELGROUPS_SET_GRAPH',
  FETCH_GRAPH: 'FUNNELGROUPS_FETCH_GRAPH',
  SET_SHOW: 'FUNNELGROUPS_SET_SHOW',
  ADD_SINGLE_ROW: 'FUNNELGROUPS_ADD_SINGLE_ROW',
  DELETE_EMPTY: 'FUNNELGROUPS_DELETE_EMPTY'
};

export const REPORTINGS = {
  FETCH_LIST: 'REPORTINGS_FETCH_LIST',
  SET_DATA: 'REPORTINGS_SET_DATA',
  SET_DATA_INITIAL: 'REPORTINGS_SET_DATA_INITIAL',
  FETCH_ATTRIBUTES: 'REPORTINGS_FETCH_ATTRIBUTES',
  SET_ATTRIBUTES: 'REPORTINGS_SET_ATTRIBUTES',
  ADD_VIEW: 'REPORTINGS_ADD_VIEW',
  DELETE_VIEW: 'REPORTINGS_DELETE_VIEW',
  DELETE_ALL_VIEWS: 'REPORTINGS_DELETE_ALL_VIEWS',
  SET_SETTINGS: 'REPORTINGS_SET_SETTINGS',
  SET_TABLE_PARAMS: 'REPORTINGS_SET_TABLE_PARAMS',
  SET_TABLE_COL_SIZES: 'REPORTINGS_SET_TABLE_COL_SIZES',
  RESET_RESTRICT_DATA: 'REPORTINGS_RESET_RESTRICT_DATA',
};

export const SUMMARY = {
  SET_REPORTING: 'SUMMARY_SET_REPORTING',
  FETCH_REPORTING: 'SUMMARY_FETCH_REPORTING',
  SET_GRAPH: 'SUMMARY_SET_GRAPH',
  FETCH_GRAPH: 'SUMMARY_FETCH_GRAPH',
  SET_TABLE_COLS: 'SUMMARY_SET_TABLE_COLS',
  SET_SETTINGS: 'SUMMARY_SET_SETTINGS'
};

export const LANDERS = {
  SET_LIST: 'LANDERS_SET_LIST',
  FETCH_CATEGORY_INFO: 'LANDERS_FETCH_CATEGORY_INFO',
  SET_CATEGORY_INFO: 'LANDERS_SET_CATEGORY_INFO',
  FETCH_INFO: 'LANDERS_FETCH_INFO',
  SET_INFO: 'LANDERS_SET_INFO',
  FETCH_INIT: 'LANDERS_FETCH_INIT',
  ADD_SINGLE: 'LANDERS_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'LANDERS_ADD_EXISTED_SINGLE',
  UPDATE_SINGLE: 'LANDERS_UPDATE_SINGLE',
  UPDATE_SINGLE_REPORTING: 'LANDERS_UPDATE_SINGLE_REPORTING',
  FETCH_LIST: 'LANDERS_FETCH_LIST',
  SET_CATEGORIES: 'LANDERS_SET_CATEGORIES',
  CREATE_CATEGORY: 'LANDERS_CREATE_CATEGORY',
  UPDATE_CATEGORY: 'LANDERS_UPDATE_CATEGORY',
  DELETE_CATEGORY: 'LANDERS_DELETE_CATEGORY',
  ARCHIVE_CATEGORY: 'LANDERS_ARCHIVE_CATEGORY',
  FETCH_SINGLE_CATEGORY: 'LANDERS_FETCH_SINGLE_CATEGORY',
  ADD_SINGLE_CATEGORY: 'LANDERS_ADD_SINGLE_CATEGORY',
  DELETE_SINGLE_CATEGORY: 'LANDERS_DELETE_SINGLE_CATEGORY',
  ARCHIVE_SINGLE_CATEGORY: 'LANDERS_ARCHIVE_SINGLE_CATEGORY',
  DELETE: 'LANDERS_DELETE',
  ARCHIVE: 'LANDERS_ARCHIVE',
  SET_REPORTING: 'LANDERS_SET_REPORTING',
  FETCH_REPORTING: 'LANDERS_FETCH_REPORTING',
  SET_SHOW: 'LANDERS_SET_SHOW',
  ADD_SINGLE_ROW: 'LANDERS_ADD_SINGLE_ROW',
  SET_QUICKSTAT_DATA: 'LANDERS_SET_QUICKSTAT_DATA',
  UPDATE_CATEGORY_ENTITIES: 'LANDERS_UPDATE_CATEGORY_ENTITIES'
};

export const OFFERS = {
  SET_LIST: 'OFFERS_SET_LIST',
  FETCH_INIT: 'OFFERS_FETCH_INIT',
  FETCH_CATEGORY_INFO: 'OFFERS_FETCH_CATEGORY_INFO',
  SET_CATEGORY_INFO: 'OFFERS_SET_CATEGORY_INFO',
  FETCH_INFO: 'OFFERS_FETCH_CATEGORY_INFO',
  SET_INFO: 'OFFERS_SET_INFO',
  FETCH_INIT_CAT: 'OFFERS_FETCH_INIT_CAT',
  ADD_SINGLE: 'OFFERS_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'OFFERS_ADD_EXISTED_SINGLE',
  UPDATE_SINGLE: 'OFFERS_UPDATE_SINGLE',
  UPDATE_SINGLE_REPORTING: 'OFFERS_UPDATE_SINGLE_REPORTING',
  FETCH_LIST: 'OFFERS_FETCH_LIST',
  SET_CATEGORIES: 'OFFERS_SET_CATEGORIES',
  UPDATE_CATEGORY: 'OFFERS_UPDATE_CATEGORY',
  CREATE_CATEGORY: 'OFFERS_CREATE_CATEGORY',
  DELETE_CATEGORY: 'OFFERS_DELETE_CATEGORY',
  ARCHIVE_CATEGORY: 'OFFERS_ARCHIVE_CATEGORY',
  FETCH_CATEGORIES: 'OFFERS_FETCH_CATEGORIES',
  ADD_SINGLE_CATEGORY: 'OFFERS_ADD_SINGLE_CATEGORY',
  DELETE_SINGLE_CATEGORY: 'OFFERS_DELETE_SINGLE_CATEGORY',
  ARCHIVE_SINGLE_CATEGORY: 'OFFERS_ARCHIVE_SINGLE_CATEGORY',
  DELETE: 'OFFERS_DELETE',
  ARCHIVE: 'OFFERS_ARCHIVE',
  SET_REPORTING: 'OFFERS_SET_REPORTING',
  FETCH_REPORTING: 'OFFERS_FETCH_REPORTING',
  SET_SHOW: 'OFFERS_SET_SHOW',
  ADD_SINGLE_ROW: 'OFFERS_ADD_SINGLE_ROW',
  UPDATE_CATEGORY_ENTITIES: 'OFFERS_UPDATE_CATEGORY_ENTITIES',
  UPDATE_OFFER_SOURCE_ENTITIES: 'OFFERS_UPDATE_OFFER_SOURCE_ENTITIES'
};

export const OVERVIEW = {
  SET_REPORTING: 'OVERVIEW_SET_REPORTING',
  FETCH_REPORTING: 'OVERVIEW_FETCH_REPORTING',
};

export const OFFERSOURCES = {
  FETCH_LIST: 'OFFERSOURCES_FETCH_LIST',
  FETCH_INIT: 'OFFERSOURCES_FETCH_INIT',
  SET_LIST: 'OFFERSOURCES_SET_LIST',
  ADD_SINGLE: 'OFFERSOURCES_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'OFFERSOURCES_ADD_EXISTED_SINGLE',
  UPDATE_SINGLE: 'OFFERSOURCES_UPDATE_SINGLE',
  UPDATE_SINGLE_REPORTING: 'OFFERSOURCES_UPDATE_SINGLE_REPORTING',
  BULK_UPSERT_CATEGORIES: 'OFFERSOURCES_BULK_UPSERT_CATEGORIES',
  SET_CATEGORIES: 'OFFERSOURCES_SET_CATEGORIES',
  DELETE: 'OFFERSOURCES_DELETE',
  ARCHIVE: 'OFFERSOURCES_ARCHIVE',
  UPDATE_CATEGORY: 'OFFERSOURCES_UPDATE_CATEGORY',
  CREATE_CATEGORY: 'OFFERSOURCES_CREATE_CATEGORY',
  DELETE_CATEGORY: 'OFFERSOURCES_DELETE_CATEGORY',
  ARCHIVE_CATEGORY: 'OFFERSOURCES_ARCHIVE_CATEGORY',
  DELETE_SINGLE_CATEGORY: 'OFFERSOURCES_DELETE_SINGLE_CATEGORY',
  ARCHIVE_SINGLE_CATEGORY: 'OFFERSOURCES_ARCHIVE_SINGLE_CATEGORY',
  SET_REPORTING: 'OFFERSOURCES_SET_REPORTING',
  FETCH_REPORTING: 'OFFERSOURCES_FETCH_REPORTING',
  SET_SHOW: 'OFFERSOURCES_SET_SHOW',
  ADD_SINGLE_ROW: 'OFFERSOURCES_ADD_SINGLE_ROW',
  UPDATE_CATEGORY_ENTITIES: 'OFFERSOURCES_UPDATE_CATEGORY_ENTITIES'
};

export const FUNNELS = {
  FETCH_LIST: 'FUNNELS_FETCH_LIST',
  ADD_SINGLE: 'FUNNELS_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'FUNNELS_ADD_EXISTED_SINGLE',
  UPDATE_SINGLE_ROW: 'FUNNELS_UPDATE_SINGLE_ROW',
  UPDATE: 'FUNNELS_UPDATE',
  SET_LIST: 'FUNNELS_SET_LIST',
  SET_ALL_LIST: 'FUNNELS_SET_ALL_LIST',
  DELETE: 'FUNNELS_DELETE',
  ARCHIVE_SINGLE: 'FUNNELS_ARCHIVE_SINGLE',
  UNARCHIVE_LIST: 'FUNNELS_UNARCHIVE_LIST',
  ARCHIVE_LIST: 'FUNNELS_ARCHIVE_LIST',
  MOVE_LIST: 'FUNNELS_MOVE_LIST',
  ADD_SINGLE_ROW: 'FUNNELS_ADD_SINGLE_ROW',
  SET_BUILDER_HAS_CHANGED_DATA: 'FUNNELS_SET_BUILDER_HAS_CHANGED_DATA',
  SET_IS_CLOSE_ALL_TABS_MODAL_OPEN: 'FUNNELS_SET_IS_CLOSE_ALL_TABS_MODAL_OPEN'
};

export const TRAFFICSOURCES = {
  FETCH_LIST: 'TRAFFICSOURCES_FETCH',
  FETCH_CATEGORY_INFO: 'TRAFFICSOURCES_FETCH_CATEGORY_INFO',
  SET_CATEGORY_INFO: 'TRAFFICSOURCES_SET_CATEGORY_INFO',
  FETCH_CATEGORIES: 'TRAFFICSOURCES_FETCH_CATEGORIES',
  FETCH_INIT: 'TRAFFICSOURCES_FETCH_INIT',
  ARCHIVE: 'TRAFFICSOURCES_ARCHIVE',
  SET_CATEGORIES: 'TRAFFICSOURCES_SET_CATEGORIES',
  SET_LIST: 'TRAFFICSOURCES_SET_LIST',
  ADD_SINGLE: 'TRAFFICSOURCES_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'TRAFFICSOURCES_ADD_EXISTED_SINGLE',
  UPDATE_SINGLE: 'TRAFFICSOURCES_UPDATE_SINGLE',
  UPDATE_SINGLE_REPORTING: 'TRAFFICSOURCES_UPDATE_SINGLE_REPORTING',
  DELETE: 'TRAFFICSOURCES_DELETE',
  UPDATE_CATEGORY: 'TRAFFICSOURCES_UPDATE_CATEGORY',
  CREATE_CATEGORY: 'TRAFFICSOURCES_CREATE_CATEGORY',
  DELETE_CATEGORY: 'TRAFFICSOURCES_DELETE_CATEGORY',
  ARCHIVE_CATEGORY: 'TRAFFICSOURCES_ARCHIVE_CATEGORY',
  ADD_SINGLE_CATEGORY: 'TRAFFICSOURCES_ADD_SINGLE_CATEGORY',
  DELETE_SINGLE_CATEGORY: 'TRAFFICSOURCES_DELETE_SINGLE_CATEGORY',
  ARCHIVE_SINGLE_CATEGORY: 'TRAFFICSOURCES_ARCHIVE_SINGLE_CATEGORY',
  SET_REPORTING: 'TRAFFICSOURCES_SET_REPORTING',
  FETCH_REPORTING: 'TRAFFICSOURCES_FETCH_REPORTING',
  SET_SHOW: 'TRAFFICSOURCES_SET_SHOW',
  ADD_SINGLE_ROW: 'TRAFFICSOURCES_ADD_SINGLE_ROW',
  UPDATE_CATEGORY_ENTITIES: 'TRAFFICSOURCES_UPDATE_CATEGORY_ENTITIES'
};

export const RESET_DATA = {
  FETCH: 'RESET_DATA_FETCH',
  DELETE: 'RESET_DATA_DELETE',
  SET_VALUE: 'RESET_DATA_SET_VALUE',
  SET_REPORTING: 'RESET_DATA_SET_REPORTING',
  FETCH_REPORTING: 'RESET_DATA_FETCH_REPORTING',
  SET_SETTINGS: 'RESET_DATA_SET_SETTINGS'
};

export const UPDATE_CONVERSIONS = {
  UPDATE: 'UPDATE_CONVERSIONS_UPDATE',
  SET_DATA: 'UPDATE_CONVERSIONS_SET_DATA'
};

export const UPDATE_TRAFFIC_COSTS = {
  UPDATE: 'UPDATE_TRAFFIC_COSTS_UPDATE',
  FETCH_REPORTING: 'UPDATE_TRAFFIC_COSTS_FETCH_REPORTING',
  SET_CAMPAIGN_OPTIONS: 'UPDATE_TRAFFIC_COSTS_SET_CAMPAIGN_OPTIONS',
  SET_CAMPAIGN_VALUE: 'UPDATE_TRAFFIC_COSTS_SET_CAMPAIGN_VALUE',
  SET_SETTINGS: 'UPDATE_TRAFFIC_COSTS_SET_SETTINGS',
  SET_ROWS: 'UPDATE_TRAFFIC_COSTS_SET_ROWS'
};

export const CAMPAIGN_ANALYSIS = {
  FETCH_LIST: 'FUNNELS_FETCH_LIST',
  SET_REPORTING: 'CAMPAIGN_ANALYSIS_SET_REPORTING',
  FETCH_REPORTING: 'CAMPAIGN_ANALYSIS_FETCH_REPORTING'
};

/**
 * TABLE ACTION TYPES
 */
export const TABLE = {
  SET_COLS: 'TABLE_SET_COLS',
  SET_EDIT_MODE: 'SET_EDIT_MODE',
  SET_ADDITIONAL_PARAMS: 'SET_ADDITIONAL_PARAMS',
  SET_TABLE_PARAMS: 'SET_TABLE_PARAMS'
};

export const VIEW = {
  FUNNELGROUPS: 'FUNNELGROUPS',
  LANDERS: 'LANDERS',
  OFFERS: 'OFFERS',
  OFFERSOURCES: 'OFFERSOURCES',
  TRAFFICSOURCES: 'TRAFFICSOURCES',
  PAGEGROUPS: 'PAGEGROUPS',
  REPORTINGS: 'REPORTINGS',
  SUMMARY: 'SUMMARY',
  QUICKSTATS: 'QUICKSTATS',
  CONDITIONS: 'CONDITIONS',
  HITS: 'HITS',
  CAMPAIGNANALYSIS: 'CAMPAIGNANALYSIS',
  GOOGLEADS: 'GOOGLEADS',
  FACEBOOKADS: 'FACEBOOKADS',
};

/**
 * NAV ACTION TYPES
 */
export const NAV_ACTIONS = {
  CHANGED: 'VIEW_CHANGED',
  BUILDER_FUNNEL_CHANGED: 'BUILDER_FUNNEL_CHANGED',
  BUILDER_FUNNEL_DELETED: 'BUILDER_FUNNEL_DELETED',
  BUILDER_FUNNELS_DELETED: 'BUILDER_FUNNELS_DELETED',
  BUILDER_FUNNELS_UPDATE_NAME: 'BUILDER_FUNNELS_UPDATE_NAME',
  SET_VIEW_LAST_ROUTE: 'SET_VIEW_LAST_ROUTE',
  SET_SIDER_MENU_IS_EXPANDED: 'SET_SIDER_MENU_IS_EXPANDED',
};

/**
 * USER ACTION TYPES
 */
export const USER_ACTIONS = {
  LOGGED_IN: 'LOGGEDIN',
  LOGGED_OUT: 'LOGGEDOUT'
};

/**
 * SYSTEM SETTINGS ACTION TYPES
 */
export const SYSTEM_SETTINGS = {
  FETCH: 'FETCH_SYSTEM_SETTINGS',
  UPDATE: 'UPDATE_SYSTEM_SETTINGS',
  DELETE: 'DELETE_SYSTEM_SETTINGS',
  TOGGLE_SHOW_PRODUCT_TOUR_BUTTONS: 'TOGGLE_SHOW_PRODUCT_TOUR_BUTTONS_SYSTEM_SETTINGS',
  TOGGLE_SHOW_EDIT_MODE_TOOLTIPS:
    'TOGGLE_SHOW_EDITMODE_TOOLTIPS_SYSTEM_SETTINGS'
};

/**
 * GOOGLE ADS ACTION TYPES
 */
export const GOOGLE_ADS = {
  FETCH_REPORTING: 'GOOGLE_ADSـFETCH_REPORTING',
  SET_DATA: 'GOOGLE_ADSـSET_DATA',
  SET_DATA_INITIAL: 'GOOGLE_ADSـSET_DATA_INITIAL',
  SET_TABLE_PARAMS: 'GOOGLE_ADSـSET_TABLE_PARAMS',
  SET_SETTINGS: 'GOOGLE_ADSـSET_SETTINGS',
  SET_TABLE_COL_SIZES: 'GOOGLE_ADSـSET_TABLE_COL_SIZES',
  SET_REPORTING: 'GOOGLE_ADSـSET_REPORTING',
  GET_USERS: 'GOOGLE_ADSـGET_USERS',
  UPDATE_CURRENT_USER: 'GOOGLE_ADSـUPDATE_CURRENT_USER',
  GET_CUSTOMER_CLIENTS: 'GOOGLE_ADSـGET_CUSTOMER_CLIENTS',
  SET_CURRENT_USER: 'GOOGLE_ADSـSET_CURRENT_USER',
  SET_CURRENT_CUSTOMER_CLIENT: 'GOOGLE_ADSـSET_CURRENT_CUSTOMER_CLIENT',
  UPDATE_AD_STATUS: 'GOOGLE_ADSـUPDATE_AD_STATUS',
  UPDATE_ADGROUP_STATUS: 'GOOGLE_ADS_UPDATE_ADGROUP_STATUS',
  UPDATE_CAMPAIGN_STATUS: 'GOOGLE_ADS_UPDATE_CAMPAIGN_STATUS',
  UPDATE_OPTIONAL_SEGMENTATION: 'GOOGLE_ADS_UPDATE_OPTIONAL_SEGMENTATION',
};

/* 
* Facebook Ads
*/
export const FACEBOOK_ADS = {
  SET_DATA: 'FACEBOOK_SET_DATA',
  SET_DATA_INITIAL: 'FACEBOOK_SET_DATA_INITIAL',
  SET_TABLE_PARAMS: 'FACEBOOK_SET_TABLE_PARAMS',
  SET_SETTINGS: 'FACEBOOK_SET_SETTINGS',
  SET_TABLE_COL_SIZES: 'FACEBOOK_SET_TABLE_COL_SIZES',
  SET_REPORTING: 'FACEBOOK_SET_REPORTING',
  SET_AD_ACCOUNTS: 'FACEBOOK_SET_AD_ACCOUNTS',
  SET_USERS: 'FACEBOOK_SET_USERS',
  FETCH_REPORTING: 'FACEBOOK_FETCH_REPORTING',
  MUTATE_STATUS: 'FACEBOOK_MUTATE_STATUS'
}

/**
 * DOMAINS ACTION TYPES
 */
export const DOMAINS = {
  FETCH: 'DOMAINS_FETCH',
  UPDATE: 'DOMAINS_UPDATE',
  DELETE: 'DOMAINS_DELETE',
  SWITCH: 'DOMAINS_SWITCH',
  DELETE_DNS: 'DOMAINS_DELETE_DNS',
  EDIT_DNS: 'DOMAINS_EDIT_DNS',
  ADD_SINGLE: 'DOMAINS_ADD_SINGLE'
};

/**
 * INTEGRATIONS ACTION TYPES
 */
export const INTEGRATIONS = {
  FETCH: 'INTEGRATIONS_FETCH',
  SET_LIST: 'INTEGRATIONS_SET_LIST',
  ADD_SINGLE: 'INTEGRATIONS_ADD_SINGLE',
  UPDATE_SINGLE: 'INTEGRATIONS_UPDATE_SINGLE',
  CREATE: 'INTEGRATIONS_CREATE',
  DELETE: 'INTEGRATIONS_DELETE'
};

/**
 * USER ACTION TYPES
 */
export const USER = {
  FETCH: 'FETCH_USER',
  QUOTA: 'QUOTA_USER',
  UPDATE: 'UPDATE_USER'
};

/**
 * CONDITIONS ACTION TYPES
 */
export const CONDITIONS = {
  SET_LIST: 'CONDITIONS_SET_LIST',
  FETCH_CATEGORY_INFO: 'CONDITIONS_FETCH_CATEGORY_INFO',
  SET_CATEGORY_INFO: 'CONDITIONS_SET_CATEGORY_INFO',
  FETCH_INFO: 'CONDITIONS_SET_INFO',
  SET_INFO: 'CONDITIONS_SET_INFO',
  FETCH_INIT: 'CONDITIONS_FETCH_INIT',
  ADD_SINGLE: 'CONDITIONS_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'CONDITIONS_ADD_EXISTED_SINGLE',
  UPDATE_SINGLE: 'CONDITIONS_UPDATE_SINGLE',
  FETCH_LIST: 'CONDITIONS_FETCH_LIST',
  SET_CATEGORIES: 'CONDITIONS_SET_CATEGORIES',
  UPDATE_CATEGORY: 'CONDITIONS_UPDATE_CATEGORY',
  CREATE_CATEGORY: 'CONDITIONS_CREATE_CATEGORY',
  DELETE_CATEGORY: 'CONDITIONS_DELETE_CATEGORY',
  FETCH_CATEGORIES: 'CONDITIONS_FETCH_CATEGORIES',
  ADD_SINGLE_CATEGORY: 'CONDITIONS_ADD_SINGLE_CATEGORY',
  DELETE_SINGLE_CATEGORY: 'CONDITIONS_DELETE_SINGLE_CATEGORY',
  DELETE: 'CONDITIONS_DELETE',
  ARCHIVE: 'CONDITIONS_ARCHIVE',
  FIND_BY_NAME: 'CONDITIONS_FIND_BY_NAME'
};

/**
 * PAGEGROUPS ACTION TYPES
 */
export const PAGE_GROUPS = {
  SET_LIST: 'PAGE_GROUPS_SET_LIST',
  SET_LANDER_INFO: 'PAGE_GROUPS_SET_LANDER_INFO',
  SET_OFFER_INFO: 'PAGE_GROUPS_SET_OFFER_INFO',
  FETCH_INFO: 'PAGE_GROUPS_FETCH_INFO',
  ADD_SINGLE: 'PAGE_GROUPS_ADD_SINGLE',
  ADD_EXISTED_SINGLE: 'PAGE_GROUPS_ADD_EXISTED_SINGLE',
  FETCH_LIST: 'PAGE_GROUPS_FETCH_LIST',
  UPDATE_SINGLE: 'PAGE_GROUPS_UPDATE_SINGLE',
  DELETE: 'PAGE_GROUPS_DELETE',
  SET_SHOW: 'PAGE_GROUPS_SET_SHOW',
  ARCHIVE: 'PAGE_GROUPS_ARCHIVE',
  SET_REPORTING: 'PAGE_GROUPS_SET_REPORTING',
  FETCH_PAGEGROUP_LANDERS: 'PAGE_GROUPS_FETCH_PAGE_GROUP_LANDERS',
  FETCH_PAGEGROUP_OFFERS: 'PAGE_GROUPS_FETCH_PAGE_GROUP_OFFERS',
  SET_LANDERS: 'PAGE_GROUPS_SET_LANDERS',
  SET_OFFERS: 'PAGE_GROUPS_SET_OFFERS',
  FIND_BY_NAME: 'PAGE_GROUPS_FIND_BY_NAME'
};

export const APP = {
  FETCH_VERSION: 'APP_FETCH_VERSION',
  SET_VERSION: 'APP_SET_VERSION',
  UPDATE_UNREAD_CHAT_COUNT: 'APP_UPDATE_UNREAD_CHAT_COUNT'
};

export const SCRIPTS = {
  FETCH_VIEW: 'SCRIPT_FETCH_VIEW',
  FETCH_CONVERSION: 'SCRIPT_FETCH_CONVERSION',
  SET_VIEW: 'SCRIPT_SET_VIEW',
  SET_CONVERSION: 'SCRIPT_SET_CONVERSION'
};

export const BUILDER = {
  SET_ZOOM: 'BUILDER_SET_ZOOM',
  SET_PAN: 'BUILDER_SET_PAN',
  SET_FOR_CREATING: 'BUILDER_SET_FOR_CREATING',
  SET_HEATMAP_VALUES: 'BUILDER_SET_HEATMAP_VALUES',
};

export const ONBOARDING = {
  SET_STATUS: 'ONBOARDING_SET_STATUS',
  FETCH_STATUS: 'ONBOARDING_FETCH_STATUS',
  TRACK_STATUS: 'ONBOARDING_TRACK_STATUS'
};

export const HITS = {
  FETCH_HITS: 'HITS_FETCH_HITS',
  SET_HITS: 'HITS_SET_HITS',
  SET_SETTINGS: 'HITS_SET_SETTINGS',
};

export const KEYWORD_ROTATION_LINKS = {
  SET_DATA: 'KEYWORD_ROTATION_LINKS_SET_DATA',
}
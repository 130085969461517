import { DrilldownReport } from '../../model/drilldownReport';
import { defined } from '../define';
import { UNCATEGORIZED } from '../../constants/modal';
import { DrilldownReportRowAttribute } from '../../model/drilldownReportRowAttribute';
import { AnyAction } from 'redux';
import { ReportCategory } from '../../model/reportCategory';
import {
  CategoryObject,
  ReportCategoryObject,
  ReportOfferSourceObject,
  OptionCategory
} from '../../types';
import { ReportOfferSource } from 'model/reportOfferSource';
import { Category, Funnel } from 'model/models';
import { DrilldownReportRow } from 'model/drilldownReportRow';
import { StatusEnum } from 'api/types';
import { makeReportingRowData } from 'utils/reporting';
import { AttributeData } from 'model/attributeData';
import { CAMPAIGN_NO_FIELD_VALUE } from 'constants/table';
import { Attribute } from 'model/attribute';
import { NO_OFFER_SOURCE } from 'constants/index';
import { FunnelGroupArray } from 'types/redux/store';

export const getCategories = (
  reporting: DrilldownReport
): ReportCategoryObject => {
  return defined(reporting.entities) && defined(reporting.entities.categories)
    ? reporting.entities.categories.reduce(
        (acc: ReportCategoryObject, category: ReportCategory) => {
          acc[category.id] = category;
          return acc;
        },
        {}
      )
    : {};
};

export const getOfferSources = (
  reporting: DrilldownReport
): ReportOfferSourceObject => {
  return defined(reporting.entities) && defined(reporting.entities.offerSources)
    ? reporting.entities.offerSources.reduce(
        (acc: ReportOfferSourceObject, offerSource: ReportOfferSource) => {
          acc[offerSource.id] = offerSource;
          return acc;
        },
        {}
      )
    : {};
};

export const getReportingTypeCategory = (
  category: Category
): DrilldownReportRowAttribute => {
  return {
    id: category.idCategory,
    value: category.categoryName,
    status: !!category.status ? category.status : 'active',
    attribute: category.categoryType
  };
};

type СategoryReportObject = {
  id: string;
  name: string;
  status: Category.StatusEnum;
};

export const getCategory = (
  entity: DrilldownReportRowAttribute,
  categories: ReportCategoryObject,
  allCategories: CategoryObject
) => {
  let category: СategoryReportObject = {
    id: UNCATEGORIZED,
    name: UNCATEGORIZED,
    status: entity.status
  };

  if (defined(entity.categoryID)) {
    if (defined(categories[entity.categoryID])) {
      category = categories[entity.categoryID];
    } else {
      const foundCategory = allCategories[entity.categoryID];

      if (defined(foundCategory)) {
        category = {
          id: foundCategory.idCategory,
          name: foundCategory.categoryName,
          status: entity.status
        };
      }
    }
  }

  return {
    id: category.id,
    value: category.name,
    status: defined(category.status) ? category.status : 'archived'
  } as DrilldownReportRowAttribute;
};

export const getOfferSource = (
  entity: DrilldownReportRowAttribute,
  offerSources: ReportOfferSourceObject
) => {
  const offerSource =
    defined(entity.offerSourceID) && defined(offerSources[entity.offerSourceID])
      ? offerSources[entity.offerSourceID]
      : {
          id: entity.offerSourceID!,
          name: NO_OFFER_SOURCE,
          status: entity.status
        };

  return {
    id: offerSource.id,
    value: offerSource.name,
    status: offerSource.status,
    attribute: Attribute.ThirdPartiesOfferSource
  } as DrilldownReportRowAttribute;
};

export const getFieldName = (action: AnyAction, reportingPayload: string) =>
  action.payload === reportingPayload ? 'reporting' : 'data';

export const getReverseStatus = (
  status: DrilldownReportRowAttribute.StatusEnum
): DrilldownReportRowAttribute.StatusEnum =>
  status === 'active'
    ? 'archived'
    : status === 'archived'
    ? 'active'
    : status === ('' as any)
    ? 'archived'
    : status;

export const reportingHasRowsWithCategory = (
  idCategory: string,
  reporting: DrilldownReport
) =>
  !!reporting.rows.filter(
    (row: DrilldownReportRow) =>
      !!row.attributes[0].categoryID &&
      row.attributes[0].categoryID === idCategory &&
      !!row.attributes[0].status &&
      row.attributes[0].status !== 'deleted'
  ).length;

export const emptyCategoryRows = (
  allCategories: Category[],
  categories: any,
  isAll: boolean,
  status: StatusEnum,
  reporting: DrilldownReport
) => {
  if (!reporting.rows.length) {
    return [];
  }

  return defined(allCategories)
    ? allCategories.length
      ? allCategories
          .filter(
            (item: Category) =>
              !categories[item.idCategory] ||
              !reportingHasRowsWithCategory(item.idCategory, reporting)
          )
          .filter((emptyCategory: Category) =>
            isAll
              ? emptyCategory.status !== 'deleted'
              : emptyCategory.status === status
          )
          .map((item: Category) => {
            return makeReportingRowData([getReportingTypeCategory(item)]);
          })
      : []
    : [];
};

export const getIndexOfCampaignAttribute = (attributes: AttributeData[]) =>
  attributes.findIndex(
    attribute =>
      attribute.attribute === Attribute.URLTrackingField &&
      defined(attribute.whitelistFilters) &&
      attribute.whitelistFilters[0].includes('campaign')
  );

export const convertCampaignNoFieldValue = (
  row: DrilldownReportRow,
  indexOfCampaignAttribute: number
) => {
  if (
    indexOfCampaignAttribute > -1 &&
    defined(row?.attributes?.[indexOfCampaignAttribute]) &&
    row.attributes[indexOfCampaignAttribute].value === 'No Field Value'
  ) {
    row.attributes[indexOfCampaignAttribute].value = CAMPAIGN_NO_FIELD_VALUE;
  }

  return row;
};

export const getCategorizedFunnelsList = (
  funnels: Funnel[],
  funnelGroupsArray: FunnelGroupArray,
  filterFunction?: (funnelItem: Funnel) => boolean
): OptionCategory[] => {
  return (filterFunction
    ? funnels.filter((funnel: Funnel) => filterFunction(funnel))
    : funnels
  ).map(funnel => ({
    id: funnel.idFunnel,
    name: funnel.funnelName,
    category: defined(funnelGroupsArray[funnel.idCampaign])
      ? funnelGroupsArray[funnel.idCampaign].campaignName
      : '',
    idCategory: defined(funnelGroupsArray[funnel.idCampaign])
      ? funnelGroupsArray[funnel.idCampaign].idCampaign
      : ''
  }));
};

import { API, API_CALL } from '../types';
import { logout } from './auth';
import { RequestConfig } from '../../api/types';

export const apiStarted = (label: string) => ({
  type: API.STARTED,
  payload: label
});

export const apiEnded = (label: string) => ({
  type: API.ENDED,
  payload: label
});

export const accessDenied = (url: string) => ({
  type: API.ACCESS_DENIED,
  payload: {
    url
  }
});

export const apiError = (error: Error) => ({
  type: API.ERROR,
  error
});

export const accessNotAuthorized = () => (dispatch: any) => {
  dispatch(logout());
};

export interface apiAction {
  requestConfig: RequestConfig;
  onSuccess: any;
  onFailure: any;
  label?: string;
}

export const apiAction = ({
  requestConfig = {
    method: 'get',
    data: undefined,
    url: '',
    headers: {}
  },
  onSuccess = () => {},
  onFailure = () => {},
  label = undefined
}: apiAction) => ({
  type: API_CALL,
  payload: {
    requestConfig,
    onSuccess,
    onFailure,
    label
  }
});

import axios, { AxiosResponse } from 'axios';
import { METHOD_DELETE, METHOD_GET, METHOD_POST } from 'constants/api';
import CloudflareResponse from 'model/domains/cloudflareResponse';
import ReduxStore from '../redux';
const { store } = ReduxStore;

export const fetchDomainStatus = (domain: string) => {
  return axios({
    method: METHOD_GET,
    url: `https://dns.google.com/resolve?name=${domain}&type=NS`
  });
};

const getTokens = (): {
  accessToken: string;
  refreshToken: string;
  userId: string;
} => {
  const {
    tokens: { accessToken, refreshToken },
    userId
  } = store.getState().user;
  return { accessToken, refreshToken, userId };
};

export const checkDomainStatus = async (domain: string) => {
  if (!domain) return false;
  try {
    const response = await axios.get(
      `https://${domain}/debug/domain-check`,
      {
        headers: {
          'Cache-Control': 'no-cache',
					'Accept': 'text/html'
        }
      }
    );

    if (response.data.found === 'yes') {
      return true;
    }
    return false;
  } catch (e) {
		return false;
  }
};

export const checkCNAMExistance = async (domain: string) => {
  try {
    const response = await axios.get(
      `https://cloudflare-dns.com/dns-query?name=${domain}&type=CNAME`,
      {
        headers: {
          Accept: 'application/dns-json'
        }
      }
    );

    if (response.data.Status === 0) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const addDomainToCloudflare = async (domainName: string) => {
  const { accessToken, refreshToken, userId } = getTokens();
  const response: AxiosResponse<CloudflareResponse.RootObject> = await axios({
    method: METHOD_POST,
    url: `https://europe-west1-helper-cloud-functions.cloudfunctions.net/cloudflare-domain-api?domain_name=${domainName}`,
    headers: {
      'access-token': accessToken,
      'refresh-token': refreshToken,
      'user-id': userId
    }
  });
  return response.data;
};

export const getDomainCloudflare = async (domainName: string) => {
  const { accessToken, refreshToken, userId } = getTokens();
  const response: AxiosResponse<CloudflareResponse.RootObject> = await axios({
    method: METHOD_GET,
    url: `https://europe-west1-helper-cloud-functions.cloudfunctions.net/cloudflare-domain-api?hostname=${domainName}`,
    headers: {
      'access-token': accessToken,
      'refresh-token': refreshToken,
      'user-id': userId
    }
  });
  return response.data;
};

export const deleteDomainCloudflare = async (domainId: string) => {
  const { accessToken, refreshToken, userId } = getTokens();
  const response: AxiosResponse<CloudflareResponse.RootObject> = await axios({
    method: METHOD_DELETE,
    url: `https://europe-west1-helper-cloud-functions.cloudfunctions.net/cloudflare-domain-api?domain_id=${domainId}`,
    headers: {
      'access-token': accessToken,
      'refresh-token': refreshToken,
      'user-id': userId
    }
  });
  return response.data;
};

import { IFloatingFilterParams, NumberFilterModel } from 'ag-grid-community';
import React, { Component, createRef } from 'react';
import { definedObject } from 'utils/define';

interface State {
  currentValue: string;
}

export default class NumberFloatingFilterComponent extends Component<
  IFloatingFilterParams,
  State
> {
  state: State = {
    currentValue: ''
  };

  onParentModelChanged(parentModel: NumberFilterModel) {
    if (definedObject(parentModel)) {
      if (parentModel.type === 'greaterThan') {
        this.setState({ currentValue: `>${parentModel.filter}` });
      } else if (parentModel.type === 'greaterThanOrEqual') {
        this.setState({ currentValue: `>=${parentModel.filter}` });
      } else if (parentModel.type === 'lessThan') {
        this.setState({ currentValue: `<${parentModel.filter}` });
      } else if (parentModel.type === 'lessThanOrEqual') {
        this.setState({ currentValue: `<=${parentModel.filter}` });
      } else if (parentModel.type === 'inRange') {
        this.setState({
          currentValue: `${parentModel.filter}-${parentModel.filterTo}`
        });
      } else if (parentModel.type === 'equals') {
        this.setState({ currentValue: `=${String(parentModel.filter)}` });
      } else if (parentModel.type === 'notEqual') {
        this.setState({ currentValue: `!=${String(parentModel.filter)}` });
      }
    }
  }

  onInputBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9-.+><=!]/g, '');
    const number = value.replace(/[^0-9-.]/g, '');

    this.setState({ currentValue: value });
    if (!value) {
      this.props.parentFilterInstance(instance => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    this.props.parentFilterInstance(instance => {
      if (value.startsWith('>')) {
        if (value.startsWith('>=')) {
          instance.onFloatingFilterChanged('greaterThanOrEqual', number);
        } else {
          instance.onFloatingFilterChanged('greaterThan', number);
        }
      } else if (value.startsWith('<')) {
        if (value.startsWith('<=')) {
          instance.onFloatingFilterChanged('lessThanOrEqual', number);
        } else {
          instance.onFloatingFilterChanged('lessThan', number);
        }
      } else if (/(-?\d+)-(-?\d+)/.test(value)) {
        const filterComponent = this.props.api.getFilterInstance(
          this.props.column.getColId()
        );
        if (filterComponent) {
          const pattern = /(-?\d+)-(-?\d+)/;
          const match = value.match(pattern);
          const filter = parseFloat(match?.[1]!);
          const filterTo = parseFloat(match?.[2]!);

          filterComponent.setModel({
            filterType: 'number',
            type: 'inRange',
            filter,
            filterTo
          });
          this.props.api.onFilterChanged();
        }
      } else if (value.startsWith('-')) {
        instance.onFloatingFilterChanged('equals', number);
      } else if (value.startsWith('=')) {
        instance.onFloatingFilterChanged('equals', number);
      } else if (value.startsWith('!=')) {
        instance.onFloatingFilterChanged('notEqual', number);
      } else {
        instance.onFloatingFilterChanged('equals', number);
      }
    });
  };

  render() {
    return (
      <div className="c-AggridTable__filterWrapper">
        <input
          className="c-AggridTable__colFilterInput"
          onChange={this.onInputBoxChanged}
          value={this.state.currentValue}
        />
      </div>
    );
  }
}

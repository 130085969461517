import auth from './auth';
import { USER, USER_ACTIONS } from '../types';
import { userDefault } from './defaultStates';
import { AnyAction } from 'redux';

export default function user(state = userDefault, action: AnyAction) {
  switch (action.type) {
    case USER_ACTIONS.LOGGED_IN:
      return auth(state, action);
    case USER_ACTIONS.LOGGED_OUT:
      return auth(undefined, action);
    case USER.FETCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

import { Input, Select } from '../Inputs';
import React from 'react';
import { defined } from '../../../utils/define';
import './index.scss';
import { isString } from '../../../utils/primitive';
import { conditionalClass } from 'conditional-class';

interface State {
  openSelect: boolean;
  value: string;
  inputValue?: string;
  options: any[];
}

interface Props {
  onChange: any;
  value: string;
  error: string;
  showErrorTooltip: boolean;
  valueGetter: Function;
  labelGetter: Function;
  options: any[];
  name: string;
  placeholder: string;
  dataTestid: string;
}

export class InputSelect extends React.Component<Props, State> {
  state = {
    value: '',
    inputValue: undefined,
    openSelect: false,
    options: []
  };

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  onChangeSelect = (value: string) => {
    if (value !== 'ant-select-list-title') {
      const selectedOption = this.props.options.find(item => item.id === value);

      this.handleState('inputValue', selectedOption.name);
      this.props.onChange(
        defined(selectedOption) ? selectedOption.name : '',
        value
      );
      this.openHideSelect(false);
    }
  };

  handleChangeInput = async (value: string) => {
    await this.handleState('inputValue', value);
    this.onChange(this.state.inputValue);

    const options =
      defined(value) && value !== ''
        ? this.props.options.filter(item =>
            defined(item) && defined(item.name) && isString(item.name)
              ? item.name.toLowerCase().indexOf(value.toLowerCase()) + 1
              : true
          )
        : this.props.options;

    await this.handleState('options', options);
  };

  openHideSelect = (openSelect: boolean) => {
    this.handleState('openSelect', openSelect);
  };

  onChange = (value1?: string, value2?: string) => {
    this.props.onChange(value1, value2);
  };

  render() {
    return (
      <div
        className={conditionalClass('c-inputSelect', {
          'c-inputSelect--open': this.state.openSelect
        })}
      >
        <Input
          onBlur={() => {
            this.openHideSelect(false);
          }}
          onClick={() => {
            this.handleChangeInput(this.props.value);
            this.openHideSelect(true);
          }}
          id={this.props.name}
          name={this.props.name}
          data-testid={this.props.dataTestid}
          value={this.state.inputValue || this.props.value}
          error={this.props.error}
          showErrorTooltip={this.props.showErrorTooltip}
          onChange={e => this.handleChangeInput(e.target.value)}
          placeholder={this.props.placeholder}
        />
        <Select
          listTitleClass={'italic-text'}
          listTitle={
            this.state.options.length === 0
              ? 'No templates found'
              : 'Select a template from below:'
          }
          id="template"
          name="template"
          groupOptions={true}
          groupBy={'category'}
          valueGetter={this.props.valueGetter}
          labelGetter={this.props.labelGetter}
          options={this.state.options}
          showSearch={true}
          filterOption={true}
          onChange={(value: string) => this.onChangeSelect(value)}
          open={this.state.openSelect}
        />
      </div>
    );
  }
}

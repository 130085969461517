import React, { CSSProperties } from 'react';
import {
  IAggFuncParams,
  ValueGetterParams,
  RowNode,
  CellClassParams,
  RowClassParams,
  RowGroupOpenedEvent,
  CellClickedEvent,
  SortModelItem
} from 'ag-grid-community';
import { message as AntMessage } from 'antd';
import { DEFAULT_TIME_RANGE } from 'constants/time';
import { COLUMN_MIN_WIDTH, COLUMN_MIN_WIDTHS, EXTENDED_COLUMN_MIN_WIDTHS, NO_CHILD_FUNNEL_PRESENT } from 'constants/table';
import { DrilldownReportRow } from 'model/drilldownReportRow';
import { FluxColGroupDef, FluxColProps, FluxReducedColGroupDef } from 'types/table/column';
import { checkAllValuesBeNumber } from 'utils/arrs';
import { defined } from 'utils/define';
import { naturalSort } from 'utils/sort';
import { TableEditModeButton } from 'types/table/index';
import { DrilldownReportRowAttribute } from 'model/drilldownReportRowAttribute';
import { FFIcon, FFNewIcon } from 'uikit';
import { conditionalClass } from 'conditional-class';
import { LOADING_ROW } from 'constants/table';
import { formatter } from 'utils/price-formatter';
import { RowStyleSetting, UpdatedRowsProps } from 'types/table';
import { Page } from 'model/page';
import { TrafficSource } from 'model/trafficSource';
import { OfferSource } from 'model/offerSource';
import { Funnel } from 'model/funnel';
import { FunnelArray, FunnelGroupArray, OfferSourceArray, PageArray, PageGroupArray, TrafficSourceArray } from 'types/redux/store';
import { Category } from 'model/category';
import NumberFloatingFilterComponent from 'components/TableNew/FloatingFilter/Number';
import TextFloatingFilterComponent from 'components/TableNew/FloatingFilter/Text';
import { TimeRangeItems, TimeRangeKeys } from 'types';
import { DrilldownReportParams } from 'model/drilldownReportParams';
import moment, { Moment } from 'moment';
import { dateIsExpired, getDatePickerValues, withoutTimeZone } from 'utils/time-helper';
import { ffWorkerInstance } from 'App';
import { Attribute } from 'model/attribute';
import { FunnelGroup } from 'model/funnelGroup';
import { pregMatchAll } from 'utils/regexp';
import {
  UNCATEGORIZED,
  FUNNEL_CATEGORY_TYPE,
  OFFER_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_TYPE,
  LANDER_CATEGORY_TYPE,
  TRAFFIC_SOURCE_CATEGORY_TYPE,
  VISITOR_JOURNEY_CATEGORY_TYPE,
  LOCATION_CATEGORY_TYPE,
  DEVICE_DATA_CATEGORY_TYPE,
  CONNECTIVITY_CATEGORY_TYPE,
  TIME_SEGMENTATION_CATEGORY_TYPE,
  TRACKING_FIELDS_CATEGORY_TYPE,
  UNKNOWN
} from 'constants/modal';
import { QuickStatsViewTypes } from 'types/quickstats';
import { getFunnelGroupsReporting } from 'redux/selectors/funnelGroups';
import { getOffersReporting } from 'redux/selectors/offers';
import { getOfferSourcesReporting } from 'redux/selectors/offersources';
import { getLandersReporting } from 'redux/selectors/landers';
import { getTrafficSourcesReporting } from 'redux/selectors/trafficsources';
import { getReportingRows } from 'redux/selectors';
import className from 'utils/style/className';
import clsx from 'clsx';

const buttonWidth = 32;

const { getClass } = className('c-AggridTable');

const getMinWidth = (fluxColProps: FluxColProps) => {
  const extendedColId = `${fluxColProps.colId}#${fluxColProps.headerName || ''}`;
  if (defined(EXTENDED_COLUMN_MIN_WIDTHS) && defined(EXTENDED_COLUMN_MIN_WIDTHS[extendedColId])) {
    return EXTENDED_COLUMN_MIN_WIDTHS[extendedColId];
  }
  if (defined(COLUMN_MIN_WIDTHS[fluxColProps?.colId])) {
    return COLUMN_MIN_WIDTHS[fluxColProps.colId];
  }

  return COLUMN_MIN_WIDTH;
};

const aggFunc = (aggFuncParams: IAggFuncParams) => {
  if (checkAllValuesBeNumber(aggFuncParams.values)) {
    let sum: number = 0;
    aggFuncParams.values.forEach((value: number) => {
      sum += value;
    });

    return sum;
  }
};

export const deleteReportingAttributes = (name: string) => {
  if (!name) return;
  const findElements = pregMatchAll(name, /.*: (.*)/g);
  if (findElements?.[0]?.length > 1) {
    return findElements[0][1];
  }
  return name;
};

export const getDefaultCols = <T extends FluxColProps>(data: T[]) => data.filter(col => col.defaultChecked);

export const getDefaultColIds = <T extends FluxColProps>(data: T[]) => getDefaultCols(data).map(col => col.colId);

export const getRowDataByParams = (
  params: ValueGetterParams | RowClassParams | RowGroupOpenedEvent | CellClickedEvent
): DrilldownReportRow | null => {
  return params ? (defined(params?.node?.aggData) ? params.node?.aggData : defined(params.data) ? params.data : params.node?.data) : null;
};

export const getRowAttributeDataByParams = (params: ValueGetterParams | RowClassParams) => {
  const data = getRowDataByParams(params);
  if (defined(params.node?.level) && defined(data?.attributes?.[params.node?.level!])) {
    return data?.attributes[params?.node?.level!];
  }
  return null;
};

const cellValueCreator = (params: ValueGetterParams, fluxColProps: FluxColProps) => {
  const colSymbol = fluxColProps?.symbol || '';
  const value = params.getValue('');
  const valueIsNumber = typeof value === 'number';

  if (fluxColProps.colId === 'visitPercentVsParent' || fluxColProps.colId === 'conversionPercentVsParent') {
    if (params.node?.level === 0) {
      return '-';
    }
  }
  if (!valueIsNumber) {
    return !!value && value !== '0' ? colSymbol + value : '';
  }

  const data = getRowDataByParams(params);
  let loadingRowIndex = -1;
  if (defined(data?.attributes)) {
    loadingRowIndex = data!.attributes.findIndex((item: DrilldownReportRowAttribute) => item.id === LOADING_ROW.id);
  }

  const shouldBeEmpty = loadingRowIndex > -1 && loadingRowIndex === params.node?.level;
  if (shouldBeEmpty) {
    return '';
  }

  if (valueIsNumber && !!colSymbol) {
    const formattedValue = formatter({
      value: Math.abs(value as number),
      minimumFractionDigits: fluxColProps.fractionDigits,
      maximumFractionDigits: fluxColProps.fractionDigits
    });
    const negativeSymbol = value < 0 ? '-' : '';
    return colSymbol === '%'
      ? `${negativeSymbol}‌‌‌‌‌‌‌‌‌‌‌${formattedValue}${colSymbol}`
      : `${negativeSymbol}${colSymbol}${formattedValue}`;
  }

  return `${
    valueIsNumber
      ? formatter({
          value: Math.abs(value as number)
        })
      : value
  }${getDeletedEntityPostfix(params)}`;
};

const getReturnOnInvestmentByParams = (params: ValueGetterParams | RowClassParams) => {
  let returnOnInvestment: number = getRowNodeDataByLevel(params.node!)?.returnOnInvestment;
  if (returnOnInvestment) return returnOnInvestment;

  const valueGetter = params.api.getColumnDef('returnOnInvestment')?.valueGetter;
  if (typeof valueGetter === 'function') {
    returnOnInvestment = valueGetter(params as ValueGetterParams);
  }

  if (typeof returnOnInvestment === 'number') {
    return returnOnInvestment;
  }
  if (typeof returnOnInvestment === 'string') {
    return Number(returnOnInvestment);
  }

  return returnOnInvestment;
};

const getRowBackgroundByROI = (returnOnInvestment: number) => {
  if (returnOnInvestment >= -100 && returnOnInvestment < -60) {
    return '#fce2df';
  } else if (returnOnInvestment >= -60 && returnOnInvestment < -40) {
    return '#fceae6';
  } else if (returnOnInvestment >= -40 && returnOnInvestment < -20) {
    return '#fef1ec';
  } else if (returnOnInvestment >= -20 && returnOnInvestment < -10) {
    return '#fff8f5';
  } else if (returnOnInvestment >= -10 && returnOnInvestment < 10) {
    return '#ffffff';
  } else if (returnOnInvestment >= 10 && returnOnInvestment < 30) {
    return '#f9fcf8';
  } else if (returnOnInvestment >= 30 && returnOnInvestment < 60) {
    return '#f3f9f1';
  } else if (returnOnInvestment >= 60 && returnOnInvestment < 100) {
    return '#edf6ea';
  } else if (returnOnInvestment >= 100 && returnOnInvestment < 200) {
    return '#e7f3e2';
  } else if (returnOnInvestment >= 200) {
    return '#e0f1da';
  } else {
    return '#FFF';
  }
};

export const isArchivedEntity = (status: string) => status === 'archived';

export const getRowStyle = (params: RowClassParams, settings: Partial<RowStyleSetting>): CSSProperties => {
  if (defined(params?.node?.isRowPinned) && params?.node?.isRowPinned()) return {};

  const returnOnInvestment = Math.floor(getReturnOnInvestmentByParams(params));
  let style: CSSProperties = {
    ...(settings.colorizeTableRows
      ? {
          background: getRowBackgroundByROI(returnOnInvestment),
          borderLeft: returnOnInvestment > 0 ? '2px solid #2cb900' : returnOnInvestment < 0 ? '2px solid #ff8375' : '2px solid #e9e9e9'
        }
      : {})
  };

  if (!settings.show || (settings.show && settings.show !== 'not-deleted')) {
    return style;
  }

  const attribute = getRowAttributeDataByParams(params);
  if (defined(attribute) && isArchivedEntity(attribute.status)) {
    return {
      ...style,
      background: 'rgba(220,220,220, 0.3)'
    };
  }

  return style;
};

const getColorClass = (field: string, value: number) => {
  const color = (value: number) => (value === 0 ? '' : value > 0 ? 'ag-cell-value-green' : 'ag-cell-value-red');
  switch (field) {
    case 'profitAndLoss':
      return color(value);
    case 'returnOnInvestment':
      return color(value);
    default:
    //
  }
};

export const makeNumberCol = (fluxColProps: FluxColProps): FluxColProps => {
  const headerTooltip = fluxColProps.headerTooltip || fluxColProps.metric;
  return {
    ...fluxColProps,
    type: 'number',
    field: fluxColProps.colId,
    headerName: deleteReportingAttributes(fluxColProps.headerName || fluxColProps.metric!),
    isUniquenessAttribute: fluxColProps.isUniquenessAttribute,
    headerTooltip: fluxColProps.isUniquenessAttribute
      ? `${headerTooltip} - The unique visitor column is currently enabled. This will significantly increase the time it takes to load reports. If reporting is slow, consider turning this off in column settings.`
      : headerTooltip,
    minWidth: getMinWidth(fluxColProps),
    width: getMinWidth(fluxColProps),
    aggFunc,
    resizable: true,
    sortable: true,
    suppressMenu: true,
    floatingFilter: true,
    unSortIcon: true,
    cellRenderer:
      fluxColProps.cellRenderer ||
      ((params: ValueGetterParams) => (
        <span className={getColorClass(params.colDef.field!, params.getValue(params.colDef.field!))}>
          {cellValueCreator(params, fluxColProps)}
        </span>
      )),
    floatingFilterComponent: NumberFloatingFilterComponent,
    filter: 'agNumberColumnFilter',
    headerClass: conditionalClass(getClass('header', 'number'), {
      [(fluxColProps.headerClass || '').toString()]: true,
      [getClass('uniqueVisitorsMetricHeader')]: !!fluxColProps.isUniquenessAttribute
    }),
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    cellClass: getClass('cell', 'number')
  };
};

export const makeTextCol = (fluxColProps: FluxColProps): FluxColProps => {
  return {
    ...fluxColProps,
    headerName: fluxColProps.headerName || fluxColProps.metric,
    headerTooltip: fluxColProps.headerTooltip || fluxColProps.metric,
    field: fluxColProps.colId,
    sortable: true,
    floatingFilter: true,
    suppressMenu: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilterComponent: TextFloatingFilterComponent,
    cellClass: getClass('cell', 'text'),
    headerClass: getClass('header', 'text'),
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    resizable: true,
    comparator: naturalSort
  };
};

export const getRowNodeDataByLevel = (rowNode: RowNode) => {
  return rowNode?.data || rowNode.aggData;
};

const getButtonCellClass = (params: CellClassParams, type: string, conditionalClass?: { [key: string]: boolean }) => {
  const rowData = getRowDataByParams(params);
  if (rowData?.attributes?.[params.node.level!]?.value === NO_CHILD_FUNNEL_PRESENT) return getClass('buttonCell', 'isEmpty');
  return clsx(getClass('buttonCell', type), conditionalClass);
};

export const buttonQuickstatsColumn = ({ onCellClicked }: { onCellClicked: (event: CellClickedEvent) => void }): FluxColProps => ({
  field: TableEditModeButton.Quickstats,
  colId: TableEditModeButton.Quickstats,
  headerName: '',
  type: 'button',
  width: buttonWidth,
  maxWidth: buttonWidth,
  suppressMenu: true,
  sortable: false,
  resizable: false,
  cellClass: params => getButtonCellClass(params, 'quickstats', {
    [getClass('buttonCell', 'isEmpty')]: params.node.key === UNKNOWN
  }),
  headerClass: getClass('buttonHeader', 'quickstats'),
  onCellClicked,
  cellRenderer: () => <FFIcon name="tableQuickStats" size="bigger" className={getClass('buttonCellIcon')} />
});

export const buttonEditColumn = ({
  hideOnLevels = [],
  showButton = () => true,
  headerClassName = '',
  onCellClicked
}: {
  hideOnLevels?: number[];
  showButton?: (attribute: DrilldownReportRowAttribute) => boolean;
  onCellClicked?: (event: CellClickedEvent) => void;
  headerClassName?: string;
} = {}): FluxColProps => {
  return {
    field: TableEditModeButton.Edit,
    colId: TableEditModeButton.Edit,
    headerName: '',
    type: 'button',
    width: buttonWidth,
    maxWidth: buttonWidth,
    suppressMenu: true,
    sortable: false,
    resizable: false,
    onCellClicked,
    cellClass: params =>
      getButtonCellClass(params, 'edit', {
        [getClass('buttonCell', 'isEmpty')]:
          hideOnLevels?.includes(params.node?.level!) ||
          params.node?.key === UNCATEGORIZED ||
          params.node.key === UNKNOWN ||
          !showButton(getRowDataByParams(params)?.attributes?.[params.node?.level!]!)
      }),
    headerClass: `${getClass('buttonHeader', 'edit')} ${headerClassName}`,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.node?.key === UNCATEGORIZED) return null;
      if (hideOnLevels?.includes(params.node?.level!)) return null;

      return <FFIcon name="tableEdit" size="small" className={getClass('buttonCellIcon')} />;
    }
  };
};

export const buttonDynamicEditBuilderColumn = (): FluxColProps => {
  return {
    field: TableEditModeButton.EditBuilder,
    colId: TableEditModeButton.EditBuilder,
    headerName: '',
    type: 'button',
    width: buttonWidth,
    maxWidth: buttonWidth,
    suppressMenu: true,
    sortable: false,
    resizable: false,
    cellClass: params => getButtonCellClass(params, 'edit', {
      [getClass('buttonCell', 'isEmpty')]: params.node.key === UNKNOWN
    }),
    headerClass: getClass('buttonHeader', 'edit'),
    cellRenderer: (params: ValueGetterParams) => {
      if (params.node?.level !== 1 || isFlowAttribute(params)) {
        return <FFIcon name="tableEdit" size="small" className={getClass('buttonCellIcon')} />;
      } else {
        return <FFIcon name="tableFunnelBuilder" size="small" className={getClass('buttonCellIcon')} />;
      }
    }
  };
};

export const buttonArchiveUnArchiveColumn = ({
  onUnArchive = () => {},
  onArchive = () => {},
  hideOnLevels = [],
  showButton = () => true
}: {
  onUnArchive?: (event: CellClickedEvent) => void;
  onArchive?: (event: CellClickedEvent) => void;
  hideOnLevels?: number[];
  showButton?: (attribute: DrilldownReportRowAttribute) => boolean;
} = {}): FluxColProps => ({
  field: TableEditModeButton.Archive,
  colId: TableEditModeButton.Archive,
  headerName: '',
  type: 'button',
  width: buttonWidth,
  maxWidth: buttonWidth,
  suppressMenu: true,
  sortable: false,
  resizable: false,
  cellClass: params =>
    getButtonCellClass(params, 'archive', {
      [getClass('buttonCell', 'isEmpty')]:
        hideOnLevels?.includes(params.node?.level!) ||
        params.node?.key === UNCATEGORIZED ||
        params.node.key === UNKNOWN ||
        !showButton(getRowDataByParams(params)?.attributes?.[params.node?.level!]!)
    }),
  headerClass: getClass('buttonHeader', 'archive'),
  onCellClicked: event => {
    const data = getRowDataByParams(event);
    if (data?.attributes[event.node.level]?.status === 'archived') {
      onUnArchive(event);
    } else {
      onArchive(event);
    }
  },
  cellRenderer: (params: ValueGetterParams) => {
    if (params.node?.key === UNCATEGORIZED) return null;
    if (hideOnLevels?.includes(params.node?.level!)) return null;
    const data = getRowDataByParams(params);

    if (data?.attributes?.[params?.node?.level!]?.status === 'active') {
      return <FFIcon name="tableArchive" size="medium" className={getClass('buttonCellIcon')} />;
    } else {
      return <FFIcon name="tableUnArchive" size="medium" className={getClass('buttonCellIcon')} />;
    }
  }
});

export const buttonDeleteColumn = ({
  hideOnLevels = [],
  showButton = () => true
}: { hideOnLevels?: number[]; showButton?: (attribute: DrilldownReportRowAttribute) => boolean } = {}): FluxColProps => ({
  field: TableEditModeButton.Delete,
  colId: TableEditModeButton.Delete,
  headerName: '',
  type: 'button',
  width: buttonWidth,
  maxWidth: buttonWidth,
  suppressMenu: true,
  sortable: false,
  resizable: false,
  cellClass: params =>
    getButtonCellClass(params, 'delete', {
      [getClass('buttonCell', 'isEmpty')]:
        hideOnLevels?.includes(params.node?.level!) ||
        params.node?.key === UNCATEGORIZED ||
        params.node.key === UNKNOWN ||
        !showButton(getRowDataByParams(params)?.attributes?.[params.node?.level!]!)
    }),
  headerClass: getClass('buttonHeader', 'delete'),
  cellRenderer: (params: ValueGetterParams) => {
    if (params.node?.key === UNCATEGORIZED) return null;
    if (hideOnLevels?.includes(params.node?.level!)) return null;
    return <FFIcon name="delete" size="medium" className={getClass('buttonCellIcon')} />;
  }
});

export const buttonSettingsColumn = ({
  hideOnLevels = [],
  onCellClicked
}: {
  hideOnLevels?: number[];
  onCellClicked: (event: CellClickedEvent) => void;
}): FluxColProps => ({
  field: TableEditModeButton.Settings,
  colId: TableEditModeButton.Settings,
  headerName: '',
  type: 'button',
  width: buttonWidth,
  maxWidth: buttonWidth,
  suppressMenu: true,
  sortable: false,
  resizable: false,
  onCellClicked,
  cellClass: params =>
    getButtonCellClass(params, 'settings', {
      [getClass('buttonCell', 'isEmpty')]: hideOnLevels?.includes(params.node?.level!) || params.node?.key === UNCATEGORIZED
    }),
  headerClass: getClass('buttonHeader', 'settings'),
  cellRenderer: (params: ValueGetterParams) => {
    if (params.node?.key === UNCATEGORIZED) return null;
    if (hideOnLevels?.includes(params.node?.level!)) return null;
    return <FFNewIcon name="table/settings" size="navigation-md" className={getClass('buttonCellIcon')} />;
  }
});

export const buttonCopyColumn = ({
  hideOnLevels = [],
  showButton = () => true
}: { hideOnLevels?: number[]; showButton?: (attribute: DrilldownReportRowAttribute) => boolean } = {}): FluxColProps => ({
  field: TableEditModeButton.Copy,
  colId: TableEditModeButton.Copy,
  headerName: '',
  type: 'button',
  width: buttonWidth,
  maxWidth: buttonWidth,
  suppressMenu: true,
  sortable: false,
  resizable: false,
  cellClass: params =>
    getButtonCellClass(params, 'copy', {
      [getClass('buttonCell', 'isEmpty')]:
        hideOnLevels?.includes(params.node?.level!) ||
        params.node?.key === UNCATEGORIZED ||
        params.node.key === UNKNOWN ||
        !showButton(getRowDataByParams(params)?.attributes?.[params.node?.level!]!)
    }),
  headerClass: getClass('buttonHeader', 'copy'),
  cellRenderer: (params: ValueGetterParams) => {
    if (params.node?.key === UNCATEGORIZED) return null;
    if (hideOnLevels?.includes(params.node?.level!)) return null;
    return <FFIcon name="tableDuplicate" size="medium" className={getClass('buttonCellIcon')} />;
  }
});

export const getDeletedEntityPostfix = (params: ValueGetterParams | CellClassParams) => {
  if (defined(params)) {
    const rowData: DrilldownReportRow = getRowDataByParams(params)!;

    if (defined(rowData) && defined(rowData.attributes) && rowData.attributes.length) {
      const value = rowData.attributes.find(
        (reportRowAttribute: DrilldownReportRowAttribute) => reportRowAttribute.value === ((params as any).value || '').toString().trim()
      );
      return defined(value) && value.status === 'deleted' ? ' - deleted' : '';
    }
  }
  return '';
};

export const getEntityName = (params: ValueGetterParams, attributeIndex: number) =>
  defined(params?.data?.attributes?.[attributeIndex])
    ? `${params.data.attributes[attributeIndex]?.value} ${getDeletedEntityPostfix(params)}`
    : '';

export const handleUnArchive = async ({
  context,
  data,
  categories,
  page,
  archiveAction,
  params,
  archiveCategoryAction
}: {
  context: { isGroup: boolean; assetId: string; assetValue: string };
  data: FunnelArray | PageArray | OfferSourceArray | FunnelGroupArray | TrafficSourceArray | PageGroupArray;
  categories?: Category[];
  page: 'Offer' | 'OfferSource' | 'TrafficSource' | 'Lander' | 'PageGroup' | 'Funnel Group' | 'Funnel';
  archiveAction: Function;
  archiveCategoryAction?: Function;
  params?: {
    funnels: Funnel[];
    archiveFunnelAction: Function;
  };
}) => {
  let payload = {};
  const isFunnelsPage = page === 'Funnel Group';
  const isCategory = !isFunnelsPage && context.isGroup;

  const category = isCategory ? (categories || []).find(category => category.idCategory === context.assetId) : null;

  if (isCategory) {
    payload = {
      ...category,
      status: 'active'
    };
  } else {
    payload = {
      ...data[context.assetId],
      status: 'active'
    };
  }
  const messageTitle = isCategory ? 'Category' : page === 'Funnel Group' ? `Funnel Group and related funnels` : page;

  try {
    if (!isFunnelsPage) {
      if (isCategory) {
        await archiveCategoryAction!(payload);
        const relatedCategoryEntities = Object.values(data).filter(enttity => {
          const enttityData = enttity as Page | TrafficSource | OfferSource;
          return !!enttityData.idCategory && !!category && enttityData.idCategory === category.idCategory;
        });
        relatedCategoryEntities.forEach(entity => {
          if (entity.status === 'archived') {
            archiveAction({
              ...entity,
              status: 'active'
            });
          }
        });
      } else {
        await archiveAction(payload);
      }
    } else {
      await archiveAction(payload);

      const idFunnels = params!
        .funnels!.filter((item: Funnel) => item.idCampaign === context.assetId && item.status === 'archived')
        .map((item: Funnel) => item.idFunnel);

      if (idFunnels.length > 0) {
        params!.archiveFunnelAction({
          entries: idFunnels
        });
      }
    }

    AntMessage.success(`
          ${messageTitle}
          '${context.assetValue}'
          has been successfully un-archived
        `);
  } catch (e) {
    AntMessage.error(`
          ${messageTitle}
          '${context.assetValue}' cannot be un-archived
        `);
  }
};

export const defaultTableSort = (): SortModelItem[] => [
  {
    colId: 'visits',
    sort: 'desc'
  }
];

export const getTableColumnsDefault = (cols: FluxColProps[]): FluxColProps[] =>
  cols.map((col, idx) =>
    makeTextCol({
      headerName: col.headerName,
      colId: col.colId,
      valueGetter: params => getEntityName(params, idx)
    })
  );

export const getValidatedReportingFilters = (
  dateRangeName: TimeRangeKeys | null,
  filters: DrilldownReportParams,
  lastChangedDate: string,
  dateRange: TimeRangeItems
): { filters: DrilldownReportParams; dateRange: Moment[] } => {
  if (defined(dateRangeName) && defined(dateRange[dateRangeName])) {
    const dateValues = getDatePickerValues(dateRange[dateRangeName]);
    return {
      filters: {
        ...filters,
        timeStart: dateValues.timeStart,
        timeEnd: dateValues.timeEnd
      },
      dateRange: dateRange[dateRangeName]
    };
  }

  const dateValues = getDatePickerValues(dateRange[DEFAULT_TIME_RANGE]);

  return {
    filters: {
      ...filters,
      timeStart: !!lastChangedDate && dateIsExpired(lastChangedDate) ? dateValues.timeStart : filters.timeStart,
      timeEnd: !!lastChangedDate && dateIsExpired(lastChangedDate) ? dateValues.timeEnd : filters.timeEnd
    },
    dateRange:
      !!lastChangedDate && dateIsExpired(lastChangedDate)
        ? dateRange[DEFAULT_TIME_RANGE]
        : [moment(withoutTimeZone(filters.timeStart)), moment(withoutTimeZone(filters.timeEnd))]
  };
};

export const updatedRows = (props: UpdatedRowsProps) => {
  return new Promise((res, _) => {
    ffWorkerInstance.updatedRowsWorker(props).then((result: DrilldownReportRow[]) => {
      res(result);
    });
  });
};

const getStatus = (status?: 'active' | 'archived' | 'deleted') => (defined(status) ? status : 'active');

export const makeReportingRowData = (attributes: DrilldownReportRowAttribute[], date = ''): DrilldownReportRow & { date?: string } => ({
  attributes: attributes,
  conversions: 0,
  cost: 0,
  indirectConversions: 0,
  indirectRevenue: 0,
  landerClicks: 0,
  landerClicksUnique: 0,
  landerViews: 0,
  landerViewsUnique: 0,
  nodeViews: 0,
  nodeViewsUnique: 0,
  offerClicks: 0,
  offerClicksUnique: 0,
  offerViews: 0,
  offerViewsUnique: 0,
  revenue: 0,
  visitors: 0,
  visits: 0,
  date
});

export const makeNewRow = (data: Page | OfferSource | TrafficSource | FunnelGroup | Funnel, page: string) => {
  let attributes: DrilldownReportRowAttribute[] = [];
  if (page === 'landers') {
    const values = data as Page;
    attributes = [
      {
        categoryID: values.idCategory,
        id: values.idPage,
        status: getStatus(values.status),
        value: values.pageName,
        attribute: Attribute.ElementLander
      }
    ];
  } else if (page === 'offers') {
    const values = data as Page;
    attributes = [
      {
        categoryID: values.idCategory,
        id: values.idPage,
        status: getStatus(values.status),
        value: values.pageName,
        offerSourceID: values.offerParams!.idOfferSource,
        attribute: Attribute.ElementOffer
      }
    ];
  } else if (page === 'offersources') {
    const values = data as OfferSource;
    attributes = [
      {
        id: values.idOfferSource,
        status: getStatus(values.status),
        value: values.offerSourceName,
        categoryID: values.idCategory,
        attribute: Attribute.ThirdPartiesOfferSource
      }
    ];
  } else if (page === 'trafficsources') {
    const values = data as TrafficSource;
    attributes = [
      {
        id: values.idTrafficSource,
        status: getStatus(values.status),
        value: values.trafficSourceName,
        categoryID: values.idCategory,
        attribute: Attribute.ThirdPartiesTrafficSource
      }
    ];
  } else if (page === 'funnel') {
    const values = data as Funnel & FunnelGroup;
    attributes = [
      {
        id: values.idCampaign,
        status: getStatus(values.status),
        value: values.campaignName,
        attribute: Attribute.ElementFunnelGroup
      }
    ];

    if (defined(values.idFunnel) && defined(values.funnelName)) {
      attributes.push({
        id: values.idFunnel,
        status: getStatus(values.status),
        value: values.funnelName,
        attribute: Attribute.ElementFunnel,
        info: values.funnelType
      });
    }
  }
  return makeReportingRowData(attributes);
};

export const reduceColGroups = (data: FluxColGroupDef[]) =>
  data.reduce((acc: FluxReducedColGroupDef, item) => {
    acc[item.type] = item.cols;
    return acc;
  }, {} as FluxReducedColGroupDef);

export const uniqueCols = (cols: FluxColProps[]) =>
  cols.reduce((acc: FluxColProps[], crr) => {
    const x = acc.find(fluxCol => fluxCol.colId === crr.colId);
    if (!x) {
      return acc.concat([crr]);
    } else {
      return acc;
    }
  }, []);

export const getReportingRowByType = {
  [FUNNEL_CATEGORY_TYPE as QuickStatsViewTypes]: getFunnelGroupsReporting,
  [OFFER_CATEGORY_TYPE as QuickStatsViewTypes]: getOffersReporting,
  [OFFER_SOURCE_CATEGORY_TYPE as QuickStatsViewTypes]: getOfferSourcesReporting,
  [LANDER_CATEGORY_TYPE as QuickStatsViewTypes]: getLandersReporting,
  [TRAFFIC_SOURCE_CATEGORY_TYPE as QuickStatsViewTypes]: getTrafficSourcesReporting,
  [VISITOR_JOURNEY_CATEGORY_TYPE as QuickStatsViewTypes]: getReportingRows,
  [LOCATION_CATEGORY_TYPE as QuickStatsViewTypes]: getReportingRows,
  [DEVICE_DATA_CATEGORY_TYPE as QuickStatsViewTypes]: getReportingRows,
  [CONNECTIVITY_CATEGORY_TYPE as QuickStatsViewTypes]: getReportingRows,
  [TIME_SEGMENTATION_CATEGORY_TYPE as QuickStatsViewTypes]: getReportingRows,
  [TRACKING_FIELDS_CATEGORY_TYPE as QuickStatsViewTypes]: getReportingRows
};

export const getRestrictToMetricsKeys = (columnDefs: FluxColProps[]) => {
  return [
    ...new Set(
      columnDefs
        .map(columnDef => columnDef?.restrictToMetrics || [])
        .flat()
        .filter(Boolean)
    )
  ];
};

export const isFlowAttribute = (event: CellClickedEvent | ValueGetterParams) => {
  return event?.data
    ? event?.data?.attributes?.[event.node?.level!]?.info === 'flow'
    : event?.node?.aggData?.[event?.node?.level!]?.info === 'flow';
};

import React from 'react';
import Messages from 'components/Messages';
import { LanderOfferCategories, MessageProps } from 'types';
import { FormSectionBox } from 'components/Parts/Content';
import Icon from 'components/Icons';
import { SimpleFlowPath } from 'model/simpleFlowPath';
import { Input, Select } from 'components/Parts/Inputs';
import { SetPathEditorModel } from 'types/ModalForms/simpleFlows';
import { PageArray } from 'types/redux/store';
import PageGroupPageSlider from 'components/PageGroupPageSlider';
import { PageGroupEntry } from 'model/pageGroupEntry';
import { SidebarContext } from 'components/SideBars/context';
import {
  FetchLandersCategoryInfo,
  FetchOffersCategoryInfo
} from 'types/actions';
import { SetButtonGroupProps, SetSidebarLoading } from 'types/dynamicSideBar';
import { Page } from 'model/page';
import { PageGroup } from 'model/pageGroup';
import { TextedIcon } from 'components/Parts/Icon';
import { SidebarProps } from 'types/sidebars';
import { LANDER_MODAL, OFFER_MODAL } from 'constants/modal';
import { generateEntityId } from 'utils/id/generator';
import { defined } from 'utils/define';
import { addCategory } from 'utils/select';
import { getActiveEntities } from 'utils/model';
import { VisibilityWrapper } from 'uikit';

interface State {
  isEditingName: boolean;
  isEditingGroupName: {
    [key: string]: boolean;
  };
  model: SimpleFlowPath;
  modelIsUpdated: boolean;
}

interface Props extends MessageProps {
  model: SimpleFlowPath;
  pagesArray: PageArray;
  onUpdate: SetPathEditorModel;
  fetchLandersCategoryInfo: FetchLandersCategoryInfo;
  fetchOffersCategoryInfo: FetchOffersCategoryInfo;
  setSidebarLoading: SetSidebarLoading;
  sidebarLoading: boolean;
  offers: Page[];
  landers: Page[];
  allPaths: SimpleFlowPath[];
  setButtonGroupProps: SetButtonGroupProps;
  onClose(): void;
  categories: LanderOfferCategories;
}

const defaultGroup = ({
  pageType = 'lander' as PageGroup.PageTypeEnum,
  pageGroupName = ''
}): PageGroup => ({
  pageGroupName,
  idPageGroup: generateEntityId(),
  status: 'active',
  routing: 'rotator',
  pageType: pageType,
  pages: []
});

class SimpleFlowsPathEditor extends React.Component<Props, State> {
  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  state: State = {
    isEditingName: false,
    model: {
      pathId: '',
      pathName: '',
      groups: []
    },
    isEditingGroupName: {},
    modelIsUpdated: false
  };

  async componentDidMount() {
    this.props.setButtonGroupProps({
      okText: 'Save',
      showOk: true,
      showCancel: true,
      cancelText: 'DISCARD',
      onOkClicked: this.handleUpdate
    });
    this.props.setSidebarLoading(true);
    await this.props.fetchLandersCategoryInfo();
    await this.props.fetchOffersCategoryInfo();
    await this.handleState('model', this.props.model, false);
    this.props.setSidebarLoading(false);
  }

  handleState = async <T extends State, P extends keyof State>(
    key: P,
    value: T[P],
    modelIsUpdated = true
  ) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value,
      modelIsUpdated:
        state.modelIsUpdated || (key === 'model' && modelIsUpdated)
    }));
  };

  toggleEditingName = async () => {
    if (this.props.model.isDefault) return;
    await this.handleState('isEditingName', !this.state.isEditingName);
    if (this.state.isEditingName) {
      (document.querySelector('#pathName')! as HTMLInputElement).focus();
    }
  };

  toggleEditingGroupName = async (idPageGroup: string) => {
    await this.handleState('isEditingGroupName', {
      ...this.state.isEditingGroupName,
      [idPageGroup]: this.state.isEditingGroupName[idPageGroup]
        ? !this.state.isEditingGroupName[idPageGroup]
        : true
    });
    if (this.state.isEditingGroupName[idPageGroup]) {
      (document.querySelector(
        `#pageGroupName-${idPageGroup}`
      )! as HTMLInputElement).focus();
    }
  };

  getPageName = (data: PageGroupEntry) => {
    return this.props.pagesArray?.[data.idPage]?.pageName;
  };

  getPageStatus = (data: PageGroupEntry) => {
    return this.props.pagesArray?.[data.idPage]?.status!;
  };

  addLanderGroup = () => {
    this.handleState('model', {
      ...this.state.model,
      groups: [
        ...this.state.model.groups,
        defaultGroup({
          pageType: 'lander',
          pageGroupName: `Lander Group ${
            this.state.model.groups.filter(g => g.pageType === 'lander')
              .length + 1
          }`
        })
      ]
    });
  };

  addOfferGroup = () => {
    this.handleState('model', {
      ...this.state.model,
      groups: [
        ...this.state.model.groups,
        defaultGroup({
          pageType: 'offer',
          pageGroupName: `Offer Group ${
            this.state.model.groups.filter(g => g.pageType === 'offer').length +
            1
          }`
        })
      ]
    });
  };

  onPageSelect = (pageGroup: PageGroup, idPage: string) => {
    this.handleState('model', {
      ...this.state.model,
      groups: this.state.model.groups.map(group =>
        group.idPageGroup === pageGroup.idPageGroup
          ? {
              ...group,
              pages: [...group.pages, { idPage, weight: 1 }]
            }
          : group
      )
    });
  };

  onSwapPage = async (
    idPageGroup: string,
    idPage: string,
    newIdPage: string
  ) => {
    this.handleState('model', {
      ...this.state.model,
      groups: this.state.model.groups.map(group =>
        group.idPageGroup === idPageGroup
          ? {
              ...group,
              pages: group.pages.map(pg =>
                pg.idPage === idPage ? { ...pg, idPage: newIdPage } : pg
              )
            }
          : group
      )
    });
  };

  setPages = (idPageGroup: string, pages: PageGroupEntry[]) => {
    this.handleState('model', {
      ...this.state.model,
      groups: this.state.model.groups.map(group =>
        group.idPageGroup === idPageGroup
          ? {
              ...group,
              pages: pages
            }
          : group
      )
    });
  };

  createNewPage = (pageType: PageGroup.PageTypeEnum) => {
    const sidebar = this.context as SidebarProps;
    if (pageType === 'lander') {
      sidebar.openSidebar(LANDER_MODAL);
    } else {
      sidebar.openSidebar(OFFER_MODAL);
    }
  };

  handleUpdate = () => {
    const allPathsName = this.props.allPaths
      .filter(path => path.pathId !== this.state.model.pathId)
      .map(path => path.pathName);
    const anyGroupWithoutAnyPages = this.state.model.groups.find(
      pageGroup => !pageGroup.pages.length
    );

    if (!this.state.model.pathName) {
      this.props.showMessage(
        Messages.warning('All paths must have a name added.')
      );
      return;
    }
    if (allPathsName.includes(this.state.model.pathName)) {
      this.props.showMessage(
        Messages.warning('All paths must have unique names.')
      );
      return;
    }
    if (!this.state.model.groups.length) {
      this.props.showMessage(
        Messages.warning('A path must have at least one page group in it.')
      );
      return;
    }
    if (defined(anyGroupWithoutAnyPages)) {
      this.props.showMessage(
        Messages.warning(
          'Any group in a path must have at least one page in it.'
        )
      );
      return;
    }

    if (this.state.modelIsUpdated) {
      this.props.onUpdate(this.state.model);
    }

    this.props.onClose();
  };

  onEditName = (value: string) => {
    this.handleState('model', {
      ...this.state.model,
      pathName: value
    });
  };

  onEditGroupName = (value: string, groupId: string) => {
    this.handleState('model', {
      ...this.state.model,
      groups: this.state.model.groups.map(g =>
        g.idPageGroup === groupId ? { ...g, pageGroupName: value } : g
      )
    });
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <div className="cform-simpleFlows__pathEditor">
        <FormSectionBox withBoxPadding={false}>
          <div className="flex flex-gap-15 flex-align-center height-75 padding-x-20">
            <VisibilityWrapper visible={!this.state.model.isDefault}>
              <Icon type="flux-modify" className="icon-size-22" />
            </VisibilityWrapper>
            {this.state.isEditingName ? (
              <Input
                id="pathName"
                name="pathName"
                data-testid="pathName"
                value={this.state.model.pathName}
                onChange={e => this.onEditName(e.target.value)}
                onBlur={this.toggleEditingName}
              />
            ) : (
              <span
                onClick={this.toggleEditingName}
                className="font-weight-600 font-size-18"
              >
                {this.state.model.pathName || 'Path Name Here'}
              </span>
            )}
          </div>
        </FormSectionBox>
        {this.state.model.groups.map((group, idx) => (
          <div key={group.idPageGroup}>
            <FormSectionBox
              withBoxPadding={false}
              key={group.idPageGroup}
              className="cform-simpleFlows__pathEditor--group"
            >
              <div className="flex flex-gap-15 flex-align-center padding-x-25 padding-y-25">
                <Icon type="flux-modify" className="icon-size-22" />
                {this.state.isEditingGroupName[group.idPageGroup] ? (
                  <Input
                    id={`pageGroupName-${group.idPageGroup}`}
                    name="pageGroupName"
                    data-testid="pageGroupName"
                    value={group.pageGroupName}
                    onChange={e =>
                      this.onEditGroupName(e.target.value, group.idPageGroup)
                    }
                    onBlur={() =>
                      this.toggleEditingGroupName(group.idPageGroup)
                    }
                  />
                ) : (
                  <span
                    onClick={() =>
                      this.toggleEditingGroupName(group.idPageGroup)
                    }
                    className="font-weight-600 font-size-18"
                  >
                    {group.pageGroupName}
                  </span>
                )}
              </div>
              <PageGroupPageSlider
                pages={group.pages}
                getName={this.getPageName}
                getStatus={this.getPageStatus}
                setPages={pages => this.setPages(group.idPageGroup, pages)}
                isActionMapped={false}
                type={
                  group.pageType === 'lander' ? 'landerGroup' : 'offerGroup'
                }
                sidebarProps={this.context}
                data={{
                  landers: this.props.landers,
                  offers: this.props.offers
                }}
                onSwapPage={(idPage: string, newIdPage: string) =>
                  this.onSwapPage(group.idPageGroup, idPage, newIdPage)
                }
                categories={
                  group.pageType === 'lander'
                    ? this.props.categories.landers
                    : this.props.categories.offers
                }
              />
              <div className="border-top-1">
                <div className="flex flex-align-center padding-x-25 padding-y-20 flex-gap-15">
                  <Select
                    placeholder={
                      group.pageType === 'lander'
                        ? 'Select Lander'
                        : 'Select Offer'
                    }
                    style={{ width: 400 }}
                    options={addCategory(
                      getActiveEntities(
                        group.pageType === 'lander'
                          ? this.props.landers
                          : this.props.offers
                      ).filter(
                        item =>
                          !defined(
                            group.pages.find(
                              item2 => item.idPage === item2.idPage
                            )
                          )
                      ),
                      group.pageType === 'lander'
                        ? this.props.categories.landers
                        : this.props.categories.offers
                    )}
                    valueGetter={(option: Page) => option.idPage}
                    labelGetter={(option: Page) => option.pageName}
                    groupOptions={true}
                    groupBy="category"
                    onChange={(idPage: string) =>
                      this.onPageSelect(group, idPage)
                    }
                    showSearch={true}
                    filterOption={true}
                  />
                  <TextedIcon
                    text={`Create New ${
                      group.pageType === 'lander' ? 'Lander' : 'Offer'
                    }`}
                    onClick={() => this.createNewPage(group.pageType)}
                  />
                </div>
              </div>
            </FormSectionBox>
            <VisibilityWrapper
              visible={idx < this.state.model.groups.length - 1}
            >
              <div className="flex flex-justify-center">
                <Icon type="flux-allActionsLinker" />
              </div>
            </VisibilityWrapper>
          </div>
        ))}
        <div className="flex flex-align-center flex-gap-15 flex-justify-center margin-top-20">
          <TextedIcon text="Add Lander Group" onClick={this.addLanderGroup} />
          <TextedIcon text="Add Offer Group" onClick={this.addOfferGroup} />
        </div>
      </div>
    );
  }
}

export default Messages.injectIn(SimpleFlowsPathEditor);

import { Input, Select, Label } from '../../../Parts/Inputs';
import { TrafficSource } from '../../../../model/trafficSource';
import React, { Component, createRef, RefObject } from 'react';
import { FunnelNode } from '../../../../model/funnelNode';
import { jsCode } from '../../../../constants/form';
import Messages from 'components/Messages';
import { MessageProps, OptionCategory } from 'types';
import { Funnel } from 'model/funnel';
import { defined } from 'utils/define';
import { UserSettings } from '../../../../model/userSettings';
import { getQueryUrlSeparate, getByTrackingFieldSlotsQuery } from 'utils/url';
import { getDefaultDomain } from '../../../../utils/settings';
import { copy } from 'utils/copy';
import { UnsavedFunnelAlert, NoDomainAlert } from '../../../Parts/Alert';
import { sortByName } from '../../../../utils/sort';
import { DomainEntry } from '../../../../model/domains/domainEntry';
import { getActiveEntities } from 'utils/model';
import { conditionalClass } from 'conditional-class';
import { FormSectionBox } from 'components/Parts/Content';
import { FFSwitch, VisibilityWrapper } from 'uikit';
import { GetLanderById, GetOfferById } from 'types/actions';
import { IconTooltip } from 'components/Parts/Tooltip';
import CodeSnippet from 'components/CodeSnippet';

const PLEASE_FILL = 'Please select an option in all of the required fields';

type SwitchEnum = 'url' | 'js';
interface Props extends MessageProps {
  funnel?: Funnel;
  funnelNode?: FunnelNode;
  funnelNodes?: FunnelNode[];
  trafficSources?: TrafficSource[];
  userSettings?: UserSettings;
  domains?: DomainEntry[];
  pages?: OptionCategory[];
  pagesName?: string;
  description?: string | (string | JSX.Element)[];
  openTsOnMount: boolean;
  isChangedFunnel: boolean;
  switch: SwitchEnum;
  showNodeId?: boolean;
  isFlow?: boolean;
  isDirectLink?: boolean;
  getLanderById?: GetLanderById;
  getOfferById?: GetOfferById;
  onSelectDestination?: (value: FunnelNode) => void;
}

interface Model {
  domain: string;
  source: string;
  cost: string;
  idPage: string;
}

interface State {
  model: Model;
  trackingFieldSlotsQuery: string;
  setDefaultTSSwitch: boolean;
  pageOptions: OptionCategory[];
  isWordpressBasedPage: boolean;
}

const getDefaultModel = () => ({
  domain: '',
  source: '',
  cost: '',
  idPage: '-1'
});

const getYourLinkJs = (
  params: {
    domain: string;
    idFunnel: string;
    cost: string;
    node: string;
    trafficSource: string;
    trackingFieldSlotsQuery?: string;
    pageDomain?: string;
    idPage: string;
  },
  appendTsParams = true,
  withSeparator = true,
  appendIdPage = true,
) => {
  let urlParams = `f=${params.idFunnel}`;

  const domain = !!params.pageDomain ? params.pageDomain : params.domain;

  if (params.node) {
    urlParams += `&n=${params.node}`;
  }

  if (params.idPage && appendIdPage) {
    urlParams += `&p=${params.idPage}`;
  }

  if (params.trafficSource) {
    urlParams += `&ts=${params.trafficSource}`;
  }

  if (params.cost) {
    urlParams += `&c=${params.cost}`;
  }

  if (
    appendTsParams &&
    params.trackingFieldSlotsQuery &&
    params.trackingFieldSlotsQuery !== ''
  ) {
    urlParams += `&${params.trackingFieldSlotsQuery}`;
  }

  return `${domain}${
    withSeparator ? getQueryUrlSeparate(urlParams, domain) : ''
  }${urlParams}`;
};

class EntranceLink extends Component<Props, State> {
  elRefs = {
    nodeId: createRef<HTMLDivElement>(),
    code: createRef<HTMLDivElement>(),
    codeView: createRef<HTMLTextAreaElement>(),
    urlToUse: createRef<HTMLDivElement>(),
    urlSuffix: createRef<HTMLDivElement>(),
    basePageUrl: createRef<HTMLDivElement>()
  };

  state: State = {
    model: getDefaultModel() as Model,
    trackingFieldSlotsQuery: '',
    setDefaultTSSwitch: false,
    pageOptions: [],
    isWordpressBasedPage: false
  };

  static defaultProps: Partial<Props> = {
    showNodeId: true,
    isDirectLink: false
  };

  componentDidMount() {
    if (this.props.openTsOnMount) {
      setTimeout(this.openTrafficSourceSelect, 500);
    }
    if (defined(this.props.pages) && this.props.pages.length) {
      this.setPageData();
    }
  }

  openTrafficSourceSelect = () => {
    if (
      defined(this.props.domains) &&
      this.props.domains.length > 0 &&
      document.getElementById('trafficsource')
    ) {
      document.getElementById('trafficsource')!.click();
    }
  };

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      defined(this.props.funnelNode) &&
      defined(prevProps.funnelNode) &&
      prevProps.funnelNode.idNode !== this.props.funnelNode.idNode &&
      !this.props.isFlow
    ) {
      await this.setState({
        model: getDefaultModel()
      });
    }

    if (
      (prevProps.openTsOnMount !== this.props.openTsOnMount ||
        prevProps.funnelNode?.idNode !== this.props.funnelNode?.idNode) &&
      this.props.openTsOnMount
    ) {
      setTimeout(this.openTrafficSourceSelect, 500);
    }

    if (this.props.funnelNode?.idNode !== prevProps.funnelNode?.idNode) {
      this.setPageData();
    }

    if (
      this.props.switch !== prevProps.switch ||
      (this.isJsSwitch() && this.state.model.idPage === '-1')
    ) {
      if (this.isJsSwitch() && this.state.model.idPage === '-1') {
        this.changeModel('idPage', '');
      } else if (!this.state.model.idPage) {
        this.changeModel('idPage', '-1');
      }
    }

    if (
      this.state.model.source !== prevState.model.source ||
      this.state.model.cost !== prevState.model.cost
    ) {
      const trafficSource = this.getTrafficSourceDataById(
        this.state.model.source
      );
      const trackingFieldSlots = trafficSource?.trackingFieldSlots;
      const trackingFieldSlotsValues = defined(trackingFieldSlots)
        ? Object.values(trackingFieldSlots)
        : [];
      const trackingFieldSlotsQuery = getByTrackingFieldSlotsQuery(
        trackingFieldSlotsValues,
        this.state.model.cost
      );
      this.setState({
        trackingFieldSlotsQuery
      });
    }
  }

  setPageData = async () => {
    if (this.props.isFlow) {
      return;
    }
    const options = this.getPageOptions();
    await this.setState((state: State) => ({
      ...state,
      pageOptions: options,
      model: {
        ...state.model,
        idPage:
          options.find(item => item.id === '-1')?.id ||
          (options.length === 1 ? options?.[0]?.id : '')
      }
    }));
    this.getPageData(this.state.model.idPage);
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (defined(props.userSettings) && state.model.domain === '') {
      return {
        model: {
          ...state.model,
          domain: getDefaultDomain(props.userSettings, props.domains)
        }
      };
    }

    return null;
  }

  handleStateValues = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  handleCopyClick = (context: RefObject<any>) => {
    copy(context, this.props.showMessage, Messages);
  };

  getTrafficSourceDataById = (id: string) =>
    (this.props.trafficSources || []).find(t => t.idTrafficSource === id);

  changeModel = (field: keyof Model, value: string) => {
    this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        [field]: value,
        cost:
          field === 'cost'
            ? value
            : this.getTrafficSourceDataById(
                field === 'source' ? value : state.model.source
              )?.defaultCost! || ''
      }
    }));

    if (field === 'idPage' && value) {
      this.getPageData(value);
    }
  };

  getPageData = (idPage: string) => {
    if (idPage !== '-1') {
      if (this.props.funnelNode?.nodeType === 'landerGroup') {
        this.props.getLanderById!(idPage);
      } else {
        this.props.getOfferById!(idPage);
      }
    }
  };

  handleSwitch = (value: boolean) => {
    this.setState({ setDefaultTSSwitch: value });
  };

  getActiveClass = (value: SwitchEnum) => {
    return this.props.switch === value ? 'active' : '';
  };

  getCode = () => {
    if (this.state.model.source) {
      let url = `https://${this.state.model.domain}/fts/${
        this.props.funnel!.idFunnel
      }${!!this.state.model.source ? `-${this.state.model.source}` : ``}${
        this.props.funnelNode!.nodeType !== 'root'
          ? `/${this.props.funnelNode!.idNode}`
          : ``
      }${
        this.state.model.idPage !== '-1' ? `/${this.state.model.idPage}` : ``
      }`;

      if (!this.state.trackingFieldSlotsQuery && this.state.model.cost) {
        url += `${getQueryUrlSeparate(this.state.model.cost, url)}c=${
          this.state.model.cost
        }`;
      }

      if (this.props.switch === 'url') {
        url += `${getQueryUrlSeparate(
          this.state.trackingFieldSlotsQuery,
          url
        )}${this.state.trackingFieldSlotsQuery}`;

        return url;
      }

      return jsCode
        .replace('#FUNNEL-ID#', this.props.funnel!.idFunnel)
        .replace('#DOMAIN#', this.state.model.domain);
    }

    return PLEASE_FILL;
  };

  isJsSwitch = () => this.props.switch === 'js';

  getPageUrl = () => {
    if (!this.state.model.idPage) {
      return `Please select a page first`;
    }
    const page = (this.props.pages || []).find(
      page => page.id === this.state.model.idPage
    );
    return page?.data?.url || '';
  };

  getPageOptions = () => {
    let pages: OptionCategory[] = [];
    if (defined(this.props.pages)) {
      pages = sortByName(this.props.pages, 'name');
      pages = pages.filter(p => p.id !== '-1');
      if (!this.isJsSwitch()) {
        const rotator = this.props.pages!.find(p => p.id === '-1');
        if (defined(rotator)) {
          pages = [rotator, ...pages];
        }
      }
    }
    return pages || [];
  };

  getYourLinkJsFn = (withBaseUrl = true, withSeparator = true) => {
    if (!this.state.model.source) {
      return PLEASE_FILL;
    }
    return getYourLinkJs(
      {
        domain: withBaseUrl ? this.getPageUrl() : '',
        idFunnel: this.props.funnel!.idFunnel,
        cost: this.state.model.cost,
        node: this.props.funnelNode!.idNode,
        trafficSource: this.state.model.source,
        trackingFieldSlotsQuery: this.state.trackingFieldSlotsQuery,
        idPage: this.state.model.idPage
      },
      true,
      withSeparator,
      !this.state.isWordpressBasedPage
    );
  };

  render() {
    return (
      <div className="c-entranceLink">
        <VisibilityWrapper visible={this.props.isChangedFunnel}>
          <div className="c-entranceLink--unsavedFunnelAlert margin-bottom-15">
            <UnsavedFunnelAlert />
          </div>
        </VisibilityWrapper>

        {!defined(this.props.domains) || this.props.domains.length === 0 ? (
          <div className="margin-bottom-15">
            <NoDomainAlert />
          </div>
        ) : (
          <>
            <FormSectionBox
              title={this.isJsSwitch() ? 'Direct Links' : 'Redirect Links'}
              className="flex flex-col flex-gap-15"
            >
              <VisibilityWrapper visible={!!this.props.description}>
                <div className="c-entranceLink--description">
                  {this.props.description}
                </div>
              </VisibilityWrapper>

              <VisibilityWrapper visible={this.props.showNodeId!}>
                <div className="flex flex-gap-5 flex-align-center width-full">
                  <span className="min-width-fitContent">Node ID</span>
                  <CodeSnippet
                    code={this.props.funnelNode!.idNode}
                    forwardedRef={this.elRefs.nodeId}
                    className="width-full"
                  />
                </div>
              </VisibilityWrapper>

              <div
                className={conditionalClass(
                  'flex flex-gap-5 flex-align-center width-full',
                  {
                    'has-error': !this.state.model.source
                  }
                )}
              >
                <span className="min-width-fitContent">Traffic Source</span>
                <Select
                  id="trafficsource"
                  data-testid="trafficsourceSelect"
                  style={{ width: `100%` }}
                  value={this.state.model.source}
                  onChange={(value: string) =>
                    this.changeModel('source', value)
                  }
                  options={getActiveEntities(this.props.trafficSources || [])}
                  valueGetter={(opt: TrafficSource) => opt.idTrafficSource}
                  labelGetter={(opt: TrafficSource) => opt.trafficSourceName}
                  placeholder={'Select a Traffic Source'}
                  filterOption={true}
                  showSearch={true}
                />
              </div>
              <VisibilityWrapper visible={defined(this.props.funnelNodes)}>
                <div
                  className={conditionalClass(
                    'flex flex-gap-5 flex-align-center width-full',
                    {
                      'has-error': !this.props.funnelNode
                    }
                  )}
                >
                  <span className="min-width-fitContent">Destination</span>
                  <Select
                    id="destination"
                    data-testid="destinationSelect"
                    style={{ width: '100%' }}
                    value={this.props.funnelNode?.idNode}
                    onChange={(idNode: string) => {
                      this.props.onSelectDestination!(
                        this.props.funnelNodes?.find(n => n.idNode === idNode)!
                      );
                    }}
                    options={this.props.funnelNodes!}
                    valueGetter={(opt: FunnelNode) => opt.idNode}
                    labelGetter={(opt: FunnelNode) => opt.nodeName}
                    placeholder="Start of this flow"
                    filterOption={true}
                    showSearch={true}
                    suspendSort
                  />
                </div>
              </VisibilityWrapper>

              <div className="flex flex-gap-5 flex-align-center width-full">
                <span className="min-width-fitContent">
                  Entrance cost <span>(optional)</span>
                </span>
                <Input
                  data-testid="entrance-link-cost"
                  placeholder="Entrance cost (optional)"
                  value={this.state.model.cost}
                  onChange={e => this.changeModel('cost', e.target.value)}
                />
              </div>
              <div className="flex flex-gap-5 flex-align-center width-full">
                <span className="min-width-fitContent">Tracking Domain</span>
                <Select
                  data-testid="trackingDomain"
                  style={{ width: `100%` }}
                  value={this.state.model.domain}
                  onChange={(value: string) =>
                    this.changeModel('domain', value)
                  }
                  options={this.props.domains!}
                  valueGetter={(option: DomainEntry) => option.domain}
                  labelGetter={(option: DomainEntry) => option.domain}
                  showSearch={true}
                />
              </div>
              <VisibilityWrapper
                visible={
                  defined(this.props.pages) && this.props.pages.length > 0
                }
              >
                <div
                  className={conditionalClass(
                    'flex flex-gap-5 flex-align-center width-full',
                    {
                      'has-error': !this.state.model.idPage
                    }
                  )}
                >
                  <span className="min-width-fitContent">
                    {this.props.pagesName}
                  </span>
                  <Select
                    style={{ width: `100%` }}
                    value={this.state.model.idPage}
                    onChange={(value: string) =>
                      this.changeModel('idPage', value)
                    }
                    options={this.state.pageOptions}
                    placeholder={`Select ${this.props.pagesName}`}
                    valueGetter={(option: OptionCategory) => option.id}
                    labelGetter={(option: OptionCategory) => option.name}
                    suspendSort={true}
                    data-testid="page"
                    classNameGetter={(option: OptionCategory) =>
                      option.id === '-1'
                        ? 'select-option-bottom-border'
                        : undefined
                    }
                    showSearch={true}
                  />
                </div>
              </VisibilityWrapper>
              <VisibilityWrapper visible={this.props.isDirectLink!}>
                <FFSwitch
                  checked={this.state.isWordpressBasedPage}
                  onClick={value =>
                    this.handleStateValues('isWordpressBasedPage', value)
                  }
                >
                  Toggle if this is a Wordpress-based page
                </FFSwitch>
              </VisibilityWrapper>
              <VisibilityWrapper visible={this.isJsSwitch()}>
                <>
                  <div className="border-bottom-1 height-1 margin-top-5 margin-bottom-5" />
                  <div className="flex flex-col">
                    <Label
                      htmlFor="pageURL"
                      text={[
                        'Base page URL',
                        <IconTooltip
                          key="base-page-url"
                          title="Base page URL"
                          body={[
                            <p>
                              If using platforms like Facebook, Google Ads and
                              Microsoft Ads, use the base page URL for your ad
                              and put the URL suffix in the appropriate field,
                              e.g. optional URL params or final URL suffix.
                            </p>
                          ]}
                        />
                      ]}
                    />
                    <CodeSnippet
                      code={this.getPageUrl()}
                      forwardedRef={this.elRefs.basePageUrl}
                      data-testid="pageURL"
                      className="width-full"
                      disabled={
                        !this.state.model.source || !this.state.model.idPage
                      }
                    />
                  </div>

                  <div className="flex flex-col">
                    <Label
                      htmlFor="urlSuffix"
                      text={[
                        'URL suffix',
                        <IconTooltip
                          key="url-suffix"
                          title="URL suffix"
                          body={[
                            <p>
                              This is the optional URL query string data that
                              you should put under final URL suffix or similar
                              options.
                            </p>
                          ]}
                        />
                      ]}
                    />
                    <CodeSnippet
                      code={this.getYourLinkJsFn(false, false)}
                      forwardedRef={this.elRefs.urlSuffix}
                      data-testid="urlSuffix"
                      className="width-full"
                      disabled={
                        !this.state.model.source || !this.state.model.idPage
                      }
                    />
                  </div>

                  <div className="flex flex-col">
                    <Label
                      htmlFor="fullDirectUrl"
                      text={[
                        'Full direct URL',
                        <IconTooltip
                          key="full-direct-url"
                          title="Full direct URL"
                          body={[
                            <p>
                              This is the resulting full URL that the traffic
                              source should hopefully load on click. If you are
                              using a traffic source that only has one field for
                              URL, use the full direct URL.
                            </p>
                          ]}
                        />
                      ]}
                    />
                    <CodeSnippet
                      code={this.getYourLinkJsFn()}
                      data-testid="fullDirectUrl"
                      className="width-full"
                      forwardedRef={this.elRefs.urlToUse}
                      disabled={
                        !this.state.model.source || !this.state.model.idPage
                      }
                    />
                  </div>
                </>
              </VisibilityWrapper>

              <VisibilityWrapper visible={!this.isJsSwitch()}>
                <CodeSnippet
                  code={this.getCode()}
                  forwardedRef={this.elRefs.code}
                  data-testid="url-js-textarea"
                  disabled={!this.state.model.source}
                />
              </VisibilityWrapper>
            </FormSectionBox>
          </>
        )}
      </div>
    );
  }
}

export default Messages.injectIn(EntranceLink);

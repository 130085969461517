import { defined } from '../define';
import { FormContextModal } from '../../types/modal';
import { AnyObject } from '../../types';

export const getNameModal = (contextModal: FormContextModal) =>
  defined(contextModal.data) ? `(${contextModal.data.value})` : '';

export const controlDeleteProcess = (
  callback: Function,
  state: {
    sure: boolean;
    submitDelayEnded: boolean;
  }
) => {
  if (!state.sure) {
    callback('sure', true);
    if (!state.submitDelayEnded) {
      setTimeout(() => {
        callback('submitDelayEnded', true);
      }, 500);
    }
  }
};

export const getModalTitle = (contextModal: FormContextModal) => {
  if (defined(contextModal?.copyName) && contextModal.copyName !== '') {
    return `Duplicate`;
  }

  return !!contextModal?.data ? 'Edit' : 'Create';
};

export const isCopyByContextModal = (
  contextModal: FormContextModal | AnyObject
) => defined(contextModal?.copyName) && contextModal.copyName !== '';

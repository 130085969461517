import React from 'react';
import FluxModal from 'components/Modal';
import { DOMAINS_MODAL_EDIT_DNS } from 'constants/modal';
import { ModalsConsumer } from 'components/Modals/context';
import { ModalProps } from '../types';
import { DNSEditDeleteProps } from 'types/ModalForms/domain';
import { FormContextModal } from 'types/modal';
import DnsRecordCreator from 'components/DnsManagement/RecordCreator';
import { connect } from 'react-redux';
import { updateDnsRecord } from 'redux/actions/domains';
import { AnyObject } from 'types';

const EditDnsModal = ({
  isOpen,
  closeSelf,
  onSubmit = () => {},
  params
}: DNSEditDeleteProps) => (
  <FluxModal
    width={'auto'}
    destroyOnClose
    title={`Edit Record`}
    classNames={['edit-dns-modal']}
    isOpen={isOpen}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <DnsRecordCreator
        isEditMode={true}
        onSubmit={() => onSubmit(params)}
        params={params}
        closeSelf={closeSelf}
      />
    )}
  />
);

const ConnectedEditDnsModal = ({ updateDnsRecord }: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <EditDnsModal
            isOpen={isOpenModal(DOMAINS_MODAL_EDIT_DNS)}
            closeSelf={() => closeModal(DOMAINS_MODAL_EDIT_DNS)}
            onSubmit={updateDnsRecord}
            params={
              (getContextModal(DOMAINS_MODAL_EDIT_DNS) as FormContextModal)
                .data as any
            }
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(null, { updateDnsRecord })(ConnectedEditDnsModal);

import React from 'react';
import Messages from 'components/Messages';
import { ContentWrapper } from 'components/Parts/Content';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from '../../../utils/reactive/generic';
import { withLoading } from 'components/Loading';
import { FunnelDeleteFormProps } from '../../../types/ModalForms/funnelDelete';
import { AnyObject, CancellablePromise } from 'types';
import { FFButton } from 'uikit';
import makeCancellablePromise, { timeoutPromise } from 'utils/promises';

interface State extends ReactiveValidateState {
  validationErrors: AnyObject;
  isTouchedForm: boolean;
  isHolding: boolean;
}
class FunnelDeleteForm extends ReactiveValidateComponent<
  FunnelDeleteFormProps,
  State
> {
  state: State = {
    validationErrors: {},
    isTouchedForm: false,
    isHolding: false
  };
  private submitPromise: Partial<CancellablePromise> = {};

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.handleState('isHolding', true);
    this.submitPromise = makeCancellablePromise(timeoutPromise(1000));

    try {
      await this.submitPromise.promise;
      this.props.startLoading('ok');
      await this.props.handleSubmit({
        entries: [this.props.contextModal.data.id]
      });
      this.props.stopLoading('all');
      this.props.showMessage(Messages.success(`${this.props.contextModal.data.value} has been deleted`));
      this.props.actions.onOk();
    } catch (e) {
      this.props.showMessage(Messages.failed(`${this.props.contextModal.data.value} Funnel cannot be deleted`));
      this.props.stopLoading('all');
      this.props.actions.onOk();
    }
  };

  handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.actions.onClose();
  };

  onMouseLeaveOrUp = () => {
    if (!this.submitPromise?.cancel) return;
    this.handleState('isHolding', false);
    this.submitPromise.cancel();
    this.props.stopLoading('all');
  };

  render() {
    return (
      <ContentWrapper>
        <p className="font-weight-bold color-red">
          Deleting a funnel will remove it from this page, prevent further
          editing, but it will not break existing tracking links/JS or delete
          existing data.
        </p>
        <p>
          Deleted funnels are still shown in reports with the text “(deleted)”
          appended to the funnel name.
        </p>
        <p>
          This is an <strong>irreversible action</strong>, we suggest archiving
          unless you are sure you do not need this funnel anymore.
        </p>
        <p>
          To delete underlying funnel data, use the <strong>Reset Stats</strong>{' '}
          feature.
        </p>
        <div className="flex flex-gap-5">
          <FFButton
            className="flex-1"
            onMouseDown={this.handleSubmit}
            onMouseLeave={this.onMouseLeaveOrUp}
            onMouseUp={this.onMouseLeaveOrUp}
            loading={this.state.isHolding}
            disabled={this.props.loading.ok}
            loadingType={this.props.loading.ok ? 'default' : 'skeleton'}
            color="dangerColor"
          >
            Hold to Delete
          </FFButton>
          <FFButton
            className="flex-1"
            onClick={this.handleCancel}
            disabled={this.props.loading.ok}
          >
            Cancel
          </FFButton>
        </div>
      </ContentWrapper>
    );
  }
}

export default withLoading(Messages.injectIn(FunnelDeleteForm));

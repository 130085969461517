import React, { PropsWithChildren, CSSProperties } from 'react';
import './style.scss';
import clsx from 'clsx';

export default ({
    children,
    className,
    ...rest
  }: PropsWithChildren & CSSProperties & { className?: string }) => (
  <div className={clsx('c-row', className)} style={rest}>
    {children}
  </div>
);

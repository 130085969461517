import React from 'react';
import Messages from 'components/Messages';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from '../../../utils/reactive/generic';
import { SwitchButton } from 'components/Parts/Buttons';
import { Label, Select } from 'components/Parts/Inputs';
import { ContentWrapper } from 'components/Parts/Content';
import { FunnelGroup } from '../../../model/funnelGroup';
import { Funnel } from '../../../model/funnel';
import { AnyObject, CancellablePromise } from '../../../types';
import { withLoading } from 'components/Loading';
import { FunnelGroupDeleteFormProps } from 'types/ModalForms/funnelGroupDelete';
import { getAllFunnelsOfFunnelGroup } from '../../../utils/model';
import { defined } from '../../../utils/define';
import { DrilldownReportRowAttribute } from 'model/drilldownReportRowAttribute';
import makeCancellablePromise, { timeoutPromise } from 'utils/promises';
import { FFButton } from 'uikit';

interface ButtonState {
  isDeleteAllFunnelsOn: boolean;
  isMoveSelectedFunnelsOn: boolean;
}

interface State extends ReactiveValidateState {
  model: FunnelGroup;
  buttons: ButtonState;
  selectDestinationFunnelGroup: string;
  moveFunnelsSelected: string[];
  funnels: Funnel[];
  deletedItems: number;
  validationErrors: AnyObject;
  isTouchedForm: boolean;
  isHolding: boolean;
}

const defaultModelState: FunnelGroup = {
  idCampaign: '',
  campaignName: '',
  status: 'active',
  accumulatedUrlParams: {},
  customTokens: {}
};

class FunnelGroupDeleteForm extends ReactiveValidateComponent<
  FunnelGroupDeleteFormProps,
  State
> {
  initialButtonsState: ButtonState = {
    isDeleteAllFunnelsOn: false,
    isMoveSelectedFunnelsOn: true
  };

  state: State = {
    model: defaultModelState,
    buttons: this.initialButtonsState,
    moveFunnelsSelected: [],
    selectDestinationFunnelGroup: '',
    deletedItems: 0,
    funnels: [],
    validationErrors: {},
    isTouchedForm: false,
    isHolding: false
  };
  private submitPromise: Partial<CancellablePromise> = {};

  static defaultProps: Partial<FunnelGroupDeleteFormProps> = {
    funnels: []
  };

  async componentDidMount() {
    await this.props.fetchFunnelGroupsInfo('active');

    const contextModalData = this.props.contextModal
      .data as DrilldownReportRowAttribute;
    if (!!this.props.funnels) {
      this.setState((state: State) => ({
        ...state,
        funnels: getAllFunnelsOfFunnelGroup(
          this.props.funnels,
          contextModalData.id
        )
      }));
    }

    if (!!this.props.contextModal.data) {
      this.setState((state: State) => ({
        ...state,
        model: {
          ...state.model,
          idCampaign: contextModalData.id
        }
      }));
    }
  }

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  handleSelectInputChange = async (key: keyof State, value: string) => {
    await this.handleState(key, value);
    this.countDeletedItems();
  };

  handleSwitchChange = async (value: boolean, name: string) => {
    await this.setState((state: State) => ({
      ...state,
      buttons: {
        ...state.buttons,
        [name]: value
      }
    }));

    this.countDeletedItems();
  };

  isAllowedToDeleteAll = () => this.state.buttons.isDeleteAllFunnelsOn;

  isAllowedToDeleteAndMoveFunnels = () =>
    this.state.buttons.isMoveSelectedFunnelsOn &&
    !!this.state.selectDestinationFunnelGroup &&
    this.state.moveFunnelsSelected.length > 0;

  handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.handleState('isHolding', true);
    this.submitPromise = makeCancellablePromise(timeoutPromise(1000));

    await this.submitPromise.promise;
    await this.handleState('isHolding', false);
    this.props.startLoading('ok');
    if (this.isAllowedToDeleteAll() && this.state.funnels.length) {
      try {
        await this.props.handleDeleteFunnel({
          entries: this.state.funnels.map((item: Funnel) => item.idFunnel)
        });
        this.props.showMessage(
          Messages.success('Funnel has been successfully deleted')
        );
      } catch (e) {
        this.props.showMessage(Messages.failed('Funnel cannot be deleted'));
        this.props.stopLoading('ok');
        return false;
      }
    } else if (this.isAllowedToDeleteAndMoveFunnels()) {
      try {
        const funnelsIds = this.state.funnels
          .filter((item: Funnel) =>
            this.state.moveFunnelsSelected.includes(item.idFunnel)
          )
          .map(item => item.idFunnel);

        const funnelGroup = Object.values(this.props.funnelGroups).find(
          item => item.idCampaign === this.state.selectDestinationFunnelGroup
        );

        await this.props.handleMoveFunnels(
          this.state.selectDestinationFunnelGroup,
          { entries: funnelsIds },
          defined(funnelGroup) ? funnelGroup.campaignName : ''
        );

        this.props.showMessage(
          Messages.success(`Funnel has been successfully moved.`)
        );
      } catch (e) {
        this.props.showMessage(Messages.failed(`Funnel cannot be moved.`));
        this.props.stopLoading('ok');
        return false;
      }
    }

    try {
      await this.props.handleSubmit({
        entries: [this.props.contextModal.entityId!]
      });
      this.props.actions.onOk();
      this.props.showMessage(
        Messages.success(`Funnel Group has been successfully deleted`)
      );
      this.props.stopLoading('ok');
    } catch (e) {
      this.props.showMessage(
        Messages.failed(`${this.state.model.campaignName} cannot be deleted`)
      );
      this.props.actions.onOk();
      this.props.stopLoading('ok');
    }
  };

  handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.actions.onClose();
  };

  handleMultipleSelectChange = async (key: keyof State, value: string) => {
    await this.handleState(key, value);
    this.countDeletedItems();
  };

  countDeletedItems = () => {
    const deletedItems =
      this.state.funnels.length -
      this.state.funnels.filter((item: Funnel) =>
        this.state.moveFunnelsSelected.includes(item.idFunnel)
      ).length;

    this.setState({ deletedItems: deletedItems });
  };

  handleMultipleDeSelectChange = (value: string) => {
    this.setState((state: State) => ({
      ...state,
      moveFunnelsSelected: this.state.moveFunnelsSelected.filter(
        (item: string) => item !== value
      )
    }));
  };

  onMouseLeaveOrUp = () => {
    if (!this.submitPromise?.cancel) return;
    this.handleState('isHolding', false);
    this.submitPromise.cancel();
    this.props.stopLoading('all');
  };

  render() {
    return (
      <ContentWrapper padding={'0'}>
        <form>
          <div style={{ padding: '22px 30px 30px 30px' }}>
            <p className="color-red font-weight-bold">
              Deleting a funnel group will delete all child funnels unless they
              are moved beforehand. Deleting funnels is not reversible, but it
              will not break existing links or delete existing tracking data.
            </p>
            <p>
              Once a funnel is deleted, it will not appear on the funnels page
              in any view. However, it will still exist in reports with the text
              “(deleted)” after its name.
            </p>
            <p>
              If you would like to delete underlying funnel data, use the
              <strong> Reset Stats</strong> function.
            </p>
            <p>
              Choose funnels below that you would like to first move to another
              group. After these are moved, the funnel group and remaining
              funnels will be deleted.
            </p>
            <div>
              <SwitchButton
                checked={this.state.buttons.isDeleteAllFunnelsOn}
                data-testid="delete-all-funnels"
                onSwitchClicked={() => {
                  this.handleSwitchChange(
                    !this.state.buttons.isDeleteAllFunnelsOn,
                    'isDeleteAllFunnelsOn'
                  );
                  this.handleSwitchChange(
                    !this.state.buttons.isMoveSelectedFunnelsOn,
                    'isMoveSelectedFunnelsOn'
                  );
                }}
                labelName="Delete all funnels"
                className="margin-bottom-5 flex"
              />
              <SwitchButton
                checked={this.state.buttons.isMoveSelectedFunnelsOn}
                data-testid="move-selected-funnels"
                onSwitchClicked={() => {
                  this.handleSwitchChange(
                    !this.state.buttons.isMoveSelectedFunnelsOn,
                    'isMoveSelectedFunnelsOn'
                  );
                  this.handleSwitchChange(
                    !this.state.buttons.isDeleteAllFunnelsOn,
                    'isDeleteAllFunnelsOn'
                  );
                }}
                labelName="Move selected funnels"
                className="margin-bottom-15 flex"
              />
              {this.state.buttons.isMoveSelectedFunnelsOn && (
                <div className="flex flex-col flex-gap-5 margin-bottom-15">
                  <div>
                    <Label
                      text="Funnels to move and not destroy:"
                      htmlFor="funnelsToMove"
                    />
                    <Select
                      mode="multiple"
                      id="funnelsToMove"
                      showArrow
                      value={this.state.moveFunnelsSelected}
                      onDeselect={this.handleMultipleDeSelectChange}
                      data-testid="move-funnels-select"
                      onChange={(value: string) => {
                        this.handleMultipleSelectChange(
                          'moveFunnelsSelected',
                          value
                        );
                      }}
                      placeholder="Select funnels you’d like to move instead"
                      options={this.state.funnels}
                      valueGetter={(option: Funnel) => option.idFunnel}
                      labelGetter={(option: Funnel) => option.funnelName}
                    />
                  </div>
                  <Select
                    showSearch
                    placeholder="Select destination funnel groups"
                    data-testid="destination-funnel-group-select"
                    value={this.state.selectDestinationFunnelGroup}
                    onChange={(value: string) => {
                      this.handleSelectInputChange(
                        'selectDestinationFunnelGroup',
                        value
                      );
                    }}
                    options={Object.values(this.props.funnelGroups).filter(
                      (item: FunnelGroup) =>
                        item.idCampaign !== this.state.model.idCampaign &&
                        item.status === 'active'
                    )}
                    valueGetter={(option: FunnelGroup) => option.idCampaign}
                    labelGetter={(option: FunnelGroup) => option.campaignName}
                  />
                  <strong data-testid="moved-funnels-count">
                    {this.state.moveFunnelsSelected.length} Funnels will be
                    moved
                  </strong>
                  <strong data-testid="deleted-funnels-count">
                    {this.state.deletedItems} Funnels will be deleted
                    irreversibly
                  </strong>
                </div>
              )}
            </div>

            <div className="flex flex-gap-5">
              <FFButton
                onMouseDown={this.handleSubmit}
                onMouseLeave={this.onMouseLeaveOrUp}
                onMouseUp={this.onMouseLeaveOrUp}
                loading={this.state.isHolding}
                disabled={this.props.loading.ok}
                loadingType={this.props.loading.ok ? 'default' : 'skeleton'}
                color="dangerColor"
              >
                Hold to Delete
              </FFButton>
              <FFButton
                onClick={this.handleCancel}
                disabled={this.props.loading.ok}
              >
                Cancel
              </FFButton>
            </div>
          </div>
        </form>
      </ContentWrapper>
    );
  }
}

export default withLoading(Messages.injectIn(FunnelGroupDeleteForm));

import { CustomError } from 'types';

export const customDuplicateError = (
  status: number,
  message: string
): CustomError => ({
  response: {
    status,
    data: {
      message
    }
  }
});

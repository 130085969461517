import { BuilderData } from 'components/Builder/types';
import { conditionalClass } from 'conditional-class';
import {
  BUILDER_SWAP_GROUP_MODAL,
  LANDER_MODAL,
  OFFER_MODAL
} from 'constants/modal';
import { Category } from 'model/category';
import { FunnelNode } from 'model/funnelNode';
import { Page } from 'model/page';
import { PageGroupEntry } from 'model/pageGroupEntry';
import React from 'react';
import { SidebarProps } from 'types/sidebars';
import { defined } from 'utils/define';
import EntitySlider from 'components/EntitySlider';
import './style.scss';

interface Props {
  pages: PageGroupEntry[];
  getName: (data: PageGroupEntry) => string;
  getStatus: (data: PageGroupEntry) => string;
  setPages: (pages: PageGroupEntry[]) => void;
  isActionMapped: boolean;
  type: FunnelNode.NodeTypeEnum;
  sidebarProps: SidebarProps;
  data: BuilderData;
  onSwapPage: (idPage: string, newIdPage: string) => void;
  setGroupName?: (page?: PageGroupEntry | Page) => void;
  changePageOverrides?: (
    field:
      | 'idPage'
      | 'accumulateUrlParams'
      | 'additionalTokens'
      | 'redirectOverride'
      | 'payoutOverride',
    value: boolean | String | any[],
    onChange?: boolean
  ) => void;
  categories: Category[];
  disableAll?: boolean;
}

interface State {
  dragEnter?: string;
  dragLeave?: string;
  inputMouseDown: { [key: number]: boolean };
}

const DEFAULT_MULTIPLIER = 100;

const getWeight = (item: PageGroupEntry) => {
  return defined(item.weight) ? item.weight * DEFAULT_MULTIPLIER : 0;
};

class PageGroupPageSlider extends React.Component<Props, State> {
  state: State = {
    dragEnter: undefined,
    dragLeave: undefined,
    inputMouseDown: {}
  };

  getDragEventIdx = (event: any) =>
    event.target.dataset.dragid
      ? event.target.dataset.dragid
      : event.target.parentNode.dataset.dragid;

  dragEnd = (event: any) => {
    this.setState({ dragEnter: undefined, dragLeave: undefined });
  };

  dragStart = (event: any) => {
    event.dataTransfer.setData('text', event.target.id);
  };

  dragEnter = (event: any) => {
    const dragId = this.getDragEventIdx(event);
    if (dragId && defined(this.props.pages[dragId])) {
      this.setState({ dragEnter: dragId, dragLeave: undefined });
    }
  };

  dragLeave = (event: any) => {
    const dragId = this.getDragEventIdx(event);
    if (dragId && defined(this.props.pages[dragId])) {
      this.setState({ dragLeave: dragId });
    }
  };

  drop = (event: any) => {
    const idx = this.getDragEventIdx(event);
    if (idx) {
      this.swap(event.dataTransfer.getData('text'), idx);
      event.dataTransfer.clearData();
    }
  };

  swap = async (firstIdx: number, secondIdx: number) => {
    if (!!firstIdx && !!secondIdx) {
      const pages = [...this.props.pages];
      [pages[firstIdx], pages[secondIdx]] = [pages[secondIdx], pages[firstIdx]];
      await this.props.setPages(pages);
      await this.setState({
        dragEnter: undefined,
        dragLeave: undefined
      });
    }
  };

  handleModifyPage = async (
    item: PageGroupEntry,
    sidebarProps: SidebarProps
  ) => {
    await sidebarProps.setContextSidebar(
      this.props.type === 'landerGroup' ? LANDER_MODAL : OFFER_MODAL,
      {
        entityId: item.idPage,
        onSubmit: (page: Page) => {
          if (this.props.setGroupName) {
            this.props.setGroupName(page);
          }
        }
      }
    );
    await sidebarProps.openSidebar(
      this.props.type === 'landerGroup' ? LANDER_MODAL : OFFER_MODAL
    );
  };

  handleSliderInputChange = async (idx: string, value: number) => {
    if (value < 0 || value > 100 || isNaN(Number(value))) {
      return false;
    }

    await this.props.setPages(
      this.props.pages.map(item =>
        item.idPage === idx
          ? { ...item, weight: Number(value) / DEFAULT_MULTIPLIER }
          : item
      )
    );
  };

  getPagesListByType = () =>
    this.props.type === 'landerGroup'
      ? this.props.data.landers
      : this.props.data.offers;

  handleSwapPage = (item: PageGroupEntry) => {
    const selectedIdPages = this.props.pages.map(item => item.idPage);

    this.props.sidebarProps.setContextSidebar(BUILDER_SWAP_GROUP_MODAL, {
      data: {
        pages: this.getPagesListByType()!.filter(
          page => !selectedIdPages.includes(page.idPage)
        ),
        categories: this.props.categories,
        type: this.props.type
      },
      onUpdate: (newIdPage: string) =>
        this.props.onSwapPage(item.idPage, newIdPage)
    });
    this.props.sidebarProps.openSidebar(BUILDER_SWAP_GROUP_MODAL);
  };

  sum = () => {
    return this.props.pages.reduce((sum, item) => sum + getWeight(item), 0);
  };

  delete = async (idPage: string) => {
    const pages = this.props.pages.filter(item => item.idPage !== idPage);
    await this.props.setPages(pages);
    if (this.props.changePageOverrides) {
      await this.props.changePageOverrides('idPage', pages[0].idPage);
    }

    if (this.props.setGroupName) {
      await this.props.setGroupName();
    }
  };

  onInputMouseDown = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.persist();
    this.setState({
      inputMouseDown: {
        //@ts-ignore
        [e.target.getAttribute('data-index') || 0]: true
      }
    });
  };

  render() {
    return (
      <div className="c-pageGroupPageSlider">
        {this.props.pages.map((item: PageGroupEntry, idx: number) => {
          const name = this.props.getName(item);
          const status = this.props.getStatus(item);

          return (
            <div
              data-testid={`groupNameSlider${idx}`}
              className={conditionalClass('c-pageGroupPageSlider--pageBox', {
                'c-pageGroupPageSlider--pageBox--dragActive':
                  this.props.pages.length > 1 &&
                  this.state.dragEnter === idx.toString() &&
                  this.state.dragEnter !== this.state.dragLeave,
                [`status-${status}`]: !!status
              })}
              draggable={
                this.props.pages.length > 1 && !this.state.inputMouseDown[idx]
              }
              key={`node${item.idPage}`}
              onDrop={this.drop}
              onDragStart={this.dragStart}
              onDragOver={event => event.preventDefault()}
              onDragEnter={this.dragEnter}
              onDragEnd={this.dragEnd}
              onDragLeave={this.dragLeave}
              id={idx.toString()}
              title={name}
            >
              <EntitySlider
                idPage={item.idPage}
                id={idx.toString()}
                isActionMapped={this.props.isActionMapped}
                counterText={`${idx + 1}`}
                name={name}
                onModify={() =>
                  this.handleModifyPage(item, this.props.sidebarProps)
                }
                onSwap={() => this.handleSwapPage(item)}
                onDelete={() => this.delete(item.idPage)}
                showDeleteIcon={this.props.pages.length > 1}
                sliderValue={getWeight(item)}
                onSliderChange={value =>
                  this.handleSliderInputChange(item.idPage, value)
                }
                onSliderInputChange={value =>
                  this.handleSliderInputChange(item.idPage, value)
                }
                onInputMouseDown={this.onInputMouseDown}
                weightValue={Number(
                  getWeight(item) > 0
                    ? (getWeight(item) / this.sum()) * DEFAULT_MULTIPLIER
                    : 0
                ).toFixed(2)}
                iconType={this.props.type}
                status={status}
                disabled={!!this.props.disableAll}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default PageGroupPageSlider;

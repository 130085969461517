import { createSelector } from 'reselect';
import { DrilldownReport } from '../../model/drilldownReport';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { getCategories, getCategory } from '../../utils/selectors';
import { OfferSourceStore } from '../../types/redux/store';
import { defined } from 'utils/define';
import { StatusEnum } from 'api/types';
import { AnyObject } from '../../types';
import { arrayToObject } from '../../utils/arrs';

export const getReporting = (state: OfferSourceStore) => state.reporting;
export const getShow = (state: OfferSourceStore) => state.settings.show;
export const isQuickStats = (_: OfferSourceStore, isQuickStats?: boolean) =>
  !!isQuickStats;

export const getOfferSourcesReporting: any = createSelector(
  [getReporting, getShow, isQuickStats],
  (
    reporting: DrilldownReport,
    show: StatusEnum,
    isQuickStatsView
  ): DrilldownReportRow[] => {
    const categories = getCategories(reporting);
    const allCategoriesByKeys = arrayToObject(
      reporting.entities?.categories || [],
      'idCategory'
    );
    let existedCategories: AnyObject = {};
    const isAll = show === 'not-deleted';
    const status = show;

    if (!defined(reporting.rows)) {
      return [];
    }
    return reporting.rows
      .filter(row =>
        row?.attributes?.[0]?.id !== '-1' &&
        isAll
          ? isQuickStatsView
            ? row
            : row.attributes[0].status !== 'deleted'
          : row.attributes[0].status === status
      )
      .map(row => {
        const entity = row.attributes[0];
        let category = getCategory(entity, categories, allCategoriesByKeys);

        existedCategories[category.id] = true;

        return {
          ...row,
          attributes: [category, ...row.attributes]
        };
      });
    /*.concat(
        emptyCategoryRows(
          allCategories,
          existedCategories,
          isAll,
          status,
          reporting
        )
      )*/
  }
);

import React from 'react';
import Messages from 'components/Messages';
import { Input } from 'components/Parts/Inputs';
import { ContentWrapper } from 'components/Parts/Content';
import { ModalButtonGroup } from 'components/Parts/Groups';
import { ReportingAddEditDeleteViewFormProps } from 'types/ModalForms/reportingAddView';
import './style.scss';
import { defined } from '../../../utils/define';
import { ReactiveValidateComponent, ReactiveValidateState } from '../../../utils/reactive/generic';
import validateForm, { required, unique } from 'utils/validation';
import { ValidationRule } from '../../../utils/validation/types';

interface State extends ReactiveValidateState {
  view: string;
  viewNames: string[];
}

class ReportingAddEditDeleteView extends ReactiveValidateComponent<ReportingAddEditDeleteViewFormProps, State> {
  input = React.createRef<HTMLInputElement>();
  getContextNameParam = () => (this.props.contextModal.data as any).name;

  state: State = {
    view: '',
    viewNames: [],
    validationErrors: {},
    isTouchedForm: false
  };

  public componentDidMount() {
    this.setValidateSubscribe();
    if (defined(this.input.current)) {
      this.input.current.focus();
    }
    if (this.props.isEdit) {
      this.handleStateChange('view', this.getContextNameParam());
    }
    if (!!(this.props.contextModal as any).viewNames) {
      this.handleStateChange('viewNames', (this.props.contextModal as any).viewNames);
    }
  }

  validationRules = (): ValidationRule[] => [
    {
      field: 'view',
      validations: [
        required,
        unique(
          this.props.isEdit ? this.state.viewNames.filter(item => item !== this.getContextNameParam()) : this.state.viewNames,
          'reporting view'
        )
      ]
    }
  ];

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {} });
      const [validationErrors] = validateForm(this.state, this.validationRules());
      this.handleStateChange('validationErrors', validationErrors);
    }
  };

  validateClassName = (field: string) => (!!this.state.validationErrors[field] ? 'has-error' : '');

  validateTooltip = (field: string) => {
    return !!this.state.validationErrors[field] ? this.state.validationErrors[field].join(', ') : '';
  };

  handleStateChange = (name: keyof State, value: any) => {
    const validate = () => this.validate$.next();

    this.setState(
      (state: State) => ({
        ...state,
        [name]: value
      }),
      validate
    );
  };

  handleDelete = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const name = this.getContextNameParam();
    const onDeleteView = (this.props.contextModal as any).onDeleteView;
    try {
      await this.props.handleDelete(name);
      onDeleteView();
      this.props.showMessage(Messages.success(`View ${name} has been deleted`));
      this.props.actions.onOk();
    } catch (error) {
      //
    }
  };

  handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await this.setTouchedForm();
    await this.validate();

    const message = this.props.isEdit ? 'View has been updated' : 'View has been added';
    const onEditView = (this.props.contextModal as any).onEditView;
    if (!Object.keys(this.state.validationErrors).length) {
      try {
        if (this.props.isEdit) {
          await this.props.handleEdit(
            {
              name: this.state.view,
              url: this.props.contextModal.data.url
            },
            this.getContextNameParam()
          );
          onEditView(this.state.view);
        } else {
          await this.props.handleCreate({
            name: this.state.view,
            url: this.props.contextModal.data.url
          });
          if (this.props.contextModal.onSubmit) {
            this.props.contextModal.onSubmit(this.state.view);
          }
        }
        this.props.showMessage(Messages.success(message));
        this.props.actions.onOk();
      } catch (error) {
        //
      }
    }
  };

  handleCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.actions.onClose();
  };

  render() {
    return (
      <ContentWrapper padding="15px 31px 29px 29px">
        <div className="form-reportingAddView">
          <div className="input-wrapper">
            <p className="text">View Name:</p>
            <div className={this.validateClassName('view')}>
              <Input
                forwardedRef={this.input}
                name="view"
                id="view"
                value={this.state.view}
                error={this.validateTooltip('view')}
                onChange={e => this.handleStateChange('view', e.target.value)}
              />
            </div>
          </div>
          <ModalButtonGroup
            showOk
            okText="Save"
            onOkClicked={this.handleSubmit}
            showDuplicate
            duplicateText="Cancel"
            additionalClass="redStyleButton-cancel"
            onDuplicateClicked={this.handleCancel}
            showCancel={this.getContextNameParam()}
            cancelText="Delete This View"
            onCancelClicked={this.handleDelete}
          />
        </div>
      </ContentWrapper>
    );
  }
}

export default Messages.injectIn(ReportingAddEditDeleteView);

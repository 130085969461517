import Icon from 'components/Icons';
import { VisibilityWrapper } from 'uikit';
import { Input, Slider } from 'components/Parts/Inputs';
import { conditionalClass } from 'conditional-class';
import { FunnelNode } from 'model/funnelNode';
import React from 'react';
import { defined } from 'utils/define';
import './style.scss';

interface Props {
  id: string;
  idPage: string;
  isActionMapped?: boolean;
  counterText: string;
  name: string;
  onModify?: () => void;
  onSwap?: () => void;
  onDelete(): void;
  showDeleteIcon?: boolean;
  sliderValue: number;
  onSliderChange: (value: number) => void;
  onSliderInputChange: (value: number) => void;
  onInputMouseDown?: (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
  weightValue: string | number;
  iconType: FunnelNode.NodeTypeEnum | 'flowPath';
  status?: string;
  disabled?: boolean;
}

class EntitySlider extends React.Component<Props, {}> {
  static defaultProps: Partial<Props> = {
    showDeleteIcon: true,
    disabled: false
  };

  getIconType = () =>
    this.props.iconType === 'landerGroup'
      ? 'lander'
      : this.props.iconType === 'offerGroup'
      ? 'offer'
      : this.props.iconType;

  render() {
    const name = this.props.status === 'deleted' ? `Deleted page (ID ${this.props.idPage})` : this.props.name
    return (
      <div
        className={conditionalClass('c-entitySlider__root', {
          'c-entitySlider__disabled': !!this.props.disabled
        })}
      >
        <div
          className="c-entitySlider__dragElement"
          data-dragid={this.props.id}
        >
          <Icon type="flux-threedot" className="c-entitySlider__dotIcon" />
        </div>
        <div className="flex flex-col padding-x-25 padding-bottom-20">
          <div className="c-entitySlider__header">
            <div
              className={conditionalClass('flex flex-gap-10', {
                entitySlider__isMapped: !!this.props.isActionMapped
              })}
            >
              <VisibilityWrapper visible={!!this.props.isActionMapped}>
                <div className="c-entitySlider__counter">
                  {this.props.counterText}
                </div>
              </VisibilityWrapper>
              <div
                className={conditionalClass('c-entitySlider__nameIcon', {
                  [`c-entitySlider__status--${this.props.status}`]: !!this.props
                    .status,
                  [`c-entitySlider__icon--${this.props.iconType}`]: true
                })}
              >
                <Icon type={`flux-${this.getIconType()}`} />
                <span data-testid="groupName" className="c-entitySlider__name">
                  {name}
                </span>
              </div>
              <VisibilityWrapper
                visible={
                  !this.props.isActionMapped && defined(this.props.onModify)
                }
              >
                <Icon type="flux-modify" onClick={this.props.onModify} />
              </VisibilityWrapper>
            </div>
            <VisibilityWrapper visible={!!this.props.isActionMapped}>
              <div className="c-entitySlider__modifySwapDelete">
                <VisibilityWrapper visible={defined(this.props.onModify)}>
                  <Icon type="flux-modify" onClick={this.props.onModify} />
                </VisibilityWrapper>
                <VisibilityWrapper visible={defined(this.props.onSwap)}>
                  <Icon type="flux-swap" onClick={this.props.onSwap} />
                </VisibilityWrapper>
                <VisibilityWrapper visible={!!this.props.showDeleteIcon}>
                  <Icon type="flux-delete" onClick={this.props.onDelete} />
                </VisibilityWrapper>
              </div>
            </VisibilityWrapper>
          </div>
          <VisibilityWrapper visible={!this.props.isActionMapped}>
            <div className="c-entitySlider__body">
              <Slider
                className="c-entitySlider__slider data-testid-sliderWeightSliderInput"
                value={this.props.sliderValue}
                step={10}
                onChange={this.props.onSliderChange}
              />
              <Input
                name=""
                className="c-entitySlider__weightInput"
                data-index={this.props.id}
                data-testid="sliderWeightValueInput"
                onMouseDown={this.props.onInputMouseDown}
                value={Number(this.props.sliderValue).toFixed(0)}
                onChange={e =>
                  this.props.onSliderInputChange(Number(e.target.value))
                }
              />
              <span
                className="c-entitySlider__weightValue"
                data-testid="sliderWeightText"
              >
                {this.props.weightValue}%
              </span>
              <VisibilityWrapper visible={defined(this.props.onSwap)}>
                <Icon type="flux-swap" onClick={this.props.onSwap} />
              </VisibilityWrapper>
              <VisibilityWrapper visible={!!this.props.showDeleteIcon}>
                <Icon type="flux-delete" onClick={this.props.onDelete} />
              </VisibilityWrapper>
            </div>
          </VisibilityWrapper>
        </div>
      </div>
    );
  }
}

export default EntitySlider;

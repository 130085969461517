import { apiAction } from './api';
import { LANDERS, VIEW } from '../types';
import { LANDERS as API, CATEGORIES } from 'api/axios';
import { shouldFetchReporting } from '../selectors';
import { setTableCols } from './table';
import { Dispatch } from 'redux';
import { Page } from '../../model/page';
import { Category } from '../../model/category';
import { DeleteList } from '../../model/deleteList';
import { StatusEnum } from 'api/types';
import { DrilldownReport } from '../../model/drilldownReport';
import { CompositeStore, LanderStore } from '../../types/redux/store';
import { makeNewRow, updatedRows } from 'utils/table';
import { DrilldownReportParams } from '../../model/drilldownReportParams';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { QuickStatsOverlayParams } from 'types';
import { defined } from 'utils/define';
import { archiveCategoryRelatedEntities } from '../../utils/redux';
import { reportCategoriesToCategories } from 'utils/arrs';
import { PageCategoryInfo } from 'model/pageCategoryInfo';
import { PageInfo } from 'model/pageInfo';

const setLandersCategoryInfo = (payload: PageCategoryInfo[]) => {
  return {
    type: LANDERS.SET_CATEGORY_INFO,
    payload
  };
};

export const setLandersInfo = (payload: PageInfo[]) => {
  return {
    type: LANDERS.SET_INFO,
    payload
  };
};

const setLandersList = (payload: Page[] = []) => {
  return {
    type: LANDERS.SET_LIST,
    payload
  };
};

const setLander = (payload: Page) => {
  return {
    type: LANDERS.ADD_SINGLE,
    payload
  };
};

const setExistedLander = (payload: Page) => {
  return {
    type: LANDERS.ADD_EXISTED_SINGLE,
    payload
  };
};

const setLanderRow = (data: DrilldownReportRow) => {
  return {
    type: LANDERS.ADD_SINGLE_ROW,
    payload: { data }
  };
};

const setQuickStatData = (data: QuickStatsOverlayParams) => {
  return {
    type: LANDERS.SET_QUICKSTAT_DATA,
    payload: data
  };
};

const setCategoryList = (payload: Category[] = []) => {
  return {
    type: LANDERS.SET_CATEGORIES,
    payload
  };
};

const createCategories = (payload: Category) => {
  return {
    type: LANDERS.CREATE_CATEGORY,
    payload
  };
};

const updateCategories = (payload: Category) => {
  return {
    type: LANDERS.UPDATE_CATEGORY,
    payload
  };
};

const deleteCategory = (payload: string[] = []) => {
  return {
    type: LANDERS.DELETE_CATEGORY,
    payload
  };
};

const archiveCategory = (data: string) => {
  return {
    type: LANDERS.ARCHIVE_CATEGORY,
    payload: { data }
  };
};

const landerArchive = (data: string = '') => {
  return {
    type: LANDERS.ARCHIVE,
    payload: { data }
  };
};

const landerDelete = (data: string[]) => {
  return {
    type: LANDERS.DELETE,
    payload: { data }
  };
};

const landerUpdate = (data: Page) => {
  return {
    type: LANDERS.UPDATE_SINGLE,
    payload: data
  };
};

const offerUpdateReporting = (data: DrilldownReportRow[]) => {
  return {
    type: LANDERS.UPDATE_SINGLE_REPORTING,
    payload: { data }
  };
};

const categoryEntitiesUpdate = (idCategory: string, categoryName: string) => {
  return {
    type: LANDERS.UPDATE_CATEGORY_ENTITIES,
    payload: {
      idCategory,
      categoryName
    }
  };
};

const setReportingsData = (data = {}, params: DrilldownReportParams) => {
  return {
    type: LANDERS.SET_REPORTING,
    payload: { data, params }
  };
};

const setShow = (payload: StatusEnum) => {
  return {
    type: LANDERS.SET_SHOW,
    payload
  };
};

export const fetchLanders = (status: StatusEnum = 'not-deleted') => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(status),
      onSuccess: (data: Page[]) => setLandersList(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.FETCH_LIST
    })
  );
};

export const fetchLandersCategoryInfo = (status: StatusEnum = 'all') => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_CATEGORY_INFO(status),
      onSuccess: (data: PageCategoryInfo[]) => {
        dispatch(setLandersCategoryInfo(data));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.FETCH_CATEGORY_INFO
    })
  );
};

export const fetchLandersInfo = (status: StatusEnum = 'all') => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_INFO(status),
      onSuccess: (data: PageInfo[]) => setLandersInfo(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.FETCH_INFO
    })
  );
};

export const fetchCategories = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: CATEGORIES.FETCH_LIST('lander'),
      onSuccess: (data: Category[]) => setCategoryList(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.FETCH_INIT
    })
  );
};

export const addLander = (data: Page) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.CREATE(data),
      onSuccess: () => {
        dispatch(setLander(data));
        return setLanderRow(makeNewRow(data, 'landers'));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.ADD_SINGLE
    })
  );
};

export const getLanderById = (idPage: string) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.GET(idPage),
      onSuccess: (page: Page) => setExistedLander(page),
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.ADD_SINGLE
    })
  );
};

export const getLandersByCategory = (idCategory: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FIND_BY_CATEGORY(idCategory),
      onSuccess: (pages: Page[]) => setLandersList(pages),
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.FETCH_LIST
    })
  );
};

export const updateLander = (data: Page) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const reportingRows = getState().landers.reporting.rows;
  const category = getState().landers.categories.find(
    item => defined(data.idCategory) && item.idCategory === data.idCategory
  );
  return dispatch(
    apiAction({
      requestConfig: API.UPDATE(data),
      onSuccess: () => {
        if (defined(category) && defined(data.idCategory)) {
          dispatch(
            categoryEntitiesUpdate(data.idCategory, category.categoryName)
          );
        }
        updatedRows({
          id: data.idPage,
          newName: data.pageName,
          newCategoryID: data.idCategory,
          rows: reportingRows
        }).then((res: any) => {
          dispatch(landerUpdate(data));
          dispatch(offerUpdateReporting(res));
        });
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.UPDATE_SINGLE
    })
  );
};

export const duplicateLander = (oldData: Page) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DUPLICATE(oldData),
      onSuccess: (data: Page) => {
        dispatch(setLander(data));
        return setLanderRow(makeNewRow(data, 'landers'));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.ADD_SINGLE
    })
  );
};

export const updateLanderCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.UPDATE(data),
    onSuccess: () => updateCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: LANDERS.ADD_SINGLE_CATEGORY
  });
};

export const fetchReportings = (params: DrilldownReportParams) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  if (shouldFetchReporting(getState().landers)) {
    return dispatch(
      apiAction({
        requestConfig: API.FETCH_REPORTING(params),
        onSuccess: (data: DrilldownReport) => {
          dispatch(setReportingsData(data, params));
          dispatch(
            setCategoryList(
              reportCategoriesToCategories(data.entities?.categories || [])
            )
          );
        },
        onFailure: (e: Error) => {
          throw e;
        },
        label: LANDERS.FETCH_REPORTING
      })
    );
  }
};

export const dispatchUpdateLanderCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(updateLanderCategories(data));
};

export const createLanderCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.CREATE(data),
    onSuccess: () => createCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: LANDERS.ADD_SINGLE_CATEGORY
  });
};

export const dispatchCreateLanderCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(createLanderCategories(data));
};

export const deleteLanderCategory = (data: DeleteList) => {
  return apiAction({
    requestConfig: CATEGORIES.DELETE(data),
    onSuccess: () => deleteCategory(data.entries),
    onFailure: (e: Error) => {
      throw e;
    },
    label: LANDERS.DELETE_SINGLE_CATEGORY
  });
};

export const dispatchDeleteLanderCategory = (data: DeleteList) => (
  dispatch: Dispatch
) => {
  return dispatch(deleteLanderCategory(data));
};

export const archiveLanderCategory = (
  data: Category,
  landersStore?: LanderStore,
  dispatch?: Dispatch
) => {
  return apiAction({
    requestConfig: CATEGORIES.ARCHIVE(data),
    onSuccess: () => {
      archiveCategoryRelatedEntities(
        dispatch!,
        landersStore!,
        data,
        archiveLander
      );
      return archiveCategory(data.idCategory);
    },
    onFailure: (e: Error) => {
      throw e;
    },
    label: LANDERS.ARCHIVE_SINGLE_CATEGORY
  });
};

export const deleteLander = (data: DeleteList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => landerDelete(data.entries),
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.DELETE
    })
  );
};

export const archiveLander = (data: Page) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.ARCHIVE(data),
      onSuccess: () => landerArchive(data.idPage),
      onFailure: (e: Error) => {
        throw e;
      },
      label: LANDERS.ARCHIVE
    })
  );
};

export const setLanderTableCols = (cols: []) => (dispatch: Dispatch) => {
  return dispatch(setTableCols(cols, VIEW.LANDERS));
};

export const setLanderShow = (show: StatusEnum) => (dispatch: Dispatch) => {
  return dispatch(setShow(show));
};

export const setLanderQuickStatData = (data: QuickStatsOverlayParams) => (
  dispatch: Dispatch
) => {
  return dispatch(setQuickStatData(data));
};

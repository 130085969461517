import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import {
  addOfferSource,
  updateOfferSource,
  getOfferSourceById,
  duplicateOfferSource,
  fetchCategories
} from 'redux/actions/offersources';
import { fetchSystemSettings } from 'redux/actions/systemSettings';
import {
  OFFER_SOURCE_CATEGORY_MODAL,
  OFFER_SOURCE_DUPLICATE_MODAL,
  OFFER_SOURCE_MODAL
} from 'constants/modal';
import { connect } from 'react-redux';
import { OfferSourcesModalSelector } from 'redux/selectors';
import { getModalTitle } from 'utils/modals';
import { FormContextSidebar } from 'types/modal';
import OfferSourceForm from 'components/Forms/OfferSources';
import {
  OfferSourceFormProps,
  OfferSourceSidebarProps,
  OfferSourcesTabKey
} from 'types/ModalForms/offerSource';
import { SidebarProps } from 'types/sidebars';
import { SidebarsConsumer } from '../context';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import {
  OFFERSOURCE_FORM_TAB,
  OFFERSOURCE_FORM_CONFIGURE_DATA_PASSING_TAB,
  OFFERSOURCE_FORM_CONFIGURE_TRACKING_TOKENS_TAB,
  OFFERSOURCE_FORM_HELP_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import { fetchDomains } from 'redux/actions/domains';
import { FFIcon } from 'uikit';

class OfferSourceFormWrapper extends React.Component<
  Omit<OfferSourceFormProps, 'showTabs' | 'setButtonGroupProps'> &
    OfferSourceSidebarProps &
    LoadingProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen!}
        hasTab={false}
        onClose={() => {
          this.props.closeSelf();
        }}
        defaultOpenSideBarKey="offersourceForm"
        defaultActiveTab={OFFERSOURCE_FORM_TAB}
        dataPortalKey="offersource"
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'offersourceForm',
            tabs: {
              [OFFERSOURCE_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [OFFERSOURCE_FORM_CONFIGURE_DATA_PASSING_TAB]: {
                title: 'Configure Data Passing',
                icon: <FFIcon name="dataPassing" />,
                highlightable: true
              },
              [OFFERSOURCE_FORM_CONFIGURE_TRACKING_TOKENS_TAB]: {
                title: 'Conversion Tracking',
                icon: <FFIcon name="conversionTracking" />,
                highlightable: true
              },
              [OFFERSOURCE_FORM_HELP_TAB]: {
                title: 'Help',
                icon: <FFIcon name="question" />
              }
            },
            title: `${getModalTitle(this.props.contextModal)} Offer Source`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setLoading,
              sidebarLoading,
              setForCopyIdsProps,
              tabTitle,
              showTabs
            }) => {
              return (
                <>
                  <OfferSourceForm
                    {...this.props}
                    activeTab={activeTab as OfferSourcesTabKey}
                    setButtonGroupProps={setButtonGroupProps}
                    setSidebarLoading={setLoading}
                    setForCopyIdsProps={setForCopyIdsProps}
                    sidebarLoading={sidebarLoading}
                    tabTitle={tabTitle}
                    showTabs={showTabs}
                  />
                </>
              );
            }
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(OfferSourceFormWrapper);

const ConnectedFormElement = ({
  offerSources,
  categories,
  defaultCustomDomain,
  addOfferSource,
  getOfferSourceById,
  updateOfferSource,
  duplicateOfferSource,
  fetchSystemSettings,
  fetchCategories,
  customDomains,
  fetchDomains,
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        openSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let sidebarName = OFFER_SOURCE_MODAL;
        let contextSidebar = getContextSidebar(
          OFFER_SOURCE_MODAL
        ) as FormContextSidebar;

        if (isOpenSidebar(OFFER_SOURCE_DUPLICATE_MODAL)) {
          sidebarName = OFFER_SOURCE_DUPLICATE_MODAL;
          contextSidebar = getContextSidebar(
            OFFER_SOURCE_DUPLICATE_MODAL
          ) as FormContextSidebar;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            offerSources
          );
        }
        const isOpen =
          isOpenSidebar(OFFER_SOURCE_MODAL) ||
          isOpenSidebar(OFFER_SOURCE_DUPLICATE_MODAL);

        return (
          <FormElement
            fetchDomains={fetchDomains}
            offerSources={offerSources}
            getOfferSourceById={getOfferSourceById}
            contextModal={contextSidebar}
            defaultCustomDomain={defaultCustomDomain}
            fetchSystemSettings={fetchSystemSettings}
            fetchCategories={fetchCategories}
            customDomains={customDomains}
            categories={categories}
            handleCreate={addOfferSource}
            handleUpdate={updateOfferSource}
            handleDuplicate={duplicateOfferSource}
            openAddNewCategory={() => openSidebar(OFFER_SOURCE_CATEGORY_MODAL)}
            isOpen={isOpen}
            closeSelf={() => closeSidebar(sidebarName)}
            zIndex={contextSidebar.zIndex!}
            offsetRight={getOffsetRight(sidebarName)}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(OfferSourcesModalSelector, {
  addOfferSource,
  updateOfferSource,
  duplicateOfferSource,
  getOfferSourceById,
  fetchSystemSettings,
  fetchCategories,
  fetchDomains,
})(ConnectedFormElement);

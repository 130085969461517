import { overview as defaultState } from './defaultStates';
import { OVERVIEW, API } from '../types';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import { OverviewStore } from '../../types/redux/store';
import { ReportingParamsAction } from '../../types/redux/action';
import { getFieldName } from '../../utils/selectors';
import { DrilldownReport } from 'model/drilldownReport';

const reporting = (
  state = defaultState.reporting,
  action: ReportingParamsAction
) => {
  switch (action.type) {
    case OVERVIEW.SET_REPORTING:
      if (defined(action.payload)) {
        return {
          ...(action.payload.data as DrilldownReport)
        };
      }
      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loadings, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        [getFieldName(action, OVERVIEW.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: true
        }
      };
    case API.ENDED:
      return {
        ...state,
        [getFieldName(action, OVERVIEW.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: false,
          fetchedAt: Date.now()
        }
      };
    default:
      return state;
  }
};

export default (state = defaultState, action: any): OverviewStore => {
  return {
    reporting: reporting(state.reporting, action),
    loadings: loading(state.loadings, action)
  };
};

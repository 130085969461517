import { Radio, RadioChangeEvent, RadioProps } from 'antd';
import React from 'react';
import { FFNewIcon } from 'uikit';
import { NewIconName } from 'uikit/types/icon';
import className from 'utils/style/className';
import './style.scss';

const { blockClassName } = className('c-ffTab');

interface TabOption {
  label: string;
  value: number | string;
  icon?: NewIconName;
}

export default ({
  value,
  onChange,
  options,
  size = 'middle'
}: {
  value: number | string;
  onChange: (e: RadioChangeEvent) => void;
  options: TabOption[];
  size?: 'small' | 'middle' | 'large'
}) => (
  <Radio.Group size={size} value={value} onChange={onChange} className={blockClassName}>
    {options.map((opt, i) => (
      <Radio.Button key={i} value={opt.value}>
        {opt.icon && <FFNewIcon name={opt.icon} />}
        <span>{opt.label}</span>
      </Radio.Button>
    ))}
  </Radio.Group>
);

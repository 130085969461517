import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { createSelector } from 'reselect';
import { DrilldownReport } from '../../model/drilldownReport';
import { CampaignAnalysisStore } from '../../types/redux/store';
import { defined } from 'utils/define';
import { StatusEnum } from 'api/types';

export const getReporting = (state: CampaignAnalysisStore) => state.reporting;
export const getShow = (state: CampaignAnalysisStore) => state.settings.show;

export const getCampaignAnalysisReporting: any = createSelector(
  [getReporting, getShow],
  (reporting: DrilldownReport, show: StatusEnum): DrilldownReportRow[] => {
    if (!defined(reporting.rows)) {
      return [];
    }
    return reporting.rows.filter((row) => row.attributes[0].status !== 'deleted');
  }
);

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="heart-rate-monitor--desktop-health-medical-heart-rate-monitor">
      <path
        id="Vector"
        fill="#ffffff"
        d="M9.95357142857143 26.874642857142856 11.944285714285714 21.89785714285714h3.9814285714285713l1.9907142857142857 4.976785714285715H9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#d7e0ff"
        d="M1.9907142857142857 3.9814285714285713h23.888571428571428c0.5972142857142857 0 0.9953571428571428 0.39814285714285713 0.9953571428571428 0.9953571428571428v15.925714285714285c0 0.5972142857142857 -0.39814285714285713 0.9953571428571428 -0.9953571428571428 0.9953571428571428H1.9907142857142857c-0.5972142857142857 0 -0.9953571428571428 -0.39814285714285713 -0.9953571428571428 -0.9953571428571428v-15.925714285714285c0 -0.5972142857142857 0.39814285714285713 -0.9953571428571428 0.9953571428571428 -0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.9907142857142857 3.9814285714285713h23.888571428571428c0.5972142857142857 0 0.9953571428571428 0.39814285714285713 0.9953571428571428 0.9953571428571428v15.925714285714285c0 0.5972142857142857 -0.39814285714285713 0.9953571428571428 -0.9953571428571428 0.9953571428571428H1.9907142857142857c-0.5972142857142857 0 -0.9953571428571428 -0.39814285714285713 -0.9953571428571428 -0.9953571428571428v-15.925714285714285c0 -0.5972142857142857 0.39814285714285713 -0.9953571428571428 0.9953571428571428 -0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11.944285714285714 21.89785714285714 -1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m15.925714285714285 21.89785714285714 1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.95357142857143 26.874642857142856 11.944285714285714 21.89785714285714h3.9814285714285713l1.9907142857142857 4.976785714285715H9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 26.874642857142856h11.944285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2105"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.075396714285714 13.126272321428571h3.04828125L12.17660207142857 9.20705357142857 15.287093142857142 16.423392857142858l2.6750223214285715 -3.2971205357142854h2.9859718928571426"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import { Category } from 'model/models';
import {
  UNCATEGORIZED,
  LANDER_CATEGORY_MODAL,
  LANDER_CATEGORY_TYPE,
  OFFER_CATEGORY_MODAL,
  OFFER_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_MODAL,
  OFFER_SOURCE_CATEGORY_TYPE,
  CONDITION_CATEGORY_MODAL,
  CONDITION_CATEGORY_TYPE,
  TRAFFIC_SOURCE_CATEGORY_MODAL,
  TRAFFIC_SOURCE_CATEGORY_TYPE,
  OFFER_CATEGORY_DUPLICATE_MODAL,
  OFFER_SOURCE_CATEGORY_DUPLICATE_MODAL,
  TRAFFIC_SOURCE_CATEGORY_DUPLICATE_MODAL,
  CONDITION_CATEGORY_DUPLICATE_MODAL,
  LANDER_CATEGORY_DUPLICATE_MODAL
} from 'constants/modal';
import { connect } from 'react-redux';
import { CategoryModalSelector } from 'redux/selectors';
import {
  updateCategories,
  insertCategories,
  deleteCategory
} from 'redux/actions/categories';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import {
  CategoryFormProps,
  CategoryReduxProps,
  CategorySidebarProps
} from 'types/ModalForms/category';
import AddNewCategory from 'components/Forms/Category';
import { LoadingProps } from 'types/loading';
import { withLoading } from 'components/Loading';
import { FormContextModal } from 'types/modal';
import { defined } from 'utils/define';
import { getCopyName } from 'utils/copy';
import { fetchCategories as fetchConditionsCategories } from 'redux/actions/conditions';
import { fetchCategories as fetchOffersCategories } from 'redux/actions/offers';
import { fetchCategories as fetchOfferSourcesCategories } from 'redux/actions/offersources';
import { fetchCategories as fetchTrafficSourcesCategories } from 'redux/actions/trafficsources';
import { fetchCategories as fetchLandersCategories } from 'redux/actions/landers';

const getCopyCategories = (
  contextModal: FormContextModal,
  categories: Category[],
  type: Category.CategoryTypeEnum
) => {
  if (defined(categories) && defined(contextModal?.data?.value)) {
    categories = [
      ...categories,
      {
        categoryName: getCopyName(
          contextModal.data.value,
          categories.reduce(
            (acc: string[], item) => [...acc, item.categoryName],
            []
          )
        ),
        idCategory: '',
        categoryType: type,
        status: 'active'
      }
    ];
  }

  return categories;
};

class CategoryFormWrapper extends React.Component<
  CategoryFormProps & CategorySidebarProps & LoadingProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen!}
        onClose={this.props.closeSelf!}
        dataPortalKey="category"
        defaultOpenSideBarKey="categoryForm"
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'categoryForm',
            title: `Categories`,
            render: ({ setButtonGroupProps }) => {
              return (
                <AddNewCategory
                  {...this.props}
                  setButtonGroupProps={setButtonGroupProps}
                />
              );
            }
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(CategoryFormWrapper);

export default connect(CategoryModalSelector, {
  updateCategories,
  insertCategories,
  deleteCategory,
  fetchConditionsCategories,
  fetchOffersCategories,
  fetchOfferSourcesCategories,
  fetchTrafficSourcesCategories,
  fetchLandersCategories
})(
  ({
    landerCategories,
    offerCategories,
    offerSourceCategories,
    trafficSourceCategories,
    conditionCategories,
    updateCategories,
    insertCategories,
    deleteCategory,
    fetchConditionsCategories,
    fetchOffersCategories,
    fetchOfferSourcesCategories,
    fetchTrafficSourcesCategories,
    fetchLandersCategories
  }: CategoryReduxProps) => (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        closeSidebar,
        openSidebar,
        setContextSidebar,
        getContextSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let type: Category.CategoryTypeEnum = LANDER_CATEGORY_TYPE;
        let categories = landerCategories;
        let sidebarName = LANDER_CATEGORY_MODAL;
        sidebarName = isOpenSidebar(LANDER_CATEGORY_DUPLICATE_MODAL)
          ? LANDER_CATEGORY_DUPLICATE_MODAL
          : LANDER_CATEGORY_MODAL;

        if (
          isOpenSidebar(OFFER_CATEGORY_MODAL) ||
          isOpenSidebar(OFFER_CATEGORY_DUPLICATE_MODAL)
        ) {
          type = OFFER_CATEGORY_TYPE;
          sidebarName = isOpenSidebar(OFFER_CATEGORY_DUPLICATE_MODAL)
            ? OFFER_CATEGORY_DUPLICATE_MODAL
            : OFFER_CATEGORY_MODAL;
          categories = offerCategories;
        }

        if (
          isOpenSidebar(OFFER_SOURCE_CATEGORY_MODAL) ||
          isOpenSidebar(OFFER_SOURCE_CATEGORY_DUPLICATE_MODAL)
        ) {
          type = OFFER_SOURCE_CATEGORY_TYPE;
          sidebarName = isOpenSidebar(OFFER_SOURCE_CATEGORY_DUPLICATE_MODAL)
            ? OFFER_SOURCE_CATEGORY_DUPLICATE_MODAL
            : OFFER_SOURCE_CATEGORY_MODAL;
          categories = offerSourceCategories;
        }

        if (
          isOpenSidebar(TRAFFIC_SOURCE_CATEGORY_MODAL) ||
          isOpenSidebar(TRAFFIC_SOURCE_CATEGORY_DUPLICATE_MODAL)
        ) {
          type = TRAFFIC_SOURCE_CATEGORY_TYPE;
          sidebarName = isOpenSidebar(TRAFFIC_SOURCE_CATEGORY_DUPLICATE_MODAL)
            ? TRAFFIC_SOURCE_CATEGORY_DUPLICATE_MODAL
            : TRAFFIC_SOURCE_CATEGORY_MODAL;
          categories = trafficSourceCategories;
        }

        if (
          isOpenSidebar(CONDITION_CATEGORY_MODAL) ||
          isOpenSidebar(CONDITION_CATEGORY_DUPLICATE_MODAL)
        ) {
          type = CONDITION_CATEGORY_TYPE;
          sidebarName = isOpenSidebar(CONDITION_CATEGORY_DUPLICATE_MODAL)
            ? CONDITION_CATEGORY_DUPLICATE_MODAL
            : CONDITION_CATEGORY_MODAL;
          categories = conditionCategories;
        }

        const isDuplicateOpen =
          isOpenSidebar(LANDER_CATEGORY_DUPLICATE_MODAL) ||
          isOpenSidebar(OFFER_CATEGORY_DUPLICATE_MODAL) ||
          isOpenSidebar(OFFER_SOURCE_CATEGORY_DUPLICATE_MODAL) ||
          isOpenSidebar(TRAFFIC_SOURCE_CATEGORY_DUPLICATE_MODAL) ||
          isOpenSidebar(CONDITION_CATEGORY_DUPLICATE_MODAL);

        const isOpen =
          isOpenSidebar(LANDER_CATEGORY_MODAL) ||
          isOpenSidebar(OFFER_CATEGORY_MODAL) ||
          isOpenSidebar(OFFER_SOURCE_CATEGORY_MODAL) ||
          isOpenSidebar(TRAFFIC_SOURCE_CATEGORY_MODAL) ||
          isOpenSidebar(CONDITION_CATEGORY_MODAL) ||
          isDuplicateOpen;
        const contextSidebar = getContextSidebar(sidebarName);

        return (
          <FormElement
            fetchConditionsCategories={fetchConditionsCategories}
            fetchOffersCategories={fetchOffersCategories}
            fetchOfferSourcesCategories={fetchOfferSourcesCategories}
            fetchTrafficSourcesCategories={fetchTrafficSourcesCategories}
            fetchLandersCategories={fetchLandersCategories}
            zIndex={contextSidebar.zIndex!}
            isOpen={isOpen}
            onUpdateCategory={updateCategories(type)}
            onCreateCategory={insertCategories(type)}
            closeSelf={() => closeSidebar(sidebarName)}
            categories={
              isDuplicateOpen
                ? getCopyCategories(contextSidebar, categories, type)
                : categories
            }
            filterOut={UNCATEGORIZED}
            type={type!}
            onDeleteCategory={deleteCategory(type)}
            openSidebar={openSidebar}
            closeSidebar={closeSidebar}
            setContextSidebar={setContextSidebar}
            offsetRight={getOffsetRight(sidebarName)}
          />
        );
      }}
    </SidebarsConsumer>
  )
);

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import {
  archiveOfferSource,
  getOfferSourceById
} from 'redux/actions/offersources';
import { ModalsConsumer } from 'components/Modals/context';
import { OFFER_SOURCE_ARCHIVE_MODAL } from 'constants/modal';
import ConfirmForm from '../../Forms/Confirm';
import { ModalProps } from '../types';
import { OfferSourceArchiveModalProps } from 'types/ModalForms/offerSourceArchive';
import { getNameModal } from '../../../utils/modals';
import { FormContextModal } from '../../../types/modal';
import { OfferSourcesModalSelector } from 'redux/selectors';
import { AnyObject } from 'types';

const OfferSourcesArchive = () => {
  return (
    <div className="margin-bottom-15 color-465565">
      <p>
        <strong>
          Archiving an offer source will hide it from the active view.
        </strong>
      </p>
      <p>
        This action will not break funnels, tracking or result in data being
        unavailable in reporting. However, they will no longer appear in the
        offer source selection when configuring offers.
      </p>
      <p>
        Use this as a soft-delete to remove old offer sources. You may also
        choose to archive offers belonging to this offer source at the same
        time.
      </p>
    </div>
  );
};

const OfferSourcesArchiveModal = ({
  isOpen,
  onSubmit,
  closeModal,
  contextModal,
  offerSources,
  getOfferSourceById
}: OfferSourceArchiveModalProps) => {
  const closeSelf = () => {
    return closeModal(OFFER_SOURCE_ARCHIVE_MODAL);
  };

  return (
    <FluxModal
      zIndex={contextModal.zIndex}
      width={547}
      destroyOnClose
      title={`Archive Offer Source ${getNameModal(contextModal)}`}
      isOpen={isOpen}
      classNames={['offer-source-archive']}
      actions={{
        onOk: closeSelf,
        onClose: closeSelf
      }}
      render={() => (
        <ConfirmForm
          prepareData={(context: FormContextModal) => ({
            ...offerSources[context?.entityId!],
            status: 'archived'
          })}
          successMessage={(context: FormContextModal) =>
            `${context.data.value} has been archived`
          }
          handleSubmit={onSubmit}
          actions={{
            onOk: closeSelf,
            onClose: closeSelf
          }}
          contextModal={contextModal}
          okText="Confirm"
          cancelText="Cancel"
          text={<OfferSourcesArchive />}
          fetchEntity={() => getOfferSourceById(contextModal?.entityId!)}
        />
      )}
    />
  );
};

const ConnectedOfferSourcesArchiveModal = ({
  archiveOfferSource,
  offerSources,
  getOfferSourceById
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <OfferSourcesArchiveModal
            isOpen={isOpenModal(OFFER_SOURCE_ARCHIVE_MODAL)}
            onSubmit={archiveOfferSource}
            closeModal={() => closeModal(OFFER_SOURCE_ARCHIVE_MODAL)}
            contextModal={
              getContextModal(OFFER_SOURCE_ARCHIVE_MODAL) as FormContextModal
            }
            offerSources={offerSources}
            getOfferSourceById={getOfferSourceById}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(OfferSourcesModalSelector, {
  archiveOfferSource,
  getOfferSourceById
})(ConnectedOfferSourcesArchiveModal);

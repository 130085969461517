import { TABLE } from '../types';
import { Dispatch } from 'redux';
import { SetTableParams } from 'types/actions';

export const setTableCols = (data = [], view = '', type = '') => {
  return {
    type: TABLE.SET_COLS,
    payload: { data, view, type }
  };
};

export const setEditMode = (data: boolean, view: string) => {
  return {
    type: TABLE.SET_EDIT_MODE,
    payload: { data, view }
  };
};

export const setAdditionalParams = (name: string, data: any, view: string) => {
  return {
    type: TABLE.SET_ADDITIONAL_PARAMS,
    payload: { data, view, name }
  };
};

export const setTableParams: SetTableParams = (name, data, view) => {
  return {
    type: TABLE.SET_TABLE_PARAMS,
    payload: { data, view, name }
  };
};

interface Props {
  cols: [];
  view: string;
}
export const setCols = ({ cols, view }: Props) => (dispatch: Dispatch) => {
  dispatch(setTableCols(cols, view));
  return Promise.resolve();
};

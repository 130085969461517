import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import './style.scss';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import {
  FUNNELGROUP_FUNNEL_FLOW_MODAL,
  FUNNELGROUP_MODAL,
  FUNNEL_MODAL,
  SIMPLE_FLOWS_MODAL
} from 'constants/modal';
import { FFButton, FFIcon, FFNewIcon } from 'uikit';
import { FormSectionBox } from 'components/Parts/Content';

export default class Partners extends React.Component<{}, {}> {
  render() {
    return (
      <SidebarsConsumer>
        {({ isOpenSidebar, closeSidebar, openSidebar }: SidebarProps) => (
          <DynamicSideBar
            isOpen={isOpenSidebar(FUNNELGROUP_FUNNEL_FLOW_MODAL)}
            onClose={() => closeSidebar(FUNNELGROUP_FUNNEL_FLOW_MODAL)}
            defaultOpenSideBarKey="funnelFlowCampaign"
            dataPortalKey="funnelFlowCampaign"
            zIndex={5}
            showCloseIcon={true}
            sideBars={[
              {
                key: 'funnelFlowCampaign',
                title: 'Create a new item',
                render: () => (
                  <div className="sb-funnelFlowCampaign">
									<FormSectionBox
										title="Create Visual Funnel"
										titlePostfix={
											<FFNewIcon name="navigation/funnels" size="navigation-md" />
										}
									>
										<p className="sb-funnelFlowCampaign__text">
											Funnels are the key part of FunnelFlux Pro. They let you
											visually design a flow you want visitors to experience.
											Note that many different traffic sources and campaigns
											can point to a single funnel.
										</p>
										<FFButton
											variant="outlined"
											size="lg"
											onClick={() => {
												closeSidebar(FUNNELGROUP_FUNNEL_FLOW_MODAL);
												requestAnimationFrame(() =>
													openSidebar(FUNNEL_MODAL)
												);
											}}
											data-testid="createFunnelBtn"
											className="sb-funnelFlowCampaign__button"
										>
											Create Visual Funnel
										</FFButton>
									</FormSectionBox>
                    <FormSectionBox
                      title="Create a Funnel Group"
                      titlePostfix={
                        <FFNewIcon name="general/duotone/label-folder-tag" size="navigation-md" />
                      }
                    >
                      <p className="sb-funnelFlowCampaign__text">
                        Funnel Groups are folders/categories that help you group similar
                        marketing efforts together. You can move funnels between
                        groups at any time, so use these to keep organised.
                      </p>
                      <FFButton
                        variant="outlined"
                        size="lg"
                        onClick={() => {
                          closeSidebar(FUNNELGROUP_FUNNEL_FLOW_MODAL);
                          requestAnimationFrame(() =>
                            openSidebar(FUNNELGROUP_MODAL)
                          );
                        }}
                        data-testid="createCampaignBtn"
                        className="sb-funnelFlowCampaign__button"
                      >
                        Create a Group
                      </FFButton>
                    </FormSectionBox>

                    <FormSectionBox
                      title="Create Simple Flow"
                      titlePostfix={
                        <FFNewIcon name="general/duotone/simple-flow" size="navigation-md" />
                      }
                    >
                      <p className="sb-funnelFlowCampaign__text">
                        Simple Flows are an easy way to get started. These will
                        let you create multiple page sequences as paths, then
                        control where they are routed to. They are the easiest
                        way to get started with simple marketing flows.
                      </p>
                      <FFButton
                        variant="outlined"
                        size="lg"
                        onClick={() => {
                          closeSidebar(FUNNELGROUP_FUNNEL_FLOW_MODAL);
                          requestAnimationFrame(() =>
                            openSidebar(SIMPLE_FLOWS_MODAL)
                          );
                        }}
                        data-testid="createSimpleFlowBtn"
                        className="sb-funnelFlowCampaign__button"
                      >
                        Create Simple Flow
                      </FFButton>
                    </FormSectionBox>

                  </div>
                )
              }
            ]}
          />
        )}
      </SidebarsConsumer>
    );
  }
}

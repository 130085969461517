import axios from 'axios';
import { loadFromStorage } from 'utils/local-storage';
import { Tokens, User } from '../model/user';
import { API_BASE_URL } from '../constants/api';
import { defined } from '../utils/define';
import { FacebookAdsStore, GoogleAdsStore } from 'types/redux/store';

interface LoginConfig {
  username: string;
  password: string;
}

interface ValidateConfig {
  idToken: string;
  refreshToken: string;
  accessToken: string;
}

interface ForgotPasswordConfig {
  email: string;
}

export interface ErrorResponse {
  error: string;
  error_description: string;
}

interface LoginRequest {
  username: string;
  password: string;
  issue_refresh_token?: boolean;
}

interface LoginResponse {
  tokens: TokensData;
  user: UserData;
}

interface TokensData {
  access_token: string;
  id_token: string;
  expires_at: number;
  refresh_token: string; // returned only if issue_refresh_token is true in request
}

interface UserData {
  user_id: string;
  permissions: string[];
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture_url: string;
  updated_at: number;
}

class Auth {
  private _baseURL: string = '';
  private _tokens?: Tokens;

  constructor(baseURL: string) {
    this._baseURL = baseURL.replace(/\/+$/, ''); // trim tailing slashes
    const store = loadFromStorage();
    const user = store && store.user;
    if (isUserObject(user)) {
      this.startSession(user.tokens);
    }
  }

  get accessToken(): string {
    return this._tokens ? this._tokens.accessToken : '';
  }

  get idToken(): string {
    return this._tokens ? this._tokens.idToken : '';
  }

  get expiresAt(): Date {
    return this._tokens
      ? new Date(this._tokens.expiresAt)
      : new Date(Date.now() - 60000);
  }

  get isAuthenticated() {
    // if have refresh_token no reason to check expiresAt
    return (
      (this._tokens &&
        new Date() < this.expiresAt &&
        !defined(this._tokens.refreshToken)) ||
      (defined(this._tokens) &&
        defined(this._tokens.refreshToken) &&
        this._tokens.refreshToken !== '')
    );
  }

  get isExpired() {
    return defined(this._tokens) && new Date(Date.now()) >= this.expiresAt;
  }

  getUserId(): string {
    const store = loadFromStorage();
    const user: UserData = store && store.user;
    return user && user.user_id;
  }
  
  login({ username, password }: LoginConfig) {
    const request: LoginRequest = {
      username,
      password,
      issue_refresh_token: true
    };
    return axios({
      method: 'post',
      url: this._baseURL + '/v1/auth/login',
      data: request
    }).then((res: any) => {
      return this.applyData(res);
    });
  }

  applyData(res: any) {
    const data = res.data;
    if (isErrorResponse(data)) {
      throw data;
    }
    const { tokens, user } = data as LoginResponse;
    const result: User = {
      userId: user.user_id,
      email: user.email,
      emailVerified: user.email_verified,
      name: user.name,
      nickname: user.nickname,
      picture: user.picture_url,
      tokens: {
        accessToken: tokens.access_token,
        idToken: tokens.id_token,
        expiresAt: tokens.expires_at * 1000, // JS timestamp in milliseconds
        refreshToken: tokens.refresh_token
      },
      loggedIn: true // should be set to true by reducer
    };
    this.startSession(result.tokens);
    return result;
  }

  validate({ idToken, refreshToken, accessToken }: ValidateConfig) {
    const request: object = {
      id_token: idToken,
      refresh_token: refreshToken
    };
    return axios({
      method: 'post',
      url: this._baseURL + '/v1/auth/validate',
      data: request,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then((res: any) => {
      return this.applyData(res);
    }).catch(() => {
      this.logout();
    });
  }

  forgotPassword({ email }: ForgotPasswordConfig) {
    const request: ForgotPasswordConfig = { email };
    return axios({
      method: 'post',
      url: this._baseURL + '/v1/auth/reset_password',
      data: request
    }).then(() => {});
  }

  logout() {
    this.clearSession();
    return Promise.resolve();
  }

  clearSession() {
    this._tokens = undefined;
  }

  startSession(tokens: Tokens) {
    this._tokens = tokens;
  }
}

function isUserObject(user: any): user is User {
  return user && user.hasOwnProperty('tokens') && user.hasOwnProperty('userId');
}

function isErrorResponse(res: any): res is ErrorResponse {
  return res && res.hasOwnProperty('error');
}

const client = new Auth(API_BASE_URL);

export default client;

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import { deleteOfferSource } from 'redux/actions/offersources';
import { ModalsConsumer } from '../context';
import { OFFER_SOURCE_DELETE_MODAL } from 'constants/modal';
import ConfirmForm from '../../Forms/Confirm';
import { ModalProps } from '../types';
import { OfferSourceDeleteModalProps } from 'types/ModalForms/offerSourceDelete';
import { getNameModal } from '../../../utils/modals';
import { FormContextModal } from '../../../types/modal';
import { AnyObject } from 'types';

const OfferSourceDeleteText = () => (
  <div className="margin-bottom-15 color-465565">
    <p>
      <strong>
        Deleting an offer source will remove it from the active view. This will
        not delete any underlying offers.
      </strong>
    </p>
    <p>
      This action will not break funnels, tracking or offer config as offer
      sources are primarily for templating.
    </p>
    <p>
      However, where the offer source is shown it will have the text &quot;-
      deleted&quot; appended and you will not be able to select this source for
      new offers, or specifically group by it in reporting.
    </p>
    <p>
      If you want to move offers to another source, you will need to do so
      manually.
    </p>
  </div>
);

const OfferSourceDeleteModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  contextModal
}: OfferSourceDeleteModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    destroyOnClose
    width={547}
    title={`Delete Offer Source ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['offersource-delete']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ConfirmForm
        handleSubmit={onSubmit}
        successMessage={(context: FormContextModal) =>
          `${context.data.value} has been deleted`
        }
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        prepareData={(context: FormContextModal) => ({
          entries: [context.data.id]
        })}
        contextModal={contextModal}
        okText="Hold to Delete"
        withHoldSubmit
        text={<OfferSourceDeleteText />}
      />
    )}
  />
);

const ConnectedOfferSourceDeleteModal = ({ deleteOfferSource }: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <OfferSourceDeleteModal
            isOpen={isOpenModal(OFFER_SOURCE_DELETE_MODAL)}
            onSubmit={deleteOfferSource}
            contextModal={
              getContextModal(OFFER_SOURCE_DELETE_MODAL) as FormContextModal
            }
            closeSelf={() => closeModal(OFFER_SOURCE_DELETE_MODAL)}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(null, { deleteOfferSource })(
  ConnectedOfferSourceDeleteModal
);

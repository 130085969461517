import { updateConversions as defaultState } from './defaultStates';
import { UPDATE_CONVERSIONS } from '../types';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import { UpdateConversionsStore } from '../../types/redux/store';
import { UpdateConversionsStoreData } from 'types/updateConversions';

const data = (
  state = defaultState.data,
  action: AnyAction
): UpdateConversionsStoreData => {
  switch (action.type) {
    case UPDATE_CONVERSIONS.SET_DATA:
      if (defined(action.payload)) {
        return {
          ...state,
          ...action.payload
        };
      }
      return state;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): UpdateConversionsStore => {
  return {
    data: data(state.data, action)
  };
};

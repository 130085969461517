import { combineReducers } from 'redux';
import { USER_ACTIONS } from './types';
import user from './reducers/user';
import funnelGroups from './reducers/funnelGroups';
import summary from './reducers/summary';
import landers from './reducers/landers';
import offers from './reducers/offers';
import offersources from './reducers/offersources';
import trafficsources from './reducers/trafficsources';
import reportings from './reducers/reportings';
import funnels from './reducers/funnels';
import quickstats from './reducers/quickstats';
import navigation from './reducers/navigation';
import systemSettings from './reducers/systemSettings';
import resetData from './reducers/resetData';
import conditions from './reducers/conditions';
import updateTrafficCosts from './reducers/updateTrafficCosts';
import updateConversions from './reducers/updateConversions';
import pageGroups from './reducers/pageGroups';
import app from './reducers/app';
import integrations from './reducers/integrations';
import billing from './reducers/billing';
import builder from './reducers/builder';
import onboarding from './reducers/onboarding';
import hits from './reducers/hits';
import campaignAnalysis from './reducers/campaignAnalysis';
import { defined } from 'utils/define';
import overview from './reducers/overview';
import googleAds from './reducers/googleAds';
import facebookAds from './reducers/facebookAds';
import keywordRotationLinks from './reducers/keywordRotationLinks';

const lastAction = (state = null, action) => {
  return action;
};

const Reducer = combineReducers({
  user,
  funnelGroups,
  summary,
  funnels,
  offers,
  offersources,
  landers,
  trafficsources,
  navigation,
  reportings,
  quickstats,
  systemSettings,
  resetData,
  conditions,
  pageGroups,
  updateTrafficCosts,
  updateConversions,
  app,
  lastAction,
  integrations,
  builder,
  billing,
  onboarding,
  hits,
  overview,
  campaignAnalysis,
  keywordRotationLinks,
  googleAds,
  facebookAds
});

const rootReducer = (state = {}, action = {}) => {
  if (action.type === USER_ACTIONS.LOGGED_OUT) {
    let newState = {
      ...rootReducer(undefined),
      navigation: state.navigation,
      systemSettings: state.systemSettings
    };

    Object.keys(state).forEach(key => {
      if (defined(state[key]?.settings?.tableCols)) {
        newState[key].settings.tableCols = state[key].settings.tableCols;
      }
      if (defined(state[key]?.tableCols)) {
        newState[key].tableCols = state[key].tableCols;
      }
    });

    state = newState;
  }

  return Reducer(state, action);
};

export default rootReducer;

import React from 'react';
import { ScrollableContent } from '../Content';
import {
  SaveButton,
  DuplicateButton,
  SaveAndCreateButton,
  AddButton,
  RemoveButton,
  OkButton,
  CancelButton
} from '../Buttons';

import './index.scss';
import { IconTooltip } from 'components/Parts/Tooltip';
import { ModalButtonGroupProps } from 'types/buttonGroup';
import { initialLoading } from 'constants/loading';
import { conditionalClass } from 'conditional-class';
import { FFSwitch, VisibilityWrapper } from 'uikit';

export const ModalButtonGroup = ({
  showOk = false,
  showSave = false,
  showCancel = false,
  showDuplicate = false,
  showSaveAndCreate = false,
  saveText = `Save record`,
  okText = `YES`,
  cancelText = `Cancel`,
  duplicateText = `Save & Duplicate`,
  saveAndCreateText = `Save & Create New`,
  onOkClicked = () => {},
  onCancelClicked = () => {},
  onSaveClicked = () => {},
  onDuplicateClicked = () => {},
  onSaveAndCreateClicked = () => {},
  okDisabled = false,
  saveDisabled = false,
  cancelDisabled = false,
  duplicateDisabled = false,
  saveAndCreateDisabled = false,
  loading = initialLoading,
  additionalClass = ''
}: ModalButtonGroupProps) => {
  return (
    <div className={`c-modalButtonGroup ${additionalClass}`}>
      <OkButton
        text={okText}
        visible={showOk}
        onClick={onOkClicked}
        disabled={okDisabled}
        loading={loading.ok}
        data-testid="modalButtonGroup-ok"
      />
      <SaveButton
        text={saveText}
        visible={showSave}
        onClick={onSaveClicked}
        disabled={saveDisabled}
        loading={loading.save}
        data-testid="modalButtonGroup-save"
      />
      <DuplicateButton
        visible={showDuplicate}
        onClick={onDuplicateClicked}
        text={duplicateText}
        disabled={duplicateDisabled}
        loading={loading.saveAndDuplicate}
        data-testid="modalButtonGroup-duplicate"
      />
      <SaveAndCreateButton
        text={saveAndCreateText}
        visible={showSaveAndCreate}
        onClick={onSaveAndCreateClicked}
        disabled={saveAndCreateDisabled}
        loading={loading.saveAndCreate}
        data-testid="modalButtonGroup-saveAndCreate"
      />
      <CancelButton
        text={cancelText}
        visible={showCancel}
        onClick={onCancelClicked}
        disabled={cancelDisabled}
        data-testid="modalButtonGroup-cancel"
      />
    </div>
  );
};

interface AddRemoveGroupProps {
  style?: object;
  className?: string;
  childClassName?: string;
  minItemHeight?: string;
  maxScrollableHeight?: string;
  onAddNewClicked?: Function;
  renderRow?: Function;
  validateField?: Function;
  onRemoveClicked?: Function;
  onToggleClick?: (_row: any, _idx: number) => void;
  showRemoveButton?: (_row: any, _idx: number) => boolean;
  toggleIsChecked?: (_row: any, _idx: number) => boolean;
  showAddNewButton?: boolean;
  showTooltip?: Function;
  data?: any[];
  showToggle?: boolean;
}

export const AddRemoveGroup = ({
  style = {},
  className = '',
  childClassName = '',
  maxScrollableHeight = `265px`,
  onAddNewClicked = () => null,
  renderRow = (_row: any, _idx: number) => null,
  validateField = (_row: any, _idx: number) => false,
  onRemoveClicked = (_row: any, _idx: number) => null,
  toggleIsChecked = (_row: any, _idx: number) => false,
  onToggleClick = (_row: any, _idx: number) => null,
  showRemoveButton = (_row: any, _idx: number) => true,
  showAddNewButton = true,
  showTooltip = (_row: any, _idx: number) => false,
  data = [],
  showToggle = false
}: AddRemoveGroupProps) => (
  <div className={`c-addRemoveGroup ${className}`} style={style}>
    <ScrollableContent maxHeight={maxScrollableHeight}>
      {data.map((row: any, idx) => {
        const hasError = validateField(row, idx) ? 'has-error' : '';
        const showRemove = showRemoveButton(row, idx);
        const tooltip = showTooltip(row, idx);
        const renderRowHtml = renderRow(row, idx);
        if (!renderRowHtml) return null;
        return (
          <React.Fragment key={row.id || idx}>
            <div
              className={conditionalClass(
                `c-addRemoveGroup__child ${childClassName}`,
                {
                  'c-addRemoveGroup__child--first': idx === 0
                }
              )}
              data-testid={`addRemoveGroup-row-${idx + 1}`}
            >
              <div
                className={conditionalClass(
                  `c-addRemoveGroup__child__body ${hasError}`,
                  {
                    [`${childClassName}--body`]: !!childClassName
                  }
                )}
              >
                {renderRowHtml}
              </div>
              <>
                {showToggle ? (
                  <VisibilityWrapper visible={showRemove}>
                    <FFSwitch
                      data-testid={`fieldArchive${idx}`}
                      className="c-addRemoveGroup__archiveBtn"
                      onClick={() => onToggleClick(row, idx)}
                      checked={toggleIsChecked(row, idx)}
                    />
                  </VisibilityWrapper>
                ) : (
                  <RemoveButton
                    visible={showRemove}
                    className={conditionalClass('c-addRemoveGroup__removeBtn', {
                      [`${childClassName}--removeBtn`]: !!childClassName
                    })}
                    onClick={() => onRemoveClicked(row, idx)}
                    data-testid={`addRemoveGroup-removeBtn-${idx}`}
                  />
                )}
                {!!tooltip.title && (
                  <div className="c-addRemoveGroup__tooltip">
                    <IconTooltip title={tooltip.title} body={tooltip.body} />
                  </div>
                )}
              </>
            </div>
          </React.Fragment>
        );
      })}
      {showAddNewButton && (
        <div className="c-addRemoveGroup__child__body__footer">
          <AddButton
            data-testid="addRemoveGroup-newBtn"
            className="c-addRemoveGroup__child__body__footer__newBtn"
            onClick={onAddNewClicked}
          />
        </div>
      )}
    </ScrollableContent>
  </div>
);

export const AndOrOperator = ({
  isOr,
  onClick,
  className
}: {
  isOr: boolean;
  onClick: (value: 'or' | 'and') => void;
  className?: string;
}) => (
  <div className={`c-andOrOperator ${className}`}>
    <div
      className={conditionalClass('c-andOrOperator--or', {
        'c-andOrOperator--active': isOr
      })}
      onClick={() => onClick('or')}
    >
      <span>OR</span>
    </div>
    <div
      className={conditionalClass('c-andOrOperator--and', {
        'c-andOrOperator--active': !isOr
      })}
      onClick={() => onClick('and')}
    >
      <span>AND</span>
    </div>
  </div>
);

import React, { Component } from 'react';
import Messages from 'components/Messages';
import { FormContentWrapper, FormSectionBox } from 'components/Parts/Content';
import { ResetDataDeleteFormProps } from 'types/ModalForms/resetDataDelete';
import './style.scss';
import { ResetUpdateParams } from 'model/models';
import { LoadingProps } from 'types/loading';
import { MessageProps } from 'types';
import { FFInput } from 'uikit';

interface State {
  sure: string;
}

class ResetDataDeleteForm extends Component<
  ResetDataDeleteFormProps & MessageProps & LoadingProps,
  State
> {
  state: State = {
    sure: ''
  };
  sureText = 'I-AM-SURE';

  componentDidMount() {
    this.props.setButtonGroupProps({
      showCancel: true,
      cancelText: 'Cancel',
      onCancelClicked: this.handleCancel,
      showOk: true,
      okText: 'Delete',
      okDisabled: true,
      onOkClicked: this.handleSubmit
    });
  }

  handleInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await this.setState({ sure: e.target.value });
    if (this.state.sure === this.sureText) {
      this.props.setButtonGroupProps({ okDisabled: false });
    } else {
      this.props.setButtonGroupProps({ okDisabled: true });
    }
  };

  handleSubmit = async () => {
    this.props.startLoading('ok');
    try {
      await this.props.onSubmit(
        this.props.contextSidebar.data as ResetUpdateParams
      );
      if (this.props.contextSidebar.onSubmit) {
        this.props.contextSidebar.onSubmit();
      }

      this.props.stopLoading('all');
      this.props.closeSelf();
    } catch (e) {
      this.props.stopLoading('all');
      this.props.showMessage(Messages.failed('Data delete failed'));
      this.props.closeSelf();
    }
  };

  handleCancel = () => {
    this.props.closeSelf();
  };

  render() {
    return (
      <FormContentWrapper>
        <FormSectionBox>
          <p className="c-resetDataDeleteForm__text">
            <span className="c-resetDataDeleteForm__redText">
              This will delete existing data.
            </span>
            This is an irreversible action.
          </p>
          <div className="c-resetDataDeleteForm__column">
            <p className="c-resetDataDeleteForm__text">
              Please confirm the action by writing
              <strong> I-AM-SURE </strong>
              into the field below:
            </p>
            <FFInput
              name="sure"
              data-testid="sure"
              placeholder="Are you sure?"
              value={this.state.sure}
              onChange={this.handleInput}
            />
          </div>
        </FormSectionBox>
      </FormContentWrapper>
    );
  }
}

export default Messages.injectIn(ResetDataDeleteForm);

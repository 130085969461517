import { Component } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DEBOUNCE_TIME } from 'constants/modal';
import { AnyObject } from '../../types';

export class ReactiveComponent<Props, State> extends Component<Props, State> {
  protected readonly unsubscribe$ = new Subject();

  componentWillUnmount() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export interface ReactiveValidateState {
  isTouchedForm: boolean;
  validationErrors: AnyObject;
}

export class ReactiveValidateComponent<Props, State extends ReactiveValidateState> extends ReactiveComponent<Props, State> {
  validate$ = new Subject();

  componentDidMount() {
    this.setValidateSubscribe();
  }

  validate = () => {};

  setValidateSubscribe = () => {
    this.validate$
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.validate());
  };

  setTouchedForm = () => {
    if (!this.state.isTouchedForm) {
      this.setState(state => ({
        ...state,
        isTouchedForm: true
      }));
    }
  };
}

import { ModalsConsumer } from '../context';
import React from 'react';
import FluxModal from 'components/Modal';
import { NAVIGATION_ADD_MODAL } from '../../../constants/modal';
import NavigationTab from '../../Forms/NavigationTab';
import { ModalProps } from '../types';
import { Funnel } from '../../../model/funnel';
import { connect } from 'react-redux';
import { NavigationTabModalSelector } from '../../../redux/selectors';
import { addFunnelEditor, changeView } from '../../../redux/actions/navigation';
import { MenuStore } from '../../../types/menu';
import { defined } from '../../../utils/define';
import { RouteComponentProps } from 'react-router';
import { FunnelGroupArray } from '../../../types/redux/store';
import './style.scss';
import { FormContextModal } from '../../../types/modal';
import { SidebarsConsumer } from 'components/SideBars/context';
import { AddFunnelEditor, FetchFunnelGroupsInfo } from 'types/actions';
import { fetchFunnelGroupsInfo } from 'redux/actions/funnelGroups';

interface AddTabModalProps {
  isOpen: boolean;
  closeSelf: () => void;
  funnels: Funnel[];
  funnelGroups: FunnelGroupArray;
  fetchFunnelGroupsInfo: FetchFunnelGroupsInfo;
  addFunnelEditor: AddFunnelEditor;
  selectedFunnelIds: string[];
  closeModal(modalName: string): void;
  contextModal: {
    history: RouteComponentProps['history'];
  };
}

const AddTabModal = ({
  isOpen,
  closeSelf,
  funnels,
  fetchFunnelGroupsInfo,
  addFunnelEditor,
  selectedFunnelIds,
  contextModal,
  funnelGroups,
  closeModal
}: AddTabModalProps) => (
  <FluxModal
    width={547}
    destroyOnClose
    title="Add New Tab"
    isOpen={isOpen}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <NavigationTab
        onClose={closeSelf}
        funnels={funnels}
        funnelGroups={funnelGroups}
        fetchFunnelGroupsInfo={fetchFunnelGroupsInfo}
        addFunnelEditor={addFunnelEditor}
        selectedFunnelIds={selectedFunnelIds}
        history={contextModal.history}
        closeModal={closeModal}
      />
    )}
  />
);

const getFunnelIds = (menu: MenuStore) => {
  return defined(menu.funnelEditorNavigation)
    ? menu.funnelEditorNavigation.reduce((acc: string[], funnel: Funnel) => {
        acc.push(funnel.idFunnel);
        return acc;
      }, [])
    : [];
};

export default connect(NavigationTabModalSelector, {
  fetchFunnelGroupsInfo,
  addFunnelEditor,
  changeView
})(
  ({ fetchFunnelGroupsInfo, funnels, funnelGroups, addFunnelEditor, menu }) => (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <SidebarsConsumer>
          {() => (
            <AddTabModal
              isOpen={isOpenModal(NAVIGATION_ADD_MODAL)}
              closeSelf={() => closeModal(NAVIGATION_ADD_MODAL)}
              funnels={funnels}
              funnelGroups={funnelGroups}
              fetchFunnelGroupsInfo={fetchFunnelGroupsInfo}
              addFunnelEditor={addFunnelEditor}
              selectedFunnelIds={getFunnelIds(menu)}
              contextModal={
                getContextModal(NAVIGATION_ADD_MODAL) as FormContextModal
              }
              closeModal={closeModal}
            />
          )}
        </SidebarsConsumer>
      )}
    </ModalsConsumer>
  )
);

import React from 'react';

import FluxModal from 'components/Modal';
import { DOMAINS_MODAL_DELETE_DOMAIN } from '../../../constants/modal';
import { ModalsConsumer } from 'components/Modals/context';
import DeleteDomain from '../../Forms/DeleteDomain';
import { ModalProps } from '../types';
import { DomainsDeleteProps } from '../../../types/ModalForms/domain';
import { FormContextModal } from '../../../types/modal';

const DeleteDomainsModal = ({
  isOpen,
  closeSelf,
  onSubmit = () => {},
  name
}: DomainsDeleteProps) => (
  <FluxModal
    width={500}
    destroyOnClose
    title={`Remove ${name}`}
    isOpen={isOpen}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    // @ts-ignore
    render={() => <DeleteDomain onClose={closeSelf} onSubmit={onSubmit} />}
  />
);

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
      <>
        <DeleteDomainsModal
          isOpen={isOpenModal(DOMAINS_MODAL_DELETE_DOMAIN)}
          closeSelf={() => closeModal(DOMAINS_MODAL_DELETE_DOMAIN)}
          onSubmit={
            (getContextModal(DOMAINS_MODAL_DELETE_DOMAIN) as FormContextModal)
              .onSubmit
          }
          name={
            (getContextModal(DOMAINS_MODAL_DELETE_DOMAIN) as FormContextModal)
              .copyName!
          }
        />
      </>
    )}
  </ModalsConsumer>
);

import { AnyObject } from '../../types';

export const sortByName = <T extends { [key: string]: any }>(
  arr: T[] = [],
  name: keyof T
): T[] => {
  return arr.sort((a, b) => {
    if (!a[name] || !b[name]) {
      return 0;
    }
    const nameA = a[name].toLowerCase();
    const nameB = b[name].toLowerCase();

    return naturalSort(nameA, nameB);
  });
};

export const sortBasedOnOrder = <T extends { [key: string]: any }>(
  arr: T[] = [],
  key: keyof T,
  orderArr: string[] = []
): T[] => {
  return arr.sort((a, b) => {
    const keyA = a[key];
    const keyB = b[key];

    const indexA = orderArr.indexOf(keyA);
    const indexB = orderArr.indexOf(keyB);

    return indexA - indexB;
  });
};

export const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});
export const naturalSort = (str1: string, str2: string) => {
  return collator.compare(str1, str2);
};

export const sortObject = (object: AnyObject) =>
  Object.keys(object)
    .sort(naturalSort)
    .reduce((acc: any, key) => ((acc[key] = object[key]), acc), {});

export const sortArray = (arr: any[]) => arr.sort(naturalSort);

export function moveToFirstByProp<T>(options: T[], sortProp: keyof T) {
  return options.sort((a, b) => (a[sortProp] ? -1 : b[sortProp] ? 1 : 0));
}

export function moveToFirstByFunc<T>(
  options: T[],
  sortFunc: (v: T) => boolean
) {
  return options.sort((a, b) => (sortFunc(a) ? -1 : sortFunc(b) ? 1 : 0));
}

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import { withLoading } from 'components/Loading';
import DomainSettings from 'components/Forms/DomainSettings';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import { DOMAIN_SETTINGS_MODAL } from 'constants/modal';
import { FormContextSidebar } from 'types/modal';
import {
  DomainSettingsFormProps,
  DomainSettingsSidebarProps
} from 'types/ModalForms/domainSettings';
import { AnyObject } from 'types';

class DomainSettingsFormWrapper extends React.Component<
  Omit<DomainSettingsFormProps, 'setButtonGroupProps'> &
    DomainSettingsSidebarProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        isOpen={this.props.isOpen!}
        hasTab={false}
        onClose={this.props.closeSelf}
        dataPortalKey="domainSettings"
        defaultOpenSideBarKey="domainSettingsForm"
        zIndex={this.props.zIndex}
        sideBars={[
          {
            key: 'domainSettingsForm',
            title: 'Domain Settings',
            width: 970,
            render: ({ setButtonGroupProps }) => (
              <DomainSettings
                {...this.props}
                setButtonGroupProps={setButtonGroupProps}
              />
            )
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(DomainSettingsFormWrapper);

const ConnectedFormElement = ({}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({ isOpenSidebar, getContextSidebar, closeSidebar }: SidebarProps) => {
        let sidebarName = DOMAIN_SETTINGS_MODAL;
        let contextSidebar = getContextSidebar(
          DOMAIN_SETTINGS_MODAL
        ) as FormContextSidebar;

        const isOpen = isOpenSidebar(DOMAIN_SETTINGS_MODAL);

        return (
          <FormElement
            isOpen={isOpen}
            closeSelf={() => closeSidebar(sidebarName)}
            contextModal={contextSidebar}
            zIndex={(contextSidebar as FormContextSidebar)?.zIndex || 0}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default ConnectedFormElement;

import React from 'react';
import Messages from 'components/Messages';
import { MessageProps } from 'types';
import { FormSectionBox } from 'components/Parts/Content';
import { Select } from 'components/Parts/Inputs';
import {
  SimpleFlowRouting,
  SimpleFlowRoutingEntity
} from 'model/simpleFlowRouting';
import EntitySlider from 'components/EntitySlider';
import { defined } from 'utils/define';
import { SimpleFlowRule } from 'model/simpleFlowRules';
import { SimpleFlowPath } from 'model/simpleFlowPath';
import { moveToFirstByProp } from 'utils/sort';

interface State {
  selectedRuleId: string;
}

interface Props extends MessageProps {
  routings: SimpleFlowRouting;
  rules: SimpleFlowRule[];
  paths: SimpleFlowPath[];
  onAddEdit: (ruleId: string, routingEntity: SimpleFlowRoutingEntity) => void;
  onDelete: (ruleId: string, routeId: string) => void;
}

const DEFAULT_MULTIPLIER = 100;

const getWeight = (item: SimpleFlowRoutingEntity) => {
  return defined(item.weight) ? item.weight * DEFAULT_MULTIPLIER : 0;
};

class SimpleFlowsConfigureRoutingToPaths extends React.Component<Props, State> {
  state: State = {
    selectedRuleId: ''
  };

  async componentDidMount() {
    const defaultRule = this.props.rules.find(r => r.isDefault);
    await this.handleState('selectedRuleId', defaultRule?.ruleId!);

    // add paths for default rule
    if (!Object.keys(this.props.routings).length) {
      this.props.paths.forEach(path =>
        this.props.onAddEdit(this.state.selectedRuleId, {
          weight: 1,
          path
        })
      );
    }
  }

  handleState = <T extends State, P extends keyof T>(name: P, value: T[P]) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  onDelete = (routeId: string) => {
    this.props.onDelete(this.state.selectedRuleId, routeId);
  };

  onSliderChange = (route: SimpleFlowRoutingEntity, value: number) => {
    if (value < 0 || value > 100 || isNaN(Number(value))) {
      return false;
    }

    this.props.onAddEdit(this.state.selectedRuleId, {
      ...route,
      weight: Number(value) / DEFAULT_MULTIPLIER
    });
  };

  getRoutingEntities = () =>
    this.props.routings[this.state.selectedRuleId] || [];

  sum = () => {
    const routingEntities = this.getRoutingEntities();
    return routingEntities.reduce((sum, item) => sum + getWeight(item), 0);
  };

  onSelectPath = (pathId: string) => {
    const path = this.props.paths.find(p => p.pathId === pathId)!;

    this.props.onAddEdit(this.state.selectedRuleId, {
      weight: 1,
      path
    });
  };

  isDefaultRuleSelected = () => {
    const defaultRule = this.props.rules.find(r => r.isDefault);
    return this.state.selectedRuleId === defaultRule?.ruleId;
  }

  render() {
    const routingEntities = this.getRoutingEntities();
    return (
      <form className="cform-simpleFlows__configureRules">
        <FormSectionBox
          title="How Rules Should Route to Paths"
          withBoxPadding={false}
          withTitlePadding={true}
        >
          <p className="padding-x-20">
            Here, select any of your rules (including the default) and you can configure what destination paths you would like the user to go to.
          </p>
          <div className="padding-y-25 padding-x-20 border-top-1">
            <Select
              placeholder="Select a rule from this flow"
              style={{ width: 214 }}
              value={this.state.selectedRuleId}
              options={this.props.rules}
              valueGetter={(option: SimpleFlowRule) => option.ruleId}
              labelGetter={(option: SimpleFlowRule) => option.ruleName}
              onChange={(ruleId: string) =>
                this.handleState('selectedRuleId', ruleId)
              }
            />
          </div>
          <div>
            {routingEntities.map((route, idx) => (
              <>
                <EntitySlider
                  id={idx.toString()}
                  counterText={`${idx} + 1`}
                  name={route.path.pathName}
                  onDelete={() => this.onDelete(route.path.pathId)}
                  showDeleteIcon={routingEntities.length > 1 && !(this.isDefaultRuleSelected() && route.path.isDefault)}
                  sliderValue={getWeight(route)}
                  onSliderChange={value => this.onSliderChange(route, value)}
                  onSliderInputChange={value =>
                    this.onSliderChange(route, value)
                  }
                  weightValue={Number(
                    getWeight(route) > 0
                      ? (getWeight(route) / this.sum()) * DEFAULT_MULTIPLIER
                      : 0
                  ).toFixed(2)}
                  iconType="flowPath"
                />
              </>
            ))}
          </div>
          <div className="padding-y-25 padding-x-20 border-top-1 flex flex-align-center flex-gap-15">
            <Select
              placeholder="Select a path to add to this rule set"
              style={{ width: 280 }}
              options={moveToFirstByProp(this.props.paths, 'isDefault')}
              valueGetter={(option: SimpleFlowPath) => option.pathId}
              labelGetter={(option: SimpleFlowPath) => option.pathName}
              onChange={this.onSelectPath}
              suspendSort
            />
          </div>
        </FormSectionBox>
      </form>
    );
  }
}

export default Messages.injectIn(SimpleFlowsConfigureRoutingToPaths);

import React from 'react';

interface Props {
  src: string;
  alt: string;
  className?: string;
  folderName?: string;
}

interface State {
  src: string;
  loading: boolean;
}

class ImageAsyncSrc extends React.Component<Props, State> {
  state = {
    loading: false,
    src: ''
  };

  static defaultProps: Partial<Props> = {
    folderName: ''
  };

  componentDidUpdate(prevProps: Props, _: State) {
    if (prevProps.src !== this.props.src && !!this.props.src) {
      this.load();
    }
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
		if (!this.props.src) {
			return false;
		}

    await this.setState({ loading: true });
    await import(
			`imgs/${!this.props.folderName ? '' : this.props.folderName + '/'}${
				this.props.src
			}`
		).then(res => {
			this.setState({ src: res?.default });
		});
    this.setState({ loading: false });
  };

  render() {
    return this.state.loading || !this.state.src ? (
      <></>
    ) : (
      <img
        src={this.state.src}
        alt={this.props.alt}
        className={this.props.className}
      />
    );
  }
}

export default ImageAsyncSrc;

import React, { CSSProperties } from 'react';
import { NewIconName, NewIconSize } from '../../types/icon';

const NewIcon = ({
  name,
  size = 'lg',
  className,
  display
}: {
  name: NewIconName;
  size?: NewIconSize;
  className?: string;
  display?: CSSProperties['display'];
}) => {
  try {
    let Icon = require(`../../../assets/icons/${name}.tsx`).default;
    const width = size === 'navigation-md' ? 22 : size === 'xsm' ? 12 : size === 'sm' ? 16 : size === 'md' ? 24 : 32;
    const height = size === 'navigation-md' ? 22 : size === 'xsm' ? 12 : size === 'sm' ? 16 : size === 'md' ? 24 : 32;

    return (
      <i
        className={className}
        style={{
          width: width,
          height: height,
          display: display || size === 'navigation-md' ? 'flex' : 'unset'
        }}>
        <Icon />
      </i>
    );
  } catch (e) {
    return <></>;
  }
};

export default NewIcon;
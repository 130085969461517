import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { CompositeStore } from 'types/redux/store';
import { loggedIn } from '../../redux/selectors';

const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}: {
  isAuthenticated: boolean;
  component: any;
} & RouteProps) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated ? (
        <Suspense fallback={<></>}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect to="/summary" />
      )
    }
  />
);

export default connect((state: CompositeStore) => ({
  isAuthenticated: loggedIn(state)
}))(PublicRoute);

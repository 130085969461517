import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Async from 'components/Async';
import ModalsProvider from 'components/Modals';
import SidebarsProvider, { SidebarOffsetRightProvider } from 'components/SideBars';
import MessagesProvider from 'components/Messages';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReduxStore from './redux';
import { saveToStorage } from 'utils/local-storage';
import 'antd/dist/antd.min.css';
import 'scss/index.scss';
import { ErrorBoundary } from './components/Error';
import reportWebVitals from './reportWebVitals';

// we need to import these modal/sidebar here as we need to
// have access to both  modal/sidebar context in them
import ContextMenu from 'components/Modals/Builder/ContextMenu';
import NavigationTab from 'components/Modals/NavigationTab';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const { store } = ReduxStore;
const a = 3;
const b = a ?? void 0;

store.subscribe(async () => {
  await saveToStorage({
    user: store.getState().user,
    navigation: store.getState().navigation,
    googleAds: store.getState().googleAds,
    facebookAds: store.getState().facebookAds,
    reportings: {
      settings: {
        view: store.getState().reportings.settings.view,
        tableCols: store.getState().reportings.settings.tableCols,
      },
      tableParams: store.getState().reportings.tableParams,
    },
    offers: {
      settings: {
        tableCols: store.getState().offers.settings.tableCols,
        tableParams: store.getState().offers.settings.tableParams,
        filters: store.getState().offers.settings.filters,
        editMode: store.getState().offers.settings.editMode,
        additionalParams: store.getState().offers.settings.additionalParams,
        show: store.getState().offers.settings.show
      }
    },
    landers: {
      settings: {
        tableCols: store.getState().landers.settings.tableCols,
        tableParams: store.getState().landers.settings.tableParams,
        filters: store.getState().landers.settings.filters,
        editMode: store.getState().landers.settings.editMode,
        show: store.getState().landers.settings.show
      }
    },
    offersources: {
      settings: {
        tableCols: store.getState().offersources.settings.tableCols,
        tableParams: store.getState().offersources.settings.tableParams,
        filters: store.getState().offersources.settings.filters,
        editMode: store.getState().offersources.settings.editMode,
        show: store.getState().offersources.settings.show
      }
    },
    trafficsources: {
      settings: {
        tableCols: store.getState().trafficsources.settings.tableCols,
        tableParams: store.getState().trafficsources.settings.tableParams,
        filters: store.getState().trafficsources.settings.filters,
        editMode: store.getState().trafficsources.settings.editMode,
        show: store.getState().trafficsources.settings.show
      }
    },
    funnelGroups: {
      settings: {
        tableCols: store.getState().funnelGroups.settings.tableCols,
        tableParams: store.getState().funnelGroups.settings.tableParams,
        filters: store.getState().funnelGroups.settings.filters,
        editMode: store.getState().funnelGroups.settings.editMode,
        additionalParams: store.getState().funnelGroups.settings.additionalParams,
        show: store.getState().funnelGroups.settings.show
      }
    },
    pageGroups: {
      settings: {
        tableCols: store.getState().pageGroups.settings.tableCols,
        tableParams: store.getState().pageGroups.settings.tableParams,
        filters: store.getState().pageGroups.settings.filters,
        editMode: store.getState().pageGroups.settings.editMode,
        additionalParams: store.getState().pageGroups.settings.additionalParams,
        show: store.getState().pageGroups.settings.show
      }
    },
    summary: {
      tableCols: store.getState().summary.tableCols,
      settings: store.getState().summary.settings
    },
    campaignAnalysis: {
      settings: store.getState().campaignAnalysis.settings
    },
    quickstats: {
      tableCols: store.getState().quickstats.tableCols
    },
    systemSettings: {
      tooltips: {
        editMode: store.getState().systemSettings.tooltips.editMode,
      },
    },
    builder: {
      zooms: store.getState().builder.zooms,
      pans: store.getState().builder.pans
    },
    onboarding: store.getState().onboarding,
    hits: {
      settings: store.getState().hits.settings
    }
  });
});

// initSentry();

const App = Async({ get: () => import('./App') });

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <MessagesProvider>
        <BrowserRouter>
          <ModalsProvider>
            <SidebarOffsetRightProvider>
              <SidebarsProvider>
                <ContextMenu />
                <NavigationTab />
                <App />
              </SidebarsProvider>
            </SidebarOffsetRightProvider>
          </ModalsProvider>
        </BrowserRouter>
      </MessagesProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
serviceWorkerRegistration.register();
reportWebVitals();
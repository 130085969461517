import table from './table';
import {
  funnelGroups as defaultState,
  emptyFunnelAttribute,
  funnelGroupsAttributesReporting,
  subcampaignAttributesReporting
} from './defaultStates';
import { FUNNELGROUPS, API, VIEW, FUNNELS, TABLE } from '../types';
import { FunnelGroupAction, ReportingParamsAction } from '../../types/redux/action';
import { getFieldName, getReverseStatus } from '../../utils/selectors';
import { AnyAction } from 'redux';
import { FunnelGroupArray, FunnelGroupStore } from '../../types/redux/store';
import { defined } from '../../utils/define';
import { FunnelGroup } from '../../model/funnelGroup';
import { DrilldownReport } from 'model/drilldownReport';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { AnyObject } from '../../types';
import { DrilldownReportParams } from 'model/drilldownReportParams';
import { FunnelGroupInfo } from 'model/funnelGroupInfo';
import { infoToResource } from 'utils/redux';

const data = (state = defaultState.data, action: FunnelGroupAction) => {
  switch (action.type) {
    case FUNNELGROUPS.SET_LIST:
      if (defined(action.payload)) {
        return (action.payload as FunnelGroup[]).reduce((acc: FunnelGroupArray, funnelGroup) => {
          acc[funnelGroup.idCampaign] = funnelGroup;
          return acc;
        }, {});
      }
      return state;
    case FUNNELGROUPS.SET_INFO:
      if (defined(action.payload)) {
        return (action.payload as FunnelGroupInfo[]).reduce((acc: FunnelGroupArray, funnelGroupInfo) => {
          if (!acc[funnelGroupInfo.idCampaign]) {
            acc[funnelGroupInfo.idCampaign] = infoToResource.funnelGroup(funnelGroupInfo);
          }
          return acc;
        }, state);
      }
      return state;
    case FUNNELGROUPS.ADD_SINGLE:
    case FUNNELGROUPS.ADD_EXISTED_SINGLE:
      const payload = action.payload as FunnelGroup;
      return {
        ...state,
        [payload.idCampaign]: payload
      };
    case FUNNELGROUPS.ARCHIVE:
      if (defined(action.payload)) {
        const funnelGroupId = (action.payload as any).data;
        return {
          ...state,
          [funnelGroupId]: {
            ...state[funnelGroupId],
            status: 'archived'
          }
        };
      }
      return state;
    case FUNNELGROUPS.DELETE:
      if (defined(action.payload)) {
        const funnelGroupId = (action.payload as any).data[0];
        return {
          ...state,
          [funnelGroupId]: {
            ...state[funnelGroupId],
            status: 'deleted'
          }
        };
      }
      return state;
    default:
      return state;
  }
};

const reporting = (state = defaultState.reporting, action: ReportingParamsAction) => {
  switch (action.type) {
    case FUNNELGROUPS.SET_REPORTING:
      if (defined(action.payload)) {
        return {
          ...(action.payload.data as DrilldownReport)
        };
      }
      return state;
    case FUNNELS.ADD_SINGLE_ROW:
    case FUNNELGROUPS.ADD_SINGLE_ROW:
      if (defined(action.payload)) {
        return {
          ...state,
          rows: [...state.rows, action.payload.data as DrilldownReportRow]
        };
      }
      return state;
    case FUNNELS.DELETE:
      if (defined(action.payload)) {
        const deletePayload = action.payload.data as string[];
        let deletedRow = {} as DrilldownReportRow;

        const remainRows = state.rows.filter(item => {
          if (defined(item.attributes?.[1]?.id) && !deletePayload.includes(item.attributes[1].id)) {
            deletedRow = item;
            return true;
          }
          return false;
        });

        return {
          ...state,
          rows: [
            ...remainRows,
            ...(!remainRows.find(item => item?.attributes?.[0].id === deletedRow?.attributes?.[0].id)
              ? [deletedRow].map(item => {
                  if (defined(item?.attributes?.[1])) {
                    item.attributes[1] = emptyFunnelAttribute;
                  }
                  return item;
                })
              : [])
          ]
        };
      }
      return state;
    case FUNNELS.ARCHIVE_SINGLE:
      if (defined(action.payload)) {
        const archivePayload = action.payload.data as string;
        return {
          ...state,
          rows: state.rows.map((item: DrilldownReportRow) => {
            if (item.attributes[1].id === archivePayload) {
              item.attributes[1].status = getReverseStatus(item.attributes[1].status);
            }
            return item;
          })
        };
      }
      return state;
    case FUNNELS.ARCHIVE_LIST:
      if (defined(action.payload)) {
        const archivePayload = action.payload.data as string;
        return {
          ...state,
          rows: state.rows.map((item: DrilldownReportRow) => {
            if (archivePayload.includes(item.attributes[1].id)) {
              item.attributes[1].status = 'archived';
            }
            return item;
          })
        };
      }
      return state;
    case FUNNELS.UNARCHIVE_LIST:
      if (defined(action.payload)) {
        const archivePayload = action.payload.data as string;
        return {
          ...state,
          rows: state.rows.map((item: DrilldownReportRow) => {
            if (archivePayload.includes(item.attributes[1].id)) {
              item.attributes[1].status = 'active';
            }
            return item;
          })
        };
      }
      return state;
    case FUNNELS.MOVE_LIST:
      if (defined(action.payload)) {
        const data = action.payload.data as AnyObject;
        const moveIds = data.entries;
        const idFunnelGroup = data.idCampaign;
        const funnelGroupName = data.campaignName;

        return {
          ...state,
          rows: state.rows.map((item: DrilldownReportRow) => {
            if (moveIds.includes(item.attributes[1].id)) {
              item.attributes[0].id = idFunnelGroup;
              item.attributes[0].value = funnelGroupName;
            }
            return item;
          })
        };
      }
      return state;
    case FUNNELGROUPS.DELETE:
      if (defined(action.payload)) {
        const deletePayload = action.payload.data as string[];
        return {
          ...state,
          rows: [...state.rows.filter((item: any) => !deletePayload.includes(item.attributes[0].id))]
        };
      }
      return state;
    case FUNNELGROUPS.DELETE_EMPTY:
      if (defined(action.payload)) {
        const deletePayload = action.payload.data as string[];
        return {
          ...state,
          rows: [...state.rows.filter((item: any) => !!item.attributes[1].value && !deletePayload.includes(item.attributes[0].id))]
        };
      }
      return state;
    case FUNNELGROUPS.ARCHIVE:
      if (defined(action.payload)) {
        const archivePayload = action.payload.data as string;
        return {
          ...state,
          rows: state.rows.map((item: DrilldownReportRow) => {
            if (item.attributes[0].id === archivePayload) {
              item.attributes[0].status = getReverseStatus(item.attributes[0].status);
            }
            return item;
          })
        };
      }
      return state;
    case FUNNELGROUPS.UPDATE_SINGLE:
    case FUNNELS.UPDATE_SINGLE_ROW:
      if (defined(action.payload)) {
        return {
          ...state,
          rows: action.payload.data as DrilldownReportRow[]
        };
      }
      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loadings, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        [getFieldName(action, FUNNELGROUPS.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: true
        }
      };
    case API.ENDED:
      return {
        ...state,
        [getFieldName(action, FUNNELGROUPS.FETCH_REPORTING)]: {
          ...state.data,
          isLoading: false,
          fetchedAt: Date.now()
        }
      };
    default:
      return state;
  }
};

const tableCols = (state = defaultState.settings.tableCols, action: AnyAction) => {
  if (defined(action.payload) && action.payload.view === VIEW.FUNNELGROUPS && action.type === TABLE.SET_COLS) {
    return table(state, action);
  } else {
    return state;
  }
};

const show = (state = defaultState.settings.show, action: AnyAction) => {
  switch (action.type) {
    case FUNNELGROUPS.SET_SHOW:
      return action.payload;
    default:
      return state;
  }
};

const filters = (state = defaultState.settings.filters, action: ReportingParamsAction): any => {
  //@TODO: Remove later
  const getParams = (params: DrilldownReportParams) =>
    Object.entries(params).reduce(
      (acc, crr) => {
        if (!['attributes', 'timeStart', 'timeEnd'].includes(crr[0])) {
          return {
            ...acc,
            [crr[0]]: crr[1]
          };
        }
        return acc;
      },
      {
        timeStart: params.timeStart,
        timeEnd: params.timeEnd,
        attributes: params.attributes.length === 3 ? subcampaignAttributesReporting : funnelGroupsAttributesReporting
      }
    );
  switch (action.type) {
    case FUNNELGROUPS.SET_REPORTING:
      return getParams(action.payload.params);
    default:
      return getParams(state);
  }
};

const editMode = (state = defaultState.settings.editMode, action: AnyAction) => {
  return action.type === TABLE.SET_EDIT_MODE && action.payload.view === VIEW.FUNNELGROUPS ? action.payload.data : state;
};

const additionalParams = (state = defaultState.settings.additionalParams, action: AnyAction) => {
  return action.type === TABLE.SET_ADDITIONAL_PARAMS && action.payload.view === VIEW.FUNNELGROUPS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const tableParams = (state = defaultState.settings.tableParams, action: AnyAction) => {
  return action.type === TABLE.SET_TABLE_PARAMS && action.payload.view === VIEW.FUNNELGROUPS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

export default (state = defaultState, action: any): FunnelGroupStore => ({
  data: data(state.data, action),
  settings: {
    show: show(state.settings.show, action),
    tableCols: tableCols(state.settings.tableCols, action),
    tableParams: tableParams(state.settings.tableParams, action),
    filters: filters(state.settings.filters, action),
    editMode: editMode(state.settings.editMode, action),
    additionalParams: additionalParams(state.settings.additionalParams, action)
  },
  reporting: reporting(state.reporting, action),
  loadings: loading(state.loadings, action)
});

import { Page } from "model/page";
import { SelectOption } from "types";

export const redirects: SelectOption<string, Page.RedirectTypeEnum>[] = [
  {
    value: '301',
    label: '301 Moved Permanently'
  },
  {
    value: '307',
    label: '307 Temporary Redirect'
  },
  {
    value: 'umr',
    label: 'Ultimate Meta Refresh'
  }
];

export const additionalRedirects: SelectOption[] = [
  {
    value: '301',
    label: '301 Moved Permanently'
  },
  {
    value: '307',
    label: '307 Temporary Redirect'
  },
  {
    value: 'meta',
    label: 'Simple Meta Refresh'
  },
  {
    value: 'umr',
    label: 'Ultimate Meta Refresh'
  }
];

export const jsCode =
  '<script>\n' +
  "        var s = document.createElement('script');\n" +
  "        s.setAttribute('src', 'http://#DOMAIN#/resources/js/funnel.js');\n" +
  '        s.onload = function () {\n' +
  '            fflux = new ffluxQuery();\n' +
  "            fflux.ffq('set', ['flux_url', fflux.getDocumentURL()]);\n" +
  "            fflux.ffq('set', ['flux_ref', fflux.getReferrer()]);\n" +
  "            fflux.ffq('set', ['flux_f', '#FUNNEL-ID#']);\n" +
  '            fflux.track({});\n' +
  '        };\n' +
  '        document.head.appendChild(s);\n' +
  '    </script>';

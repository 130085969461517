import { Input } from 'components/Parts/Inputs';
import React from 'react';
import { OfferSourceTemplate } from 'types/offerSources';
import { TrafficSourceTemplate } from 'types/trafficsource';
import { sortByName } from 'utils/sort';
import './style.scss';

export const TemplateSelector = ({
    onSelect,
    onSearch,
    searchValue,
    templates = [],
    type,
  }: {
    onSelect: (id?: string) => void;
    onSearch: (val: string) => void;
    searchValue: string;
    templates: (TrafficSourceTemplate | OfferSourceTemplate)[],
    type: 'offersource' | 'trafficsource'
  }) => (
    <div className={`c-templateSelector c-templateSelector__${type}`}>
      <div className="c-templateSelector__search">
        <Input
          id="tsTemplateSearch"
          name="tsTemplateSearch"
          data-testid="tsTemplateSearch"
          placeholder="Search to filter available templates"
          value={searchValue}
          onChange={e => onSearch(e.target.value)}
        />
      </div>
      <div className="c-templateSelector__templates">
        <div
          className="c-templateSelector__template"
          onClick={() => onSelect()}
        >
          <span className="font-size-16">Create a custom source</span>
          <span className="font-size-26">+</span>
        </div>
        {sortByName(templates, 'name')
          .filter(template => template.name.toLowerCase().includes(searchValue))
          .map(template => (
            <div
              className="c-templateSelector__template"
              onClick={() => onSelect(template.id)}
              key={template.id}
            >
              <img
                className={`c-templateSelector__template--img c-templateSelector__template--img--${template.id}`}
                src={template.imgSrc}
                alt={template.name}
              />
            </div>
          ))}
      </div>
    </div>
  );

import React, { PropsWithChildren } from 'react';
import className from 'utils/style/className';
import './style.scss';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { blockClassName, getClass } = className('c-ffField');

export default ({
  label,
  children,
  error,
  htmlFor,
  block = false,
  wdith,
  tootlipContent,
  className,
  direction = 'column'
}: PropsWithChildren<{
  label: string;
  error?: string;
  htmlFor?: string;
  block?: boolean;
  wdith?: string;
  className?: string;
  direction?: 'column' | 'row';
  tootlipContent?: string | JSX.Element;
}>) => (
  <div
    className={[
      blockClassName,
      block ? `${blockClassName}--block` : `${blockClassName}--minimum`,
      `${blockClassName}--${direction}`,
      className
    ].join(' ')}
    style={{ minWidth: wdith, maxWidth: wdith }}
  >
    <label htmlFor={htmlFor} className={getClass('label')}>
      <span>{label}</span>
      {tootlipContent && (
        <Tooltip className={getClass('tooltip')} overlayClassName={getClass('tooltipOverlay')} title={tootlipContent}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </label>
    <div className={getClass('childContent')}>{children}</div>
    {error && <span className={getClass('error')}>{error}</span>}
  </div>
);

import { ModelEnum } from '../../types';
import { Page } from '../../model/page';
import { Category } from '../../model/category';
import { TrafficSource } from '../../model/trafficSource';
import { OfferSource } from '../../model/offerSource';
import { FunnelGroup } from '../../model/funnelGroup';
import { Funnel } from '../../model/funnel';
import { FunnelNode } from '../../model/funnelNode';
import { generateEntityId } from '../id/generator';
import { Attribute } from '../../model/attribute';
import { DrilldownReportRowAttribute } from '../../model/drilldownReportRowAttribute';
import { defined } from '../define';
import { FunnelCondition } from '../../model/funnelCondition';
import { PageGroup } from '../../model/pageGroup';
import { Meta } from 'model/meta';
import { getTrafficNode } from 'constants/builder';

export const getEntityId = (model: ModelEnum) => {
  if ((model as Page).idPage !== undefined) {
    return (model as Page).idPage;
  } else if ((model as TrafficSource).idTrafficSource !== undefined) {
    return (model as TrafficSource).idTrafficSource;
  } else if ((model as OfferSource).idOfferSource !== undefined) {
    return (model as OfferSource).idOfferSource;
  } else if ((model as Funnel).idFunnel !== undefined) {
    return (model as Funnel).idFunnel;
  } else if ((model as FunnelGroup).idCampaign !== undefined) {
    return (model as FunnelGroup).idCampaign;
  } else if ((model as FunnelCondition).idCondition !== undefined) {
    return (model as FunnelCondition).idCondition;
  } else if ((model as Category).idCategory !== undefined) {
    return (model as Category).idCategory;
  } else if (defined((model as DrilldownReportRowAttribute).id)) {
    return (model as DrilldownReportRowAttribute).id;
  } else if ((model as PageGroup).idPageGroup !== undefined) {
    return (model as PageGroup).idPageGroup;
  }

  return '';
};

export const getEntityName = (model: ModelEnum) => {
  if ((model as Page).idPage !== undefined) {
    return (model as Page).pageName;
  } else if ((model as TrafficSource).idTrafficSource !== undefined) {
    return (model as TrafficSource).trafficSourceName;
  } else if ((model as OfferSource).idOfferSource !== undefined) {
    return (model as OfferSource).offerSourceName;
  } else if ((model as Funnel).idFunnel !== undefined) {
    return (model as Funnel).funnelName;
  } else if ((model as FunnelCondition).conditionName !== undefined) {
    return (model as FunnelCondition).conditionName;
  } else if ((model as FunnelGroup).idCampaign !== undefined) {
    return (model as FunnelGroup).campaignName;
  } else if ((model as Category).idCategory !== undefined) {
    return (model as Category).categoryName;
  } else if ((model as PageGroup).idPageGroup !== undefined) {
    return (model as PageGroup).pageGroupName;
  }

  return '';
};

export const getEmptyFunnelNodeByType = (
  type: FunnelNode.NodeTypeEnum
): FunnelNode => {
  const node = {
    idNode: generateEntityId(),
    nodeName: '',
    nodeType: type,
    status: 'active'
  } as FunnelNode;
  let additionalNode = {};
  switch (type) {
    case 'offer':
    case 'lander':
      break;
    case 'externalUrl':
      additionalNode = {
        nodeName: 'External Url',
        nodeExternalUrlParams: {
          url: ''
        }
      };
      break;
    case 'rotator':
      additionalNode = {
        nodeName: 'Rotator',
        nodeRotatorParams: {}
      };
      break;
    case 'condition':
      additionalNode = {
        nodeConditionParams: {
          idCondition: ''
        }
      };
      break;
  }

  return {
    ...node,
    ...additionalNode
  };
};

interface Model {
  name: string;
}

export const getEntityByReportingAttribute = (
  attribute: Attribute,
  rowAttribute: DrilldownReportRowAttribute,
  idCampaign: string
): ModelEnum | Model | undefined => {
  switch (attribute) {
    case 'Element: Funnel Group':
      return {
        idCampaign: rowAttribute.id,
        campaignName: rowAttribute.value,
        status: 'active'
      };
    case 'Element: Funnel':
      return {
        idFunnel: rowAttribute.id,
        funnelName: rowAttribute.value,
        idCampaign,
        status: 'active'
      };

    default:
      return {
        name: rowAttribute.value
      };
  }
};

export const getEntityNameByReportingAttribute = (attribute: Attribute) => {
  switch (attribute) {
    case Attribute.ElementFunnelGroup:
      return 'campaign';
    case Attribute.ElementFunnel:
      return 'funnel';
    default:
      return 'data';
  }
};

export const getAllFunnelsOfFunnelGroup = (
  funnels: Funnel[],
  idFunnelGroup: string
) => funnels.filter(item => item.idCampaign === idFunnelGroup);

/**
 * Update/remove some data on old funnels to avoid server errors
 * @param funnel
 */
export const clearFunnel = (funnel: Funnel) => {
  funnel.postbackOverrides = [];

  (funnel.nodes || []).map(node => {
    if (node.nodeName === '') {
      if (node.nodeType === 'rotator') {
        node.nodeName = 'Rotator';
      } else if (node.nodeType === 'externalUrl') {
        node.nodeName = 'External Url';
      }
    }

    return node;
  });

  return funnel;
};

export const clearTrafficSource = (trafficSource: TrafficSource) => {
  if (
    defined(trafficSource.postback) &&
    ((defined(trafficSource.postback.idTrafficSource) &&
      trafficSource.postback.idTrafficSource === '') ||
      !defined(trafficSource.postback.idTrafficSource))
  ) {
    trafficSource.postback.idTrafficSource = trafficSource.idTrafficSource;
  }

  return trafficSource;
};

export const isPageModel = (page: any) =>
  defined(page.idPage) && defined(page.pageName);

type EntityType =
  | Page
  | PageGroup
  | Category
  | OfferSource
  | TrafficSource
  | FunnelCondition
  | Funnel
  | FunnelGroup;

export const getActiveEntities = <T extends { filter: Function }>(
  entities: T
): T => {
  if (Array.isArray(entities)) {
    return (entities as T).filter(
      (item: EntityType) => !defined(item.status) || item.status === 'active'
    );
  }
  return entities;
};

export const getNotDeletedEntities = <T extends { filter: Function }>(
  entities: T
): T => {
  if (Array.isArray(entities)) {
    return (entities as T).filter(
      (item: EntityType) =>
        !defined(item.status) ||
        item.status === 'active' ||
        item.status === 'archived'
    );
  }
  return entities;
};

export const getDeletedEntities = <T extends { filter: Function }>(
  entities: T
): T => {
  if (Array.isArray(entities)) {
    return (entities as T).filter(
      (item: EntityType) => item.status === 'deleted'
    );
  }
  return entities;
};

let trafficSourceSelectTimeout: any = null;
export const onTrafficSourceSelectChange = (
  value: string[],
  handleStateValuesChange: Function,
  handleReportingFilterValuesChange: Function,
  fetchReportings: (force?: boolean) => Promise<void>
) => {
  handleStateValuesChange('filterBySource', value);
  clearTimeout(trafficSourceSelectTimeout);
  trafficSourceSelectTimeout = setTimeout(async () => {
    await handleReportingFilterValuesChange('filters', [
      {
        attribute: 'Third Parties: Traffic Source',
        whitelistFilters: value.includes('-1') ? [] : value
      }
    ]);
    await handleReportingFilterValuesChange(
      'includeAssetsWithoutTraffic',
      value.includes('-1') || !value.length
    );
    await fetchReportings();
    clearTimeout(trafficSourceSelectTimeout);
  }, 1000);
};

export const withIncrementedVersion = <T extends { meta?: Meta }>(
  model: T,
  latestVersion?: number
): T => ({
  ...model,
  meta: {
    ...(model?.meta || {}),
    version: (latestVersion || model?.meta?.version || 0) + 1
  }
});

/**
 * @param localVersion
 * @param serverVersion
 * @returns {Boolean}
 */
export const serverVersionIsAheadOfLocal = (
  localVersion?: number,
  serverVersion?: number
) => {
  if (!serverVersion || !localVersion) {
    return false;
  }
  return serverVersion > localVersion;
};

export const getFunnelWithDefaultNodes = (funnel: Funnel) => {
  if (funnel.nodes?.length) {
    return funnel;
  }
  funnel.nodes = [getTrafficNode({})];
  return funnel;
};

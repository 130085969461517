import { Component } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DEBOUNCE_TIME } from 'constants/modal';

export class ReactiveComponent extends Component {
  constructor(props) {
    super(props);
    this.unsubscribe$ = new Subject();
  }

  componentWillUnmount() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export class ReactiveValidateComponent extends ReactiveComponent {
  validate$ = new Subject();

  componentDidMount() {
    this.setValidateSubscribe();
  }

  setValidateSubscribe = () => {
    this.validate$
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.validate());
  };

  setTouchedForm = () => {
    if (!this.state.isTouchedForm) {
      this.setState(state => ({
        ...state,
        isTouchedForm: true
      }));
    }
  };
}

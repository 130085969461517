import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import { CONFIRM_MODAL } from 'constants/modal';
import { FormContextModal } from 'types/modal';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import { ContentWrapper } from 'components/Parts/Content';
import { ConfirmModalProps } from 'types/ModalForms/confirm';
import { SetButtonGroupProps } from 'types/dynamicSideBar';

class ConfirmForm extends React.Component<
  ConfirmModalProps & {
    setButtonGroupProps: SetButtonGroupProps;
  },
  {}
> {
  componentDidMount() {
    this.props.setButtonGroupProps({
      showOk: true,
      showCancel: true,
      okText: this.props.context.okText || `Yes`,
      cancelText: this.props.context.cancelText || `No`,
      onOkClicked: this.props.context.onConfirm,
      onCancelClicked: () => {
        if (this.props.context.onCancel) {
          this.props.context.onCancel();
        }
      }
    });
  }
  render() {
    return (
      <ContentWrapper padding="15px 30px 30px 30px">
        <>{this.props.context.content}</>
      </ContentWrapper>
    );
  }
}

class ConfirmFormWrapper extends React.Component<ConfirmModalProps, {}> {
  static defaultProps: Partial<ConfirmModalProps> = {
    context: {}
  };

  render() {
    return (
      <DynamicSideBar
        loading={{
          ok: this.props.context.loading
        }}
        isOpen={this.props.isOpen!}
        zIndex={this.props.context?.zIndex}
        onClose={this.props.closeSelf!}
        dataPortalKey="confirm"
        defaultOpenSideBarKey="confirmForm"
        sideBars={[
          {
            key: 'confirmForm',
            width: this.props.context.width || 450,
            title: this.props.context.title,
            hasHeader: this.props.context.hasHeader,
            render: ({ setButtonGroupProps }) => {
              return (
                <ConfirmForm
                  {...this.props}
                  setButtonGroupProps={setButtonGroupProps}
                />
              );
            }
          }
        ]}
      />
    );
  }
}

export const ConfirmSidebar = () => (
  <SidebarsConsumer>
    {({ isOpenSidebar, getContextSidebar, closeSidebar }: SidebarProps) => {
      return (
        <ConfirmFormWrapper
          isOpen={isOpenSidebar(CONFIRM_MODAL)}
          closeSelf={() => closeSidebar(CONFIRM_MODAL)}
          context={getContextSidebar(CONFIRM_MODAL) as FormContextModal}
        />
      );
    }}
  </SidebarsConsumer>
);

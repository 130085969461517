import { apiAction } from './api';
import { setTableCols } from './table';
import { FUNNELGROUPS, VIEW } from '../types';
import { FUNNELGROUPS as API } from 'api/axios';
import { shouldFetchReporting } from '../selectors';
import { Dispatch } from 'redux';
import { FunnelGroup } from '../../model/funnelGroup';
import { DeleteList } from '../../model/deleteList';
import { StatusEnum } from 'api/types';
import { DrilldownReport } from '../../model/drilldownReport';
import { CompositeStore } from '../../types/redux/store';
import { makeNewRow, updatedRows } from 'utils/table';
import { DrilldownReportParams } from '../../model/drilldownReportParams';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { FunnelGroupInfo } from 'model/funnelGroupInfo';

const setFunnelGroupList = (payload: FunnelGroup[] = []) => {
  return {
    type: FUNNELGROUPS.SET_LIST,
    payload
  };
};

const setFunnelGroupInfo = (payload: FunnelGroupInfo[] = []) => {
  return {
    type: FUNNELGROUPS.SET_INFO,
    payload
  };
};

const setFunnelGroup = (payload: FunnelGroup) => {
  return {
    type: FUNNELGROUPS.ADD_SINGLE,
    payload
  };
};

const setExistedFunnelGroup = (payload: FunnelGroup) => {
  return {
    type: FUNNELGROUPS.ADD_EXISTED_SINGLE,
    payload
  };
};

const setReportingsData = (data = {}, params: DrilldownReportParams) => {
  return {
    type: FUNNELGROUPS.SET_REPORTING,
    payload: { data, params }
  };
};

const funnelGroupArchive = (data: string = '') => {
  return {
    type: FUNNELGROUPS.ARCHIVE,
    payload: { data }
  };
};

const funnelGroupDelete = (data: string[]) => {
  return {
    type: FUNNELGROUPS.DELETE,
    payload: { data }
  };
};

const funnelGroupUpdate = (data: DrilldownReportRow[]) => {
  return {
    type: FUNNELGROUPS.UPDATE_SINGLE,
    payload: { data }
  };
};

const setShow = (payload: StatusEnum) => {
  return {
    type: FUNNELGROUPS.SET_SHOW,
    payload
  };
};

const setFunnelGroupRow = (data: DrilldownReportRow) => {
  return {
    type: FUNNELGROUPS.ADD_SINGLE_ROW,
    payload: { data }
  };
};

export const getFunnelGroupById = (idCampaign: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.GET(idCampaign),
      onSuccess: (funnelGroup: FunnelGroup) =>
        setExistedFunnelGroup(funnelGroup),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.FETCH_LIST
    })
  );
};

export const fetchReportings = (params: DrilldownReportParams) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  if (shouldFetchReporting(getState().funnelGroups)) {
    return dispatch(
      apiAction({
        requestConfig: API.FETCH_REPORTING(params),
        onSuccess: (data: DrilldownReport) => setReportingsData(data, params),
        onFailure: (e: Error) => {
          throw e;
        },
        label: FUNNELGROUPS.FETCH_REPORTING
      })
    );
  }
};

export const fetchFunnelGroups = () => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(),
      onSuccess: (data: FunnelGroup[]) => setFunnelGroupList(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.FETCH_LIST
    })
  );
};

export const fetchFunnelGroupsInfo = (
  status: StatusEnum = 'not-deleted',
  includeChilds = true
) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_INFO(status, includeChilds),
      onSuccess: (data: FunnelGroupInfo[]) => setFunnelGroupInfo(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.FETCH_INFO
    })
  );
};

export const setFunnelGroupTableCols = (cols: []) => (dispatch: Dispatch) => {
  return dispatch(setTableCols(cols, VIEW.FUNNELGROUPS));
};

export const addFunnelGroup = (data: FunnelGroup) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.CREATE(data),
      onSuccess: () => {
        dispatch(setFunnelGroup(data));
        return setFunnelGroupRow(
          makeNewRow(
            {
              idCampaign: data.idCampaign,
              campaignName: data.campaignName,
              status: 'active'
            },
            'funnel'
          )
        );
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.ADD_SINGLE
    })
  );
};

export const updateFunnelGroup = (data: FunnelGroup) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const reportingRows = getState().funnelGroups.reporting.rows;
  return dispatch(
    apiAction({
      requestConfig: API.UPDATE(data),
      onSuccess: () => {
        updatedRows({
          id: data.idCampaign,
          newName: data.campaignName,
          rows: reportingRows
        }).then((res: any) => {
          dispatch(funnelGroupUpdate(res));
        });
        return fetchFunnelGroups();
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.UPDATE_SINGLE
    })
  );
};

export const duplicateFunnelGroup = (oldData: FunnelGroup) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.DUPLICATE(oldData),
      onSuccess: (data: FunnelGroup) => {
        dispatch(setFunnelGroup(data));
        return setFunnelGroupRow(
          makeNewRow(
            {
              idCampaign: data.idCampaign,
              campaignName: data.campaignName,
              status: 'active',
              idFunnel: '',
              funnelName: ''
            },
            'funnel'
          )
        );
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.ADD_SINGLE
    })
  );
};

export const archiveFunnelGroup = (data: FunnelGroup) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.ARCHIVE(data),
      onSuccess: () => funnelGroupArchive(data.idCampaign),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.ARCHIVE
    })
  );
};

export const deleteFunnelGroup = (data: DeleteList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => funnelGroupDelete(data.entries),
      onFailure: (e: Error) => {
        throw e;
      },
      label: FUNNELGROUPS.DELETE
    })
  );
};

export const setCampaignShow = (show: StatusEnum) => (dispatch: Dispatch) => {
  return dispatch(setShow(show));
};

import React, { PropsWithChildren } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import className from 'utils/style/className';

const { blockClassName, getClass } = className('c-subscriptionAlert');

const SubscriptionAlert = ({ children, type }: PropsWithChildren<{ type: 'info' | 'warning' }>) => {
  return (
    <div className={getClass('wrapper', type)}>
      <p>{children} </p>
      <Link className={getClass('link')} to="/subscriptionbilling">Manage my subscription</Link>
    </div>
  );
};

export default SubscriptionAlert;

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import TrafficSourceDeleteForm from 'components/Forms/TrafficSourceDelete';
import { deleteTrafficSource } from 'redux/actions/trafficsources';
import { ModalsConsumer } from '../context';
import { TRAFFIC_SOURCE_DELETE_MODAL } from 'constants/modal';
import { ModalProps } from '../types';
import { TrafficSourceDeleteModalProps } from 'types/ModalForms/trafficSourceDelete';
import { getNameModal } from '../../../utils/modals'
import { FormContextModal } from '../../../types/modal';
import { AnyObject } from 'types';

const TrafficSourceDeleteModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  contextModal
}: TrafficSourceDeleteModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    width={547}
    destroyOnClose
    title={`Delete Traffic Source ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['traffic-source-delete']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <TrafficSourceDeleteForm
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        contextModal={contextModal}
      />
    )}
  />
);

const ConnectedTrafficSourceDeleteModal = ({ deleteTrafficSource }: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <TrafficSourceDeleteModal
            isOpen={isOpenModal(TRAFFIC_SOURCE_DELETE_MODAL)}
            onSubmit={deleteTrafficSource}
            contextModal={getContextModal(TRAFFIC_SOURCE_DELETE_MODAL) as FormContextModal}
            closeSelf={() => closeModal(TRAFFIC_SOURCE_DELETE_MODAL)}
          />
        </>
      )}
    </ModalsConsumer>
  )
}

export default connect(
  null,
  { deleteTrafficSource }
)(ConnectedTrafficSourceDeleteModal);

import React, { createRef } from 'react';
import Messages from 'components/Messages';
import {
  ReactiveValidateComponent,
  ReactiveValidateState
} from 'utils/reactive/generic';
import { AnyObject, MessageProps, SelectOption } from 'types';
import { LoadingProps } from 'types/loading';
import './style.scss';
import Icon from 'components/Icons';
import { SimpleFlowsFormProps } from 'types/ModalForms/simpleFlows';
import GeneralSettings from './components/GeneralSettings';
import ConfigurePaths from './components/ConfigurePaths';
import ConfigureRules from './components/ConfigureRules';
import ConfigureRoutingToPaths from './components/ConfigureRoutingToPaths';
import {
  FormContentWrapper,
  FormHelpSection,
  FormSectionBox
} from 'components/Parts/Content';
import {
  SIMPLE_FLOWS_FORM_ADVANCED_FLOW_SETTINGS_TAB,
  SIMPLE_FLOWS_FORM_CONFIGURE_PATHS_TAB,
  SIMPLE_FLOWS_FORM_CONFIGURE_ROUTING_TAB,
  SIMPLE_FLOWS_FORM_CONFIGURE_RULES_TAB,
  SIMPLE_FLOWS_FORM_GET_REDIRECT_LINKS_TAB,
  SIMPLE_FLOWS_FORM_HELP_TAB,
  SIMPLE_FLOWS_FORM_PAGE_ACTION_LINKS_TAB,
  SIMPLE_FLOWS_FORM_TAB
} from 'constants/dynamicSidebar';
import {
  asyncVersionConfirmSidebar,
  getVisibilityByActiveTab
} from 'utils/dynamic-sidebar';
import { SimpleFlow } from 'model/simpleFlow';
import { SimpleFlowPath } from 'model/simpleFlowPath';
import { SimpleFlowRule } from 'model/simpleFlowRules';
import { generateEntityId } from 'utils/id/generator';
import { SimpleFlowRoutingEntity } from 'model/simpleFlowRouting';
import { defined } from 'utils/define';
import {
  getActiveEntities,
  getFunnelWithDefaultNodes,
  withIncrementedVersion
} from 'utils/model';
import { Funnel } from 'model/funnel';
import { Input, Label, Select } from 'components/Parts/Inputs';
import { moveToFirstByFunc, sortByName } from 'utils/sort';
import { disabledIPs, IPs, systemSettingsDefault } from 'constants/view';
import { AddRemoveGroup } from 'components/Parts/Groups';
import { TrafficSource } from 'model/trafficSource';
import {
  countDecimals,
  removeCommaOrMultipleDot,
  removeNonDigits
} from 'utils/string';
import { ValidationRule } from 'utils/validation/types';
import validateForm, { required } from 'utils/validation';
import {
  allOnActionNumbers,
  getConditionNode,
  getFunnelConnection,
  getPageGroupNode,
  getRotatorNode,
  getTrafficNode
} from 'constants/builder';
import { FunnelNode } from 'model/funnelNode';
import { FunnelConnection } from 'model/funnelConnection';
import EntranceLink from '../Builder/EntranceLink';
import { addCategoryToOptions } from 'utils/select';
import { RequestIdleCallback } from 'utils/performance';
import { DomainEntry } from 'model/models';
import { getCorrectActionNumber, getPageActionLink } from 'utils/linkJs';
import { isCopyByContextModal } from 'utils/modals';
import { SidebarProps } from 'types/sidebars';
import { SidebarContext } from 'components/SideBars/context';
import CodeSnippet from 'components/CodeSnippet';

interface State extends ReactiveValidateState {
  model: SimpleFlow;
  funnelModel: Funnel;
  anotherModel: AnyObject;
  ipAnonymizers: SelectOption[];
  showErrorTooltip: boolean;
  isNew: boolean;
  idCondition: string;
  selectedFunnelNode: FunnelNode;
  isEntranceLinkDisabled: boolean;
  updatedIds: string[];
  domain: string;
  actionNumber: string;
  trafficOverrides: Funnel.IncomingCostOverrides[];
}

class SimpleFlows extends ReactiveValidateComponent<
  SimpleFlowsFormProps & MessageProps & LoadingProps,
  State
> {
  state: State = {
    model: {
      flowName: '',
      idFlow: generateEntityId(),
      idFunnelGroup: '',
      defaultCost: '',
      paths: {},
      rules: {},
      routings: {}
    },
    funnelModel: {
      nodes: [],
      connections: [],
      idCampaign: '',
      idFunnel: '',
      status: 'active',
      funnelName: '',
      idIndirectFunnels: [],
      incomingCostOverrides: {}
    },
    anotherModel: {
      ipAnonymizer: ''
    },
    isEntranceLinkDisabled: false,
    ipAnonymizers: [...IPs],
    showErrorTooltip: false,
    isTouchedForm: false,
    validationErrors: {},
    isNew: true,
    idCondition: generateEntityId(),
    selectedFunnelNode: {} as any,
    updatedIds: [],
    domain: '',
    actionNumber: '1',
    trafficOverrides: [{ source: '', value: '' }]
  };
  elRefs = {
    actionURL: createRef<HTMLDivElement>()
  };
  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  async componentDidMount() {
    await this.props.setSidebarLoading(true);

    this.props.setButtonGroupProps({
      showSave: true,
      showCancel: true,
      saveText: isCopyByContextModal(this.props.contextModal)
        ? 'DUPLICATE'
        : 'SAVE',
      onSaveClicked: this.handleSubmit,
      onCancelClicked: this.handleClose,
      cancelText: 'DISCARD'
    });

    await this.props.fetchFunnelGroupsInfo();
    await this.handleState('domain', this.props.domain);
    this.props.setPathUpdateCallback(this.onPathUpdate);
    this.props.setRuleUpdateCallback(this.onRuleUpdate);

    const id = this.props.contextModal?.entityId!;

    if (!id) {
      this.setDefaultState();
    } else {
      this.props.setForCopyIdsProps({
        'Flow ID': id
      });
      try {
        await this.props.getFunnelById(id);
        await this.setDefaultData(id);
        await Promise.all([
          this.props.fetchPageGroups('active'),
        ]);
        await this.handleState('isNew', false);
        await this.props.setSidebarLoading(false);
      } catch (e) {
        this.setDefaultState();
      }
    }

    await Promise.all([
      this.props.fetchDomains(),
      this.props.fetchLandersInfo(),
      this.props.fetchOffersInfo()
    ]);
  }

  setDefaultState = async () => {
    await this.addDefaultPath();
    await this.addDefaultRule();
    await this.handleState('isEntranceLinkDisabled', true);
    await this.props.setSidebarLoading(false);
  };

  componentDidUpdate(prevProps: SimpleFlowsFormProps, prevState: State) {
    if (prevState.model.paths.length !== this.state.model.paths.length) {
      this.props.onUpdatePathsList(Object.values(this.state.model.paths));
    }
    if (prevState.model.rules.length !== this.state.model.rules.length) {
      this.props.onUpdateRulesList(Object.values(this.state.model.rules));
    }

    if (
      this.state.isEntranceLinkDisabled !== prevState.isEntranceLinkDisabled
    ) {
      this.props.setTabDisabledState(
        SIMPLE_FLOWS_FORM_GET_REDIRECT_LINKS_TAB,
        this.state.isEntranceLinkDisabled
      );
    }

    if (this.props.activeTab !== prevProps.activeTab) {
      if (this.props.activeTab === SIMPLE_FLOWS_FORM_GET_REDIRECT_LINKS_TAB) {
        this.handleState(
          'selectedFunnelNode',
          (this.state.funnelModel.nodes || []).find(n => n.nodeType === 'root')!
        );
        this.props.fetchTrafficSources();
      }
    }
  }

  static getDerivedStateFromProps(props: SimpleFlowsFormProps, state: State) {
    if (
      (defined(props.userSettings) && state.anotherModel.domain === '') ||
      state.anotherModel.ipAnonymizer === ''
    ) {
      const ipAnonymizer = defined(state.funnelModel.ipAnonymizer)
        ? state.funnelModel.ipAnonymizer
        : props.userSettings.ipAnonymizer;
      return {
        anotherModel: {
          ...state.anotherModel,
          ipAnonymizer: ipAnonymizer
        },
        ipAnonymizers: state.ipAnonymizers.map(item =>
          item.value === ipAnonymizer
            ? { value: item.value, label: `System Default (${item.label})` }
            : item
        )
      } as Pick<State, 'ipAnonymizers' | 'anotherModel'>;
    }

    return null;
  }

  setDefaultData = async (id: string) => {
    const data = { ...this.props.funnelsArray[id] };
    const idCondition = (data.nodes || []).find(n => n.nodeType === 'condition')
      ?.nodeConditionParams?.idCondition!;

    if (isCopyByContextModal(this.props.contextModal)) {
      data.funnelName = this.props.contextModal.copyName!;
      data.status = 'active';
    }

    await this.props.getConditionById(idCondition);
    await this.handleState('model', {
      ...this.state.model,
      idFlow: data.idFunnel,
      idFunnelGroup: data.idCampaign,
      flowName: data.funnelName,
      defaultCost: data.defaultCost || '',
      rules: data.meta?.simpleFlow?.rules!,
      paths: data.meta?.simpleFlow?.paths!,
      routings: data.meta?.simpleFlow?.routings!
    });
    await this.handleState('idCondition', idCondition);
    await this.handleState('funnelModel', {
      ...this.state.funnelModel,
      ...data
    });

    if (defined(data.incomingCostOverrides)) {
      await this.handleState(
        'trafficOverrides',
        Object.entries(data.incomingCostOverrides || {}).reduce(
          (acc: Funnel.IncomingCostOverrides[], [key, value]) => [
            ...acc,
            { source: key, value: value }
          ],
          []
        )
      );
    }
  };

  setIncomingTrafficCostOverrides = async () => {
    await this.handleState('funnelModel', {
      ...this.state.funnelModel,
      incomingCostOverrides: this.state.trafficOverrides.reduce(
        (acc: {}, item: AnyObject) =>
          !!item.source && !!item.value
            ? {
                ...acc,
                [item.source]: item.value
              }
            : acc,
        {}
      )
    });
  };

  validationRules = (): { [key: string]: ValidationRule } => ({
    idFunnelGroup: {
      field: 'idFunnelGroup',
      validations: [required],
      tab: SIMPLE_FLOWS_FORM_TAB
    },
    flowName: {
      field: 'flowName',
      validations: [required],
      tab: SIMPLE_FLOWS_FORM_TAB
    }
  });

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {}, showErrorTooltip: false });
      const [validationErrors] = validateForm(
        this.state.model,
        Object.values(this.validationRules())
      );
      this.setState((state: State) => ({
        ...state,
        validationErrors
      }));
    }
  };

  handleClose = () => {};

  handleSubmit = async (e: Event) => {
    if (defined(e)) {
      e.preventDefault();
    }
    this.props.startLoading('save');

    if (await this.performCreateOrUpdate()) {
      this.props.stopLoading('all');
      this.props.closeSelf();
      this.props.resetDisabledTabs();
    }

    this.props.stopLoading('all');
    return false;
  };

  getFunnelNodesConnections = (funnel: Funnel, conditionName: string): Funnel => {
    // nodes
    const nodes = [...(funnel.nodes || [])];
    const nodesObj = nodes.reduce((acc: { [key: string]: FunnelNode }, crr) => {
      acc[crr.idNode] = crr;
      return acc;
    }, {});
    const trafficNode = nodes.shift() || getTrafficNode({});
    const conditionNode = getConditionNode({
      idNode: nodes.shift()?.idNode,
      idCondition: this.state.idCondition,
      nodeName: conditionName,
      posY: 250
    });
    const ruleRotatorNodes = Object.values(this.state.model.rules).map((r, idx) =>
      getRotatorNode({
        nodeName: r.ruleName,
        idNode: nodesObj[r.ruleId]?.idNode || r.ruleId,
        posY: conditionNode.posY! + 120,
        posX: idx * 120
      })
    );
    const pathRotatorNodes = Object.values(this.state.model.paths).map((p, idx) =>
      getRotatorNode({
        nodeName: p.pathName,
        idNode: nodesObj[p.pathId]?.idNode || p.pathId,
        posY: conditionNode.posY! + 240,
        posX: idx * 120
      })
    );
    funnel.nodes = [trafficNode, conditionNode, ...ruleRotatorNodes, ...pathRotatorNodes];
    for (const path of Object.values(this.state.model.paths)) {
      path.groups.forEach((pageGroup, idx) => {
        funnel.nodes!.push(
          getPageGroupNode({
            idNode: nodesObj[pageGroup.idPageGroup]?.idNode || pageGroup.idPageGroup,
            nodeName: pageGroup.pageGroupName,
            idPageGroup: nodesObj[pageGroup.idPageGroup]?.idNode || pageGroup.idPageGroup,
            nodeType: pageGroup.pageType === 'lander' ? 'landerGroup' : 'offerGroup',
            posY: conditionNode.posY! + 360 + idx * 60
          })
        );
      });
    }

    // connections
    const connections = [...(funnel.connections || [])];
    const connectionsObject = connections.reduce((acc: { [key: string]: FunnelConnection }, crr) => {
      acc[`${crr.idSourceNode}-${crr.idTargetNode}`] = crr;
      return acc;
    }, {});
    funnel.connections = [];
    const connectionkey = (idSourceNode: string, idTargetNode: string) => `${idSourceNode}-${idTargetNode}`;

    funnel.connections.push(
      connectionsObject[connectionkey(trafficNode.idNode, conditionNode.idNode)] || {
        idConnection: generateEntityId(),
        idSourceNode: trafficNode.idNode,
        idTargetNode: conditionNode.idNode,
        connectionRotatorParams: { weight: 1 }
      }
    );

    ruleRotatorNodes.forEach((roleRotatorNode, idx) => {
      funnel.connections!.push({
        idConnection: generateEntityId(),
        idSourceNode: conditionNode.idNode,
        idTargetNode: roleRotatorNode.idNode,
        connectionConditionParams: { onRouteNumber: idx }
      });
    });

    ruleRotatorNodes.forEach(roleRotatorNode => {
      this.state.model.routings[roleRotatorNode.idNode].forEach(routingEntity => {
        funnel.connections!.push({
          idConnection: generateEntityId(),
          idSourceNode: roleRotatorNode.idNode,
          idTargetNode: routingEntity.path.pathId,
          connectionRotatorParams: { weight: routingEntity.weight }
        });
      });
    });

    pathRotatorNodes.forEach(pathRotator => {
      const groups = [...this.state.model.paths[pathRotator.idNode].groups];

      if (groups.length) {
        funnel.connections!.push({
          idConnection: generateEntityId(),
          idSourceNode: pathRotator.idNode,
          idTargetNode: groups[0].idPageGroup,
          connectionRotatorParams: { weight: 1 }
        });
        for (let i = 0; i < groups.length - 1; i++) {
          funnel.connections!.push({
            idConnection: generateEntityId(),
            idSourceNode: groups[i].idPageGroup,
            idTargetNode: groups[i + 1].idPageGroup,
            connectionPageParams: { onActionNumbers: allOnActionNumbers }
          });
        }
      }
    });

    return funnel;
  };

  addUpdatePageGroups = async (funnel: Funnel) => {
    for (const path of Object.values(this.state.model.paths)) {
      for (const group of path.groups) {
        if (!this.props.pageGroupsArray[group.idPageGroup]) {
          await this.props.addPageGroup(
            {
              ...group,
              restrictToFunnelId: funnel.idFunnel
            },
            false
          );
        } else {
          if (this.state.updatedIds.includes(group.idPageGroup)) {
            await this.props.updatePageGroup(
              {
                ...group,
                restrictToFunnelId: funnel.idFunnel
              },
              false
            );
          }
        }
      }
    }
  };

  performCreateOrUpdate = async () => {
    await this.setTouchedForm();
    await this.validate();
    await this.setIncomingTrafficCostOverrides();

    if (this.props.setTabsError) {
      this.props.setTabsError(
        Object.keys(this.state.validationErrors || {}).map(
          k => this.validationRules()?.[k]?.tab || ''
        )
      );
    }

    for (const pathId in this.state.model.paths) {
      if (!this.state.model.paths[pathId]?.groups.length) {
        this.props.showMessage(
          Messages.warning(
            `The path "${this.state.model.paths[pathId].pathName}" should have at least one page group.`
          )
        );
        return false;
      }
    }
    for (const ruleId in this.state.model.rules) {
      if (
        !defined(this.state.model.routings[ruleId]) ||
        !this.state.model.routings[ruleId].length
      ) {
        this.props.showMessage(
          Messages.warning(
            'All rules must have at least one destination path configured'
          )
        );
        return false;
      }
    }

    let funnel: Funnel = {
      ...this.state.funnelModel,
      funnelName: this.state.model.flowName,
      idCampaign: this.state.model.idFunnelGroup,
      idFunnel: this.state.model.idFlow,
      defaultCost: this.state.model.defaultCost,
      funnelType: 'flow',
      meta: {
        ...(this.props.funnelsArray?.[this.state.model.idFlow]?.meta || {}),
        isFlow: true,
        simpleFlow: {
          ...this.state.model,
          rules: this.state.model.rules,
          paths: this.state.model.paths
        }
      }
    };
    const conditionName = 'Dynamic Routing by Rules';
    const conditionRoutes = moveToFirstByFunc(
      Object.values(this.state.model.rules).map(r => r.rule),
      r => r.routeName === 'Default Route'
    );
    const updateFlowProcess = async () => {
      await this.addUpdatePageGroups(funnel);
      await this.props.updateCondition({
        ...this.props.conditionArray[this.state.idCondition],
        routes: conditionRoutes
      });
      funnel = this.getFunnelNodesConnections(funnel, conditionName);
    };

    if (!Object.keys(this.state.validationErrors).length) {
      try {
        if (isCopyByContextModal(this.props.contextModal)) {
          await updateFlowProcess();
          await this.props.duplicateFunnel({
            ...funnel,
            meta: { ...funnel.meta, version: 1 }
          });
        } else if (!this.state.isNew) {
          await this.props.getFunnelById(funnel.idFunnel);
          if (
            funnel?.meta &&
            funnel.meta?.version !==
              this.props.funnelsArray[funnel.idFunnel]?.meta?.version
          ) {
            const sidebar = this.context as SidebarProps;
            try {
              await asyncVersionConfirmSidebar(sidebar);
              await updateFlowProcess();
              await this.props.updateFunnel(
                withIncrementedVersion(
                  funnel,
                  this.props.funnelsArray[funnel.idFunnel]?.meta?.version
                )
              );
            } catch (e) {
              this.props.stopLoading('all');
              this.props.closeSelf();
              return false;
            }
          } else {
            await updateFlowProcess();
            await this.props.updateFunnel(withIncrementedVersion(funnel));
          }
        } else {
          funnel = withIncrementedVersion(funnel);
          await this.props.addFunnel(getFunnelWithDefaultNodes(funnel));
          await this.props.addCondition({
            idCondition: this.state.idCondition,
            conditionName,
            restrictToFunnelId: funnel.idFunnel,
            status: 'active',
            routes: conditionRoutes
          });
          await this.addUpdatePageGroups(funnel);
          funnel = this.getFunnelNodesConnections(funnel, conditionName);
          await this.props.updateFunnel(funnel);
        }

        return true;
      } catch (e) {
        this.props.stopLoading('save');
        return false;
      }
    } else {
      this.props.stopLoading('all');
    }

    return false;
  };

  handleUpdatedIds = (ids: string[]) => {
    this.handleState('updatedIds', [...this.state.updatedIds, ...ids]);
  };

  addDefaultPath = () => {
    const pathId = generateEntityId();
    this.handleState('model', {
      ...this.state.model,
      paths: {
        [pathId]: {
          pathId,
          pathName: 'Default Path',
          groups: [],
          isDefault: true
        }
      }
    });
  };

  addDefaultRule = () => {
    const ruleId = generateEntityId();
    this.handleState('model', {
      ...this.state.model,
      rules: {
        [ruleId]: {
          ruleId,
          ruleName: 'Default (all unmatched traffic)',
          isDefault: true,
          rule: {
            operator: 'or',
            groups: [],
            routeName: 'Default Route'
          }
        }
      }
    });

    if (this.state.idCondition) {
      this.handleUpdatedIds([this.state.idCondition]);
    }
  };

  onPathUpdate = (path: SimpleFlowPath) => {
    if (!this.state.model.paths[path.pathId]) {
      this.handleState('isEntranceLinkDisabled', true);
    }
    this.handleState('model', {
      ...this.state.model,
      paths: {
        ...this.state.model.paths,
        [path.pathId]: path
      }
    });

    if (!this.state.isNew) {
      RequestIdleCallback(() => {
        this.handleUpdatedIds(
          path.groups.map(pageGroup => pageGroup.idPageGroup)
        );
      });
    }
  };

  onRuleUpdate = (rule: SimpleFlowRule) => {
    this.handleState('model', {
      ...this.state.model,
      rules: {
        ...this.state.model.rules,
        [rule.ruleId]: rule
      }
    });

    if (this.state.idCondition) {
      this.handleUpdatedIds([this.state.idCondition]);
    }
  };

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  onGeneralSettingsChange = async <T extends SimpleFlow, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    this.handleState('model', {
      ...this.state.model,
      [key]: value
    });
  };

  deletePath = (path: SimpleFlowPath) => {
    const paths = { ...this.state.model.paths };
    delete paths[path.pathId];
    const routings = { ...this.state.model.routings };
    Object.keys(this.state.model.routings).forEach(ruleId => {
      routings[ruleId] = routings[ruleId].filter(
        p => p.path.pathId !== path.pathId
      );
    });

    this.handleState('model', {
      ...this.state.model,
      paths,
      routings
    });
    this.handleState('isEntranceLinkDisabled', true);
  };

  deleteRule = (rule: SimpleFlowRule) => {
    const rules = { ...this.state.model.rules };
    delete rules[rule.ruleId];
    const routings = { ...this.state.model.routings };
    delete routings[rule.ruleId];

    this.handleState('model', {
      ...this.state.model,
      rules,
      routings
    });

    if (this.state.idCondition) {
      this.handleUpdatedIds([this.state.idCondition]);
    }
  };

  addEditRoute = (ruleId: string, routingEntity: SimpleFlowRoutingEntity) => {
    const routingEntities = this.state.model.routings?.[ruleId] || [];
    const isNew =
      routingEntities.findIndex(
        re => re.path.pathId === routingEntity.path.pathId
      ) === -1;

    this.handleState('model', {
      ...this.state.model,
      routings: {
        ...this.state.model.routings,
        [ruleId]: isNew
          ? [...routingEntities, routingEntity]
          : routingEntities.map(re =>
              re.path.pathId === routingEntity.path.pathId ? routingEntity : re
            )
      }
    });
  };

  deleteRoute = (ruleId: string, routeId: string) => {
    const routingEntities = this.state.model.routings?.[ruleId] || [];
    this.handleState('model', {
      ...this.state.model,
      routings: {
        ...this.state.model.routings,
        [ruleId]: routingEntities.filter(re => re.path.pathId !== routeId)
      }
    });
  };

  removeTrafficOverride = async (id: number) => {
    await this.handleState(
      'trafficOverrides',
      this.state.trafficOverrides.filter((_: any, idx: number) => idx !== id)
    );
  };

  addNewTrafficOverride = async () => {
    await this.setState((state: State) => ({
      ...state,
      trafficOverrides: [...state.trafficOverrides, { source: '', value: '' }]
    }));
  };

  handleTrafficOverrides = (
    key: 'source' | 'value',
    value: string,
    idx: number
  ) => {
    this.handleState(
      'trafficOverrides',
      (this.state.trafficOverrides || []).map((to: any, tIdx: number) =>
        tIdx === idx
          ? {
              ...to,
              [key]: value
            }
          : to
      )
    );
  };

  validateClassName = (field: string) =>
    !!this.state.validationErrors[field] ? 'has-error' : '';

  validateTooltip = (field: string) => {
    return !!this.state.validationErrors[field]
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  getEntranceLinkPages = () => {
    return addCategoryToOptions(
      getActiveEntities([...this.props.landers, ...this.props.offers]).map(
        page => ({
          id: page.idPage,
          name: page.pageName,
          category: page.idCategory || '',
          data: {
            url: page.baseURL
          }
        })
      ),
      [...this.props.categories.landers, ...this.props.categories.offers]
    );
  };

  getSortedFunnelNodes = (funnelNodes: FunnelNode[]) => {
    const sortedNodes: FunnelNode[] = [];
    const trafficNode = funnelNodes.find(
      funnelNode => funnelNode.nodeType === 'root'
    );
    sortedNodes.push({
      ...trafficNode!,
      nodeName: 'Start of this flow'
    });
    funnelNodes.forEach(funnelNode => {
      if (
        funnelNode.nodeType === 'rotator' &&
        defined(this.state.model.paths[funnelNode.idNode])
      ) {
        sortedNodes.push(funnelNode);
      }
    });
    return sortedNodes;
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <div className="cform-simpleFlows">
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_TAB,
            this.props.activeTab
          )}
        >
          <GeneralSettings
            funnelGroupsArray={this.props.funnelGroupsArray}
            model={this.state.model}
            onChange={this.onGeneralSettingsChange}
            validateClassName={this.validateClassName}
            validateTooltip={this.validateTooltip}
          />
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_CONFIGURE_PATHS_TAB,
            this.props.activeTab
          )}
        >
          <ConfigurePaths
            openSidebar={this.props.openSidebar}
            setPathEditorModel={this.props.setPathEditorModel}
            onDeletePath={this.deletePath}
            paths={moveToFirstByFunc(
              Object.values(this.state.model.paths),
              path => !!path.isDefault
            )}
          />
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_CONFIGURE_RULES_TAB,
            this.props.activeTab
          )}
        >
          <ConfigureRules
            openSidebar={this.props.openSidebar}
            setRuleEditorModel={this.props.setRuleEditorModel}
            onDeleteRule={this.deleteRule}
            rules={moveToFirstByFunc(
              Object.values(this.state.model.rules),
              rule => !!rule.isDefault
            )}
          />
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_GET_REDIRECT_LINKS_TAB,
            this.props.activeTab
          )}
        >
          <EntranceLink
            isFlow={true}
            showNodeId={false}
            funnel={this.state.funnelModel}
            funnelNodes={this.getSortedFunnelNodes(
              this.state.funnelModel.nodes || []
            )}
            funnelNode={this.state.selectedFunnelNode}
            trafficSources={this.props.trafficSources}
            userSettings={this.props.userSettings}
            openTsOnMount={false}
            isChangedFunnel={this.state.isEntranceLinkDisabled}
            domains={this.props.domains}
            switch="url"
            onSelectDestination={(value: FunnelNode) => {
              this.handleState('selectedFunnelNode', value);
            }}
            getLanderById={this.props.getLanderById}
            getOfferById={this.props.getLanderById}
            pagesName="Page"
          />
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_PAGE_ACTION_LINKS_TAB,
            this.props.activeTab
          )}
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <p>
              Action links are universal click-through URLs that you should use
              in your pages to send visitors to the next node. You can
              right-click action connections in the funnel builder to get URLs
              with additional default parameters.
            </p>
            <div className="flex flex-row flex-gap-15 margin-bottom-15">
              <div className="flex flex-col flex-1 cform-offer--selectDomain">
                <Label text={`Select a domain`} htmlFor="domain" />
                <Select
                  value={this.state.domain}
                  onChange={(value: string) =>
                    this.handleState('domain', value)
                  }
                  id="domain"
                  name="domain"
                  options={this.props.domains}
                  valueGetter={(option: DomainEntry) => option.domain}
                  labelGetter={(option: DomainEntry) => option.domain}
                  placeholder="Select Domain"
                  data-testid="yourJs-selectDomain"
                />
              </div>
              <div className="flex flex-col">
                <Label text="Action number" htmlFor="actionNumber" />
                <Select
                  options={allOnActionNumbers}
                  valueGetter={(option: number) => option}
                  labelGetter={(option: number) => option}
                  placeholder="Select Action Number"
                  name="actionNumber"
                  id="actionNumber"
                  data-testid="actionNumber"
                  value={this.state.actionNumber}
                  onChange={(value: string) => {
                    this.handleState(
                      'actionNumber',
                      getCorrectActionNumber(value)
                    );
                  }}
                  style={{ width: 150 }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <Label text="Action URL" htmlFor="actionURL" />
              <CodeSnippet
                data-testid="actionURL"
                placeholder="Action URL"
                forwardedRef={this.elRefs.actionURL}
                code={getPageActionLink(
                  this.state.domain,
                  this.state.actionNumber
                )}
              />
            </div>
          </FormSectionBox>
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_CONFIGURE_ROUTING_TAB,
            this.props.activeTab
          )}
        >
          <ConfigureRoutingToPaths
            routings={this.state.model.routings}
            rules={Object.values(this.state.model.rules)}
            paths={Object.values(this.state.model.paths)}
            onAddEdit={this.addEditRoute}
            onDelete={this.deleteRoute}
          />
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_ADVANCED_FLOW_SETTINGS_TAB,
            this.props.activeTab
          )}
          className="cform-simpleFlows__advancedSettings"
        >
          <FormSectionBox title="IP Anonymization Settings">
            <div className="flex flex-col flex-gap-15">
              <Label
                htmlFor="ipAnonymizer"
                text="These settings will determine how FunnelFlux
                        truncates IP data before it gets logged to your
                        analytics database, for user privacy and GDPR compliance.
                        This will not impact geo-location data or tokens used in page URLs."
              />
              <Select
                value={this.state.anotherModel.ipAnonymizer}
                id="ipAnonymizer"
                data-testid="ipAnonymizer"
                name="ipAnonymizer"
                placeholder="Select IP Anonymization Settings"
                options={[
                  ...sortByName(
                    this.state.ipAnonymizers.filter(
                      item =>
                        item.value !==
                        (this.state.anotherModel.ipAnonymizer
                          ? this.state.anotherModel.ipAnonymizer
                          : systemSettingsDefault.value)
                    ),
                    'label'
                  )
                ]}
                defaultValueFallback={{
                  label: defined(this.state.anotherModel.ipAnonymizer)
                    ? this.state.ipAnonymizers.find(
                        item =>
                          item.value === this.state.anotherModel.ipAnonymizer
                      )?.label!
                    : disabledIPs.label,
                  value: defined(this.state.anotherModel.ipAnonymizer)
                    ? this.state.anotherModel.ipAnonymizer
                    : disabledIPs.value
                }}
                valueGetter={(option: SelectOption) => option.value}
                labelGetter={(option: SelectOption) => option.label}
                onChange={(value: Funnel.IpAnonymizerEnum) =>
                  this.handleState('funnelModel', {
                    ...this.state.funnelModel,
                    ipAnonymizer: value
                  })
                }
              />
            </div>
          </FormSectionBox>

          <FormSectionBox title="Incoming Traffic Cost Override">
            <div className="flex flex-col flex-gap-15">
              <Label text='Incoming cost overrides will change the cost of all matching visitors who enter a funnel. This will not affect existing/past visitors. These overrides are higher priority than the "c" parameter in URLs/JS and can be used to update average incoming cost in real-time.' />
              <AddRemoveGroup
                data={this.state.trafficOverrides}
                className="cform-simpleFlows__advancedSettings--costOverride"
                childClassName="cform-simpleFlows__advancedSettings--costOverrideChild"
                onAddNewClicked={this.addNewTrafficOverride}
                onRemoveClicked={(_: any, idx: number) =>
                  this.removeTrafficOverride(idx)
                }
                validateField={
                  (_: any, idx: string) => ''
                  // this.validateClassName(idx)
                }
                showRemoveButton={(row: any) =>
                  !!(this.state.trafficOverrides.length > 1 || row.id)
                }
                maxScrollableHeight="100%"
                renderRow={({ source, value }: any, idx: number) => {
                  return (
                    <>
                      <div className="flex flex-row flex-gap-15">
                        <Select
                          value={source}
                          className="cform-simpleFlows__advancedSettings--costOverrideChildSelect"
                          placeholder="Select a traffic source"
                          id={`trafficSource${idx}`}
                          data-testid={`trafficSource${idx}`}
                          options={this.props.trafficSources}
                          valueGetter={(option: TrafficSource) =>
                            option.idTrafficSource
                          }
                          labelGetter={(option: TrafficSource) =>
                            option.trafficSourceName
                          }
                          onChange={(value: string) =>
                            this.handleTrafficOverrides('source', value, idx)
                          }
                        />
                        <Input
                          value={value}
                          name="trafficSourceOverrideCost"
                          data-testid={`trafficSourceOverride${idx}`}
                          placeholder="E.g. 0.01"
                          onChange={e => {
                            let value = removeNonDigits(
                              removeCommaOrMultipleDot(e.target.value)
                            );
                            if (countDecimals(Number(value)) >= 7) {
                              return;
                            }
                            this.handleTrafficOverrides('value', value, idx);
                          }}
                        />
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </FormSectionBox>
        </FormContentWrapper>
        <FormContentWrapper
          show={getVisibilityByActiveTab(
            SIMPLE_FLOWS_FORM_HELP_TAB,
            this.props.activeTab
          )}
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <FormHelpSection
              content={
                <>
                  <p>
                    Simple flows are a different visual UI for building funnels
                    in our system. They use the same backend where a funnel is
                    created, with nodes linked together in a specific pattern.
                    It's our way of making a simpler entry point for users.
                  </p>

                  <p>
                    To uses these flows, you need to configure three important
                    elements: your page sequences (paths), optional rules
                    visitors can be matched against, and lastly, routing
                    configuration for how users will go to each of your paths.
                  </p>

                  <p>
                    Because flows inherently involve rules and redirection,
                    there is currently no direct linking option. We will add
                    this later so that you can choose to direct link to a page
                    in one of your paths. Just keep in mind that this would make
                    all rules/routing config irrelevant, as you quite literaly
                    skip past it.
                  </p>

                  <p>
                    In the future we will also provide the option to convert a
                    flow to a visual funnel (irreversibly), so that you can edit
                    and extend it in our visual builder.
                  </p>
                  <p>
                    Need more help? Read our documentation{' '}
                    <a
                      href="https://help.funnelflux.pro/article/93"
                      target="_blank"
                      rel="noopener noreferrer" 
                    >
                      here
                    </a>
                  </p>
                </>
              }
              name="Simple Flow"
            />
          </FormSectionBox>
        </FormContentWrapper>
      </div>
    );
  }
}

export default Messages.injectIn(SimpleFlows);

import React from 'react';
import './style.scss';
import { LOADING } from 'constants/table';
import { LoadingOutlined } from '@ant-design/icons';

export const createMarkup = () => ({ __html: LOADING });

export const FluxLoading = (props: { show: boolean; className?: string }) =>
  props.show ? (
    <div className="c-fluxLoading">
      <LoadingOutlined className="c-fluxLoading__loader" />
    </div>
  ) : (
    <></>
  );

export const CenterLoading = ({ show }: { show: boolean }) =>
  show ? (
    <div className="c-centerLoading">
      <LoadingOutlined className="c-centerLoading__loader" />
    </div>
  ) : (
    <></>
  );

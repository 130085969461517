import { FunnelCondition } from 'model/funnelCondition';
import { FunnelConditionRule } from 'model/funnelConditionRule';
import { TrafficSource } from 'model/trafficSource';
import { RulesAttributes } from 'types/ModalForms/condition';
import { defined } from 'utils/define';
import { Option } from '../types';
import { connectionTypes } from './connectionTypes';
import { continents } from './continents';
import { countries } from './countries';
import { deviceOS } from './deviceOS';
import { deviceTypes } from './deviceTypes';
import { languages } from './languages';
import { timeZones } from './timeZones';
import { FFSelectOption } from 'uikit/types/select';
import { RuleTimeDayOfWeekParams } from 'model/ruleTimeDayOfWeekParams';

export const daysOfWeek: FFSelectOption<RuleTimeDayOfWeekParams['day']>[] = [
  {
    value: 'monday',
    label: 'Monday',
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
  },
  {
    value: 'thursday',
    label: 'Thursday',
  },
  {
    value: 'friday',
    label: 'Friday',
  },
  {
    value: 'saturday',
    label: 'Saturday',
  },
  {
    value: 'sunday',
    label: 'Sunday',
  }
];

export const months: Option[] = [
  {
    id: 1,
    name: 'January'
  },
  {
    id: 2,
    name: 'February'
  },
  {
    id: 3,
    name: 'March'
  },
  {
    id: 4,
    name: 'April'
  },
  {
    id: 5,
    name: 'May'
  },
  {
    id: 6,
    name: 'June'
  },
  {
    id: 7,
    name: 'July'
  },
  {
    id: 8,
    name: 'August'
  },
  {
    id: 9,
    name: 'September'
  },
  {
    id: 10,
    name: 'October'
  },
  {
    id: 11,
    name: 'November'
  },
  {
    id: 12,
    name: 'December'
  }
];

export const rulesAttributes: Partial<RulesAttributes> = {
  'Location: Continent': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'select',
    options: continents
  },
  'Location: Country': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'select',
    options: countries,
    valueGetter: (option: FFSelectOption) => option.value,
    labelGetter: (option: FFSelectOption) => option.label
  },
  'Location: City': {
    test: ['is', 'is not'],
    type: 'text'
  },
  'Location: Region': {
    test: ['is', 'is not'],
    type: 'text'
  },
  'Location: Timezone': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'select',
    options: timeZones,
    valueGetter: (option: FFSelectOption) => option.value,
    labelGetter: (option: FFSelectOption) => option.label
  },
  'Device: Type': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'select',
    options: deviceTypes
  },
  'Device: Brand': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  },
  /*'Device: Model': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  },*/
  'Device: OS': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'select',
    options: deviceOS
  },
  'Device: OS Version': {
    test: ['is', 'is not', '<', '>'],
    type: 'text'
  },
  'Device: Browser': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  },
  'Device: Main Language': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'select',
    options: languages,
    valueGetter: (option: FFSelectOption) => option.value,
    labelGetter: (option: FFSelectOption) => option.label
  },
  'Device: Browser Version': {
    test: ['is', 'is not'],
    type: 'text'
  },
  'Connection: IP': {
    test: [
      'is',
      'is not',
      'contains',
      'does not contain',
      //'range',
      'any in',
      'not in'
    ],
    type: 'text'
  },
  'Connection: ISP': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  },
  'Connection: Connection Type': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'select',
    options: connectionTypes
  },
  'Connection: User Agent': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  },
  'Connection: Referrer': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  },
  'Connection: Current URL': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  },
  /*'Conversion Cap: Current Visitor': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text',
    param: 'capVisitorConversionParams',
    paramKey: 'capVisitorConversionParams'
  },
  'Conversion Cap: Globally on Offer': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text',
    param: 'capOfferConversionParams',
    paramKey: 'capOfferConversionParams'
  },*/
  'Time: Date': {
    test: ['<', '<=', 'is', '>', '>='],
    type: 'date',
    param: 'timeDateParams',
    paramKey: ''
  },
  'Time: Day of Week': {
    test: ['is', 'is not'],
    type: 'select',
    options: daysOfWeek,
    valueGetter: (option: FFSelectOption) => option.value,
    labelGetter: (option: FFSelectOption) => option.label,
    param: 'timeDayOfWeekParams',
    paramKey: 'day',
    suspendSort: true
  },
  'Time: Day of Month': {
    test: ['is', 'is not'],
    type: 'select',
    options: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ],
    param: 'timeDayOfMonthParams',
    paramKey: 'day'
  },
  'Time: Month of Year': {
    test: ['is', 'is not'],
    type: 'select',
    options: months,
    param: 'timeMonthOfYearParams',
    paramKey: 'month',
    suspendSort: true,
    valueGetter: (option: Option) => option.id,
    labelGetter: (option: Option) => option.name
  },
  'Time: Time of Day': {
    //test: ['is before', 'is after', 'is between', 'is not between'],
    test: ['<', '<=', 'is', '>', '>='],
    type: 'time',
    param: 'timeOfDayParams',
    paramKey: ''
  },
  /*'Number of Total Visitors': {
    group: 'Others',
    test: ['<', '>'],
    type: 'text'
  },
  'Number of Visitors Today': {
    group: 'Others',
    test: ['<', '>'],
    type: 'text'
  },*/
  'Traffic Source': {
    test: ['is', 'is not', 'any in', 'not in'],
    type: 'resource',
    resourceName: 'trafficSources',
    valueGetter: (option: TrafficSource) => option.idTrafficSource,
    labelGetter: (option: TrafficSource) => option.trafficSourceName
  },
  'Tracking Field': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'fieldAndValue',
    param: 'trackingFieldParams',
    paramKey: 'trackingFieldParams'
  },
  'Tracking Domain': {
    test: ['is', 'is not', 'contains', 'does not contain'],
    type: 'text'
  }
};

export const getRuleParam = (rule: FunnelConditionRule) =>
  rule.hasOwnProperty('attribute') &&
  defined(rulesAttributes[rule.attribute]?.param)
    ? rulesAttributes[rule.attribute]?.param
    : 'genericParams';

export const getRuleParamKey = (rule: FunnelConditionRule) =>
  rule.hasOwnProperty('attribute') &&
  defined(rulesAttributes[rule.attribute]?.paramKey)
    ? rulesAttributes[rule.attribute]?.paramKey
    : 'values';

export const getConditionCorrectAttributeName = (item: string) => {
  if (item.match(/:/)) {
    return item.slice(item.search(/:/) + 2, item.length);
  }
  return item;
};

export const getConditionCorrectAttributeCategory = (item: string) => {
  if (item.match(/:/)) {
    return item.slice(0, item.search(/:/));
  }
  return 'Others';
};

export const getDefaultCondition = (
  restrictToFunnelId?: string
): FunnelCondition => ({
  idCondition: '',
  conditionName: '',
  status: 'active',
  routes: [
    {
      routeName: 'Default Route',
      groups: [],
      operator: 'or'
    },
    {
      routeName: 'Route 1',
      operator: 'or',
      groups: [
        {
          operator: 'or',
          rules: [{} as FunnelConditionRule]
        }
      ],  
    }
  ],
  restrictToFunnelId
});
import { ReportingsStore } from '../../types/redux/store';
import { defined } from '../../utils/define';

export const getReportingAttributes = (state: ReportingsStore) =>
  state.attributes;
export const getReportingColumns = (state: ReportingsStore) => {
  if (
    defined(state.reporting.columns) &&
    defined(state.reporting.columns.attributes)
  ) {
    return state.reporting.columns.attributes.map(item => item);
  }
  return [];
};

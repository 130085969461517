import React, { createRef, RefObject } from 'react';
import Messages from 'components/Messages';
import { ReactiveValidateComponent, ReactiveValidateState } from '../../../utils/reactive/generic';
import validateForm, { required, unique, xxxUrl } from 'utils/validation';
import { FormContentWrapper, FormHelpSection, FormSectionBox } from 'components/Parts/Content';
import { AddButton as OpenButton, CopyButton } from 'components/Parts/Buttons';
import { UNCATEGORIZED } from 'constants/modal';
import { addUrlSlash, getQueryUrlSeparate } from 'utils/url';
import { Page } from '../../../model/page';
import { generateEntityId } from '../../../utils/id/generator';
import { Category } from '../../../model/category';
import { AnyObject, MessageProps, SelectOption, StringObject } from '../../../types';
import { redirects } from '../../../constants/form';
import { trimStringPropertiesInObject, withoutWhiteSpace } from 'utils/string';
import { DUPLICATE_RECORD_CODE } from '../../../constants/errors';
import { LanderFormProps } from '../../../types/ModalForms/lander';
import { defined } from '../../../utils/define';
import { finalUrl, handleScrollToErrorElement } from '../../../utils/validation';
import { copy, getDuplicateModalSidebarContext } from 'utils/copy';
import { DomainEntry } from 'model/models';
import { getPageActionLink, getCorrectActionNumber } from 'utils/linkJs';
import { isCopyByContextModal } from 'utils/modals';
import { cloneObject } from '../../../utils/object';
import { getActiveEntities, serverVersionIsAheadOfLocal, withIncrementedVersion } from '../../../utils/model';
import { ValidationRule } from '../../../utils/validation/types';
import { asyncVersionConfirmSidebar, getVisibilityByActiveTab } from 'utils/dynamic-sidebar';
import { LoadingProps } from 'types/loading';
import {
  LANDER_FORM_TAB,
  LANDER_FORM_ADVANCED_TAB,
  LANDER_FORM_HELP_TAB,
  LANDER_FORM_JAVASCRIPT_TAB,
  LANDER_FORM_PAGE_LINKS_TAB
} from 'constants/dynamicSidebar';
import { SidebarProps } from 'types/sidebars';
import { SidebarContext } from 'components/SideBars/context';
import Icon from 'components/Icons';
import CodeSnippet from 'components/CodeSnippet';
import { InputRef } from 'antd';
import { FFField, FFInput, FFNewButton, FFRow, FFSelect, FFSwitch, FFTextarea, VisibilityWrapper } from 'uikit';
import NewDataPassing from 'components/DataPassingNew';
import './style.scss';
import className from 'utils/style/className';

const { blockClassName, getClass } = className('c-landerForm');

interface State extends ReactiveValidateState {
  pageNames: string[];
  model: Page;
  anotherModel: AnyObject;
  validationErrors: AnyObject;
  isTouchedForm: boolean;
  isDataPassingOn: boolean;
  dataPassingErrors: boolean;
  isSubmitted: boolean;
  showErrorTooltip: boolean;
  isPageLinksSectionOn: boolean;
  isJavascriptSectionOn: boolean;
  domain: string;
  actionNumber: string;
}

const defaultModelState: Page = {
  idPage: '',
  pageType: 'lander',
  pageName: '',
  baseURL: '',
  queryParams: {},
  redirectType: '301',
  idCategory: undefined,
  status: 'active',
  notes: '',
  disableAppendVid: false,
};

const defaultState = (): State => ({
  pageNames: [],
  model: defaultModelState,
  validationErrors: {},
  isTouchedForm: false,
  isDataPassingOn: false,
  isSubmitted: false,
  dataPassingErrors: false,
  showErrorTooltip: false,
  anotherModel: {
    queryParams: {}
  },
  isPageLinksSectionOn: false,
  isJavascriptSectionOn: false,
  domain: '',
  actionNumber: '1'
});

class LanderForm extends ReactiveValidateComponent<LanderFormProps & MessageProps & LoadingProps, State> {
  elRefs = {
    baseURL: createRef<InputRef>(),
    finalUrl: createRef<HTMLDivElement>(),
    actionURL: createRef<HTMLDivElement>(),
    jsTrackingHeader: createRef<HTMLDivElement>(),
    defaultParams: createRef<HTMLDivElement>(),
    genericViewSingle: createRef<HTMLDivElement>()
  };

  state: State = defaultState();

  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  static defaultProps: Partial<LanderFormProps> = {
    categories: []
  };

  static getDerivedStateFromProps(props: LanderFormProps, state: State) {
    if (
      state.model.idCategory !== '' &&
      props.categories.filter((item: Category) => item.idCategory === state.model.idCategory).length === 0
    ) {
      return {
        model: {
          ...state.model,
          idCategory: ''
        }
      };
    }

    return null;
  }

  async componentDidMount() {
    this.props.setSidebarLoading!(true);
    await this.onDidMount();
    this.props.setSidebarLoading!(false);
  }

  onDidMount = async () => {
    this.setValidateSubscribe();
    const id = this.props.contextModal.entityId!;

    if (defined(this.props.setButtonGroupProps)) {
      this.props.setButtonGroupProps({
        showSave: true,
        saveText: isCopyByContextModal(this.props.contextModal) ? `DUPLICATE` : `SAVE`,
        saveAndCreateText: 'Save & Create New Lander',
        onSaveClicked: this.handleSubmit,
        showCancel: true,
        cancelText: 'DISCARD'
      });
    }

    if (this.props.setForCopyIdsProps) {
      this.props.setForCopyIdsProps({
        'Lander ID': id
      });
    }

    await this.props.fetchCategories();

    await this.setState((state: State) => ({
      ...state,
      domain: this.props.domain,
      pageNames: Object.values(this.props.landers).map(lander => lander.pageName)
    }));

    if (defined(this.props.contextModal.data || this.props.contextModal.entityId)) {
      await this.props.getLanderById(id);
      await this.setEditModel(id);
    } else {
      this.setDefaultRedirectType();
    }

    if (!defined(this.state.model.redirectType)) {
      this.setDefaultRedirectType();
    }
  };

  setDefaultRedirectType = async () => {
    this.handleSelectInputChange('redirectType', this.props.redirectType);
  };

  async componentDidUpdate(prevProps: LanderFormProps, prevState: State) {
    if (this.props.activeTab !== prevProps.activeTab) {
      if (this.props.activeTab === 'lander-form-javascript' || this.props.activeTab === 'lander-form-page-links') {
        await this.props.fetchDomains();
      }
    }
  }

  setEditModel = (id: string) => {
    const editModel = defined(this.props.landers?.[id]) ? cloneObject(this.props.landers[id]) : null;

    if (defined(editModel)) {
      editModel.disableAppendVid = editModel.disableAppendVid || false;
      if (isCopyByContextModal(this.props.contextModal)) {
        editModel.pageName = this.props.contextModal.copyName!;
        editModel.status = 'active';
      }
      this.setState((state: State) => ({
        model: {
          ...state.model,
          ...editModel
        },
        anotherModel: {
          ...state.anotherModel,
          queryParams: editModel.queryParams
        },
        pageNames: state.pageNames.filter(name => name !== editModel.pageName)
      }));
    }
  };

  validationRules = (): { [key: string]: ValidationRule } => ({
    pageName: {
      field: 'pageName',
      validations: [required, unique(this.state.pageNames, 'lander')],
      tab: LANDER_FORM_TAB
    },
    baseURL: {
      field: 'baseURL',
      validations: [required, xxxUrl],
      tab: LANDER_FORM_TAB
    },
    redirectType: {
      field: 'redirectType',
      validations: [required],
      tab: LANDER_FORM_TAB
    },
    finalUrl: {
      field: 'finalUrl',
      validations: [finalUrl(this.state.model)],
      tab: LANDER_FORM_ADVANCED_TAB
    }
  });

  handleSelectInputChange = (name: string, value: any) => {
    const validate = () => this.validate$.next();

    this.setState(
      (state: State) => ({
        ...state,
        model: {
          ...state.model,
          [name]: value
        }
      }),
      validate
    );
  };

  handleCopyClick = (context: RefObject<any>) => {
    copy(context, this.props.showMessage, Messages);
  };

  handleStateValuesChange = async <T extends State, P extends keyof T>(key: P, value: T[P]) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  makeFinalLanderUrl = (baseUrl: string) => {
    const pairs = Object.entries(this.state.model.queryParams || {})
      .reduce((acc: string[], [key, value]: [string, string]) => (!!key && !!value ? [...acc, `${key}=${value}`] : acc), [])
      .join('&');

    return !!baseUrl ? `${addUrlSlash(baseUrl)}${getQueryUrlSeparate(pairs, baseUrl)}${pairs}` : undefined;
  };

  handleDisableAppendVid = () => {
    this.handleStateValuesChange('model', {
      ...this.state.model,
      disableAppendVid: !this.state.model.disableAppendVid
    });
  }

  handleSaveAndCreate = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    this.props.startLoading('saveAndCreate');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm(true);
    }
  };

  setTabsError = () => {
    if (this.props.setTabsError) {
      const tabs = Object.keys(this.state.validationErrors || {}).map(k => this.validationRules()?.[k]?.tab || '');
      if (this.state.dataPassingErrors) {
        tabs.push(LANDER_FORM_ADVANCED_TAB);
      }
      this.props.setTabsError(tabs);
    }
  };

  performCreateOrUpdate = async () => {
    await this.setTouchedForm();
    await this.validate();
    const model = trimStringPropertiesInObject(this.state.model, ['pageName']);
    this.setTabsError();
    if (model.idCategory === UNCATEGORIZED) {
      delete model.idCategory;
    }

    if (!Object.keys(this.state.validationErrors).length && !this.state.dataPassingErrors) {
      try {
        if (isCopyByContextModal(this.props.contextModal)) {
          await this.props.handleDuplicate({
            ...model,
            meta: { version: 1 }
          });
        } else if (!!this.state.model.idPage) {
          await this.props.getLanderById(model.idPage);
          if (model?.meta && serverVersionIsAheadOfLocal(model.meta?.version, this.props.landers[model.idPage]?.meta?.version)) {
            const sidebar = this.context as SidebarProps;
            try {
              await asyncVersionConfirmSidebar(sidebar);
              await this.props.handleUpdate(withIncrementedVersion(model, this.props.landers[model.idPage]?.meta?.version));
            } catch (e) {
              this.props.stopLoading('all');
              this.props.closeSelf();
              return;
            }
          } else {
            await this.props.handleUpdate(withIncrementedVersion(model));
          }
        } else {
          const newID = generateEntityId();
          await this.props.handleCreate({
            ...model,
            idPage: newID,
            meta: { version: 1 }
          });
          await this.setState({
            model: {
              ...this.state.model,
              idPage: newID
            }
          });
        }

        await this.props.fetchLandersInfo();

        this.props.showMessage(Messages.success(`${model.pageName} ${!!model.idPage ? 'has been updated' : 'has been added'}`));
        this.props.stopLoading('all');
        if (defined(this.props.contextModal.onSubmit)) {
          this.props.contextModal.onSubmit(model);
        }
      } catch (e) {
        if (defined(e.response) && e.response.status === DUPLICATE_RECORD_CODE) {
          this.setState(
            {
              pageNames: [...this.state.pageNames, this.state.model.pageName]
            },
            this.validate
          );
        }
        this.props.showMessage(
          Messages.failed(`${this.state.model.pageName} ${!!this.state.model.idPage ? 'cannot be updated' : 'cannot be added'}`)
        );
        this.props.stopLoading('all');
        return false;
      }

      return true;
    } else {
      this.props.stopLoading('all');
    }

    return false;
  };

  handleSubmit = async (e: Event) => {
    this.setState({ isSubmitted: true });

    if (defined(e)) {
      e.preventDefault();
    }
    this.props.startLoading('save');
    if (await this.performCreateOrUpdate()) {
      await this.resetForm();
      this.props.closeSelf();
    }

    return false;
  };

  handleDataPassingChanges = async (name: string, value: string | StringObject) => {
    await this.setState((state: State) => ({
      ...state,
      model: {
        ...state.model,
        [name]: value
      },
      dataPassingErrors: false
    }));
    this.validate$.next();
  };

  resetForm = async (isCreateAndNew = false) => {
    if (isCreateAndNew) {
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          pageName: getDuplicateModalSidebarContext(this.state.model.idPage, this.props.landers),
          idPage: ''
        }
      });
    }
  };

  validate = async () => {
    if (this.state.isTouchedForm) {
      await this.setState({ validationErrors: {}, showErrorTooltip: false });
      const [validationErrors] = validateForm(this.state.model, Object.values(this.validationRules()));
      this.setState((state: State) => ({
        ...state,
        validationErrors: validationErrors
      }));

      handleScrollToErrorElement(validationErrors, (entries: IntersectionObserverEntry[]) => {
        if (defined(entries[0]) && entries[0].isIntersecting) {
          this.setState({ showErrorTooltip: true });
        }
      });
    }
  };

  setDataPassingError = (value: boolean) => {
    this.handleStateValuesChange('dataPassingErrors', value);
  };

  validateClassName = (field: string) => (!!this.state.validationErrors[field] ? 'has-error' : '');

  validateTooltip = (field: string, idx?: string) => {
    return (idx !== undefined && !!this.state.validationErrors[field] && !!this.state.validationErrors[field][idx]) ||
      (idx === undefined && !!this.state.validationErrors[field])
      ? this.state.validationErrors[field].join(', ')
      : '';
  };

  getActionNumbers = () => {
    const actions = [];
    for (let i = 1; i <= 255; i++) {
      actions.push(i);
    }
    return actions;
  };

  render() {
    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <form className={blockClassName}>
        <FormContentWrapper show={getVisibilityByActiveTab(LANDER_FORM_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle!}>
            <FFRow gap={10} marginBottom={15}>
              <FFField label="Lander Name" htmlFor="pageName" block>
                <FFInput
                  id="pageName"
                  name="pageName"
                  placeholder="Lander name"
                  data-testid="pageName"
                  value={this.state.model.pageName}
                  error={this.validateTooltip('pageName')}
                  onChange={e => this.handleSelectInputChange(e.target.name, e.target.value)}
                />
              </FFField>
              <FFField label="Category" htmlFor="category">
                <FFSelect
                  id="category"
                  data-testid="category"
                  dropdownMatchSelectWidth={300}
                  className={getClass('categorySelect')}
                  placeholder={UNCATEGORIZED}
                  options={getActiveEntities(this.props.categories)}
                  valueGetter={(option: Category) => option.idCategory}
                  labelGetter={(option: Category) => option.categoryName}
                  value={this.state.model.idCategory}
                  defaultValueFallback={{
                    label: UNCATEGORIZED,
                    value: UNCATEGORIZED
                  }}
                  onChange={(value: string) => this.handleSelectInputChange('idCategory', value)}
                  showSearch={true}
                  filterOption={true}
                  style={{ width: 170 }}
                />
                <OpenButton data-testid="open-category-form" onClick={this.props.openCategoriesModal} />
              </FFField>
            </FFRow>
            <FFRow marginBottom={15}>
              <FFField
                label="Base Lander URL"
                tootlipContent={
                  <span>
                    Put your lander page URL here. You can configure appending of extra data (query string) in the <strong>Configure Data Passing</strong> section. You can still use tokens in this base URL.
                  </span>
                }
                htmlFor="baseURL"
                block
              >
                <FFInput
                  id="baseURL"
                  name="baseURL"
                  data-testid="baseURL"
                  placeholder="Lander URL"
                  forwardedRef={this.elRefs.baseURL}
                  value={this.state.model.baseURL}
                  error={this.validateTooltip('baseURL')}
                  onChange={e => this.handleSelectInputChange(e.target.name, withoutWhiteSpace(e.target.value))}
                />
                <CopyButton data-testid="baseURL-copyBtn" onClick={() => this.handleCopyClick(this.elRefs.baseURL)} />
              </FFField>
            </FFRow>
            <FFRow marginBottom={15}>
              <FFField htmlFor="redirectType" label="Redirect Type" block>
                <FFSelect
                  id="redirectType"
                  data-testid="redirectType"
                  placeholder="Select redirect type"
                  value={this.state.model.redirectType}
                  options={redirects}
                  valueGetter={(option: SelectOption) => option.value}
                  labelGetter={(option: SelectOption) => option.label}
                  onChange={(value: string) => this.handleSelectInputChange('redirectType', value)}
                />
              </FFField>
            </FFRow>
            <FFRow marginBottom={15}>
              <FFField label="Notes" htmlFor="notes" block>
                <FFTextarea
                  id="notes"
                  name="notes"
                  rows={4}
                  data-testid="notes"
                  value={this.state.model.notes!}
                  onChange={e => this.handleSelectInputChange(e.target.name, e.target.value)}
                />
              </FFField>
            </FFRow>
            <VisibilityWrapper visible={!isCopyByContextModal(this.props.contextModal)}>
              <FFNewButton loading={this.props.loading.saveAndCreate} onClick={this.handleSaveAndCreate}>
                Save & Create New
              </FFNewButton>
            </VisibilityWrapper>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper shouldMount show={getVisibilityByActiveTab(LANDER_FORM_ADVANCED_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle!}>
            <p>Here you can configure additional data that gets appended to your base page URL.</p>
            <NewDataPassing
              queryParams={this.state.model.queryParams!}
              onChange={(value: StringObject) => this.handleDataPassingChanges('queryParams', value)}
              setError={this.setDataPassingError}
              showErrors={this.state.isSubmitted}
              baseURL={this.state.model.baseURL}
              pageName="Data Passing Landers"
            />
            <FFField
              label="Resulting Lander URL"
              tootlipContent={
                <span>
                  This is the resulting URL from your base URL + query string data configured in the <strong>Configure Data Passing</strong> section. This is the URL FunnelFlux Pro will use when redirecting users to your page.
                </span>
              }
            >
              <CodeSnippet
                data-testid="finalUrl"
                placeholder="Resulting Lander URL"
                forwardedRef={this.elRefs.finalUrl}
                code={this.makeFinalLanderUrl(this.state.model.baseURL)!}
                error={this.validateTooltip('finalUrl')}
                className={getClass('finalUrl')}
              />
            </FFField>
          </FormSectionBox>
          <FormSectionBox title="Advanced Settings">
            <p>Here you can configure advanced settings for data passing</p>
            <FFSwitch checked={this.state.model.disableAppendVid} onClick={this.handleDisableAppendVid}>
              Disable automatic appending of visitor ID on redirect
            </FFSwitch>
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper
          show={
            getVisibilityByActiveTab(LANDER_FORM_PAGE_LINKS_TAB, this.props.activeTab) && !isCopyByContextModal(this.props.contextModal)
          }
        >
          <FormSectionBox title={this.props.tabTitle!}>
            <p>
              Action links are universal click-through URLs that you should use in your pages to send visitors to the next node. You can
              right-click action connections in the funnel builder to get URLs with additional default parameters.
            </p>
            <FFRow gap={15} marginBottom={15}>
              <FFField label="Select a domain:" block>
                <FFSelect
                  value={this.state.domain}
                  onChange={(value: string) => this.handleStateValuesChange('domain', value)}
                  id="domain"
                  options={this.props.customDomains}
                  valueGetter={(option: DomainEntry) => option.domain}
                  labelGetter={(option: DomainEntry) => option.domain}
                  placeholder="Select Domain"
                  data-testid="selectDomain"
                />
              </FFField>
              <FFField label="Action number" block>
                <FFSelect
                  options={this.getActionNumbers()}
                  placeholder="Select Action Number"
                  id="actionNumber"
                  data-testid="actionNumber"
                  value={this.state.actionNumber}
                  valueGetter={option => String(option)}
                  labelGetter={option => String(option)}
                  onChange={(value: string) => {
                    this.setState({
                      actionNumber: getCorrectActionNumber(value)
                    });
                  }}
                />
              </FFField>
            </FFRow>
            <FFRow>
              <FFField label="Action URL" block>
                <CodeSnippet
                  data-testid="actionURL"
                  placeholder="Action URL"
                  forwardedRef={this.elRefs.actionURL}
                  code={getPageActionLink(this.state.domain, this.state.actionNumber)}
                />
              </FFField>
            </FFRow>
          </FormSectionBox>
        </FormContentWrapper>
        <FormContentWrapper
          show={
            getVisibilityByActiveTab(LANDER_FORM_JAVASCRIPT_TAB, this.props.activeTab) && !isCopyByContextModal(this.props.contextModal)
          }
        >
          <FormSectionBox title="Required: Universal JS Tag">
            <FFRow marginBottom={15}>
              <FFField label="Select a domain:" htmlFor="domain" direction="row" block>
                <FFSelect
                  value={this.state.domain}
                  onChange={(value: string) => this.handleStateValuesChange('domain', value)}
                  options={this.props.customDomains}
                  valueGetter={(option: DomainEntry) => option.domain}
                  labelGetter={(option: DomainEntry) => option.domain}
                  placeholder="Select Domain"
                />
              </FFField>
            </FFRow>
            <p>
              This script is required for direct linking. Place it just before {'</head>'}. We recommend adding this to all of your pages
              regardless as it improves tracking. This code already includes a page view event.
            </p>
            <CodeSnippet forwardedRef={this.elRefs.jsTrackingHeader} codeType="genericViewFull" domain={this.state.domain} />
          </FormSectionBox>
          <FormSectionBox title="Optional: View-only Event">
            <p>
              If you have already loaded our universal JS tag and want to fire a singular page view event, you can use the following code.
              See our{' '}
              <a href="https://help.funnelflux.pro/article/105-javascript-tracking-of-page-views" target="_blank">
                help documentation
              </a>{' '}
              for further methods/arguments.
            </p>
            <CodeSnippet codeType="genericViewSingle" forwardedRef={this.elRefs.genericViewSingle} maxContent />
          </FormSectionBox>
          <FormSectionBox title="Optional: Default Tracking Parameters">
            <p>
              This code is optional and is NOT required for tracking to work. If you would like to track organic visits to your page, it
              needs to declare some defaults. You can embed the below code <em>before</em> our global snippet, to provide the tracking with
              default parameters. See our{' '}
              <a href="https://help.funnelflux.pro/article/108-setting-javascript-page-defaults" target="_blank">
                help documentation
              </a>{' '}
              for more info.
            </p>
            <CodeSnippet codeType="defaultParams" forwardedRef={this.elRefs.defaultParams} idPage={this.state.model.idPage} maxContent />
          </FormSectionBox>
        </FormContentWrapper>

        <FormContentWrapper show={getVisibilityByActiveTab(LANDER_FORM_HELP_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle!}>
            <FormHelpSection
              content={
                <>
                  <p>
                    Landers are pages you send users to within your funnels. These pages cannot convert and create revenue - for that you
                    need to use offer pages.
                  </p>

                  <p>
                    When creating landers you only need to provide a few settings such as URL and redirect type. You can also append extra
                    data using our query builder, or put it in the base URL directly (its up to you, though we always recommend using the
                    field/value form to do this, as it helps avoid mistakes).
                  </p>
                  <p>
                    For more help on landers, see our documentation{' '}
                    <a href="https://help.funnelflux.pro/article/7-introduction-to-landers" target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </p>
                  <p>
                    For further information on using our Javascript tracking, see our documentation{' '}
                    <a href="https://help.funnelflux.pro/collection/6-javascript-tracking" target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </p>
                </>
              }
              name="Lander"
            />
          </FormSectionBox>
        </FormContentWrapper>
      </form>
    );
  }
}

export default Messages.injectIn(LanderForm);

import { daysOfWeek } from 'constants/condition';
import { connectionTypes } from 'constants/connectionTypes';
import { continents } from 'constants/continents';
import { countries } from 'constants/countries';
import { deviceOS } from 'constants/deviceOS';
import { deviceTypes } from 'constants/deviceTypes';
import { languages } from 'constants/languages';
import { timeZones } from 'constants/timeZones';
import { FunnelConditionRule } from 'model/funnelConditionRule';
import { FFSelectOption } from 'uikit/types/select';

function makeFieldsFromEnum(attributes: { [key: string]: string }) {
  var options: FFSelectOption[] = [];
  for (let key in attributes) {
    const value = attributes[key];
    const splited = value.split(':');
    if (splited.length > 1) {
      options.push({
        category: splited[0],
        label: splited[1],
        value: attributes[key]
      });
    } else {
      options.push({
        category: 'Others',
        label: splited[0],
        value: attributes[key]
      });
    }
  }
  return options;
}

export const attributeFields = makeFieldsFromEnum(FunnelConditionRule.AttributeEnum);

export interface ConditionRuleSelectParams<T = any> {
  options: T[];
  defaultValueFallback?: { label: string; value: string; };
  valueGetter: (opt: T) => string;
  labelGetter: (opt: T) => string;
}

export const continentSelectParams: ConditionRuleSelectParams<string> = {
  options: continents,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const countrySelectParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: countries,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const timezoneSelectParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: timeZones,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const deviceTypeSelectParams: ConditionRuleSelectParams<string> = {
  options: deviceTypes,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const deviceOsSelectParams: ConditionRuleSelectParams<string> = {
  options: deviceOS,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const deviceMainLanguageParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: languages,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const connectionTypeParams: ConditionRuleSelectParams<string> = {
  options: connectionTypes,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const dayOfWeekParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: daysOfWeek,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const dayOfMonthParams: ConditionRuleSelectParams<string> = {
  options: Array.from(Array(31).keys()).map(i => `${i + 1}`),
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const monthOfYearParams: ConditionRuleSelectParams<string> = {
  options: Array.from(Array(12).keys()).map(i => `${i + 1}`),
  valueGetter: opt => opt,
  labelGetter: opt => months[Number(Number(opt) - 1)]
};

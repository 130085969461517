import React, { Component, PropsWithChildren } from 'react';
import DomainSettings from './DomainSettings';
import LanderSidebar from './Landers';
import OfferSidebar from './Offers';
import OfferSourcesSidebar from './OfferSources';
import ConditionSidebar from './Condition';
import TrafficSourcesSidebar from './TrafficSources';
import PageGroupsSidebar from './PageGroups';
import FunnelGroupSidebar from './FunnelGroup';
import FunnelSidebar from './Funnels';
import SwapPage from './Builder/Swap';
import SimpleFlows from './SimpleFlows';
import ResetDataDelete from './ResetDataDelete';
import FunnelFlowCampaign from './FunnelFlowCampaign';
import NewOfferLanderGroup from './NewOfferLanderGroup';
import QuickStats from './QuickStats';
import { ConfirmSidebar } from './Confirm';
import CategorySidebar from './Category';
import {
  SidebarsProvider as Provider,
  SidebarsConsumer as Consumer,
  SidebarOffsetRightContext
} from './context';
import { withRouter } from 'react-router';
import { AnyObject } from 'types';
import { FormContextSidebar } from 'types/modal';
import { History } from '../../types';
import { SidebarProps } from 'types/sidebars';
import { DEFAULT_MODAL_Z_INDEX } from 'constants/modal';
import { definedObject } from 'utils/define';
import { hotkeysGlobalScope } from 'utils/app';
import { conditionalClass } from 'conditional-class';
import { FFIcon } from 'uikit';
import './style.scss';

interface State {
  contexts: AnyObject;
  zIndex: number;
}

interface Props {
  history: History;
}

class SidebarsProvider extends Component<PropsWithChildren<Props>, State> {
  static contextType = SidebarOffsetRightContext;
  context!: React.ContextType<typeof SidebarOffsetRightContext>;

  state: State = {
    contexts: {},
    zIndex: DEFAULT_MODAL_Z_INDEX + 1
  };

  openSidebar = async (sidebarName: string) => {
    await this.context.addOpenedSidebar(sidebarName);
    await this.setState((state: State) => ({
      ...state,
      contexts: {
        ...state.contexts,
        [sidebarName]: {
          ...state.contexts[sidebarName],
          isOpen: true,
          history: this.props.history,
          zIndex: state.zIndex + 1
        }
      },
      zIndex: state.zIndex + 1
    }));
    hotkeysGlobalScope.setScope.setSidebarScope();

    return () => this.closeSidebar(sidebarName);
  };

  isOpenSidebar = (sidebarName: string) => {
    return !!this.state.contexts[sidebarName]?.isOpen;
  };

  numberOfOpenSidebars = () =>
    Object.keys(this.state.contexts).filter(k =>
      definedObject(this.state.contexts[k])
    ).length;

  closeSidebar = async (sidebarName: string) => {
    await this.context.decreaseOpenedSidebar();
    await this.setState(state => ({
      ...state,
      contexts: {
        ...state.contexts,
        [sidebarName]: {}
      },
      zIndex: state.zIndex - 1
    }));
    if (
      !Object.values(this.state.contexts).filter(v => definedObject(v)).length
    ) {
      hotkeysGlobalScope.setScope.setPageScope();
    }
  };

  setContextSidebar = <T extends FormContextSidebar>(
    sidebarName: string,
    value: T
  ) => {
    this.setState(state => ({
      ...state,
      contexts: {
        ...state.contexts,
        [sidebarName]: {
          ...state.contexts[sidebarName],
          ...value
        }
      }
    }));
  };

  getContextSidebar = (sidebarName: string) => {
    return definedObject(this.state.contexts[sidebarName])
      ? this.state.contexts[sidebarName]
      : {};
  };

  render() {
    return (
      <>
        <Provider
          value={{
            contexts: this.state.contexts,
            zIndex: this.state.zIndex,
            openSidebar: this.openSidebar,
            closeSidebar: this.closeSidebar,
            isOpenSidebar: this.isOpenSidebar,
            setContextSidebar: this.setContextSidebar,
            getContextSidebar: this.getContextSidebar,
            getOffsetRight: this.context.getOffsetRight,
            numberOfOpenSidebars: this.numberOfOpenSidebars
          }}
        >
          <LanderSidebar />
          <OfferSidebar />
          <OfferSourcesSidebar />
          <TrafficSourcesSidebar />
          <PageGroupsSidebar />
          <ConditionSidebar />
          <FunnelSidebar />
          <FunnelGroupSidebar />
          <CategorySidebar />
          <ConfirmSidebar />
          <SwapPage />
          <SimpleFlows />
          <QuickStats />
          <DomainSettings />
          <FunnelFlowCampaign />
          <NewOfferLanderGroup />
          <ResetDataDelete />

          {this.props.children}
        </Provider>
      </>
    );
  }
}

interface OffsetRightState {
  openSidebars: string[];
}
export class SidebarOffsetRightProvider extends Component<
  PropsWithChildren,
  OffsetRightState
> {
  state: OffsetRightState = {
    openSidebars: []
  };

  getOffsetRight = (sidebarName: string) => {
    const openSidebars = this.state.openSidebars;
    const index = openSidebars.indexOf(sidebarName);
    return index < openSidebars.length - 1
      ? (openSidebars.length - (index + 1)) * 140
      : 0;
  };

  addOpenedSidebar = async (sidebarName: string) => {
    await this.setState(state => ({
      ...state,
      openSidebars: [...state.openSidebars, sidebarName]
    }));
  };

  decreaseOpenedSidebar = async () => {
    await this.setState(state => ({
      ...state,
      openSidebars: state.openSidebars.slice(0, state.openSidebars.length - 1)
    }));
  };

  render() {
    return (
      <SidebarOffsetRightContext.Provider
        value={{
          getOffsetRight: this.getOffsetRight,
          addOpenedSidebar: this.addOpenedSidebar,
          decreaseOpenedSidebar: this.decreaseOpenedSidebar,
          openSidebars: this.state.openSidebars
        }}
      >
        {this.props.children}
      </SidebarOffsetRightContext.Provider>
    );
  }
}

export const SidebarTrigger = ({
  sidebarToOpen,
  label = '',
  disabled = false
}: {
  sidebarToOpen: string;
  label?: string;
  disabled?: boolean;
}) => {
  return (
    <Consumer>
      {(sidebarProps: SidebarProps) => (
        <div
          className={conditionalClass('c-sidebarTrigger', {
            'c-sidebarTrigger--disabled': disabled
          })}
          onClick={() =>
            disabled ? {} : sidebarProps.openSidebar(sidebarToOpen)
          }
          aria-disabled={disabled}
        >
          <FFIcon name="plusCircle" size="bigger" />
          <span>{label}</span>
        </div>
      )}
    </Consumer>
  );
};

//@ts-ignore
export default withRouter(SidebarsProvider);

import { Loading } from "types/loading";

export const initialLoading: Loading = {
  save: false,
  saveAndDuplicate: false,
  saveAndCreate: false,
  ok: false,
  confirm: false,
  fetching: false
};

import React from 'react';
import FluxModal from 'components/Modal';
import { DOMAINS_MODAL_DELETE_DNS } from 'constants/modal';
import { ModalsConsumer } from 'components/Modals/context';
import { ModalProps } from '../types';
import { DNSEditDeleteProps } from 'types/ModalForms/domain';
import { FormContextModal } from 'types/modal';
import './style.scss';
import { getTTLNameByValue } from 'utils/domains';
import { ProxyToggle } from 'components/Parts/Buttons';
import { ModalButtonGroup } from 'components/Parts/Groups';
import { connect } from 'react-redux';
import { deleteDnsRecord } from 'redux/actions/domains';
import { AnyObject } from 'types';

const DeleteDnsModal = ({
  isOpen,
  closeSelf,
  onSubmit,
  params
}: DNSEditDeleteProps) => (
  <FluxModal
    width={920}
    destroyOnClose
    title={`Delete Record For ${!!params ? params.name : ''}`}
    classNames={['delete-dns-modal']}
    isOpen={isOpen}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <div className="delete-dns-modal">
        <p>Are you sure you want to delete this record?</p>
        {!!params && (
          <table>
            <thead>
              <th>Type</th>
              <th>Name</th>
              <th>Content</th>
              <th>TTL</th>
              <th>Proxy status</th>
            </thead>
            <tbody>
              <tr>
                <td>{params.type}</td>
                <td>{params.name}</td>
                <td>{params.content}</td>
                <td>{getTTLNameByValue(params.ttl)}</td>
                <td>
                  <ProxyToggle value={params.proxied} />
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <ModalButtonGroup
          loading={{}}
          showSave
          showCancel
          saveText={`Delete`}
          onSaveClicked={() => onSubmit!(params)}
          cancelText={`Cancel`}
          onCancelClicked={closeSelf}
        />
      </div>
    )}
  />
);

const ConnectedDeleteDnsModal = ({ deleteDnsRecord }: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <DeleteDnsModal
            isOpen={isOpenModal(DOMAINS_MODAL_DELETE_DNS)}
            closeSelf={() => closeModal(DOMAINS_MODAL_DELETE_DNS)}
            onSubmit={deleteDnsRecord}
            params={
              (getContextModal(DOMAINS_MODAL_DELETE_DNS) as FormContextModal)
                .data as any
            }
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(null, { deleteDnsRecord })(ConnectedDeleteDnsModal);

import React from 'react';

const content = () => (
  <div className="notification-sidebar--versioning">
    <h2>Version outdated</h2>
    <p>
      The resource you are trying to save has been updated since you opened it.
    </p>
    <p>
      Choose [YES] to forcefully save your changes, overwriting any existing
      data.
    </p>
    <p>
      Choose [NO] to cancel your save attempt. You can then discard your changes
      and manually reload this resource.
    </p>
  </div>
);
export default content;

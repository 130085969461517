export const isNumeric = (value: any): boolean => {
  if (value === '') {
    return false;
  }
  return !isNaN(parseFloat(value)) && isFinite(value);
};

export const exists = (
  value: any,
  allowEmptyString: boolean = false
): boolean => {
  return value !== null && (value !== '' || allowEmptyString);
};

export const isString = (value: any): boolean => typeof value === 'string';

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import {
  addNewTrafficSource,
  updateTrafficSource,
  getTrafficSourceById,
  duplicateTrafficSource,
  fetchCategories
} from 'redux/actions/trafficsources';
import { fetchReportingsAttributes } from 'redux/actions/reportings';
import {
  TRAFFIC_SOURCE_CATEGORY_MODAL,
  TRAFFIC_SOURCE_DUPLICATE_MODAL,
  TRAFFIC_SOURCE_MODAL
} from 'constants/modal';
import { withLoading } from 'components/Loading';
import { connect } from 'react-redux';
import { TrafficSourceModalSelector } from 'redux/selectors';
import { FormContextModal } from 'types/modal';
import TrafficSourceForm from 'components/Forms/TrafficSource';
import {
  TrafficSourceFormProps,
  TrafficSourceSidebarProps,
  TrafficSourcesTabKey
} from 'types/ModalForms/trafficSource';
import { SidebarProps } from 'types/sidebars';
import { SidebarsConsumer } from '../context';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import { LoadingProps } from 'types/loading';
import { getModalTitle } from 'utils/modals';
import {
  TRAFFICSOURCE_FORM_TAB,
  TRAFFICSOURCE_FORM_CONVERSION_TRACKING_TAB,
  TRAFFICSOURCE_FORM_HELP_TAB,
  TRAFFICSOURCE_FORM_TRACKING_FIELDS_TAB,
  TRAFFICSOURCE_FORM_ADVANCED_SETTINGS_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import { FFIcon } from 'uikit';

class TrafficSourceFormWrapper extends React.Component<
  Omit<TrafficSourceFormProps, 'showTabs' | 'setButtonGroupProps'> &
    TrafficSourceSidebarProps &
    LoadingProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen}
        hasTab={false}
        onClose={() => {
          this.props.closeSelf();
        }}
        defaultOpenSideBarKey="trafficsourceForm"
        dataPortalKey="trafficsource"
        defaultActiveTab={TRAFFICSOURCE_FORM_TAB}
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'trafficsourceForm',
            tabs: {
              [TRAFFICSOURCE_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [TRAFFICSOURCE_FORM_TRACKING_FIELDS_TAB]: {
                title: 'Tracking Fields',
                icon: <FFIcon name="trackingFields" />,
                highlightable: true
              },
              [TRAFFICSOURCE_FORM_CONVERSION_TRACKING_TAB]: {
                title: 'Conversion Tracking',
                icon: <FFIcon name="conversionTracking" />,
                highlightable: true
              },
              [TRAFFICSOURCE_FORM_ADVANCED_SETTINGS_TAB]: {
                title: 'Advanced Settings',
                icon: <FFIcon name="advancedSettings" />,
                highlightable: true
              },
              [TRAFFICSOURCE_FORM_HELP_TAB]: {
                title: 'Help',
                icon: <FFIcon name="question" />
              }
            },
            title: `${getModalTitle(this.props.contextModal)} Traffic Source`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setLoading,
              setForCopyIdsProps,
              sidebarLoading,
              showTabs,
              tabTitle
            }) => {
              return (
                <>
                  <TrafficSourceForm
                    {...this.props}
                    activeTab={activeTab as TrafficSourcesTabKey}
                    setForCopyIdsProps={setForCopyIdsProps}
                    setSidebarLoading={setLoading}
                    sidebarLoading={sidebarLoading}
                    setButtonGroupProps={setButtonGroupProps}
                    tabTitle={tabTitle}
                    showTabs={showTabs}
                  />
                </>
              );
            }
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(TrafficSourceFormWrapper);

const ConnectedFormElement = ({
  addNewTrafficSource,
  updateTrafficSource,
  duplicateTrafficSource,
  trafficSources,
  getTrafficSourceById,
  categories,
  attributes,
  fetchReportingsAttributes,
  fetchCategories,
  userSettings
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        openSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let sidebarName = TRAFFIC_SOURCE_MODAL;
        let contextSidebar = getContextSidebar(
          TRAFFIC_SOURCE_MODAL
        ) as FormContextModal;

        if (isOpenSidebar(TRAFFIC_SOURCE_DUPLICATE_MODAL)) {
          sidebarName = TRAFFIC_SOURCE_DUPLICATE_MODAL;
          contextSidebar = getContextSidebar(
            TRAFFIC_SOURCE_DUPLICATE_MODAL
          ) as FormContextModal;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            trafficSources
          );
        }
        const isOpen =
          isOpenSidebar(TRAFFIC_SOURCE_MODAL) ||
          isOpenSidebar(TRAFFIC_SOURCE_DUPLICATE_MODAL);

        return (
          <FormElement
            getTrafficSourceById={getTrafficSourceById}
            contextModal={contextSidebar}
            trafficSources={trafficSources}
            attributes={attributes}
            categories={categories}
            userSettings={userSettings}
            fetchCategories={fetchCategories}
            fetchReportingsAttributes={fetchReportingsAttributes}
            handleCreate={addNewTrafficSource}
            handleUpdate={updateTrafficSource}
            handleDuplicate={duplicateTrafficSource}
            closeSelf={() => closeSidebar(sidebarName)}
            openCategoriesModal={() =>
              openSidebar(TRAFFIC_SOURCE_CATEGORY_MODAL)
            }
            isOpen={isOpen}
            zIndex={contextSidebar.zIndex!}
            offsetRight={getOffsetRight(sidebarName)}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(TrafficSourceModalSelector, {
  addNewTrafficSource,
  updateTrafficSource,
  duplicateTrafficSource,
  fetchCategories,
  getTrafficSourceById,
  fetchReportingsAttributes
})(ConnectedFormElement);

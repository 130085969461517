import { apiAction } from './api';
import { setTableCols } from './table';
import { OFFERS, PAGE_GROUPS, VIEW } from '../types';
import { OFFERS as API, CATEGORIES } from 'api/axios';
import { shouldFetchReporting } from '../selectors';
import { Dispatch } from 'redux';
import { Page } from '../../model/page';
import { DeleteList } from '../../model/deleteList';
import { Category } from '../../model/category';
import { fetchOfferSources } from './offersources';
import { DrilldownReport } from '../../model/drilldownReport';
import { StatusEnum } from '../../api/types';
import { CompositeStore, OfferStore } from '../../types/redux/store';
import { makeNewRow, updatedRows } from 'utils/table';
import { DrilldownReportParams } from '../../model/drilldownReportParams';
import { DrilldownReportRow } from '../../model/drilldownReportRow';
import { defined } from 'utils/define';
import { archiveCategoryRelatedEntities } from '../../utils/redux';
import { reportCategoriesToCategories } from 'utils/arrs';
import { PageInfo } from 'model/pageInfo';
import { PageCategoryInfo } from 'model/pageCategoryInfo';

const setPageGroupPagesList = (payload: Page[]) => {
  return {
    type: PAGE_GROUPS.SET_OFFERS,
    payload
  };
};

export const setOffersInfo = (payload: PageInfo[]) => {
  return {
    type: OFFERS.SET_INFO,
    payload
  };
};

const setOffersCategoryInfo = (payload: PageCategoryInfo[] = []) => {
  return {
    type: OFFERS.SET_CATEGORY_INFO,
    payload
  };
};

const setOffersList = (payload: Page[] = []) => {
  return {
    type: OFFERS.SET_LIST,
    payload
  };
};

const setOffer = (payload: Page) => {
  return {
    type: OFFERS.ADD_SINGLE,
    payload
  };
};

const setExistedOffer = (payload: Page) => {
  return {
    type: OFFERS.ADD_EXISTED_SINGLE,
    payload
  };
};

const setOfferRow = (data: DrilldownReportRow) => {
  return {
    type: OFFERS.ADD_SINGLE_ROW,
    payload: { data }
  };
};

const setCategoryList = (payload: Category[] = []) => {
  return {
    type: OFFERS.SET_CATEGORIES,
    payload
  };
};

const createCategories = (payload = {}) => {
  return {
    type: OFFERS.UPDATE_CATEGORY,
    payload
  };
};

const updateCategories = (payload = {}) => {
  return {
    type: OFFERS.CREATE_CATEGORY,
    payload
  };
};

const deleteCategory = (payload = {}) => {
  return {
    type: OFFERS.DELETE_CATEGORY,
    payload
  };
};

const archiveCategory = (data: string) => {
  return {
    type: OFFERS.ARCHIVE_CATEGORY,
    payload: { data }
  };
};

const offerUpdate = (data: Page) => {
  return {
    type: OFFERS.UPDATE_SINGLE,
    payload: data
  };
};

const offerUpdateReporting = (data: DrilldownReportRow[]) => {
  return {
    type: OFFERS.UPDATE_SINGLE_REPORTING,
    payload: { data }
  };
};

const offerDelete = (data: string[]) => {
  return {
    type: OFFERS.DELETE,
    payload: { data }
  };
};

const offerArchive = (data: string = '') => {
  return {
    type: OFFERS.ARCHIVE,
    payload: { data }
  };
};

const setReportingsData = (data = {}, params: DrilldownReportParams) => {
  return {
    type: OFFERS.SET_REPORTING,
    payload: { data, params }
  };
};

const categoryEntitiesUpdate = (idCategory: string, categoryName: string) => {
  return {
    type: OFFERS.UPDATE_CATEGORY_ENTITIES,
    payload: {
      idCategory,
      categoryName
    }
  };
};

const offerSourceEntitiesUpdate = (
  idCategory: string,
  idOfferSource: string,
  offerSourceName: string
) => {
  return {
    type: OFFERS.UPDATE_OFFER_SOURCE_ENTITIES,
    payload: {
      idCategory,
      idOfferSource,
      offerSourceName
    }
  };
};

const setShow = (payload: StatusEnum) => {
  return {
    type: OFFERS.SET_SHOW,
    payload
  };
};

export const fetchOffers = (
  status: StatusEnum = 'not-deleted',
  shouldFetchOfferSources = true
) => (dispatch: Dispatch, getState: () => CompositeStore) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(status),
      onSuccess: (data: Page[]) => {
        dispatch(setOffersList(data));
        dispatch(setPageGroupPagesList(data));
        if (shouldFetchOfferSources) {
          return fetchOfferSources();
        }
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.FETCH_LIST
    })
  );
};

export const fetchOffersCategoryInfo = (status: StatusEnum = 'all') => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_CATEGORY_INFO(status),
      onSuccess: (data: PageCategoryInfo[]) => {
        dispatch(setOffersCategoryInfo(data));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.FETCH_CATEGORY_INFO
    })
  );
};

export const fetchOffersInfo = (status: StatusEnum = 'all') => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_INFO(status),
      onSuccess: (data: PageInfo[]) => setOffersInfo(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.FETCH_INFO
    })
  );
};

export const fetchCategories = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: CATEGORIES.FETCH_LIST('offer'),
      onSuccess: (data: Category[]) => setCategoryList(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.FETCH_INIT
    })
  );
};

export const addOffer = (data: Page) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  return dispatch(
    apiAction({
      requestConfig: API.CREATE(data),
      onSuccess: () => {
        dispatch(setOffer(data));
        setReportingEntries(data, dispatch, getState);
        return setOfferRow(makeNewRow(data, 'offers'));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.ADD_SINGLE_ROW
    })
  );
};

export const duplicateOffer = (data: Page) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  return dispatch(
    apiAction({
      requestConfig: API.DUPLICATE(data),
      onSuccess: (data: Page) => {
        dispatch(setOffer(data));
        setReportingEntries(data, dispatch, getState);
        return setOfferRow(makeNewRow(data, 'offers'));
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.ADD_SINGLE_ROW
    })
  );
};

export const getOfferById = (idPage: string) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.GET(idPage),
      onSuccess: (page: Page) => setExistedOffer(page),
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.ADD_SINGLE
    })
  );
};

export const getOffersByCategory = (idCategory: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FIND_BY_CATEGORY(idCategory),
      onSuccess: (pages: Page[]) => setOffersList(pages),
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.FETCH_LIST
    })
  );
};

export const fetchReportings = (params: DrilldownReportParams) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  if (shouldFetchReporting(getState().offers)) {
    return dispatch(
      apiAction({
        requestConfig: API.FETCH_REPORTING(params),
        onSuccess: (data: DrilldownReport) => {
          dispatch(setReportingsData(data, params));
          dispatch(
            setCategoryList(
              reportCategoriesToCategories(data.entities?.categories || [])
            )
          );
        },
        onFailure: (e: Error) => {
          throw e;
        },
        label: OFFERS.FETCH_REPORTING
      })
    );
  }
};

const setReportingEntries = (
  data: Page,
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  const category = getState().offers.categories.find(
    item => defined(data.idCategory) && item.idCategory === data.idCategory
  );

  const offerSource = Object.values(getState().offersources.data).find(
    item =>
      defined(data.offerParams) &&
      item.idOfferSource === data.offerParams.idOfferSource
  );

  if (defined(category) && defined(data.idCategory)) {
    dispatch(categoryEntitiesUpdate(data.idCategory, category.categoryName));

    if (defined(offerSource) && defined(offerSource.idOfferSource)) {
      dispatch(
        offerSourceEntitiesUpdate(
          data.idCategory,
          offerSource.idOfferSource,
          offerSource.offerSourceName
        )
      );
    }
  }
};

export const updateOffer = (data: Page) => (
  dispatch: Dispatch,
  getState: () => CompositeStore
) => {
  return dispatch(
    apiAction({
      requestConfig: API.UPDATE(data),
      onSuccess: () => {
        setReportingEntries(data, dispatch, getState);

        updatedRows({
          id: data.idPage,
          newName: data.pageName,
          newCategoryID: data.idCategory,
          newOfferSourceID: defined(data.offerParams)
            ? data.offerParams.idOfferSource
            : undefined,
          newPayout: data.offerParams?.payout,
          rows: getState().offers.reporting.rows
        }).then((res: any) => {
          dispatch(offerUpdateReporting(res));
          dispatch(offerUpdate(data));
        });
      },
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.UPDATE_SINGLE
    })
  );
};

export const updateOfferCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.UPDATE(data),
    onSuccess: () => createCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: OFFERS.ADD_SINGLE_CATEGORY
  });
};

export const dispatchUpdateOfferCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(updateOfferCategories(data));
};

export const createOfferCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.CREATE(data),
    onSuccess: () => updateCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: OFFERS.ADD_SINGLE_CATEGORY
  });
};

export const dispatchCreateOfferCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(createOfferCategories(data));
};

export const deleteOfferCategory = (data: DeleteList) => {
  return apiAction({
    requestConfig: CATEGORIES.DELETE(data),
    onSuccess: () => deleteCategory(data.entries),
    onFailure: (e: Error) => {
      throw e;
    },
    label: OFFERS.ADD_SINGLE_CATEGORY
  });
};

export const dispatchDeleteOfferCategory = (data: DeleteList) => (
  dispatch: Dispatch
) => {
  return dispatch(deleteOfferCategory(data));
};

export const archiveOfferCategory = (
  data: Category,
  offerStore?: OfferStore,
  dispatch?: Dispatch
) => {
  return apiAction({
    requestConfig: CATEGORIES.ARCHIVE(data),
    onSuccess: () => {
      archiveCategoryRelatedEntities(
        dispatch!,
        offerStore!,
        data,
        archiveOffer
      );
      return archiveCategory(data.idCategory);
    },
    onFailure: (e: Error) => {
      throw e;
    },
    label: OFFERS.ARCHIVE_SINGLE_CATEGORY
  });
};

export const dispatchArchiveOfferCategory = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(archiveOfferCategory(data));
};

export const setOfferTableCols = (cols: []) => (dispatch: Dispatch) => {
  return dispatch(setTableCols(cols, VIEW.OFFERS));
};

export const deleteOffer = (data: DeleteList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => offerDelete(data.entries),
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.DELETE
    })
  );
};

export const archiveOffer = (data: Page) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.ARCHIVE(data),
      onSuccess: () => offerArchive(data.idPage),
      onFailure: (e: Error) => {
        throw e;
      },
      label: OFFERS.ARCHIVE
    })
  );
};

export const setOfferShow = (show: StatusEnum) => (dispatch: Dispatch) => {
  return dispatch(setShow(show));
};

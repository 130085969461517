import { INTEGRATIONS } from '../types';
import { Dispatch } from 'redux';
import { apiAction } from './api';
import { INTEGRATIONS as API } from '../../api/endpoints';
import { IntegrationAPIKey } from 'model/integrationAPIKey';
import { GenerateIntegrationAPIKeyRequest } from 'model/generateIntegrationAPIKeyRequest';
import { IntegrationAPIDeleteKey } from 'model/integrationAPIDeleteKey';
import { sortByName } from 'utils/sort';

const setIntegrations = (payload: IntegrationAPIKey[]) => {
  return {
    type: INTEGRATIONS.SET_LIST,
    payload
  };
};

const addApiKey = (payload: IntegrationAPIKey) => {
  return {
    type: INTEGRATIONS.ADD_SINGLE,
    payload
  };
};

const updateApiKey = (payload: IntegrationAPIKey) => {
  return {
    type: INTEGRATIONS.UPDATE_SINGLE,
    payload
  };
};

const deleteApiKey = (payload: IntegrationAPIDeleteKey) => {
  return {
    type: INTEGRATIONS.DELETE,
    payload
  };
};

export const fetchApiKeysList = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH(),
      onSuccess: (data: IntegrationAPIKey[]) =>
        setIntegrations(sortByName(data, 'name')),
      onFailure: (e: Error) => {
        throw e;
      },
      label: INTEGRATIONS.FETCH
    })
  );
};

export const addIntegrationApiKey = (
  data: GenerateIntegrationAPIKeyRequest
) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.CREATE(data),
      onSuccess: (data: IntegrationAPIKey) => addApiKey(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: INTEGRATIONS.CREATE
    })
  );
};

export const updateIntegrationApiKey = (data: IntegrationAPIKey) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.UPDATE(data),
      onSuccess: () => updateApiKey(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: INTEGRATIONS.CREATE
    })
  );
};

export const deleteIntegrationApiKey = (data: IntegrationAPIDeleteKey) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => deleteApiKey(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: INTEGRATIONS.DELETE
    })
  );
};

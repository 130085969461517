import { pageGroups as defaultState } from './defaultStates';
import {
  PageGroupAction,
  ReportingParamsAction
} from '../../types/redux/action';
import { API, LANDERS, OFFERS, PAGE_GROUPS, TABLE, VIEW } from '../types';
import { defined } from '../../utils/define';
import { PageGroupArray, PageGroupStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { PageGroup } from '../../model/pageGroup';
import { pageGroupsReportingTypes } from '../../types/reporting';
import table from './table';
import { PageGroupInfo } from 'model/pageGroupInfo';
import { infoToResource } from 'utils/redux';

const data = (
  state = defaultState.data,
  action: PageGroupAction | AnyAction
) => {
  switch (action.type) {
    case PAGE_GROUPS.SET_LIST:
      if (defined(action.payload)) {
        return (action.payload as PageGroup[]).reduce(
          (acc: PageGroupArray, pageGroup) => {
            acc[pageGroup.idPageGroup] = pageGroup;
            return acc;
          },
          {}
        );
      }
      return state;
    case PAGE_GROUPS.SET_LANDER_INFO:
      if (defined(action.payload)) {
        return (action.payload as PageGroupInfo[]).reduce(
          (acc: PageGroupArray, pageGroupInfo) => {
            if (!acc[pageGroupInfo.idPageGroup]) {
              acc[pageGroupInfo.idPageGroup] = infoToResource.pageGroup(
                pageGroupInfo,
                'lander'
              );
            }
            return acc;
          },
          state
        );
      }
      return state;
    case PAGE_GROUPS.SET_OFFER_INFO:
      if (defined(action.payload)) {
        return (action.payload as PageGroupInfo[]).reduce(
          (acc: PageGroupArray, pageGroupInfo) => {
            acc[pageGroupInfo.idPageGroup] = infoToResource.pageGroup(
              pageGroupInfo,
              'offer'
            );
            return acc;
          },
          state
        );
      }
      return state;
    case PAGE_GROUPS.ARCHIVE:
      if (defined(action.payload)) {
        const archivePayload = action.payload.data as PageGroup;
        state[archivePayload.idPageGroup].status = 'archived';
      }
      return state;
    case PAGE_GROUPS.ADD_SINGLE:
    case PAGE_GROUPS.UPDATE_SINGLE:
    case PAGE_GROUPS.ADD_EXISTED_SINGLE:
      return {
        ...state,
        [(action.payload as PageGroup).idPageGroup]: action.payload
      };
    case PAGE_GROUPS.DELETE:
      if (defined(action.payload)) {
        const deletePayload = action.payload as string[];
        deletePayload.forEach(item => {
          if (defined(state[item])) {
            delete state[item];
          }
        });
      }
      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loading, action: AnyAction) => {
  if (
    [LANDERS.FETCH_REPORTING, OFFERS.FETCH_REPORTING].includes(action.payload)
  ) {
    switch (action.type) {
      case API.STARTED:
        return {
          ...state,
          isLoading: true
        };
      case API.ENDED:
        return {
          ...state,
          isLoading: false,
          fetchedAt: Date.now()
        };
      default:
        return state;
    }
  }

  return state;
};

const editMode = (
  state = defaultState.settings.editMode,
  action: AnyAction
) => {
  return action.type === TABLE.SET_EDIT_MODE &&
    action.payload.view === VIEW.PAGEGROUPS
    ? action.payload.data
    : state;
};

const show = (state = defaultState.settings.show, action: AnyAction) => {
  switch (action.type) {
    case PAGE_GROUPS.SET_SHOW:
      return action.payload;
    default:
      return state;
  }
};

const filters = (
  state = defaultState.settings.filters,
  action: ReportingParamsAction | AnyAction,
  pageType: pageGroupsReportingTypes
) => {
  switch (action.type) {
    case PAGE_GROUPS.SET_REPORTING:
      if (action.payload.pageType === pageType) {
        return action.payload.params;
      }

      return state[pageType];
    default:
      return state[pageType];
  }
};

const reporting = (
  state = defaultState.reporting,
  action: ReportingParamsAction | AnyAction,
  pageType: pageGroupsReportingTypes
) => {
  switch (action.type) {
    case PAGE_GROUPS.SET_REPORTING:
      if (action.payload.pageType === pageType) {
        return action.payload.data;
      }

      return state[pageType];
    default:
      return state[pageType];
  }
};

const tableParams = (
  state = defaultState.settings.tableParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_TABLE_PARAMS &&
    action.payload.view === VIEW.PAGEGROUPS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const additionalParams = (
  state = defaultState.settings.additionalParams,
  action: AnyAction
) => {
  return action.type === TABLE.SET_ADDITIONAL_PARAMS &&
    action.payload.view === VIEW.PAGEGROUPS
    ? { ...state, [action.payload.name]: action.payload.data }
    : state;
};

const tableCols = (
  state = defaultState.settings.tableCols,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.payload.view === VIEW.PAGEGROUPS &&
    action.type === TABLE.SET_COLS
  ) {
    return table(state, action);
  } else {
    return state;
  }
};

export default (state = defaultState, action: any): PageGroupStore => {
  return {
    data: data(state.data, action),
    reporting: {
      landers: reporting(state.reporting, action, 'landers'),
      offers: reporting(state.reporting, action, 'offers')
    },
    loading: loading(state.loading, action),
    settings: {
      additionalParams: additionalParams(
        state.settings.additionalParams,
        action
      ),
      tableCols: tableCols(state.settings.tableCols, action),
      tableParams: tableParams(state.settings.tableParams, action),
      filters: {
        landers: filters(state.settings.filters, action, 'landers'),
        offers: filters(state.settings.filters, action, 'offers')
      },
      editMode: editMode(state.settings.editMode, action),
      show: show(state.settings.show, action)
    }
  };
};

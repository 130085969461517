import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import { ModalsConsumer } from '../context';
import { CONDITION_DELETE_MODAL } from 'constants/modal';
import { ConditionDeleteModalSelector } from 'redux/selectors';
import { ModalProps } from '../types';
import { getNameModal } from '../../../utils/modals';
import { deleteCondition } from '../../../redux/actions/conditions';
import { ConditionDeleteModalProps } from '../../../types/ModalForms/conditionDelete';
import ConditionDeleteForm from 'components/Forms/ConditionDelete';
import { defined } from '../../../utils/define';
import { FormContextModal } from '../../../types/modal';
import { fetchFunnels } from '../../../redux/actions/funnels';
import { AnyObject } from 'types';

const ConditionDeleteModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  funnels,
  contextModal,
  fetchFunnels
}: ConditionDeleteModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    destroyOnClose
    width={467}
    title={`Delete Connection ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['connection-delete']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ConditionDeleteForm
        fetchFunnels={fetchFunnels}
        handleSubmit={onSubmit}
        contextModal={contextModal}
        funnels={funnels}
        idCondition={defined(contextModal.data) ? contextModal.data.id : ''}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
      />
    )}
  />
);

const ConnectedConditionDeleteModal = ({
  deleteCondition,
  funnels,
  fetchFunnels
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <ConditionDeleteModal
            isOpen={isOpenModal(CONDITION_DELETE_MODAL)}
            fetchFunnels={fetchFunnels}
            onSubmit={deleteCondition}
            closeSelf={() => closeModal(CONDITION_DELETE_MODAL)}
            contextModal={
              getContextModal(CONDITION_DELETE_MODAL) as FormContextModal
            }
            funnels={funnels}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(ConditionDeleteModalSelector, {
  deleteCondition,
  fetchFunnels
})(ConnectedConditionDeleteModal);

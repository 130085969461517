import { navDefault } from './defaultStates';
import { NAV_ACTIONS } from '../types';
import { AnyAction } from 'redux';
import { Funnel } from 'model/funnel';
import { defined } from 'utils/define';

export default function navigation(state = navDefault, action: AnyAction) {
  switch (action.type) {
    case NAV_ACTIONS.CHANGED:
      return {
        ...state,
        prev: action.data.prev,
        current: action.data.next,
        prevPath: action.data.prevPath,
        sub: action.data.sub
      };
    case NAV_ACTIONS.BUILDER_FUNNEL_CHANGED:
      return {
        ...state,
        funnelEditorSub: !!state.funnelEditorSub.find(
          (funnel: Funnel) => funnel.idFunnel === action.data.idFunnel
        )
          ? state.funnelEditorSub
          : [...state.funnelEditorSub, action.data]
      };
    case NAV_ACTIONS.BUILDER_FUNNEL_DELETED:
      return {
        ...state,
        funnelEditorSub: state.funnelEditorSub.filter(
          (funnel: Funnel) => funnel.idFunnel !== action.data.idFunnel
        )
      };
    case NAV_ACTIONS.BUILDER_FUNNELS_UPDATE_NAME:
      const data = action.data as Funnel;
      return {
        ...state,
        funnelEditorSub: state.funnelEditorSub.map((funnel: Funnel) =>
          defined(data.idFunnel) && funnel.idFunnel === data.idFunnel
            ? {
                ...funnel,
                funnelName: data.funnelName
              }
            : funnel
        )
      };
    case NAV_ACTIONS.BUILDER_FUNNELS_DELETED:
      return {
        ...state,
        funnelEditorSub: state.funnelEditorSub.filter(
          (funnel: Funnel) => !action.data.idFunnels.includes(funnel.idFunnel)
        )
      };
    case NAV_ACTIONS.SET_SIDER_MENU_IS_EXPANDED:
      return {
        ...state,
        siderMenuIsExpanded: !state.siderMenuIsExpanded
      };
    case NAV_ACTIONS.SET_VIEW_LAST_ROUTE:
      return {
        ...state,
        lastViewRoute: {
          ...state.lastViewRoute,
          [action.payload.currentView]: action.payload.path
        }
      };
    default:
      return state;
  }
}

import { DOMAINS, SYSTEM_SETTINGS } from '../types';
import { Dispatch } from 'redux';
import { apiAction } from './api';
import {
  SYSTEM_SETTINGS as API,
  DOMAIN as API_DOMAIN
} from '../../api/endpoints';
import { UserSettings } from '../../model/userSettings';
import { DNSRecordParams } from 'types/domains';
import { CloudflaredDomain, DomainEntry } from 'model/models';
import { getCloudflaredDomain } from 'utils/domains';
import {
  addDomainToCloudflare,
  deleteDomainCloudflare,
  getDomainCloudflare
} from 'services/cloudflare';

const setSystemSettings = (payload = {}) => {
  return {
    type: SYSTEM_SETTINGS.FETCH,
    payload
  };
};

const setDomains = (payload: DomainEntry[] = []) => {
  return {
    type: DOMAINS.FETCH,
    payload
  };
};

const addSingleDomain = (payload: CloudflaredDomain) => {
  return {
    type: DOMAINS.ADD_SINGLE,
    payload
  };
};

const setDeleteDomain = (payload: DomainEntry) => {
  return {
    type: DOMAINS.DELETE,
    payload
  };
};

export const updateSettings = (data: UserSettings) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.PUT(data),
      onSuccess: () => setSystemSettings(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.UPDATE
    })
  );
};

export const addDomain = (domainEntry: DomainEntry) => (dispatch: any) => {
  return new Promise((res, rej) => {
    dispatch(
      apiAction({
        requestConfig: API_DOMAIN.PUT(domainEntry),
        onSuccess: async (data: DomainEntry) => {
          try {
            const response = await addDomainToCloudflare(data.domain);
            const cloudflaredDomain = getCloudflaredDomain(
              data,
              response.result
            );
            dispatch(addSingleDomain(cloudflaredDomain));
            res(cloudflaredDomain);
          } catch (e) {
            dispatch(deleteLegacyDomain(domainEntry));
            rej(e);
          }
        },
        onFailure: (e: Error) => {
          rej(e);
          throw e;
        },
        label: DOMAINS.UPDATE
      })
    );
  });
};

export const updateDomainData = (domainEntry: DomainEntry) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API_DOMAIN.PUT(domainEntry),
      onSuccess: () => {},
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.UPDATE
    })
  );
};

export const migrateDomain = (
  domainEntry: DomainEntry,
  prevDomainEntry: DomainEntry
) => (dispatch: any) => {
  return new Promise((res, rej) => {
    dispatch(
      apiAction({
        requestConfig: API_DOMAIN.PUT(domainEntry),
        onSuccess: async (data: DomainEntry) => {
          try {
            const response = await addDomainToCloudflare(data.domain);
            const cloudflaredDomain = getCloudflaredDomain(
              data,
              response.result
            );
            await dispatch(deleteLegacyDomain(prevDomainEntry));
            dispatch(addSingleDomain(cloudflaredDomain));
            res(cloudflaredDomain);
          } catch (e) {
            dispatch(updateDomainData(prevDomainEntry));
            rej(e);
          }
        },
        onFailure: (e: Error) => {
          throw e;
        },
        label: DOMAINS.UPDATE
      })
    );
  });
};

export const fetchDomains = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API_DOMAIN.FETCH(),
      onSuccess: (data: DomainEntry[]) => setDomains(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.UPDATE
    })
  );
};

export const deleteLegacyDomain = (domainEntry: DomainEntry) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API_DOMAIN.DELETE(domainEntry),
      onSuccess: () => setDeleteDomain(domainEntry),
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.DELETE
    })
  );
};

export const deleteCloudflaredDomain = (domainEntry: CloudflaredDomain) => (
  dispatch: Dispatch
) => {
  return new Promise((res, rej) => {
    dispatch(
      apiAction({
        requestConfig: API_DOMAIN.DELETE(domainEntry),
        onSuccess: async () => {
          try {
            const cloudflareDomain = await getDomainCloudflare(
              domainEntry.domain
            );
            await deleteDomainCloudflare(cloudflareDomain.result.id);
            dispatch(setDeleteDomain(domainEntry));
            res({});
          } catch (e) {
            rej(e);
          }
        },
        onFailure: (e: Error) => {
          rej(e);
          throw e;
        },
        label: DOMAINS.DELETE
      })
    );
  });
};

export const switchDomain = (data: UserSettings) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.PUT(data),
      onSuccess: () => {},
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.SWITCH
    })
  );
};

export const deleteDnsRecord = (data: DNSRecordParams) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE_DNS(data),
      onSuccess: () => {},
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.DELETE_DNS
    })
  );
};

export const updateDnsRecord = (data: DNSRecordParams) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.EDIT_DNS(data),
      onSuccess: () => {},
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.EDIT_DNS
    })
  );
};

export const addDnsRecord = (data: DNSRecordParams) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.ADD_DNS(data),
      onSuccess: () => {},
      onFailure: (e: Error) => {
        throw e;
      },
      label: DOMAINS.EDIT_DNS
    })
  );
};

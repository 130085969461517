import { FunnelNode } from '../../model/funnelNode';
import { Funnel } from '../../model/funnel';
import { FunnelConnection } from '../../model/funnelConnection';
import { BuilderData, Data, Node } from '../../components/Builder/types';
import { AnyObject } from '../../types';
import { defined } from '../define';
import {
  NODE_HEIGHT,
  NODE_WIDTH,
  OFFSET_NEW_NODE,
  getDefaultPageOverrides
} from '../../constants/builder';
import { FunnelCondition } from '../../model/funnelCondition';
import { FunnelConditionRoute } from '../../model/funnelConditionRoute';
import { PageGroupEntry } from '../../model/pageGroupEntry';
import { makeQueryUrl } from 'utils/url';
import { sortArray } from '../sort/index';
import { ConditionArray, PageGroupArray } from 'types/redux/store';
import { PageGroup } from 'model/models';
import { DrilldownReport } from 'model/drilldownReport';
import { HeatmapExtraParams, HeatmapReport } from 'types/heatmap';
import { DrilldownReportRow } from 'model/drilldownReportRow';
import {
  makeReportingRowData,
  reportingGetCVR,
  reportingGetProfitAndLoss,
  reportingGetReturnOnInvestment
} from 'utils/reporting';

const getNodeName = (node: FunnelNode, builderData: BuilderData) => {
  if (node.nodeName) return node.nodeName;
  switch (node.nodeType) {
    case 'condition':
      if (
        defined(node.nodeConditionParams) &&
        defined(builderData.conditionsArray)
      ) {
        const condition =
          builderData.conditionsArray?.[node.nodeConditionParams!.idCondition];

        if (
          defined(condition?.conditionName) &&
          condition?.conditionName !== ''
        ) {
          return condition?.conditionName;
        }
      }

      return node.nodeName;
    case 'offerGroup':
    case 'landerGroup':
      if (
        defined(node.nodePageGroupParams) &&
        defined(builderData.pageGroupsArray)
      ) {
        const pageGroup =
          builderData.pageGroupsArray[node.nodePageGroupParams.idPageGroup];

        if (
          defined(pageGroup?.pageGroupName) &&
          pageGroup?.pageGroupName !== ''
        ) {
          return pageGroup?.pageGroupName;
        }
      }

      return node.nodeName;
    default:
      return node.nodeName;
  }
};

export const convertFunnelNodeToPlumbData = (
  node: FunnelNode,
  builderData: BuilderData
): Node => {
  const nodeName = getNodeName(node, builderData)!;
  const nodeParams = {
    id: node.idNode,
    type: node.nodeType,
    text: nodeName,
    node: {
      ...node,
      nodeName: nodeName
    },
    w: 100,
    h: 100,
    left: node.posX || 0,
    top: node.posY || 0
  };

  const pageGroupsArray = builderData.pageGroupsArray || {};
  const conditionsArray = builderData.conditionsArray || {};

  if (
    ['offerGroup', 'landerGroup'].includes(node.nodeType) &&
    defined(pageGroupsArray) &&
    defined(node.nodePageGroupParams) &&
    defined(pageGroupsArray[node.nodePageGroupParams.idPageGroup || ''])
  ) {
    return {
      ...nodeParams,
      params: {
        pageGroup: pageGroupsArray[node.nodePageGroupParams.idPageGroup]
      }
    };
  } else if (
    node.nodeType === 'condition' &&
    defined(conditionsArray) &&
    defined(node.nodeConditionParams)
  ) {
    const condition =
      conditionsArray?.[node?.nodeConditionParams?.idCondition!];

    if (defined(condition)) {
      return {
        ...nodeParams,
        params: {
          condition: condition
        }
      };
    }
  }

  return nodeParams;
};

export const convertFunnelToPlumbData = (
  funnel: Funnel,
  builderData: BuilderData
): Data => ({
  nodes: defined(funnel.nodes)
    ? funnel.nodes.map((node: FunnelNode) =>
        convertFunnelNodeToPlumbData(node, builderData)
      )
    : [],
  edges: defined(funnel.connections)
    ? funnel.connections.map((connection: FunnelConnection) => ({
        id: connection.idConnection,
        source: connection.idSourceNode,
        target: connection.idTargetNode,
        data: {
          id: connection.idConnection,
          connection: connection
        }
      }))
    : []
});

export const getEdgeLabel = (
  edge: AnyObject,
  connection?: FunnelConnection,
  connections?: FunnelConnection[],
  condition?: FunnelCondition
) => {
  if (defined(connection)) {
    switch (edge.source.data.type) {
      case 'root':
      case 'rotator':
        let sum = defined(connections)
          ? connections.reduce((sum: number, currentConnection: any) => {
              const weight =
                defined(currentConnection.connectionRotatorParams) &&
                defined(currentConnection.connectionRotatorParams.weight)
                  ? currentConnection.connectionRotatorParams.weight
                  : 0;
              return sum + weight;
            }, 0)
          : 1;

        const weight =
          sum > 0
            ? defined(connection.connectionRotatorParams)
              ? defined(connection.connectionRotatorParams.weight)
                ? Number(
                    (connection.connectionRotatorParams.weight * 100) / sum
                  ).toFixed(2)
                : 0
              : null
            : 0;

        return !!weight || weight === 0
          ? `<div class="${weight > 0 ? 'active ' : ''}weight">${weight}%</div>`
          : '';
      case 'condition':
        const routes =
          defined(condition) && defined(condition.routes)
            ? condition.routes
            : false;

        const name =
          defined(connection.connectionConditionParams) &&
          defined(connection.connectionConditionParams.onRouteNumber) &&
          routes &&
          defined(routes[connection.connectionConditionParams.onRouteNumber]) &&
          defined(
            routes[connection.connectionConditionParams.onRouteNumber].routeName
          )
            ? routes[connection.connectionConditionParams.onRouteNumber]
                .routeName
            : null;

        return defined(name) ? `<div class="weight active">${name}</div>` : '';
      case 'lander':
      case 'offer':
      case 'landerGroup':
      case 'offerGroup':
        const numbers =
          defined(connection.connectionPageParams) &&
          defined(connection.connectionPageParams.onActionNumbers)
            ? connection.connectionPageParams.onActionNumbers
            : null;
        const postfix = connection?.connectionPageParams?.isConversion ? ' (C)' : connection?.connectionPageParams?.isCustomEvent ? ' (CE)' : '';
        let text = '';
        let tooltip = (numbers || []).map(item => `ACTION ${item}`).join(', ');
        if (defined(numbers)) {
          text = numbers.length > 2 ? 'Multiple Actions' : tooltip;
        }
        return defined(numbers)
          ? `<div class="weight active" title="${tooltip}">${text}${postfix}</div>`
          : '';
    }
  }

  return '';
};

export const getConnectionParamsByType = (
  funnelNode: FunnelNode,
  connections: FunnelConnection[]
) => {
  switch (funnelNode.nodeType) {
    case 'root':
    case 'rotator':
      return { connectionRotatorParams: { weight: 1 } };
    case 'lander':
    case 'offer':
    case 'landerGroup':
    case 'offerGroup':
      const numbers =
        connections.length > 0
          ? connections
              .filter(
                connection => connection.idSourceNode === funnelNode.idNode
              )
              .map(connection =>
                !!connection.connectionPageParams
                  ? connection.connectionPageParams?.onActionNumbers!
                  : 0
              )
              .flat()
          : [];

      return {
        connectionPageParams: {
          onActionNumbers: getFreePageConnectionNumber(numbers)
        }
      };
    default:
      return {};
  }
};

export const getActionsBriefText = (array: number[]) => {
  let actions: number[] = sortArray(array || []);
  let hashMap: { [key: number]: number[] } = {};
  let low = 0;
  let text = '';

  if (actions.length <= 3) {
    text = actions.join(', ');
    return text;
  }

  actions.forEach((act, idx) => {
    if (idx === 0) {
      low = act;
      hashMap[act] = [act];
    } else {
      if (
        !defined(actions[idx + 1]) ||
        Math.abs(act - actions[idx - 1]) > Math.abs(act - actions[idx + 1])
      ) {
        low = act;
        hashMap[act] = [act];
      } else {
        hashMap[low].push(act);
      }
    }
  });

  Object.values(hashMap).forEach(item => {
    if (text.length !== 0) {
      text += ', ';
    }
    if (item.length >= 3) {
      text += `${item[0]}-${item[item.length - 1]}`;
    } else if (item.length === 2) {
      text += `${item[0]} and ${item[1]}`;
    } else {
      text += `${item[0]}`;
    }
  });

  return text;
};

const getSortedNodes = (funnel: Funnel) => {
  return !!funnel && !!funnel.nodes
    ? funnel.nodes.sort((a: FunnelNode, b: FunnelNode) =>
        !!a.posX && !!b.posX && a.posX > b.posX ? -1 : 1
      )
    : [];
};

export const getNodesTopPosition = (funnel: Funnel) => {
  const sortedNodes = getSortedNodes(funnel);

  return !!sortedNodes && !!sortedNodes[0].posY && sortedNodes.length > 0
    ? sortedNodes.length > 1
      ? NODE_HEIGHT + NODE_HEIGHT * 1.5
      : sortedNodes[0].posY + NODE_HEIGHT
    : NODE_HEIGHT;
};

export const getNodesLeftPosition = (funnel: Funnel) => {
  const sortedNodes = getSortedNodes(funnel);

  return !!sortedNodes && !!sortedNodes[0].posX && sortedNodes.length > 0
    ? sortedNodes.length > 1
      ? sortedNodes[0].posX + NODE_WIDTH + NODE_WIDTH * 1.3
      : sortedNodes[0].posX - OFFSET_NEW_NODE - 2
    : NODE_WIDTH;
};

export const getCurrentNodeData = (funnel: Funnel, funnelNode: FunnelNode) => {
  const activeNode = funnel.nodes!.find(
    (item: FunnelNode) => item.idNode === funnelNode!.idNode
  );

  const connections = !!funnel.connections
    ? funnel.connections.filter((item: FunnelConnection) => {
        return (
          item.idSourceNode === activeNode!.idNode ||
          item.idTargetNode === activeNode!.idNode
        );
      })
    : [];

  const connectionsIds = connections.reduce(
    (acc: string[], item: FunnelConnection) => [...acc, item.idTargetNode],
    []
  );

  return {
    nodes: funnel.nodes!.filter(
      (item: FunnelNode) =>
        connectionsIds.includes(item.idNode) &&
        item.idNode !== activeNode!.idNode
    ),
    activeNode,
    connections
  };
};

export const handleScrollToElement = (toElement?: string, callback?: void) => {
  setTimeout(() => {
    if (!!toElement) {
      const element = document.getElementById(toElement);

      if (!!element && typeof element.scrollIntoView === 'function') {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });

        if (!!callback) {
          const rootElements = document.querySelectorAll(
            '.ant-modal-body form'
          );

          if (defined(rootElements[0])) {
            new IntersectionObserver(callback, {
              root: rootElements[0],
              rootMargin: '0px',
              threshold: 1.0
            }).observe(element);
          }
        }
      }
    }
  }, 300);
};

export const getFreeConditionRouteNumber = (
  connections: FunnelConnection[],
  condition: FunnelCondition
) => {
  if (defined(condition.routes)) {
    let numbers = getFreeConditionRouteNumbers(connections, condition);

    return numbers.length > 0 ? Math.min(...numbers) : 0;
  } else {
    return 0;
  }
};

export const getFreePageConnectionNumber = (numbers: number[]) => {
  for (let i = 1; i < 255; i++) {
    if (numbers.indexOf(i) === -1) {
      return [i];
    }
  }
  return [255];
};

export const getFreeConditionRouteNumbers = (
  connections: FunnelConnection[],
  condition: FunnelCondition
) => {
  let numbers: number[] = [];

  if (defined(condition.routes)) {
    condition.routes.forEach((route: FunnelConditionRoute, idx: number) => {
      if (
        !!connections &&
        !connections.find((item: FunnelConnection) => {
          return (
            defined(item.connectionConditionParams) &&
            defined(item.connectionConditionParams.onRouteNumber) &&
            Number(idx) === Number(item.connectionConditionParams.onRouteNumber)
          );
        })
      ) {
        numbers.push(idx);
      }
    });
  }

  return numbers;
};

export const getConnectionsWithRouteNumber = (
  connections: FunnelConnection[],
  connection: FunnelConnection,
  routeNumber: number
) => {
  return connections.map((item: FunnelConnection) => {
    if (item.idConnection === connection.idConnection) {
      item = {
        ...item,
        connectionConditionParams: {
          ...item.connectionConditionParams,
          onRouteNumber: routeNumber
        }
      };
    }

    return item;
  });
};

export const getNameOrderCount = (
  models: FunnelNode[],
  pageGroups: PageGroupArray,
  name: string
) => {
  let count = -1;
  const regex = /\((\d+)\)/g;
  models.forEach(item => {
    if (
      defined(pageGroups?.[item?.nodePageGroupParams?.idPageGroup!]) &&
      isPageGroupGlobal(pageGroups?.[item?.nodePageGroupParams?.idPageGroup!])
    ) {
      return item;
    }

    let matchHelper: RegExpExecArray | null = null;
    let matched: RegExpExecArray | null = null;
    while ((matchHelper = regex.exec(item.nodeName)) != null) {
      matched = matchHelper;
    }
    const countNumber =
      defined(matched) && defined(matched[0]) ? parseInt(matched[1]) : 0;
    const index = defined(matched)
      ? defined(matched[0])
        ? regex.lastIndex - matched[0].length
        : item.nodeName.length
      : item.nodeName.length;

    if (item.nodeName.slice(0, index).trim() === name) {
      count += countNumber > 0 ? 0 : 1;
      count = Math.max(count, countNumber);
    }
  });

  return count;
};

const getFunnelNodeIds = (funnel: Funnel) =>
  defined(funnel.nodes) ? funnel.nodes.map(n => n.idNode) : [];

const getFunnelConnectionIds = (funnel: Funnel) =>
  defined(funnel.connections)
    ? funnel.connections.map(c => c.idConnection)
    : [];

export const getCorrectFunnelData = (
  funnel: Funnel,
  prevFunnel?: Funnel,
  directlyDeletedConnections?: string[]
): Funnel => {
  let nodes: FunnelNode[] = [];
  let connections: FunnelConnection[] = [];

  if (defined(funnel)) {
    if (defined(prevFunnel)) {
      const currentFunnelNodeIds = getFunnelNodeIds(funnel);
      nodes = [
        ...(funnel.nodes || []),
        ...(defined(prevFunnel.nodes) && prevFunnel.nodes.length
          ? prevFunnel.nodes
              .filter(node => currentFunnelNodeIds.indexOf(node.idNode) === -1)
              .map(node => {
                return {
                  ...node,
                  status: 'deleted'
                } as FunnelNode;
              })
          : [])
      ];
    } else {
      nodes =
        defined(funnel.nodes) && funnel.nodes.length
          ? funnel.nodes.filter(node =>
              !!node.status ? node.status !== 'deleted' : true
            )
          : [];
    }

    const nodeIds = nodes.map(node => node.idNode);

    if (defined(prevFunnel) && defined(prevFunnel.connections)) {
      const currentFunnelConnectionIds = getFunnelConnectionIds(funnel);
      connections = [
        ...(funnel.connections || []),
        ...(prevFunnel.connections.length
          ? prevFunnel.connections.filter(
              connection =>
                currentFunnelConnectionIds.indexOf(connection.idConnection) ===
                  -1 &&
                directlyDeletedConnections!.indexOf(connection.idConnection) ===
                  -1
            )
          : [])
      ];
    } else {
      connections =
        defined(funnel.connections) && funnel.connections.length
          ? funnel.connections.filter(
              connection =>
                nodeIds.indexOf(connection.idTargetNode) !== -1 &&
                nodeIds.indexOf(connection.idSourceNode) !== -1
            )
          : [];
    }

    return {
      ...funnel,
      nodes: nodes,
      connections: connections
    };
  }

  return funnel;
};

export const getPageOverridesFunnelNode = (
  pages: PageGroupEntry[],
  funnelNode: FunnelNode
): {
  params: AnyObject;
  customUrlStrings: AnyObject;
  switchs: AnyObject;
} => {
  let params = {} as AnyObject;
  let customUrlStrings = {} as AnyObject;
  let switchs = {} as AnyObject;

  const hasPageOverrides =
    defined(funnelNode) &&
    defined(funnelNode!.nodePageGroupParams) &&
    defined(funnelNode!.nodePageGroupParams.pageOverrides);

  pages.forEach((page: PageGroupEntry) => {
    let data = getDefaultPageOverrides(page.idPage);
    if (hasPageOverrides) {
      data =
        funnelNode!.nodePageGroupParams!.pageOverrides!.find(
          item => item.idPage === page.idPage
        ) || getDefaultPageOverrides(page.idPage);
    }

    params[page.idPage] = data;
    customUrlStrings[page.idPage] =
      defined(data) && defined(data!.additionalTokens)
        ? makeQueryUrl(data!.additionalTokens, true)
        : '';

    switchs[page.idPage] = {
      additionalTokens: !!customUrlStrings[page.idPage],
      redirectOverride: defined(data) && !!data.redirectOverride
    };
  });

  return {
    params,
    customUrlStrings,
    switchs
  };
};

export const isPageGroupGlobal = (pageGroup: PageGroup) =>
  !pageGroup.restrictToFunnelId;

export const getLocalNodesDataFromFunnelPlusGlobal = {
  pageGroups: (globalPageGroupsArray: PageGroupArray, funnel: Funnel) => ({
    ...(globalPageGroupsArray || {}),
    ...(funnel?.nodes || []).reduce((acc: PageGroupArray, crr) => {
      if (
        (crr.nodeType === 'offerGroup' || crr.nodeType === 'landerGroup') &&
        crr.nodePageGroupParams?.idPageGroup &&
        !globalPageGroupsArray[crr.nodePageGroupParams?.idPageGroup]
      ) {
        acc = {
          ...acc,
          [crr.nodePageGroupParams?.idPageGroup]: {
            idPageGroup: crr.nodePageGroupParams?.idPageGroup,
            pageGroupName: '',
            restrictToFunnelId: funnel.idFunnel,
            pageType: crr.nodeType === 'landerGroup' ? 'lander' : 'offer',
            routing: 'rotator',
            pages: []
          }
        };
      }
      return acc;
    }, {})
  }),
  conditions: (globalConditionsArray: ConditionArray, funnel: Funnel) => ({
    ...(globalConditionsArray || {}),
    ...(funnel?.nodes || []).reduce((acc: ConditionArray, crr) => {
      if (
        crr.nodeType === 'condition' &&
        crr.nodeConditionParams?.idCondition &&
        !globalConditionsArray[crr.nodeConditionParams?.idCondition]
      ) {
        acc = {
          ...acc,
          [crr.nodeConditionParams?.idCondition]: {
            idCondition: crr.nodeConditionParams?.idCondition,
            conditionName: '',
            restrictToFunnelId: funnel.idFunnel,
            routes: []
          }
        };
      }
      return acc;
    }, {})
  })
};

export const getHeatmapReport = (
  report: DrilldownReport,
  funnel: Funnel
): HeatmapReport => {
  let heatmapReport: HeatmapReport = {};

  if (!report.rows.length) {
    (funnel.nodes || []).forEach(funnelNode => {
      heatmapReport[funnelNode.idNode] = makeReportingRowData([]);
    });
  }

  report.rows.forEach(row => {
    const splitedIds = row.attributes[0].id.split(',');
    let id = splitedIds.pop()!;
    id = id.replace(/pg\|/g, '').split(':').shift()!;

    if (!heatmapReport[id]) {
      heatmapReport[id] = row;
    } else {
      (Object.keys(row) as (keyof DrilldownReportRow)[]).forEach(key => {
        if (key !== 'attributes' && key !== 'customEvents') {
          heatmapReport[id][key] += row?.[key]!;
        }
      });
    }
  });

  return heatmapReport;
};

export const defaultHeatmapExtraParams: () => HeatmapExtraParams = () => ({
  conversionRates: {
    min: Math.min(),
    max: Math.max(),
    nodeValues: {},
    nodeValuesPercentages: {}
  },
  visitorVolume: {
    min: Math.min(),
    max: Math.max(),
    nodeValues: {}
  },
  returnOnInvestment: {
    min: Math.min(),
    max: Math.max(),
    nodeValues: {}
  },
  profitAndLoss: {
    min: Math.min(),
    max: Math.max(),
    nodeValues: {}
  },
  basicStats: {
    min: Math.min(),
    max: Math.max(),
    nodeValues: {}
  }
});

export const getHeatmapExtraParams = (
  heatmapReport: HeatmapReport
): HeatmapExtraParams => {
  let heatmapExtraParams: HeatmapExtraParams = defaultHeatmapExtraParams();

  Object.values(heatmapReport).forEach(reportRow => {
    // basic stats
    heatmapExtraParams.basicStats.max = Math.max(
      heatmapExtraParams.basicStats.max,
      reportRow.visits
    );
    heatmapExtraParams.basicStats.min = Math.min(
      heatmapExtraParams.basicStats.min,
      reportRow.visits
    );

    // visitor volume
    heatmapExtraParams.visitorVolume.max = Math.max(
      heatmapExtraParams.visitorVolume.max,
      reportRow.visits
    );
    heatmapExtraParams.visitorVolume.min = Math.min(
      heatmapExtraParams.visitorVolume.min,
      reportRow.visits
    );

    // CVR
    const cvr = reportingGetCVR(reportRow);
    heatmapExtraParams.conversionRates.max = Math.max(
      heatmapExtraParams.conversionRates.max,
      cvr
    );
    heatmapExtraParams.conversionRates.min = Math.min(
      heatmapExtraParams.conversionRates.min,
      cvr
    );

    // profit and loss
    const profitAndLoss = reportingGetProfitAndLoss(reportRow);
    heatmapExtraParams.profitAndLoss.max = Math.max(
      heatmapExtraParams.profitAndLoss.max,
      profitAndLoss
    );
    heatmapExtraParams.profitAndLoss.min = Math.min(
      heatmapExtraParams.profitAndLoss.min,
      profitAndLoss
    );

    // ROI
    const roi = reportingGetReturnOnInvestment(reportRow);
    heatmapExtraParams.returnOnInvestment.max = Math.max(
      heatmapExtraParams.returnOnInvestment.max,
      roi
    );
    heatmapExtraParams.returnOnInvestment.min = Math.min(
      heatmapExtraParams.returnOnInvestment.min,
      roi
    );
  });

  Object.keys(heatmapReport).forEach(nodeId => {
    // basic stats
    heatmapExtraParams.basicStats.nodeValuesPercentages = {
      ...heatmapExtraParams.basicStats.nodeValuesPercentages,
      [nodeId]: Math.floor(
        (heatmapReport[nodeId].visits / heatmapExtraParams.basicStats.max) * 100
      )
    };

    // visitor volume
    heatmapExtraParams.visitorVolume.nodeValues[nodeId] =
      heatmapReport[nodeId].visits;

    // CVR
    heatmapExtraParams.conversionRates.nodeValues[nodeId] = reportingGetCVR(
      heatmapReport[nodeId]
    );

    // profit and loss
    heatmapExtraParams.profitAndLoss.nodeValues[nodeId] = reportingGetProfitAndLoss(heatmapReport[nodeId]);

    // ROI
    heatmapExtraParams.returnOnInvestment.nodeValues[nodeId] = reportingGetReturnOnInvestment(heatmapReport[nodeId]);
  });

  return heatmapExtraParams;
};

export const setBuilderTooltipVisibility = (visible: boolean) => {
  localStorage.setItem('ff_showBuilderTooltip', `${visible}`);
}

export const getBuilderTooltipVisibility = () => {
  return localStorage.getItem('ff_showBuilderTooltip') === 'true';
}

import { onboarding as defaultState } from './defaultStates';
import { OnboardingStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { ONBOARDING } from '../types';

const quota = (state = defaultState.status, action: AnyAction) => {
  switch (action.type) {
    case ONBOARDING.SET_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): OnboardingStore => ({
  status: quota(state.status, action)
});

import React from 'react';
import Paths from './paths';
import { Tooltip } from 'antd';
import { defined } from '../../utils/define';
import './style.scss';

const Icon = ({ type, className = '', render = null, ...props }) => {
  const icon = Paths.get(type);
  if (defined(render)) {
    return (
      <i className={`flux-icon flux-icon-${type} ${className}`} {...props}>
        {render()}
      </i>
    );
  } else {
    return icon !== null ? (
      <i
        className={`flux-icon flux-icon-${icon.class} ${className}`}
        {...props}
      >
        {icon.render ? (
          <>
            {icon.render()}
            {props.children}
          </>
        ) : (
          <>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width={`1em`}
              height={`1em`}
              viewBox={icon.viewBox}
              fill="currentColor"
              aria-hidden="true"
              fillRule={icon.fillRule}
            >
              <path
                d={icon.path}
                transform={icon.transform}
                fill={props.fill || icon.fill}
                stroke={props.stroke}
                {...props.path}
              />
            </svg>
            {props.children}
          </>
        )}
      </i>
    ) : (
      <span className="flux-icon flux-icon-no-icon">:(</span>
    );
  }
};

export default Icon;

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import { RESET_DATA_DELETE_SIDEBAR } from 'constants/modal';
import { connect } from 'react-redux';
import { FormContextSidebar } from 'types/modal';
import ResetDataDelete from 'components/Forms/ResetDataDelete';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import { AnyObject } from 'types';
import { deleteData } from 'redux/actions/resetData';
import {
  ResetDataDeleteFormProps,
  ResetDataDeleteSidebarProps
} from 'types/ModalForms/resetDataDelete';

class ResetDataDeleteFormWrapper extends React.Component<
  Omit<ResetDataDeleteFormProps, 'setButtonGroupProps' | 'setSidebarLoading'> &
    ResetDataDeleteSidebarProps &
    LoadingProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        isOpen={this.props.isOpen!}
        hasTab={false}
        loading={this.props.loading}
        onClose={this.props.closeSelf}
        defaultOpenSideBarKey="resetDataDelete"
        dataPortalKey="resetDataDelete"
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'resetDataDelete',
            title: 'Reset Data Delete',
            render: ({ setButtonGroupProps }) => {
              return (
                <ResetDataDelete
                  {...this.props}
                  setButtonGroupProps={setButtonGroupProps}
                />
              );
            }
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(ResetDataDeleteFormWrapper);

const ConnectedFormElement = ({ deleteData }: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        openSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let contextSidebar = getContextSidebar(
          RESET_DATA_DELETE_SIDEBAR
        ) as FormContextSidebar;
        const isOpen = isOpenSidebar(RESET_DATA_DELETE_SIDEBAR);

        return (
          <FormElement
            onSubmit={deleteData}
            contextSidebar={contextSidebar}
            isOpen={isOpen}
            offsetRight={getOffsetRight(RESET_DATA_DELETE_SIDEBAR)}
            closeSelf={() => closeSidebar(RESET_DATA_DELETE_SIDEBAR)}
            zIndex={contextSidebar.zIndex!}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(null, {
  deleteData
})(ConnectedFormElement);

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import ReportingAddEditDeleteView from 'components/Forms/ReportingAddEditDeleteView';
import { addView, deleteView, editView } from 'redux/actions/reportings';
import { ModalsConsumer } from '../context';
import { REPORTING_ADD_VIEW, REPORTING_EDIT_VIEW } from 'constants/modal';
import { ModalProps } from '../types';
import { ReportingAddEditDeleteViewModalProps } from 'types/ModalForms/reportingAddView';
import { FormContextModal } from '../../../types/modal';
import { AnyObject } from 'types';

const ReportingAddViewModal = ({
  isOpen,
  onCreate,
  onEdit,
  onDelete,
  closeSelf,
  isEdit,
  contextModal
}: ReportingAddEditDeleteViewModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    width={484}
    destroyOnClose
    title={`Create New View`}
    isOpen={isOpen}
    classNames={['create-new-view']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ReportingAddEditDeleteView
        isEdit={isEdit}
        handleCreate={onCreate}
        handleEdit={onEdit}
        handleDelete={onDelete}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        contextModal={contextModal}
      />
    )}
  />
);

const ConnectedReportingAddViewModal = ({
  addView,
  editView,
  deleteView
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <ReportingAddViewModal
            isOpen={
              isOpenModal(REPORTING_ADD_VIEW) ||
              isOpenModal(REPORTING_EDIT_VIEW)
            }
            isEdit={isOpenModal(REPORTING_EDIT_VIEW)}
            onCreate={addView}
            onEdit={editView}
            onDelete={deleteView}
            contextModal={
              (isOpenModal(REPORTING_ADD_VIEW)
                ? getContextModal(REPORTING_ADD_VIEW)
                : getContextModal(REPORTING_EDIT_VIEW)) as FormContextModal
            }
            closeSelf={() => {
              closeModal(REPORTING_ADD_VIEW);
              closeModal(REPORTING_EDIT_VIEW);
            }}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(null, { addView, deleteView, editView })(
  ConnectedReportingAddViewModal
);

import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import {
  addOffer,
  updateOffer,
  getOfferById,
  duplicateOffer,
  fetchCategories as fetchOfferCategories,
  fetchOffersInfo
} from 'redux/actions/offers';
import { fetchSystemSettings } from 'redux/actions/systemSettings';
import {
  OFFER_CATEGORY_MODAL,
  OFFER_DUPLICATE_MODAL,
  OFFER_MODAL,
  OFFER_SOURCE_MODAL
} from 'constants/modal';
import { connect } from 'react-redux';
import { OfferModalSelector } from 'redux/selectors';
import {
  OfferFormProps,
  OfferSidebarProps,
  OffersTabKey
} from 'types/ModalForms/offers';
import { getModalTitle, isCopyByContextModal } from 'utils/modals';
import { FormContextSidebar } from 'types/modal';
import {
  fetchOfferSources,
  fetchCategories as fetchOfferSourceCategories
} from 'redux/actions/offersources';
import OfferForm from 'components/Forms/Offer';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import {
  OFFER_FORM_TAB,
  OFFER_FORM_CONFIGURE_DATA_PASSING_TAB,
  OFFER_FORM_CONVERSION_TRACKING_TAB,
  OFFER_FORM_HELP_TAB,
  OFFER_FORM_INTEGRATION_PRODUCT_IDS_TAB,
  OFFER_FORM_JAVASCRIPT_TAB,
  OFFER_FORM_PAGE_ACTION_LINKS_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import { fetchDomains } from 'redux/actions/domains';
import { FFIcon } from 'uikit';

class OfferFormWrapper extends React.Component<
  OfferFormProps & OfferSidebarProps & LoadingProps,
  {}
> {
  render() {
    return (
      <DynamicSideBar
        isOpen={this.props.isOpen!}
        hasTab={true}
        loading={this.props.loading}
        onClose={this.props.closeSelf}
        defaultOpenSideBarKey="offerForm"
        defaultActiveTab={OFFER_FORM_TAB}
        dataPortalKey="offer"
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'offerForm',
            tabs: {
              [OFFER_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [OFFER_FORM_CONFIGURE_DATA_PASSING_TAB]: {
                title: 'Configure Data Passing',
                icon: <FFIcon name="dataPassing" />
              },
              [OFFER_FORM_PAGE_ACTION_LINKS_TAB]: {
                title: 'Page Action Links',
                icon: <FFIcon name="actionLink" />,
                isHidden: isCopyByContextModal(this.props.contextModal)
              },
              [OFFER_FORM_JAVASCRIPT_TAB]: {
                title: 'Javascript Tracking',
                icon: <FFIcon name="jsTracking" />,
                isHidden: isCopyByContextModal(this.props.contextModal)
              },
              [OFFER_FORM_CONVERSION_TRACKING_TAB]: {
                title: 'Conversion Tracking',
                icon: <FFIcon name="conversionTracking" />,
                isHidden: isCopyByContextModal(this.props.contextModal)
              },
							// [OFFER_FORM_INTEGRATION_PRODUCT_IDS_TAB]: {
              //   title: 'Integration Product IDs',
              //   icon: <FFIcon name="productIds" />,
              //   isHidden: isCopyByContextModal(this.props.contextModal)
              // },
              [OFFER_FORM_HELP_TAB]: {
                title: 'Help',
                icon: <FFIcon name="question" />,
              }
            },
            title: `${getModalTitle(this.props.contextModal)} Offer`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setForCopyIdsProps,
              setLoading,
              sidebarLoading,
              setTabsError,
              tabTitle
            }) => {
              return (
                <>
                  <OfferForm
                    {...this.props}
                    activeTab={activeTab as OffersTabKey}
                    setForCopyIdsProps={setForCopyIdsProps}
                    setButtonGroupProps={setButtonGroupProps}
                    setSidebarLoading={setLoading}
                    sidebarLoading={sidebarLoading}
                    tabTitle={tabTitle}
                    setTabsError={setTabsError}
                  />
                </>
              );
            }
          }
        ]}
      />
    );
  }
}

const FormElement = withLoading(OfferFormWrapper);

const ConnectedFormElement = ({
  addOffer,
  updateOffer,
  duplicateOffer,
  offers,
  domain,
  customDomains,
  redirectType,
  categories,
  offerSources,
  offerSourcesCategories,
  getOfferById,
  fetchSystemSettings,
  fetchOfferSourceCategories,
  fetchOfferSources,
  fetchOfferCategories,
  fetchDomains,
  fetchOffersInfo
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        openSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let sidebarName = OFFER_MODAL;
        let contextSidebar = getContextSidebar(
          OFFER_MODAL
        ) as FormContextSidebar;

        if (isOpenSidebar(OFFER_DUPLICATE_MODAL)) {
          sidebarName = OFFER_DUPLICATE_MODAL;
          contextSidebar = getContextSidebar(
            OFFER_DUPLICATE_MODAL
          ) as FormContextSidebar;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            offers
          );
        }
        const isOpen =
          isOpenSidebar(OFFER_MODAL) || isOpenSidebar(OFFER_DUPLICATE_MODAL);

        return (
          <FormElement
            fetchOffersInfo={fetchOffersInfo}
            fetchDomains={fetchDomains}
            fetchCategories={fetchOfferCategories}
            offers={offers}
            categories={categories}
            domain={domain}
            customDomains={customDomains}
            fetchSystemSettings={fetchSystemSettings}
            redirectType={redirectType}
            contextModal={contextSidebar}
            getOfferById={getOfferById}
            offerSources={offerSources}
            offerSourcesCategories={offerSourcesCategories}
            handleCreate={addOffer}
            handleUpdate={updateOffer}
            handleDuplicate={duplicateOffer}
            openOfferSourceModal={() => openSidebar(OFFER_SOURCE_MODAL)}
            fetchOfferSourceCategories={fetchOfferSourceCategories}
            openAddNewCategory={() => openSidebar(OFFER_CATEGORY_MODAL)}
            isOpen={isOpen}
            closeSelf={() => closeSidebar(sidebarName)}
            zIndex={contextSidebar.zIndex!}
            offsetRight={getOffsetRight(sidebarName)}
            fetchOfferSources={fetchOfferSources}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(OfferModalSelector, {
  addOffer,
  updateOffer,
  duplicateOffer,
  getOfferById,
  fetchSystemSettings,
  fetchOfferSourceCategories,
  fetchOfferSources,
  fetchOfferCategories,
  fetchDomains,
  fetchOffersInfo
})(ConnectedFormElement);

import { FFSelectOption } from 'uikit/types/select';

export const languages: FFSelectOption[] = [
  { value: 'ab', label: 'Abkhazian' },
  { value: 'aa', label: 'Afar' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'All Arabic' },
  { value: 'ar-dz', label: 'Arabic (Algeria)' },
  { value: 'ar-bh', label: 'Arabic (Bahrain)' },
  { value: 'ar-eg', label: 'Arabic (Egypt)' },
  { value: 'ar-iq', label: 'Arabic (Iraq)' },
  { value: 'ar-jo', label: 'Arabic (Jordan)' },
  { value: 'ar-kw', label: 'Arabic (Kuwait)' },
  { value: 'ar-lb', label: 'Arabic (Lebanon)' },
  { value: 'ar-ly', label: 'Arabic (Libya)' },
  { value: 'ar-ma', label: 'Arabic (Morocco)' },
  { value: 'ar-om', label: 'Arabic (Oman)' },
  { value: 'ar-ps', label: 'Arabic (Palestine)' },
  { value: 'ar-qa', label: 'Arabic (Qatar)' },
  { value: 'ar-sa', label: 'Arabic (Saudi Arabia)' },
  { value: 'ar-sy', label: 'Arabic (Syria)' },
  { value: 'ar-tn', label: 'Arabic (Tunisia)' },
  { value: 'ar-ae', label: 'Arabic (United Arab Emirates)' },
  { value: 'ar-ye', label: 'Arabic (Yemen)' },
  { value: 'an', label: 'Aragonese' },
  { value: 'hy', label: 'Armenian' },
  { value: 'as', label: 'Assamese' },
  { value: 'ast', label: 'Asturian' },
  { value: 'ay', label: 'Aymara' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'ba', label: 'Bashkir' },
  { value: 'eu', label: 'Basque' },
  { value: 'bn', label: 'Bengali' },
  { value: 'dz', label: 'Bhutani' },
  { value: 'bh', label: 'Bihari' },
  { value: 'bi', label: 'Bislama' },
  { value: 'br', label: 'Breton' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'my', label: 'Burmese' },
  { value: 'be', label: 'Byelorussian (Belarusian)' },
  { value: 'km', label: 'Cambodian' },
  { value: 'ca', label: 'Catalan' },
  { value: 'zh', label: 'All Chinese' },
  { value: 'zh-hk', label: 'Chinese (Hong-Kong)' },
  { value: 'zh-cn', label: 'Chinese (PRC)' },
  { value: 'zh-sg', label: 'Chinese (Singapore)' },
  { value: 'zh-tw', label: 'Chinese (Taiwan)' },
  { value: 'co', label: 'Corsican' },
  { value: 'cr', label: 'Cree' },
  { value: 'cv', label: 'Chuvash' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'nl', label: 'All Dutch' },
  { value: 'nl-be', label: 'Dutch (Belgian)' },
  { value: 'nl-nl', label: 'Dutch (Netherlands)' },
  { value: 'en', label: 'All English' },
  { value: 'en-au', label: 'English (Australia)' },
  { value: 'en-bz', label: 'English (Belize)' },
  { value: 'en-ca', label: 'English (Canada)' },
  { value: 'en-ie', label: 'English (Ireland)' },
  { value: 'en-jm', label: 'English (Jamaica)' },
  { value: 'en-nz', label: 'English (New Zealand)' },
  { value: 'en-ph', label: 'English (Philippines)' },
  { value: 'en-za', label: 'English (South Africa)' },
  { value: 'en-tt', label: 'English (Trinidad and Tobago)' },
  { value: 'en-gb', label: 'English (United Kingdom)' },
  { value: 'en-us', label: 'English (United States)' },
  { value: 'en-zw', label: 'English (Zimbabwe)' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'et', label: 'Estonian' },
  { value: 'fo', label: 'Faeroese' },
  { value: 'fa', label: 'Farsi (Persian)' },
  { value: 'fj', label: 'Fiji' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'All French' },
  { value: 'fr-be', label: 'French (Belgium)' },
  { value: 'fr-ca', label: 'French (Canada)' },
  { value: 'fr-fr', label: 'French (France)' },
  { value: 'fr-lu', label: 'French (Luxembourg)' },
  { value: 'fr-mc', label: 'French (Monaco)' },
  { value: 'fr-ch', label: 'French (Switzerland)' },
  { value: 'fy', label: 'Frisian' },
  { value: 'gl', label: 'Galician' },
  { value: 'gd', label: 'Gaelic (Scottish)' },
  { value: 'gd-ie', label: 'Gaelic (Irish)' },
  { value: 'gv', label: 'Gaelic (Manx)' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'All German' },
  { value: 'de-at', label: 'German (Austria)' },
  { value: 'de-de', label: 'German (Germany)' },
  { value: 'de-li', label: 'German (Liechtenstein)' },
  { value: 'de-lu', label: 'German (Luxembourg)' },
  { value: 'de-ch', label: 'German (Switzerland)' },
  { value: 'el', label: 'Greek' },
  { value: 'kl', label: 'Greenlandic' },
  { value: 'gn', label: 'Guarani' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'ht', label: 'Haitian Creole' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he|iw', label: 'Hebrew' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'is', label: 'Icelandic' },
  { value: 'io', label: 'Ido' },
  { value: 'id|in', label: 'Indonesian' },
  { value: 'ia', label: 'Interlingua' },
  { value: 'ie', label: 'Interlingue' },
  { value: 'iu', label: 'Inuktitut' },
  { value: 'ik', label: 'Inupiak' },
  { value: 'ga', label: 'Irish' },
  { value: 'it', label: 'All Italian' },
  { value: 'it-it', label: 'Italian (Italy)' },
  { value: 'it-ch', label: 'Italian (Switzerland)' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'kn', label: 'Kannada' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'rw', label: 'Kinyarwanda (Ruanda)' },
  { value: 'ky', label: 'Kirghiz' },
  { value: 'rn', label: 'Kirundi (Rundi)' },
  { value: 'ko', label: 'All Korean' },
  { value: 'ko-kp', label: 'Korean (North Korea)' },
  { value: 'ko-kr', label: 'Korean (South Korea)' },
  { value: 'ku', label: 'Kurdish' },
  { value: 'lo', label: 'Laothian' },
  { value: 'la', label: 'Latin' },
  { value: 'lv', label: 'Latvian (Lettish)' },
  { value: 'li', label: 'Limburgish (Limburger)' },
  { value: 'ln', label: 'Lingala' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt', label: 'Maltese' },
  { value: 'mi', label: 'Maori' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mo', label: 'Moldavian' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'na', label: 'Nauru' },
  { value: 'ne', label: 'Nepali' },
  { value: 'no', label: 'Norwegian' },
  { value: 'nb', label: 'Norwegian (Bokmal)' },
  { value: 'nn', label: 'Norwegian (Nynorsk)' },
  { value: 'oc', label: 'Occitan' },
  { value: 'or', label: 'Oriya' },
  { value: 'om', label: 'Oromo (Afaan Oromo)' },
  { value: 'ps', label: 'Pashto (Pushto)' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'All Portuguese' },
  { value: 'pt-pt', label: 'Portuguese (Portugal)' },
  { value: 'pt-bz', label: 'Portuguese (Brazil)' },
  { value: 'pa', label: 'All Punjabi' },
  { value: 'pa-in', label: 'Punjabi (India)' },
  { value: 'pa-pk', label: 'Punjabi (Pakistan)' },
  { value: 'qu', label: 'Quechua' },
  { value: 'rm', label: 'Rhaeto-Romance' },
  { value: 'ro', label: 'All Romanian' },
  { value: 'ro-ro', label: 'Romanian (Romania)' },
  { value: 'ro-mo', label: 'Romanian (Moldavia)' },
  { value: 'ru', label: 'All Russian' },
  { value: 'ru-ru', label: 'Russian (Russia)' },
  { value: 'ru-mo', label: 'Russian (Moldavia)' },
  { value: 'sm', label: 'Samoan' },
  { value: 'sg', label: 'Sangro' },
  { value: 'sa', label: 'Sanskrit' },
  { value: 'sr', label: 'Serbian' },
  { value: 'sh', label: 'Serbo-Croatian' },
  { value: 'st', label: 'Sesotho' },
  { value: 'tn', label: 'Setswana' },
  { value: 'sn', label: 'Shona' },
  { value: 'ii', label: 'Sichuan Yi' },
  { value: 'sd', label: 'Sindhi' },
  { value: 'si', label: 'Sinhalese' },
  { value: 'ss', label: 'Siswati' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'so', label: 'Somali' },
  { value: 'es', label: 'All Spanish' },
  { value: 'es-ar', label: 'Spanish (Argentina)' },
  { value: 'es-bo', label: 'Spanish (Bolivia)' },
  { value: 'es-cl', label: 'Spanish (Chile)' },
  { value: 'es-co', label: 'Spanish (Colombia)' },
  { value: 'es-cr', label: 'Spanish (Costa Rica)' },
  { value: 'es-do', label: 'Spanish (Dominican Republic)' },
  { value: 'es-ec', label: 'Spanish (Ecuador)' },
  { value: 'es-sv', label: 'Spanish (Al Salvador)' },
  { value: 'es-gt', label: 'Spanish (Guatemala)' },
  { value: 'es-hn', label: 'Spanish (Honduras)' },
  { value: 'es-mx', label: 'Spanish (Mexico)' },
  { value: 'es-ni', label: 'Spanish (Nicaragua)' },
  { value: 'es-pa', label: 'Spanish (Panama)' },
  { value: 'es-py', label: 'Spanish (Paraguay)' },
  { value: 'es-pe', label: 'Spanish (Peru)' },
  { value: 'es-pr', label: 'Spanish (Puerto Rico)' },
  { value: 'es-es', label: 'Spanish (Spain)' },
  { value: 'es-uy', label: 'Spanish (Uruguay)' },
  { value: 'es-ve', label: 'Spanish (Venezuela)' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sw', label: 'Swahili (Kiswahili)' },
  { value: 'sv', label: 'All Swedish' },
  { value: 'sv-fi', label: 'Swedish (Finland)' },
  { value: 'sv-sv', label: 'Swedish (Sweden)' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'tg', label: 'Tajik' },
  { value: 'ta', label: 'Tamil' },
  { value: 'tt', label: 'Tatar' },
  { value: 'te', label: 'Telugu' },
  { value: 'th', label: 'Thai' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'to', label: 'Tonga' },
  { value: 'ts', label: 'Tsonga' },
  { value: 'tr', label: 'Turkish' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tw', label: 'Twi' },
  { value: 'ug', label: 'Uighur' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vo', label: 'Volapük' },
  { value: 'wa', label: 'Wallon' },
  { value: 'cy', label: 'Welsh' },
  { value: 'wo', label: 'Wolof' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'yi', label: 'Yiddish' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'zu', label: 'Zulu' }
];

import React from 'react';
import { Tooltip, TooltipProps } from 'antd';
import InfoIcon from 'imgs/info.png';
import InfoRedIcon from 'imgs/info-red.png';
import { conditionalClass } from 'conditional-class';
import './style.scss';

const IconTooltip = ({
  body,
  isError = false
}: Omit<TooltipProps, 'overlay'> & {
  body: string | JSX.Element;
  isError?: boolean;
}) => (
  <Tooltip
    title={body}
    overlayClassName={conditionalClass('c-ffIconTooltip__overlay')}
  >
    <img src={isError ? InfoRedIcon : InfoIcon} alt="info icon" />
  </Tooltip>
);

export default IconTooltip;

import React, { Component } from 'react';
import { ModalButtonGroup } from '../../Parts/Groups';
import { Funnel } from '../../../model/funnel';
import { Select } from '../../Parts/Inputs';
import { History, OptionCategory } from '../../../types';
import { defined } from '../../../utils/define';
import { FunnelGroupArray } from '../../../types/redux/store';
import { NAVIGATION_CREATE_OR_OPEN_MODAL } from '../../../constants/modal';
import { getCategorizedFunnelsList } from 'utils/selectors';
import { AddFunnelEditor, FetchFunnelGroupsInfo } from 'types/actions';

interface Props {
  onClose(): void;
  funnels: Funnel[];
  funnelGroups: FunnelGroupArray;
  fetchFunnelGroupsInfo: FetchFunnelGroupsInfo;
  addFunnelEditor: AddFunnelEditor;
  selectedFunnelIds: string[];
  history: History;
  closeModal(modalName: string): void;
}

interface State {
  value: string;
}

export default class NavigationTab extends Component<Props, State> {
  state = {
    value: ''
  };

  componentDidMount() {
    this.props.fetchFunnelGroupsInfo('not-deleted');
  }

  onSubmit = () => {
    const funnel = this.props.funnels.find(
      funnel => funnel.idFunnel === this.state.value
    );
    if (defined(funnel)) {
      this.props.addFunnelEditor({
        idFunnel: funnel.idFunnel,
        funnelName: funnel.funnelName
      });

      this.props.history.push(`/funneleditor/${funnel.idFunnel}`);
      this.props.onClose();
      this.props.closeModal(NAVIGATION_CREATE_OR_OPEN_MODAL);
    }
  };

  getFunnelOptions = (): OptionCategory[] =>
    getCategorizedFunnelsList(
      this.props.funnels,
      this.props.funnelGroups,
      (funnel: Funnel) =>
        !this.props.selectedFunnelIds.includes(funnel.idFunnel) && funnel.funnelType !== 'flow'
    );

  render() {
    return (
      <div className="flex flex-col flex-gap-15 padding-x-25 padding-y-20">
        <Select
          value={this.state.value}
          options={this.getFunnelOptions()}
          onChange={(value: string) => this.setState({ value: value })}
          placeholder="Select Funnel"
          showSearch={true}
          groupOptions={true}
          groupBy="category"
          filterOption={true}
          valueGetter={(option: OptionCategory) => option.id}
          labelGetter={(option: OptionCategory) => option.name}
        />
        <ModalButtonGroup
          showOk
          okText="Add"
          onOkClicked={this.onSubmit}
          okDisabled={!this.state.value}
          showCancel
          cancelText="Cancel"
          onCancelClicked={this.props.onClose}
        />
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import NodeDeleteForm from 'components/Forms/NodeDelete';
import { PageGroupDeleteModalSelector } from 'redux/selectors';
import { ModalsConsumer } from '../context';
import { PAGE_GROUPS_DELETE } from 'constants/modal';
import { getEntityId } from '../../../utils/model';
import { ModalProps } from '../types';
import { FormContextModal } from '../../../types/modal';
import { getNameModal } from '../../../utils/modals';
import { deletePageGroup } from '../../../redux/actions/pageGroups';
import { PageGroupDeleteModalProps } from '../../../types/ModalForms/pageGroupDelete';
import { AnyObject } from 'types';

const ModalContent = () => {
  return (
    <div className="margin-bottom-15 color-465565">
      <p>
        <strong>
          Deleting a page group removes it from the current view but not from
          existing stats or funnels.
        </strong>
      </p>
      <p>The group will appear in reporting as “Page Group Name (deleted)”.</p>
      <p>
        Deleting a page group is not reversible but will NOT break funnels that
        use the group – existing groups nodes will remain and use the existing
        config, which you can still control.
      </p>
      <p>
        However, you will not be able to add the group as a new node in funnels.
      </p>
      <p>Click delete below to confirm removal.</p>
    </div>
  );
};

const PageGroupDeleteModal = ({
  isOpen,
  onSubmit,
  closeSelf,
  pageGroups,
  contextModal
}: PageGroupDeleteModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    destroyOnClose
    width={422}
    title={`Delete Page Group ${getNameModal(contextModal)}`}
    isOpen={isOpen}
    classNames={['page-group-delete']}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <NodeDeleteForm
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        prepareData={(context: FormContextModal) => ({
          entries: [getEntityId(context.data)]
        })}
        data={pageGroups}
        contextModal={contextModal}
        type="page group"
        customContent={ModalContent()}
      />
    )}
  />
);

const ConnectedPageGroupDeleteModal = ({
  deletePageGroup,
  pageGroups
}: AnyObject) => {
  return (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => (
        <>
          <PageGroupDeleteModal
            isOpen={isOpenModal(PAGE_GROUPS_DELETE)}
            onSubmit={deletePageGroup}
            contextModal={
              getContextModal(PAGE_GROUPS_DELETE) as FormContextModal
            }
            pageGroups={pageGroups}
            closeSelf={() => closeModal(PAGE_GROUPS_DELETE)}
          />
        </>
      )}
    </ModalsConsumer>
  );
};

export default connect(PageGroupDeleteModalSelector, { deletePageGroup })(
  ConnectedPageGroupDeleteModal
);

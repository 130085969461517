import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import { withLoading } from 'components/Loading';
import { QuickStatsSidebarProps } from 'types/quickstats';
import { QUICK_STATS_MODAL } from 'constants/modal';
import './style.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CenterLoading } from 'components/FluxLoading';

const QuickStats = React.lazy(() => import('../../QuickStats'));

class QuickStatsWrapper extends React.Component<QuickStatsSidebarProps, {}> {
  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen!}
        onClose={this.props.onClose!}
        dataPortalKey="quickstats"
        defaultOpenSideBarKey="quickstats"
        zIndex={this.props.zIndex}
        sideBars={[
          {
            key: 'quickstats',
            title: `Quick Stats`,
            showReload: true,
            showDateTimePicker: true,
            render: ({
              setButtonGroupProps,
              setRefreshProps,
              setDateTimePickerProps
            }) => {
              return (
                <div className="c-quickstatsSidebar">
                  <React.Suspense fallback={<CenterLoading show={true} />}>
                    <QuickStats
                      {...this.props}
                      setButtonGroupProps={setButtonGroupProps}
                      onClose={this.props.onClose}
                      open={this.props.isOpen}
                      closeQuickStats={this.props.onClose}
                      setRefreshProps={setRefreshProps}
                      setDateTimePickerProps={setDateTimePickerProps}
                    />
                  </React.Suspense>
                </div>
              );
            }
          }
        ]}
      />
    );
  }
}

const QS = withLoading(QuickStatsWrapper);
class QuickStatsSidebar extends React.Component<RouteComponentProps> {
  render() {
    return (
      <SidebarsConsumer>
        {({ isOpenSidebar, closeSidebar, getContextSidebar }: SidebarProps) => {
          const isOpen = isOpenSidebar(QUICK_STATS_MODAL);
          const contextSidebar = getContextSidebar(QUICK_STATS_MODAL);

          return (
            <QS
              isOpen={isOpen}
              zIndex={contextSidebar.zIndex!}
              onClose={async () => {
                await closeSidebar(QUICK_STATS_MODAL);
                if (contextSidebar.quickstats?.onClose) {
                  contextSidebar.quickstats.onClose();
                }
              }}
              viewType={contextSidebar.quickstats?.viewType!}
              isFunnelEditor={contextSidebar.quickstats?.isFunnelEditor!}
              data={contextSidebar.quickstats?.data!}
              history={this.props.history}
              isCampaignAnalysis={Boolean(
                contextSidebar.quickstats?.data.isCampaignAnalysis
              )}
              showOpenInReporting={contextSidebar.quickstats?.data.showOpenInReporting}
            />
          );
        }}
      </SidebarsConsumer>
    );
  }
}

export default withRouter(QuickStatsSidebar);

import { apiAction } from './api';
import { CONDITIONS } from '../types';
import { CONDITIONS as API, CATEGORIES } from 'api/axios';
import { Dispatch } from 'redux';
import { DeleteList } from '../../model/deleteList';
import { Category } from '../../model/category';
import { FunnelCondition } from '../../model/funnelCondition';
import { FindByNameSchema } from 'model/findByName';
import { CustomError } from 'types';
import { defined } from 'utils/define';
import { StatusEnum } from 'api/types';
import { FunnelConditionInfo } from 'model/funnelConditionInfo';
import { FunnelConditionCategoryInfo } from 'model/funnelConditionCategoryInfo';
import { GetConditionById } from 'types/actions';

const setConditionsList = (payload: FunnelCondition[] = []) => {
  return {
    type: CONDITIONS.SET_LIST,
    payload
  };
};

const setConditionsCategoryInfo = (
  payload: FunnelConditionCategoryInfo[] = []
) => {
  return {
    type: CONDITIONS.SET_CATEGORY_INFO,
    payload
  };
};

const setConditionsInfo = (payload: FunnelConditionInfo[] = []) => {
  return {
    type: CONDITIONS.SET_INFO,
    payload
  };
};

const setCondition = (payload: FunnelCondition) => {
  return {
    type: CONDITIONS.ADD_SINGLE,
    payload
  };
};

const setExistedCondition = (payload: FunnelCondition) => {
  return {
    type: CONDITIONS.ADD_EXISTED_SINGLE,
    payload
  };
};

const conditionUpdate = (data: FunnelCondition) => {
  return {
    type: CONDITIONS.UPDATE_SINGLE,
    payload: data
  };
};

const setCategoryList = (payload: Category[] = []) => {
  return {
    type: CONDITIONS.SET_CATEGORIES,
    payload
  };
};

const createCategories = (payload = {}) => {
  return {
    type: CONDITIONS.UPDATE_CATEGORY,
    payload
  };
};

const updateCategories = (payload = {}) => {
  return {
    type: CONDITIONS.CREATE_CATEGORY,
    payload
  };
};

const deleteCategory = (payload = {}) => {
  return {
    type: CONDITIONS.DELETE_CATEGORY,
    payload
  };
};

const conditionDelete = (payload: string) => {
  return {
    type: CONDITIONS.DELETE,
    payload
  };
};

export const fetchConditions = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST(),
      onSuccess: (data: FunnelCondition[]) => dispatch(setConditionsList(data)),
      onFailure: (e: Error) => {
        throw e;
      },
      label: CONDITIONS.FETCH_LIST
    })
  );
};

export const fetchConditionsByIds = (ids: string[]) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_LIST_BY_IDS(ids),
      onSuccess: (data: FunnelCondition[]) => dispatch(setConditionsList(data)),
      onFailure: (e: Error) => {
        throw e;
      },
      label: CONDITIONS.FETCH_LIST
    })
  );
};

export const fetchConditionsCategoryInfo = (
  status: StatusEnum,
  idFunnel?: string,
  includeChilds = true
) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_CATEGORY_INFO(status, idFunnel, includeChilds),
      onSuccess: (data: FunnelConditionCategoryInfo[]) =>
        dispatch(setConditionsCategoryInfo(data)),
      onFailure: (e: Error) => {
        throw e;
      },
      label: CONDITIONS.FETCH_CATEGORY_INFO
    })
  );
};

export const fetchConditionsInfo = (status: StatusEnum, idFunnel?: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.FETCH_INFO(status, idFunnel),
      onSuccess: (data: FunnelConditionInfo[]) =>
        dispatch(setConditionsInfo(data)),
      onFailure: (e: Error) => {
        throw e;
      },
      label: CONDITIONS.FETCH_INFO
    })
  );
};

export const fetchCategories = () => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: CATEGORIES.FETCH_LIST('condition'),
      onSuccess: (data: Category[]) => setCategoryList(data),
      onFailure: (e: Error) => {
        throw e;
      },
      label: CONDITIONS.FETCH_INIT
    })
  );
};

export const addCondition = (
  data: FunnelCondition,
  saveLocally = false
) => async (dispatch: Dispatch) => {
  if (saveLocally) {
    await dispatch(setCondition(data));
  } else {
    return dispatch(
      apiAction({
        requestConfig: API.CREATE(data),
        onSuccess: () => setCondition(data),
        onFailure: (e: Error) => {
          throw e;
        },
        label: CONDITIONS.ADD_SINGLE
      })
    );
  }
};

export const duplicateCondition = (oldData: FunnelCondition) => {
  return apiAction({
    requestConfig: API.DUPLICATE(oldData),
    onSuccess: (data: FunnelCondition) => setCondition(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: CONDITIONS.ADD_SINGLE
  });
};

export const findConditionByName = (
  data: FindByNameSchema,
  customError?: CustomError
) => {
  return apiAction({
    requestConfig: API.FIND_BY_NAME(data),
    onSuccess: (condition: FunnelCondition) => {
      if (defined(condition?.idCondition) && condition.status === 'active') {
        throw customError;
      }
    },
    onFailure: (e: Error) => {
      throw e;
    },
    label: CONDITIONS.FIND_BY_NAME
  });
};

export const getConditionById: GetConditionById = (idCondition: string) => (
  dispatch: Dispatch
) => {
  return dispatch(
    apiAction({
      requestConfig: API.GET(idCondition),
      onSuccess: (condition: FunnelCondition) => setExistedCondition(condition),
      onFailure: (e: Error) => {
        throw e;
      },
      label: CONDITIONS.ADD_SINGLE
    })
  );
};

export const updateCondition = (
  data: FunnelCondition,
  saveLocally = false
) => async (dispatch: Dispatch) => {
  if (saveLocally) {
    await dispatch(conditionUpdate(data));
  } else {
    return dispatch(
      apiAction({
        requestConfig: API.UPDATE(data),
        onSuccess: () => conditionUpdate(data),
        onFailure: (e: Error) => {
          throw e;
        },
        label: CONDITIONS.UPDATE_SINGLE
      })
    );
  }
};

export const updateConditionCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.UPDATE(data),
    onSuccess: () => createCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: CONDITIONS.ADD_SINGLE_CATEGORY
  });
};

export const dispatchUpdateConditionCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(updateConditionCategories(data));
};

export const createConditionCategories = (data: Category) => {
  return apiAction({
    requestConfig: CATEGORIES.CREATE(data),
    onSuccess: () => updateCategories(data),
    onFailure: (e: Error) => {
      throw e;
    },
    label: CONDITIONS.ADD_SINGLE_CATEGORY
  });
};

export const dispatchCreateConditionCategories = (data: Category) => (
  dispatch: Dispatch
) => {
  return dispatch(createConditionCategories(data));
};

export const deleteConditionCategory = (data: DeleteList) => {
  return apiAction({
    requestConfig: CATEGORIES.DELETE(data),
    onSuccess: () => deleteCategory(data.entries),
    onFailure: (e: Error) => {
      throw e;
    },
    label: CONDITIONS.ADD_SINGLE_CATEGORY
  });
};

export const dispatchDeleteConditionCategory = (data: DeleteList) => (
  dispatch: Dispatch
) => {
  return dispatch(deleteConditionCategory(data));
};

export const deleteCondition = (data: DeleteList) => (dispatch: Dispatch) => {
  return dispatch(
    apiAction({
      requestConfig: API.DELETE(data),
      onSuccess: () => conditionDelete(data.entries[0]),
      onFailure: (e: Error) => {
        throw e;
      },
      label: CONDITIONS.DELETE
    })
  );
};

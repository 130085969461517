import React from 'react';
import { connect } from 'react-redux';
import FluxModal from 'components/Modal';
import { CategoryModalSelector } from 'redux/selectors';
import { ModalsConsumer } from '../context';
import { archiveCategory } from 'redux/actions/categories';
import {
  LANDER_CATEGORY_ARCHIVE_MODAL,
  LANDER_CATEGORY_TYPE,
  OFFER_CATEGORY_ARCHIVE_MODAL,
  OFFER_CATEGORY_TYPE,
  OFFER_SOURCE_CATEGORY_ARCHIVE_MODAL,
  OFFER_SOURCE_CATEGORY_TYPE,
  UNCATEGORIZED,
  categoryTypeSourceNameMap
} from 'constants/modal';
import ConfirmForm from '../../Forms/Confirm';
import {
  TRAFFIC_SOURCE_CATEGORY_ARCHIVE_MODAL,
  TRAFFIC_SOURCE_CATEGORY_TYPE
} from '../../../constants/modal';
import { ModalProps } from '../types';
import {
  CategoryArchiveReduxProps,
  CategoryArchiveModalProps
} from 'types/ModalForms/category';
import { FormContextModal } from '../../../types/modal';
import { getNameModal } from '../../../utils/modals';
import { getOffersByCategory } from 'redux/actions/offers';
import { getOfferSourcesByCategory } from 'redux/actions/offersources';
import { getTrafficSourcesByCategory } from 'redux/actions/trafficsources';
import { getLandersByCategory } from 'redux/actions/landers';

const ArchiveCategoryText = (props: { categoryType: string }) => {
  return (
    <div className="margin-bottom-15 color-465565">
      <p>
        Archiving this category will move all{' '}
        {categoryTypeSourceNameMap[props.categoryType]} in it to{' '}
        <strong>{UNCATEGORIZED}</strong>.
      </p>
      <p>
        Are you sure you want to archive this category? (it can be restored
        later).
      </p>
    </div>
  );
};

export const CategoryArchive = ({
  isOpen,
  onSubmit,
  contextModal,
  closeSelf,
  type,
  categories,
  getByCategory
}: CategoryArchiveModalProps) => (
  <FluxModal
    zIndex={contextModal.zIndex}
    width={418}
    destroyOnClose
    isOpen={isOpen}
    title={`Archive Category ${getNameModal(contextModal)}`}
    actions={{
      onOk: closeSelf,
      onClose: closeSelf
    }}
    render={() => (
      <ConfirmForm
        prepareData={(context: FormContextModal) => ({
          ...categories.find(
            category => category.idCategory === context.data.id
          ),
          status: 'archived'
        })}
        successMessage={(context: FormContextModal) =>
          `${context.data.value} has been archived`
        }
        handleSubmit={onSubmit}
        actions={{
          onOk: closeSelf,
          onClose: closeSelf
        }}
        contextModal={contextModal}
        cancelText="NO"
        text={<ArchiveCategoryText categoryType={type} />}
        fetchEntity={() => getByCategory(contextModal?.data.id)}
      />
    )}
  />
);

export default connect(CategoryModalSelector, {
  archiveCategory,
  getOffersByCategory,
  getOfferSourcesByCategory,
  getTrafficSourcesByCategory,
  getLandersByCategory
})(
  ({
    archiveCategory,
    getOffersByCategory,
    getOfferSourcesByCategory,
    getTrafficSourcesByCategory,
    getLandersByCategory,
    landerCategories,
    offerCategories,
    offerSourceCategories,
    trafficSourceCategories
  }: CategoryArchiveReduxProps) => (
    <ModalsConsumer>
      {({ isOpenModal, closeModal, getContextModal }: ModalProps) => {
        let type: string = LANDER_CATEGORY_TYPE;
        let modalName = LANDER_CATEGORY_ARCHIVE_MODAL;
        let categories = landerCategories;
        let contextModal = getContextModal(
          LANDER_CATEGORY_ARCHIVE_MODAL
        ) as FormContextModal;
        let getByCategoryAction = getLandersByCategory;

        if (isOpenModal(OFFER_CATEGORY_ARCHIVE_MODAL)) {
          type = OFFER_CATEGORY_TYPE;
          modalName = OFFER_CATEGORY_ARCHIVE_MODAL;
          categories = offerCategories;
          contextModal = getContextModal(
            OFFER_CATEGORY_ARCHIVE_MODAL
          ) as FormContextModal;
          getByCategoryAction = getOffersByCategory;
        }

        if (isOpenModal(OFFER_SOURCE_CATEGORY_ARCHIVE_MODAL)) {
          type = OFFER_SOURCE_CATEGORY_TYPE;
          modalName = OFFER_SOURCE_CATEGORY_ARCHIVE_MODAL;
          categories = offerSourceCategories;
          contextModal = getContextModal(
            OFFER_SOURCE_CATEGORY_ARCHIVE_MODAL
          ) as FormContextModal;
          getByCategoryAction = getOfferSourcesByCategory;
        }

        if (isOpenModal(TRAFFIC_SOURCE_CATEGORY_ARCHIVE_MODAL)) {
          type = TRAFFIC_SOURCE_CATEGORY_TYPE;
          modalName = TRAFFIC_SOURCE_CATEGORY_ARCHIVE_MODAL;
          categories = trafficSourceCategories;
          contextModal = getContextModal(
            TRAFFIC_SOURCE_CATEGORY_ARCHIVE_MODAL
          ) as FormContextModal;
          getByCategoryAction = getTrafficSourcesByCategory;
        }

        const isOpen =
          isOpenModal(LANDER_CATEGORY_ARCHIVE_MODAL) ||
          isOpenModal(OFFER_CATEGORY_ARCHIVE_MODAL) ||
          isOpenModal(OFFER_SOURCE_CATEGORY_ARCHIVE_MODAL) ||
          isOpenModal(TRAFFIC_SOURCE_CATEGORY_ARCHIVE_MODAL);

        return (
          <>
            <CategoryArchive
              type={type}
              isOpen={isOpen}
              onSubmit={archiveCategory(type)}
              categories={categories}
              contextModal={contextModal}
              closeSelf={() => closeModal(modalName)}
              getByCategory={getByCategoryAction}
            />
          </>
        );
      }}
    </ModalsConsumer>
  )
);

import React from 'react';
import { FunnelConnection } from '../../../../model/funnelConnection';
import { Label, Select } from '../../../Parts/Inputs';
import { Alert as AntAlert } from 'antd';
import { defined } from '../../../../utils/define';
import { FunnelNode } from '../../../../model/funnelNode';
import { NodeParams } from '../../../Builder/types';
import { getActionsBriefText } from 'utils/builder';
import { FunnelConnectionPageParams } from 'model/models';
import { FFButton, FFField, FFInput, FFSelect, FFSwitch, VisibilityWrapper } from 'uikit';
import { customEventTriggerOptions } from 'constants/index';
import { Tag } from 'antd';
import { connect } from 'react-redux';
import { BuilderModifyAction } from 'redux/selectors';
import { fixNumStringToDecimals } from 'utils/string';
import './style.scss';

const txOverride = (value: string) => value.replace(/[^a-zA-Z0-9\_\-\(\)]+/g, '');

const validateNumberInput = (input: string) => {
  const regex = /^(?:0|[1-9]\d*)(?:\.\d{1,6})?$/;
  if (!regex.test(input)) {
    return 'Please enter a valid number. No more than 6 decimal places allowed.';
  }
  const num = parseFloat(input);
  return num >= 0 && num <= 10000 ? '' : 'Please enter a number between 0 and 10000.';
};


interface State {
  connection?: FunnelConnection;
  connections?: FunnelConnection[];
  actions: number[];
  revenueError: string;
}

interface Props {
  connections: FunnelConnection[];
  connection: FunnelConnection;
  funnelNode: FunnelNode;
  paramsNode: NodeParams;
  onClose: () => void;
  customEventAliases: { [key: number]: { alias: string; shortAlias: string } };
  onChange(connections: FunnelConnection[]): void;
}

class ModifyAction extends React.Component<Props, State> {
  state: State = {
    actions: [],
    revenueError: ''
  };

  static defaultProps = {
    connections: []
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (!!props.connection && !!props.connections && !state.connection) {
      return {
        connection: props.connection,
        connections: props.connections
      };
    }

    return null;
  }

  getActionNumbers = (connectionPageParams?: FunnelConnectionPageParams) => {
    if (defined(connectionPageParams)) {
      return connectionPageParams.onActionNumbers! || [];
    } else {
      return this.state.connection?.connectionPageParams?.onActionNumbers! || [];
    }
  };

  async componentDidMount() {
    if (defined(this.state.connections) && defined(this.state.connection)) {
      await this.setState((state: State) => ({
        actions: (state.connections || [])
          ?.filter(connection => connection.idSourceNode === this.state.connection?.idSourceNode)
          .map(item => this.getActionNumbers(item.connectionPageParams))
          .flat()
      }));
    }
  }

  handleStateValues = async <T extends State, P extends keyof T>(key: P, value: T[P]) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  handleSelectChange = async (value: number[]) => {
    if (value.length > 0) {
      await this.setState((state: State) => ({
        ...state,
        connection: {
          ...state.connection!,
          connectionPageParams: {
            ...state.connection!.connectionPageParams!,
            onActionNumbers: value
          }
        }
      }));
    }
  };

  handleConversionSpecsChange = async (key: 'txOverride' | 'revenueOverride', value: string | number) => {
    this.setState((state: State) => ({
      ...state,
      connection: {
        ...state.connection!,
        connectionPageParams: {
          ...state.connection?.connectionPageParams!,
          inlineConversionSpecs: {
            ...state.connection?.connectionPageParams?.inlineConversionSpecs!,
            [key]: value
          }
        }
      },
      revenueError: key === 'revenueOverride' ? validateNumberInput(value as string) : state.revenueError
    }));
  };

  handleCustomEventSpecsChange = async (key: 'customEventNumber' | 'revenueOverride', value: string | number) => {
    this.setState((state: State) => ({
      ...state,
      connection: {
        ...state.connection!,
        connectionPageParams: {
          ...state.connection?.connectionPageParams!,
          inlineCustomEventSpecs: {
            ...state.connection?.connectionPageParams?.inlineCustomEventSpecs!,
            [key]: value
          }
        }
      },
      revenueError: key === 'revenueOverride' ? validateNumberInput(value as string) : state.revenueError
    }));
  };

  handleSwitcherChange = async (key: 'isConversion' | 'isCustomEvent', value: boolean) => {
    await this.setState((state: State) => ({
      ...state,
      connection: {
        ...state.connection!,
        connectionPageParams: {
          ...state.connection?.connectionPageParams!,
          [key]: value
        }
      },
      revenueError: ''
    }));
  };

  onToggleIsConversion = (value: boolean) => {
    const isCustomEvent = this.state.connection?.connectionPageParams?.isCustomEvent;
    this.handleSwitcherChange('isConversion', value);
    this.handleSwitcherChange('isCustomEvent', isCustomEvent ? false : false);
  };

  onToggleIsCustomEvent = (value: boolean) => {
    const isConversion = this.state.connection?.connectionPageParams?.isConversion;
    this.handleSwitcherChange('isCustomEvent', value);
    this.handleSwitcherChange('isConversion', isConversion ? false : false);
  };

  onSave = () => {
    if (this.state.revenueError) {
      return;
    }
    const connections = this.state.connections?.map(connection => {
      if (connection.idConnection === this.state.connection?.idConnection) {
        const connection = { ...this.state.connection };
        if (connection.connectionPageParams?.isConversion) {
          delete connection.connectionPageParams?.inlineCustomEventSpecs;
          delete connection.connectionPageParams?.isCustomEvent;
        } else {
          delete connection.connectionPageParams?.inlineConversionSpecs;
          delete connection.connectionPageParams?.isConversion;
        }
        return connection;
      }
      return connection;
    });
    this.handleStateValues('connections', connections);
    this.props.onChange(connections || []);
    this.props.onClose();
  };

  getActions = () => {
    const actions = [];
    for (let i = 1; i <= 255; i++) {
      actions.push(i);
    }
    return actions;
  };

  selectIsDisabled = (value: number) => {
    const numbers = this.getActionNumbers(this.state.connection?.connectionPageParams);
    return (this.state.actions.indexOf(value) !== -1 && numbers.indexOf(value) === -1) || (numbers.length === 1 && numbers[0] === value);
  };

  onDeselect = (value: number) => {
    this.setState({ actions: this.state.actions.filter(i => i !== value) });
  };

  preventWheelChange = (e: any) => {
    e.target.blur();
  };

  onConversionsRevenueBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    this.handleConversionSpecsChange('revenueOverride', parseFloat(e.target.value));
  };

  onCustomEventsRevenueBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    this.handleCustomEventSpecsChange('revenueOverride', parseFloat(e.target.value));
  };

  render() {
    return (
      <form className="cform-modifyAction flex flex-col flex-gap-10 padding-x-25 padding-y-20">
        {this.getActionNumbers().length > 0 && !!this.state.connection?.connectionPageParams?.onActionNumbers && (
          <>
            <div>
              <Label htmlFor="select-action" text="Select Actions for this connection:" />
              <Select
                value={this.getActionNumbers()}
                id="select-action"
                name="select-action"
                data-testid="select-action"
                placeholder="Select actions numbers"
                className="expand-to-max-height"
                options={this.getActions()}
                onDeselect={this.onDeselect}
                valueGetter={(option: number) => option}
                labelGetter={(option: number) => `Action ${option}`}
                mode="multiple"
                checkDisabled={this.selectIsDisabled}
                onChange={(value: number[]) => this.handleSelectChange(value)}
              />
            </div>
            <div data-testid="selected-actions-text">
              <span>
                Actions: <Tag color="geekblue">{getActionsBriefText(this.getActionNumbers())}</Tag>
              </span>
            </div>

            {defined(this.props.paramsNode?.pageGroup) && (
              <>
                <span className="cform-modifyAction__eventTriggerHeading">Optional event triggers</span>
                <FFSwitch
                  checked={this.state.connection?.connectionPageParams?.isConversion}
                  onClick={this.onToggleIsConversion}
                  data-testid="conversionSwitch"
                >
                  {this.getActionNumbers().length > 1 ? 'Make all actions trigger a conversion' : 'Trigger a conversion'}
                </FFSwitch>
                <VisibilityWrapper visible={this.state.connection?.connectionPageParams?.isConversion!}>
                  <>
                    <FFField label="Revenue (optional)">
                      <FFInput
                        placeholder="Revenue value"
                        type="number"
                        error={this.state.revenueError}
                        onWheel={this.preventWheelChange}
                        onBlur={this.onConversionsRevenueBlur}
                        value={this.state.connection?.connectionPageParams.inlineConversionSpecs?.revenueOverride}
                        onChange={e => e.target.value.length <= 8 && this.handleConversionSpecsChange('revenueOverride', fixNumStringToDecimals(e.target.value, 6))}
                      />
                    </FFField>
                    <FFField label="Transaction ID (optional)">
                      <FFInput
                        value={this.state.connection?.connectionPageParams.inlineConversionSpecs?.txOverride}
                        placeholder="Optional TxID override"
                        onChange={e =>
                          e.target.value.length <= 48 && this.handleConversionSpecsChange('txOverride', txOverride(e.target.value))
                        }
                      />
                    </FFField>
                  </>
                </VisibilityWrapper>
                <FFSwitch
                  checked={this.state.connection?.connectionPageParams?.isCustomEvent}
                  onClick={this.onToggleIsCustomEvent}
                  data-testid="customEventSwitch"
                >
                  {this.getActionNumbers().length > 1 ? 'Make all actions trigger a custom event' : 'Trigger a custom event'}
                </FFSwitch>
                <VisibilityWrapper visible={this.state.connection?.connectionPageParams?.isCustomEvent!}>
                  <>
                    <FFField label="Custom event to trigger">
                      <FFSelect
                        value={this.state.connection?.connectionPageParams.inlineCustomEventSpecs?.customEventNumber}
                        options={customEventTriggerOptions(this.props.customEventAliases)}
                        valueGetter={opt => opt.value as any}
                        labelGetter={opt => opt.label}
                        placeholder="Select a custom event"
                        onSelect={(value: string) => this.handleCustomEventSpecsChange('customEventNumber', Number(value))}
                      />
                    </FFField>
                    <FFField label="Revenue (optional)">
                      <FFInput
                        value={this.state.connection?.connectionPageParams.inlineCustomEventSpecs?.revenueOverride}
                        placeholder="Revenue value"
                        type="number"
                        onBlur={this.onCustomEventsRevenueBlur}
                        error={this.state.revenueError}
                        onWheel={this.preventWheelChange}
                        onChange={e => e.target.value.length <= 8 && this.handleCustomEventSpecsChange('revenueOverride', fixNumStringToDecimals(e.target.value, 6))}
                      />
                    </FFField>
                  </>
                </VisibilityWrapper>

                {this.getActionNumbers().length > 1 && (
                  <AntAlert
                    message=""
                    description={
                      <span>
                        If you would like only a specific action in your list to trigger a conversion, create a separate connection for it -
                        bundle any conversion-based actions into their own connection
                      </span>
                    }
                    type="warning"
                  />
                )}
              </>
            )}
          </>
        )}
        <div className="flex flex-gap-10 width-fitContent margin-top-20">
          <FFButton onClick={this.onSave}>Save</FFButton>
          <FFButton onClick={this.props.onClose} variant="outlined">
            Close
          </FFButton>
        </div>
      </form>
    );
  }
}

export default connect(BuilderModifyAction)(ModifyAction);

import table from './table';
import { reportings as defaultState } from './defaultStates';
import { REPORTINGS, API, VIEW, TABLE } from '../types';
import { ReportingsStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { defined } from '../../utils/define';
import { ReportingView } from 'types/reporting';
import { SelectOption } from 'types';

const data = (state = defaultState.reporting, action: AnyAction) => {
  switch (action.type) {
    case REPORTINGS.SET_DATA:
      if (defined(action.payload)) {
        /*let rootRows = defined(action.payload.rootRows)
          ? [...state.rootRows, ...action.payload.rootRows]
          : state.rootRows;
        if (definedObject(action.row)) {
          rootRows.push(action.row);
        }*/
        return {
          ...state,
          ...action.payload
          //rootRows: rootRows
        };
      }
      return state;
    default:
      return state;
  }
};

const dataInitial = (state = defaultState.reporting, action: AnyAction) => {
  switch (action.type) {
    case REPORTINGS.SET_DATA_INITIAL:
      if (defined(action.payload)) {
        return {
          ...state,
          ...action.payload
        };
      }
      return state;
    default:
      return state;
  }
};

const attributes = (state = defaultState.attributes, action: AnyAction) => {
  switch (action.type) {
    case REPORTINGS.SET_ATTRIBUTES:
      if (defined(action.payload)) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};

const loading = (state = defaultState.loading, action: AnyAction) => {
  switch (action.type) {
    case API.STARTED:
      return {
        ...state,
        isLoading: true
      };
    case API.ENDED:
      return {
        ...state,
        isLoading: false,
        fetchedAt: Date.now()
      };
    default:
      return state;
  }
};

const tableCols = (
  state = defaultState.settings.tableCols,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.payload.view === VIEW.REPORTINGS &&
    action.type === TABLE.SET_COLS
  ) {
    return table(state, action);
  } else {
    return state;
  }
};

const tableColSizes = (
  state = defaultState.settings.tableColSizes,
  action: AnyAction
) => {
  if (
    defined(action.payload) &&
    action.type === REPORTINGS.SET_TABLE_COL_SIZES
  ) {
    return action.payload;
  } else {
    return state;
  }
};

const view = (state = defaultState.settings.view, action: AnyAction) => {
  switch (action.type) {
    case REPORTINGS.ADD_VIEW:
      if (defined(action.payload)) {
        return {
          ...state,
          views: [...state.views, action.payload]
        };
      }
      return state;
    case REPORTINGS.DELETE_VIEW:
      return {
        ...state,
        views: state.views.filter(
          (item: ReportingView) => item.name !== action.payload
        ),
      };
    case REPORTINGS.DELETE_ALL_VIEWS:
      return {
        ...state,
        views: []
      };
    default:
      return state;
  }
};

const settings = (state = defaultState.settings.params, action: AnyAction) => {
  switch (action.type) {
    case REPORTINGS.SET_SETTINGS:
      if (defined(action.payload)) {
        return {
          ...state,
          [action.payload.key]: action.payload.data
        };
      }
      return state;
    case REPORTINGS.RESET_RESTRICT_DATA:
      return {
        ...state,
        restrictData: {
          fieldsData: {},
          switches: {}
        }
      };
    default:
      return state;
  }
};

const tableParams = (state = defaultState.tableParams, action: AnyAction) => {
  switch (action.type) {
    case REPORTINGS.SET_TABLE_PARAMS:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    default:
      return state;
  }
};

const restrictFilter = (
  state = defaultState.restrictFilter,
  action: AnyAction
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default (state = defaultState, action: any): ReportingsStore => {
  switch (action.type) {
    default:
      return {
        reporting: data(state.reporting, action),
        reportingInitial: dataInitial(state.reportingInitial, action),
        attributes: attributes(state.attributes, action),
        loading: loading(state.loading, action),
        settings: {
          tableCols: tableCols(state.settings.tableCols, action),
          tableColSizes: tableColSizes(state.settings.tableColSizes, action),
          view: view(state.settings.view, action),
          params: settings(state.settings.params, action)
        },
        tableParams: tableParams(state.tableParams, action),
        restrictFilter: restrictFilter(state.restrictFilter, action),
      };
  }
};

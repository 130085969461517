import DynamicSideBar from 'components/DynamicSideBar';
import { partners } from 'constants/partners';
import React from 'react';
import { OpenDynamicSideBar } from 'types/dynamicSideBar';
import './style.scss';
import { Partner, PartnerCategory } from 'types/partners';
import { partnerCategories } from '../../../constants/partners';
import { Input } from 'components/Parts/Inputs';
import { SearchOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import ImageAsyncSrc from 'components/ImageAsyncSrc';
import { SidebarContext } from '../context';
import { SidebarProps } from 'types/sidebars';
import { PARTNERS_MODAL } from 'constants/modal';
import { FFIcon } from 'uikit';
interface PartnerSideBarComponentProps {
  open: OpenDynamicSideBar;
  setProps: (data: Partner) => void;
}

interface PartnerSideBarComponentState {
  category: PartnerCategory;
  search: string;
}

class PartnerSideBar extends React.Component<
  PartnerSideBarComponentProps,
  PartnerSideBarComponentState
> {
  state: PartnerSideBarComponentState = {
    category: 'Tools & Services',
    search: ''
  };

  open = (key: string, data: Partner) => {
    this.props.setProps(data);
    this.props.open(key);
  };

  setCategory = (value: PartnerCategory) => {
    this.setState({ category: value });
  };

  getFilteredPartners = (category: PartnerCategory, searchText: string) => {
    return partners.filter(
      p =>
        p.partner_name.toLowerCase().includes(searchText) &&
        (!category ? true : p.category === category)
    );
  };

  render() {
    return (
      <div className="partner-sidebar">
        <div className="partner-categories">
          <ul>
            <li>CATEGORIES</li>
            {partnerCategories.map(ct => (
              <li
                key={ct}
                onClick={() => this.setCategory(ct)}
                data-active={this.state.category === ct}
              >
                {ct}
              </li>
            ))}
          </ul>
        </div>
        <div className="partner-main">
          <div className="partner-main-header">
            <div>
              <h2>Partners Marketplace</h2>
            </div>
            <div>
              <Input
                name="search"
                value={this.state.search}
                onChange={e =>
                  this.setState({ search: e.target.value.toLowerCase() })
                }
                prefix={<SearchOutlined />}
                placeholder="type to search for partners"
              />
            </div>
          </div>
          <div className="partner-main-list">
            {this.getFilteredPartners(
              this.state.category,
              this.state.search
            ).map(item => {
              return (
                <div
                  className="partner-main-list-card"
                  onClick={() => this.open('partnerDetailForm', item)}
                  key={item.key}
                >
                  <div className="partner-main-list-card-img">
                    <ImageAsyncSrc
                      src={item.img_src}
                      alt={item.partner_name}
                      folderName="marketplace"
                    />
                  </div>
                  <div className="partner-main-list-card-description">
                    <div className="partner-main-list-card-description-top">
                      <h2>{item.partner_name}</h2>
                    </div>
                    <div className="partner-main-list-card-description-text">
                      <ReactMarkdown>{item.description}</ReactMarkdown>
                      <div className="read-more" />
                    </div>
                    {item.promo_description && (
                      <span>{item.promo_description}</span>
                    )}
                  </div>
                  <div className="arrow-wrapper">
                    <FFIcon name="chevronRight" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

interface PartnerDetailSideBarComponentProps {
  sidebarItemDetailProps: Partner;
}
class PartnerDetailSideBar extends React.Component<
  PartnerDetailSideBarComponentProps,
  {}
> {
  render() {
    return (
      <div className="partner-detail-sidebar">
        <div className="partner-detail-sidebar-header">
          <h2>{this.props.sidebarItemDetailProps.partner_name}</h2>
        </div>
        <div className="partner-detail-sidebar-body">
          <ImageAsyncSrc
            src={this.props.sidebarItemDetailProps.img_src}
            alt={this.props.sidebarItemDetailProps.partner_name}
            folderName="marketplace"
          />
          <div>
            <ReactMarkdown>
              {this.props.sidebarItemDetailProps.description_expanded}
            </ReactMarkdown>
          </div>
          <a
            href={this.props.sidebarItemDetailProps.cta_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.sidebarItemDetailProps.cta_text}
          </a>
        </div>
      </div>
    );
  }
}

interface PartnersState {
  sidebarDetailProps: Partner;
}

interface PartnersProps {
  isOpen: boolean;
  onClose(): void;
}

export default class Partners extends React.Component<PartnersProps, {}> {
  state: PartnersState = {
    sidebarDetailProps: {
      key: '',
      partner_name: '',
      img_src: '',
      description: '',
      description_expanded: '',
      cta_link: '',
      cta_text: '',
      category: 'Affiliate Networks',
    }
  };
  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;

  componentDidUpdate(prevProps: PartnersProps, _: PartnersState) {
    const sidebar = this.context as SidebarProps;
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        sidebar.openSidebar(PARTNERS_MODAL);
      } else {
        sidebar.closeSidebar(PARTNERS_MODAL);
      }
    }
  }

  render() {
    return (
      <DynamicSideBar
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        defaultOpenSideBarKey="partnerForm"
        dataPortalKey="partners"
        showCloseIcon={true}
        sideBars={[
          {
            key: 'partnerForm',
            render: ({ open }) => (
              <PartnerSideBar
                open={open}
                setProps={data =>
                  this.setState({
                    sidebarDetailProps: data
                  })
                }
              />
            )
          },
          {
            key: 'partnerDetailForm',
            render: () => (
              <PartnerDetailSideBar
                sidebarItemDetailProps={this.state.sidebarDetailProps}
              />
            )
          }
        ]}
      />
    );
  }
}

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g>
      <path
        id="Vector"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.16025 0.7960714285714285 6.3686191928571425 10.58775c-0.07615219285714285 0.07147129285714285 -0.13684467857142857 0.15779727857142858 -0.1783359214285714 0.25364427857142857 -0.041491242857142854 0.09583107857142857 -0.06290556428571428 0.19916115 -0.06290556428571428 0.3036057214285714 0 0.10444457142857143 0.02141432142857143 0.20777464285714287 0.06290556428571428 0.3036057214285714 0.041491242857142854 0.09584699999999999 0.10218372857142857 0.1821729857142857 0.1783359214285714 0.25364427857142857L16.16025 21.49392857142857"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

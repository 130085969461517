import { app as defaultState } from './defaultStates';
import { AppStore } from '../../types/redux/store';
import { AnyAction } from 'redux';
import { APP } from '../types';

const version = (state = defaultState.version, action: AnyAction) => {
  switch (action.type) {
    case APP.FETCH_VERSION:
      return action.payload;
    default:
      return state;
  }
};

const unreadChatCount = (state = defaultState.unreadChatCount, action: AnyAction) => {
  switch (action.type) {
    case APP.UPDATE_UNREAD_CHAT_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export default (state = defaultState, action: any): AppStore => ({
  version: version(state.version, action),
  unreadChatCount: unreadChatCount(state.unreadChatCount, action),
});

import { CancellablePromise } from "types";

export default function makeCancellablePromise(promise: Promise<any>): CancellablePromise {
  let isCancelled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((...args) => !isCancelled && resolve(...args))
      .catch((error) => !isCancelled && reject(error));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCancelled = true;
    },
  };
}

export const timeoutPromise = (timeout: number) =>
  new Promise(res => {
    setTimeout(res, timeout);
  });

export default class HashTable {
  constructor(hashLimit = 100) {
    this.storage = [];
    this.count = 0;
    this.limit = hashLimit;
  }

  flush = () => (this.storage = []);

  insert = (key, value) => {
    const index = this.hashFunc(key, this.limit);
    let bucket = this.storage[index];
    if (!bucket) {
      bucket = [];
      this.storage[index] = bucket;
    }

    let override = false;
    for (let i = 0; i < bucket.length; i++) {
      let tuple = bucket[i];
      if (tuple[0] === key) {
        tuple[1] = value;
        override = true;
      }
    }

    if (!override) {
      bucket.push([key, value]);
      this.count++;
      // resize our storage
      if (this.count > this.limit * 0.75) {
        this.resize(this.limit * 2);
      }
    }
    return this;
  };

  remove = key => {
    let idx = this.hashFunc(key, this.limit);
    let bucket = this.storage[idx];
    if (!bucket) {
      return;
    }

    for (let i = 0; i < bucket.length; i++) {
      var tuple = bucket[i];

      if (tuple[0] === key) {
        bucket.splice(i, 1);
        this.count--;
        if (this.count < this.limit * 0.25) {
          this.resize(this.limit / 2);
        }
        return tuple[1];
      }
    }
  };

  hashFuncV2 = str => {
    let hash = 5381;
    const len = str.length;

    for (let i = 0; i < len; i++) {
      hash = (hash * 33) ^ str.charCodeAt(i);
    }

    return hash >>> 0;
  };

  hashFuncV3 = str => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash;
      hash = hash + str.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash) % this.limit;
  };

  hashFuncV4 = str => {
    let hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return Math.abs(hash) % this.limit;
  };

  hashFuncV5 = str => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash;
      hash = hash + str.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash % this.limit); //negative
  };

  /*
      Computing a hash value by summing the ASCII value of each character of the
      string (the argument passed-in) using the JavaScript function charCodeAt() to
      return a character’s ASCII value after multiplying the ASCII value by a multiplier H,
      which in this case, is an odd prime 37. And the reason to choose 37 being, by some
      empirical research, if we take over 50,000 English words (formed as the union of the word
      lists provided in two variants of Unix), using the constants 31, 33, 37, 39, and 41 will produce
      less than 7 collisions in each case, while creating a hasing function.
    */
  hashFuncV6 = str => {
    const H = 37;
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash += H * hash + str.charCodeAt(i);
    }
    hash %= this.storage.length;
    if (hash < 1) {
      hash = this.storage.length;
    }

    return Math.abs(parseInt(hash, 10));
  };

  hashFunc = (str, max) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      let letter = str[i];
      hash = (hash << 5) + letter.charCodeAt(0);
      hash = (hash & hash) % max;
    }
    return hash;
  };

  resize = limit => {
    let backup = this.storage;

    this.limit = limit;
    this.count = 0;
    this.storage = [];

    backup.forEach(bucket => {
      if (!bucket) {
        return;
      }
      for (let i = 0; i < bucket.length; i++) {
        let tuple = bucket[i];
        this.insert(tuple[0], tuple[1]);
      }
    });
  };

  get = key => {
    const idx = this.hashFunc(key, this.limit);
    const bucket = this.storage[idx];

    if (!bucket) {
      return;
    }

    for (let i = 0; i < bucket.length; i++) {
      let tuple = bucket[i];
      if (tuple[0] === key) {
        return tuple[1];
      }
    }

    return null;
  };

  getAllRelated = (id = 'CLID|0-TID', justSingleKey = 'default') => {
    let srcId = id.split('-')[0];
    let keys = this.getAllIDs();
    let occurs = [];

    const d = key => {
      switch (justSingleKey) {
        case 'src':
          if (key.split('-')[0] === srcId) {
            occurs = [...occurs, srcId];
          }
          break;
        default:
          if (key.startsWith(srcId)) {
            let o = this.get(key);
            occurs = [...occurs, o];
          }
      }
    };

    keys.forEach(key => d(key));

    return occurs;
  };

  getAllIDs = () => {
    let keys = [];

    for (let i = 0; i < this.storage.length; i++) {
      let bucket = this.storage[i];
      if (!bucket) {
        continue;
      }
      for (let k = 0; k < bucket.length; k++) {
        let tuple = bucket[k];
        keys = [...keys, tuple[0]];
      }
    }

    return keys;
  };
}

/**
 * FunnelFlux Stats API
 * API endpoints to manage stats and run statistic reports
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 export type Attribute =
 | "URL Tracking Field"
 | "Element: Campaign"
 | "Element: Funnel Group"
 | "Element: Funnel"
 | "Element: Lander"
 | "Element: Lander Group"
 | "Element: Offer"
 | "Element: Offer Group"
 | "Element: Lander Category"
 | "Element: Offer Category"
 | "Element: Node ID"
 | "Element: Node Name"
 | "Insight: Visitor Journey"
 | "Insight: Visitor Journey (Lander-Offer Groups)"
 | "Insight: Visitor Journey (All Nodes)"
 | "Insight: MVT Combination"
 | "Insight: MVT Key-Value Pairs"
 | "Insight: Time on Page"
 | "Insight: Time on Page Segments"
 | "Insight: Time to Conversion Segments"
 | "Third Parties: Traffic Source"
 | "Third Parties: Offer Source"
 | "Traffic: Tracking Domain"
 | "Traffic: Visitor Tag"
 | "Traffic: Filtered Status"
 | "Click: Action Num"
 | "Conversion: Transaction ID"
 | "Device: Device Type"
 | "Device: Brand"
 | "Device: Model"
 | "Device: Model Name"
 | "Device: OS"
 | "Device: OS Version"
 | "Device: Browser"
 | "Device: Browser Version"
 | "Device: Main Language"
 | "Connection: Connection Type"
 | "Connection: ISP"
 | "Connection: Mobile Carrier"
 | "Connection: Referrer"
 | "Connection: Referrer Domain"
 | "Connection: Type + Carrier + ISP"
 | "Location: Continent"
 | "Location: Country"
 | "Location: Region"
 | "Location: City"
 | "Location: Timezone"
 | "Time: Date"
 | "Time: Week-Parting"
 | "Time: Day-Parting"
 | "Time: 10-Minute Blocks"
 | "Time: 30-Minute Blocks"
 | "Time: 1-Hour Blocks"
 | "Time: 3-Hour Blocks";

export const Attribute = {
 URLTrackingField: "URL Tracking Field" as Attribute,
 ElementCampaign: "Element: Campaign" as Attribute,
 ElementFunnelGroup: "Element: Funnel Group" as Attribute,
 ElementFunnel: "Element: Funnel" as Attribute,
 ElementLander: "Element: Lander" as Attribute,
 ElementLanderGroup: "Element: Lander Group" as Attribute,
 ElementOffer: "Element: Offer" as Attribute,
 ElementOfferGroup: "Element: Offer Group" as Attribute,
 ElementLanderCategory: "Element: Lander Category" as Attribute,
 ElementOfferCategory: "Element: Offer Category" as Attribute,
 ElementNodeID: "Element: Node ID" as Attribute,
 ElementNodeName: "Element: Node Name" as Attribute,
 InsightVisitorJourney: "Insight: Visitor Journey" as Attribute,
 InsightVisitorJourneyLanderOfferGroups:
   "Insight: Visitor Journey (Lander-Offer Groups)" as Attribute,
 InsightVisitorJourneyAllNodes:
   "Insight: Visitor Journey (All Nodes)" as Attribute,
 InsightMVTCombination: "Insight: MVT Combination" as Attribute,
 InsightMVTKeyValuePairs: "Insight: MVT Key-Value Pairs" as Attribute,
 InsightTimeOnPage: "Insight: Time on Page" as Attribute,
 InsightTimeOnPageSegments: "Insight: Time on Page Segments" as Attribute,
 InsightTimeToConversionSegments:
   "Insight: Time to Conversion Segments" as Attribute,
 ThirdPartiesTrafficSource: "Third Parties: Traffic Source" as Attribute,
 ThirdPartiesOfferSource: "Third Parties: Offer Source" as Attribute,
 TrafficTrackingDomain: "Traffic: Tracking Domain" as Attribute,
 TrafficVisitorTag: "Traffic: Visitor Tag" as Attribute,
 TrafficFilteredStatus: "Traffic: Filtered Status" as Attribute,
 ClickActionNum: "Click: Action Num" as Attribute,
 ConversionTransactionID: "Conversion: Transaction ID" as Attribute,
 DeviceDeviceType: "Device: Device Type" as Attribute,
 DeviceBrand: "Device: Brand" as Attribute,
 DeviceModel: "Device: Model" as Attribute,
 DeviceModelName: "Device: Model Name" as Attribute,
 DeviceOS: "Device: OS" as Attribute,
 DeviceOSVersion: "Device: OS Version" as Attribute,
 DeviceBrowser: "Device: Browser" as Attribute,
 DeviceBrowserVersion: "Device: Browser Version" as Attribute,
 DeviceMainLanguage: "Device: Main Language" as Attribute,
 ConnectionConnectionType: "Connection: Connection Type" as Attribute,
 ConnectionISP: "Connection: ISP" as Attribute,
 ConnectionMobileCarrier: "Connection: Mobile Carrier" as Attribute,
 ConnectionReferrer: "Connection: Referrer" as Attribute,
 ConnectionReferrerDomain: "Connection: Referrer Domain" as Attribute,
 ConnectionTypeCarrierISP: "Connection: Type + Carrier + ISP" as Attribute,
 LocationContinent: "Location: Continent" as Attribute,
 LocationCountry: "Location: Country" as Attribute,
 LocationRegion: "Location: Region" as Attribute,
 LocationCity: "Location: City" as Attribute,
 LocationTimezone: "Location: Timezone" as Attribute,
 TimeDate: "Time: Date" as Attribute,
 TimeWeekParting: "Time: Week-Parting" as Attribute,
 TimeDayParting: "Time: Day-Parting" as Attribute,
 Time10MinuteBlocks: "Time: 10-Minute Blocks" as Attribute,
 Time30MinuteBlocks: "Time: 30-Minute Blocks" as Attribute,
 Time1HourBlocks: "Time: 1-Hour Blocks" as Attribute,
 Time3HourBlocks: "Time: 3-Hour Blocks" as Attribute,
};

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <g>
      <path
        id="Union"
        fill="currentColor"
        fillRule="evenodd"
        d="M15.678285714285714 0.6276285714285714c-0.8368571428571429 -0.8368385714285713 -2.1936285714285715 -0.836837142857143 -3.0304571428571427 0L1.6377714285714284 11.637785714285714c-0.09970000000000001 0.09971428571428571 -0.16767142857142858 0.22671428571428573 -0.1953285714285714 0.3649857142857143L0.013870942857142857 19.14557142857143c-0.04683722857142857 0.2342857142857143 0.026464628571428568 0.47628571428571426 0.19533905714285715 0.6452857142857142 0.16887285714285713 0.16885714285714287 0.4109728571428572 0.24214285714285716 0.6451585714285715 0.19528571428571428l7.142860000000001 -1.4285714285714286c0.13827142857142857 -0.027714285714285716 0.26527142857142855 -0.09557142857142857 0.365 -0.19528571428571428l11.010057142857143 -11.010214285714287c0.8368571428571429 -0.8368428571428572 0.8368571428571429 -2.1936285714285715 0 -3.0304571428571427L15.678285714285714 0.6276285714285714Z"
        clipRule="evenodd"
        strokeWidth="1"
      ></path>
    </g>
  </svg>
);

import { UpdateTrafficCostsStore } from 'types/redux/store';
import { DrilldownReportRow } from 'model/drilldownReportRow';
import { SelectOption } from 'types';
import { DrilldownReport } from 'model/drilldownReport';

export const getSelectOptions = (state: UpdateTrafficCostsStore) => {
  let data: DrilldownReport = state.campaign.data;
  let options: SelectOption[] = [];

  data.rows.forEach((item: DrilldownReportRow) => {
    const name = item.attributes[0].value;
    options.push({
      label: name,
      value: name
    });
  });

  return options;
};

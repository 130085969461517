import { AnyObject, OptionCategory } from '../../types';
import { Category } from '../../model/category';
import { UNCATEGORIZED } from '../../constants/modal';
import { defined } from '../define';
import { OptionProps } from 'antd/lib/select';

const getCategories = (categories: Category[]): AnyObject => {
  return categories.reduce((acc: any, category) => {
    acc[category.idCategory] = category;
    return acc;
  }, {});
};

export const addCategory = (
  options: AnyObject[],
  categories: Category[],
  defaultValue = UNCATEGORIZED,
  defaultField = 'category'
) => {
  const categoriesArray = getCategories(categories);
  return options.map(item => ({
    ...item,
    [defaultField]:
      defined(item.idCategory) && defined(categoriesArray[item.idCategory])
        ? categoriesArray[item.idCategory].categoryName
        : defaultValue
  }));
};

export const addCategoryToOptions = (
  options: OptionCategory[],
  categories: Category[],
  defaultValue = UNCATEGORIZED
) => {
  const categoriesArray = getCategories(categories);
  return options.map(item => ({
    ...item,
    category:
      defined(item.category) && defined(categoriesArray[item.category])
        ? categoriesArray[item.category].categoryName
        : defaultValue
  }));
};

export const filterOptionByCategory = (
  inputValue: string,
  option: React.ReactElement<OptionProps>
) =>
  (defined(option.key) &&
    option.key!.toString().toLowerCase().includes(inputValue.toLowerCase())) ||
  (defined(option.props.children) &&
    option.props.children
      .toString()
      .toLowerCase()
      .includes(inputValue.toLowerCase())) ||
  // @ts-ignore
  (defined(option.props.label) &&
    // @ts-ignore
    option.props.label!.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);

import React, { Component } from 'react';
import CodeSnippet from 'components/CodeSnippet';
import Messages from 'components/Messages';
import { FormSectionBox } from 'components/Parts/Content';
import './style.scss';
import { DomainSettingsFormProps } from 'types/ModalForms/domainSettings';
import CloudflareResponse from 'model/domains/cloudflareResponse';
import {
  checkCNAMExistance,
  checkDomainStatus,
  getDomainCloudflare
} from 'services/cloudflare';
import { MessageProps } from 'types';
import { defined } from 'utils/define';
import Icon from 'components/Icons';
import { FFTag, VisibilityWrapper } from 'uikit';
import { cloudflareDNSFormatter, ucFirst } from 'utils/string';

interface Props extends DomainSettingsFormProps, MessageProps {}

interface State {
  cloudflareData?: CloudflareResponse.Result;
  funnelFluxDomainCheck: boolean;
  cnameExists: boolean;
}

const getSSlStatus = (status: string = '') =>
  status.toLocaleLowerCase() === 'pending_validation' ? 'Pending' : ucFirst(status);

const getHostnameStatus = (cloudflareData: CloudflareResponse.Result) =>
  defined(cloudflareData.ownership_verification) ? 'Pending' : 'Active';

const getVerificationErrors = (errors: string[] = []) =>
  !errors.length ? (
    <FFTag color="blue">No errors present</FFTag>
  ) : (
    errors.map(error => <FFTag color="red">{ucFirst(error)}</FFTag>)
  );

const returnFormattedNameRecord = (record: string = '', type: string) => {
  const domainParts = record.split('.');
  const domainLength = domainParts.length;
  if (type === 'cname' && domainLength < 3) {
      return '@';
  }
  domainParts.splice(-2, 2);
  const subdomain = domainParts.join('.');
  return subdomain;
}

const getTagColor = (status: string | boolean) => {
  switch (status) {
    case true:
      return 'blue';
    case false:
      return 'red';
    case 'Pending':
      return 'orange';
    case 'pending_validation':
      return 'orange';
    case 'Active':
      return 'blue';
    case 'Error':
      return 'red';
    default:
      return 'cyan';
  }
};

class DomainSettings extends Component<Props, State> {
  state: State = {
    cloudflareData: undefined,
    cnameExists: false,
    funnelFluxDomainCheck: false
  };
  elRefs = {
    hostnameValidationRecordName: React.createRef<HTMLDivElement>(),
    hostnameValidationRecordValue: React.createRef<HTMLDivElement>(),
    sslCertificateValidationRecordName: React.createRef<HTMLDivElement>(),
    sslCertificateValidationRecordValue: React.createRef<HTMLDivElement>(),
    cnameRecordName: React.createRef<HTMLDivElement>(),
    cnameRecordValue: React.createRef<HTMLDivElement>()
  };

  handleState = async <T extends State, P extends keyof T>(
    key: P,
    value: T[P]
  ) => {
    await this.setState(state => ({
      ...state,
      [key]: value
    }));
  };

  async componentDidMount() {
    this.props.setButtonGroupProps({
      showCancel: true,
      onCancelClicked: this.props.closeSelf,
      cancelText: 'Close'
    });

    try {
      const cloudflareData = await getDomainCloudflare(
        this.props.contextModal.data.domain
      );
      if (cloudflareData.success) {
        this.handleState('cloudflareData', cloudflareData.result);
        this.handleState(
          'cnameExists',
          await checkCNAMExistance(cloudflareData.result.hostname)
        );
      }
    } catch {
      this.props.showMessage(
        Messages.warning('Sorry, Getting domain data from cloudflare failed.')
      );
    }
    this.handleState(
      'funnelFluxDomainCheck',
      await checkDomainStatus(this.props.contextModal?.data?.domain)
    );
  }

  render() {
    if (!this.state.cloudflareData) return <Icon type="flux-rippleLoading" />;
    return (
      <div className="f-domainSettings">
        <FormSectionBox title="Overall Domain Health">
          <>
            <div className="f-domainSettings__row">
              <strong>Manual check:</strong>
              <span>
                Click{' '}
                <a
                  href={`https://${this.state.cloudflareData?.hostname}/debug/request`}
                  target="_blank"
                  rel="noopener noreferrer" 
                >
                  here
                </a>{' '}
                to load our debug page. It should load a payload of text and
                give no security/SSL errors.
              </span>
            </div>
            <div className="f-domainSettings__row">
              <strong>FunnelFlux Endpoint Test:</strong>
              <FFTag color={getTagColor(this.state.funnelFluxDomainCheck)}>
                {this.state.funnelFluxDomainCheck ? 'OK' : 'Test Failed!'}
              </FFTag>
            </div>
            <div className="f-domainSettings__row">
              <strong>Cloudflare SSL Status:</strong>
              <FFTag
                color={getTagColor(
                  getSSlStatus(this.state.cloudflareData?.ssl?.status!)
                )}
              >
                {getSSlStatus(this.state.cloudflareData?.ssl?.status!)}
              </FFTag>
            </div>
            <div className="f-domainSettings__row">
              <strong>Cloudflare Hostname Status:</strong>
              <FFTag
                color={getTagColor(
                  getHostnameStatus(this.state.cloudflareData!)
                )}
              >
                {getHostnameStatus(this.state.cloudflareData!)}
              </FFTag>
            </div>
            <div className="f-domainSettings__row">
              <strong>Verification Errors:</strong>
              {getVerificationErrors(
                this.state.cloudflareData.verification_errors
              )}
            </div>
          </>
        </FormSectionBox>
        <FormSectionBox title="Required | CNAME Record">
          <VisibilityWrapper
            visible={defined(this.state.cloudflareData?.hostname)}
          >
            <>
              <p>
                This CNAME record must be added to point your domain to our
                system. You can add this first, then the SSL verification
                record.
              </p>
              <div className="f-domainSettings__row">
                <span>Record name:</span>
                <CodeSnippet
                  className="f-domainSettings__codeSnippet"
                  code={returnFormattedNameRecord(this.state.cloudflareData?.hostname, 'cname')}
                  formatter={cloudflareDNSFormatter}
                  forwardedRef={this.elRefs.cnameRecordName}
                />
              </div>
              <div className="f-domainSettings__row">
                <span>Record value:</span>
                <CodeSnippet
                  className="f-domainSettings__codeSnippet"
                  code="edge.funnelflux.pro"
                  forwardedRef={this.elRefs.cnameRecordValue}
                />
              </div>
              <div className="f-domainSettings__row"></div>
            </>
          </VisibilityWrapper>
        </FormSectionBox>
        <FormSectionBox
          title="Required | SSL Certificate Validation Record"
          titlePostfix={
            <FFTag
              className={`f-domainSettings__tag f-domainSettings__tag--${getSSlStatus(
                this.state.cloudflareData?.ssl?.status!
              )}`}
              color={getTagColor(
                getSSlStatus(this.state.cloudflareData?.ssl?.status!)
              )}
            >
              {getSSlStatus(this.state.cloudflareData?.ssl?.status!)}
            </FFTag>
          }
        >
          <VisibilityWrapper
            visible={defined(this.state.cloudflareData?.ssl?.txt_name)}
          >
            <>
              <p>
                Add the following TXT record to your domain's DNS records – this
                is required so that Cloudflare issues an SSL certificate for the
                domain. Without this, HTTPS will not function.
              </p>
              <div className="f-domainSettings__row">
                <span>Record name:</span>
                <CodeSnippet
                  className="f-domainSettings__codeSnippet"
                  code={returnFormattedNameRecord(this.state.cloudflareData?.ssl.txt_name, 'txt')}
                  forwardedRef={this.elRefs.sslCertificateValidationRecordName}
                />
              </div>
              <div className="f-domainSettings__row">
                <span>Record value:</span>
                <CodeSnippet
                  className="f-domainSettings__codeSnippet"
                  code={this.state.cloudflareData?.ssl.txt_value}
                  forwardedRef={this.elRefs.sslCertificateValidationRecordValue}
                />
              </div>
            </>
          </VisibilityWrapper>
        </FormSectionBox>
        <FormSectionBox
          title="Optional | Hostname Pre-validation Record"
          titlePostfix={
            <FFTag
              className={`f-domainSettings__tag f-domainSettings__tag--${getHostnameStatus(
                this.state.cloudflareData!
              )}`}
              color={getTagColor(getHostnameStatus(this.state.cloudflareData!))}
            >
              {getHostnameStatus(this.state.cloudflareData!)}
            </FFTag>
          }
        >
          <VisibilityWrapper
            visible={defined(this.state.cloudflareData?.ownership_verification)}
          >
            <>
              <p>
                To verify the domain and issue SSL <em>before</em> adding the
                CNAME record – to ensure no downtime, you can add this
                validation record and the SSL record first, then wait for them
                to fully verify before adding the CNAME record. Otherwise, this
                record is not needed.
              </p>
              <div className="f-domainSettings__row">
                <span>Record name:</span>
                <CodeSnippet
                  className="f-domainSettings__codeSnippet"
                  code={returnFormattedNameRecord(this.state.cloudflareData?.ownership_verification?.name, 'txt')}
                  forwardedRef={this.elRefs.hostnameValidationRecordName}
                />
              </div>
              <div className="f-domainSettings__row">
                <span>Record value:</span>
                <CodeSnippet
                  className="f-domainSettings__codeSnippet"
                  code={
                    this.state.cloudflareData?.ownership_verification?.value
                  }
                  forwardedRef={this.elRefs.hostnameValidationRecordValue}
                />
              </div>
            </>
          </VisibilityWrapper>
        </FormSectionBox>
      </div>
    );
  }
}

export default Messages.injectIn(DomainSettings);
